import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { Switch } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
// import SparkleCheckBox from 'common/sparkle-common/checkBox';
import { _errorHandler, _patternTest, _removeDuplicates, toastState } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { IoMdSettings } from 'react-icons/io';
// import { IoMdSettings } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setDuplicatesCondition,
  // setDuplicatesCondition,
  setProspects,
  setProspectsVariable,
  setVariableList,
} from 'store/campaign-v2';
import { setCurrentStepCount } from 'store/custom';

const MapVariable = () => {
  const { prospects, ids, campaign_name } = useAppSelector((state) => state?.campaignv2);
  const { brand_id, campaign_id, prospects_id } = useAppSelector((state) => state?.campaignv2?.ids);
  // const {
  //   skip_duplicate,
  //   import_duplicates_with_new_data,
  //   donot_import_leads_from_brand_campaign,
  // } = prospects.manage_duplicate;
  const [customVariable, setCustomVariable] = React.useState<{ view: boolean; key: string; input: string }>({
    view: false,
    key: '',
    input: '',
  });
  const [loading, setLoading] = React.useState({
    continue: false,
    skip: false,
    remove: false
  });
  const [modalLoading, setModalLoading] = React.useState(false);
  // const [similarEmailModal, setSimilarEmailModal] = React.useState({ view: false, count: 0, list: [] });
  const dispatch = useAppDispatch();
  // Open select box
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    const selectBoxInitialValue =
      prospects?.uploaded_prospects_list?.length > 0 &&
      Object.keys(prospects?.uploaded_prospects_list?.[0]).reduce((acc: { [key: string]: boolean }, key: string) => {
        acc[key] = false;
        return acc;
      }, {});

    setOpen(selectBoxInitialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects?.uploaded_prospects_list]);

  const handleSelect = (key: string, variable: { name: string; variable: string }) => {
    const notExist = Object.values(prospects.selected_variable).every((value) => variable.variable !== value.variable);
    if (notExist || variable.variable === 'select') {
      dispatch(setProspectsVariable({ [key]: { name: variable.name, variable: variable.variable } }));
      // setSelectedVariable((prev: { [key: string]: string }) => ({ ...prev, [key]: variable }))
      setOpen((prev) => ({ ...prev, [key]: false }));
    } else {
      toast.error(`Variable already selected`, toastState.error);
    }
  };

  const handleBack = () => {
    dispatch(setCurrentStepCount(2));
  };

  const createProspectListApi = async (prospects_list: { [key: string]: string }[], data: any) => {
    const { file_name } = prospects;
    const { invalid_emails_count, duplicate_emails_count, total_count } = data;
    const params = {
      campaign_name: campaign_name,
      ...(campaign_id && { campaign_id }),
      ...(brand_id && { brand_id }),
      prospects_data: {
        ...(prospects_id && { prospects_id }),
        file_name,
        prospect_type: prospects?.prospects_flow,
        // csv_data: prospects?.uploaded_prospects_list,
        selected_variable: prospects?.selected_variable,
        prospects_total_count: prospects_list.length,
        prospects_list,
        removed_prospects_data: {
          invalid_emails_count,
          duplicate_emails_count
        },
        skip_prospect_from_active_campaigns: prospects?.manage_duplicate?.skip_prospect_from_active_campaigns
      },
    };
    try {
      // type && setLoading((prev) => ({ ...prev, [type]: true }));
      const response = await BaseApi.post('/campaign/prospects', params);
      if (response?.error) {
        toast.error(_errorHandler(response?.message), toastState.error);
      } else {
        dispatch(
          setProspects({
            uploaded_leads_count: {
              view: true,
              contacts_in_donot_count: response?.data?.donot_send_count,
              skip_prospect_from_active_campaigns_count: response?.data?.skip_prospect_from_active_campaigns_count,
              totalCount: total_count,
              total_duplicates_count: duplicate_emails_count,
              invalid_count: invalid_emails_count,
            }
          })
        );
        dispatch(setCurrentStepCount(4));
      }
    } catch (err) {
      // console.error(err);
    } finally {
      // type && setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  // const handleConfirmLeads = async (brand_id: string, prospects_list: any, duplicateCount: number, invalidCount: number, totalCount: number) => {
  //   try {
  //     const prospectsCountResponse = await BaseApi.post('/campaign/prospects/remove/donot/list', { brand_id, prospects_list });
  //     if (prospectsCountResponse?.error) {
  //       toast.error(_errorHandler(prospectsCountResponse?.message), toastState.error);
  //     }
  //     else {
  //       dispatch(
  //         setProspects({
  //           uploaded_leads_count: {
  //             view: true,
  //             totalCount: totalCount,
  //             total_duplicates_count: duplicateCount,
  //             invalid_count: invalidCount,
  //             contacts_in_donot_count: prospectsCountResponse?.data?.matched_donot_list_count,
  //             donot_removed_prospects_list: prospectsCountResponse?.data?.donot_removed_prospects_list,
  //           },
  //         })
  //       );
  //     }

  //   } catch (error) {
  //     // console.error('Mapping variable error', error);
  //   }
  // };

  const handleContinue = async () => {
    const selectedVariableList: { name: string; variable: string }[] = Object.values(prospects.selected_variable);
    const uploadedListEntries = prospects?.uploaded_prospects_list.map((item) => Object.entries(item));
    const isEmailExist = selectedVariableList.some((value) => value.name === 'email' || value.variable === '{{ email }}'); // Check if user selected email variable

    if (isEmailExist) {
      try {
        setLoading((prev) => ({ ...prev, continue: true }));
        const originalList = uploadedListEntries.map((value) => {
          const finalValue = value.reduce((acc: Record<string, string>, item, index) => {
            const selectedData: string = selectedVariableList[index].name;
            if (selectedData !== 'select') {
              acc[selectedData] = item[1] as string;
            }
            return acc;
          }, {});
          return finalValue;
        });

        const uniqueProspects = _removeDuplicates(originalList, ['email']);
        const prospects_list = uniqueProspects.filter((item) => {
          const testEmailPattern = _patternTest('email', item.email);
          // if (!testEmailPattern) {
          //   invalidEmails = true;
          // }
          return testEmailPattern;
        });
        const duplicate_emails_count = originalList?.length - uniqueProspects?.length;
        const invalid_emails_count = uniqueProspects?.length - prospects_list?.length
        const total_count = originalList?.length;
        dispatch(setProspects({ prospects_list }));
        await createProspectListApi(prospects_list, { duplicate_emails_count, invalid_emails_count, total_count });

      } catch (error) {
        // console.error('Mapping variable error', error);
      } finally {
        setLoading((prev) => ({ ...prev, continue: false }));;
      }
    } else {
      toast.error('Selecting an email is mandatory', toastState.error);
    }
  };

  const handleClickCustomVariable = (key: string) => {
    setCustomVariable({ view: true, key, input: '' });
  };

  const handleAddVariable = async () => {
    try {
      const notExist = Object.values(prospects.selected_variable).every((value) => customVariable.input !== value.name);
      setModalLoading(true);
      if (notExist) {
        const response = await BaseApi.post('/map/variables/linked/campaign', { campaign_id: ids.campaign_id, variable: customVariable.input });
        const listResponse = await BaseApi.post('/map/variables/linked/campaign/list', { campaign_id: ids.campaign_id, campaign_name });
        if (!response.error && !listResponse.error) {
          dispatch(setVariableList(listResponse?.data || []));
          dispatch(setCurrentStepCount(3));
          dispatch(setProspectsVariable({ [customVariable.key]: response?.data }));
          setCustomVariable({ view: false, key: '', input: '' });
        }
      } else {
        toast.error(`Variable already selected`, toastState.error);
      }
    } catch (error) {
      // console.log('Fetching variables error', error);
    } finally {
      setModalLoading(false);
    }
  };

  // const handleSimilarMailSkipAndContinueOrRemove = async (type: string) => {
  //   try {
  //     if (type === 'skip') {
  //       await createProspectListApi(prospects.prospects_list, 'skip');
  //     } else {
  //       if (!similarEmailModal.list?.length) {
  //         toast.error('Prospects list should not be empty', toastState.error);
  //         return;
  //       }
  //       await createProspectListApi(similarEmailModal.list, 'remove');
  //       dispatch(setProspects({ prospects_list: similarEmailModal.list }))
  //     }
  //   } catch (error) {
  //     // console.log('error', error);
  //   }
  // };

  const disable = Object.values(prospects.selected_variable).some((value) => value.variable !== 'select') ? false : true;

  return (
    <div className="flex items-center justify-center p-8">
      <div className="w-[40.625rem] rounded-md bg-white p-5 shadow-md">
        <div className="flex flex-col">
          <h4 className="text-md font-semibold text-heading">Map variables</h4>
          {/* Columns data from csv */}
          <div>
            {/* Header */}
            <div className="mt-6 flex border-b py-2 text-xs font-semibold text-heading">
              <span className="w-3/12 px-2">Column</span>
              <span className="w-4/12 px-2">Variable Types</span>
              <span className="w-5/12 px-2">Samples</span>
            </div>
            {/* Body */}
            <div className="">
              {prospects?.uploaded_prospects_list?.length > 0 &&
                Object.entries(prospects?.uploaded_prospects_list?.[0])
                  ?.filter((item) => item[0] !== '__verification_status__')
                  ?.map((item, index) => {
                    const variableData =
                      prospects?.selected_variable[item[0]]?.variable || Object.values(prospects?.selected_variable)?.filter((key: any) => key?.name === item[0])?.[0]?.variable;

                    return (
                      <div className=" flex items-center border-b py-5" key={index}>
                        <span className="w-3/12 truncate px-2 text-[.8125rem] font-semibold text-heading">{item[0]}</span>
                        <div className="w-4/12 pl-2 pr-4">
                          <CustomMenu
                            open={open[item[0]]}
                            onOpen={() =>
                              setOpen((prev) => ({
                                ...prev,
                                [item[0]]: true,
                              }))
                            }
                            onClose={() =>
                              setOpen((prev) => ({
                                ...prev,
                                [item[0]]: false,
                              }))
                            }
                            btnContent={variableData}
                            btnContentClassName="w-32 truncate"
                            btnClassName="w-full justify-between !text-column !text-sm !rounded-md !font-normal truncate"
                            menuListClassName="w-full max-h-40 overflow-y-scroll !mt-1 !p-0"
                            rightIcon={<BiChevronDown size={18} />}
                            btnId={`select_variable_btn_${index}`}
                          >
                            <div
                              className={`w-full cursor-pointer rounded-t-md py-1 text-center text-[.8125rem]  font-medium text-column hover:bg-buttonBackground`}
                              onClick={() => handleSelect(item[0], { name: 'select', variable: 'select' })}
                            >
                              Select
                            </div>
                            <div
                              className={`w-full cursor-pointer rounded-b-md py-1 text-center text-[.8125rem]  font-medium text-column hover:bg-buttonBackground`}
                              onClick={() => handleClickCustomVariable(item[0])}
                            >
                              Custom Variable
                            </div>
                            {prospects?.variable_list
                              ?.filter((variable) => !variable?.name?.includes('apollo'))
                              ?.map((variable, index) => (
                                <div
                                  id={variable?.name}
                                  key={index}
                                  className={`w-full cursor-pointer py-1 text-center text-[.8125rem] font-medium  text-column ${prospects.selected_variable[item[0]] === variable ? 'bg-menuBorder text-white' : 'hover:bg-buttonBackground'
                                    }`}
                                  onClick={() => handleSelect(item[0], variable)}
                                >
                                  {variable?.variable}
                                </div>
                              ))}
                          </CustomMenu>
                        </div>
                        <span className="w-5/12 truncate px-2 text-xs font-semibold text-paused-row">{item[1] as string}</span>
                      </div>
                    );
                  })}
            </div>
            {/* Manage duplicates */}
            <div className="mt-2 bg-field p-2 rounded-md">
              <div className="flex items-center gap-2">
                <IoMdSettings className="text-column" size={20} />{' '}
                <span className="text-[.8125rem] font-semibold text-heading">
                  Manage Duplicates
                </span>
              </div>
              <div className="my-4 flex items-center gap-2 text-xs font-medium text-paused-color">
                <Switch size={'sm'} checked={prospects?.manage_duplicate?.skip_prospect_from_active_campaigns}
                  onChange={() => dispatch(setDuplicatesCondition({ skip_prospect_from_active_campaigns: !prospects?.manage_duplicate?.skip_prospect_from_active_campaigns }))}
                />
                <span className='text-[0.8rem]'>
                  Remove Duplicates from Other Campaigns
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex gap-4">
          <Button name="Back" customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.82rem]" onClick={handleBack} />
          <Button name="Continue" id="map_variables_continue_btn" customClass="w-full !text-[.82rem]" onClick={handleContinue} disable={disable || loading?.continue} loading={loading?.continue} />
        </div>
      </div>

      <Modal
        onClose={() => {
          setCustomVariable({ view: false, key: '', input: '' });
          setOpen((prev) => ({
            ...prev,
            [customVariable.key]: false,
          }));
        }}
        isOpen={customVariable.view}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent zIndex={10} bg={'#F9FAFB'}>
          <ModalCloseButton />
          <ModalHeader className="mt-4 border-b !px-5 !py-1 !text-sm !text-column">Custom variable</ModalHeader>
          <ModalBody className="overflow-y-auto rounded-md border-[#EAECF0]" zIndex={0}>
            <h4 className="text-xs font-semibold text-column">Name of custom variable</h4>
            <InputField
              label={''}
              placeholder="Enter custom variable..."
              inputClass="!rounded-md !h-9"
              value={customVariable.input}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCustomVariable((prev) => ({
                  ...prev,
                  input: event?.target?.value,
                }))
              }
            />
            <div className="mb-3 mt-6 flex gap-4">
              <Button
                name="Cancel"
                customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]"
                onClick={() => {
                  setCustomVariable({ view: false, key: '', input: '' });
                }}
              />
              <Button
                name="Add Variable"
                customClass="w-full text-[.82rem]"
                onClick={handleAddVariable}
                disable={customVariable.input.length < 1 || modalLoading}
                loading={modalLoading}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* <Modal isOpen={similarEmailModal.view} size={'auto'} onClose={() => setSimilarEmailModal((prev) => ({ ...prev, view: false }))} isCentered={true}>
        <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
        <ModalContent className="!w-1/4 p-2">
          <ModalCloseButton
            className="z-10 text-gray-700"
          // top='22px'
          // right="12px"
          />
          <ModalBody className="!relative !rounded-md  !p-2 ">
            <div>
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-draft-bg p-[.375rem]">
                  <div className="rounded-full bg-warningbg p-2 text-warningtext">
                    <FiAlertTriangle size={19} />
                  </div>
                </div>
              </div>
              <div className="px-2">
                <h4 className="my-1.5 font-semibold">Leads in similar campaign</h4>
                <p className="my-2 w-full text-wrap text-sm text-column">
                  {similarEmailModal?.count} leads in the uploaded file are already added in a similar campaign of the same brand. Do you want to continue?
                </p>
              </div>
              <div className="flex w-full gap-2 pt-2">
                <Button
                  id="back-btn"
                  name="Skip & Continue"
                  loading={loading?.skip}
                  disable={loading?.skip}
                  customClass="w-64 !bg-white !text-gray-800 !font-bold duration-300 !text-xs !h-8 !p-0"
                  onClick={() => handleSimilarMailSkipAndContinueOrRemove('skip')}
                />
                <Button
                  name="Remove from This Campaign"
                  customClass="w-full !text-xs hover:bg-purple-700 duration-300 !h-8"
                  onClick={() => handleSimilarMailSkipAndContinueOrRemove('remove')}
                  loading={loading?.remove}
                  disable={loading?.remove}
                />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </div>
  );
};

export default MapVariable;
