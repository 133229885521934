import { OrganizationType } from 'common/dto/types/store/organization';

export const organization = { file_name: '', upload_logo: '', organization_name: '', industry: '', business_type: '', company_size: '', occupation: '', primary_goal: '' };

export const workspace = { file_name: '', workspace_logo: '', workspace_name: '' };

export const adduser = { name: '', email: '', role_name: '' };

export const workspaceEditState = { editState: false, editId: '', organization_id: '' };

export const workspaceInviteId = '';

// export const teamsRoleEdit: { uuid: string; role_name: string; access_given: AccessGiven[] } = { uuid: '', role_name: '', access_given: [] };
export const teamsRoleEdit = { uuid: '', role_name: '', access_given: [{}], organization_id: '' };

export const teamsWorkspaceEdit = { editState: false, uuid: '', description: 'testing dev description', plan: 'free', logo: '', name: '', credits: '' };

export const initialState: OrganizationType = {
  modal_view: false,
  organization_id: '',
  step_count: 1,
  organization,
  workspace,
  adduser,
  organization_view: false,
  workspaceEditState,
  teamsWorkspaceEdit,
  workspaceInviteId,
  teamsRoleEdit,
};
