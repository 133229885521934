/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import Button from 'common/sparkle-common/Button';
// import { BaseApi } from 'common/api/common/base-api';
// import { toast } from 'react-toastify';
// import { toastState } from 'common/utils/utility';
import Input from 'common/sparkle-common/Input';
import { BaseApi } from 'common/api/common/base-api';
import { toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';

interface AccordionState {
  activeIndex: number | null;
  input: {
    [accordionIndex: number]: {
      [keyName: string]: {
        value: string;
        index: number;
      };
    };
  };
}

const AdminSettings = () => {
  //   const [openAccordion, setOpenAccordion] = useState<AccordionState>({
  //     activeIndex: null,
  //     input: {},
  //   });
  const accordionData = [
    {
      title: 'Mailer Smtp',
      description: 'This section allows you to configure the Simple Mail Transfer Protocol (SMTP) settings required for sending emails from your application.',
      index: 0,
      keys: ['SMTP PORT', 'SMTP HOST', 'SMTP USER', 'SMTP PASSWORD', 'SMTP FROM EMAIL'],
      variant: 'mailer_smtp',
    },
    {
      title: 'App Oauth',
      index: 1,
      description: 'This section allows you to configure OAuth settings for your application, enabling secure and streamlined authentication with third-party services',
      keys: ['OAUTH SERVICE', 'OAUTH TYPE', 'OAUTH CLIENT ID', 'OAUTH CLIENT SECRET', 'OAUTH REDIRECT URL', 'OAUTH ADMIN REDIRECT URL'],
      variant: 'app_oauth',
    },
    {
      title: 'Stripe',
      index: 2,
      description: 'This setting is used to configure the secret key for Stripe',
      keys: ['STRIPE SECRET KEY'],
      variant: 'stripe',
    },
    {
      title: 'Godaddy',
      index: 3,
      description: 'This section allows you to configure the API key and secret for integrating with GoDaddy services.',
      keys: ['GODADDY API KEY', 'GODADDY API SECRET'],
      variant: 'godaddy',
    },
    {
      title: 'Pay as you go',
      index: 4,
      description: 'These keys will authenticate your requests and enable on-demand access to services like domain management, email setups, and hosting.',
      keys: ['SERVICE ACCOUNT EMAIL', ' SERVICE ACCOUNT PRIVATE KEY'],
      variant: 'pay_as_you_go',
    },
    {
      title: 'Apollo',
      index: 5,
      description: 'These keys will authenticate your Apollo keys',
      keys: ['APOLLO KEY'],
      variant: 'apollo',
    },
  ];
  const [openAccordion, setOpenAccordion] = useState<AccordionState>({
    activeIndex: null,
    input: accordionData.reduce((acc, item) => {
      acc[item.index] = {};
      item.keys.forEach((keyName, keyIndex) => {
        acc[item.index][keyName] = {
          value: '',
          index: keyIndex,
        };
      });
      return acc;
    }, {} as { [accordionIndex: number]: { [keyName: string]: { value: string; index: number } } }),
  });

  const fetchData = async () => {
    try {
      const response = await BaseApi.get('/admin/key');
      if (response.status === 200) {
        // setData(response.data.data);
        // Update input fields with API values if they exist
        await setOpenAccordion((prevState) => {
          const newState = { ...prevState };
          accordionData.forEach((section, sectionIndex) => {
            const variantData = response.data.data[section.variant];
            if (variantData) {
              variantData.forEach((apiEntry: any) => {
                Object.keys(apiEntry).forEach((apiKey) => {
                  const formattedKey = apiKey.replace(/_/g, ' ').toUpperCase();
                  if (newState.input[sectionIndex][formattedKey]) {
                    newState.input[sectionIndex][formattedKey].value = apiEntry[apiKey] || '';
                  }
                });
              });
            }
          });
          return newState;
        });
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (accordionIndex: number, keyName: string, value: string) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      input: {
        ...prevState.input,
        [accordionIndex]: {
          ...prevState.input[accordionIndex],
          [keyName]: {
            ...prevState.input[accordionIndex][keyName],
            value,
          },
        },
      },
    }));
  };

  const handleSave = async (accordionIndex: number, variant: string) => {
    const sectionData = openAccordion.input[accordionIndex];
    const transformedData = Object.keys(sectionData).reduce((acc, keyName) => {
      const formattedKeyName = keyName.toLowerCase().replace(/ /g, '_');
      acc[formattedKeyName] = sectionData[keyName].value;
      return acc;
    }, {} as { [key: string]: string });
    console.log(`Saving data for section ${accordionIndex}:`, transformedData);
    try {
      const response = await BaseApi.post(`/admin/key/${variant}`, {
        api_key_details: transformedData,
      });
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        fetchData();
      } else {
        toast.error(response.message, toastState.error);
      }
    } catch (error) {}
  };

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState.activeIndex === index ? null : index,
    }));
  };

  return (
    <div className="mt-4 flex  justify-center bg-white">
      <div className="m-3 w-[62%] rounded-lg bg-white p-2  shadow-lg">
        <h1 className="ms-[4rem] mt-3 text-left text-lg font-semibold text-heading">Admin Key Settings</h1>
        <div className="h-[35rem] overflow-y-auto ">
          <div className="mt-5 flex  flex-col items-center justify-center gap-1 ">
            {accordionData.map((item, accordionIndex) => (
              <div className=" mb-3 w-[610px] rounded-lg border border-[#E5E5E5] px-6 py-2 dark:border-darkBorder ">
                <div key={accordionIndex} className="mb-5 flex cursor-pointer items-center gap-4 " onClick={() => handleAccordionClick(item?.index)}>
                  <div className="flex w-full items-center">
                    <div className="grow items-start">
                      <p className="font-semibold text-heading dark:text-white">{item.title} settings</p>
                      {openAccordion.activeIndex !== item.index && <div className="text-sm text-column dark:text-gray-600">{item.description}</div>}
                    </div>
                    <div>
                      <BiChevronDown />
                    </div>
                  </div>
                </div>
                {openAccordion.activeIndex === item.index && (
                  <div className="!mt-5 pb-2">
                    <div className="text-sm text-column">
                      {item.keys.map((key, keyIndex) => (
                        <div key={keyIndex} className="flex items-center gap-6">
                          <span className="basis-52 font-semibold text-[#2B3674]">{key}</span>
                          <div className="mt-5 basis-80">
                            <Input
                              inputType="text"
                              customClass=""
                              value={openAccordion?.input[item.index][key]?.value ? openAccordion?.input[item.index][key]?.value : ''}
                              onChange={(e) => handleInputChange(item?.index, key, e.target.value)}
                            />
                          </div>
                        </div>
                      ))}
                      <div className="mt-5 flex justify-end">
                        <Button name="Save Changes" onClick={() => handleSave(item.index, item?.variant)} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
