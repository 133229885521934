// import GetSign from 'components/signature/GetSign'
import Card from 'components/card'
import React from 'react'
import SignCanva from './SignCanva'

const Signature = () => {
  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        Signature
      </h4>
      <p className="mt-1 text-base text-gray-600">
        Add an email signature that will be shown at the end of each email.
      </p>
      <div className="w-full mt-3 py-4 bg-[#E0E5F2] rounded-3xl">
        <div className='flex gap-3 ml-5'>
          <button className="rounded-2xl bg-brand-500 px-5 py-2 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Source Code
          </button>
          <button className="rounded-2xl bg-brand-500 px-5 py-2 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Unsubscribe link
          </button>
        </div>
        <hr className='mt-4 bg-[#ffffff] h-[1px]'/>
          <SignCanva />
      </div>
      <p className='mt-3 mb-5'>
      A great email signature can improve email engagement. 
       <span className='underline font-bold'>Read our guide to creating signatures.</span>
      </p>
    </Card>
  )
}

export default Signature