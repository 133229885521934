/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { KeyValuePair } from 'common/dto/types/views/email-verifier';
import React, { useEffect } from 'react';
import Button from 'common/sparkle-common/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { setProspectListResult } from 'store/crm-onboard';
import { CustomMenu } from 'components/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import InputField from 'components/fields/InputField';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
  setIsOpenVerify: any;
}

const options = [
  {
    lable: 'First Name',
    value: 'first_name',
  },
  {
    lable: 'Last Name',
    value: 'last_name',
  },
  {
    lable: 'Phone No',
    value: 'phone_no',
  },
  {
    lable: 'email',
    value: 'email',
  },
];

const MapVariablePopup = (props: ModalComponentProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<any>({});
  const [customVariable, setCustomVariable] = React.useState<any>({
    view: false,
    key: '',
    input: '',
  });

  const [mapVariables, setMapVariables] = React.useState<any>({});
  const dispatch = useAppDispatch();
  const { onClose, isOpen, setIsOpenVerify } = props;
  const onClick = () => {
    navigate('/crm/onboard/connect/accounts');
  };

  const { prospect_list } = useAppSelector((state) => state?.crmOnboard);

  useEffect(() => {
    if (prospect_list?.file_data?.length) {
      const variableInitialValue = Object?.keys(prospect_list.file_data[0]).reduce((acc: any, key: string) => {
        acc[key] = 'select';
        return acc;
      }, {});
      setMapVariables(variableInitialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSelect = (key: string, variable: string) => {
    const notExist = Object.values(mapVariables).every((value) => variable !== value);
    if (notExist || variable === 'select') {
      setMapVariables((prev: any) => ({
        ...prev,
        [key]: variable,
      }));
      setOpen((prev: { [key: string]: string }) => ({ ...prev, [key]: false }));
    } else {
      toast.error(`Variable already selected`, toastState.error);
    }
  };

  const handleNext = () => {
    if (Object.values(mapVariables).includes('email')) {
      const transformedData = prospect_list?.file_data?.map((item: any) =>
        Object.keys(item).reduce((acc: any, key: any) => {
          acc[mapVariables[key] === 'select' ? key : mapVariables[key] || key] = item[key];
          return acc;
        }, {})
      );
      dispatch(setProspectListResult(Object.values(transformedData)));
      onClose();
      setIsOpenVerify(true);
      return;
    } else {
      toast.error(`email id is mandatory`, toastState.error);
    }
  };

  const handleClickCustomVariable = (key: any) => {
    setCustomVariable({ view: true, key, input: '' });
  };

  const handleAddVariable = () => {
    const notExist = Object.values(mapVariables).every((value) => customVariable.input !== value);
    if (notExist) {
      setMapVariables((prev: any) => ({
        ...prev,
        [customVariable.key]: customVariable.input,
      }));
      setOpen((prev: { [key: string]: string }) => ({
        ...prev,
        [customVariable.key]: false,
      }));
      setCustomVariable({ view: false, key: '', input: '' });
    } else {
      toast.error(`Variable already selected`, toastState.error);
    }
  };
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-sm text-heading ">Map variables</h5>
            </div>
          </ModalHeader>

          <ModalBody className=" !px-0">
            <div className="h-96 overflow-auto">
              {prospect_list?.file_data?.length &&
                Object.entries(prospect_list?.file_data[0]).map((item: any, index) => (
                  <div className="flex justify-between px-4 py-1">
                    <p className="text-sm font-medium text-[#2B3674] ">{item[0]}</p>
                    <CustomMenu
                      btnId={`select_variable_${index}`}
                      open={open[item[0]]}
                      onOpen={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          [item[0]]: true,
                        }))
                      }
                      onClose={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          [item[0]]: false,
                        }))
                      }
                      menuClassName="w-2/4 flex"
                      btnContent={mapVariables[item[0]]}
                      btnClassName="w-full justify-between !items-start !text-column !text-sm !rounded-md !font-normal truncate"
                      menuListClassName="w-full !mt-1 !p-0 flex flex-col !justify-start !text-start"
                      rightIcon={<BiChevronDown size={18} />}
                    >
                      <div
                        className={`w-full cursor-pointer px-4 py-1 text-start text-[.8125rem] font-medium  text-column hover:bg-buttonBackground`}
                        onClick={() => handleSelect(item[0], 'select')}
                      >
                        select
                      </div>
                      {options?.map((variable) => (
                        <div
                          id={variable?.value}
                          className={`w-full cursor-pointer px-4 py-1 text-start text-[.8125rem] font-medium  text-column ${
                            item[0] === variable ? 'bg-menuBorder text-white' : 'hover:bg-buttonBackground'
                          }`}
                          onClick={() => handleSelect(item[0], variable.value)}
                        >
                          {variable.value}
                        </div>
                      ))}
                      <div
                        className={`w-full cursor-pointer px-4 py-1 text-start text-[.8125rem] font-medium  text-column hover:bg-buttonBackground`}
                        onClick={() => handleClickCustomVariable(item[0])}
                      >
                        Custom variable
                      </div>
                    </CustomMenu>
                  </div>
                ))}

              <Modal
                onClose={() => {
                  setCustomVariable({ view: false, key: '', input: '' });
                  setOpen((prev: { [key: string]: string }) => ({
                    ...prev,
                    [customVariable.key]: false,
                  }));
                }}
                isOpen={customVariable.view}
                isCentered={true}
              >
                <ModalOverlay />
                <ModalContent zIndex={10} bg={'#F9FAFB'}>
                  <ModalCloseButton />
                  <ModalHeader className="mt-4 border-b !px-5 !py-1 !text-sm !text-column">Custom variable</ModalHeader>
                  <ModalBody className="overflow-y-auto rounded-md border-[#EAECF0]" zIndex={0}>
                    <h4 className="text-xs font-semibold text-column">Name of custom variable</h4>
                    <InputField
                      label={''}
                      placeholder="Enter custom variable..."
                      inputClass="!rounded-md !h-9"
                      value={customVariable.input}
                      onChange={(event: any) =>
                        setCustomVariable((prev: any) => ({
                          ...prev,
                          input: event?.target?.value,
                        }))
                      }
                    />
                    <div className="mb-3 mt-6 flex gap-4">
                      <Button
                        name="Cancel"
                        customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]"
                        onClick={() => {
                          setCustomVariable({
                            view: false,
                            key: '',
                            input: '',
                          });
                        }}
                      />
                      <Button name="Add Variable" customClass="w-full text-[.82rem]" onClick={handleAddVariable} disable={customVariable.input.length < 1} />
                    </div>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </div>

            <div className="flex w-full gap-4 px-6 py-2">
              <Button type="button" name="cancel" fontSize="sm" px="px-6" color="text-column" onClick={onClose} customClass="text-column font-medium text-sm w-1/2 !bg-white" />
              <Button
                id={'map_variables_next_btn'}
                type="button"
                name="Next"
                fontSize="sm"
                px="px-6"
                onClick={() => handleNext()}
                customClass="text-column font-medium text-sm w-1/2"
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MapVariablePopup;
