import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { toastState } from 'common/utils/utility';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setSnoozeId, setSnoozePopupState } from 'store/unified-inbox';
import DateTimePicker from 'views/crm/features/inbox/Tasks/DatePickerWithTime';

interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  FetchDataTrigger?: () => void;
}

const SnoozPickerPopup = (props: ModalComponentProps) => {
  const { onClose, isOpen, FetchDataTrigger } = props;
  const dispatch = useAppDispatch();
  const { snoozeId } = useAppSelector((state) => state.unifiedInboxData);
  const [date, setDate] = useState(null);

  const handleCustomSnooze = async () => {
    try {
      const payload = {
        unified_inbox_status: 'snoozed',
        uuid: [snoozeId],
        snoozed_date: date,
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        dispatch(setSnoozeId(''));
        dispatch(setSnoozePopupState(false));
        FetchDataTrigger();
      } else {
        dispatch(setSnoozeId(''));
        dispatch(setSnoozePopupState(false));
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const mergeDateAndTime = async (dateString: string, timeString: string) => {
    const date = new Date(dateString); // Parses the date string
    const [time, period] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const formatdate = date.toISOString();
    const currentDate = new Date(formatdate);
    const mongoDBDate = currentDate.toISOString();
    setDate(mongoDBDate);
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'sm'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-sm text-heading ">Pick date and time</h5>
            </div>
          </ModalHeader>
          <ModalCloseButton id="szooz" />
          <ModalBody className=" !px-0">
            <div className="relative flex flex-col items-center justify-center p-4">
              <DateTimePicker mergeDateAndTime={mergeDateAndTime} />
              <div className="flex w-full items-center justify-center gap-4 py-4">
                <Button type="button" name="Next" fontSize="sm" customClass="text-column font-medium text-sm w-[18rem]" onClick={() => handleCustomSnooze()} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SnoozPickerPopup;
