import React from 'react';
// import { Progress } from '@chakra-ui/react';
import PlanOptions from './planfeature/PlanOptions';
import AddMoreCredits from './planfeature/AddMoreCredits';
import { useAppSelector } from 'store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { setCheckSparkles, setPaymentCards, setPaymentStatus } from 'store/account-settings';
import { BaseApi } from 'common/api/common/base-api';
import { toastState } from 'common/utils/utility';
import { setUpdateSettingsData } from 'store/global-settings';

const BillingPlan = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get('order_id');
  const dispatch = useAppDispatch();

  const updateResponse = async () => {
    try {
      const response = await BaseApi.get(`/subscription/payment/status?order_id=${orderId}`);
      if (response?.data?.payment_status === 'SUCCESS') {
        toast.success('Credits added successfully', toastState.success);
        dispatch(setPaymentStatus(true));
        dispatch(setCheckSparkles());
        dispatch(setUpdateSettingsData('billings'));
      } else {
        toast.error('Failed adding Credits ', toastState.error);
        dispatch(setPaymentCards({}));
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (orderId) {
      orderId && updateResponse();
      navigate('/settings/billing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);
  // const dispatch = useAppDispatch();

  // const paymentStatus = useAppSelector((state) => state?.accountSettings?.paymentStatus);
  // const [sparkles, setSparkles] = React.useState({
  //   total_credits: 0,
  //   active_credits: 0,
  // });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sparklesCheck = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);

  // React.useEffect(() => {
  //   const fetchSparklesUsage = async () => {
  //     try {
  //       const response = await BaseApi.get(`/subscription/plan/sparkles/counts`);
  //       if (!response?.error) {
  //         setSparkles({ ...sparkles, total_credits: response?.data?.remaining_credits, active_credits: response?.data?.active_credits });
  //       }
  //     } catch (error) {}
  //   };
  //   fetchSparklesUsage();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sparklesCheck]);
  const percentage = ((sparklesCheck?.active_credits / sparklesCheck?.remaining_credits) * 100).toFixed(1);
  const { environment } = useAppSelector((state) => state.globalsettings);

  return (
    <>
      <div className="flex w-full   items-center rounded-md bg-white py-2 shadow-sm">
        <div className="w-1/12  justify-center">
          <div className="items-center text-center text-sm font-semibold">{sparklesCheck?.plan_type === 'free' ? `Free Plan` : `Pro Plan`}</div>
        </div>
        <div className=" w-10/12 items-center py-2">
          {sparklesCheck && (
            <div className="mb-1 text-xs font-semibold">
              {sparklesCheck?.active_credits} of {sparklesCheck?.remaining_credits} Sparkles Remaining
            </div>
          )}
          <div className="flex-col pt-1 ">
            <div className="mt-0.5 h-[5px] w-full rounded-full bg-gray-200">
              <div
                style={{ width: `${percentage}%` }}
                className={`${false ? 'bg-[#8F9BBA]' : 'bg-blueSecondary'} h-[5px] rounded-full
                                   `}
              ></div>
            </div>
          </div>
          {/* <Progress value={80} size={'xs'} className="!rounded-full" /> */}
        </div>
      </div>
      <div className="min-h-[70vh] overflow-auto ">
        {sparklesCheck && sparklesCheck?.plan_type === 'free' && environment?.app_default_feature?.is_org_enbaled && <PlanOptions />}
        {((sparklesCheck && !sparklesCheck?.plan_type) || (!environment?.app_default_feature?.is_org_enbaled)) && <AddMoreCredits />}
        {/* <AddMoreCredits /> */}

        {/* <MIniCardLoader/> */}
      </div>
    </>
  );
};

export default BillingPlan;
