import Card from 'components/card';
import TagsField from 'components/fields/TagsField';
import Uploader from 'components/uploader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import BackArrow from 'assets/svg/backArrow.svg';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { restrictionPlan, toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { EDIT_ID } from 'common/constants/constants';
import PaymentModal from './PaymentModal';
import { setCredits } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';
import Button from 'common/sparkle-common/Button';
import { setUpdateSettingsData } from 'store/global-settings';

const UploadEmailList = () => {
  const navigate = useNavigate();
  const [listName, setListName] = React.useState('');
  // const [checked, setChecked] = useState(1);
  const [showPayment, setShowPayment] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const custom = useAppSelector((state) => state?.custom);
  const [searchParams] = useSearchParams();
  const [emailDetails, setEmailDetails] = useState<any>({ emails: [] });
  const [uploadEmailList, setUploadEmailList] = useState<any>([]);
  const orderId = searchParams.get('orderId');

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (orderId) {
      navigate(window.location.pathname);

      const updateResponse = async () => {
        try {
          const response = await BaseApi.get(`/subscription/payment/status?orderId=${orderId}`);
          if (response.data) {
            dispatch(
              setCredits({
                totalAvailableCredits: custom.credits.totalAvailableCredits,
                totalCredits: custom.credits.totalCredits,
                hitCount: 1,
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      };
      updateResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // const handleUpdateEmailList = (emailList: Array<object>) => {
  //   const email_list = emailList.map((item: any) => {
  //     return { id: item?.id?.toString(), email: item?.name };
  //   });
  //   setManualList(email_list);
  // };
  const userType = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);
  const {environment} = useAppSelector((state)=>state.globalsettings)

  const handleSubmit = async () => {
    try {
      const restriction = environment?.app_default_feature?.is_org_enbaled &&  restrictionPlan('email_verify', userType);
      if (!restriction && environment?.app_default_feature?.is_org_enbaled) return;
      setLoading(true);
      if (!listName) {
        toast.error('Add List name!', toastState.error);
        return;
      }
      let emailList = [];
      if (uploadEmailList?.emails?.length) {
        emailList.push(...uploadEmailList?.emails);
      }
      if (emailDetails?.emails?.length) {
        emailList.push(...emailDetails?.emails);
      }
      if (!emailList?.length) {
        toast.error('At least upload CSV file or add manual email list!', toastState.error);
        return;
      }
      if (emailList?.length > 10000) {
        toast.error('Please upload email less than 10000', toastState.error);
        return;
      }

      const payload: any = {
        list_name: listName,
        email_list: emailList,
      };

      // const createList = await BaseApi.post(`${checked === 1 ? '/email/verify/quick/verification' : '/email/verify/thorough/verification'}`, payload);
      const createList = await BaseApi.post(`/email/verify/thorough/verification`, payload);

      // if (createList?.status === 200 && createList?.message === 'credits_not_found') {
      //   setShowPayment(true);
      //   return;
      // }
      if (createList?.status === 200) {
        sessionStorage.setItem(EDIT_ID, createList?.data?.insert_id);
        dispatch(
          setCredits({
            totalAvailableCredits: custom.credits.totalAvailableCredits,
            totalCredits: custom.credits.totalCredits,
            hitCount: 1,
          })
        );
        dispatch(setUpdateSettingsData('billings'));
        navigate('/email-verifier/result');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setEmailDetails([]);
      setUploadEmailList([]);
    }
  };

  const handleChange = (name: string, value: string | { id: string; email: string }[], type: string) => {
    const drawerDetails: any = { ...emailDetails, ...uploadEmailList };
    drawerDetails[name] = value as string | { id: string; email: string }[];
    type === 'tag' ? setEmailDetails(drawerDetails) : setUploadEmailList(drawerDetails);
    if (uploadEmailList?.emails?.length === 0 && type==='upload') {
      toast.error('Please upload email with correct format', toastState.error);
    }
  };

  useEffect(() => {
    if (uploadEmailList?.emails?.length > 10000) {
      toast.error('Please upload email less than 10000', toastState.error);
      setUploadEmailList([])
      return;
    }
    if (uploadEmailList?.emails?.length) {
      toast.success('Emails uploaded successfully!', toastState.success);
    }
  }, [uploadEmailList]);

  return (
    <div className={`mt-2 h-screen w-full overflow-auto rounded-lg bg-white  p-2 shadow-sm dark:bg-darkThree dark:text-white`}>
      <button
        id="campaign-close-btn"
        onClick={() => {
          navigate('/email-verifier');
        }}
        className=""
      >
        <ReactSVG src={BackArrow} className="scale-[0.8] dark:bg-darkOne" />
      </button>
      <div className="mb-24 flex items-center justify-center">
        <div className="sm:w-[80%] 2xl:w-[55%]">
          <Card extra={'w-full px-6 '}>
            {/* Header */}
            <div className="w-full px-2">
              <h4 className="mb-2 mt-4 text-[18px] font-bold leading-7 text-heading dark:text-white">Upload email</h4>
              {/* <div className="flex">
                <div
                  className={`flex cursor-pointer items-start gap-2 border p-3 ${checked === 1 && 'border-2 border-blueSecondary'} rounded-s-lg`}
                  id="quick"
                  onClick={() => setChecked(1)}
                >
                  <div className="radio-option mt-1 flex cursor-pointer items-center" onClick={() => setChecked(1)}>
                    <div className={`h-3 w-3 rounded-full border ${checked === 1 && 'border-blueSecondary'} flex cursor-pointer items-center justify-center`}>
                      <div className={`h-1 w-1  rounded-full  ${checked === 1 && 'bg-blueSecondary'} `}></div>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-[#344054]">Quick verification</p>
                    <p className="text-xs text-column">
                      Select quick verification when speed is a priority. This method focuses on rapid results, compromising on depth of checks and retries for non-responsive email
                      servers.
                    </p>
                  </div>
                </div>
                <div
                  className={`flex cursor-pointer items-start gap-2 rounded-r-lg border p-3
                ${checked === 2 && 'border-2 border-blueSecondary'}
              `}
                  id="thorough"
                  onClick={() => setChecked(2)}
                >
                  <div className="radio-option mt-1 flex cursor-pointer items-center" onClick={() => setChecked(2)}>
                    <div className={`h-3 w-3 rounded-full border ${checked === 2 && 'border-blueSecondary'} flex cursor-pointer items-center justify-center`}>
                      <div className={`h-1 w-1  rounded-full  ${checked === 2 && 'bg-blueSecondary'} `}></div>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-[#344054] ">Thorough verification</p>
                    <p className="text-xs text-column">
                      Choose thorough verification for the most accurate results. This method conducts multiple checks to ensure reliability, though it may take more time compared
                      to quick verification
                    </p>
                  </div>
                </div>
              </div> */}
              <h3 className="mt-6 text-[14px] font-bold leading-6 text-heading dark:text-white">List Name</h3>
              <p className="text-[14px] font-normal leading-5 text-column dark:text-white">Enter your list of email addresses ( “comma separated” or “one per line” )</p>
              <input
                type="text"
                className={`mt-2 flex h-10 w-full items-center justify-center rounded-lg border bg-white/0 p-3 text-sm
                            outline-none dark:border-darkBorder dark:bg-darkThree`}
                placeholder="Name"
                onChange={(e) => setListName(e.target.value)}
                id="email-verify-add-name"
              />
            </div>

            <div className="mt-4 px-2 outline-none dark:!border-white/10 sm:w-full md:w-full">
              <div className="col-span-1 row-span-2  h-full">
                <TagsField
                  id="description"
                  onChange={(item: any) => {
                    const emails = item.map((email: { id: number; name: string }) => {
                      return { id: email?.id?.toString(), email: email?.name };
                    });
                    handleChange('emails', emails, 'tag');
                  }}
                  placeholderTags={emailDetails?.emails?.map((email: { id: string; email: string }, index: number) => {
                    return { id: index, name: email?.email };
                  })}
                  placeholder="Enter emails"
                  pattern={
                    // eslint-disable-next-line no-useless-escape
                    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                  }
                  className="h-stretch relative flex min-h-10 flex-row flex-wrap rounded-lg border border-gray-200 bg-none p-3 pb-24 pt-4 focus:border-brand-200 dark:!border-white/10"
                />
                <Uploader
                  dependencies={[uploadEmailList]}
                  onUpload={(uploadedData) => {
                    const newEmails = uploadedData?.filter((item: any) => item.email);
                    handleChange('emails', [...newEmails], 'upload');
                  }}
                />
              </div>
            </div>
            <div className="my-4 flex w-full justify-center">
              <button
                className="mx-1 flex w-3/6 items-center justify-center gap-0.5 rounded-md border bg-white px-2 py-1.5 text-sm font-medium text-black transition duration-200 dark:border-none dark:bg-brand-400 dark:text-white"
                onClick={() => navigate('/email/verification/list')}
              >
                Back
              </button>
              <Button
                onClick={handleSubmit}
                loading={loading}
                disable={loading}
                id="email-verify-startbutton"
                name="Start Verification"
                customClass="w-3/6 dark:border-none text-sm font-medium text-white transition duration-200 dark:bg-brand-400 dark:text-white"
              />
              {/* <button
                className="mx-1 flex w-3/6 items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 dark:bg-brand-400 dark:text-white"
                onClick={() => handleSubmit()}
                id="email-verify-startbutton"
              >
                Start Verification
              </button> */}
            </div>
          </Card>
        </div>
      </div>
      {showPayment && <PaymentModal isOpen={showPayment} onClose={() => setShowPayment(false)} />}
    </div>
  );
};

export default UploadEmailList;
