/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import AsideNav from './Aside';
import { Spinner } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'store';
import { cryptoDecrypt } from 'common/utils/utility';
import { setAppSettings, setLoggedUserDetails, setLoggedUserRolePermissionList } from 'store/authentication';
// import { setModalView } from 'store/organization';
import { BaseApi } from 'common/api/common/base-api';
import { setCredits, setNotificationCount, setSideBarMenu } from 'store/custom';
import Navbar from './Navbar';
import usePageTitle from 'common/hooks/usePageTitle';
import { LocalStorage } from 'common/api/common/storage';
import { setSettingsData } from 'store/campaign-v2';
import { setGlobalSetting } from 'store/global-settings';

export default function HomeLayout() {
  usePageTitle();
  const dispatch = useAppDispatch();
  const { environment } = useAppSelector((state) => state.globalsettings);

  // const [navbarName, setNavbarName] = React.useState('');
  const { global_loading }: any = useAppSelector((state: { custom: any }) => state.custom);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const updateSetting = useAppSelector((state) => state?.globalsettings?.updateSettingsData);

  // const location = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToHome = React.useCallback(() => {
    if (!LocalStorage.getString(LOGIN_STORAGE_VAR)) {
      navigate('/sign-in', { replace: true });
    } else {
      location.pathname === '/' && navigate(environment?.app_default_feature?.base_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const theme = LocalStorage.getItem('darkMode') === 'true' ? true : false;
      updateLoggeduserDataToStore();
      fetchConfigMiddleware();
      // if (!loggedUser || !loggedUser?.organization_id) {
      //   return;
      // }
      if (!Object.keys(loggedUser).length) {
        return;
      }

      dispatch(setAppSettings({ key: 'theme', value: theme ? 'dark' : 'light' }));
      if (environment?.app_default_feature?.is_org_enbaled) {
        fetchEmailAliasName();
        fetchCount();
      }

      fetchSparklesUsage();
    }
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.organization_id]);

  React.useEffect(() => {
    navigateToHome();
  }, [navigateToHome]);

  async function updateLoggeduserDataToStore() {
    const decodedData = await cryptoDecrypt(LocalStorage.getString(LOGIN_STORAGE_VAR), true);
    // dispatch(setLoggedUserDetails(decodedData));
    dispatch(
      setLoggedUserDetails({
        id: decodedData?.id,
        uuid: decodedData?.uuid,
        is_admin_account: decodedData?.is_admin_account,
        first_name: decodedData?.first_name,
        last_name: decodedData?.last_name,
        email: decodedData?.email,
        organization_id: decodedData?.organization_id,
        workspace_id: decodedData?.workspace_id,
        organization_name: decodedData?.organization_name,
        workspace_name: decodedData?.workspace_name,
        authToken: decodedData?.authToken,
        picture: decodedData?.picture,
        app_settings: decodedData?.app_settings,
        is_google: decodedData?.is_google,
      })
    );
    dispatch(setLoggedUserRolePermissionList(decodedData?.role_permission_list));
    const prevDarkMode = LocalStorage.getItem('darkMode');
    const theme = prevDarkMode === 'true' ? true : false;
    theme && document.body.classList.add('dark');
  }

  const fetchCount = async () => {
    try {
      const response = await BaseApi.get(`/unified/inbox/get/notification/unread/count`);
      if (response?.status === 200) {
        dispatch(setNotificationCount(response.data.data?.pending_count));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSparklesUsage = async () => {
    try {
      const response = await BaseApi.get(`/subscription/plan/sparkles/counts`);
      if (!response?.error) {
        dispatch(setCredits({ totalCredits: response?.data?.remaining_credits, totalAvailableCredits: response?.data?.active_credits }));
        // setSparkles({ ...sparkles, total_credits: response?.data?.total_credits , active_credits: response?.data?.active_credits });
      }
    } catch (err) {
      console.error('fetchSparkleCount Error:', err);
    }
  };

  async function fetchConfigMiddleware() {
    try {
      const response = await BaseApi.get('/middleware/config');
      dispatch(setSideBarMenu(response?.data));
    } catch (err) {
      console.error('fetchConfigMiddleware Error:', err);
    }
  }

  const fetchEmailAliasName = async () => {
    try {
      const response = await BaseApi.get(`/organization/${loggedUser?.organization_id}`);
      const aliasName = `${response?.data?.sender_alias_personas?.first_name || ''} ${response?.data?.sender_alias_personas?.last_name || ''}`;
      dispatch(setSettingsData({ email_alias_name: aliasName?.trim() }));
    } catch (err) {
      console.error(err);
    }
  };

  //  Global Settings Api fetching
  const fetchGloabalSettings = async (updateSetting: string) => {
    try {
      const response = await BaseApi.get(`/auth/user/settings?source=${updateSetting}`);
      if (!response?.error) {
        updateSetting ? dispatch(setGlobalSetting({ ...response?.data })) : dispatch(setGlobalSetting(response?.data));
      }
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (LocalStorage.getString(LOGIN_STORAGE_VAR)) {
      fetchGloabalSettings(updateSetting?.updateSettingData || '');
    }
  }, [updateSetting?.updateSettingData, updateSetting?.updateState]);

  // React.useEffect(
  //   () => {
  //     const prevDarkMode = LocalStorage.getItem('darkMode');
  //     const prevStatus = prevDarkMode === 'true' ? true : false;
  //     dispatch(setDarkMode(prevStatus))
  //     prevStatus && document.body.classList.add('dark')
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []
  // )

  React.useEffect(() => {
    const updateUserSettings = async () => {
      try {
        const response = await BaseApi.patch(`/auth/user/settings`, loggedUser?.app_settings);
        if (response?.error) {
          console.error(response?.error);
        } else {
          if (response?.data) {
          }
        }
      } catch (error) {
        // toast.error('An error occurred while Updating Settings.');
      }
    };
    if (loggedUser || loggedUser?.organization_id) {
      loggedUser?.app_settings && Object.keys(loggedUser?.app_settings).length > 0 && updateUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.app_settings]);

  return (
    <>
      <div className={`flex h-screen w-full overflow-hidden bg-grayBorder p-3 pb-0 dark:bg-darkOne ${global_loading ? 'pointer-events-none blur-[.375rem]' : ''}`}>
        {loggedUser && loggedUser?.authToken && (
          <>
            {<AsideNav />}
            <div className="ml-3 h-screen w-full">
              <main className={`overflow-y-auto transition-all`}>
                {/* Header - Navbar */}
                <Navbar />
                {/* Content - Body */}
                <Outlet />
              </main>
            </div>
          </>
        )}
      </div>
      {global_loading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          // emptyColor='gray.200'
          // color='brand.500'
          className="absolute left-1/2 top-1/2 text-brand-500"
          size="xl"
        />
      )}
    </>
  );
}
