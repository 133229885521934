import { useReactTable, getCoreRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';
import CheckBox from 'common/sparkle-common/MultiSelectCheckBox.tsx';
import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TbWorldWww } from 'react-icons/tb';
import { useAppDispatch, useAppSelector } from 'store';
import { setSelectedProspectsData } from 'store/campaign-v2';
import AccessDataButton from './AccessDataButton';
import { BiSolidContact } from 'react-icons/bi';
import SelectCheckbox from './SelectCheckbox';
// import { dummyData } from 'common/dto/initial-value/views/campaign-v2';

const TableList = () => {
  const [columns, setColumns] = React.useState([]);
  // const selectedProspects = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.users_count);
  const columnHelper = createColumnHelper<any>();
  // const data = dummyData;
  const data = useAppSelector((state) => state.campaignv2.prospects.search_prospects.data);
  const dispatch = useAppDispatch();
  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const columnsData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => {
        // {
        //   selectedProspects?.select_number_of_people && table.getToggleAllRowsSelectedHandler();
        // }
        return (
          <div className="mx-2 flex items-center">
            <SelectCheckbox
              //  checked={table.getIsAllPageRowsSelected()}  // Check if all rows on the current page are selected
              //  onChange={() => table.getToggleAllRowsSelectedHandler()}  // Toggle all rows selection
              // onChange={()=>table.getToggleAllRowsSelectedHandler()}

              // onChange={()=>{table.getToggleAllRowsSelectedHandler();table.getIsAllRowsSelected()}} // Toggle all rows selection
              table={table} // Pass the table instance to SelectCheckbox
            />
            {/* <input
              type="checkbox"
              id="select-all"
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              className="block size-3.5 cursor-pointer accent-brand-500 dark:accent-purple-600"
            /> */}
          </div>
        );
      },
      cell: (info) => {
        return (
          <div className="mx-2 flex items-center">
            <CheckBox onSelect={() => {}} info={info} table={table} className="!w-fit" />
          </div>
        );
      },
    }),
    columnHelper.accessor('full_name', {
      id: 'full_name',
      enableSorting: false,
      header: (row) => <div className="select-none truncate font-medium">Full Name</div>,
      cell: (info) => (
        <div className="group relative  flex w-40 items-center gap-2 font-semibold">
          <div className="rounded-full border p-0.5">
            <img src={info?.row?.original?.photo_url} alt="" className="size-6 rounded-full" />
          </div>{' '}
          <span className="w-28 truncate">{info.getValue() || "-"}</span>
        </div>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: (row) => <div className="group relative select-none font-medium ">Email</div>,
      cell: (info) => {
        return (
          // <p className={` font-semibold `}>
          //   {info.getValue()}........
          // </p>
          <p className="font-semibold">
            {info.getValue() === '' || info.getValue() === 'email_not_unlocked@domain.com' ? (
              <AccessDataButton
                prospectId={[info?.row?.original]}
                enrichmentType={'email'}
                text={'Access email'}
                leftIcon={<BiSolidContact size={16} />}
                textColor={'text-heading'}
                borderColor={'border-heading'}
              />
            ) : (
              info.getValue()
            )}
          </p>
        );
      },
    }),
    columnHelper.accessor('title', {
      id: 'title',
      header: (row) => <div className="group relative select-none font-medium ">Title</div>,
      cell: (info) => {
        return <p className={` font-semibold `}>{info.getValue()}</p>;
      },
    }),
    columnHelper.accessor('state', {
      id: 'state',
      header: (row) => <div className="group relative select-none font-medium ">State</div>,
      cell: (info) => {
        return <p className={` font-semibold `}>{info.getValue()}</p>;
      },
    }),
    columnHelper.accessor('city', {
      id: 'city',
      header: (row) => <div className="group relative select-none font-medium ">City</div>,
      cell: (info) => {
        return <>{<p className={` font-semibold `}>{info.getValue() || "Not available"}</p>}</>;
      },
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: (row) => <div className="group relative select-none font-medium ">Country</div>,
      cell: (info) => {
        return <>{<p className={` font-semibold `}>{info.getValue()}</p>}</>;
      },
    }),
    columnHelper.accessor('linkedin_url', {
      id: 'linkedin_url',
      header: (row) => <div className="group relative w-24 select-none font-medium ">People Social</div>,
      cell: (info) => {
        return (
          <div>
            <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
              <FaLinkedin size={15} />
            </a>
            {info?.row.original.twitter_url ? (
              <a href={info?.row.original?.twitter_url} target="_blank" rel="noreferrer" className="cursor-pointer">
                <FaXTwitter size={15} />
              </a>
            ) : (
              <></>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('organization_name', {
      id: 'organization_name',
      header: (row) => {
        return <div className="group relative select-none  font-medium">Company</div>;
      },
      cell: (info) => {
        return (
          <div className="group relative  flex min-w-28 items-center gap-2 font-semibold">
            <div className="rounded-full border p-0.5">
              <img src={info?.row?.original?.organization_logo_url} alt="" className="size-6 rounded-full" />
            </div>{' '}
            <span>{info.getValue()}</span>
          </div>
        );
      },
    }),
    // columnHelper.accessor('organization_industry', {
    //   id: 'organization_industry',
    //   enableSorting: false,
    //   header: (row) => <div className="relative select-none font-medium ">Industry</div>,
    //   cell: (info) => <div className="group relative  font-semibold ">{info.getValue()}</div>,
    // }),
    columnHelper.accessor('organization_founded_year', {
      id: 'organization_founded_year',
      enableSorting: false,
      header: (row) => <div className="relative w-40 select-none font-medium ">Founded Year</div>,
      cell: (info) => <div className="group relative  font-semibold ">{info.getValue()}</div>,
    }),
    // columnHelper.accessor('organization_employees_count', {
    //   id: 'organization_employees_count',
    //   enableSorting: false,
    //   header: (row) => <div className="w-40 relative select-none font-medium ">Employee Count</div>,
    //   cell: (info) => <div className="group relative  font-semibold ">{info.getValue()}</div>,
    // }),
    columnHelper.accessor('organization_phone', {
      id: 'organization_phone',
      enableSorting: false,
      header: (row) => <div className="relative w-40 select-none font-medium ">Company Phone</div>,
      cell: (info) => <div className="group relative  font-semibold ">{info.getValue()}</div>,
    }),
    // columnHelper.accessor('organization_keywords', {
    //   id: 'organization_keywords',
    //   header: (row) => <div className="w-40 group relative select-none font-medium ">Organization Keywords</div>,
    //   cell: (info) => {
    //     return <>{<p className={`font-semibold `}>{info.getValue()}</p>}</>;
    //   },
    // }),
    columnHelper.accessor('organization_primary_domain', {
      id: 'organization_primary_domain',
      enableSorting: false,
      header: (row) => <div className="relative w-40 select-none font-medium ">Company Primary Domain</div>,
      cell: (info) => <div className="group relative  font-semibold ">{info.getValue()}</div>,
    }),
    columnHelper.accessor('organization_website_url', {
      id: 'organization_website_url',
      header: (row) => <div className="group relative select-none font-medium ">Company Website</div>,
      cell: (info) => (
        <div className="group relative font-semibold">
          <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
            <TbWorldWww size={18} />
          </a>
        </div>
      ),
    }),

    columnHelper.accessor('organization_linkedin_url', {
      id: 'organization_linkedin_url',
      header: (row) => <div className="relative select-none font-medium ">Company Social</div>,
      cell: (info) => {
        return (
          <div className="flex gap-2">
            <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
              <FaLinkedin size={15} />
            </a>
            {info?.row.original?.twitter_url ? (
              <a href={info?.row.original?.twitter_url} target="_blank" rel="noreferrer" className="cursor-pointer">
                <FaXTwitter size={15} />
              </a>
            ) : (
              <></>
            )}
          </div>
        );
      },
    }),
    // columnHelper.accessor('organization_raw_address', {
    //   id: 'organization_raw_address',
    //   header: (row) => <div className="w-40 group relative select-none font-medium ">Organization Address</div>,
    //   cell: (info) => {
    //     return <>{<p className={` font-semibold `}>{info.getValue()}</p>}</>;
    //   },
    // }),
  ];

  React.useEffect(() => {
    const selectedList = table?.getSelectedRowModel().rows.map((item) => item?.original);
    dispatch(setSelectedProspectsData(selectedList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table?.getSelectedRowModel()]);

  React.useEffect(() => {
    setColumns(columnsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-[66vh] w-full overflow-x-auto overflow-y-auto scroll-smooth">
      <table className="w-full">
        <thead>
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup?.headers?.map((header, index, arr) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    className={`mb-2 border-y border-gray-200 bg-gray-50 py-2 text-start dark:border-white/10 dark:bg-navy-900 ${index > 0 ? 'min-w-28' : ''}`}
                  >
                    <div className="text-[0.7rem] font-medium text-[#667085]">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y-[1px] selection:bg-yellow-50 selection:underline selection:decoration-yellow-400 selection:decoration-[1.5px]">
          {data.length > 0 &&
            table?.getRowModel()?.rows?.map((row) => {
              return (
                <tr key={row.id}>
                  {row?.getVisibleCells?.()?.map((cell, index) => {
                    return (
                      <td key={cell.id} className={`border-white/0 py-4 pr-3 text-start text-xs text-column duration-300 dark:text-white ${index > 0 ? 'min-w-28' : ''}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      {data.length === 0 && <div className="mt-1 text-center text-xs text-column">No Data</div>}
      {/* <InfiniteScrollComponent loadMore={campaignFetch} loader={<TableLoader width='w-full' border='border border-gray-100' column={10} row={1} skeletonHeight='h-2' padding='px-5 py-4' columnWidth='4% 22% 10% 10% 10% 10% 10% 10% 10% 4%' />} /> */}
    </div>
  );
};

export default TableList;
