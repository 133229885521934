/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import unmatched, { setfetchAllDataTrigger } from 'store/unmatched';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
  handleMerge?: () => void;
  content?: string;
  title?: string;
  secondaryEmailData?: {
    uuid?: string;
    platform_message_details?: {
      from?: {
        address?: string;
      };
    };
  };
  primaryEmailData?: {
    email?: string;
  };
}

const MergeConfrimPopUp = (props: PropsType) => {
  const { isOpen, onClose, secondaryEmailData, primaryEmailData } = props;
  const dispatch = useAppDispatch();
  const handleMerge = async () => {
    const payload = {
      prospect_details: primaryEmailData,
      unmatched_message_id: secondaryEmailData?.uuid,
    };
    onClose();
    try {
      const response = await BaseApi.patch(`/unmatched/inbox/merge`, payload);
      if (response?.status === 200) {
        toast.success(response?.message, toastState.success);
        onClose();
        dispatch(setfetchAllDataTrigger());
      } else {
        toast.error(`${response.message}`, toastState.error);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'md'} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Merge Confirmation</ModalHeader>
        <ModalCloseButton />
        <hr />
        <ModalBody>
          <div className="p-3">
            <p className="text-sm text-grayish-blue">
              Do you want to merge <span className="font-bold">{secondaryEmailData?.platform_message_details?.from?.address}</span> with{' '}
              <span className="font-bold">{primaryEmailData?.email}</span>?
            </p>
          </div>
        </ModalBody>
        <hr />
        <ModalFooter>
          <div className="mt-3 flex w-11/12 gap-3">
            <Button name="Cancel" customClass="w-full !bg-white !text-blueSecondary !border-blueSecondary font-semibold !text-[12px]" onClick={onClose} />
            <Button name="Merge" customClass="w-full font-semibold !text-[12px]" onClick={handleMerge} />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MergeConfrimPopUp;
