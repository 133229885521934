import { Avatar } from '@chakra-ui/react'
import React from 'react'
import { IoPerson } from 'react-icons/io5'
import { MdModeEditOutline } from 'react-icons/md'

import Toggle from 'components/Toggle'
import { useAppDispatch, useAppSelector } from 'store'
import { setEdit, setHeaderCount, setPreview } from 'store/campaign-v2'
import { setCurrentStepCount, setTotalStepCount } from 'store/custom'
import PreviewModal from './features/PreviewModal'
import { FaAngleDown } from 'react-icons/fa'

const ReviewAside = () => {
  const state = useAppSelector(state => state?.campaignv2)
  const { selectedSenderList, selectedGroupList, email_alias, email_alias_name } = state?.settings;
  const dispatch = useAppDispatch()
  const senderList = useAppSelector((state) => state.campaignv2.senderList)
  const groupList = useAppSelector((state) => state.campaignv2.groupList)

  const data = state?.schedule_list.filter(item => item.uuid === state?.scheduleSettings?.schedule_id)

  const moveSection = (section: number, step: number) => {
    dispatch(setEdit(true))
    dispatch(setHeaderCount(section))
    dispatch(setTotalStepCount(3))
    dispatch(setCurrentStepCount(step))
  }
  const selectedsenderUuid = Object.entries(selectedSenderList).map((item) => {
    if (item[1]) {
      return item[0]
    } else {
      return null
    }
  }).filter(Boolean)
  const selectedSenderMail = senderList?.length ? senderList?.find((item) => item?.uuid === selectedsenderUuid[0]) : ''

  const selectedGroupUuid = Object.entries(selectedGroupList).map((item) => {
    if (item[1]) {
      return item[0]
    } else {
      return null
    }
  }).filter(Boolean)
  const selectedGroupMail = groupList?.length ? groupList?.find((item) => item?.uuid === selectedGroupUuid[0]) : ''

  return (
    <div className='w-[40%] flex flex-col border shadow-dropShadow p-3.5 bg-white gap-4 h-full overflow-auto'>
      <div className='bg-[#A3AED00F] rounded-md p-3 flex flex-col gap-3'>
        <div className='flex items-center gap-2'>
          <IoPerson className='text-[#667085] size-3.5' />
          <h5 className='text-[0.8rem] font-medium text-[#667085]'>Campaign name</h5>
        </div>
        <div className='rounded-md border border-[#D0D5DD] px-2.5 py-2 bg-white'>
          <h5 className='text-column text-[0.8rem]'>{state?.campaign_name}</h5>
        </div>
      </div>
      <div className='bg-[#A3AED00F] rounded-md p-3 flex flex-col gap-1.5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IoPerson className='text-[#667085] size-3.5' />
            <h5 className='text-[0.8rem] font-medium text-[#667085]'>Tracking settings</h5>
          </div>
          <button onClick={() => moveSection(5, 1)}>
            <MdModeEditOutline className='size-3 text-column' id='setting_track_edit_id_btn' />
          </button>
        </div>
        <div className='flex gap-4'>
          <div className='flex items-center'>
            <h5 className='text-[0.73rem] font-semibold'>Track Opens</h5>
            <Toggle className='scale-[.4]' disabled={true} checkedCondition={state?.tracking_options?.open} />
          </div>
          <div className='flex items-center'>
            <h5 className='text-[0.73rem] font-semibold'>Track link clicks</h5>
            <Toggle className='scale-[.4]' disabled={true} checkedCondition={state?.tracking_options?.link_clicks} />
          </div>
          {/* <div className='flex items-center'>
            <Toggle className='scale-[.4] -ml-4 -mr-2' disabled={true} />
            <h5 className='text-[0.73rem] font-medium'>Stop for People who reply</h5>
          </div> */}
        </div>
      </div>
      <div className='bg-[#A3AED00F] rounded-md p-3 flex flex-col gap-3'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IoPerson className='text-[#667085] size-3.5' />
            <h5 className='text-[0.8rem] font-medium text-[#667085]'>Advance settings</h5>
          </div>
          {/* <MdModeEditOutline className='size-3 text-column' /> */}
        </div>
        <div className='flex gap-3'>
          <button id='email_preview_id' className='border border-[#E9EDF7] text-heading w-full rounded-md bg-[#F4F7FE] text-[0.7rem] p-2 font-medium' onClick={() => dispatch(setPreview({ preview_modal: true }))}>Preview Email</button>
          {/* <button className='border border-[#E9EDF7] text-heading w-full rounded-md bg-[#F4F7FE] text-[0.7rem] p-2 font-medium'>Send Test E-Mail</button> */}
        </div>
      </div>
      <div className='bg-[#A3AED00F] rounded-md p-3 flex flex-col gap-3'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IoPerson className='text-[#667085] size-3.5' />
            <h5 className='text-[0.8rem] font-medium text-[#667085]'>Sending accounts</h5>
          </div>
          <button onClick={() => moveSection(1, 1)}>
            <MdModeEditOutline className='size-3 text-column' id='setting_sending_account_edit_id_btn' />
          </button>
        </div>
        <div className='flex flex-col gap-3'>
          <div className='flex items-center justify-between'>
            <h5 className='text-[0.8rem] text-[#667085] font-medium'>{email_alias ? 'Persona' : 'Sender for email steps*'}</h5>
            <div className='flex relative justify-between items-center w-[20.8rem] bg-gray-100 border border-[#D0D5DD] rounded-md px-3 py-2 cursor-pointer' >
              <div className='flex items-center w-full truncate'>
                {selectedSenderMail && <>
                  <Avatar src={selectedSenderMail?.picture || ''} name={selectedSenderMail?.name} bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                  <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{selectedSenderMail?.name} ({selectedSenderMail?.account_details?.email})</h5>
                </>}

                {!selectedSenderMail && selectedGroupMail && <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{selectedGroupMail?.name} ({selectedGroupMail?.account_details?.email})</h5>}

                {!selectedSenderMail && !selectedGroupMail && email_alias_name && <>
                  <Avatar src={''} name={''} bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                  <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{email_alias_name}</h5>
                </>}
              </div>
              {selectedsenderUuid.length > 1 ? <span className='p-1 rounded-full text-[0.6rem] font-semibold text-column bg-lightPrimary h-5 w-fit flex items-center justify-center box-border'>+{selectedsenderUuid?.length - 1}</span> : <></>}
              {selectedGroupUuid.length > 1 ? <span className='p-1 rounded-full text-[0.6rem] font-semibold text-column bg-lightPrimary h-5 w-fit flex items-center justify-center box-border'>+{selectedGroupUuid?.length - 1}</span> : <></>}
              <FaAngleDown className='size-2.5' />
            </div>
          </div>
          {/* <div className='flex items-center justify-between'>
            <h5 className='text-[0.8rem] text-[#667085] font-medium'>Sender for Whatsapp steps*</h5>
            <div className='flex relative justify-between items-center w-[20.8rem] bg-gray-100 border border-[#D0D5DD] rounded-md px-3 py-2 cursor-pointer' >
              <div className='flex items-center'>
                <ImLinkedin className='rounded-full text-blue-700' />
                <h5 className='text-[0.825rem] text-column ml-2'>912345670</h5>
              </div>
            </div>
          </div> */}
          {/* <div className='flex items-center justify-between'>
            <h5 className='text-[0.8rem] text-[#667085] font-medium'>Sender for LinkedIn steps*</h5>
            <div className='flex relative justify-between items-center w-[20.8rem] bg-gray-100 border border-[#D0D5DD] rounded-md px-3 py-2 cursor-pointer' >
              <div className='flex items-center'>
                <ImLinkedin className='rounded-full text-blue-700' />
                <h5 className='text-[0.825rem] text-column ml-2'>../Jayvyas</h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className='bg-[#A3AED00F] rounded-md p-3 flex flex-col gap-3'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IoPerson className='text-[#667085] size-3.5' />
            <h5 className='text-[0.8rem] font-medium text-[#667085]'>Campaign Schedule</h5>
          </div>
          <button onClick={() => moveSection(5, 1)}>
            <MdModeEditOutline className='size-3 text-column' id='setting_schedule_edit_id_btn' />
          </button>
        </div>
        <div className='border border-[#8476FF] w-[16rem] flex items-center gap-2 p-3 px-4 rounded-md'>
          <h5 className='text-[0.75rem] font-semibold text-heading'>{data[0]?.name}</h5>
          <div className='flex flex-col'>
            <h5 className='text-[0.6rem] text-column text-nowrap font-semibold'>{data[0]?.start_time?.hours?.padStart(2, '0')}:{data[0]?.start_time?.minutes?.padStart(2, '0')} {data[0]?.start_time?.period} - {data[0]?.end_time?.hours?.padStart(2, '0')}:{data[0]?.end_time?.minutes?.padStart(2, '0')} {data[0]?.end_time?.period}</h5>
            {/* <h5 className='text-[0.7rem] text-column'>Every Monday</h5> */}
          </div>
        </div>
      </div>
      <PreviewModal />
    </div>
  )
}

export default ReviewAside