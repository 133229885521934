/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MdAdd, MdOutlineWatchLater } from 'react-icons/md';

import { FiSearch } from 'react-icons/fi';
// import { jwtDecrypt } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';

import React, { useState } from 'react';

// import AddAndEditCoupons from './features/CreateAndEditCoupons';
import { setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import TableLoader from 'components/Loaders/TableViewLoader';
import { IoMdCloudDone } from 'react-icons/io';
import moment from 'moment';
import CreateAndEditCoupons from './features/CreateAndEditCoupons';
import RowSettings from './features/RowSettings';
import { toast } from 'react-toastify';
import InfiniteScrollComponent from 'components/infinityScroll';

const CouponSettingsList = () => {
  // const navigate = useNavigate();
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 1,
    search: '',
    filter: '',
  });
  const tableRef = React.useRef(null);
  const SearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const [showCreateEditModal, setShowCreateEditModal] = React.useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const columnHelper = createColumnHelper<any>();
  const [showLoading, setShowLoading] = React.useState(true);
  const [columns, setColumns] = React.useState([]);
  const [editData, setEidtData] = React.useState(null);
  const fetchList = async () => {
    const query = setAndGetQueryParams([
      { key: 'limit', value: queryParams?.limit },
      { key: 'offset', value: queryParams?.offset },
      { key: 'search', value: queryParams?.search },
      { key: 'filter', value: queryParams?.filter },
    ]);
    try {
      setShowLoading(true);
      const response = await BaseApi.get(`/coupons/?${query}`);
      if (!response.data?.data) return setData({ data: [], count: 0 });
      if (response.data && response.data?.data?.length) {
        setData({
          data: response.data.data,
          count: response.data.total_records.count,
        });
      }
      setColumns(columnData);
    } catch (error) {
    } finally {
      setShowLoading(false);
    }
  };
  React.useEffect(() => {
    fetchList();
  }, [fetchTrigger]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const trigerFetchFunction = () => {
    setFetchTrigger(!fetchTrigger);
  };

  const handleEdit = (uuid: string, data: any) => {
    console.log('edit');
    setShowCreateEditModal(true);
    setEidtData(data);
  };

  const handleListDelete = async (uuid: string) => {
    console.log('delete');
    try {
      const response = await BaseApi.delete(`/coupons/`, { data: { uuid: uuid } });
      if (!response.error) {
        fetchList();
      } else {
        toast.error(response.error, toastState.error);
      }
    } catch (error) {}
  };

  // Scroll function
  const fetchMoretData = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/coupons/?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            table.resetRowSelection();
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: prev?.data?.length,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.offset]
  );

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-3 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-3.5 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-3 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-3.5 cursor-pointer border border-[#D0D5DD] accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('coupon_id', {
      id: 'coupon_id',
      enableSorting: false,
      header: () => <div className="text-xs font-medium text-[#667085]">Coupon Id</div>,
      cell: (info) => <p className="w-full truncate text-[0.65rem] font-semibold text-[#2B3674] dark:text-gray-200">{info.getValue()}</p>,
    }),
    columnHelper.accessor('description', {
      id: 'description',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Description</div>,
      cell: (info) => {
        return (
          <>
            <p className="w-48 overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs text-[#667085] dark:text-gray-400">{info.getValue()}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('discount_value', {
      id: 'discount_value',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Value</div>,
      cell: (info) => {
        return (
          <>
            <p className="w-full truncate text-xs text-[#667085] dark:text-gray-400">{info.getValue()}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('discount_type', {
      id: 'discount_type',
      enableSorting: false,
      header: () => <div className="text-xs font-medium text-[#667085]">Type</div>,
      cell: (info) => <p className="w-full truncate text-[0.65rem] font-semibold text-[#2B3674] dark:text-gray-200">{info.getValue()}</p>,
    }),

    columnHelper.accessor('valid_from', {
      id: 'valid_from',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Valid From</div>,
      cell: (info) => {
        const fromdate = moment(info.getValue()).format('YYYY MM DD');
        return (
          <>
            <p className="w-full truncate text-xs text-[#667085] dark:text-gray-400">{fromdate}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('valid_until', {
      id: 'valid_until',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Validity Ends</div>,
      cell: (info) => {
        const todate = moment(info.getValue()).format('YYYY MM DD');
        return (
          <>
            <p className="w-full truncate text-xs text-[#667085] dark:text-gray-400">{todate}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      enableSorting: false,
      header: () => <div className="relative text-xs font-medium text-[#667085]">Status</div>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === 0 && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                <MdOutlineWatchLater className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color `}>Inactive</h5>
              </div>
            )}
            {info.getValue() === 1 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]">
                <IoMdCloudDone color="#12B76A" />
                <span>Active</span>
              </div>
            )}
            {info.getValue() === 2 && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                <MdOutlineWatchLater className="text-xs text-rejected-color" />
                <h5 className={`text-xs font-semibold text-rejected-color `}>Deleted</h5>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative font-thin"></div>,
      cell: (row) => {
        // const blacklistStatus = row.row.original.blacklist_status;
        return (
          <div onClick={(event) => event?.stopPropagation()}>
            <RowSettings
              status={1}
              id={row.row.id}
              uuidRow={row.getValue()}
              rowData={row.row.original}
              table={table}
              setData={setData}
              handleEdit={handleEdit}
              handleListDelete={handleListDelete}
            />
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm  dark:bg-darkThree dark:text-white sm:overflow-x-auto `}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => SearchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={SearchRef}
                type="text"
                placeholder="Search"
                id="blacklist-search-input"
                className="w-full text-sm font-bold
                text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="flex items-center gap-1">
            {/* Role Access for Creating Ticket */}
            {/* {(roleStatus?.is_owner || roleStatus?.create) && (
              <button
                className="flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => navigate('/true-verifier')}
              >
                <FaRegNoteSticky className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Tickets</span>
              </button>
            )} */}
            {/* Role Access for Adding IP or Doamin */}

            <button
              id="blacklist-add-btn"
              className=" flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => {
                setEidtData(null);
                setShowCreateEditModal(true);
              }}
            >
              <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add</span>
            </button>
          </div>
        </div>
        <div className="">
          {!showLoading ? (
            <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
              <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`
                       
                        mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne">
                          {row.getVisibleCells().map((cell, i) => {
                            return (
                              <td
                                key={cell.id}
                                className={`border-white/0 py-4 
                               text-heading dark:text-white 
                               ${i === 1 ? 'w-56' : i === 2 ? 'w-60' : i === 3 ? 'w-52' : i === 4 ? 'w-32' : i === 5 ? 'w-36' : i === 6 ? 'w-32' : i === 7 ? 'w-44' : 'w-11'}
                               `}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={fetchMoretData}
                loader={
                  <TableLoader
                    width="w-full"
                    border="border border-gray-100"
                    column={10}
                    row={1}
                    skeletonHeight="h-2"
                    padding="px-5 py-4"
                    columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                  />
                }
              />
            </div>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={10}
              row={13}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
            />
          )}
        </div>
      </div>
      <CreateAndEditCoupons isOpen={showCreateEditModal} onClose={() => setShowCreateEditModal(false)} couponData={editData} trigerFetchFunction={trigerFetchFunction} />
    </>
  );
};

export default CouponSettingsList;
