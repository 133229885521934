/* eslint-disable react-hooks/exhaustive-deps */
import { CustomMenuType } from 'common/dto/types/components/customMenu';
import React from 'react';

export const CustomMenu = (props: CustomMenuType) => {
  const { children, btnContent, btnClassName, btnId, condition, menuListClassName, menuClassName, leftIcon, rightIcon, open, onOpen, onClose } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleRef = React.useRef(null);
  const menuRef = React.useRef(null);
  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    isOpen ? onOpen?.() : onClose?.();
    // if (menuRef?.current) {
    //   const viewportHeight = window.innerHeight;
    //   const bottom = Math.floor(menuRef?.current?.getBoundingClientRect()?.bottom);
    //   if (bottom > viewportHeight) {
    //     const top = Math.floor(viewportHeight - menuRef?.current?.getBoundingClientRect()?.bottom + 20);
    //     menuRef.current.style.top = `${top}px`;
    //   }
    // }
  }, [isOpen]);

  // Close the menu if clicking outside of it
  React.useEffect(() => {
    const refCondition = condition === undefined ? true : condition;
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target) && refCondition && !toggleRef?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [condition, menuRef]);

  const toggleMenu = (event: any) => {
    event.stopPropagation();
    // if (event.keyCode !== 32) {
    setIsOpen(!isOpen);
    // }
  };

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  //   if (event.keyCode === 32) {
  //     // 32 is the keyCode for spacebar
  //     setIsOpen(true); // Prevent the spacebar from triggering onClick
  //   }
  // };
  return (
    <div ref={toggleRef} className={`${menuClassName} relative `}>
      <div
        onClick={toggleMenu}
        // onKeyUp={toggleMenu}
        // onKeyDown={toggleMenu}
        id={btnId}
        className={`${btnClassName || ''} flex cursor-pointer gap-1 border-2  dark:border-darkBorder dark:bg-darkTwo dark:!text-gray-200 dark:hover:border-menuBorder ${
          isOpen ? 'border-menuBorder' : 'border-gray-100'
        } rounded-md px-3 py-1.5 text-xs font-medium hover:border-menuBorder`}
      >
        {leftIcon && <span>{leftIcon}</span>}
        <span className={props.btnContentClassName}>{btnContent}</span>
        {rightIcon && <span>{rightIcon}</span>}
      </div>
      {isOpen && (
        <div
          ref={menuRef}
          className={`${
            menuListClassName || ''
          } absolute z-50 mt-2  w-fit rounded-md border bg-white p-2 shadow-lg shadow-gray-100 dark:bg-darkTwo dark:!text-gray-200 dark:shadow-none dark:shadow-darkBorder ${
            isOpen ? 'border-menuBorder  dark:border-darkBorder  dark:border-darkThree' : 'border-gray-100'
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};
