import React from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import moment from 'moment';
import TableLoader from 'components/Loaders/TableViewLoader';
import { TbCloudDownload } from 'react-icons/tb';
import { useAppSelector } from 'store';
// import { BaseApi } from 'common/api/common/base-api';
// import { getErrorMessage } from 'store/custom';
import { setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import { MdOutlineCalendarToday, MdOutlineWatchLater } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';
import { FaXmark } from 'react-icons/fa6';
import DateRangeSelector from 'views/campaigns-insights/analytics/charts/DateRangeSelector';
import { BaseApi } from 'common/api/common/base-api';
// import { RxCross2 } from 'react-icons/rx';
const PaymentHistory = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });
  // const [intialLoading, setInitialLoading] = React.useState(true);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState([new Date(), new Date()]);
  //   const [optionData, setOptionData] = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, start_date: '', end_date: '', search: '', filter: '' });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  //   const dispatch = useAppDispatch();
  //   const searchRef = React.useRef(null);
  const columnHelper = createColumnHelper<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({ selectedIds: [], globalOpt: false });

  const paymentStatus = useAppSelector((state) => state?.accountSettings?.paymentStatus);
  React.useEffect(() => {
    const fetchInvoiceData = async () => {
      setData({ ...data, loading: true });
      try {
        const query = setAndGetQueryParams([
          { key: 'limit', value: queryParams?.limit },
          { key: 'start_date', value: queryParams?.start_date },
          { key: 'end_date', value: queryParams?.end_date },

          // { key: 'offset', value: queryParams?.offset },
          // { key: 'search', value: queryParams?.search },
          // { key: 'filter', value: queryParams?.filter },
        ]);
        const response = await BaseApi.get(`/billing/usage/list?${query}`);
        if (!response?.error) {
          response?.data?.data?.length
            ? setData({ ...data, data: response?.data?.data, count: response?.data?.total_records?.count, loading: false })
            : setData({ ...data, data: [], count: 0, loading: false });
        }
      } catch (error) { }
    };
    fetchInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.start_date, queryParams?.end_date]);

  //   const fetchList = React.useCallback(async () => {
  // try {
  //   const brandId = sessionStorage.getItem('brandId');
  //   const response = await BaseApi.get(`/donot/list/linked/with/email/${brandId}`);
  //   if (response?.error) {
  //     dispatch(getErrorMessage(response?.message));
  //   }
  //   const data = response?.data;
  //   setData((prev) => ({ ...prev, data: data?.data_list, count: data?.total_records?.count }));
  // } catch (error) {
  //   console.log('Do not email list fetching error', error);
  // }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  //   const [donotemailStatus, setDonotemailStatus] = React.useState(null);

  //   const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  //   React.useEffect(() => {
  //     const getRolePermissionStatus = async () => {
  //       const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'do_not_email_list');
  //       setDonotemailStatus(status);
  //     };
  //     getRolePermissionStatus();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  // const dummyData = [
  //   { invoice: 'Bill aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa 1', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 1 },
  //   { invoice: 'Bill 2', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 0 },
  //   { invoice: 'Bill 3', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 2 },
  //   { invoice: 'Bill 4', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 2 },
  //   { invoice: 'Bill 6', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 1 },
  //   { invoice: 'Bill 5', sparklesPurchased: '500', amount: '$1.0 USD', date: new Date(), status: 1 },
  // ];
  // React.useEffect(() => {
  //   setData({ ...data, data: dummyData });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    showCalendar &&
      // selectedDate &&
      setQueryParams({ ...queryParams, start_date: moment(selectedDate[0]).format('YYYY-MM-DD'), end_date: moment(selectedDate[1]).format('YYYY-MM-DD') });
    // const fetchInvoiceDataDateFilter = async () => {
    //   setData({ ...data, loading: true });
    //   try {
    //     const query = setAndGetQueryParams([
    //       { key: 'limit', value: queryParams?.limit },
    //       { key: 'start_date', value: moment(selectedDate[0]).format('YYYY-MM-DD') },
    //       { key: 'end_date', value: moment(selectedDate[1]).format('YYYY-MM-DD') },

    //       // { key: 'offset', value: queryParams?.offset },
    //       // { key: 'search', value: queryParams?.search },
    //       // { key: 'filter', value: queryParams?.filter },
    //     ]);
    //     const response = await BaseApi.get(`/billing/usage/list?${query}`);

    //     if (!response?.error) {
    //       response?.data?.data?.length
    //         ? setData({ ...data, data: response?.data?.data, count: response?.data?.total_records?.count, loading: false })
    //         : setData({ ...data, data: [], count: 0, loading: false });
    //     }
    //   } catch (error) {}
    // };
    // !intialLoading && !showCalendar && fetchInvoiceDataDateFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  // }, [flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  // console.log('selectedDate', queryParams);
  // console.log('tableSelection', tableSelection);

  //   React.useEffect(() => {
  //     const dateFormate = (from: number | string, to: number = 1) => {
  //       const fromDate =
  //         typeof from === 'string'
  //           ? moment()
  //               .startOf(from as moment.unitOfTime.StartOf)
  //               .format('YYYY-MM-DD')
  //           : moment().subtract(from, 'days').format('YYYY-MM-DD');
  //       const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
  //       return `${fromDate},${currentDate}`;
  //     };
  //     const data = [
  //       {
  //         id: '1',
  //         name: 'Added By',
  //         uniqueColumnName: 'added_by',
  //         conditions: [
  //           { id: '1', name: 'Is', uniqueName: 'is' },
  //           { id: '2', name: 'Is not', uniqueName: 'not' },
  //         ],
  //         resultValue: [{ id: '1', name: 'Me', uniqueName: 'me' }],
  //       },
  //       {
  //         id: '2',
  //         name: 'Added On',
  //         uniqueColumnName: 'cdate',
  //         conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
  //         resultValue: [
  //           { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
  //           { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
  //           { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
  //           { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
  //           { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
  //           { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
  //           { id: '7', name: 'Date Range' },
  //         ],
  //       },
  //     ];
  //     setOptionData(data);
  //   }, []);

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-3 flex items-center">
          {/* <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          /> */}
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-3 flex items-center" onClick={(event) => event?.stopPropagation()}>
            {/* <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
            /> */}
          </div>
        );
      },
    }),
    columnHelper.accessor('data', {
      id: 'invoice',
      enableSorting: false,
      header: () => <div>Invoice</div>,
      cell: (info) => {
        return <h5 className="w-60 truncate !text-xs font-semibold text-gray-900 dark:text-gray-300">{info?.row?.original?.title}</h5>;
      },
    }),
    columnHelper.accessor('sparkles_purchased', {
      id: 'sparkles_purchased',
      enableSorting: false,
      header: () => <div>Sparkles Purchased</div>,
      cell: (info) => <h5 className="text-xs dark:text-gray-300">{info?.row?.original?.payment_status !== 'pending' && info?.row?.original?.payment_details?.metadata?.requested_credit_count}</h5>,
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      enableSorting: false,
      header: () => <div>Amount</div>,
      cell: (info) => (
        <h5 className="text-xs dark:text-gray-300">
          {info?.row?.original?.payment_status !== 'pending' && info?.row?.original?.amount_paid} {info?.row?.original?.payment_status !== 'pending' && info?.row?.original?.currency}
        </h5>
      ),
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: () => <div className="text-start dark:text-gray-300">Date</div>,
      cell: (info) => {
        const utcDateString = info?.row?.original?.cdate;
        // const utcDateString = info.getValue();

        // const userTimeZone = 'Asia/Kolkata';
        // const utcDate = new Date(utcDateString);
        // const localDate = utcDate.toLocaleString('en-US', {
        //   timeZone: userTimeZone,
        // });
        const date = moment(utcDateString).format('MMM DD, YYYY');
        return <h5 className="text-xs">{date}</h5>;
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      enableSorting: false,
      header: () => <div className="relative text-start dark:text-gray-300">Status</div>,
      cell: (info) => {
        return (
          <div>
            {info?.row?.original?.payment_status === 'pending' && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-0.5">
                <MdOutlineWatchLater className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color `}>Pending</h5>
              </div>
            )}
            {info?.row?.original?.payment_status === 'paid' && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-0.5 text-[.7rem] font-semibold text-[#029A48]">
                <GiCheckMark color="#12B76A" />
                <span>Paid</span>
              </div>
            )}
            {info?.row?.original?.payment_status === 'unpaid' && (
              <div className="bg-red-4x00 flex w-min items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-0.5 text-[.65rem] font-semibold text-red-600">
                <FaXmark />
                <span>Failed</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative w-min font-thin ">{''}</div>,
      cell: (row) => {
        return (
          <div className="">
            <TbCloudDownload className=" mx-auto cursor-pointer text-center text-lg" />
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  //   const handleFilter = async (filteredData: any) => {
  //     const encryptedData = jwtEncrypt(filteredData);
  //     setQueryParams((prev) => {
  //       if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
  //       return { ...prev, filter: encryptedData };
  //     });
  //   };

  //   const handleListSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setQueryParams((prev) => ({
  //       ...prev,
  //       search: event.target.value.trim().toLowerCase(),
  //     }));
  //   };

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 2 },
            { key: 'search', value: queryParams?.search },
            { key: 'start_date', value: queryParams?.start_date },
            { key: 'end_date', value: queryParams?.end_date },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/billing/usage/list?${query}`);
          const responseData: any = response?.data;
          console.log("responseData",responseData)
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.offset]
  );

  //   const handleDelete = async () => {
  //     const uuids = tableSelection?.selectedIds?.map((item) => item?.uuid);
  //     // const brandId = sessionStorage.getItem('brandId');
  //     // try {
  //     //   const params: any = {
  //     //     uuid: brandId,
  //     //     data_variant_id: uuids,
  //     //   };
  //     //   const response = await BaseApi.delete('/donot/list/delete/variant/list', { data: params });
  //     //   if (response?.error) {
  //     //     dispatch(getErrorMessage(response?.message));
  //     //   } else {
  //     //     dispatch(setFlagStatus(!flagStatus));
  //     //   }
  //     // } catch (error) {
  //     //   console.log('Donot email list single delete error', error);
  //     // } finally {
  //     //   table.resetRowSelection();
  //     // }
  //   };

  //   const handleExportCSV = () => {
  //     const exportData = tableSelection?.selectedIds.map((item) => ({
  //       Invoice: item?.invoice,
  //       'Sparkles Purchased': item?.sparklesPurchased,
  //       Amount: item?.amount,

  //       'Added On': `${moment(item?.cdate).format('MMM DD YYYY')}`,
  //       Status: item?.status === 0 ? 'Pending' : item?.status === 1 ? 'Paid' : 'Failed',
  //     }));
  //     CSVFileDownloader(exportData, 'donot_entries_list.csv');
  //     table.resetRowSelection();
  //   };
  console.log('SelectedDATA', selectedDate[0]);
  return (
    <div className="h-[90vh] ">
      {/* Table */}
      <div className={` mt-2.5 h-[78vh] rounded-lg bg-white  pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-6 mt-1 flex items-center px-9 py-0.5 sm:gap-32 lg:gap-32 xl:justify-between">
          <div className="-pl-2 -ml-5 flex gap-2.5">
            <span className="font-semibold ">Billing History</span>
            {/* <div
              className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        md:w-[13rem] lg:w-[26rem] dark:border-gray-800"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                onChange={handleListSearch}
                type="text"
                placeholder="Search"
                id="donot-search-input"
                className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
              />
            </div>
            {<CustomFilter optionData={optionData} handleFilter={handleFilter} />} */}
          </div>
          <div className="flex items-center gap-2">
            {/* {(donotemailStatus?.is_owner || donotemailStatus?.delete)&&  */}
            {/* <Button
              disable={tableSelection?.selectedIds?.length <= 0}
              LeftIcon={<MdDeleteOutline className="float-right text-lg" />}
              name="Delete"
              customClass={`${tableSelection?.selectedIds?.length > 0 ? 'bg-white font-semibold !text-gray-800' : ''} delete_btn`}
              onClick={handleDelete}
            /> */}
            {/* } */}
            <div className="relative min-w-28:">
              <div
                className={`border-2 ${showCalendar ? 'border-menuBorder' : 'border-gray-100'
                  } flex h-8 cursor-pointer items-center   gap-2 rounded-md bg-white px-3 py-3.5 shadow-bottom hover:border-menuBorder`}
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <MdOutlineCalendarToday className="size-4 text-[#667085]" />
                <h5 className="text-[0.7rem] font-medium text-[#667085]"> {`${queryParams?.start_date || queryParams?.end_date ? moment(selectedDate[0]).format('MMM-DD-YYYY') === moment(selectedDate[1]).format('MMM-DD-YYYY') ? moment(selectedDate[0]).format('MMM-DD-YYYY'):`${moment(selectedDate[0]).format('MMM-DD-YYYY')} - ${moment(selectedDate[1]).format('MMM-DD-YYYY')}` : 'Select Date'}`}</h5>
                {/* <>{ queryParams?.start_date || queryParams?.end_date ? <></>: <RxCross2 className="size-4 text-[#667085]" onClick={()=>{}}/>}</> */}
              </div>
              {showCalendar && <DateRangeSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} setShowCalendar={setShowCalendar} right="right-0" top="top-9" />}
            </div>
            {/* Export button */}
            {/* <Button
              disable={tableSelection?.selectedIds?.length <= 0}
              LeftIcon={<TbCloudDownload className="float-right text-lg" />}
              name="Download All"
              customClass={`${tableSelection?.selectedIds?.length > 0 ? 'bg-white font-semibold !text-gray-800' : ''} export_csv`}
              onClick={handleExportCSV}
            /> */}
          </div>
        </div>
        {!data.loading ? (
          <div className="min-h-12 overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = ['w-[3%]', 'w-[42%]', 'w-[10%]', 'w-[15%]', 'w-[10%]', 'w-[10%]', 'w-[10%]'];
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default  select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[.625rem] font-semibold ">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = ['w-[3%]', 'w-[42%]', 'w-[10%]', 'w-[15%]', 'w-[10%]', 'w-[10%]', 'w-[10%]'];
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={6}
            row={8}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 25% 22% 22% 25% 4%"
          />
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
