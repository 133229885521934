import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { useAppSelector } from 'store';
import BrandHeaderComponent from './brand-list/BrandHeaderComponent';
import StepSettings from './sender-settings';
interface PropsTyped {
  brandList?: any[];
}

const BrandInputData = (props: PropsTyped) => {
  const { brandList } = props;
  const state = useAppSelector(state => state?.campaignv2)

  return (
    <div className="relative top-[40%] -translate-y-1/2 flex justify-center">
      {state?.editLoading && <Spinner
        className='absolute top-1/2 text-brand-300'
        speed='0.8s'
        size={'md'} />}
      <div className={` rounded-md bg-white dark:bg-darkThree w-[46.875rem] mt-11 pb-2 shadow-lg`}>
        <div className="flex justify-between px-4 pb-2 pt-5 border-b">
          <h4 className="mt-2 text-sm font-bold text-column dark:text-white">Setup</h4>
        </div>
        <div className="flex justify-between px-4 pb-2 pt-5">
          <h4 className="mt-2 text-xs font-medium text-column dark:text-white">Select Brand</h4>
          <BrandHeaderComponent brandList={brandList} />
        </div>
        <StepSettings />
      </div>
      
    </div>
  );
};

export default BrandInputData;
