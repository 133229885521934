import { BaseApi } from 'common/api/common/base-api';
import { SessionStorage } from 'common/api/common/storage';
import { CHANNEL_ID } from 'common/constants/constants';
import Button from 'common/sparkle-common/Button';
import { toastState } from 'common/utils/utility';
import Toggle from 'components/Toggle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';

const NumbersBillingPage = () => {
  const navigate = useNavigate();
  const numbersToBuy = useAppSelector((state) => state?.senderGroupListData?.numbersToBuy);
  const countryCodeToBuy = useAppSelector((state) => state?.senderGroupListData?.countryCode);

  const [loading, setLoading] = React.useState(false);
  const variantType = SessionStorage?.getString(CHANNEL_ID);
  const phoneNumbersToBuy = numbersToBuy?.map((item: { number: string }) => item?.number);

  const handlePurchaseNumbers = async () => {
    try {
      setLoading(true);
      const response = await BaseApi.post(`/sender-accounts/purchase/${variantType}/numbers`, { phoneNumbers: phoneNumbersToBuy, countryCode: countryCodeToBuy });
      console.log('response', response);
      if (!response?.error) {
        toast.success('Purchase Successful', toastState?.success);
        setLoading(false);
        navigate('/sender/accounts');
      }
    } catch (error) {}
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-2/5 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">Billing</p>
        <hr />

        <div className="m-4 rounded-xl border border-[#FEC84B] bg-gradient-to-r from-[#FFFFFF] to-[#FEF0C7]">
          <div className="flex justify-between p-4 ">
            <p className="text-xl font-bold text-blackDark">Purchase Numbers</p>
            <div>
              <p className="mb-2 flex items-center justify-center text-base font-medium text-column">
                Set-up fee :<span className="mr-1 font-semibold"> $0 </span>
                {/* <span>
                <IoIosInformationCircle className="ml-3 text-[#7090b0]" />
              </span> */}
              </p>
              <p className="mb-2 flex items-center justify-center text-base font-medium text-column">
                Monthly :
                <span className="mr-1 font-semibold">
                  {' '}
                  ${numbersToBuy
                    .map((item: { monthly_rental_rate: string }) => parseFloat(item?.monthly_rental_rate))
                    .reduce((acc: number, current: number) => acc + current, 0)}{' '}
                </span>
                {/* <span>
                <IoIosInformationCircle className="ml-3 text-[#7090b0]" />
              </span> */}
              </p>
            </div>
          </div>
          <hr />
          <div className="flex justify-between gap-5 p-4 ">
            <div className="h-48 w-full overflow-auto rounded-md border bg-white p-3">
              <div className="flex items-center justify-center text-xs font-semibold text-heading">
                <Toggle className="scale-50" checkedCondition={true} />
                Annual pricing (<span className="font-medium text-[#6941C6]">save 20%</span>)
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-heading">Numbers</p>
                <div className="flex gap-3">
                  <p className="text-sm font-semibold text-heading">Monthly</p>
                  <p className="text-sm font-semibold text-heading">Annual</p>
                </div>
              </div>
              {numbersToBuy.map((item: any) => (
                <div className="mb-2 mt-1 flex items-center justify-between">
                  <p className="text-xs font-medium text-heading">
                    {item?.number} {item?.voice_enabled && <span className="ml-2 rounded-full bg-blue-100 px-1.5 text-xs text-blue-500">Voice</span>}
                    {item?.sms_enabled && <span className="ml-2 rounded-full bg-purple-100 px-1.5 text-xs text-purple-500">SMS</span>}
                    {item?.mms_enabled && <span className="ml-2 rounded-full bg-green-100 px-1.5 text-xs text-green-500">MMS</span>}
                  </p>

                  <div className="flex gap-3">
                    <p className="text-xs font-medium text-heading">${parseFloat(item?.monthly_rental_rate).toFixed(2)}/m</p>
                    <p className="text-xs font-medium text-heading">${(parseFloat(item?.monthly_rental_rate) * 12).toFixed(2)}/yr</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div className="p-2">
            <Button
              name={`Purchase`}
              disable={loading}
              loading={loading}
              customClass="!text-[.875rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out"
              onClick={handlePurchaseNumbers}
              id="workspace_next_btn"
            />
          </div>

          {/* <Button name="Purchase now" customClass="w-[96%] my-4 ml-4 text-sm" /> */}
        </div>
      </div>
    </div>
  );
};

export default NumbersBillingPage;
