
type StatsBoxProps = {
    title?: string;
    section?: string;
    value?: string;
}

const StatsBox = (props: StatsBoxProps) => {
    const { section, title, value } = props;
    return (
        <div className='h-[10.5rem] w-[25.8rem] border border-[#EAECF0] rounded-md px-4 py-2.5'>
            <h5 className='text-heading font-medium text-[1rem]'>{title}</h5>
            <h5 className='text-heading font-semibold text-[1rem] -mt-1'>{section}</h5>
            <h5 className='text-heading font-semibold text-[3rem] mt-[1.75rem]'>{value}</h5>
        </div>
    )
}

export default StatsBox