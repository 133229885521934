import Button from 'common/sparkle-common/Button';
import SparkleRadio from 'common/sparkle-common/Radio';
import { CustomMenu } from 'components/customMenu';
import SearchBox from 'components/searchBox';
import React from 'react'
import { BiArrowBack } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CiMail } from "react-icons/ci";
import { FaUser } from 'react-icons/fa6';
// import { HiOutlineSwitchVertical } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { IoFilterSharp } from 'react-icons/io5';
import { TextInput } from './TextInput';
import { BaseApi } from 'common/api/common/base-api';
import { Box, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import moment from 'moment';
import InfiniteScrollComponent from 'components/infinityScroll';
import { setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import { useAppDispatch, useAppSelector } from 'store';
import { setUnMatchedEmails } from 'store/crm';
import SelectAllContent from 'common/SparkleCommon/SelectAll';

const BoxSkeleton = () => {
    return (
        <Box m='2' padding='4' boxShadow='lg' bg='white' className='flex gap-3 items-center'>
            <div className='flex flex-col justify-center items-center gap-2'>
                <SkeletonCircle size='10' />
                <SkeletonText noOfLines={1} spacing='2' skeletonHeight='3' className='w-3' />
            </div>
            <SkeletonText mt='2' noOfLines={4} spacing='2' skeletonHeight='2' className='w-full' />
        </Box>
    )
}

const LeftSideView = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [emailsData, setEmails] = React.useState({ loading: true, originalData: [], searched_word: '', searched_emails: [], count: 0 })
    const [selectedEmails, setSelectedEmails] = React.useState([])
    const [shiftIndex, setShiftIndex] = React.useState(0)
    const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' })
    const [filter, setFilter] = React.useState({
        rules_type: 0,  // 0 - filter search, 1 - ignore , 2 - ignore rule, 3 - match campaign rule, 4 - search campaign, 5 - new oppertunity
        filter_search: {
            from: '',
            to: '',
            domain: '',
            subject: '',
            keywords: '',
            date: ''
        },
        rules: {
            ignore: false,
            match_campaign: false,
            new_oppertunity: false,
            apply_matched_results: false
        },
        rule_ignore: {
            once: false,
            entire_domain: false,
            entire_email: false
        },
        rule_matched_campaign: {
            searched_word: '',
            searched_campaigns: [],
        },
        rule_new_oppertunity: {
            name: '',
            email: '',
            company_name: ''
        }

    })
    const debouncedSearchValue = useDebounce(queryParams?.search, 300);
    const dispatch = useAppDispatch()
    const { unmatched_emails } = useAppSelector((state) => state?.crm)
    // const data = Array.from({ length: 10 }, () => {
    //     return {
    //         name: 'vijay',
    //         profile: 'dfdf',
    //         time: '12:30 PM',
    //         subject: 'New product or service announcement',
    //         message: 'Dear Ramprasad, We are thrilled to announce the launch of our new product.'
    //     };
    // });

    React.useEffect(() => {
        const fetchDbEmails = async () => {
            try {
                setEmails(prev => ({ ...prev, loading: true }))
                const query = setAndGetQueryParams([{ key: 'search', value: queryParams?.search }, { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' }, { key: 'filter', value: queryParams?.filter }])
                const response = await BaseApi.get(`/crm/pipeline/opportunity/unmatched/list?${query}`);
                if (response?.data && response?.data?.data) {
                    setEmails(prev => ({ ...prev, originalData: response?.data?.data, count: response?.data?.total_records?.count }))
                    const current_length = emailsData?.originalData?.length + response?.data?.data?.length
                    const uuid = response?.data?.data[0]?.uuid
                    dispatch(setUnMatchedEmails({ current_length, selected_uuid: uuid }))
                }
            } catch (error) {
                console.log('Unmatched email fetching error', error)
            } finally {
                setEmails(prev => ({ ...prev, loading: false }))
            }
        }

        fetchDbEmails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order])

    const handleRadioChange = (isChecked: boolean, name: string) => {
        setFilter(prev => {
            const finalData: any = { ...prev }
            if (name === 'apply_matched_results') {
                finalData.rules[name] = isChecked;
            } else {
                Object.keys(finalData?.rules).forEach(key => {
                    if (key !== 'apply_matched_results') {
                        finalData.rules[key] = key === name ? isChecked : false;
                    }
                });
            }

            return finalData
        })
        // setChecked(prev => {
        //     let newState: any = { ...prev };
        //     if (name === 'apply_matched_results') {
        //         newState[name] = isChecked;
        //     } else {
        //         Object.keys(newState).forEach(key => {
        //             if (key !== 'apply_matched_results') {
        //                 newState[key] = key === name ? isChecked : false;
        //             }
        //         });
        //     }

        //     return newState;
        // });
    }

    const handleChangeIgnoreRule = (isChecked: boolean, name: string) => {
        setFilter(prev => {
            const finalData: any = { ...prev }
            Object.keys(finalData?.rule_ignore).forEach(key => {
                finalData.rule_ignore[key] = key === name ? isChecked : false;
            });
            return finalData
        })
    }
    const profile = {
        photo: false,
        name: 'sam',
        campaign_name: 'Campaign name',
        date: 'Dec 15, 10:00 AM'
    }

    const handleChangeStage = (stage: number) => {
        setFilter((prev) => ({
            ...prev,
            rules_type: stage
        }))
    }

    const handleSetRules = () => {
        const { ignore, match_campaign, new_oppertunity } = filter?.rules
        if (ignore) {
            return handleChangeStage(2)
        }
        if (match_campaign) {
            return handleChangeStage(3)
        }
        if (new_oppertunity) {
            return handleChangeStage(5)
        }
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleMatchCampaignSearch = (event: any) => {
        setFilter(prev => {
            const finalData: any = { ...prev }
            finalData.rule_matched_campaign.searched_word = event?.target?.value
            return finalData
        })
    }

    const handleInputChange = (key: string, event: any) => {
        const { name, value } = event?.target
        setFilter(prev => {
            const finalData: any = { ...prev }
            finalData[key][name] = value
            return finalData
        })
    }

    const handleMailSearch = (event: any) => {
        setQueryParams((prev) => ({
            ...prev,
            search: event?.target?.value
        }))
    }

    const handleSelect = (event: any, uuid: number) => {
        if (event?.nativeEvent?.shiftKey) {
            setSelectedEmails(prev => {
                const selectedMails = emailsData?.originalData?.reduce((result, _, index) => {
                    if (((shiftIndex < uuid && shiftIndex <= index && index <= uuid) ||
                        (shiftIndex > uuid && shiftIndex >= index && index >= uuid))) {
                        result.push(index);
                    }
                    return result;
                }, []);

                const finallData = new Set([...prev, ...selectedMails])
                return Array.from(finallData);
            });
        } else if (event?.target?.checked) {
            setSelectedEmails((prev) => [...prev, uuid])
            setShiftIndex(uuid)
        } else {
            setShiftIndex(0)
            setSelectedEmails(prev => prev.filter(item => item !== uuid))
        }
    }

    const handleSelectAll = (event: any) => {
        if (event?.target?.checked) {
            const selectedMails = emailsData?.originalData?.map((_, index) => index) // instead of returning index return uuid
            setSelectedEmails(selectedMails)
        } else {
            setSelectedEmails([])
        }
        setShiftIndex(0)
    }

    const loadMoreMails = React.useCallback(async (setIsFetching: any) => {
        const fetchEntryList = async () => {
            try {
                setIsFetching(true)
                const query = setAndGetQueryParams([{ key: 'limit', value: queryParams?.limit }, { key: 'offset', value: (queryParams?.offset + 25) }, { key: 'search', value: queryParams?.search }, { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' }, { key: 'filter', value: queryParams?.filter }])
                const response = await BaseApi.get(`/crm/pipeline/inbox/unmatched/list?${query}`);
                const responseData: any = response.data
                if (!responseData?.data) return setEmails(prev => ({ ...prev, originalData: [], count: 0 }))
                if (!response?.error) {
                    // if (responseData?.data?.length < 25) {
                    //     setEmails((prev) => ({ ...prev, originalData: [...prev?.originalData, ...responseData?.data], count: prev?.originalData?.length, }))
                    // } else {
                    setEmails((prev) => ({ ...prev, originalData: [...prev?.originalData, ...responseData?.data] }))
                    // }
                    setQueryParams((prev) => ({
                        ...prev,
                        offset: prev?.offset + 25
                    }))
                }
            } catch (error) {
                console.log(error)
            } finally {
                setIsFetching(false)
            }
        }

        emailsData?.originalData?.length && emailsData?.originalData?.length < emailsData?.count && fetchEntryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailsData?.originalData?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]);


    const handleClickEmail = (uuid: string) => {
        dispatch(setUnMatchedEmails({ selected_uuid: uuid }))
    }

    return (
        <div className=''>
            {/* Header */}
            <div className='px-1'>
                <div className='px-4 pt-4 pb-2 flex justify-between'>
                    <h4 className='font-bold'>Unmatched emails</h4>
                    <div className='flex gap-1 text-gray-800 items-center'><CiMail size={14} /><span className='text-[.625rem]'>{`${emailsData?.count} Email${emailsData?.count > 1 ? 's' : ''}`}</span></div>
                </div>
                <div className='flex'>
                    <SearchBox placeholder='Search' extraClassName='w-full' onChange={handleMailSearch} />
                    {
                        queryParams?.search &&
                        <CustomMenu open={isOpen} onOpen={() => setIsOpen(true)} onClose={handleClose} btnContent='' rightIcon={<IoFilterSharp />} btnClassName={` border-2 ${isOpen ? 'border-menuBorder' : 'border-gray-100'} rounded-md text-sm py-2 px-3.5 cursor-pointer my-2 mr-2`} menuListClassName={`border-2 w-[15.625rem] rounded-md ${isOpen ? 'border-menuBorder' : 'border-gray-100'} left-0 `}>

                            {
                                filter?.rules_type === 0 &&
                                <>
                                    <div className='p-2 border-b'>
                                        <TextInput label='From' placeholder='jay@joytechnologies' name='from' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                        <TextInput label='To' placeholder='Sam@hello.com' name='to' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                        <TextInput label='Domain' placeholder='google.com' name='domain' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                        <TextInput label='Subject' placeholder='subject' name='subject' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                        <TextInput label='Keywords' placeholder='keywords' name='keywords' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                        <TextInput label='Date' placeholder='-' name='date' onChange={(event: any) => handleInputChange('filter_search', event)} />
                                    </div>
                                    <div className='m-2 flex gap-2'>
                                        <Button name='Set rules' bg='white' color='gray-800' customClass='w-full transition-all duration-300' py='py-1.5' onClick={() => handleChangeStage(1)} />
                                        <Button name='Search' customClass='w-full transition-all duration-300' py='py-1.5' />
                                    </div>
                                </>
                            }

                            {
                                filter?.rules_type === 1 &&
                                <>
                                    <div className='p-3 border-b flex flex-col gap-3'>
                                        <div className='flex gap-1.5'><SparkleRadio checked={filter?.rules?.ignore} name='ignore' onChange={handleRadioChange} /> <div className='text-xs font-medium'>Ignore</div></div>
                                        <div className='flex gap-1.5'><SparkleRadio checked={filter?.rules?.match_campaign} name='match_campaign' onChange={handleRadioChange} /> <div className='text-xs font-medium'>Match to a campaign</div></div>
                                        <div className='flex gap-1.5'><SparkleRadio checked={filter?.rules?.new_oppertunity} name='new_oppertunity' onChange={handleRadioChange} /> <div className='text-xs font-medium'>Create a new opportunity</div></div>
                                        <div className='flex gap-1.5'><input type="checkbox"
                                            id={`select-all`}
                                            checked={filter?.rules?.apply_matched_results}
                                            name='apply_matched_results'
                                            onChange={(event: any) => handleRadioChange(event?.target?.checked, event?.target?.name)}
                                            className='size-3 accent-brand-500 dark:accent-purple-600 cursor-pointer mb-[.0625rem]' /> <div className='text-xs font-medium'>Also apply rules for matching results</div></div>
                                    </div>
                                    <div className='mx-3 my-2 flex gap-2'>
                                        <Button name='Set rules' customClass='w-full transition-all duration-300' py='py-1.5' onClick={handleSetRules} />
                                    </div>
                                </>
                            }

                            {
                                filter?.rules_type === 2 &&
                                <>
                                    <div className='py-2 pl-4 pr-2 border-b flex flex-col gap-2.5'>
                                        <div className='text-column flex gap-2'><BiArrowBack className=' cursor-pointer' onClick={() => handleChangeStage(1)} /> <h5 className='text-xs font-medium cursor-default'>Select ignore rule</h5></div>
                                        <div className='flex gap-1.5 border-b pb-1.5 border-gray-100'><SparkleRadio checked={filter?.rule_ignore?.once} name='once' onChange={handleChangeIgnoreRule} /> <div className='text-xs '>Ignore <span className='text-blueSecondary'>once</span></div></div>
                                        <div className='flex gap-1.5 border-b pb-1.5 border-gray-100'><SparkleRadio checked={filter?.rule_ignore?.entire_domain} name='entire_domain' onChange={handleChangeIgnoreRule} /> <div className='text-xs'>Ignore entire <span className='text-blueSecondary'>domain</span> for future</div></div>
                                        <div className='flex gap-1.5'><SparkleRadio checked={filter?.rule_ignore?.entire_email} name='entire_email' onChange={handleChangeIgnoreRule} /> <div className='text-xs'>Ignore entire <span className='text-blueSecondary'>email</span> for future</div></div>
                                    </div>
                                    <div className='mx-3 my-2 flex gap-2'>
                                        <Button name='Select ignore rules' customClass='w-full transition-all duration-300' py='py-1.5' />
                                    </div>
                                </>
                            }

                            {/* Match campaign */}
                            {
                                filter?.rules_type === 3 &&
                                <>
                                    <div className='flex flex-col gap-1.5'>
                                        <div className='text-column flex gap-2 mx-2 mt-2'><BiArrowBack className=' cursor-pointer' onClick={() => handleChangeStage(1)} /> <h5 className='text-xs font-medium cursor-default'>Check match from you company</h5></div>

                                        <div className='h-48 overflow-y-auto'>
                                            <div className='flex mt-1 px-2 mx-1'>
                                                <div className='flex gap-3 w-full'>
                                                    <div className='h-8 w-8 flex justify-center items-center p-1 rounded-full shadow-md'>
                                                        {profile?.photo ?
                                                            <img className=" w-6 h-6 rounded-full" src={''} alt="" /> :
                                                            <div className="bg-gray-100 w-6 h-6 flex items-center justify-center border rounded-full group-hover:bg-brand-50 transition-all duration-300">
                                                                <FaUser className={`text-sm duration-500 text-gray-400 `} />
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className='flex flex-col justify-center'>
                                                        <h6 className='capitalize font-semibold text-xs'>{profile?.name}</h6>
                                                        <p className='text-[10px] whitespace-nowrap text-column'>{profile?.campaign_name}</p>
                                                    </div>
                                                </div>
                                                <div className='w-full flex items-end flex-col gap-1.5 mt-0.5'>
                                                    <span className='text-[.625rem]'>{profile?.date}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='border-t-2 mt-2 border-dashed p-2 text-center text-[10px] font-bold'>
                                            Not able to find your match? <span className='text-blueSecondary underline select-none cursor-pointer' onClick={() => handleChangeStage(4)}>search here</span>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                filter?.rules_type === 4 &&
                                <>
                                    <div className='flex flex-col gap-1.5'>
                                        <div className='text-column flex items-center'><BiArrowBack className='ml-2 cursor-pointer' onClick={() => handleChangeStage(3)} /> <SearchBox placeholder='Search campaigns or oppotunities' extraClassName='w-full' inputClass='!text-xs font-normal' onChange={handleMatchCampaignSearch} /></div>
                                        <div className='h-44 flex justify-center items-center text-[10px] text-column'>
                                            no search
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                filter?.rules_type === 5 &&
                                <>
                                    <div className='p-2 border-b flex flex-col'>
                                        <div className='text-column flex gap-2'><BiArrowBack className=' cursor-pointer' onClick={() => handleChangeStage(1)} /> <h5 className='text-xs font-medium cursor-default'>Create new opportunity</h5></div>
                                        <TextInput label='Name*' placeholder='jay@joytechnologies' name='name' onChange={(event: any) => handleInputChange('rule_new_oppertunity', event)} />
                                        <TextInput label='Email ID*' placeholder='hello@joytechnologies' name='email' onChange={(event: any) => handleInputChange('rule_new_oppertunity', event)} />
                                        <TextInput label='Company name*' placeholder='hello@joytechnologies' name='company_name' onChange={(event: any) => handleInputChange('rule_new_oppertunity', event)} />
                                        <button className='px-2 py-0.5 mt-2 mb-1 ml-1 select-none rounded-full bg-lightGray text-[.5625rem] w-fit flex gap-0.5 items-center'><span>Add new feilds</span><IoMdAdd /></button>
                                    </div>
                                    <div className='mx-3 my-2 flex gap-2'>
                                        <Button name='Create new opportunity' customClass='w-full transition-all duration-300' py='py-1.5' />
                                    </div>
                                </>
                            }


                        </CustomMenu>
                    }
                </div>
            </div>
            {/* Body */}
            <div>
                {/* Head */}
                <div className='flex p-2'>
                    <div className='flex gap-1 items-center w-full pl-1.5 h-5'>
                        <input type="checkbox"
                            id={`select-all`}
                            checked={emailsData?.originalData?.length && emailsData?.originalData?.length === selectedEmails?.length}
                            className='size-3 accent-brand-500 dark:accent-purple-600 cursor-pointer mb-[.0625rem]'
                            onChange={handleSelectAll}
                        />
                        <span className='text-xs text-column'>Select All</span>
                        {selectedEmails?.length > 0 && <div className='px-1.5 py-0.5 ml-1 rounded-full text-[.6875rem] bg-bglightSkyBlue text-textDarkBlue'>{selectedEmails?.length} Selected</div>}
                    </div>
                    <div className='w-full flex justify-end'>
                        {/* <div className='flex gap-1.5 items-center px-2 border-r'><HiOutlineSwitchVertical className='text-gray-700' size={12} /><span className='text-[.625rem] text-column'>Latest</span></div> */}
                        <div className='px-2'><BsThreeDotsVertical size={15} className='text-column' /></div>
                    </div>
                </div>
                {emailsData?.originalData?.length > 0 && emailsData?.originalData?.length === selectedEmails?.length && selectedEmails?.length !== emailsData?.count && <SelectAllContent currentCount={emailsData?.originalData?.length} totalCount={emailsData?.count} recordName={'emails'} featureName={'inbox'} onClick={() => console.log('clicked')} />}
                {/* Mails */}
                <div className='border-t mt-1 h-[31.25rem] overflow-y-auto'>
                    {
                        !emailsData?.loading ? emailsData?.originalData?.map((profile: any, index) => {
                            const selected = selectedEmails.includes(index)
                            const dateAndTime = moment(profile?.mail_data?.date).format("MMM DD, hh:mm A")
                            const firstName = profile?.mail_data.from.address?.split('@')
                            const profileName = profile?.mail_data.from.name || firstName[0]
                            const active = profile?.uuid === unmatched_emails?.selected_uuid || selected
                            return (
                                <div className='border-b w-full' key={index}>
                                    <div
                                        //   onClick={()=>handleProspectClick(index)}
                                        className={`group flex rounded-[.3125rem] duration-500 gap-1 m-2 py-2 cursor-pointer lg:cursor-pointer ${active ? 'bg-hover' : 'hover:bg-hover'}`} onClick={() => handleClickEmail(profile?.uuid)}
                                    >
                                        {/* Profile picture */}
                                        <div className='w-2/12 flex flex-col gap-3 items-center'>
                                            <div className="relative">
                                                {profile?.photo ?
                                                    <img className="w-10 h-10 rounded-full" src={profile?.photo} alt="" /> :
                                                    <div className={`w-10 h-10 flex items-center justify-center border rounded-full group-hover:bg-brand-50 ${active ? 'bg-brand-50' : 'bg-gray-100'} transition-all duration-300`}>
                                                        <FaUser className={`text-lg duration-500 text-gray-400`} />
                                                    </div>
                                                }
                                            </div>
                                            <input type="checkbox"
                                                id={`select-${index}`}
                                                checked={selected}
                                                // disabled={}
                                                onClick={(event) => event?.stopPropagation()}
                                                onChange={(event) => handleSelect(event, index)}
                                                className='size-3 accent-brand-500 dark:accent-purple-600 cursor-pointer' />
                                        </div>

                                        {/* Profile details */}
                                        <div className="w-full">
                                            <div className="flex w-full items-center gap-1.5 p-1">
                                                <h1 id={`preview-user-${profile?.name}`} className={`text-xs w-full  duration-500 font-bold capitalize text-gray-800 dark:text-white`}>
                                                    {profileName}
                                                </h1>
                                                <h4 className="text-[.625rem] w-2/5 text-gray-800">{dateAndTime}</h4>
                                            </div>
                                            <h1 id={`preview-subject-${profile?.receiver_name}`} className={`px-1 w-[17.5rem] truncate text-xs duration-500 font-bold text-gray-800 dark:text-white`}>
                                                {profile?.mail_data?.subject}
                                            </h1>
                                            <p className="group-hover:block text-xs font-normal tracking-normal !mt-2 !pl-1 text-navy-700 dark:text-white">
                                                {profile?.mail_data?.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                            :
                            <>
                                <BoxSkeleton />
                                <BoxSkeleton />
                                <BoxSkeleton />
                                <BoxSkeleton />
                            </>
                    }
                    <InfiniteScrollComponent loadMore={loadMoreMails} loader={<BoxSkeleton />} />
                </div>
            </div>
        </div>
    )
}

export default LeftSideView