import React from 'react';
import PaymentHistory from './paymentfeature.tsx/PaymentHistory';

const PaymentAndBilling = () => {
  return (
    <div>
      {/* <CardSection/> */}
      <PaymentHistory />
    </div>
  );
};

export default PaymentAndBilling;
