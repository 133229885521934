// import CustomFilter from 'components/customFilter';
import React from 'react';
// import { FiSearch } from 'react-icons/fi';
// import { MdAdd } from 'react-icons/md';
import UserManagementTable from './user-management-table';
import WorkspaceManagementTable from './workspace-management-table';
// import Button from 'common/sparkle-common/Button';
import RoleManagementTable from './role-management-table';
import { SessionStorage } from 'common/api/common/storage';
import { useAppSelector } from 'store';
import { checkRolePermissionStatus } from 'common/utils/utility';

const Team = () => {
  // const [openPopUP, setOpenPopUp] = React.useState(false);

  const [switchState, setSwitchState] = React.useState(parseInt(SessionStorage.getString('Active_tab')));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addBtn, setAddBtn] = React.useState('Add User');

  const ActiveTab = SessionStorage.getString('Active_tab');

  const handleSwitchState = (state: number) => {
    SessionStorage.setString('Active_tab', `${state}`);
    setSwitchState(state);
    setAddBtn(state === 1 ? 'Add User' : state === 2 ? 'Add New Role' : 'Add Workspace');
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState(null);
  const [workspaceStatus, setworkspaceStatus] = React.useState(null);

  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'role_management');

      setRoleStatus(status);

      const userstatus = await checkRolePermissionStatus(userRolePermissionListStoreData, 'user_management');
      setUserStatus(userstatus);

      const workspacestatus = await checkRolePermissionStatus(userRolePermissionListStoreData, 'workspace_management');
      setworkspaceStatus(workspacestatus);

      // !ActiveTab &&
      setSwitchState(
        parseInt(ActiveTab) || (workspacestatus?.view || workspaceStatus?.is_owner ? 3 : userstatus?.view || userstatus?.is_owner ? 1 : status?.view || status?.is_owner ? 2 : 0)
      );
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-[82vh] overflow-y-auto ">
      <div className="sticky top-[-140px] mt-[8px] w-full rounded-tl-lg rounded-tr-lg bg-white py-2 dark:!border-darkBorder dark:!bg-darkOne dark:!text-white">
        {/* <h1 className="mx-8 my-1 pt-4 text-2xl font-medium">Team Personnel</h1>
        <p className="mx-8 my-1 text-xs font-thin leading-relaxed tracking-wide text-column">
          Invite other users to your team to share your credits and recipients,
          unlock team CRM features, share important data and templates, manage
          permissions <br />
          and analyze each member's input.{' '}
          <a href="/settings" className="text-blue-500">
            Find out more.
          </a>
        </p> */}

        <div className=" ml-4 mt-0  flex gap-5 text-sm font-semibold">
          {(userStatus?.is_owner || userStatus?.view) && (
            <div className="user-management-nav-btn relative cursor-pointer text-center" id='user-management-tab' onClick={() => handleSwitchState(1)}>
              <h4 className={`mb-2 ${switchState === 1 ? '' : 'text-gray-700'}`}>User Management</h4>
              <div className={`absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 1 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
          {(roleStatus?.is_owner || roleStatus?.view) && (
            <div className="role-management-nav-btn relative cursor-pointer text-center" id='role-management-tab' onClick={() => handleSwitchState(2)}>
              <h4 className={`mb-2 ${switchState === 2 ? '' : 'text-gray-700'}`}>Role Management</h4>
              <div className={`absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 2 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
          {(workspaceStatus?.is_owner || workspaceStatus?.view) && (
            <div className="workspace-management-nav-btn relative cursor-pointer text-center" id='workspace-management-tab' onClick={() => handleSwitchState(3)}>
              <h4 className={`mb-2 ${switchState === 3 ? '' : 'text-gray-700'}`}>Workspace Management</h4>
              <div className={`dark:bg- absolute bottom-0 h-[.1600rem] w-full rounded-tl-md rounded-tr-md bg-navBlue ${switchState === 3 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
        </div>
        {/* <hr /> */}
        <hr className="dark:border-gray-800" />
      </div>
      {switchState === 1 && <UserManagementTable />}
      {switchState === 2 && <RoleManagementTable />}
      {switchState === 3 && <WorkspaceManagementTable />}
    </div>
  );
};

export default Team;
