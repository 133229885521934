/* eslint-disable @typescript-eslint/no-unused-vars */
import ProfileDetails from 'components/crm/ProfileDetails';
import QuickAction from 'components/crm/QuickAction';
import Statistics from 'components/crm/Statistics';
import WorkEducation from 'components/crm/WorkEducation';
import WindowsSvg from 'assets/svg/windows.svg';
import OpportunityDetails from 'components/crm/OpportunityDetails';
import CompanyDetails from 'components/crm/CompanyDetails';
import CompanyMutualContacts from 'components/crm/CompanyMutualContacts';
import React, { useEffect, useState } from 'react';
import ModalView from './ModalView';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setOpportunityInboxViewData, setProspectDetails } from 'store/crm';
import { useLocation } from 'react-router-dom';

const Inbox = () => {
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const dispatch = useAppDispatch();
  const { brand_id, opportunity_domain, opportunity_id } = opportunityInboxViewData;
  const [loading, setLoading] = useState(false);
  const [mutualContactData, setMutualContactData] = useState([]);
  const location = useLocation();
  const opportunityIdFromQuery = new URLSearchParams(location.search).get('opportunityid');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const workDetails = {
    title: 'Work',
    viewMore: 'View full work experience',
    viewLess: 'View less work experience',

    details: [
      {
        title: 'Chairman and CEO',
        duration: 'Sep 2023 - Present · 9 mos',
        icon: WindowsSvg,
        iconText: 'Microsoft',
      },
      {
        title: 'Senior Product Designer',
        duration: 'Sep 2021 - Aug 2023 · 2 yrs',
        icon: WindowsSvg,
        iconText: 'Verloop.io',
      },
      {
        title: 'Product Designer',
        duration: 'Aug 2021 - Aug 2023 · 2 yrs 1 mo',
        icon: WindowsSvg,
        iconText: 'Perks.tech',
      },
      {
        title: 'Chairman and CEO -1',
        duration: 'Sep 2023 - Present · 9 mos',
        icon: WindowsSvg,
        iconText: 'Microsoft',
      },
      {
        title: 'Senior Product Designer-2',
        duration: 'Sep 2021 - Aug 2023 · 2 yrs',
        icon: WindowsSvg,
        iconText: 'Verloop.io',
      },
    ],
  };

  const educationDetails = {
    title: 'Education',
    viewMore: 'View full education details ',
    viewLess: 'View less education details',
    details: [
      {
        title: 'Executive MBA, International',
        duration: 'Sep 2023 - Present · 9 mos',
        icon: WindowsSvg,
        iconText: 'Microsoft',
      },
      {
        title: 'MBA, Marketing & Sales',
        duration: 'Sep 2021 - Aug 2023 · 2 yrs',
        icon: WindowsSvg,
        iconText: 'Verloop.io',
      },
      {
        title: 'Product Designer',
        duration: 'Aug 2021 - Aug 2023 · 2 yrs 1 mo',
        icon: WindowsSvg,
        iconText: 'Perks.tech',
      },
      {
        title: 'BA literature',
        duration: 'Sep 2023 - Present · 9 mos',
        icon: WindowsSvg,
        iconText: 'Microsoft',
      },
      {
        title: 'Senior Product Designer 12',
        duration: 'Sep 2021 - Aug 2023 · 2 yrs',
        icon: WindowsSvg,
        iconText: 'Verloop.io',
      },
    ],
  };

  const fetchOpportunityData = async (isMounted: Boolean) => {
    try {
      if (!opportunity_id) {
        const response = await BaseApi.get(`/crm/pipeline/opportunity/single/${opportunityIdFromQuery}`);
        if (response?.error) {
          toast.error('Failed to fetch opportunity data');
        }
        if (isMounted && response?.data?.length) {
          const payload = {
            opportunity_id: response?.data?.[0]?.uuid,
            opportunity_domain: response?.data?.[0]?.opportunity[0]?.prospect_email_domain,
            brand_id: response?.data?.[0]?.brand_id,
            pipeline_id: response?.data?.[0]?.pipeline_id,
            prospect_enrich_details: response?.data?.[0]?.prospect_enrich_details?.[0],
          };
          dispatch(setOpportunityInboxViewData(payload));
          dispatch(setProspectDetails(response?.data?.[0]));
        }
      }
    } catch (error) {
      toast.error('Failed to fetch opportunity data');
      console.log(error);
    }
  };

  const fetchMutualContact = async (isMounted: boolean) => {
    try {
      setLoading(true);
      if (opportunity_id) {
        const response = await BaseApi.get(`/crm/pipeline/opportunity/get/mail/contacts/${brand_id}/${opportunity_id}?email_domain=${opportunity_domain || 'gmail.com'}`);
        if (response?.error) {
          toast.error('Failed to fetch contacts');
        }
        if (isMounted) {
          setMutualContactData(response.data);
        }
      }
    } catch (error) {
      toast.error('Failed to fetch contacts');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    fetchMutualContact(isMounted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity_id]);

  useEffect(() => {
    let isMounted = true;
    fetchOpportunityData(isMounted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity_id, opportunityIdFromQuery]);

  const handleNavigateToTab = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={`mt-2.5 h-[calc(100vh-68px)] w-full cursor-default overflow-y-auto rounded-lg bg-white pb-2 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
      <div className="noscroll flex w-full">
        <div className="flex h-[calc(100vh-76px)] w-[30%] flex-col gap-3 overflow-y-auto px-3 pt-2.5">
          <ProfileDetails loading={loading} />
          <QuickAction handleNavigateToTab={handleNavigateToTab} />
          {/* <WorkEducation data={workDetails} /> */}
          {/* <WorkEducation data={educationDetails} /> */}
          {/* <Statistics /> */}
        </div>
        <ModalView selectedIndex={selectedIndex} handleNavigateToTab={handleNavigateToTab} />
        <div className="flex h-[calc(100vh-76px)] w-[30%] flex-col gap-3 overflow-hidden overflow-y-scroll  px-3 pt-3">
          <OpportunityDetails />
          {/* <CompanyDetails /> */}
          <CompanyMutualContacts mutualContactData={mutualContactData} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default Inbox;
