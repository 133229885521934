// const statusText = getStatusText('campaign', item?.campaign_status);

import moment from 'moment';
import { BsSend } from 'react-icons/bs';

// const badgeColor = getStatusBadgeColor('campaign', item?.campaign_status);
const SenderAccountSearchData = (props: any) => {
  const { data, handleNavigate } = props;
  return (
    <div>
      {data?.map((item: any) => {
        return (
          <>
            <div className="my-2 cursor-pointer rounded-l p-2  hover:bg-lightpurplebg " onClick={() => handleNavigate(item?.url)}>
              <div className=" flex justify-between  gap-1">
                {item?.picture ? <img src={item?.picture} alt={'profile'} className="h-8 w-8 rounded-full" /> : <BsSend className="self-center  text-heading" size={28} />}

                <div className=" flex flex-col  text-[13px]">
                  <p className="m-0 w-48  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading  ">{item?.email}</p>
                  <p className="m-0 w-48  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{item?.full_name}</p>
                </div>
                <div className="flex flex-col text-[13px] ">
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">{item?.variant}</p>
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{moment(item?.cdate).format('D MMM YYYY')}</p>
                </div>
              </div>
            </div>
            <hr className=" w-full" />
          </>
        );
      })}
    </div>
  );
};
//   {/* {item?.picture ? <img src={item?.picture} alt={'profile'} className="h-8 w-8 rounded-full" /> : <BsSend className="self-center" size={28} />} */}
export default SenderAccountSearchData;
