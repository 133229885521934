import { createSlice } from '@reduxjs/toolkit';
import {
  brand_inputs,
  finalSettingsData,
  ids,
  loadGroupsData,
  prospects,
  prospects_verification,
  reviewState,
  scheduleData,
  scheduleSettingsData,
  senderListData,
  sequenceData,
  settingData,
  stopReplyToData,
  trackingOptionsData,
} from 'common/dto/initial-value/store/campaignv2';
// import { searchProspectsToolLists } from 'common/dto/initial-value/views/campaign-v2'
import { campaignsStoreState, FilterProspectType } from 'common/dto/types/views/campaignv2';
import { generateUUID, getDeliveryDataPattern } from 'common/utils/utility';

const initialState: campaignsStoreState = {
  uuid: '',
  editLoading: false,
  apiPayload: {},
  current_sequence: 0,
  current_sequence_id: '',
  current_version: 'A',
  campaign_name: '',
  scale: '100',
  header_count: 1,
  ids: ids,
  brand_input: brand_inputs,
  verified_prospects_list: [],
  prospects: prospects,
  prospects_verification,
  sequence: [],
  variable_area: 'subject',
  createSequenceModal: false,
  versionAB: false,
  settings: settingData,
  scheduleSettings: scheduleSettingsData,
  schedule: scheduleData,
  schedule_list: [],
  finalSettings: finalSettingsData,
  senderList: senderListData,
  loadGroups: loadGroupsData,
  groupList: [],
  stop_reply_to: stopReplyToData,
  tracking_options: trackingOptionsData,
  review: reviewState,
  edit: false,
};
// Upon completion this should be moved to Campaign-V2 initial values

const campaignv2Slice = createSlice({
  name: 'campaignv2',
  initialState,
  reducers: {
    setUUID: (state, action) => {
      state.uuid = action.payload;
    },
    setCurrentSequence: (state, action) => {
      state.current_sequence = action.payload;
    },
    setCurrentSequenceID: (state, action) => {
      state.current_sequence_id = action.payload;
    },
    setCurrentVersion: (state, action) => {
      state.current_version = action.payload;
    },
    // Campaign Name
    setCampaignName: (state, action) => {
      state.campaign_name = action.payload;
    },
    // Header Count
    setHeaderCount: (state, action) => {
      state.header_count = action.payload;
    },
    // UUID's
    setIds: (state, action) => {
      state.ids = { ...state.ids, ...action.payload };
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    // Brand Input
    setBrandInput: (state, action) => {
      state.brand_input = { ...state.brand_input, ...action.payload };
    },
    removeBrandInput: (state) => {
      state.brand_input = brand_inputs;
    },
    // Prospect
    setProspects: (state, action) => {
      const data = action.payload;
      if (data?.prospects_list?.length > 0) {
        const prospectsList = [...data?.prospects_list];
        const list = prospectsList?.sort((a: any, b: any) => {
          const aHasNull = Object.values(a).some((value) => value === '');
          const bHasNull = Object.values(b).some((value) => value === '');
          if (aHasNull && !bHasNull) return -1;
          if (!aHasNull && bHasNull) return 1;
          return 0;
        });
        state.prospects = { ...state.prospects, prospects_list: list };
      } else {
        state.prospects = { ...state.prospects, ...action.payload };
      }
    },
    setVerificationToProspects: (state) => {
      state.prospects.prospects_list = state.prospects?.prospects_list.map((item) => {
        return { ...item, verification_status: '' };
      });
    },
    setVerifiedProspects: (state, action) => {
      state.verified_prospects_list = action.payload;
    },
    setEnrichment: (state, action) => {
      state.prospects.enrichment = { ...state.prospects.enrichment, ...action.payload };
    },
    setVariableList: (state, action) => {
      state.prospects.variable_list = [...action.payload];
    },
    insertVariable: (state, action) => {
      const section = action.payload;
      const id = state?.sequence.findIndex((item) => item.id === state.current_sequence_id);
      const version = state.current_version === 'A' ? 'version_a' : 'version_b';
      state.sequence[id].delivery_data[version][state.variable_area] += section;
    },
    setProspectsVariable: (state, action) => {
      state.prospects.selected_variable = { ...state.prospects.selected_variable, ...action.payload };
    },
    setDuplicatesCondition: (state, action) => {
      state.prospects.manage_duplicate = { ...state.prospects.manage_duplicate, ...action.payload };
    },

    updateVariableData: (state, action) => {
      state.prospects.update_variables = { ...state.prospects.update_variables, ...action.payload };
    },
    updatePreviewProspectsData: (state, action) => {
      state.prospects.preview = { ...state.prospects.preview, ...action.payload };
    },
    setSimilarEmailModal: (state, action) => {
      state.prospects.similarEmailModal = { ...state.prospects.similarEmailModal, ...action.payload };
    },
    // Search prospects
    setSearchProspectsTool: (state, action) => {
      state.prospects.search_prospects.tool_details = { ...state.prospects.search_prospects.tool_details, ...action.payload };
    },
    setSearchType: (state, action) => {
      state.prospects.search_prospects.prospects_filter.search_type = action.payload;
    },
    setFilterData: (state, action) => {
      const { key1, key2, key3, data }: { key1: keyof FilterProspectType; key2: any; key3: any; data: FilterProspectType } = action?.payload;
      if (key2) {
        if (key3) {
          state.prospects.search_prospects.prospects_filter.filter_data[key1][key2][key3] = data;
        } else {
          state.prospects.search_prospects.prospects_filter.filter_data[key1][key2] = data;
        }
      } else {
        state.prospects.search_prospects.prospects_filter.filter_data[key1] = data;
      }
    },
    cleanSearchProspectsFilterData: (state) => {
      state.prospects.search_prospects.prospects_filter.filter_data = {
        // Name
        people_name: '',
        // Job Titles
        job_titles: {
          view: {
            is_not_any_of: false,
            include_past_job_titles: false,
          },
          selected_method: 'is_any_of',
          is_any_of_data: {
            job_titles: [],
            is_not_any_of: [],
            include_past_job_titles: [],
          },
          management_level: [],
          department_job_function: [],
        },
        // Companies
        companies: {
          view: {
            is_not_any_of: false,
            include_past_companies: false,
          },
          selected_method: 'is_any_of',
          is_any_of_data: {
            companies: [],
            is_not_any_of: [],
            include_past_companies: [],
          },
          include_list: '',
          exclude_list: '',
          include_list_id: '',
          exclude_list_id: '',
        },
        // Location
        location: {
          view: {
            exclude_location: false,
            range_view: false,
          },
          selected_method: 'region',
          region: {
            include: [],
            exclude: [],
          },
          zip_code: {
            zip: '',
            range: '50',
          },
        },
        // Employees
        employees: {
          selected_method: 'predefined_range',
          predefined_range: [],
          custom_range: {
            min: '',
            max: '',
          },
        },
        // Industry & keywords
        industry_keywords: {
          view: {
            include: false,
            include_all: false,
            exclude_keywords: false,
          },
          selected_method: 'is_not_any_of',
          industries: '',
          is_not_any_of_data: '',
          company_keywords: {
            include: '',
            include_all: '',
            exclude: '',
          },
        },
        // Technologies
        technologies: {
          include: [],
          include_all: [],
        },
        // Revenue
        revenue: {
          selected_method: 'is_between',
          is_between: {
            min: '',
            max: '',
          },
          private_company: false,
          public_company: false,
        },
        // Funding
        funding: {
          funding_data: [],
          selected_method: 'is_between',
          last_funding_round_date: {
            from: '',
            to: '',
          },
          last_funding_round_amount: {
            min: '',
            max: '',
          },
        },
        // Job Posting
        job_posting: {
          job_titles: '',
          locations: '',
          active_job_posting: {
            min: '',
            max: '',
          },
          job_posted_at: {
            from: '',
            to: '',
          },
        },
      };
      state.prospects.search_prospects.data = [];
    },
    setSearchProspectsData: (state, action) => {
      state.prospects.search_prospects.data = action.payload;
    },
    setSingleProspectsData: (state, action) => {
      const data = action.payload;
      state.prospects.search_prospects.data =
        state.prospects.search_prospects.data?.map((item) => {
          const enrichedItem = data.find((element: any) => element?.id === item?.id);
          return enrichedItem ? { ...item, email: enrichedItem.email } : item;
        }) || [];
    },
    setSelectedProspectsData: (state, action) => {
      state.prospects.search_prospects.selected_prospects = action.payload;
    },
    setSearchProspectsTemproryId: (state, action) => {
      state.prospects.search_prospects.temprory_id = action.payload;
    },
    setSelectedProspectsCountData: (state, action) => {
      state.prospects.search_prospects.users_count = { ...state.prospects.search_prospects.users_count, ...action.payload };
    },
    setTotalProspectsCount: (state, action) => {
      state.prospects.search_prospects.total_records = action.payload;
    },
    // Email Verify
    setProspectsVerification: (state, action) => {
      state.prospects_verification = { ...state.prospects_verification, ...action.payload };
    },
    // Sequence
    setCreateSequenceModal: (state) => {
      state.createSequenceModal = !state.createSequenceModal;
    },
    setSequence: (state, action) => {
      state.sequence = action.payload;
    },
    addSequence: (state, action) => {
      const { channel, channelAction, trigger } = action.payload;
      const data = state.sequence.slice();
      const id = generateUUID();
      const sequenceIndex = data.findIndex((item: any) => item.id === state?.current_sequence_id);
      // const parentTriggerIndex = data.findIndex((item: any) => item.trigger?.yes || item.trigger?.no === sequenceIndex)
      if (!trigger) {
        if (!state.current_sequence_id) {
          data.splice(0, 0, {
            ...sequenceData,
            id,
            type: channel,
            channelAction,
            ...(channel === 'linkedin' && { pause_sequence: false }),
            ...getDeliveryDataPattern(channelAction),
            next: data[0]?.id || null,
            delay: { days: 0 },
          });
        } else {
          const { next, trigger } = data[sequenceIndex];
          data[sequenceIndex]?.type && (data[sequenceIndex] = { ...data[sequenceIndex], next: id, trigger: null });
          data[sequenceIndex]?.type
            ? data.splice(sequenceIndex + 1, 0, {
                ...sequenceData,
                id,
                ...getDeliveryDataPattern(channelAction),
                type: channel,
                channelAction,
                ...(channel === 'linkedin' && { pause_sequence: false }),
                next,
                trigger,
              })
            : (data[sequenceIndex] = {
                ...sequenceData,
                ...data[sequenceIndex],
                ...getDeliveryDataPattern(channelAction),
                type: channel,
                channelAction,
                ...(channel === 'linkedin' && { pause_sequence: false }),
              });
        }
      }
      if (trigger) {
        data[sequenceIndex].trigger = {
          yes: `${id}-yes`,
          no: `${id}-no`,
          trigger_type: trigger,
          trigger_variant: channel,
          trigger_waiting_period: { days: 1 },
        };
        data[sequenceIndex].type
          ? data.splice(sequenceIndex + 1, 0, { id: `${id}-yes`, trigger_on: 'yes' }, { id: `${id}-no`, trigger_on: 'no' })
          : (data[sequenceIndex] = { ...data[sequenceIndex], type: channel });
      }

      state.sequence = data;
    },
    duplicateSequence: (state, action) => {
      const id = state?.sequence?.findIndex((item) => item.id === action.payload);
      const contentId = generateUUID();
      const data = state.sequence;
      data.splice(id + 1, 0, { ...data[id], trigger_on: null, id: contentId });
      data[id].next = contentId;
      data[id].trigger = null;
    },
    removeSequence: (state, action) => {
      const id = state?.sequence?.findIndex((item) => item.id === action.payload);
      let sequence = state.sequence;

      if (!sequence[id]?.trigger_on) {
        const parentId = sequence.findIndex((item) => item.next === action.payload);
        if (parentId >= 0) {
          sequence[parentId].next = '';
          sequence[id].next && (sequence[parentId].next = sequence[id].next);
          sequence[id].trigger && (sequence[parentId].trigger = sequence[id].trigger);
          sequence.splice(id, 1);
        } else {
          sequence[0]?.trigger ? sequence.splice(0) : sequence.splice(id, 1);
        }
      } else {
        sequence[id] = { id: sequence[id].id, trigger_on: sequence[id].trigger_on, type: '' };
      }
    },
    removeTriggers: (state, action) => {
      const { id, list } = action.payload;
      const targetId = state?.sequence?.findIndex((item) => item.id === id);
      state.sequence[targetId].trigger = null;
      state.sequence = state?.sequence?.filter((item: any) => !list.includes(item.id));
    },
    setVersionAB: (state, action) => {
      const id = state?.sequence.findIndex((item) => item.id === action.payload);
      const data = state.sequence.slice();
      data[id].versionAB = !data[id].versionAB;
    },
    clearVersionAB: (state, action) => {
      const { id, version } = action.payload;
      const targetId = state?.sequence?.findIndex((item) => item.id === id);
      console.log(targetId);
      const data = state?.sequence;
      version === 'A' && (data[targetId].delivery_data.version_a.subject = data[targetId].delivery_data.version_b.subject);
      version === 'A' && (data[targetId].delivery_data.version_a.message = data[targetId].delivery_data.version_b.message);
      data[targetId].delivery_data.version_b.subject = '';
      data[targetId].delivery_data.version_b.message = '';
      data[targetId].versionAB = false;
    },
    setVariableArea: (state, action) => {
      state.variable_area = action.payload;
    },
    setMessageData: (state, action) => {
      const { input, value } = action.payload;
      const id = state?.sequence.findIndex((item) => item.id === state?.current_sequence_id);
      const key = input === 'subject' ? 'subject' : 'message';
      const version = state?.current_version === 'A' ? 'version_a' : 'version_b';
      const data = state?.sequence;
      data[id].delivery_data[version][key] = value;
    },
    handleDelayCount: (state, action) => {
      const { id, operation, value, trigger } = action.payload;
      if (trigger) {
        operation === '=' && value >= 0 && value < 99 && (state.sequence[id].trigger.trigger_waiting_period.days = value);
        operation === '+' && state.sequence[id].trigger.trigger_waiting_period.days < 99 && (state.sequence[id].trigger.trigger_waiting_period.days += 1);
        operation === '-' && state.sequence[id].trigger.trigger_waiting_period.days && (state.sequence[id].trigger.trigger_waiting_period.days -= 1);
      } else {
        operation === '=' && value >= 0 && value < 99 && (state.sequence[id].delay.days = value);
        operation === '+' && state.sequence[id].delay.days < 99 && (state.sequence[id].delay.days += 1);
        operation === '-' && state.sequence[id].delay.days && (state.sequence[id].delay.days -= 1);
      }
    },
    handleToggleMenu: (state, action) => {
      const { targetId, condition, key }: { targetId: string; condition: boolean; key: 'delayModal' | 'triggerModal' | 'deleteContentMenu' | 'deleteTriggerMenu' } = action.payload;
      const id = state?.sequence.findIndex((item) => item.id === targetId);
      const data = state.sequence.slice();
      if (typeof condition === 'boolean') {
        data[id][key] = condition;
        return;
      }
      data[id][key] = !data[id][key];
    },
    setScale: (state, action) => {
      state.scale = action.payload;
    },
    setReplyData: (state, action) => {
      const key: keyof typeof state.stop_reply_to = action.payload;
      state.stop_reply_to[key] = !state.stop_reply_to[key];
    },
    setTrackingData: (state, action) => {
      const key: keyof typeof state.tracking_options = action.payload;
      state.tracking_options[key] = !state.tracking_options[key];
    },
    setHolidays: (state, action) => {
      const data = action.payload;
      state.settings.skip_holidays = { ...state.settings.skip_holidays, ...data };
    },
    // Settings
    setSettingsData: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setSelectedSenderList: (state, action) => {
      state.settings.selectedSenderList = { ...state.settings.selectedSenderList, ...action.payload };
    },
    setSelectedGroupList: (state, action) => {
      state.settings.selectedGroupList = { ...state.settings.selectedGroupList, ...action.payload };
    },
    removeSelectedGroupList: (state) => {
      state.settings.selectedGroupList = {};
      state.settings.selectedSenderList = {};
      // state.groupList = [];
      // state.senderList = [];
    },

    setSendingPriority: (state, action) => {
      state.settings.sending_priority = { ...state.settings.sending_priority, ...action.payload };
    },
    setFinalSettings: (state, action) => {
      state.finalSettings = { ...state.finalSettings, ...action.payload };
    },
    deleteSignature: (state, action) => {
      state.finalSettings.unsubscribeAndSignatureData.signature_list = state.finalSettings.unsubscribeAndSignatureData.signature_list.filter(
        (item: any) => item.uuid !== action.payload
      );
    },

    deleteCustomUnsubscribe: (state, action) => {
      state.finalSettings.unsubscribeAndSignatureData.unsubscribe_list = state.finalSettings.unsubscribeAndSignatureData.unsubscribe_list.filter(
        (item: any) => item.uuid !== action.payload
      );
    },
    setSenderList: (state, action) => {
      state.senderList = action.payload;
    },
    addSenderList: (state, action) => {
      state.senderList = [...state.senderList, ...action.payload];
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    addGroupList: (state, action) => {
      state.groupList = [...state.groupList, ...action.payload];
    },
    setSelectSenders: (state, action) => {
      const id = action.payload;
      state.senderList[id].selected = !state.senderList[id].selected;
    },
    setEmailAlias: (state, action) => {
      state.settings = { ...state.settings, email_alias: action.payload };
    },
    setSelectGroups: (state, action) => {
      const id = action.payload;
      state.groupList[id].selected = !state.groupList[id].selected;
    },
    setLoadGroupsData: (state, action) => {
      const key: keyof typeof loadGroupsData = action.payload;
      state.loadGroups[key] = !state.loadGroups[key];
    },
    selectAllSenders: (state, action) => {
      const condition = action.payload;
      const data = state.senderList.slice();
      data.map((item) => (item.selected = condition));
      state.senderList = data;
    },
    setCampaignPeriod: (state, action) => {
      const { key, value } = action.payload;
      state.scheduleSettings[key] = value;
    },
    setCurrentSchedule: (state, action) => {
      const id = action.payload;
      state.schedule = state.schedule_list[id];
    },
    setScheduleList: (state, action) => {
      const data = action.payload;
      const scheduleList = data.map((item: any) => {
        return {
          uuid: item.uuid,
          name: item.name,
          days: {
            mon: item.days.includes('mon'),
            tue: item.days.includes('tue'),
            wed: item.days.includes('wed'),
            thu: item.days.includes('thu'),
            fri: item.days.includes('fri'),
            sat: item.days.includes('sat'),
            sun: item.days.includes('sun'),
          },
          time_zone: item.time_zone,
          start_time: item.start_time,
          end_time: item.end_time,
        };
      });
      state.schedule_list = scheduleList;
    },
    setSchedule: (state, action) => {
      const { key, value } = action.payload;
      state.schedule = { ...state.schedule, [key]: value };
    },
    setCurrentScheduleID: (state, action) => {
      state.scheduleSettings.schedule_id = action.payload;
    },
    clearSchedule: (state) => {
      state.schedule = scheduleData;
    },
    setDays: (state, action) => {
      const key = action.payload;
      state.schedule.days[key] = !state.schedule.days[key];
    },
    setTime: (state, action) => {
      const { obj, key, value } = action.payload;
      if (value.length > 2) return;
      state.schedule[obj][key] = value?.padStart(2, 0);
    },
    setApiPayload: (state) => {
      state.header_count === 4 &&
        (state.apiPayload = {
          sequence_data: {
            sequence_id: generateUUID(),
            sequence_list: state?.sequence,
          },
        });

      state.header_count === 4 && console.log('Api called for 4');
    },
    setReview: (state, action) => {
      state.review = { ...state.review, ...action.payload };
    },
    setPreview: (state, action) => {
      state.review.preview = { ...state.review.preview, ...action.payload };
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setCampaignV2: (state, action) => {
      const { campaign_brand_data, campaign_data, campaign_prospects_data, campaign_sequence_data, campaign_settings_data } = action.payload;
      const { name, icp, pain_points, solution, geography_target, about_brand_details } = campaign_brand_data;
      const { brand_id, campaign_name, uuid } = campaign_data;
      const { prospects_id, file_name, prospects_list, selected_variable } = campaign_prospects_data;
      const { sequence_id, sequence_list } = campaign_sequence_data;
      const {
        settings_id,
        sender_list,
        schedule_id,
        skip_holidays,
        stop_reply_to,
        tracking_options,
        unsubscribe_rotation,
        signature_rotation,
        remove_watermark_signature,
        total_unsubscribe_list,
        total_signature_list,
        sender_data,
        groups_data,
      } = campaign_settings_data;

      const senderEmailList: any = {};
      const groupEmailList: any = {};
      const unsubscribeList: { [key: string]: boolean } = {};
      const signatureList: { [key: string]: boolean } = {};

      state.campaign_name = campaign_name;
      state.header_count = 1;
      state.ids = { campaign_id: uuid, brand_id, prospects_id, settings_id, sequence_id };
      state.brand_input = { name, icp, pain_points, solution, geography_target, about_brand_details };
      state.prospects.file_name = file_name;
      state.prospects.uploaded_prospects_list = prospects_list?.map(({ verify_mail_status, ...item }: any) => item) || [];
      state.prospects.prospects_list =
        prospects_list
          ?.map(({ verify_mail_status, ...item }: any) => item)
          ?.sort((a: any, b: any) => {
            const aHasNull = Object.values(a).some((value) => value === '');
            const bHasNull = Object.values(b).some((value) => value === '');
            if (aHasNull && !bHasNull) return -1;
            if (!aHasNull && bHasNull) return 1;
            return 0;
          }) || [];

      state.prospects.selected_variable = selected_variable;
      selected_variable?.length !== 0 && (state.prospects.variable_list = [{ key: '' }]);
      state.sequence = sequence_list || [];
      state.scheduleSettings.schedule_id = schedule_id;

      sender_list?.email_accounts?.list?.forEach((item: string) => {
        senderEmailList[item] = true;
      });
      sender_list?.email_accounts?.group_list?.forEach((item: string) => {
        groupEmailList[item] = true;
      });
      !sender_list?.email_accounts?.email_alias && (state.settings.selectedGroupList = groupEmailList);
      !sender_list?.email_accounts?.email_alias && (state.settings.selectedSenderList = senderEmailList);
      sender_list?.email_accounts?.email_alias && (state.settings.email_alias = sender_list?.email_accounts?.email_alias);
      state.senderList = sender_data && [sender_data];
      state.groupList = groups_data && [groups_data];
      skip_holidays && (state.settings.skip_holidays = skip_holidays);
      stop_reply_to && (state.stop_reply_to = stop_reply_to);
      tracking_options && (state.tracking_options = tracking_options);
      unsubscribe_rotation?.forEach((item: string) => (unsubscribeList[item] = true));
      signature_rotation?.signature_list?.forEach((item: string) => (signatureList[item] = true));
      state.finalSettings.selectedUnsubscribeLinks = unsubscribeList;
      state.finalSettings.selectedSignature = signatureList;
      state.finalSettings.remove_watermark_signature = remove_watermark_signature;
      state.settings.signature_type = signature_rotation?.signature_type || 'nothing';
      state.finalSettings.unsubscribeAndSignatureData = {
        unsubscribe_list: total_unsubscribe_list,
        signature_list: total_signature_list,
      };
    },
    clearCampaignV2: (state) => {
      state.uuid = '';
      state.campaign_name = '';
      state.header_count = 1;
      state.ids = { campaign_id: '', brand_id: '', prospects_id: '', settings_id: '', sequence_id: '' };
      state.brand_input = { name: '', icp: '', pain_points: '', solution: '', geography_target: '', about_brand_details: '' };
      state.prospects = {
        prospects_list: [],
        uploaded_prospects_list: [],
        file_name: '',
        prospect_type: 1,
        prospects_flow: 'manual_upload',
        selected_variable: {},
        manage_duplicate: {
          skip_prospect_from_active_campaigns: false,
        },
        variable_list: [],
        enrichment: {
          status: false,
          modalView: false,
          stepCount: 1,
          enrichmentType: 'waterfall',
          enrichment_data: [],
          enchriment_keys: [],
        },
        update_variables: {
          modal_view: false,
          row_email: '',
        },
        preview: {
          isPreview: false,
          update_prospect_list: [],
        },
        search_prospects: {
          data: [],
          selected_prospects: [],
          total_records: 0,
          temprory_id: '',
          tool_details: {
            name: 'apollo',
            title: '',
            api_key_method: 'sparkle',
            api_key: '',
          },
          users_count: {
            max_people_per_company: 0,
            max_people: false,
            select_number_of_people: 0,
          },
          prospects_filter: {
            search_type: 'people',
            filter_data: {
              // Name
              people_name: '',
              // Job Titles
              job_titles: {
                view: {
                  is_not_any_of: false,
                  include_past_job_titles: false,
                },
                selected_method: 'is_any_of',
                is_any_of_data: {
                  job_titles: '',
                  is_not_any_of: '',
                  include_past_job_titles: '',
                },
                management_level: [],
                department_job_function: [],
              },
              // Companies
              companies: {
                view: {
                  is_not_any_of: false,
                  include_job_titles: false,
                },
                selected_method: 'is_any_of',
                is_any_of_data: {
                  companies: '',
                  is_not_any_of: '',
                  include_past_companies: '',
                },
                include_list: '',
                exclude_list: '',
              },
              // Location
              location: {
                view: {
                  exclude_location: false,
                  range_view: false,
                },
                selected_method: 'region',
                region: {
                  include: '',
                  exclude: '',
                },
                zip_code: {
                  zip: '',
                  range: '50',
                },
              },
              // Employees
              employees: {
                selected_method: 'predefined_range',
                predefined_range: [],
                custom_range: {
                  min: '',
                  max: '',
                },
              },
              // Industry & keywords
              industry_keywords: {
                view: {
                  is_not_any_of: false,
                  include_all: false,
                  exclude_keywords: false,
                },
                selected_method: 'is_not_any_of',
                industries: '',
                is_not_any_of_data: '',
                company_keywords: {
                  is_not_any_of: '',
                  include_all: '',
                  exclude: '',
                },
              },
              // Technologies
              technologies: {
                include: '',
                include_all: '',
              },
              // Revenue
              revenue: {
                selected_method: 'is_between',
                is_between: {
                  min: '',
                  max: '',
                },
                private_company: false,
                public_company: false,
              },
              // Funding
              funding: {
                funding_data: [],
                selected_method: 'is_between',
                last_funding_round_date: {
                  from: '',
                  to: '',
                },
                last_funding_round_amount: {
                  min: '',
                  max: '',
                },
              },
              // Job Posting
              job_posting: {
                job_titles: '',
                locations: '',
                active_job_posting: {
                  min: '',
                  max: '',
                },
                job_posted_at: {
                  from: '',
                  to: '',
                },
              },
            },
          },
        },
      };
      state.prospects_verification = {
        modal_view: false,
        step_count: 1,
        verifierData: [],
      };
      state.sequence = [];
      state.settings = {
        selectedSenderList: {},
        selectedGroupList: {},
        isCampaignStartDateSelected: false,
        isCampaignEndDateSelected: false,
        skip_holidays: {
          status: false,
          country: '',
        },
        sending_priority: {
          prioritize_this_campaign: false,
          prioritize_follow_ups: false,
        },
        signature_type: 'nothing',
        email_alias_name: state?.settings?.email_alias_name,
      };
      state.senderList = [];
      state.groupList = [];
      state.loadGroups = { email: true };
      state.scheduleSettings.schedule_id = '';
      state.finalSettings = {
        unsubscribe_message_rotation: false,
        unsubscribe: '',
        custom_unsubscribe: '',
        signature: '',
        signature_rotation: false,
        remove_watermark_signature: false,
        selectedUnsubscribeLinks: {},
        selectedSignature: {},
        editSignatureId: '',
        unsubscribeEditId: '',
        unsubscribeAndSignatureData: {},
      };
      state.review = {
        preview: {
          preview_modal: false,
          preview_list: [],
          list_count: 0,
          search: '',
          selected_index: 0,
          loading: false,
        },
      };
    },
    setEnrichmentKeys: (state, action) => {
      state.prospects.enrichment.enchriment_keys = [...state.prospects.enrichment.enchriment_keys, action.payload];
    },
  },
});

export const {
  setUUID,
  setCurrentSequenceID,
  setCampaignName,
  setIds,
  setHeaderCount,
  setBrandInput,
  setProspects,
  setVerificationToProspects,
  setEnrichment,
  setProspectsVerification,
  addSequence,
  clearCampaignV2,
  setVersionAB,
  removeSequence,
  setCreateSequenceModal,
  handleToggleMenu,
  handleDelayCount,
  duplicateSequence,
  setCurrentSequence,
  setCurrentVersion,
  setMessageData,
  setVariableList,
  setSimilarEmailModal,
  setProspectsVariable,
  setDuplicatesCondition,
  clearVersionAB,
  setReplyData,
  setTrackingData,
  setSenderList,
  addSenderList,
  addGroupList,
  setSelectSenders,
  selectAllSenders,
  setFinalSettings,
  setGroupList,
  setHolidays,
  setSelectGroups,
  setLoadGroupsData,
  deleteSignature,
  deleteCustomUnsubscribe,
  setCampaignPeriod,
  setDays,
  removeBrandInput,
  setEmailAlias,
  setSchedule,
  setTime,
  clearSchedule,
  setScheduleList,
  setCurrentScheduleID,
  setCurrentSchedule,
  setSettingsData,
  setSelectedSenderList,
  setSelectedProspectsCountData,
  updateVariableData,
  updatePreviewProspectsData,
  setSelectedGroupList,
  setSendingPriority,
  setApiPayload,
  setVariableArea,
  insertVariable,
  setSequence,
  setScale,
  setVerifiedProspects,
  removeTriggers,
  setReview,
  setPreview,
  setCampaignV2,
  setEditLoading,
  setEdit,
  removeSelectedGroupList,
  // Search prospects
  setSearchProspectsTool,
  setSearchType,
  setFilterData,
  setSearchProspectsData,
  cleanSearchProspectsFilterData,
  setSelectedProspectsData,
  setSearchProspectsTemproryId,
  setTotalProspectsCount,
  setEnrichmentKeys,
  setSingleProspectsData,
} = campaignv2Slice.actions;

export default campaignv2Slice.reducer;
