import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader } from '@chakra-ui/modal';
import React, { useState } from 'react';
import { HiOutlineBuildingOffice, HiUserGroup } from 'react-icons/hi2';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';
import { PiMedal } from 'react-icons/pi';
// import { RiTwitterXFill } from 'react-icons/ri';
import { BiChevronDown, BiChevronRight, BiKey } from 'react-icons/bi';
// import { FiGithub } from 'react-icons/fi';
import { MdDelete, MdEmail, MdWork } from 'react-icons/md';
import { IoClose, IoLocation } from 'react-icons/io5';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
// import { FaMoneyBillTrendUp, FaBuildingUser } from 'react-icons/fa6';
// import { SiCrunchbase } from 'react-icons/si';
import { TbWorld } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { CiLinkedin } from 'react-icons/ci';

import { AZ_BUCKET } from 'common/constants/constants';
import CustomBadge from 'components/badges/CustomBadge';
import { getErrorMessage } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';
import { setEnrichment, setEnrichmentKeys, setIds, setProspects, setProspectsVariable } from 'store/campaign-v2';
import EnchrimentTools from '../../../../../../../assets/json/Enrichment.json';
import SearchBox from 'components/searchBox';
import { CustomMenu } from 'components/customMenu';
import Button from 'common/sparkle-common/Button';
import SparkleRadio from 'common/sparkle-common/Radio';
import InputField from 'components/fields/InputField';
import { BaseApi } from 'common/api/common/base-api';
import { _errorHandler, toastState } from 'common/utils/utility';
import { setUpdateSettingsData } from 'store/global-settings';

type ToolData = {
  tools: string[];
  input_fields: { [key: string]: string[] };
  key: { [key: string]: string };
};

type EnrichmentToolsDto = {
  [key: string]: ToolData;
};

const EnrichemntModal = () => {
  const enchrimentTools: EnrichmentToolsDto = EnchrimentTools as EnrichmentToolsDto;
  const campaign_id = useAppSelector((state) => state?.campaignv2?.ids?.campaign_id);
  const { campaign_name } = useAppSelector((state) => state.campaignv2);
  const { prospects_id } = useAppSelector((state) => state?.campaignv2?.ids);
  //   const selectedProspects = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.selected_prospects);
  const selected_variable = useAppSelector((state) => state.campaignv2?.prospects?.selected_variable);
  //   const prospect_type = useAppSelector((state) => state?.campaignv2?.prospects?.prospect_type);
  const { modalView, stepCount, enrichmentType } = useAppSelector((stete) => stete.campaignv2.prospects.enrichment);

  const dispatch = useAppDispatch();
  const [selectedMethod, setSelectedMethod] = React.useState({
    waterfall: 0,
  });
  const [openToolsMenu, setOpenToolsMenu] = useState(false);
  const [formData, setFormData] = React.useState({
    loading: false,
    search: '',
    selectedKeyType: 'sparkle',
    inputApiKey: '',
  });
  const [enrichmentToolData, setEnrichmentToolData] = useState([]);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [singleEnrichmentToolData, setSingleEnrichmentToolData] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [openApikeyMenu, setOpenAPIKeyMenu] = useState(false);
  const [mapData, setMapData] = React.useState<any>({
    first_name: { select: '', name: 'first name' },
    last_name: { select: '', name: 'last name' },
    full_name: { select: '', name: 'full name' },
    email: { select: '', name: 'email address' },
    company_name: { select: '', name: 'company name', view: true },
    company_domain: { select: '', name: 'company domain', view: false },
    linkedin_url: { select: '', name: 'linkedin url', view: false },
  });
  const [enrichmentKey, setEnrichmentKey] = useState([]);
  const [inputFieldData, setInputFieldData] = useState([]);
  const [mappedData, setMappedData] = useState<any>({});
  const [openSelectMapMenu, setOpenSelectMapMenu] = useState<{ [key: number]: boolean }>({});
  const [btnLoading, setBtnLoading] = useState(false);
  //   const [openMapVariableMenu, setOpenMapVariableMenu] = useState(false);
  const enrichmentData = [
    { name: 'Current Company', Icon: <HiOutlineBuildingOffice />, key: 'organization_name' },
    // { name: 'Education', Icon: <RiGraduationCapLine />, key: 'education' },
    // { name: 'First name', Icon: <AiOutlineUser />, key: 'first_name' },
    { name: 'Full name', Icon: <FaUser />, key: 'full_name' },
    // { name: 'Work Email', Icon: <MdEmail />, key: 'email' },
    // { name: 'Gender', Icon: <BsGenderAmbiguous />, key: 'gender' },
    { name: 'Industry', Icon: <HiOutlineBuildingOffice />, key: 'organization_industry' },
    { name: 'First Name, Last Name', Icon: <FaUser />, key: 'first_name,last_name' },
    // { name: 'Facebook', Icon: <RiFacebookLine />, key: 'facebook_url' },
    // { name: 'Github', Icon: <FiGithub />, key: 'github' },
    // { name: 'Instagram', Icon: <FaInstagram />, key: 'instagram' },
    { name: 'Country', Icon: <IoLocation />, key: 'country' },
    // { name: 'Address', Icon: <PiAddressBook />, key: 'address' },
    // { name: 'Competitors', Icon: <PiHandshakeFill />, key: 'competitors' },
    // { name: 'Crunchbase Handle', Icon: <SiCrunchbase />, key: 'crunchbase_handle' },
    // { name: 'Description', Icon: <TbFileDescription />, key: 'description' },
    { name: 'Domain', Icon: <TbWorld />, key: 'organization_primary_domain' },
    { name: 'Employee size', Icon: <HiUserGroup />, key: 'organization_employees_count' },
    // { name: 'Company profile', Icon: <FaUserTie />, key: 'organization_profile' },
    { name: 'Linkedin profile', Icon: <CiLinkedin />, key: 'linkedin_url' },
    // { name: 'Founded year', Icon: <MdOutlineCalendarMonth />, key: 'organization_founded_year' },
    // { name: 'Funding', Icon: <TbReportMoney />, key: 'funding' },
    // { name: 'Investors', Icon: <FaUserTie />, key: 'investors' },
    // { name: 'Job openings', Icon: <MdPersonSearch />, key: 'job_openings' },
    { name: 'Logo', Icon: <BsFillBuildingsFill />, key: 'organization_logo_url' },
    // { name: 'Revenue', Icon: <FaMoneyBillTrendUp />, key: 'revenue' },
    // { name: 'Total Funding', Icon: <TbReportMoney />, key: 'total_funding' },
    // { name: 'Twitter', Icon: <RiTwitterXFill />, key: 'twitter_url' },
    // { name: 'Bio', Icon: <BsChatRightDots />, key: 'bio' },
    // { name: 'Company Facebook', Icon: <FaFacebook />, key: 'organization_facebook_url' },
    { name: 'Job Title', Icon: <MdWork />, key: 'title' },
    // { name: 'Location', Icon: <IoLocation />, key: 'location' },
    // { name: 'Mobile Phone Number', Icon: <FaPhone />, key: 'mobile_phone_url' },
    { name: 'Personal Email', Icon: <MdEmail />, key: 'personal_emails' },
    { name: 'Profile Picture', Icon: <CgProfile />, key: 'photo_url' },
    // { name: 'School Name', Icon: <FaSchool />, key: 'school_name' },
    // { name: 'Work Experience', Icon: <FaBuildingUser />, key: 'work_experience' },
  ];

  //   const filterToolsByField = (field: string) => {
  //     return enchrimentTools.enchrimentTools.filter((tool) => tool.seacrchfields.includes(field));
  //   };

  const enchrimentToolsImg: any = {
    apollo: AZ_BUCKET?.SPARKLE_APOLLO,
    nimbler: '',
  };

  const updateMapData = (key1: string, key2: string, data: string | boolean) => {
    setMapData((prev: any) => ({
      ...prev,
      [key1]: {
        ...prev[key1],
        [key2]: data,
      },
    }));
  };

  const handleMapDataView = () => {
    const enrich: any = {
      companies: ['first_name', 'last_name', 'email'],
      education: [''],
      first_name: [''],
      full_name: [''],
      gender: [''],
      industry: [''],
      facebook: [''],
      github: [''],
      instagram: [''],
    };
    const enrichmentDropdownData = enrich[enrichmentData[selectedMethod?.waterfall].key];
    enrichmentDropdownData?.forEach((key1: string) => {
      updateMapData(key1, 'view', true);
    });
  };

  const handleCloseModal = () => {
    if (!formData?.loading) {
      dispatch(setEnrichment({ modalView: false }));
      dispatch(setEnrichment({ stepCount: 1 }));
      setMappedData({});
      setSingleEnrichmentToolData([]);
      setInputFieldData([]);
      // setSelectedTool(null);
      setEnrichmentKey([]);
    }
  };

  const handleClick = async (index: number, keys: string, name: string) => {
    dispatch(setEnrichmentKeys(keys));
    setEnrichmentKey([keys]);
    setSelectedMethod((prev) => ({ ...prev, waterfall: index }));
    const fieldData = enchrimentTools[name];
    if (fieldData) {
      //   Mo(fieldData.tools, 'tools');
      const enrichmentToolData = fieldData.tools.map((tool: string, idx: number) => ({
        name: `Enrich ${enrichmentData[index]?.name.toLowerCase()} from ${tool}`,
        img: enchrimentToolsImg[tool] || '',
        order: idx + 1,
        apiKey: selectedTool?.tool === tool ? selectedTool?.apiKey : 'sparkle',
        selectedKeyType: selectedTool?.tool === tool ? selectedTool?.selectedKeyType : 'sparkle',
        tool: tool,
        key: enrichmentData[index]?.name,
      }));

      setEnrichmentToolData(enrichmentToolData);
      setSingleEnrichmentToolData(enrichmentToolData);
      dispatch(setEnrichment({ stepCount: 2 }));
      //   setEnrichmentToolData([])
    } else {
      setEnrichmentToolData([]);
      setSingleEnrichmentToolData([]);
      toast.error(`No tools to find ${name.toLowerCase()}`, toastState.error);
    }
  };

  const handleWaterFallClick = () => {
    // handleMapDataView();
    // dispatch(setEnrichment({ enrichmentType: 'waterfall', stepCount: 4 }));
  };

  const handleDataPointClick = (item: any, index: number) => {
    setSelectedTool(item);
    const selectedToolInputs = enchrimentTools[item?.key]?.input_fields[item.tool] || [];
    setInputFieldData(selectedToolInputs);
    handleMapDataView();
    dispatch(setEnrichment({ enrichmentType: 'data point', stepCount: 3 }));
  };

  const handleChangeSearchBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setFormData((prev) => ({ ...prev, search: value }));
  };

  const handleEnrichment = async () => {
    try {
      setBtnLoading(true);
      setFormData((prev) => ({ ...prev, loading: true }));
      //   const selectedProspectIds = selectedProspects?.map((item: any) => item?.uuid);
      //   const uniqueEnchrimentkeys = await enchriment_keys.reduce((acc, value) => {
      //     if (!acc.includes(value)) {
      //       acc.push(value);
      //     }
      //     return acc;
      //   }, []);

      const macted_key = await Object.keys(mappedData).reduce((acc: any, key) => {
        acc[key] = mappedData[key].name;
        return acc;
      }, {});
      const updatedkey = enrichmentKey[0] === 'first_name,last_name' ? ['first_name', 'last_name'] : enrichmentKey;
      const params = {
        enrichment_tools: [{ name: selectedTool?.tool, apiKey: selectedTool?.apiKey }],
        keys: updatedkey,
        campaign_id,
        matched_keys: macted_key,
      };
      const response = await BaseApi.post('/prospects/enrich', params);
      if (!response.error) {
        try {
          //   const keyName = uniqueEnchrimentkeys[uniqueEnchrimentkeys.length - 1];
          let formattedEnrichData: any = [];
          // const AddCustomVariable = await Promise.all(updatedkey?.filter((item) => (!selected_variable[item] && !selected_variable[selectedTool?.key]))?.map(async (item: string) => {
          const AddCustomVariable = await Promise.all(updatedkey?.filter((item) => (!selected_variable[`Sparkle ${item}`] && !selected_variable[`Sparkle ${selectedTool?.key}`]))?.map(async (item: string) => {
            const payload = {
              campaign_id,
              variable: `${selectedTool.tool}_${item}`,
            };
            return await BaseApi.post('/map/variables/linked/campaign', payload)
          }));
          let payload = {};
          if (updatedkey?.length > 1) {
            if (AddCustomVariable?.length) {
              AddCustomVariable?.forEach((item, index) => {
                // const payloadData = {
                //   [updatedkey[index]]: { name: item?.data?.name, variable: item?.data?.variable },
                // };
                payload = {
                  ...payload,
                  [`Sparkle ${updatedkey[index]}`]: { name: item?.data?.name, variable: item?.data?.variable },
                }
                // dispatch(setProspectsVariable(payloadData));
              })

              formattedEnrichData = await response?.data?.map((prospect: any) => {
                return updatedkey?.reduce((acc: any, key: string, index: number) => {
                  acc[AddCustomVariable[index]?.data?.name] = updatedkey[0] === 'personal_emails' ? (prospect[`${selectedTool.tool}_${key}`][0] || '') : prospect[`${selectedTool.tool}_${key}`];
                  // acc[AddCustomVariable[index]?.data?.name] = prospect[`${selectedTool.tool}_${key}`];
                  delete acc[`${selectedTool.tool}_${key}`]
                  return acc;
                }, { ...prospect })
              })
            } else {
              formattedEnrichData = await response?.data?.map((prospect: any) => {
                return updatedkey?.reduce((acc: any, key: string, index: number) => {
                  // acc[selected_variable[key]?.name] = prospect[`${selectedTool.tool}_${key}`];
                  acc[selected_variable[key]?.name] = updatedkey[0] === 'personal_emails' ? (prospect[`${selectedTool.tool}_${key}`][0] || '') : prospect[`${selectedTool.tool}_${key}`];
                  delete acc[`${selectedTool.tool}_${key}`]
                  return acc;
                }, { ...prospect })
              })
            }

          } else if (!selected_variable[`Sparkle ${selectedTool?.key}`]) {
            if (AddCustomVariable[0]?.data) {
              const key = `Sparkle ${selectedTool?.key}`;
              payload = {
                ...payload,
                [key]: { name: AddCustomVariable[0]?.data?.name, variable: AddCustomVariable[0]?.data?.variable },
              }
              // const payloadData = {
              //   [key]: { name: AddCustomVariable[0]?.data?.name, variable: AddCustomVariable[0]?.data?.variable },
              // };
              // dispatch(setProspectsVariable(payloadData));
              formattedEnrichData = await response?.data?.map((item: any) => {
                const convertedData = {
                  ...item,
                  [AddCustomVariable[0]?.data?.name]: updatedkey[0] === 'personal_emails' ? (item[`${selectedTool.tool}_${enrichmentKey[0]}`][0] || "") : item[`${selectedTool.tool}_${enrichmentKey[0]}`],
                  // [AddCustomVariable[0]?.data?.name]: item[`${selectedTool.tool}_${enrichmentKey[0]}`],
                };
                delete convertedData[`${selectedTool.tool}_${enrichmentKey[0]}`];
                return convertedData;
              });
            }
          } else {
            formattedEnrichData = await response?.data?.map((item: any) => {
              const convertedData = {
                ...item,
                [selected_variable[`Sparkle ${selectedTool?.key}`].name]: updatedkey[0] === 'personal_emails' ? (item[`${selectedTool.tool}_${enrichmentKey[0]}`][0] || '') : item[`${selectedTool.tool}_${enrichmentKey[0]}`],
              };
              delete convertedData[`${selectedTool.tool}_${enrichmentKey[0]}`];
              return convertedData;
            });
          }

          const params = {
            campaign_name: campaign_name,
            ...(campaign_id && { campaign_id }),
            prospects_data: {
              ...(prospects_id && { prospects_id }),
              prospects_total_count: formattedEnrichData?.length,
              prospects_list: formattedEnrichData,
              ...(AddCustomVariable?.length ? {
                selected_variable: {
                  ...selected_variable,
                  ...payload
                }
              } : {})
            },
          };
          const prospectAddingResponse = await BaseApi.post('/campaign/prospects', params);
          if (prospectAddingResponse?.error) {
            toast.error(_errorHandler(prospectAddingResponse?.message), toastState.error);
            dispatch(getErrorMessage(prospectAddingResponse?.message));
          } else {
            dispatch(setProspectsVariable(payload));
            dispatch(setIds({ prospects_id: prospectAddingResponse?.data?.prospects_id }));
            dispatch(setProspects({ prospects_list: formattedEnrichData }));
            handleCloseModal();
          }
      dispatch(setUpdateSettingsData('billings'));

        } catch (error) {
          // console.log('error', error);
        }
      }
      else {
        toast.error(_errorHandler(response?.message), toastState.error);
      }
    } catch (error) {
      // console.log('Enriching data error', error);
    } finally {
      setFormData((prev) => ({ ...prev, loading: false }));
      setBtnLoading(false);
    }
  };

  const handleDelete = (index: number) => {
    setEnrichmentToolData((prevData) => {
      // Remove the item from the array
      const updatedData = prevData.filter((_, i) => i !== index);
      // Update the order numbers
      return updatedData.map((item, idx) => ({
        ...item,
        order: idx + 1,
      }));
    });
  };

  const handleApikeyChange = (keyType: string, index: number) => {
    setEnrichmentToolData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        selectedKeyType: keyType,
        apiKey: keyType === 'sparkle' ? 'sparkle' : '',
      };
      return updatedData;
    });
  };

  const handleApiKeyInputChange = (value: string, index: number) => {
    setEnrichmentToolData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        apiKey: value,
      };
      return updatedData;
    });
  };

  const handleMenuOpen = (index: number) => {
    if (openMenuIndex === index) {
      setOpenMenuIndex(null);
    } else {
      setOpenMenuIndex(index);
    }
  };

  const handleMenuClose = () => {
    setOpenMenuIndex(null);
  };

  const handleSelectEnrichTool = (item: any) => {
    setSelectedTool(item);
    setOpenToolsMenu(false);
    const selectedToolInputs = enchrimentTools[item?.key]?.input_fields[item.tool] || [];
    setInputFieldData(selectedToolInputs);
  };

  const handleMapVariable = async (key: string, value: any) => {
    const notExist = Object.values(mappedData).every((mappedValue: any) => mappedValue.name !== value?.name);
    if (notExist || value?.variable === 'select') {
      setMappedData((prev: any) => ({
        ...prev,
        [key]: { name: value?.name, variable: value?.variable },
      }));
    } else {
      toast.error(`Variable already selected`, toastState.error);
    }
  };

  const handleToolsApikey = (keyType: string) => {
    setSelectedTool((prevData: any) => ({
      ...prevData,
      selectedKeyType: keyType,
      apiKey: keyType === 'sparkle' ? 'sparkle' : '',
    }));
  };

  const isButtonDisabled = () => {
    const hasAtLeastOneFieldMapped = Object.keys(mappedData).length > 0;
    const isEmailFieldMapped = mappedData['email'] && mappedData['email'].variable !== 'select';
    return !(hasAtLeastOneFieldMapped && isEmailFieldMapped);
  };

  const handleInputChange = (value: string) => {
    setSelectedTool((prevData: any) => ({
      ...prevData,

      apiKey: value,
    }));
  };

  const handleOpenMapSelect = (index: number) => {
    setOpenSelectMapMenu((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  const handleCloseMapSelect = (index: number) => {
    setOpenSelectMapMenu((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  return (
    <Modal isOpen={modalView} size={'auto'} onClose={handleCloseModal} isCentered={true}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent className="!w-[32.5rem]">
        <ModalCloseButton className="!top-3 z-10 text-gray-700" />
        <ModalHeader className="select-none border-b !px-5 !pb-1 !pt-5 !text-[15px] !font-medium !text-column">
          {(stepCount === 1 || stepCount === 2) && 'Add Enrichment'}
          {(stepCount === 3 || stepCount === 4) && (
            <div className="flex items-center gap-2 capitalize">
              <AiOutlineArrowLeft
                size={16}
                className="cursor-pointer"
                onClick={() => {
                  dispatch(setEnrichment({ stepCount: 2 }));
                }}
              />{' '}
              {enrichmentData[selectedMethod?.waterfall]?.name} - {enrichmentType}
            </div>
          )}
        </ModalHeader>
        <ModalBody className="!relative select-none  !rounded-md !p-0">
          {stepCount === 1 && (
            <div>
              {/* Search */}
              <div className="p-4">
                <SearchBox extraClassName="!m-0" onChange={handleChangeSearchBox} />
              </div>

              {/* Data */}
              <div className="select-none">
                {/* Title */}
                <h4 className="border-y px-5 py-2 text-sm text-column">Personal data</h4>
                <div className="h-[380px] overflow-y-auto py-3">
                  {enrichmentData
                    .filter((item) => item?.name?.toLowerCase()?.includes(formData?.search?.toLowerCase()))
                    .map((item, index) => {
                      return (
                        <button key={item.key} onClick={() => handleClick(index, item.key, item?.name)} className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple">
                          <div className="w-fit text-blueSecondary">{item.Icon}</div>
                          <div className="w-full text-start text-[.8125rem] font-medium">{item.name}</div>
                          <BiChevronRight className="w-fit" />
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          {stepCount === 2 && (
            <div>
              {/* Waterfall Title  */}
              <div className="flex flex-col justify-center p-4 text-column">
                <h4 className="text-[.8125rem] font-medium">Waterfall</h4>
                <span className="text-[.625rem]">Use a recommended sequence of integrations to get the best coverage for a data point</span>
              </div>
              {/* Waterfall data */}
              {/* <button onClick={handleWaterFallClick} className="flex w-full items-center gap-2 border-y p-4 hover:bg-lightpurple" disabled={true}> */}
              <button onClick={handleWaterFallClick} className="flex w-full items-center gap-2 border-y p-4" disabled={true}>
                <div className="w-fit">{enrichmentData[selectedMethod?.waterfall]?.Icon}</div>
                <div className="w-full text-start text-[13px] font-medium"><span className='mr-2'>{enrichmentData[selectedMethod?.waterfall]?.name}</span><CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 !font-semibold text-[.625rem] !px-2 !py-0.5' /></div>
                <BiChevronRight className="w-fit" />
              </button>

              {/*  Data Point Title  */}
              <div className="flex flex-col justify-center border-b p-4 text-column">
                <h4 className="text-[.8125rem] font-medium">Data point</h4>
                <span className="text-[.625rem]">Use an individual integration to find the data you're looking for</span>
              </div>

              {/* Data points data */}
              <div className="my-3">
                {singleEnrichmentToolData?.map((item, index) => {
                  return (
                    <button key={index} onClick={() => handleDataPointClick(item, index)} className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple">
                      <img src={item?.img} alt="apollo img" className="size-5 rounded-sm" />
                      <div className="w-full text-start text-[.8125rem] font-medium">{item.name}</div>
                      <BiChevronRight className="w-fit" />
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          {stepCount === 3 && (
            <div>
              {/* Title  */}
              <div className="flex flex-col justify-center border-b px-4 pb-2 pt-6 text-column">
                <h4 className="text-[.8125rem] font-medium">Select existing provider</h4>
                <span className="text-[.625rem]">Extract information from your existing integrations</span>
              </div>

              {/* Drop down */}
              <div className="px-4 pt-3">
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <CustomMenu
                      open={openToolsMenu}
                      onOpen={() => setOpenToolsMenu(true)}
                      onClose={() => setOpenToolsMenu(false)}
                      btnContent={
                        <div className="flex items-center gap-2">
                          {selectedTool?.img ? <img src={selectedTool?.img} alt="Tool img" /> : <PiMedal size={18} />}

                          <span className="text-[.83rem] font-medium">{selectedTool?.name ? selectedTool?.name : 'Select Tool'}</span>
                        </div>
                      }
                      btnClassName="w-full flex justify-between items-center h-10"
                      menuListClassName="w-full !shadow-none"
                      rightIcon={<BiChevronDown size={17} className="!text-heading" />}
                    >
                      {singleEnrichmentToolData?.map((item, index) => {
                        return (
                          <button key={index} className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple" onClick={() => handleSelectEnrichTool(item)}>
                            <div className="w-fit">{item?.img ? <img src={item?.img} alt="Tool img" /> : <PiMedal size={18} />}</div>
                            <div className="w-full text-start text-[13px] font-medium">{item?.name}</div>
                          </button>
                        );
                      })}
                    </CustomMenu>
                  </div>

                  <CustomMenu
                    btnContent={<BiKey size={17} />}
                    btnClassName="!border-none !p-0 !w-fit !rounded-full !text-paused-row hover:!text-white hover:!bg-blueSecondary duration-300 cursor-pointer"
                    menuListClassName="!w-80 !shadow-none !p-0"
                    open={openApikeyMenu}
                    onOpen={() => setOpenAPIKeyMenu(true)}
                    onClose={() => setOpenAPIKeyMenu(false)}
                  >
                    <div className="flex border-b px-4 pb-1.5 pt-4 text-column">
                      <span className="w-full text-[.9375rem] font-medium">Add API</span>
                      <IoClose size={22} onClick={() => setOpenAPIKeyMenu(false)} className="cursor-pointer" />
                    </div>
                    <div className="border-b px-4 pb-2 pt-5 text-xs text-column">Choose Apollo.io API key account</div>
                    <div className="flex flex-col gap-2 p-4">
                      <div className="flex items-center gap-2">
                        <SparkleRadio checked={selectedTool?.selectedKeyType === 'sparkle'} onChange={() => handleToolsApikey('sparkle')} />{' '}
                        <span className="text-xs text-paused-color">Use Sparkle's Apollo API key</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <SparkleRadio checked={selectedTool?.selectedKeyType === 'own'} onChange={() => handleToolsApikey('own')} />{' '}
                        <span className="text-xs text-paused-color">Use my own Apollo API key</span>
                      </div>
                      {selectedTool?.selectedKeyType === 'own' && (
                        <div className="mt-1.5">
                          <div className="text-sm text-column">Add Apollo API key</div>
                          <InputField
                            label={''}
                            value={selectedTool?.selectedKeyType === 'own' ? selectedTool?.apiKey : ''}
                            placeholder="Enter API key"
                            inputClass="!rounded-md !h-9"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex gap-4 border-t px-3 pb-3 pt-2 ">
                      <Button
                        name="Cancel"
                        customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.8125rem] transition-all duration-300"
                        onClick={() => setOpenAPIKeyMenu(false)}
                      />
                      <Button name="Add API Key" customClass="w-full !text-[.8125rem] hover:bg-purple-700 transition-all duration-300" onClick={() => setOpenAPIKeyMenu(false)} />
                    </div>
                  </CustomMenu>
                </div>
              </div>
              <div className="flex justify-between border-b px-4 pb-2 pt-6">
                <div className="text-[.8125rem] font-medium text-column">Add input data</div>
              </div>
              <div className="px-4 pt-3">
                <div className="flex items-center gap-2">
                  <div className="flex w-full flex-col gap-2">
                    {inputFieldData.map((item: any, index: number) => (
                      <CustomMenu
                        key={index}
                        btnContent={
                          <div className="text-[.83rem] font-medium text-gray-600">
                            {mappedData[item] && mappedData[item].variable !== 'select' ? mappedData[item].variable : `Map your ${item.replace(/_/g, ' ')} column`}
                          </div>
                        }
                        btnClassName="w-full flex justify-between items-center h-10"
                        menuListClassName="w-full !shadow-none"
                        rightIcon={<BiChevronDown size={17} className="!text-heading" />}
                        open={openSelectMapMenu[index] || false}
                        onOpen={() => handleOpenMapSelect(index)}
                        onClose={() => handleCloseMapSelect(index)}
                      // open={openMapVariableMenu}
                      // onOpen={() => setOpenMapVariableMenu(true)}
                      // onClose={() => setOpenMapVariableMenu(false)}
                      >
                        <div className="h-28 overflow-y-auto ">
                          <button
                            className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple"
                            onClick={() => {
                              handleMapVariable(item, { name: 'select', variable: 'select' });
                              handleCloseMapSelect(index);
                            }}
                          >
                            <div className="w-fit">
                              <PiMedal />
                            </div>
                            <div className="w-full text-start text-[13px] font-medium">select</div>
                          </button>
                          {Object.entries(selected_variable)
                            .filter(([key, value]) => value.name !== 'select')
                            .map(([key, value]) => {
                              return (
                                <button
                                  className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple "
                                  onClick={() => {
                                    handleMapVariable(item, value);
                                    handleCloseMapSelect(index);
                                  }}
                                >
                                  <div className="w-fit">
                                    <PiMedal />
                                  </div>
                                  <div className="w-full text-start text-[13px] font-medium">{value.variable}</div>
                                </button>
                              );
                            })}
                        </div>
                      </CustomMenu>
                    ))}
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex gap-4 px-4 py-5 ">
                <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.8125rem] transition-all duration-300" onClick={handleCloseModal} />
                <Button
                  name="Add fields"
                  loading={btnLoading}
                  customClass="w-full !text-[.8125rem] hover:bg-purple-700 transition-all duration-300"
                  disable={isButtonDisabled() || btnLoading}
                  onClick={handleEnrichment}
                />
              </div>
            </div>
          )}

          {stepCount === 4 && (
            <div>
              <div className="flex justify-between border-b px-4 pb-2 pt-6">
                <div className="text-[.8125rem] font-medium text-column">Find data with the providers below</div>
                <button className="text-[.75rem] font-semibold text-blueSecondary hover:text-hoverlightpurple">+ Add another provider</button>
              </div>
              {/* Drop down */}
              <div className="px-4 pt-3">
                {enrichmentToolData?.map((item, index) => {
                  return (
                    <div className="my-2 flex items-center gap-2">
                      <span className="flex size-5 items-center justify-center rounded-full bg-bglightSkyBlue text-[.6875rem] font-bold text-textDarkBlue">{item?.order}</span>
                      <div className="w-full">
                        <div className="flex items-center gap-2 rounded-md border-2 p-2">
                          <img src={item?.img} alt="tools img" className="size-5 rounded-sm" />
                          <span className="text-[.83rem] font-medium">{item?.name}</span>
                        </div>
                      </div>
                      {/* <div className='w-fit rounded-full text-paused-row hover:text-white hover:bg-blueSecondary duration-300 cursor-pointer'><BiKey/></div> */}
                      <CustomMenu
                        btnContent={<BiKey size={17} />}
                        btnClassName="!border-none !p-0 !w-fit !rounded-full !text-paused-row hover:!text-white hover:!bg-blueSecondary duration-300 cursor-pointer"
                        menuListClassName="!w-80 !shadow-none !p-0"
                        open={openMenuIndex === index}
                        onOpen={() => handleMenuOpen(index)}
                        onClose={handleMenuClose}
                      >
                        <div className="flex border-b px-4 pb-1.5 pt-4 text-column">
                          <span className="w-full text-[.9375rem] font-medium">Add API</span>
                          <IoClose size={22} onClick={handleMenuClose} />
                        </div>
                        <div className="border-b px-4 pb-2 pt-5 text-xs text-column">Choose Apollo.io API key account</div>
                        <div className="flex flex-col gap-2 p-4">
                          <div className="flex items-center gap-2">
                            <SparkleRadio checked={item?.selectedKeyType === 'sparkle'} onChange={() => handleApikeyChange('sparkle', index)} />{' '}
                            <span className="text-xs text-paused-color">Use Sparkle's Apollo API key</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <SparkleRadio checked={item?.selectedKeyType === 'own'} onChange={() => handleApikeyChange('own', index)} />{' '}
                            <span className="text-xs text-paused-color">Use my own Apollo API key</span>
                          </div>
                          {item?.selectedKeyType === 'own' && (
                            <div className="mt-1.5">
                              <div className="text-sm text-column">Add Apollo API key</div>
                              <InputField
                                label={''}
                                value={item?.selectedKeyType === 'own' ? item?.apiKey : ''}
                                placeholder="Enter API key"
                                inputClass="!rounded-md !h-9"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleApiKeyInputChange(event.target.value, index)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex gap-4 border-t px-3 pb-3 pt-2 ">
                          <Button
                            name="Cancel"
                            customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.8125rem] transition-all duration-300"
                            onClick={handleMenuClose}
                          />
                          <Button name="Add input" customClass="w-full !text-[.8125rem] hover:bg-purple-700 transition-all duration-300" onClick={handleMenuClose} />
                        </div>
                      </CustomMenu>
                      <button className="w-fit rounded-full text-paused-row duration-300 hover:text-gray-700">
                        <MdDelete size={17} onClick={() => handleDelete(index)} />
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-between border-b px-4 pb-2 pt-6">
                <div className="text-[.8125rem] font-medium text-column">Add input data</div>
              </div>
              <div className="px-4 pt-3">
                <div className="flex items-center gap-2">
                  <div className="flex w-full flex-col gap-2">
                    {Object.entries(mapData)
                      .filter((item: any) => item[1].view)
                      .map((item: any) => (
                        <CustomMenu
                          key={item[0]}
                          btnContent={<div className="text-[.83rem] font-medium text-gray-600">Map your {item[1].name.replace(/_/g, ' ')} column</div>}
                          btnClassName="w-full flex justify-between items-center h-10"
                          menuListClassName="w-full !shadow-none"
                          rightIcon={<BiChevronDown size={17} className="!text-heading" />}
                        >
                          <button className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple">
                            <div className="w-fit">
                              <PiMedal />
                            </div>
                            <div className="w-full text-start text-[13px] font-medium">Full name</div>
                          </button>
                          <button className="flex w-full items-center gap-2 px-4 py-2.5 hover:bg-lightpurple">
                            <div className="w-fit">
                              <PiMedal />
                            </div>
                            <div className="w-full text-start text-[13px] font-medium">Full name</div>
                          </button>
                        </CustomMenu>
                      ))}
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex gap-4 px-4 py-5 ">
                <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.8125rem] transition-all duration-300" onClick={handleCloseModal} />
                <Button
                  loading={formData?.loading}
                  disable={formData?.loading}
                  name="Add fields"
                  customClass="w-full !text-[.8125rem] hover:bg-purple-700 transition-all duration-300"
                  onClick={handleEnrichment}
                />
              </div>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnrichemntModal;

//   const getEnrichedData = async () => {
//     try {
//       const queryparams = setAndGetQueryParams([
//         { key: 'campaign_id', value: campaign_id },
//         { key: 'prospects_type', value: prospect_type === 1 ? 'upload' : 'search' },
//       ]);
//       const response = await BaseApi.get(`/sparkle/leads/enrich/prospects/list?${queryparams}`);
//       if (!response?.error) {
//         // dispatch(setEnrichment({ enrichment_data: response?.data?.data }))
//         const lists = response?.data?.data?.map((item: any) => {
//           const toolData = Object.entries(item?.from_tools)?.reduce((acc: any, toolData: [string, any], index: number) => {
//             if (Object.keys(item?.from_tools)?.length - 1 !== index && !toolData[1][enrichmentData[selectedMethod?.waterfall].key]) {
//               return acc;
//             } else {
//               enrichment_data?.forEach((key: string) => {
//                 acc[`${toolData[0]}_${key}`] = toolData[1][key] || '';
//               });
//               acc[`${toolData[0]}_${enrichmentData[selectedMethod?.waterfall].key}`] = toolData[1][enrichmentData[selectedMethod?.waterfall].key] || '';
//               return acc;
//             }
//           }, {});
//           const { id, uuid, ...rest } = item.uploaded_prospects;

//           return {
//             ...rest,
//             ...toolData,
//           };
//         });
//         dispatch(setEnrichment({ enrichment_data: [...enrichment_data, enrichmentData[selectedMethod?.waterfall].key], status: true }));
//         dispatch(setProspects({ prospects_list: lists }));
//       }
//     } catch (error) {
//       console.log('Getting enriched data error', error);
//     }
//   };

//   const handleEnrichment = async () => {
//     try {
//       setFormData((prev) => ({ ...prev, loading: true }));
//       const selectedProspectIds = selectedProspects?.map((item: any) => item?.uuid);
//       const params = {
//         enrichment_tools: [
//           { name: 'apollo', apiKey: 'sparkle' },
//           { name: 'nimbler', apiKey: 'dsfdsfds' },
//           { name: 'dropcontact', apiKey: 'dsfdsfds' },
//           { name: 'prospeo', apiKey: 'dsfdsfds' },
//           { name: 'dropcontact', apiKey: 'dsfdsfds' },
//         ],
//         key: 'first_name',
//         campaign_id,
//         prospects_list: selectedProspectIds,
//         prospects_type: prospect_type === 1 ? 'upload' : 'search',
//       };
//       console.log(params);
//       //   const response = await BaseApi.post('/sparkle/leads/enrich/prospects', params);
//       //   if (!response.error) {
//       //     await getEnrichedData();
//       //   }
//     } catch (error) {
//       console.log('Enriching data error', error);
//     } finally {
//       setFormData((prev) => ({ ...prev, loading: false }));
//     }
//   };

//   const SingEnrichmentData = [
//     {
//       name: `Enrich ${enrichmentData[selectedMethod?.waterfall]?.name.toLowerCase()} from Apollo.io`,
//       img: 'https://sparkle-dev.azurewebsites.net/api/v1/helper/az/bucket/1722929362749/apollo.mini.io.png',
//     },
//     { name: `Enrich ${enrichmentData[selectedMethod?.waterfall]?.name.toLowerCase()} from`, img: '' },
//     { name: `Enrich ${enrichmentData[selectedMethod?.waterfall]?.name.toLowerCase()} from`, img: '' },
//     { name: `Enrich ${enrichmentData[selectedMethod?.waterfall]?.name.toLowerCase()} from`, img: '' },
//     { name: `Enrich ${enrichmentData[selectedMethod?.waterfall]?.name.toLowerCase()} from`, img: '' },
//   ];
