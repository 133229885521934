import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { FiSearch } from 'react-icons/fi';
import { BiChevronDown } from 'react-icons/bi';
import { Avatar } from '@chakra-ui/react';
import { MdAccessTime, MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage } from 'store/custom';
import { CustomMenu } from 'components/customMenu';
import { BaseApi } from 'common/api/common/base-api';
import { countryList } from '../../../../common/dto/initial-value/views/campaign-v2';
import { cryptoDecrypt, toastState } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import Button from 'common/sparkle-common/Button';
import TimeZoneList from 'assets/json/world-timezone-all-list.json';
import ImageFileUploader from 'common/sparkle-common/ImageFileUploader';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { setLoggedUserDetails } from 'store/authentication';
import { LocalStorage } from 'common/api/common/storage';

const PersonalDetails = () => {
  const [handleState, setHandleState] = React.useState({
    loading: false,
    personalData: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      picture: '',
      role: '',
      country: '',
      time_zone: '',
    },
    timeZoneSearch: '',
  });

  const [open, setOpen] = React.useState({
    timezone: false,
  });

  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const dispatch = useAppDispatch();

  const handleTimeZoneSearchChange = (event: any) => {
    setHandleState((prevState) => ({
      ...prevState,
      timeZoneSearch: event.target.value,
    }));
  };

  const handleUpdateProfilePicture = async (image: string) => {
    try {
      const response = await BaseApi.post('/helper/generate/access/token', {});
      if (!response.error) {
        LocalStorage.setString(LOGIN_STORAGE_VAR, response?.data?.data);
        const loginData = await cryptoDecrypt(response?.data?.data, true);
        dispatch(
          setLoggedUserDetails({
            id: loginData?.id,
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            organization_id: loginData?.organization_id,
            workspace_id: loginData?.workspace_id,
            organization_name: loginData?.organization_name,
            workspace_name: loginData?.workspace_name,
            authToken: loginData?.authToken,
            picture: image,
            is_google: loginData?.is_google,
          })
        );
      }
    } catch (error) {
      console.log('', error);
    }
  };

  const filteredTimeZones = TimeZoneList?.filter((item) => item?.name?.toLowerCase().includes(handleState?.timeZoneSearch?.toLowerCase()));

  const handleImageUpload = (data: any) => {
    setHandleState((prevState) => ({
      ...prevState,
      personalData: {
        ...prevState.personalData,

        picture: data?.file_Url,
      },
    }));
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      personalData: {
        ...prevState.personalData,
        [id]: value,
      },
    }));
  };

  const fetchUserPersonalDetails = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const userResponse = await BaseApi.get(`/auth/user/personal/details`);
      setHandleState((prevState) => ({
        ...prevState,
        personalData: {
          ...prevState.personalData,
          first_name: userResponse?.data?.first_name,
          last_name: userResponse?.data?.last_name,
          email: userResponse?.data?.email,
          mobile: userResponse?.data?.mobile,
          picture: userResponse?.data?.picture,
          role: userResponse?.data?.role,
          country: userResponse?.data?.country,
          time_zone: userResponse?.data?.time_zone,
        },
      }));

      setHandleState((prevState) => ({
        ...prevState,
        personalData: userResponse?.data,
      }));

      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchUserPersonalDetails();
  }, []);

  const handleUpdateUserPersonalDetails = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const updateResponse = await BaseApi.patch(`/auth/user/personal/details`, {
        ...(handleState?.personalData?.first_name && { first_name: handleState?.personalData?.first_name }),
        ...(handleState?.personalData?.last_name && { last_name: handleState?.personalData?.last_name }),
        ...(handleState?.personalData?.email && { email: handleState?.personalData?.email }),
        ...(handleState?.personalData?.mobile && { mobile: handleState?.personalData?.mobile }),
        ...(handleState?.personalData?.picture && { picture: handleState?.personalData?.picture }),
        ...(handleState?.personalData?.role && { role: handleState?.personalData?.role }),
        ...(handleState?.personalData?.country && { country: handleState?.personalData?.country }),
        ...(handleState?.personalData?.time_zone && { time_zone: handleState?.personalData?.time_zone }),
      });
      if (!updateResponse?.error) {
        dispatch(getErrorMessage({}));
        toast.success('Profile Updated Successfully', toastState.success);
        handleUpdateProfilePicture(handleState?.personalData?.picture);
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      } else {
        dispatch(getErrorMessage(updateResponse?.message));
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-2 h-[90vh] w-full overflow-auto rounded-md  bg-white p-2 dark:!bg-darkOne">
      {/* Header Component with Save button */}
      <div className="">
        <div className="m-1.5 flex h-12 w-full items-center justify-between">
          <div className="flex flex-grow flex-col dark:!text-white">
            <span className="text-md font-semibold">Personal info</span>
            <span className="text-xs">Update your photo and personal details here.</span>
          </div>
          <div className="mx-auto flex w-1/6 items-center gap-3">
            <Button
              disable={handleState?.loading}
              loading={handleState?.loading}
              id="personal-details-save-btn"
              name="Save"
              customClass="text-[.8125rem] dark:border-none personal_details_save_button w-full active:scale-95 transition-transform duration-150 ease-out"
              onClick={handleUpdateUserPersonalDetails}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Name Input */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">
          Name <span className="text-red-500">*</span>
        </span>
        <div className="flex w-2/3 gap-3">
          <div className="w-1/2">
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0"
              inputClass="!mt-0.5 !rounded-md !h-9 personal_details_first_name"
              labelClass="!text-paused-color !font-[500]  !text-xs "
              placeholder="First name"
              id="first_name"
              onChange={handleChange}
              value={handleState?.personalData?.first_name || ''}
              type="text"
              errorMessage={errorMessage?.first_name}
              state={errorMessage?.first_name ? 'error' : ''}
            />
          </div>
          <div className="w-1/2">
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0"
              inputClass="!mt-0.5 !rounded-md !h-9 personal_details_last_name"
              labelClass="!text-paused-color !font-[500]  !text-xs"
              placeholder="Last name"
              id="last_name"
              type="text"
              onChange={handleChange}
              value={handleState?.personalData?.last_name || ''}
              errorMessage={errorMessage?.last_name}
              state={errorMessage?.last_name ? 'error' : ''}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Email Input */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">Email</span>
        <div className="flex w-2/3 gap-3">
          <div className="w-full">
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0"
              inputClass="!mt-0.5 !rounded-md !h-9 personal_details_email !bg-paused-bg cursor-default"
              labelClass="!text-paused-color !font-[500]  !text-xs "
              placeholder="Email address"
              id="email"
              type="text"
              readOnly={true}
              value={handleState?.personalData?.email || ''}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Profile Picture uploader */}
      <div className="-mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <div className="flex flex-col">
          <span className="text-sm font-medium dark:!text-white">Profile picture</span>
          <span className="text-xs dark:!text-white">This will be displayed on your profile.</span>
        </div>

        <div className="flex w-2/3 items-center gap-1">
          <div className="size-full w-1/3 ">
            {/* <div className="ml-1">
              <Avatar
                height={'5rem'}
                width={'5rem'}
                name={`${handleState?.personalData?.first_name} ${handleState?.personalData?.last_name}`}
                bg="gray.100"
                id="avatar"
                src={handleState?.personalData?.picture}
                className="!justify-center !border !object-cover !text-xl "
              />
            </div> */}
            <div className="ml-1">
              <Avatar
                height={'5rem'}
                width={'5rem'}
                name={`${handleState?.personalData?.first_name} ${handleState?.personalData?.last_name}`}
                bg="gray.100"
                id="avatar"
                src={handleState?.personalData?.picture}
                className="group !relative !justify-center  !object-cover !text-xl"
              >
                <button
                  className="absolute right-0 top-0 rounded-full bg-red-500 px-1 py-1 text-xs text-white   opacity-0 transition-opacity group-hover:opacity-100"
                  onClick={() =>
                    setHandleState((prevState) => ({
                      ...prevState,
                      personalData: {
                        ...prevState.personalData,
                        picture: '',
                      },
                    }))
                  }
                >
                  <MdDeleteForever size={15} className="text-white" />
                </button>
              </Avatar>
            </div>
          </div>
          <div className="w-full ">
            <ImageFileUploader onUpload={handleImageUpload} customClass="!h-[164px] !w-full  bg-white  hover:bg-[#F5F8FF] rounded-[10px] border-[1px]  " />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Role Input */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">
          Designation <span className="text-red-500">*</span>
        </span>
        <div className="flex w-2/3 gap-3">
          <div className="w-full">
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0 "
              inputClass="!mt-0.5 !rounded-md !h-9 personal_details_role"
              labelClass="!text-paused-color !font-[500]  !text-xs"
              placeholder="Designation"
              value={handleState?.personalData?.role || ''}
              id="role"
              type="text"
              onChange={handleChange}
              errorMessage={errorMessage?.role}
              state={errorMessage?.role ? 'error' : ''}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Mobile Input */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">
          Mobile <span className="text-red-500">*</span>
        </span>
        <div className="flex w-2/3 gap-3">
          <div className="w-full">
            <InputField
              autoComplete="off"
              customClass="!h-9 !mt-0"
              inputClass="!mt-0.5 !rounded-md !h-9 personal_details_mobile"
              labelClass="!text-paused-color !font-[500]  !text-xs"
              placeholder="Mobile"
              value={handleState?.personalData?.mobile || ''}
              id="mobile"
              onChange={handleChange}
              type="number"
              errorMessage={errorMessage?.mobile}
              state={errorMessage?.mobile ? 'error' : ''}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Country Input */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">
          Country <span className="text-red-500">*</span>
        </span>
        <div className="flex w-2/3 gap-3">
          <div className="w-full">
            <ReactFlagsSelect
              selectedSize={12}
              optionsSize={12}
              selectButtonClassName={`${errorMessage?.country ? `!border !border-red-500` : ``} dark:!bg-darkOne dark:!text-white`}
              className="mt-1 w-full *:dark:!border-darkBorder *:dark:!bg-darkOne *:dark:!text-white"
              selected={handleState?.personalData?.country}
              onSelect={(country) => {
                setHandleState((prevState) => ({
                  ...prevState,
                  personalData: {
                    ...prevState.personalData,
                    country: country,
                  },
                }));
              }}
              countries={countryList}
              searchable
            />
            {errorMessage?.country && <span className="ml-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.country}</span>}
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>

      {/* Time Zone Dropdown */}
      <div className="mt-2 flex w-3/5 items-center justify-between gap-2 p-1">
        <span className="text-sm font-medium dark:!text-white">
          Time Zone <span className="text-red-500">*</span>
        </span>
        <div className="flex w-2/3 gap-3">
          <div className="w-full">
            <CustomMenu
              open={open?.timezone}
              onOpen={() => setOpen((prev: any) => ({ ...prev, timezone: true }))}
              onClose={() => setOpen((prev: any) => ({ ...prev, timezone: false }))}
              btnContent={
                <div className="flex items-center gap-2 dark:!bg-darkOne dark:text-white ">
                  <MdAccessTime />
                  {handleState?.personalData?.time_zone || 'select'}
                </div>
              }
              btnClassName={`w-full timezone-dropdown-btn justify-between dark:!bg-darkOne items-center !h-9 !text-column !text-sm !rounded-md !font-normal truncate !bg-white ${
                errorMessage?.time_zone ? `!border !border-red-500` : ``
              }`}
              menuListClassName="w-full !mt-0.5 !p-0 dark:shadow-darkBorder"
              rightIcon={<BiChevronDown size={18} />}
              btnId="industry_drop_down"
            >
              <div className="!max-h-48 overflow-y-auto dark:bg-darkOne ">
                <div className="w-11-/12 relative m-2 flex items-center rounded-md border">
                  <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
                  <input
                    type="text"
                    placeholder="Search"
                    value={handleState?.timeZoneSearch}
                    onChange={handleTimeZoneSearchChange}
                    className="input input-bordered w-full rounded-md  p-1 pl-6 text-sm focus:outline-none dark:bg-darkTwo dark:text-white"
                  />
                </div>
                {filteredTimeZones?.map((items, id) => (
                  <button
                    key={id}
                    onClick={() => {
                      setHandleState((prevState) => ({
                        ...prevState,
                        personalData: {
                          ...prevState.personalData,
                          time_zone: `${items?.name}(${items?.utc_offset})`,
                        },
                      }));
                      setOpen((prev: any) => ({ ...prev, timezone: false }));
                    }}
                    id={items?.name}
                    className={`timezone-dropdown-btn-${id} text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:bg-darkThree dark:text-white`}
                  >
                    {`${items?.name}(${items?.utc_offset})`}
                  </button>
                ))}
              </div>
            </CustomMenu>
            {errorMessage?.time_zone && (
              <span className="ml-1 mt-1 flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.time_zone}</span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2 flex items-center  gap-3">
        <div className="h-px w-full bg-gray-200 dark:!bg-darkThree" />
      </div>
    </div>
  );
};

export default PersonalDetails;
