import { Tabs, TabList, Tab, TabIndicator, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { createContext, Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseApi } from 'common/api/common/base-api';
import { initialLayout } from 'common/dto/initial-value/views/campaign-insights';
import { toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';
import { setCampaignName, setSequence } from 'store/campaign-v2';
import { ContentsType, LayoutItemType } from 'common/dto/types/views/campaign-insights';
import ProspectsInsights from './prospects';
import { setProspectsList } from 'store/campaign-insights';
import { useParams } from 'react-router-dom';
import { LocalStorage } from 'common/api/common/storage';

const AnalyticsInsights = React.lazy(() => import('./analytics'));
// const ProspectsInsights = React.lazy(() => import('./prospects'));
const SequenceInsights = React.lazy(() => import('./sequence'));
const SettingsInsights = React.lazy(() => import('./settings'));

export const InsightsContext = createContext(null);

const CampaignInsights = () => {
  const [sequenceData, setSequenceData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [contents, setContents] = React.useState<ContentsType[]>([]);
  const [layout, setLayout] = useState<LayoutItemType[]>([]);
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [campaignTime, setCampaignTime] = useState({ campaign_first_activity_date: '', campaign_last_activity_date: '' });
  const [progressData, setProgressData] = useState([]);
  const [loadState, setLoadState] = useState(true);
  const [prospectLoader, setProspectLoader] = useState(true);
  const { uuid } = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    Promise.all([fetchProgressData(), fetchSequenceData(), fetchChartData(), fetchSettingsData()])
      .then(([response1, response2, response3, response4]) => {
        dispatch(setCampaignName(response1?.campaign_name));
        handleContents(response1);
        setProgressData(response1);
        setSequenceData(response2);
        setChartData(response3?.campaign_activity_by_date);
        dispatch(setSequence(response2));
        setSettingsData(response4);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoadState(false);
      });

    setLayout(() => {
      const savedLayout: any = LocalStorage.getItem('gridLayout');
      return savedLayout ? JSON?.parse(savedLayout) : initialLayout;
      // return initialLayout;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const fetchProgressData = async () => {
    try {
      const response = await BaseApi.post('/campaign/v2/insights/analytics/progress', { uuid });
      if (!response?.error) {
        return response?.data;
      } else {
        toast.error(response?.message, toastState.error);
        console.error(response?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSequenceData = async () => {
    try {
      const response = await BaseApi.post('/campaign/v2/insights/analytics/sequence', { uuid });
      if (!response?.error) {
        return response?.data?.sequence_report;
      } else {
        toast.error(response?.message, toastState.error);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchChartData = async () => {
    try {
      const response = await BaseApi.post('/campaign/v2/insights/analytics/chart', { uuid });
      if (!response?.error) {
        return response?.data;
      } else {
        toast.error(response?.message, toastState.error);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSettingsData = async () => {
    try {
      const response = await BaseApi.get(`/campaign/v2/insights/settings/${uuid}`);
      if (!response?.error) {
        return response?.data;
      } else {
        toast.error(response?.message, toastState.error);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProspectsData = async () => {
    try {
      const response = await BaseApi.get(`/campaign/v2/insights/prospects/${uuid}`);
      if (!response?.error) {
        const data = response?.data?.data?.map(({ email, status, ...item }: any) => {
          return { email, status, ...item };
        });
        dispatch(setProspectsList(data));
        setCampaignTime({
          campaign_first_activity_date: response?.data?.campaign_first_activity_date,
          campaign_last_activity_date: response?.data?.campaign_last_activity_date,
        });
        setData({ data, count: response?.data?.total_records?.count });
      } else {
        toast.error(response?.message, toastState.error);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setProspectLoader(false);
    }
  };

  const handleContents = (data: any) => {
    setContents([
      { i: '1', type: 'linearProgressBar', percentage: data?.sent_percentage },
      { i: '2', type: 'circularProgressBar', color: '#00D8B6', title: 'Sent', percentage: data?.sent_percentage, count: data?.sent_count },
      { i: '3', type: 'circularProgressBar', color: '#008FFB', title: 'Opened', percentage: data?.open_percentage, count: data?.open_count },
      { i: '4', type: 'circularProgressBar', color: '#FFB01A', title: 'Replied', percentage: data?.replied_percentage, count: data?.replied_count },
      { i: '5', type: 'circularProgressBar', color: '#775DD0', title: 'Unsubscribed', percentage: data?.unsubscribed_percentage, count: data?.unsubscribed_count },
      { i: '6', type: 'circularProgressBar', color: '#FFEE58', title: 'Contacts', percentage: data?.prospects_count || 0, count: data?.unsubscribed_count || 0 },
      { i: '7', type: 'barChartStacked' },
      { i: '8', type: 'barChartUnstacked' },
      { i: '9', type: 'areaChart' },
      { i: '10', type: 'dataTable' },
    ]);
  };

  return (
    <div className={`mt-3 min-h-[100vh] w-full overflow-auto rounded-lg bg-white px-[0.35rem] py-1.5 shadow-sm before:mt-2.5 dark:bg-navy-700 dark:text-white`}>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Tab _selected={{ color: '#6941C6' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[0.73rem] font-medium">Analytics</span>
          </Tab>
          <Tab _selected={{ color: '#6941C6' }} color={'#7C8493'} paddingX={'.65rem'} onClick={() => data?.data?.length === 0 && fetchProspectsData()}>
            <span className="text-[0.73rem] font-medium">Prospects</span>
          </Tab>
          <Tab _selected={{ color: '#6941C6' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[0.73rem] font-medium">Sequence</span>
          </Tab>
          <Tab _selected={{ color: '#6941C6' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="text-[0.73rem] font-medium">Settings</span>
          </Tab>
        </TabList>

        <TabIndicator mt="-2px" height="2px" className="scale-x-[85%] bg-blueSecondary" borderTopRadius="20px" />

        <TabPanels>
          <TabPanel padding={0} className="noscroll">
            <InsightsContext.Provider value={{ loadState, contents, sequenceData, chartData, layout, setLayout }}>
              <Suspense fallback={<div>loading...</div>}>
                <AnalyticsInsights />
              </Suspense>
            </InsightsContext.Provider>
          </TabPanel>
          <TabPanel padding={0}>
            <InsightsContext.Provider value={{ uuid, data, setData, campaignTime, prospectLoader }}>
              <ProspectsInsights />
            </InsightsContext.Provider>
          </TabPanel>
          <TabPanel padding={0}>
            <InsightsContext.Provider value={{ progressData }}>
              <Suspense fallback={<div>loading...</div>}>
                <SequenceInsights />
              </Suspense>
            </InsightsContext.Provider>
          </TabPanel>
          <TabPanel padding={0}>
            <InsightsContext.Provider value={{ settingsData }}>
              <Suspense fallback={<div>loading...</div>}>
                <SettingsInsights />
              </Suspense>
            </InsightsContext.Provider>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default CampaignInsights;
