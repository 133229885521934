/* eslint-disable react-hooks/exhaustive-deps */
// src/hooks/usePageTitle.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import titles from '../titles';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from 'store';
import { setHeadingTitle } from 'store/custom';

const usePageTitle = () => {
  const dispatch = useAppDispatch();
  const { environment } = useAppSelector((state) => state.globalsettings);
  console.log(environment, 'environment');
  useEffect(() => {
    const themeAppSetup: any = {
      '--background-100': '#FFFFFF',
      '--background-900': '#070f2e',
      '--shadow-100': 'rgba(112, 144, 176, 0.08)',
      '--color-50': '#E9E3FF',
      '--color-100': '#C0B8FE',
      '--color-200': '#A195FD',
      '--color-300': '#8171FC',
      '--color-400': '#7551FF',
      '--color-500': '#422AFB',
      '--color-600': '#3311DB',
      '--color-700': '#2111A5',
      '--color-800': '#190793',
      '--color-900': '#11047A',
    };
    for (let color in themeAppSetup) {
      document.documentElement.style.setProperty(color, themeAppSetup[color]);
    }
  }, []);

  const location = useLocation();
  const path = location.pathname;
  const headingTitleList = [
    { path: '/sign-up', title: environment?.app_default_feature?.is_org_enbaled ? 'Sign Up | Sparkle' : 'Sign Up', heading: '' },
    { path: '/sign-in', title: environment?.app_default_feature?.is_org_enbaled ? 'Login | Sparkle' : 'Login', heading: '' },
    { path: '/forgot-password', title: 'Forgot Password | Sparkle', heading: '' },
    { path: '/reset/password', title: 'Reset Password | Sparkle', heading: '' },
    { path: '/campaigns/insights', title: 'Campaigns Insights | Sparkle', heading: 'campaign_name' },
    { path: '/campaigns', title: 'Campaigns | Sparkle', heading: 'Campaigns' },
    { path: '/blacklist-monitor', title: 'Blacklist Monitor | Sparkle', heading: 'Blacklist Monitor' },
    { path: '/sender-accounts', title: 'Sender Accounts | Sparkle', heading: 'Sender Accounts' },
    { path: '/email-verifier', title: environment?.app_default_feature?.is_org_enbaled ? 'Email Verifier | Sparkle' : 'Email Verifier', heading: 'Email Verifier' },
    { path: '/true-verifier', title: environment?.app_default_feature?.is_org_enbaled ? 'True Verifier | Sparkle' : 'True Verifier', heading: 'True Verifier' },
    { path: '/live', title: 'Live | Sparkle', heading: 'Live' },
    { path: '/crm', title: 'CRM | Sparkle', heading: 'CRM', navigation: { title: 'Back', path: '/crm' } },

    { path: '/inbox', title: 'Unified Inbox | Sparkle', heading: 'Unified Inbox' },
    { path: '/brands', title: 'Brands | Sparkle', heading: 'Brands' },
    { path: '/unmatched-emails', title: 'Unmatched Emails | Sparkle', heading: 'Unmatched Emails' },
    { path: '/admin-tickets', title: 'Admin Tickets | Sparkle', heading: '' },
    { path: '/admin-accounts', title: 'Admin Accounts | Sparkle', heading: '' },
    { path: '/admin-settings', title: 'Admin Settings | Sparkle', heading: '' },
    {
      path: '/settings',
      title: environment?.app_default_feature?.is_org_enbaled ? 'Settings | Sparkle' : 'Settings',
      heading: 'Settings',
      navigation: { title: 'Back', path: environment?.app_default_feature?.base_url },
    },
    {
      path: '/',
      title: environment?.app_default_feature?.is_org_enbaled ? 'Sparkle' : 'EmailVerifier',
      heading: environment?.app_default_feature?.is_org_enbaled ? 'Sparkle' : 'Email Verifier',
    },
  ];

  useEffect(() => {
    const headingTitleData = headingTitleList?.find((item: { path: string }) => path?.startsWith(item?.path));
    dispatch(setHeadingTitle(headingTitleData));
    document.title = headingTitleData?.title;
  }, [path]);
  return (
    <Helmet>
      <title>{environment?.app_default_feature?.is_org_enbaled ? 'EmailVerifier' : 'Sparkle'}</title>
    </Helmet>
  );
};

export default usePageTitle;
