import { BaseApi } from 'common/api/common/base-api';
import Input from 'common/sparkle-common/Input';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';

// type DomainProps = {
//   setHandleNextStep: React.Dispatch<React.SetStateAction<() => void>>;
// };
type DomainProps = {
  setHandleNextStep: any;
};
const DmarkSetup: React.FC<DomainProps> = ({ setHandleNextStep }) => {
  const { domain_list, domain_uuid } = useAppSelector((state) => state.senderGroupListData);
  const [trigger,setTrigger] = useState(false)
  const [domainData, setDomainData] = useState({
    email_ids_per_domain: [],
    domain_forwarding_url_list: [],
    dmark_email: '',
    uuid:domain_uuid
  });
  const handleDomainEmailIdInputChange = (e: React.ChangeEvent<HTMLInputElement>, domain: string) => {
    const { value } = e.target;
    setDomainData((prev) => {
      const domainIndex = prev.email_ids_per_domain.findIndex((item) => item.domain === domain);
      if (domainIndex !== -1) {
        const updatedEmailIdsPerDomain = prev.email_ids_per_domain.map((item, index) => (index === domainIndex ? { ...item, email_count: Number(value) } : item));
        return {
          ...prev,
          email_ids_per_domain: updatedEmailIdsPerDomain,
        };
      } else {
        return {
          ...prev,
          email_ids_per_domain: [...prev.email_ids_per_domain, { domain: domain, email_count: Number(value) }],
        };
      }
    });
  };

  const handleDmarkUrlChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainData((prev) => {
      const updatedUrlList = [...prev.domain_forwarding_url_list];
      if (updatedUrlList[index]) {
        updatedUrlList[index] = { ...updatedUrlList[index], url: e.target.value };
      } else {
        updatedUrlList[index] = { url: e.target.value };
      }
      return { ...prev, domain_forwarding_url_list: updatedUrlList };
    });
  };

  useEffect(() => {
    setHandleNextStep(() => handleNext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHandleNextStep,trigger]);

  const handleNext = async() => {
    setTrigger((prev)=>!prev)

    if(domain_uuid){
      const response = await BaseApi.patch(`/buy/domain`, domainData)
      if(response.data){
        return true
      }
      return false
    }
    console.log('DmarkSetup next logic');
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-3/5 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">DMARC & Forwarding</p>
        <hr />

        <div className="my-4 flex gap-5 bg-[#f9fafc] p-4">
          <div className="w-1/3">
            <p className="mb-2 text-sm font-semibold text-[#2c5886]">Domain forwarding</p>
            <p className="w-56 text-xs text-column">Enter domain to forward users to after they visit domain created in the last step</p>
          </div>
          <div className="w-1/3 ">
            <p className="mb-2 text-xs font-semibold text-[#2c5886]">Recommended</p>
            <Input onChange={(e) => handleDmarkUrlChange(0, e)} />
          </div>
          <div className="w-1/3">
            <p className="mb-2 text-xs font-semibold text-[#2c5886]">Recommended</p>
            <Input onChange={(e) => handleDmarkUrlChange(1, e)} />
          </div>
        </div>

        <div className="my-4 flex justify-between gap-5 bg-[#f9fafc] p-4">
          <div className="w-1/2">
            <p className="mb-2 text-sm font-semibold text-[#2c5886]">DMARK Email</p>
            <p className="text-xs text-column">Enter email address to receive DMARC reports</p>
          </div>
          <div className="w-1/2 ">
            <p className="mb-2 text-xs font-semibold text-[#2c5886]">Optional</p>
            <Input onChange={(e) => setDomainData((prev) => ({ ...prev, dmark_email: e.target.value }))} />
          </div>
        </div>

        <div className="mb-4 flex flex-col gap-3">
          <div className="bg-[#fafafc] p-3">
            <div className="flex items-start justify-between gap-3">
              <div>
                <p className="text-sm font-semibold text-[#2c5886]">Email ids per domain</p>
                <p className="mt-2 text-xs text-column">Auto generate number of domains you need.</p>
              </div>
              <div className=" w-3/5 px-2 ">
                <p className="mb-2 mr-32 text-end text-xs font-medium text-heading">No of email id’s</p>
                {domain_list?.map((item: { domain: string }) => (
                  <div className="flex items-center  justify-between gap-3" key={item.domain}>
                    <p className="text-xs font-semibold text-heading">{item.domain}</p>
                    <Input inputType="number" customClass="my-1 p-2 !w-56" onChange={(e) => handleDomainEmailIdInputChange(e, item?.domain)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmarkSetup;
