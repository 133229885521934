import React from 'react';
import Chart from 'react-apexcharts';

interface EmailAreaChartProps {
  data: any[];
}
type TooltipParams = {
  series: number[][],
  seriesIndex: number,
  dataPointIndex: number,
  w: {
    globals: {
      labels: string[]
    }
  }
};

const EmailAreaChart = (props : EmailAreaChartProps) => {
    const {data} = props
    const [options, setOptions] = React.useState({})
    
    React.useEffect(()=> setOptions({
      colors: ['#4318FF', '#4318FF'],     
stroke: {
  show: true,
  curve: 'straight',
  lineCap: 'butt',
  colors: undefined,
  width: 1,
  dashArray: 0, 
},
      chart: {
        id: 'area-datetime',
        type: 'area',
        toolbar:{
          show: false
        },
        zoom: {
          autoScaleYaxis: true
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,      // you can either change hear to disable all grids
},   
      yaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false
        },
      },
      xaxis: {
        show: false,
        labels: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      fill: {
        color: 'red',
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }: TooltipParams) {
          console.log(series)
          console.log(seriesIndex)
          console.log(dataPointIndex)
          console.log(w)
          return (
            `<div style='background-color: #2B3674; height: min-content; width: min-content; padding: 5px; display: flex; flex-direction: column; justify-content: space-around;'>
            <span style='color: white;font-size: 0.7rem;'>${w.globals.labels[dataPointIndex]}</span>
            <span style='color: white; font-size: 0.5rem;'>${series[seriesIndex][dataPointIndex]}</span>
            </div>`
          );
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[]
)

  return (
    <div className='h-5 flex items-center pt-3'>
      <Chart options={options} series={[{ data: data }]} type="area" height={90} width={125}/>
    </div>
  );
};

export default EmailAreaChart;
