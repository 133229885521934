import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
// import MessageBox from 'common/sparkle-common/MessageBox';
import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor';
import { toastState } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import React from 'react';
import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setMessageSent } from 'store/crm';

const ComposeMailPopup = (props: any) => {
  const { isOpen, onClose } = props;
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_id } = opportunityInboxViewData;
  const editorRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromList, setFromList] = useState<any>([]);
  const [primaryEmails, setPrimaryEmails] = useState([]);
  const dispatch = useAppDispatch();
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [mailData, setMailData] = useState({
    from: '',
    sender_id: '',
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    opportunity_id: opportunity_id,
    message: '',
  });
  const removeDuplicates = async (prospectAccounts: any) => {
    const seenEmails = new Set();

    return prospectAccounts.filter((account: any) => {
      const email = account.prospect_email;
      if (!seenEmails.has(email)) {
        seenEmails.add(email);
        return true;
      }
      return false;
    });
  };

  const handleCloseModal = () => {
    setShowBcc((prev) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    setShowCc((prev) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    setMailData({
      from: '',
      sender_id: '',
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      opportunity_id: opportunity_id,
      message: '',
    });
  };
  useEffect(() => {
    const getSenderAccountList = async () => {
      try {
        if (opportunity_id) {
          const response = await BaseApi.get(`crm/pipeline/opportunity/sender/account/${opportunity_id}`);
          if (response?.status === 200) {
            setFromList(response.data);
            const uniqueProspects = await removeDuplicates(response?.data?.prospect_account);

            const transformedUniqueProspects = await uniqueProspects.map((email: any, index: number) => ({
              id: index,
              name: email.prospect_email,
              prospect_name: email.prospect_name ? email.prospect_name : email.prospect_email.split('@')[0],
            }));
            setMailData((prevData) => ({
              ...prevData,
              to: transformedUniqueProspects,
            }));
            setPrimaryEmails(transformedUniqueProspects);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSenderAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOnchange = (value: any, varient: string) => {
    if (varient === 'from')
      setMailData((prev) => ({
        from: value.email,
        sender_id: value.uuid,
        to: prev.to,
        subject: prev.subject,
        opportunity_id: prev.opportunity_id,
        message: prev.message,
        cc: prev.cc,
        bcc: prev.bcc,
      }));

    if (varient === 'subject')
      setMailData((prev) => ({
        from: prev.from,
        sender_id: prev.sender_id,
        to: prev.to,
        subject: value,
        opportunity_id: prev.opportunity_id,
        message: prev.message,
        cc: prev.cc,
        bcc: prev.bcc,
      }));
    if (varient === 'message')
      setMailData((prev) => ({
        from: prev.from,
        sender_id: prev.sender_id,
        to: prev.to,
        subject: prev.subject,
        opportunity_id: prev.opportunity_id,
        message: value?.toString(),
        cc: prev.cc,
        bcc: prev.bcc,
      }));
  };

  const sendMailOnClick = async () => {
    const isPrimaryEmailPresent = primaryEmails.some((primaryEmail) => mailData.to.some((email) => email.name === primaryEmail.name));

    if (!isPrimaryEmailPresent) {
      toast.error('At least one primary email must be present.', toastState.error);
      return;
    }

    const prospect_email = mailData?.to?.map((item) => ({
      email: item.name,
      name: item.prospect_name ? item.prospect_name : item.name.split('@')[0],
    }));

    const updatedCC = mailData?.cc?.map((item) => ({
      email: item.name,
      name: item.name.split('@')[0],
    }));
    const updatedBCc = mailData?.bcc?.map((item) => ({
      email: item.name,
      name: item.name.split('@')[0],
    }));
    try {
      setIsLoading(true);
      if (opportunity_id && mailData?.message) {
        const payload = {
          opportunity_id: opportunity_id,
          conversation_details: {
            prospect_email: prospect_email,
            sender_id: mailData.sender_id,
            variant: 'email',
            primary_email: mailData?.to[0]?.name,
            message: mailData.message,
            subject: mailData.subject.replace(/\s+/g, ' ').trim(),
            cc: updatedCC,
            bcc: updatedBCc,
          },
        };
        const data = await BaseApi.post(`/crm/pipeline/opportunity/create/mail/communication`, payload);
        if (data?.status === 200) {
          setIsLoading(false);
          dispatch(setMessageSent());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowBcc(false);
      setShowCc(false);
      onClose();
      setMailData({
        from: '',
        sender_id: '',
        to: [],
        cc: [],
        bcc: [],
        subject: '',
        opportunity_id: opportunity_id,
        message: '',
      });
      setIsLoading(false);
    }
  };

  const handleUpdateTo = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id, name: item?.name, prospect_name: item.prospect_name ? item.prospect_name : item.name.split('@')[0] };
    });

    setMailData((prevData) => ({
      ...prevData,
      to: email_list,
    }));
  };

  const handleUpdateCC = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id?.toString(), name: item?.name };
    });
    setMailData((prevData) => ({
      ...prevData,
      cc: email_list,
    }));
  };

  const handleUpdateBCC = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id?.toString(), name: item?.name };
    });
    setMailData((prevData) => ({
      ...prevData,
      bcc: email_list,
    }));
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          handleCloseModal();
          onClose();
        }}
        size={'2xl'}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="!p-0 dark:border-darkBorder2" overflow={'auto'}>
          <ModalHeader className="!m-0 !p-0">
            <div className="space-y-1 bg-heading p-4 dark:bg-darkTwo">
              <h5 className=" text-sm font-thin text-white ">Compose email</h5>
              <ModalCloseButton size="sm" color={'#fff'} id="add-blacklist-pop-close" className="mr-3 " />
            </div>
          </ModalHeader>
          <hr />
          <ModalBody className=" !px-0  dark:bg-darkTwo">
            <div className="flex !h-[30rem] flex-col gap-4 overflow-auto px-4 py-2">
              <div className="flex items-center justify-start gap-2 border-b border-[#EDEFF1] py-2 text-xs text-column dark:border-darkBorder dark:text-white">
                From
                <Menu>
                  <MenuButton className="from-compose-btn !w-[20rem] truncate  rounded-lg !px-2 !text-xs !font-medium !text-heading dark:!text-column">
                    <div className="flex justify-between py-1">
                      <span className="truncate">{mailData?.from ? mailData.from : 'select sender'} </span>
                      <BiChevronDown className="size-4 !text-[#2B3674] dark:!text-column" />
                    </div>
                  </MenuButton>
                  <MenuList
                    minW="0"
                    w={'122px'}
                    className="!h-[109px] !w-[20rem] overflow-y-auto !rounded-lg bg-white !p-0 px-2 shadow-lg dark:border-darkBorder dark:bg-darkThree"
                    style={{ width: '120px', height: '109px' }}
                  >
                    {fromList?.sender?.length
                      ? fromList?.sender?.map((item: any, index: number) => (
                          <MenuItem
                            key={item.uuid}
                            onClick={() => handleOnchange({ email: item?.account_details?.email, uuid: item?.uuid }, 'from')}
                            className={`flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white from-email-${index} dark:border-darkBorder dark:!text-white hover:dark:!bg-radioPurple  `}
                          >
                            <span className={`from_sender_option truncate`}>{item?.account_details?.email}</span>
                          </MenuItem>
                        ))
                      : null}
                  </MenuList>
                </Menu>
              </div>
              <div className="flex items-start border-b border-[#EDEFF1] dark:border-darkBorder ">
                <div className="flex  items-start justify-start gap-2  py-2 text-xs text-column dark:text-white">
                  To
                  <TagsField
                    id="description"
                    onChange={(item: any) => {
                      handleUpdateTo(item);
                    }}
                    placeholderTags={[
                      ...mailData?.to?.map((email, index) => {
                        return { id: index, name: email.name, prospect_name: email.prospect_name ? email.prospect_name : email.name.split('@')[0] };
                      }),
                    ]}
                    placeholder=""
                    pattern={
                      // eslint-disable-next-line no-useless-escape
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    }
                    extra="!border-none !mb-0  -mt-4  !w-[32rem]"
                    tagsClass="!bg-tagsbg !text-column hover:!bg-gray-100"
                    className="h-stretch flex-rowflex-wrap relative flex rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
                  />
                </div>
                <div className="mr-6 ms-6 cursor-pointer" onClick={() => setShowCc((prev) => !prev)}>
                  <span className="text-xs  text-column  dark:text-white">CC</span>
                </div>
                <div className=" cursor-pointer" onClick={() => setShowBcc((prev) => !prev)}>
                  <span className="text-xs text-column dark:text-white">BCC</span>
                </div>
              </div>
              {showBcc && (
                <div className="flex items-start justify-start gap-2 border-b  border-[#EDEFF1]  pb-2 text-xs text-column dark:border-darkBorder dark:text-white">
                  <span className="text-xs  text-column  dark:text-white">BCC</span>
                  <TagsField
                    id="description"
                    onChange={(item: any) => {
                      handleUpdateBCC(item);
                    }}
                    placeholderTags={[
                      ...mailData?.bcc?.map((email) => {
                        return { id: parseInt(email?.id), name: email.name };
                      }),
                    ]}
                    placeholder=""
                    pattern={
                      // eslint-disable-next-line no-useless-escape
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    }
                    tagsClass="!bg-tagsbg !text-column hover:!bg-gray-100"
                    extra="border-none !mb-0 -mt-4"
                    className="h-stretch flex-rowflex-wrap relative flex !max-h-6 rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
                  />
                </div>
              )}
              {showCc && (
                <div className="flex items-start  justify-start gap-2 border-b  border-[#EDEFF1]  pb-2 text-xs text-column  dark:border-darkBorder dark:text-white">
                  <span className="text-xs  text-[#667085]  dark:text-white">CC</span>
                  <TagsField
                    id="description"
                    onChange={(item: any) => {
                      handleUpdateCC(item);
                    }}
                    placeholderTags={[
                      ...mailData?.cc?.map((email) => {
                        return { id: parseInt(email?.id), name: email.name };
                      }),
                    ]}
                    placeholder=""
                    pattern={
                      // eslint-disable-next-line no-useless-escape
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    }
                    tagsClass="!bg-tagsbg !text-column hover:!bg-gray-100"
                    extra=" !mb-0 -mt-4 border-none "
                    className="h-stretch flex-rowflex-wrap relative flex !max-h-6 rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
                  />
                </div>
              )}

              <div className="flex  items-center justify-start gap-2   border-[#EDEFF1] pb-2 text-xs text-column dark:text-white">
                <InputField
                  placeholder="Subject"
                  label=""
                  extra="!w-full"
                  id="subject-compose"
                  inputClass="border-none py-1 h-4 text-column dark:text-white"
                  onChange={(e: any) => handleOnchange(e?.target?.value, 'subject')}
                />
              </div>

              <CustomSunEditor
                defaultValue={mailData?.message}
                onFocus={() => {
                  // dispatch(setVariableArea('message'));
                }}
                height="10rem"
                editorRef={editorRef}
                onChange={(value: any) => {
                  handleOnchange(value, 'message');
                }}
                leftContent={
                  <div className=" !p-0 ">
                    <Button
                      name="Send message"
                      disable={!(mailData?.message && mailData?.from && mailData?.to?.length > 0 && mailData?.subject) || isLoading}
                      loading={isLoading}
                      onClick={() => sendMailOnClick()}
                      id="compose-send-message-btn"
                    />
                  </div>
                }
              />
              {/* 
              <MessageBox
                quillRef={quillRef}
                value={mailData?.message}
                editorClass="!border-none !h-[8rem]"
                toolBarClass="!p-0 bg-white border-none"
                onChange={(e) => handleOnchange(e, 'message')}
                leftContent={
                  <div className=" !p-0 ">
                    <Button
                      name="Send message"
                      id="compose-send-message-btn"
                      disable={mailData?.message?.length === 0 || isLoading}
                      loading={isLoading}
                      onClick={() => sendMailOnClick()}
                    />
                  </div>
                }
              /> */}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ComposeMailPopup;
