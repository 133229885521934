import React from 'react';
// import { CgFile } from 'react-icons/cg';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'store';
import { SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { AiFillMail } from 'react-icons/ai';
import { setCurrentSequenceID, setCurrentVersion, setHeaderCount, setPreview, updatePreviewProspectsData, updateVariableData } from 'store/campaign-v2';
import { setCurrentStepCount, setDrawerStatus, setTotalStepCount } from 'store/custom';
import moment from 'moment';
// import saveAs from 'file-saver';

const RightSideView = () => {
    const preview = useAppSelector((state) => state?.campaignv2?.review?.preview)
    const prospects = useAppSelector((state) => state?.campaignv2?.prospects)
    const dispatch = useAppDispatch()

    const preview_list = preview?.preview_list?.filter((item: any) => (item?.receiver_email?.includes(preview?.search) || item?.receiver_name?.toLowerCase()?.includes(preview?.search)))

    const handleCurrentEmailIndex = (count: number) => {
        const value = preview.selected_index + count;
        if (value >= 0 && value < preview_list?.length) {
            dispatch(setPreview({ selected_index: value }))
        }
    };

    React.useEffect(() => {
        const missingVariableButtons = document.querySelectorAll('#missedVariable');
        if (missingVariableButtons.length) {
            missingVariableButtons.forEach(button => {
                button.addEventListener('click', () => {
                    const data = button.getAttribute('data-set');
                    dispatch(setPreview({ preview_modal: false, preview_list: [], list_count: 0, selected_index: 0, }))
                    dispatch(setCurrentStepCount(4));
                    dispatch(setTotalStepCount(4));
                    dispatch(setHeaderCount(2))
                    const update_prospect_list = prospects.prospects_list.filter((item) => {
                        return Object.values(item).some((item: any) => item.length === 0)
                    })
                    dispatch(updatePreviewProspectsData({ isPreview: true, update_prospect_list }))
                    dispatch(updateVariableData({ modal_view: true, row_email: data }))
                });
            });
        }

        const invalidVariableButtons = document.querySelectorAll('#invalidVariable');
        if (invalidVariableButtons.length) {
            invalidVariableButtons.forEach(button => {
                button.addEventListener('click', () => {
                    const data = button.getAttribute('data-set').split(',');
                    dispatch(setPreview({ preview_modal: false, preview_list: [], list_count: 0, selected_index: 0, }))
                    dispatch(setCurrentStepCount(1));
                    dispatch(setTotalStepCount(0));
                    dispatch(setHeaderCount(4))
                    dispatch(setDrawerStatus())
                    dispatch(setCurrentSequenceID(data[0]))
                    dispatch(setCurrentVersion(data[1]))
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preview.preview_list, preview.selected_index])


    return (
        <div className="p-2 ">
            {/* Nav bar */}
            <div className="flex items-center pb-3  pr-1 pt-4 text-xs text-column select-none ">
                <FaChevronLeft className="w-12 cursor-pointer select-none hover:text-brand-500" onClick={() => handleCurrentEmailIndex(-1)} />
                {preview.loading ? (
                    <div className="flex w-full justify-center">
                        <SkeletonText noOfLines={1} spacing="2" skeletonHeight="3" className="w-12" />
                    </div>
                ) : (
                    <span className="w-full text-center">
                        {preview.selected_index + 1} of {preview_list?.length}
                    </span>
                )}
                <FaChevronRight className="w-12 cursor-pointer select-none hover:text-brand-500" onClick={() => handleCurrentEmailIndex(1)} />
            </div>
            {/* Header profile */}
            {preview.loading ? (
                <div className="p-4">
                    {/* Profile loader */}
                    <div className="mt-6 flex items-center gap-3">
                        <div className="flex flex-col items-center justify-center gap-2">
                            <SkeletonCircle size="12" />
                        </div>
                        <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
                    </div>
                    {/* Subject loader */}
                    <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />

                    {/* Message loader */}
                    <SkeletonText mt="4" noOfLines={20} spacing="1.5" skeletonHeight="2" className="w-full" />
                </div>
            ) : (
                <div className='max-h-[33rem] w-[32rem] overflow-y-auto'>
                    {
                        preview_list?.[preview.selected_index]?.sequence_list?.map((message: any, index: number) => {
                            const firstName = preview_list[preview.selected_index]?.receiver_email?.split('@')
                            const profileName = preview_list[preview.selected_index]?.receiver_name || firstName[0]
                            let delayDay = 0
                            if (index) {
                                const startDate = preview_list[preview.selected_index]?.sequence_list[index - 1]?.schedule_time
                                delayDay = moment(message?.schedule_time).startOf('day').diff(moment(startDate).startOf('day'), 'days');
                            }
                            return (
                                <React.Fragment key={index}>
                                    {index > 0 ? <div className='flex items-center gap-1 py-3'>
                                        <div className='border-t-2 border-dashed border-gray-50 w-full' />
                                        {message.type === 'email' ?
                                            <div className='py-1.5 px-3 rounded-md bg-lightpurplebg w-fit flex gap-3 items-center'>
                                                <AiFillMail className='text-blueSecondary' size={18} />
                                                <span className='text-[10px] text-column whitespace-nowrap'>After {delayDay} business day{delayDay > 1 ? 's' : ''}</span></div> : <></>}
                                        <div className='border-t-2 border-dashed border-gray-50 w-full' />
                                    </div> : <></>}
                                    <div className={`ml-2 mr-4 flex items-center py-2 ${index === 0 ? 'border-t' : ''}`}>
                                        <div className="flex w-full gap-1.5">
                                            <div className='flex'>
                                                <div className="flex size-7 items-center justify-center rounded-full border bg-lightsky transition-all duration-300 capitalize text-md text-white">
                                                    {profileName[0]}
                                                </div>
                                            </div>

                                            {message.type === 'email' ? <div className="flex flex-col justify-center">
                                                <div> <span className='text-heading text-[0.9rem] font-semibold capitalize'>{profileName}</span> <span className="text-[0.7rem] text-column">{`<${preview?.preview_list[preview.selected_index]?.receiver_email}>`}</span></div>
                                                <span className="text-[.6875rem] text-column">from - <span className='font-medium'>{preview?.preview_list[preview.selected_index]?.sender_email}</span></span>
                                            </div>
                                                : <></>
                                            }
                                        </div>

                                    </div>
                                    {/* Message */}
                                    <div className="">
                                        <div className="p-3 preview-email">
                                            {/* subject */}
                                            <div className="py-2 text-sm font-semibold" dangerouslySetInnerHTML={{ __html: message.subject }} />
                                            {/* message */}
                                            <div className='text-xs mt-2 w-[30rem] overflow-x-auto textEditor' dangerouslySetInnerHTML={{ __html: message.message }} />
                                            {/* <div className='text-xs w-full border border-red-500 leading-5 text-paused-color text-wrap'>{emailData?.profile?.message}</div> */}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            )}
        </div>
    );
};

export default RightSideView;
