import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import Input from 'common/sparkle-common/Input';
import { useEffect, useRef, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import DateTimePicker from './DatePickerWithTime';

const TaskCreate = (props: any) => {
  const { taskData, handleDueSelect, handleAssigneeSelect, handleReminderSelect, handleChangeTitle, assigneeList } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const pickerRef = useRef(null);

  const reminderMenuList = [
    {
      label: '30 Mins before ',
    },
    {
      label: '1 hr before',
    },
    {
      label: '1 day before',
    },
    {
      label: '3 days before',
    },
    {
      label: '5 days before',
    },
    {
      label: '1 week before',
    },
  ];

  function useOutsideClick(ref: any, callback: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
  }

  useOutsideClick(pickerRef, () => {
    if (showCalendar) setShowCalendar(false);
  });

  function mergeDateAndTime(dateString: string, timeString: string) {
    const date = new Date(dateString); // Parses the date string
    const [time, period] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    formatDateTime(date);
  }

  function formatDateTime(date: Date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    handleDueSelect(`${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`);

    return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`;
  }

  const formateDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <div>
      <div>
        <Input
          placeholder="Task heading"
          customClass={`border-none !outline-none ${'task-heading-input'} dar`}
          onChange={(e) => handleChangeTitle(e.target.value)}
          value={taskData?.title}
        />
        <div className="mx-3 flex w-11/12 justify-between  border-t border-[#EDEFF1] py-2  ">
          <div className="relative flex w-1/3 flex-col gap-2">
            <p className="text-[0.6rem] font-medium text-heading">Due Date</p>
            <div id="task-date-picker" className="z-20">
              <Menu>
                <MenuButton
                  _hover={{ bg: '#fff' }}
                  _active={{ bg: '#fff' }}
                  bg="#fff"
                  className="task-date-picker !flex !text-[0.7rem] !font-bold !text-blueSecondary "
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <p className="flex items-center gap-2 text-[0.7rem] font-bold text-blueSecondary">
                    <span> {formateDate(taskData?.due) !== 'Invalid Date' ? formateDate(taskData.due) : 'Select due date'}</span>{' '}
                    <BiSolidDownArrow className="text-xs text-blackDark" />
                  </p>
                </MenuButton>
                <MenuList className="!border-none !bg-none">
                  <div ref={pickerRef}>{showCalendar ? <DateTimePicker mergeDateAndTime={mergeDateAndTime} due={formateDate(taskData.due)} /> : null}</div>
                </MenuList>
              </Menu>
            </div>
          </div>

          <div className="flex w-1/3 flex-col gap-2">
            <p className="text-[0.6rem] font-medium text-heading">Reminder</p>
            <div className="z-20">
              <Menu>
                <MenuButton _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className="task-remainder-menu !flex !text-[0.7rem] !font-bold !text-blueSecondary ">
                  <p className="flex items-center gap-2 text-[0.7rem] font-bold text-blueSecondary">
                    <span> {taskData.reminder}</span> <BiSolidDownArrow className="text-xs text-blackDark" />
                  </p>
                </MenuButton>
                <MenuList
                  minW="0"
                  className="!z-20 !h-[218px] !w-[130px] !rounded-lg border border-blueSecondary bg-white !p-0 shadow-lg "
                  style={{ width: '130px', height: '218px' }}
                >
                  {reminderMenuList.map((item, index) => (
                    <MenuItem
                      onClick={() => {
                        handleReminderSelect(item.label);
                      }}
                      className={`flex ${index}-remainder items-center space-x-2 !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674]  hover:!bg-[#4318FF] hover:!text-white ${
                        index === reminderMenuList.length - 1 ? 'rounded-b-lg' : ''
                      } ${index === 0 ? 'rounded-t-lg hover:rounded-t-lg' : ''}`}
                    >
                      <span className="!overflow-hidden overflow-ellipsis whitespace-nowrap">{item.label}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-[0.6rem] font-medium text-heading">Assigned To</p>
            {/* <p className="text-[0.7rem] font-bold text-blueSecondary ">In four days</p>
             */}
            <div id="task-assignee-menu" className="z-20">
              <Menu>
                <MenuButton _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className="task-assignee-menu !flex !text-[0.7rem] !font-bold !text-blueSecondary ">
                  <p className="flex items-center gap-2 text-[0.7rem] font-bold text-blueSecondary ">
                    <span> {taskData.assingee}</span> <BiSolidDownArrow className="text-xs text-blackDark" />
                  </p>
                </MenuButton>
                <MenuList minW="0" w={'140px'} className=" !w-[140px] !rounded-lg bg-white !p-0 shadow-lg  " style={{ width: '140px', minHeight: '45px', maxHeight: '109px' }}>
                  {assigneeList?.map((item: { first_name: string; last_name: string; uuid: string }, index: number) => (
                    <MenuItem
                      onClick={() => {
                        handleAssigneeSelect({ name: `${item.first_name} ${item.last_name}`, uuid: item.uuid });
                      }}
                      className={`flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white ${index}-asignee`}
                    >
                      <span className="!overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {item.first_name} {item.last_name}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
        <div className="relative mx-3 w-[38rem] py-2 text-justify">
          <p className=" text-[0.8rem] font-medium leading-4 text-heading"></p>
        </div>
      </div>
    </div>
  );
};

export default TaskCreate;
