import React from 'react';
import AddDoNotLists from './add-lists';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import RowSettings from './row-setting';
import moment from 'moment';
import { FiSearch } from 'react-icons/fi';
import TableLoader from 'components/Loaders/TableViewLoader';
import { TbCloudDownload } from 'react-icons/tb';
import Button from 'common/sparkle-common/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import CustomFilter from 'components/customFilter';
import { checkRolePermissionStatus, CSVFileDownloader, jwtEncrypt, setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import { MdDeleteOutline } from 'react-icons/md';
import { donotEmailFilterOption } from 'common/utils/page-filter';

const AddDoNotEmailList = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });
  const [optionData, setOptionData] = React.useState([]);
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  const dispatch = useAppDispatch();
  const searchRef = React.useRef(null);
  const columnHelper = createColumnHelper<any>();
  const [tableSelection, setTableSelection] = React.useState({ selectedIds: [], globalOpt: false });

  const fetchList = React.useCallback(async () => {
    try {
      const brandId = sessionStorage.getItem('brandId');
      const response = await BaseApi.get(`/donot/list/linked/with/email/${brandId}`);
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      }
      const data = response?.data;
      setData((prev) => ({ ...prev, data: data?.data_list, count: data?.total_records?.count }));
    } catch (error) {
      console.log('Do not email list fetching error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [donotemailStatus, setDonotemailStatus] = React.useState(null);

  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'do_not_email_list');
      setDonotemailStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // donot entry fetch function
    const fetchEntryList = async () => {
      setData((prev) => ({ ...prev, loading: true }));
      if (queryParams.sort.order === '0') fetchList();
      else {
        try {
          const brandId = sessionStorage.getItem('brandId');
          const query = setAndGetQueryParams([
            { key: 'search', value: queryParams?.search },
            { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/donot/list/linked/with/email/${brandId}?${query}`);
          if (response?.error) {
            dispatch(getErrorMessage(response?.message));
          }
          const responseData: any = response.data;
          if (!responseData?.data_list) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data_list?.length < 25) {
            setData((prev) => ({ ...prev, data: [...responseData?.data_list], count: responseData?.data_list?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...responseData?.data_list], count: responseData?.total_records?.count }));
          }
          setQueryParams((prev) => ({ ...prev, offset: 25 }));
        } catch (error) {
          console.log(error);
        } finally {
          setData((prev) => ({ ...prev, loading: false }));
        }
      }
    };
    fetchEntryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  React.useEffect(() => {
    setOptionData(donotEmailFilterOption);
  }, []);

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-3 flex items-center">
          <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-3 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('data', {
      id: 'data',
      enableSorting: false,
      header: () => <div>Email ID</div>,
      cell: (info) => <h5 className="text-xs font-semibold text-gray-900 dark:text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('added_by', {
      id: 'added_by',
      enableSorting: false,
      header: () => <div>Added By</div>,
      cell: (info) => <h5 className="text-xs dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: () => <div className="text-start dark:text-gray-300">Added on</div>,
      cell: (info) => {
        const utcDateString = info.getValue();
        // const userTimeZone = 'Asia/Kolkata';
        // const utcDate = new Date(utcDateString);
        // const localDate = utcDate.toLocaleString('en-US', {
        //   timeZone: userTimeZone,
        // });
        const date = moment(utcDateString).format('MMM DD, YYYY');
        return <h5 className="text-xs">{date}</h5>;
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin ">{''}</div>,
      cell: (row) => {
        return <>{<RowSettings id={row.row.id} uuidRow={row.getValue()} rowData={row.row.original} table={table} setData={setData} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
      return { ...prev, filter: encryptedData };
    });
  };

  const handleListSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const brandId = sessionStorage.getItem('brandId');
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/donot/email/list/${brandId}?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list], count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 25,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  const handleDelete = async () => {
    const uuids = tableSelection?.selectedIds?.map((item) => item?.uuid);
    const brandId = sessionStorage.getItem('brandId');
    try {
      const params: any = {
        uuid: brandId,
        data_variant_id: uuids,
      };
      const response = await BaseApi.delete('/donot/list/delete/variant/list', { data: params });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        dispatch(setFlagStatus(!flagStatus));
      }
    } catch (error) {
      console.log('Donot email list single delete error', error);
    } finally {
      table.resetRowSelection();
    }
  };


  const handleExportCSV = () => {
    const exportData = tableSelection?.selectedIds.map((item) => ({
      'Email/Domain': item?.data,
      'Added By': item?.added_by,
      'Added On': `${moment(item?.cdate).format('MMM DD YYYY')}`,
    }));
    CSVFileDownloader(exportData, 'donot_entries_list.csv');
  };
  return (
    <div className="h-[90vh] overflow-y-auto">
      <AddDoNotLists />
      {/* Table */}
      <div className={`mx-6 mt-2.5 rounded-lg bg-white px-9 pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-6 mt-1 flex items-center py-0.5 sm:gap-32 lg:gap-32 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        md:w-[13rem] lg:w-[26rem] dark:border-gray-800"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                onChange={handleListSearch}
                type="text"
                placeholder="Search"
                id="donot-search-input"
                className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
              />
            </div>
            {<CustomFilter optionData={optionData} handleFilter={handleFilter} />}
          </div>
          <div className='flex items-center gap-2'>
           {(donotemailStatus?.is_owner || donotemailStatus?.delete)&& <Button
              disable={tableSelection?.selectedIds?.length <= 0}
              LeftIcon={<MdDeleteOutline className="float-right text-lg" />}
              name="Delete"
              customClass={`${tableSelection?.selectedIds?.length > 0 ? 'bg-white font-semibold !text-gray-800' : ''} delete_btn`}
              onClick={handleDelete}
            />}
            <Button
              disable={tableSelection?.selectedIds?.length <= 0}
              LeftIcon={<TbCloudDownload className="float-right text-lg" />}
              name="Export CSV"
              customClass={`${tableSelection?.selectedIds?.length > 0 ? 'bg-white font-semibold !text-gray-800' : ''} export_csv`}
              onClick={handleExportCSV}
            />
          </div>
        </div>
        {!data.loading ? (
          <div className="min-h-12 overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = ['w-[3%]', 'w-[62%]', 'w-[10%]', 'w-[15%]', 'w-[3%]'];
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[.625rem] font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = ['w-[3%]', 'w-[62%]', 'w-[10%]', 'w-[15%]', 'w-[3%]'];
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={6}
            row={3}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 25% 22% 22% 25% 4%"
          />
        )}
      </div>

    </div>
  );
};

export default AddDoNotEmailList;
