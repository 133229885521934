import React from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import { useAppDispatch } from 'store';
import { _errorHandler, toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { ModalComponentProp } from 'common/dto/types/views/blacklist';
import { getErrorMessage } from 'store/custom';
import CreateBrand from './CreateBrand';

function CreateBrandModal(props: ModalComponentProp) {
    const { isOpen, onClose } = props;
    const dispatch = useAppDispatch();
    const [handleState, setHandleState] = React.useState({
        loading: false,
        brandData: null,
    });

    const handleBrandDataChange = (state: any) => {
        setHandleState((prevState) => ({ ...prevState, loading: true }));
        if (state?.uuid) {
            setHandleState((prevState) => ({ ...prevState, brandData: state }));
        } else {
            const { uuid, ...rest } = state;
            setHandleState((prevState) => ({ ...prevState, brandData: rest }));
        }
    };

    const handleClose = (uuid: string) => {
        const brandData = { uuid, ...handleState?.brandData };
        onClose(brandData);
        setHandleState((prevState) => ({ ...prevState, brandData: null }));
    };

    React.useEffect(() => {
        const submitCreateBrandApi = async () => {
            const response = await BaseApi.post('/brand', handleState?.brandData);
            setHandleState((prevState) => ({ ...prevState, loading: false }));
            if (response?.error) {
                toast.error(_errorHandler(response?.message), toastState?.error);
                dispatch(getErrorMessage(response?.message));
            } else {
                dispatch(getErrorMessage({}));
                toast.success('Brand has been added successfully', toastState.error)
                handleClose(response?.data?.insert_id);
            }
        }
        handleState?.brandData && submitCreateBrandApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleState?.brandData]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={() => handleClose(null)} size="3xl">
            <ModalOverlay backdropFilter="blur(2px)" />
            <ModalContent overflow={'auto'} className="dark:bg-darkThree">
                <ModalBody className="w-full !p-0">
                    <CreateBrand bodycustomClass={'h-[80vh] w-full '} onParentDataChange={handleBrandDataChange} onChangeloading={handleState?.loading} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default CreateBrandModal;
