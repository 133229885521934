import { SparkleNameBlueImg, SparkleNameWhiteImg, SparkleTransparantImg, SparkleTransparantWhiteImg, EmailVerificationLogoHome, EmailVerifierLogo } from 'assets/img/auth';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';

const EnvironmentHomeLogo = () => {
  const { loggedUser } = useAppSelector((state) => state.auth);
  const { sideBarFullView } = useAppSelector((state) => state.custom);
  const { environment } = useAppSelector((state) => state.globalsettings);
  const navigate = useNavigate();

  return (
    <>
      {environment?.site_ui_access_feature?.sparkle_logo_home ? (
        <div
          className="mb-7 mt-4 flex w-full cursor-pointer items-center gap-2 px-5 text-2xl font-bold tracking-widest text-brand-500 dark:text-white"
          onClick={() => navigate('/')}
        >
          {sideBarFullView ? (
            loggedUser?.app_settings?.theme === 'dark' ? (
              <img src={SparkleNameWhiteImg} alt="" />
            ) : (
              <img src={SparkleNameBlueImg} alt="" />
            )
          ) : loggedUser?.app_settings?.theme === 'dark' ? (
            <img src={SparkleTransparantWhiteImg} alt="" />
          ) : (
            <img src={SparkleTransparantImg} alt="" />
          )}
          {/* <BsFillLightningChargeFill /> */}
          {/* <h5 className={`text-xl font-extrabold ${!sideBarFullView && 'hidden'}`}>Sparkle.io</h5> */}
        </div>
      ) : environment?.site_ui_access_feature?.email_verifier_logo_home ? (
        <div
          className="mb-7 mt-4 flex w-full cursor-pointer items-center gap-2 px-5 text-2xl font-bold tracking-widest text-brand-500 dark:text-white"
          onClick={() => navigate('/')}
        >
          {sideBarFullView ? (
            loggedUser?.app_settings?.theme === 'dark' ? (
              <img src={EmailVerificationLogoHome} alt="" />
            ) : (
              <img src={EmailVerificationLogoHome} alt="" />
            )
          ) : loggedUser?.app_settings?.theme === 'dark' ? (
            <img src={EmailVerifierLogo} alt="" />
          ) : (
            <img src={EmailVerifierLogo} alt="" />
          )}
          {/* <BsFillLightningChargeFill /> */}
          {/* <h5 className={`text-xl font-extrabold ${!sideBarFullView && 'hidden'}`}>Sparkle.io</h5> */}
        </div>
      ) : null}
    </>
  );
};

export default EnvironmentHomeLogo;
