import Button from 'common/sparkle-common/Button';
import Toggle from 'components/Toggle';
import { useEffect } from 'react';

type DomainProps = {
  setHandleNextStep: any;
};

const DomainBilling: React.FC<DomainProps> = ({ setHandleNextStep }) => {
  useEffect(() => {
    setHandleNextStep(() => handleNext);
  }, [setHandleNextStep]);

  const handleNext = () => {
    console.log('DomainBilling next logic');
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-3/5 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">Billing</p>
        <hr />

        <div className="m-4 rounded-xl border border-[#FEC84B] bg-gradient-to-r from-[#FFFFFF] to-[#FEF0C7]">
          <div className="flex justify-between p-4 ">
            <p className="text-xl font-bold text-blackDark">Purchase domains</p>
            <div>
              <p className="mb-2 flex items-center justify-center text-base font-medium text-column">
                <span className="mr-1 font-semibold"> $100 </span> for domains{' '}
                {/* <span>
                  <IoIosInformationCircle className="ml-3 text-[#7090b0]" />
                </span> */}
              </p>
              <p className="flex items-center justify-center text-base font-medium text-column">
                <span className="mr-1 font-semibold"> $60 </span> for email IDs{' '}
                {/* <span>
                  <IoIosInformationCircle className="ml-3 text-[#7090b0]" />
                </span> */}
              </p>
            </div>
          </div>
          <hr />
          <div className="flex justify-between gap-5 p-4 ">
            <div className="h-44 w-3/5 overflow-auto rounded-md border bg-white p-3">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-heading">Domain</p>
                <p className="text-sm font-semibold text-heading">Price</p>
              </div>
              {Array.from({ length: 6 }).map(() => (
                <div className="mb-2 mt-1 flex items-center justify-between gap-5">
                  <p className="text-xs font-medium text-heading">appointmentsbright.com</p>
                  <p className="text-xs font-medium text-heading">$15/year</p>
                </div>
              ))}
            </div>

            <div className="h-48 w-full overflow-auto rounded-md border bg-white p-3">
              <div className="flex items-center justify-center text-xs font-semibold text-heading">
                <Toggle className="scale-50" checkedCondition={true} />
                Annual pricing (<span className="font-medium text-[#6941C6]">save 20%</span>)
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-heading">Email</p>
                <p className="text-sm font-semibold text-heading">Price</p>
              </div>
              {Array.from({ length: 7 }).map(() => (
                <div className="mb-2 mt-1 flex items-center justify-between">
                  <p className="text-xs font-medium text-heading">sam@appointmentsbright.com</p>
                  <p className="text-xs font-medium text-heading">$15/year</p>
                </div>
              ))}
            </div>
          </div>
          <hr />

          <Button name="Purchase now" customClass="w-[96%] my-4 ml-4 text-sm" />
        </div>
      </div>
    </div>
  );
};

export default DomainBilling;
