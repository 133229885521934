/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { KeyValuePair } from 'common/dto/types/views/email-verifier';
import React from 'react';
import Button from 'common/sparkle-common/Button';
import { useNavigate } from 'react-router-dom';
import Input from 'common/sparkle-common/Input';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
}

const ConnectEmailPopup = (props: ModalComponentProps) => {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [formData, setFormData] = React.useState({
    imap_host: '',
    smtp_host: '',
    smtp_username: '',
    imap_username: '',
    smtp_password: '',
    imap_password: '',
  });
  const { onClose, isOpen } = props;
  const onClick = () => {
    navigate('/onboard/connect/accounts');
  };

  React.useEffect(() => {
    const isValid = Object.values(formData).every((value) => value.trim() !== '');
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    //e.preventDefault();
    // console.log(formData);
  };
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-base text-[#667085] ">Connect your email</h5>
            </div>
            <ModalCloseButton size={'lg'} color={'#667085'} id="add-blacklist-pop-close" className="mr-3 " />
          </ModalHeader>
          <hr />
          <ModalBody className="!px-0">
            <div className="grid grid-cols-2 px-6">
              <div className="flex flex-col px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674]">SMTP host</p>
                <Input placeholder="e.g smtp.customerserver.com" customClass={'!h-11 !text-sm'} name="smtp_host" onChange={handleChange} />
              </div>
              <div className="flex  flex-col  justify-between px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674] ">IMAP host</p>
                <Input name="imap_host" placeholder="e.g smtp.customerserver.com" customClass={'!h-11 !text-sm'} onChange={handleChange} />
              </div>
              <div className="flex  flex-col  justify-between px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674] ">SMTP username</p>
                <Input name="smtp_username" placeholder="e.g email@domain.com" customClass="!h-11 !text-sm" onChange={handleChange} />
              </div>
              <div className="flex  flex-col  justify-between px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674] ">IMAP username</p>
                <Input name="imap_username" placeholder="e.g email@domain.com" customClass={'!h-11 !text-sm'} onChange={handleChange} />
              </div>
              <div className="flex  flex-col justify-between px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674] ">SMTP password</p>
                <Input placeholder="password" customClass={'!h-11 !text-sm'} name="smtp_password" onChange={handleChange} />
              </div>
              <div className="flex flex-col justify-between px-3 py-3">
                <p className="mb-2 text-base font-semibold text-[#2B3674] ">IMAP password</p>
                <Input placeholder="password" customClass={'!h-11 !text-sm'} name="imap_password" onChange={handleChange} />
              </div>
            </div>
            <div className="flex w-full gap-4 px-12 pb-3 pt-5">
              <Button
                type="button"
                name="cancel"
                fontSize="sm"
                px="px-6"
                color="text-column"
                onClick={() => onClose()}
                customClass="text-column font-medium text-base w-1/2 !bg-white"
              />
              <Button
                type="button"
                name="Upload"
                fontSize="sm"
                px="px-6"
                onClick={() => handleSubmit()}
                customClass="text-column font-medium text-sm w-1/2"
                disable={!isFormValid}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectEmailPopup;
