import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd, MdEdit } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { AiFillDelete } from 'react-icons/ai';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { BaseApi } from 'common/api/common/base-api';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import { checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import Button from 'common/sparkle-common/Button';
import TableLoader from 'components/Loaders/TableViewLoader';
import InfiniteScrollComponent from 'components/infinityScroll';
import NewBrandModal from './features/NewBrandModal';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import ConfirmationPopup from 'components/confirmationPopup/ConfirmationPopup';
import { removeBrandInput, setBrandInput, setIds } from 'store/campaign-v2';
import ArrowedTooltip from 'components/ArrowedTooltip';
import CustomFilter from 'components/customFilter';
import { brandsFilterDataList } from 'common/utils/page-filter';

const BrandList = () => {
  const columnWidth = ['w-[5%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[5%]'];

  const [options, setOptions] = React.useState([]);
  const dispatch = useAppDispatch();
  const [brandData, setBrandData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({
    selectedIds: [],
    globalOpt: false,
  });
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const [optionData, setOptionData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptionData(brandsFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
      return { ...prev, filter: encryptedData };
    });
  };

  const [handleState, setHandleState] = React.useState({
    newBrandModal: false,
    loading: false,
    deleteloading: false,
    roleStatus: null,
    editBrandData: {
      uuid: '',
      name: '',
      icp: '',
      pain_points: '',
      solution: '',
      geography_target: '',
      about_brand_details: '',
    },
    confirm: {
      isOpen: false,
      headerContent: '',
      bodyContent: '',
      bodyRedContent: '',
      descriptionContent: '',
      uuid: [],
    },
  });
  const custom = useAppSelector((state) => state?.custom);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);

  const handleBrandConfirmCancel = () => {
    setHandleState((prevState) => ({
      ...prevState,
      confirm: {
        ...prevState.confirm,
        isOpen: false,
      },
    }));
  };

  const handleBrandConfirmSubmitDelete = async () => {
    try {
      setHandleState((prevState) => ({ ...prevState, deleteloading: true }));

      setQueryParams((prev) => ({
        ...prev,
        offset: 0,
      }));
      const response = await BaseApi.delete('/brand', {
        data: { uuid: handleState?.confirm?.uuid },
      });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
        setHandleState((prevState) => ({ ...prevState, deleteloading: false }));
        toast.error('Failed Deleting Brand', toastState.error);
      } else {
        dispatch(setFlagStatus(!custom?.flagStatus));
        setHandleState((prevState) => ({
          ...prevState,
          confirm: {
            ...prevState.confirm,
            isOpen: false,
          },
        }));

        setHandleState((prevState) => ({ ...prevState, deleteloading: false }));
        setQueryParams((prev) => ({
          ...prev,
          offset: 0,
        }));
        toast.success('Brand Deleted Successfully', toastState.success);
      }
    } catch (error) {
      console.error('Brand list single delete error', error);
    }
  };

  const handleBrandSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const handleBrandSingleDelete = async (rowuuid: string, rowDetails: any) => {
    setHandleState((prevState) => ({
      ...prevState,
      confirm: {
        ...prevState.confirm,
        isOpen: true,
        headerContent: 'Do you Want to Delete Brand',
        bodyContent: 'Delete Brand ',
        bodyRedContent: rowDetails?.name,
        descriptionContent: 'Do you confirm deleting Brand',
        uuid: [rowuuid],
      },
    }));
  };

  const handleEditBrand = async (rowuuid: string, rowDetails: any) => {
    try {
      setQueryParams((prev) => ({
        ...prev,
        offset: 0,
      }));
      const apiEditGetOneBrand = await BaseApi.get(`/brand/${rowuuid}`);
      const brandData = {
        name: apiEditGetOneBrand?.data?.name,
        icp: apiEditGetOneBrand?.data?.icp,
        pain_points: apiEditGetOneBrand?.data?.pain_points,
        solution: apiEditGetOneBrand?.data?.solution,
        geography_target: apiEditGetOneBrand?.data?.geography_target,
        about_brand_details: apiEditGetOneBrand?.data?.about_brand_details,
      };
      setHandleState((prevState) => ({
        ...prevState,
        editBrandData: {
          uuid: apiEditGetOneBrand?.data?.uuid,
          ...brandData,
        },
      }));
      dispatch(setBrandInput(brandData));
      dispatch(setIds({ brand_id: apiEditGetOneBrand?.data?.uuid }));
      setHandleState((prevState) => ({ ...prevState, newBrandModal: true }));
    } catch (error) {}
  };

  const handleBrandMultipleDeletes = async () => {
    try {
      const response = await BaseApi.delete('/brand', {
        data: { uuid: tableSelection?.selectedIds },
      });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        toast.success('Brand Deleted Sucessfully');
        setQueryParams((prev) => ({
          ...prev,
          offset: 0,
        }));
        dispatch(setFlagStatus(!custom?.flagStatus));
      }
    } catch (error) {
      console.log('Brand list single delete error', error);
    }
  };

  const multiSelectOptions = [
    {
      icon: <AiFillDelete />,
      name: 'Delete',
      functioning: handleBrandMultipleDeletes,
    },
  ];

  React.useEffect(() => {
    const fetchBrandsList = async () => {
      // console.log('call happens');
      try {
        // setLoading(true);
        setHandleState((prevState) => ({ ...prevState, loading: true }));

        const query = setAndGetQueryParams([
          { key: 'limit', value: queryParams?.limit },
          // { key: 'offset', value: queryParams?.offset },
          { key: 'search', value: queryParams?.search },
          {
            key: 'sort',
            value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
          },
          { key: 'filter', value: queryParams?.filter },
        ]);
        const brandListResponse = await BaseApi.get(`/brand/statistics/list?${query}`);
        brandListResponse?.data?.data?.length
          ? setBrandData({ ...brandData, data: brandListResponse?.data?.data, count: brandListResponse?.data?.total_records?.count })
          : setBrandData({ ...brandData, data: [], count: 0 });
        table.resetRowSelection();
        // setLoading(false);
        setHandleState((prevState) => ({ ...prevState, loading: false }));

        setQueryParams((prev) => ({
          ...prev,
          offset: 2,
        }));
      } catch (error) {
        console.error('Error From Brand Table Page Catch block', error);
      }
    };
    fetchBrandsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'brands');
      setHandleState((prevState) => ({ ...prevState, roleStatus: status }));
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  const brandRowSettingOptions = [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: handleEditBrand,
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      accessName: 'delete',
      name: 'Delete',
      functioning: handleBrandSingleDelete,
    },
  ];

  React.useEffect(() => {
    const finalBradRowSettingOption = handleState?.roleStatus?.is_owner
      ? brandRowSettingOptions
      : brandRowSettingOptions.filter((item) => handleState?.roleStatus?.hasOwnProperty(item?.accessName) && handleState?.roleStatus?.hasOwnProperty(item.accessName));

    setOptions(finalBradRowSettingOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.roleStatus]);

  const columnHelper = createColumnHelper<any>();

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="!ml-7 flex items-center">
          <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="!ml-7 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('name', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="text-md">Name</div>,
      cell: (info) => (
        // <h5 className="group relative w-36 truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">
        //   {info.getValue()}
        //   {info.getValue()?.length > 18 && (
        //     <ArrowedTooltip className={`ml-8 hidden !min-w-fit !px-4 group-hover:block `} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} />
        //   )}
        // </h5>
        <div className="group relative">
          <h5 className="w-36 truncate text-[14px] font-bold text-gray-900 dark:text-gray-200">{info.getValue()}</h5>
          {info.getValue()?.length > 18 && (
            <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-${info?.row?.id}`}
              data={info.getValue()}
            />
          )}
        </div>
      ),
    }),

    columnHelper.accessor('campaign_count', {
      id: 'campaign_count',
      enableSorting: false,
      header: () => <div>Campaigns</div>,
      cell: (info) => <h5 className={`ml-7 text-[12px] font-medium text-gray-700 dark:text-gray-300 status-index-${info?.row?.id}`}>{info.getValue()}</h5>,
    }),

    columnHelper.accessor('interests_count', {
      id: 'interests_count',
      enableSorting: false,
      header: () => <div>Interests</div>,
      cell: (info) => <h5 className=" ml-5 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('meetings_booked_count', {
      id: 'meetings_booked_count',
      header: () => <div className="text-start">Meeting booked</div>,
      cell: (info) => <h5 className=" ml-10 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('opportunities_won', {
      id: 'oportunities_won',
      header: () => <div className="text-start">Opportunities Won</div>,
      cell: (info) => <h5 className=" ml-12 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('total_deal_value', {
      id: 'total_deal_value',
      header: () => <div className="text-start">Total deal value</div>,
      cell: (info) => <h5 className=" ml-10 text-[12px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative -ml-2 w-min">Action</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={options} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: brandData?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);
  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/brand/statistics/list?${query}`);
          const responseData: any = response.data;
          console.log('responseData', responseData);
          if (!responseData?.data) return setBrandData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setBrandData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setBrandData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      brandData?.data?.length && brandData?.data?.length < brandData?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brandData?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <div className=" mt-3 min-h-[88vh] rounded-md bg-white dark:bg-darkTwo">
      <div className="-mt-2 flex w-full items-center rounded-tl-lg rounded-tr-lg border-b-2 bg-white  px-2 py-4 dark:border-darkBorder dark:bg-darkTwo sm:gap-32 lg:gap-32 xl:justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
            // onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-gray-300" />
            <input
              type="text"
              placeholder="Search"
              id="donot-search-input"
              className="w-full text-sm  text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:text-gray-300 dark:placeholder:text-white "
              onChange={handleBrandSearch}
            />
          </div>
          <CustomFilter optionData={optionData} handleFilter={handleFilter} />

          {/* {<CustomFilter />} */}
        </div>
        {(handleState?.roleStatus?.is_owner || handleState?.roleStatus?.add) && (
          <Button
            id="add_brand_btn"
            name="Add Brand"
            customClass="text-xs add_brand_btn !font-normal bg-blueSecondary add-user-btn dark:border-none"
            LeftIcon={<MdAdd className="text-[16px]" />}
            // disable={openPopUP}
            onClick={() => {
              setHandleState((prevState) => ({
                ...prevState,
                editBrandData: {
                  uuid: '',
                  name: '',
                  icp: '',
                  pain_points: '',
                  solution: '',
                  geography_target: '',
                  about_brand_details: '',
                },
              }));
              dispatch(removeBrandInput());
              dispatch(setIds({ brand_id: '' }));
              setHandleState((prevState) => ({ ...prevState, newBrandModal: true }));
            }}
          />
        )}
      </div>
      {!handleState?.loading ? (
        <>
          <div className="mb-2  h-[82vh]  overflow-y-auto overflow-x-scroll  scroll-smooth xl:overflow-x-hidden">
            <table className="w-full ">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = columnWidth;
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-3.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[12px] font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className=" divide-y-[1px] dark:divide-darkBorder ">
                {brandData?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none text-start hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="pt-2 text-center text-[12px] font-medium text-gray-700">
                      No Result Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        </>
      ) : (
        <TableLoader
          width="w-full"
          border="border border-gray-100"
          column={9}
          row={3}
          skeletonHeight="h-2"
          padding="px-5 py-4"
          tableViewBg={{ headBg: 'bg-gray-50' }}
          columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
        />
      )}
      {tableSelection?.globalOpt && (handleState?.roleStatus?.is_owner || handleState?.roleStatus?.delete) && <MultiselectGlobalOpt options={multiSelectOptions} />}

      <ConfirmationPopup
        isOpen={handleState?.confirm?.isOpen}
        loading={handleState?.deleteloading}
        headerContent={handleState?.confirm?.headerContent}
        bodyContent={handleState?.confirm?.bodyContent}
        bodyRedContent={handleState?.confirm?.bodyRedContent}
        descriptionContent={handleState?.confirm?.descriptionContent}
        handleCancel={handleBrandConfirmCancel}
        handleConfirmFunction={handleBrandConfirmSubmitDelete}
      />

      {handleState?.newBrandModal && (
        <NewBrandModal
          isOpen={handleState?.newBrandModal}
          onClose={() => {
            setHandleState((prevState) => ({ ...prevState, newBrandModal: false }));
          }}
          editData={handleState?.editBrandData}
        />
      )}
    </div>
  );
};

export default BrandList;
