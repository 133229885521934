import React from 'react'

const MIniCardLoader = () => {
  return (
    <div className="mt-4 ">
  <div className="w-full rounded-md bg-white p-4 text-center shadow border dark:!border-darkBorder dark:bg-darkThree animate-pulse">
    <div className="my-1 h-8 w-3/4 mx-auto rounded bg-gray-300 dark:bg-darkFour"></div>
    <div className="mt-2 h-4 w-1/5 mx-auto rounded bg-blueSecondary dark:bg-purple-500"></div>
    <div className="mt-3 h-6 w-full rounded bg-gray-200 dark:bg-darkFour"></div>
  </div>
</div>
  )
}

export default MIniCardLoader