import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import Input from 'common/sparkle-common/Input';
import { toastState } from 'common/utils/utility';
import { useState } from 'react';
import { toast } from 'react-toastify';
import React from 'react';

interface ModalPropsDTO {
  isOpen?: boolean;
  onClose?: () => void;
  callback?: (val: FieldsDTO[]) => Promise<boolean>;
  initialLoad?: () => void;
  prevData?: string[];
  addPrevData?: boolean;
  setAddPrevData?: (val: boolean) => void;
  fields: FieldsDTO[];
  setFields: (val: any) => void;
  onCloseClick: () => void;
}

interface FieldsDTO {
  firstName: string;
  lastName: string;
  id: string;
}
const PayAsYouGoPopUp = (props: ModalPropsDTO) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onClose, callback, initialLoad, prevData, addPrevData, setAddPrevData, fields, setFields, onCloseClick } = props;
  const [loading, setLoading] = useState(false)
  // const addFields = async () => {
  //   setFields([...fields, { id: generateUUID(), firstName: '', lastName: '' }]);
  // };

  // const deleteFields = (id: string) => {
  //   const data = fields.filter((item) => item.id !== id);
  //   setFields(data);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, uuid: string, type: string) => {
    const { value } = e.target;
    setFields((prevFields: any) =>
      prevFields.map((field: any) =>
        field.id === uuid
          ? {
            ...field,
            [type === 'fname' ? 'firstName' : 'lastName']: value,
          }
          : field
      )
    );
  };

  const handleContinue = async () => {
    const incompleteFields = fields.some((field) => !field.firstName || !field.lastName);
    if (!addPrevData && !fields.length) {
      toast.error('Please add atleast one persona', toastState.error);
    }
    else if (!addPrevData && incompleteFields) {
      toast.error('Please fill out both first and last names for all personas.', toastState.error);
      return;
    }
    else {
      setLoading(true)
      const data = incompleteFields ? [] : fields;
      if (await callback(data)) {
        onClose()
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    }

  };

  React.useEffect(
    () => {
      // if (!prevData?.length) {
      initialLoad()
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} maxW={'45rem'}>
          <ModalHeader>
            <div className="flex flex-col items-start justify-center gap-3">
              <h5 className="text-xl font-semibold text-heading">Pay as you go</h5>
              <h6 className="w-5/6 text-sm font-thin text-column">
                Use Sparkle's ready-to-use custom addresses based on your campaign needs and personas. You only pay per message sent.
              </h6>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" onClick={onCloseClick} />
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col gap-3">
              {/* <div className='bg-[#fafafc] p-3 w-full rounded-md'>
                <p className="mt-3 text-xs text-column">You already have {prevData?.length} email aliases </p>
                <div className='flex items-center mt-3 gap-1'>
                  <input type="checkbox" id='prevAlias' className='size-3 accent-blueSecondary' checked={addPrevData} onChange={() => setAddPrevData(!addPrevData)} />
                  <label className='text-xs text-heading font-medium' htmlFor="prevAlias">Use previously added aliases with this campaign</label>
                </div>
              </div> */}
              <div className="bg-[#fafafc] p-3">
                <div className="flex items-start justify-between gap-3">
                  <div>
                    <p className="text-sm font-semibold text-heading">Set your persona</p>
                    <p className="mt-3 text-xs text-column">All fields are required for domain registration</p>
                  </div>

                  <div className="h-20 overflow-auto">
                    {fields?.map((item: { id: string; firstName: string; lastName: string }, index: number) => (
                      <div className="flex items-center gap-3 px-1" key={item.id}>
                        {/* <p className="text-xs text-column">Persona {index + 1}</p> */}
                        <div>
                          <p className="my-1 text-xs font-medium text-heading">First Name</p>
                          <Input id='persona-firstname' value={item.firstName} onChange={(e) => handleInputChange(e, item.id, 'fname')} />
                        </div>
                        <div>
                          <p className="my-1 text-xs font-medium text-heading">Last Name</p>
                          <Input id='persona-lastname' value={item.lastName} onChange={(e) => handleInputChange(e, item.id, 'lname')} />
                        </div>
                        {/* <AiOutlineDelete onClick={() => deleteFields(item.id)} className="cursor-pointer" /> */}
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="flex cursor-pointer items-center justify-end gap-2 bg-[#fafafc] text-sm font-medium text-blueSecondary" onClick={addFields}>
                  <AiOutlinePlus /> Add Personas
                </div> */}
              </div>

              <div className="space-y-3 mt-2 rounded-md border border-[#FEC84B] bg-gradient-to-r from-[#FFFFFF] to-[#FEF0C7] py-2">
                <div className="flex justify-between px-4">
                  <div>
                    <p className="text-center text-base text-column">You will not be charged now, but billed later at $5 per message based on the number of messages you send</p>
                  </div>
                </div>
              </div>

              <div className="mt-3 flex gap-4">
                <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={onClose} />
                <Button id="create_persona_confirm_btn" name="Confirm" customClass="w-full text-[.82rem]" onClick={handleContinue} loading={loading} disable={loading} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default PayAsYouGoPopUp
