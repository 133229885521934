import { createSlice } from '@reduxjs/toolkit';
import { initialState } from 'common/dto/initial-value/store/account-settings';

const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    setSelectedCards: (state, action) => {
      state.billing.usage.selected_cards = state?.billing?.usage?.selected_cards?.some((item) => item === action?.payload)
        ? state?.billing?.usage?.selected_cards?.filter((item) => item !== action?.payload)
        : [...state?.billing?.usage?.selected_cards, action?.payload];
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setGraphData: (state, action) => {
      state.billing.usage.graph_data = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.billing.usage.selected_date = {
        ...state?.billing?.usage?.selected_date,
        ...action?.payload,
      };
    },
    setUserUsage: (state, action) => {
      state.billing.usage.user_usage = {
        ...state?.billing?.usage?.user_usage,
        ...action.payload,
      };
    },
    setUser: (state, action) => {
      state.billing.usage.user.list = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.billing.usage.user.selected_user = state?.billing?.usage?.user?.selected_user?.some((item) => item === action?.payload)
        ? state?.billing?.usage?.user?.selected_user?.filter((item) => item !== action?.payload)
        : [...state?.billing?.usage?.user?.selected_user, action?.payload];
    },
    setPaymentCards: (state, action) => {
      state.paymentCards = action.payload;
    },
    setSubscriptonPlanId: (state, action) => {
      state.paymentCards = action.payload;
    },
    setCheckSparkles: (state) => {
      state.checkSparkles = !state.checkSparkles
    },
    // API
    setApiFormData: (state, action) => {
      state.api_key.formData = { ...state.api_key.formData, ...action.payload }
    },
    setApiList: (state, action) => {
      state.api_key.list = action.payload
    },
    setApiSelectedList: (state, action) => {
      state.api_key.selectedLists = action.payload
    },
    toggleDependency: (state) => {
      state.api_key.dependency = !state?.api_key?.dependency
    },
  },
});

export const { setSelectedCards, setGraphData, setSelectedDate, setUserUsage, setUser,
  setSelectedUser, setPaymentStatus, setPaymentCards, setSubscriptonPlanId, setCheckSparkles, setApiFormData, setApiList, setApiSelectedList,
  toggleDependency
} = accountSettingsSlice.actions;
export default accountSettingsSlice.reducer;
