import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AuthUserState {
  loggedUser: any;
  loggedUserRolePermissionList: string;
  
}

const initialState: AuthUserState = {
  loggedUser: {
    // app_settings: {
    //   theme: LocalStorage.getItem('darkMode'),
    // },
  },
  loggedUserRolePermissionList: '',

  
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setLoggedUserDetails: (state, action: PayloadAction<object>) => {
      state.loggedUser = { ...state?.loggedUser, ...action?.payload };
    },
    setLoggedUserRolePermissionList: (state, action: PayloadAction<string>) => {
      state.loggedUserRolePermissionList = action.payload;
    },
    setAppSettings: (state, action) => {
      const { key, value } = action.payload;
      state.loggedUser.app_settings = { ...state.loggedUser.app_settings, [key]: value };
    },
   
  },
});

export const { setLoggedUserDetails, setLoggedUserRolePermissionList, setAppSettings } = headerSlice.actions;
export default headerSlice.reducer;
