import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import { BaseApi } from 'common/api/common/base-api';
import { Avatar, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillDelete, AiFillExclamationCircle } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
// import CustomFilter from 'components/customFilter';
import Button from 'common/sparkle-common/Button';
import { MdAdd } from 'react-icons/md';
import AddUserManagementModal from './feature/AddUserManagementModal';
import RowUserManagementSettings from './feature/row-setting-usermanagement';
import { useAppSelector } from 'store';
// import CustomGroupTags from './feature/CustomGroupTags';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import { useDispatch } from 'react-redux';
import { FaRegCircleCheck } from 'react-icons/fa6';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { userManagementFilterDataList } from 'common/utils/page-filter';
import CustomFilter from 'components/customFilter';

const UserManagementTable = () => {
  const custom = useAppSelector((state) => state?.custom);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const columnWidth = ['w-[5%]', 'w-[20%]', 'w-[10%]', 'w-[10%]', 'w-[35%]', 'w-[10%]', 'w-[5%]'];
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });
  const dispatch = useDispatch();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: true,
  });

  const handleUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const fetchListData = async () => {
    try {
      setData({ ...data, loading: true });
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
        {
          key: 'sort',
          value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
        },
        { key: 'filter', value: queryParams?.filter },
      ]);
      const response = await BaseApi.get(`/user/role/management?${query}`);
      table.resetRowSelection();

      response?.data?.data?.length ? setData({ ...data, data: response?.data?.data, loading: false }) : setData({ ...data, data: [], loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  const [optionsData, setOptionsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptionsData(userManagementFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
      // if (!prev?.filter && !filteredData?.data?.length) return { ...prev, filter:'' };
      // return { ...prev, filter: encryptedData };
    });
    // setQueryParams((prev) => {
    //   if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
    //   return { ...prev, filter: encryptedData };
    // });
  };

  const [userStatus, setUserStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'user_management');
      setUserStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  React.useEffect(() => {
    fetchListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    custom?.flagStatus,
    queryParams?.filter,
    debouncedSearchValue,
    queryParams?.sort?.columnName,
    queryParams?.sort?.order,
    queryParams?.offset,
    custom?.globalDependencyLoading,
  ]);

  const columnHelper = createColumnHelper<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({
    selectedIds: [],
    globalOpt: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMultiDelete = async () => {
    try {
      setLoading(true);
      const response = await BaseApi.delete('/user/role/management/invited/workspace', {
        data: {
          delete_ids: tableSelection?.selectedIds,
        },
      });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
        setLoading(false);
      } else {
        setLoading(false);
        setIsOpen(false);

        dispatch(setFlagStatus(!custom?.flagStatus));
      }
    } catch (error) {
      console.log('Role management list Multi delete error', error);
    }
  };

  // console.log(tableSelection?.selectedIds);

  const multiSelectOptions = [
    {
      icon: <AiFillDelete />,
      name: 'Remove User',
      functioning: () => setIsOpen(true),
    },
  ];
  // console.log('MULTI', multiSelect);
  const columnData = [
    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => (
        <div className="ml-3 flex items-center">
          <input
            type="checkbox"
            id="select_all"
            checked={table?.getIsAllRowsSelected()}
            onChange={table?.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div
            className="ml-3 flex items-center"
            // onClick={() =>
            //   setMultiSelect((prev) => [
            //     ...prev,
            //     {
            //       uuid: info?.row?.original?.uuid,
            //       workspace_id: info?.row?.original?.all_workspacess?.workspace_id,
            //     },
            //   ])
            // }
          >
            <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('username', {
      id: 'username',
      enableSorting: false,
      header: () => <div className="">Name</div>,
      cell: (info) => {
        const row = info.row.original;
        return (
          <div className="flex w-64 items-center space-x-2 ">
            <Avatar height={'32px'} width={'32px'} name={`${row.username[0]}`} bg="teal.500" src={row?.image} />
            <div className="w-4/5 ">
              <h5 className=" truncate text-xs font-semibold text-gray-900 dark:text-white">{row.username}</h5>
              <div className="group relative">
                <ArrowedTooltip data={row.email} className="hidden  w-fit group-hover:block" positionAxis="top-5 left-10 -translate-x-1/2" />
                <p className="truncate text-xs">{row.email}</p>
              </div>
            </div>
          </div>
        );
      },
    }),

    columnHelper.accessor('all_workspaces', {
      id: 'all_workspaces',
      enableSorting: false,
      header: () => <div>Role</div>,
      cell: (info) => (
        <h5 className="text-[10px] font-medium text-gray-700 dark:text-gray-200">
          {info.row.original.all_workspaces?.role_details?.role_name ? info?.row?.original?.all_workspaces?.role_details?.role_name : 'Not Assigned'}
        </h5>
      ),
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => <div className="text-start">Status</div>,
      cell: (info) => {
        const row = info.row.original;
        const status = row.all_workspaces?.invite_details?.invite_status || 0;
        return (
          <div className="flex items-center space-x-2 ">
            {status === 0 ? <AiFillExclamationCircle color="gray" size={26} /> : <AiFillCheckCircle color="#0dbf3d" size={26} />}
            <h5 className={`text-xs font-bold text-gray-800 dark:text-gray-200 status-index-${info?.row?.id}`}>{status === 0 ? 'Invited' : 'Active'}</h5>
          </div>
        );
      },
    }),

    columnHelper.accessor('all_workspaces', {
      id: 'all_workspaces',
      header: () => <div className=" text-start">Workspace Access Given</div>,
      cell: (info) => (
        <h5 className="text-[10px] font-medium text-gray-700">
          <span className="ml-2 rounded-full bg-[#EFF8FF] px-1.5 text-xs text-[#175CD3]">{info?.row.original.all_workspaces?.workspace_name}</span>
          {/* <CustomGroupTags data={info?.row.original.all_workspaces} /> */}
        </h5>
      ),
    }),

    columnHelper.accessor('credits_usage', {
      id: 'credits_usage',
      header: () => <div className="text-start">Credit Usage</div>,
      cell: (info) => <h5 className="text-[10px] font-medium text-gray-700 dark:text-gray-200">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return <>{<RowUserManagementSettings id={row.row.id} uuidRow={row.row.original.uuid} rowData={row.row.original} table={table} setData={setData} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    // const tableSelectedData = table.getSelectedRowModel().rows?.map((val: any) => val.original);
    const tableSelectedData = table.getSelectedRowModel().rows?.map((val: any) => ({
      uuid: val?.original?.uuid,
      workspace_id: val?.original?.all_workspaces?.workspace_id,
    }));
    // console.log('Tab selecetd data ', tableSelectedData);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    if (table.getSelectedRowModel().rows?.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/user/role/management?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            table.resetRowSelection();
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: prev?.data?.length,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div className=" sticky top-[0px] z-10 -mt-2 flex w-full items-center rounded-bl-lg rounded-br-lg bg-white px-2 py-4 shadow dark:!bg-darkOne sm:gap-32 lg:gap-32 xl:justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
            // onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-white" />
            <input
              type="text"
              placeholder="Search"
              id="donot-search-input"
              className="w-full text-sm  text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkOne dark:text-gray-300 dark:placeholder:text-white "
              onChange={handleUserSearch}
            />
          </div>
          <CustomFilter optionData={optionsData} handleFilter={handleFilter} />
          {/* {<CustomFilter />}      //commented need for development */}
        </div>
        {(userStatus?.is_owner || userStatus?.add) && (
          <Button
          id='add-user-btn'
            name="Add User"
            customClass="text-xs dark:!border-none !font-normal bg-blueSecondary add-user-btn active:scale-95 transition-transform duration-150 ease-out"
            LeftIcon={<MdAdd className="text-[16px]" />}
            // disable={openPopUP}
            onClick={() => setShowCreateModal(true)}

            // onClick={() => setOpenPopUp(true)}
          />
        )}
      </div>
      <div className="my-3 h-[73vh]  overflow-y-auto scroll-smooth rounded-lg bg-white py-3 shadow dark:bg-darkTwo ">
        {!data.loading ? (
          <div className="min-h-48 overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = columnWidth;
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[.625rem] font-semibold">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px] dark:divide-darkBorder">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:!text-white `}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      No Matching Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={7}
            row={3}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50 ' }}
            columnWidth="4% 18% 20% 10% 30% 14% 4%"
          />
        )}
      </div>
      {tableSelection?.globalOpt && (userStatus?.is_owner || userStatus?.delete) && <MultiselectGlobalOpt options={multiSelectOptions} />}

      {isOpen && (
        <Modal isCentered isOpen={isOpen} onClose={() => {}} size="lg">
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent overflow={'auto'} className="">
            <ModalHeader className="mb-1 bg-[#FAFAFC]">
              <div className="flex items-center gap-2">
                <FaRegCircleCheck size={32} className="rounded-full border-4 border-red-100 bg-red-200 p-1  text-red-600 " />
                <span className="text-sm text-blackDark">Delete “{tableSelection?.selectedIds?.length}” Users </span>
              </div>
            </ModalHeader>
            <ModalBody className="!p-3">
              <>
                <div className=" text-center">
                  <h1 className="font-semibold text-blackDark">
                    Delete <span className="text-red-500">{tableSelection?.selectedIds?.length}</span> User
                  </h1>
                  <h5 className="text-xs text-column">Do you want to delete {tableSelection?.selectedIds?.length} user</h5>
                  <h5 className="text-xs text-column">from their Workspace</h5>
                </div>
              </>
            </ModalBody>
            <ModalFooter className="!-mt-2 !mb-1">
              <>
                <Button
                  id="blacklist-close"
                  name="Cancel"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2 !border-[#A3AED0] !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
                />
                <Button
                  id="add-user-invite-btn"
                  disable={loading}
                  loading={loading}
                  name="Delete"
                  onClick={handleMultiDelete}
                  customClass={` w-full ${loading ? '' : '!bg-danger'} !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem]`}
                />
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <AddUserManagementModal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)} />
    </>
  );
};

export default UserManagementTable;
