import { FormEvent, useState } from "react";
import InputField from "components/fields/InputField";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { forgotFormDTO } from "common/dto/types/views/authentication";
import { forgotPassData } from "common/dto/initial-value/views/authentication";
import { BaseApi } from "common/api/common/base-api";
import { useAppSelector, useAppDispatch } from "store";
import { getErrorMessage } from "store/custom";
import LogoCard from "../components/LogoCard";
import { toast } from "react-toastify";
import { toastState } from "common/utils/utility";
import { Link } from "react-router-dom";
import Button from "common/sparkle-common/Button";
function ForgotPassword() {

    const dispatch = useAppDispatch();
    const { errorMessage }: any = useAppSelector((state: { custom: any; }) => state.custom);
    const [formData, setFormData] = useState<forgotFormDTO>(forgotPassData);
    const handleFormChange = (e: { target: { id: any; value: any; }; }): void => setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setFormData((prev) => ({ ...prev, loading: true }))
            const originUrl = window.location.origin
            const data = await BaseApi.post('/auth/reset/password/request', { email: formData?.email, redirect_url: originUrl });
            if (data?.error) {
                dispatch(getErrorMessage(data?.message))
            }
            else {
                toast.success('Verification link send to your email', toastState.success)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setFormData((prev) => ({ ...prev, loading: false }))
        }
    }
    return (
        <div>
            <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
                <main className={`mx-auto min-h-screen`}>
                    <FixedPlugin />
                    <LogoCard
                        maincard={
                            <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start relative top-[40%] left-[5%]">
                                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                                    <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                                        Forgot your  password?
                                    </h3>
                                    <p className="mb-9 ml-1 text-base text-gray-600">
                                        Don't worry, happens to all of us. Enter your email below to recover your password
                                    </p>

                                    <form onSubmit={(e) => handleFormSubmit(e)} className="space-y-4">
                                        <InputField
                                            variant="auth"
                                            extra="mb-3"
                                            label="Email*"
                                            placeholder="example@sample.com"
                                            id="email"
                                            type="text"
                                            onChange={handleFormChange}
                                            value={formData?.email}
                                            errorMessage={errorMessage?.email}
                                            state={errorMessage?.email ? 'error' : ''}
                                        />
                                        <Button loading={formData?.loading} disable={formData?.loading} name="Submit" type="submit" customClass="w-full rounded-xl text-base font-medium transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" />
                                        {/* <button id="submit" type="submit" className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        Submit
                                        </button> */}
                                    </form>
                                    <Link
                                        to="/sign-in"
                                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white hover:underline"
                                    >
                                        Remember Password?
                                    </Link>
                                </div>
                            </div>
                        }
                    />
                </main>
            </div>
        </div>
    );
}

export default ForgotPassword;
