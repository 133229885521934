import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import Button from 'common/sparkle-common/Button';
import { toastState, useDebounce } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import SearchBox from 'components/searchBox'
import React, { ChangeEvent } from 'react'
import { BiCopy } from 'react-icons/bi';
import { MdAdd, MdOutlineCalendarToday } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setApiFormData, setApiList } from 'store/account-settings';
import DateRangeSelector from 'views/campaigns-insights/analytics/charts/DateRangeSelector';

const APIHeader = () => {
    // Redux state
    const formData = useAppSelector((state) => state.accountSettings.api_key.formData)
    // const dependency = useAppSelector((state) => state.accountSettings.api_key.dependency)

    // Local state
    const [selectedDate, setDate] = React.useState([new Date(), new Date()]);
    const [showCalendar, setShowCalendar] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [generatedApiKey, setGeneratedApiKey] = React.useState('');
    const searchDebounce = useDebounce(search, 300);

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(setApiList([{ uuid: 'sdfghjksdfgh', name: 'Api 1', api_key: 'xxxxxxxxxxxxxxxx', added_by: 'dhanush', sparkle_consumed: 120, limit: 100, added_date: '10/10/24' }, { uuid: 'sdfgdfghjhjksdfgh', name: 'Api 2', api_key: 'xxxxxxxxxxxxxxxx', added_by: 'dhanush', sparkle_consumed: 120, limit: 100, added_date: '10/10/24' }]))
        console.log('searchDebounce', searchDebounce)
        console.log('selectedDate', selectedDate)

        // }, [searchDebounce, dependency, selectedDate])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCloseModal = () => {
        dispatch(setApiFormData({ modal: false, uuid: '', name: '', limit: null }))
        setGeneratedApiKey('')
    }

    const handleCopyKey = async (APIKey: string) => {
        try {
            await navigator.clipboard.writeText(APIKey);
            toast.success('API key copied successfully', toastState.success)
        } catch (error) {
            console.error('Failed to copy the API key: ', error);
        }
    }

    const handleGenerateOrSaveAPI = async () => {
        try {
            if (!formData?.uuid) {
                setGeneratedApiKey('XXXXXXXXXXQWEAA');
            }
        } catch (error) {
            console.log('handleGenerateOrSaveAPI', error)
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event?.target;
        dispatch(setApiFormData({ [name]: value }))
    }

    return (
        <div className='my-2.5 bg-white rounded-md p-1.5 shadow-sm flex items-center'>
            {/* Search Box */}
            <SearchBox extraClassName='h-8 w-2/6' placeholder='Search name...' onChange={(event) => setSearch(event?.target?.value)} />

            {/* Date Selector */}
            <div className='relative w-[11.25rem] ml-1'>
                <div className={`border ${showCalendar ? "border-menuBorder" : ""} bg-white hover:border-menuBorder rounded-md h-8  px-3 py-3.5 gap-1 flex items-center justify-around shadow-bottom cursor-pointer`} onClick={() => { setShowCalendar(!showCalendar) }}>
                    <MdOutlineCalendarToday className='text-[#667085] size-4' />
                    <h5 className='text-[0.7rem] text-[#667085] font-medium'>Select dates</h5>
                </div>
                {showCalendar && <DateRangeSelector selectedDate={selectedDate} setSelectedDate={setDate} setShowCalendar={setShowCalendar} right='left-0' top='top-9' />}
            </div>

            {/* Generate API Key Button */}
            <div className='w-full flex justify-end mr-1.5'>
                <Button LeftIcon={<MdAdd size={18} />} name='Generate API key' customClass='h-[2.125rem]' onClick={() => dispatch(setApiFormData({ modal: true }))} />
            </div>

            {/* Generate API Key Modal */}
            <Modal isCentered isOpen={formData?.modal} onClose={onCloseModal} size={'lg'} closeOnOverlayClick={false} closeOnEsc={false}>
                <ModalOverlay backdropFilter="blur(2px)" />
                <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
                    <ModalHeader className="flex items-center justify-between !px-4 !pb-2 dark:bg-darkOne">
                        <h5 className="text-[.9375rem] font-semibold text-heading capitalize dark:text-white select-none">
                            Generate API Key
                        </h5>
                    </ModalHeader>
                    <ModalBody className="!px-4 !py-0 cursor-pointer gap-4 overflow-y-auto rounded-lg">
                        <div className='w-full'>
                            <InputField name='name' onChange={handleInputChange} value={formData?.name} label='Name*' inputClass='!h-9 !rounded-md' customClass='!mt-0' labelClass='!ml-0 !font-medium !text-heading select-none' placeholder='Enter API key name...' />
                            <InputField name='limit' onChange={handleInputChange} value={formData?.limit} label='Limit' type='number' extra='mt-1' inputClass='!h-9 !rounded-md' customClass='!mt-0' labelClass='!ml-0 !font-medium !text-heading select-none' placeholder='400' />
                        </div>
                    </ModalBody>
                    <ModalFooter className='!p-4 !flex-col'>
                        <div className="flex w-full justify-center gap-3">
                            <Button onClick={onCloseModal} type="button" name="Cancel" fontSize="xs" customClass="select-none font-medium text-sm w-full !bg-[#fff] !text-[#344054] h-9" />
                            <Button
                                type="button"
                                name={formData?.uuid ? "Save" : "Generate"}
                                fontSize="xs"
                                customClass="text-column w-full dark:border-none select-none h-9"
                                onClick={handleGenerateOrSaveAPI}
                            />
                        </div>
                        {
                            generatedApiKey?.length ?
                                <div className='px-4 mt-4 bg-lightyellow flex w-full py-2 rounded-md select-none'>
                                    <div className='w-full'>
                                        <h3 className='text-sm font-bold text-heading'>API key successfully created</h3>
                                        <h4 className='mt-1 text-xs text-column'>XXXXXXXXXXQWEAA</h4>
                                    </div>
                                    <div className='w-8 flex justify-end items-center cursor-pointer' onClick={() => handleCopyKey('XXXXXXXXXXQWEAA')}><BiCopy /></div>
                                </div>
                                :
                                <></>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default APIHeader