import ArrowedTooltip from 'components/ArrowedTooltip';
import React from 'react';

function CustomGroupTags({ data }: { data: any }) {
  const [showOpt, setShowOpt] = React.useState(false);
  const tooltipRef = React.useRef(null);
  const toggleRef = React.useRef(null);
  // console.log('data', data);
  // OnBlur functionality for tooltip
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !toggleRef?.current?.contains(event.target)) {
        setShowOpt(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [tooltipRef]);

  const ToolTipComponent = ({
    groups,
  }: {
    groups: {
      id?: string;
      menu_name?: string;
      uuid?: string;
      status?: number;
    }[];
  }) => {
    return (
      <div className=" flex flex-col "> 
      {/* removed relative from above tag */}
        {groups.map(
          (
            group: {
              id?: string;
              menu_name?: string;
              uuid?: string;
              status?: number;
            },
            index: number
          ) => (
            <div className='flex flex-col py-2.5 w-full'>
              {
                <div key={index} className="group absolute flex items-center justify-between -mt-3 w-full">
                  <span className=" cursor-default  text-start text-xs text-gray-100  text-nowrap"> {group.menu_name}</span>
                  {/* <button
                    className="hidden px-2 text-sm hover:text-gray-500 group-hover:block"
                    onClick={() => handleDelteGroup(group.uuid)}
                  >
                    x
                  </button> */}
                </div>

              }
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div className=" flex">
      {/* removed relative from above tag */}

      {data?.length > 0 &&
        data?.slice(0, 4)?.map((val: any, key: number) => (
          <>
            {
              <div
                key={key}
                className={`group   mx-0.5 px-3 py-0.5 ${key === 0 ? 'bg-[#EFF8FF]' : key === 1 ? 'bg-[#F4F3FF]' : key === 2 ? 'bg-[#FEF6EE]' : 'bg-[#EEF4FF]'}  text-xs ${key === 0 ? 'text-[#175CD3]' : key === 1 ? 'text-[#6941C6]' : key === 2 ? 'text-[#B93815]' : 'text-[#3538CD]'
                  } w-min  cursor-default whitespace-nowrap rounded-full font-[500]`}
              >
      {/* removed relative from above tag */}

                <span id={`added-group-${key}`}>{val.menu_name}</span>
              </div>
            }
          </>
        ))}
      <>
        {data?.workspace_name && (
          <div className={`group   mx-0.5 w-min cursor-default whitespace-nowrap  rounded-full bg-[#EFF8FF] px-3  py-0.5 text-xs font-[500] text-[#175CD3]`}>
      {/* removed relative from above tag */}

            <span>{data?.workspace_name}</span>
          </div>
        )}
      </>
      {data?.length > 4 && (
        <div className="" ref={toggleRef}>
      {/* removed relative from below tag */}

          <span
            className="  flex cursor-pointer items-center justify-center rounded-full bg-gray-50 px-2 text-xs font-semibold hover:bg-gray-100"
            onClick={() => setShowOpt((prev) => !prev)}
          >
            +{data?.length - 4}
          </span>
          {showOpt && (
            <div className="absolute -mt-4 -ml-4" ref={tooltipRef}>
              <ArrowedTooltip width='w-36' data={<ToolTipComponent groups={data?.slice(4)} />} className="!-left-10" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomGroupTags;
