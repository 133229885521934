/* eslint-disable react/no-is-mounted */
interface CustomContext {
    customCommand: {
        targetButton: HTMLElement;
    };
}

const customUnsubscribePlugin = {
    name: 'unsubscribeLink',
    display: 'command',
    title: 'Add unsubscribe link',
    innerHTML: 'U',
    add: function (core: any, targetElement: HTMLElement) {
        const context: CustomContext = core.context;
        context.customCommand = {
            targetButton: targetElement
        };

        // Bind the action and active functions to the core object
        this.action = this?.action?.bind(core);
        this.active = this?.active?.bind(core);
        // console.log('core', core)

    },
    action: function (this: any) {
        if (!this?.util?.hasClass(this?.context?.customCommand?.targetButton, 'active')) {
            const unsubscribeUrl = '#';
            const newNode = this?.util?.createElement('A');
            newNode?.setAttribute('href', unsubscribeUrl);
            newNode?.setAttribute('id', 'unsub_link_id');
            // console.log('newNode', this)
            // this?.nodeChange(newNode, ['id', 'href']);
        } else {
            this?.nodeChange(null, ['id', 'href'], ['A'], true);
        }
    },
    active: function (this: any, element: HTMLElement | null) {
        if (!element) {
            this?.util?.removeClass(this?.context?.customCommand?.targetButton, 'active');
        } else if (element.nodeName === 'A' && (element as HTMLAnchorElement).id === 'unsub_link_id') {
            this?.util?.addClass(this?.context?.customCommand?.targetButton, 'active');
            return true;
        }
        return false;
    },
};

export default customUnsubscribePlugin;
