import { BaseApi } from 'common/api/common/base-api';
import Input from 'common/sparkle-common/Input';
import { generateUUID } from 'common/utils/utility';
import { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { useAppSelector } from 'store';

interface FieldsDTO {
  first_name: string;
  last_name: string;
  id: string;
}
// type DomainProps = {
//   setHandleNextStep: React.Dispatch<React.SetStateAction<() => void>>;
// };

type DomainProps = {
  setHandleNextStep: any;
};

const CustomizeEmail: React.FC<DomainProps> = ({ setHandleNextStep }) => {
  const { domain_list, domain_uuid } = useAppSelector((state) => state.senderGroupListData);

  const [fields, setFields] = useState<FieldsDTO[]>(Array.from({ length: domain_list?.length }).map(() => ({ id: generateUUID(), first_name: '', last_name: '' })));

  const addFields = async () => {
    setFields([...fields, { id: generateUUID(), first_name: '', last_name: '' }]);
  };

  const deleteFields = (id: string) => {
    const data = fields.filter((item) => item.id !== id);
    setFields(data);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, uuid: string, type: string) => {
    const { value } = e.target;
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === uuid
          ? {
              ...field,
              [type === 'fname' ? 'first_name' : 'last_name']: value,
            }
          : field
      )
    );
  };

  const handleNext = async() => {
    if(domain_uuid){
      const response = await BaseApi.patch(`/buy/domain`, fields)
      if(response?.data){
        return true
      }
      return false
    }
    console.log('DmarkSetup next logic');
  };

  useEffect(() => {
    setHandleNextStep(() => handleNext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHandleNextStep]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-2/3 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">Customize your email IDs</p>
        <hr />

        <div className="my-4 flex flex-col gap-3">
          <div className="bg-[#fafafc] p-3">
            <div className="flex items-start justify-between gap-3">
              <div className="w-1/3">
                <p className="text-base font-medium text-[#344054]">Add Profiles</p>
                <p className="mt-3 text-xs text-column">
                  Enter the fields for each profile. You can add as many profiles as you need. On the next page, you'll be able to customize email IDs individually for each
                  profile.
                </p>
              </div>

              <div className="h-[22rem] overflow-auto ">
                {fields?.map((item: { id: string; first_name: string; last_name: string }, index: number) => (
                  <div className="my-2 flex items-center gap-5" key={item.id}>
                    <p className="text-xs font-medium text-column">Profile {index + 1}</p>
                    <div>
                      <p className="my-1 text-xs font-medium text-heading">First Name</p>
                      <Input value={item.first_name} onChange={(e) => handleInputChange(e, item.id, 'fname')} customClass="!w-44" />
                    </div>
                    <div>
                      <p className="my-1 text-xs font-medium text-heading">Last Name</p>
                      <Input value={item.last_name} onChange={(e) => handleInputChange(e, item.id, 'lname')} customClass="!w-44" />
                    </div>
                    <AiOutlineDelete onClick={() => deleteFields(item.id)} className="cursor-pointer text-column" />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex cursor-pointer items-center justify-end gap-2 bg-[#fafafc] text-sm font-medium text-blueSecondary" onClick={addFields}>
              <AiOutlinePlus /> Add profile
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeEmail;
