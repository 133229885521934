import {
  brandInputsType,
  finalSettingsType,
  IdsType,
  loadGroupsType,
  prospectsType,
  ProspectVerificationType,
  scheduleDataType,
  scheduleSettingsType,
  sequenceDataType,
  SettingDataType,
  stopReplyToType,
  trackingOptionsType,
} from 'common/dto/types/views/campaignv2';
// import { searchProspectsToolLists } from '../views/campaign-v2';

export const brand_inputs: brandInputsType = {
  name: '',
  icp: '',
  pain_points: '',
  solution: '',
  geography_target: '',
  about_brand_details: '',
};
export const prospects: prospectsType = {
  prospects_list: [],
  uploaded_prospects_list: [],
  file_name: '',
  prospect_type: 1, // 1 - upload csv, 2 - search prospects,
  prospects_flow: 'manual_upload',
  selected_variable: {},
  uploaded_leads_count: {
    view: false,
    total_duplicates_count: 0,//
    invalid_count: 0,//
    totalCount: 0,//
    contacts_in_donot_count: 0,//
    skip_prospect_from_active_campaigns_count: 0,
    donot_removed_prospects_list: []
  },
  manage_duplicate: {
    skip_prospect_from_active_campaigns: false,
  },
  variable_list: [],
  enrichment: {
    status: false,
    modalView: false,
    stepCount: 1,
    enrichmentType: 'waterfall',
    enrichment_data: [],
    enchriment_keys: [],
  },
  update_variables: {
    modal_view: false,
    row_email: '',
  },
  similarEmailModal: { view: false, count: 0, list: [] },
  preview: {
    isPreview: false,
    update_prospect_list: [],
  },
  search_prospects: {
    data: [],
    selected_prospects: [],
    total_records: 0,
    temprory_id: '',
    tool_details: {
      name: "apollo",
      title: '',
      api_key_method: 'sparkle',
      api_key: '',
    },
    users_count: {
      max_people_per_company: 0,
      max_people: false,
      select_number_of_people: 0,
    },
    prospects_filter: {
      search_type: 'people',
      filter_data: {
        // Name
        people_name: '',
        // Job Titles
        job_titles: {
          view: {
            is_not_any_of: false,
            include_past_job_titles: false,
          },
          selected_method: 'is_any_of',
          is_any_of_data: {
            job_titles: [],
            is_not_any_of: [],
            include_past_job_titles: [],
          },
          management_level: [],
          department_job_function: [],
        },
        // Companies
        companies: {
          view: {
            is_not_any_of: false,
            include_past_companies: false,
          },
          selected_method: 'is_any_of',
          is_any_of_data: {
            companies: [],
            is_not_any_of: [],
            include_past_companies: [],
          },
          include_list: '',
          exclude_list: '',
          include_list_id: '',
          exclude_list_id: '',
        },
        // Location
        location: {
          view: {
            exclude_location: false,
            range_view: false,
          },
          selected_method: 'region',
          region: {
            include: [],
            exclude: [],
          },
          zip_code: {
            zip: '',
            range: '50',
          },
        },
        // Employees
        employees: {
          selected_method: 'predefined_range',
          predefined_range: [],
          custom_range: {
            min: '',
            max: '',
          },
        },
        // Industry & keywords
        industry_keywords: {
          view: {
            include: false,
            include_all: false,
            exclude_keywords: false,
          },
          selected_method: 'is_not_any_of',
          industries: '',
          is_not_any_of_data: '',
          company_keywords: {
            include: '',
            include_all: '',
            exclude: '',
          },
        },
        // Technologies
        technologies: {
          include: [],
          include_all: [],
        },
        // Revenue
        revenue: {
          selected_method: 'is_between',
          is_between: {
            min: '',
            max: '',
          },
          private_company: false,
          public_company: false,
        },
        // Funding
        funding: {
          funding_data: [],
          selected_method: 'is_between',
          last_funding_round_date: {
            from: '',
            to: '',
          },
          last_funding_round_amount: {
            min: '',
            max: '',
          },
        },
        // Job Posting
        job_posting: {
          job_titles: '',
          locations: '',
          active_job_posting: {
            min: '',
            max: '',
          },
          job_posted_at: {
            from: '',
            to: '',
          },
        },
      },
    },
  },
};

export const ids: IdsType = {
  uuid: '',
  campaign_id: '',
  brand_id: '',
  prospects_id: '',
  settings_id: '',
  sequence_id: '',
};

export const stopReplyToData: stopReplyToType = {
  reply_for_email: false,
  reply_for_linkedin: false,
  reply_for_call: false,
  click_on_link: false,
};

export const trackingOptionsData: trackingOptionsType = {
  open: false,
  link_clicks: false,
};

export const prospects_verification: ProspectVerificationType = {
  modal_view: false,
  step_count: 1, // 1 - Initial popup view, 2 - Prospects List, 3 - Select emails popup view, 4 - Error emails selected popup view, 5 - True verifier popup view
  verifierData: [],
};

export const senderListData: any = [];

export const finalSettingsData: finalSettingsType = {
  unsubscribe_message_rotation: false,
  unsubscribe: '',
  custom_unsubscribe: '',
  signature: '',
  signature_rotation: false,
  remove_watermark_signature: false,
  selectedUnsubscribeLinks: {},
  selectedSignature: {},
  editSignatureId: '',
  unsubscribeEditId: '',
  unsubscribeAndSignatureData: {},
};

export const loadGroupsData: loadGroupsType = {
  email: true,
};

export const scheduleSettingsData: scheduleSettingsType = {
  campaign_start_date: new Date().toString(),
  campaign_end_date: new Date().toString(),
  schedule_id: '',
};

export const scheduleData: scheduleDataType = {
  uuid: '',
  name: '',
  time_zone: '',
  days: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
    sun: false,
  },
  start_time: { hours: '08', minutes: '00', period: 'AM' },
  end_time: { hours: '05', minutes: '00', period: 'PM' },
};

export const settingData: SettingDataType = {
  //    Initial modal data
  selectedEmailAliasList: {},
  selectedSenderList: {},
  selectedGroupList: {},
  // Seconde modal data
  isCampaignStartDateSelected: false,
  isCampaignEndDateSelected: false,
  skip_holidays: {
    status: false,
    country: '',
  },
  sending_priority: {
    prioritize_this_campaign: false,
    prioritize_follow_ups: false,
  },

  // Third modal data
  signature_type: 'nothing',
};

export const reviewState: any = {
  preview: {
    preview_modal: false,
    preview_list: [],
    search: '',
    list_count: 0,
    selected_index: 0,
    loading: false,
  },
};

export const sequenceData: sequenceDataType = {
  items: [],
  id: '',
  type: '',
  versionAB: false,
  delivery_data: {
    version_a: {
      subject: '',
      message: '',
    },
    version_b: {
      subject: '',
      message: '',
    },
  },
  delay: { days: 1 },
  delayModal: false,
  triggerModal: false,
  deleteContentMenu: false,
  deleteTriggerMenu: false,
  next: null,
  trigger_on: null,
  trigger: null,
};

export const dummy: any = [
  {
    items: [],
    id: 'abc-123-def-456',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: 'xyz-123-mno-456',
    trigger_on: null,
    trigger: null,
  },
  {
    items: [],
    id: 'xyz-123-mno-456',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: null,
    trigger: {
      yes: 'triggered-1',
      no: 'triggered-2',
      trigger_variant: 'email',
      trigger_type: 'open_email',
    },
  },
  {
    items: [],
    id: 'triggered-1',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    // next: "end-3",
    next: null,
    trigger_on: 'yes',
    trigger: null,
  },
  {
    items: [],
    id: 'triggered-2',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'no',
    trigger: null,
    // trigger: {
    //     yes: "end-1",
    //     no: "end-2",
    //     trigger_variant: "email",  // email, whatsapp, call, linkedin, twitter, sms
    //     trigger_type: "open_email"
    // }
  },
  {
    items: [],
    id: 'end-1',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'yes',
    trigger: {
      yes: 'end-5',
      no: 'end-6',
      trigger_variant: 'email', // email, whatsapp, call, linkedin, twitter, sms
      trigger_type: 'open_email',
    },
  },
  {
    items: [],
    id: 'end-2',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'no',
    trigger: null,
  },
  {
    items: [],
    id: 'end-3',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: null,
    trigger: {
      yes: 'end-9',
      no: 'end-10',
      trigger_variant: 'email', // email, whatsapp, call, linkedin, twitter, sms
      trigger_type: 'open_email',
    },
  },
  {
    items: [],
    id: 'end-5',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'yes',
    trigger: {
      yes: 'end-11',
      no: 'end-12',
      trigger_variant: 'email', // email, whatsapp, call, linkedin, twitter, sms
      trigger_type: 'open_email',
    },
  },
  {
    items: [],
    id: 'end-6',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'no',
    trigger: null,
  },
  {
    items: [],
    id: 'end-9',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'yes',
    trigger: null,
  },
  {
    items: [],
    id: 'end-10',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'no',
    trigger: null,
  },
  {
    items: [],
    id: 'end-11',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'yes',
    trigger: null,
  },
  {
    items: [],
    id: 'end-12',
    type: 'email', // email, whatsapp, call, linkedin, twitter, sms
    versionAB: false,
    delivery_data: {
      version_a: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
      version_b: {
        subject: 'Campaign V2 Wednesday Test 2',
        message: '<p>Hello <b>{{first_name}}</b></p><p>Welcome</p><p>to Sparkle</p><p>{{signature}}</p>',
      },
    },
    delay: { days: 0 },
    delayModal: false,
    delayCount: 0,
    next: null,
    trigger_on: 'no',
    trigger: null,
  },
];
