import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, toastState } from 'common/utils/utility';
import React from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setSingleProspectsData } from 'store/campaign-v2';

type ButtonProps = {
  text: string;
  enrichmentType: string;
  textColor?: string;
  leftIcon?: React.JSX.Element;
  borderColor?: string;
  fontSize?: string;
  prospectId?: string[];
};

const AccessDataButton = (props: ButtonProps) => {
  const { leftIcon, text, enrichmentType, textColor, borderColor, prospectId } = props;
  // const searchedProspects = useAppSelector((state) => state.campaignv2.prospects.search_prospects.data);
  const tool_details = useAppSelector((state) => state.campaignv2.prospects.search_prospects.tool_details);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  //   const handleButtonClick = async () => {
  //     if (enrichmentType === 'email') {
  //       try {
  //         setLoading(true);
  //         const updatedData = data.map((item) => {
  //             if (item?.uuid === prospectId) {
  //               return {
  //                 ...item,
  //                 email: changeMail, 
  //               };
  //             }
  //             return item;
  //           });
  //           dispatch(setSearchProspectsData(updatedData))


  //         await new Promise((resolve) => setTimeout(resolve, 1500));
  //         toast.success('Enriched Successfully');
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  const handleButtonClick = async () => {
    if (enrichmentType === 'email') {
      try {
        setLoading(true);
        const params = {
          enrichment_tools: [{ name: tool_details?.name, apiKey: tool_details?.api_key_method === 'sparkle' ? "sparkle" : tool_details?.api_key }],
          prospects_list: prospectId?.map(({ email, ...rest }: any) => ({ ...rest })),
          prospects_count: 1,
          keys: ["email"],
          // matched_keys: {
          //   id: "id",
          //   first_name: "first_name",
          //   last_name: "last_name",
          //   name: "full_name",
          //   linkedin_url: "linkedin_url",
          //   organization_name: "organization_name"
          // }
          // campaign_id,
          // prospects_type: "search"
        }
        const response = await BaseApi.post('/prospects/enrich', params)
        if (!response.error) {
          const responseData = response?.data?.map((item: any) => ({ id: item?.id, email: item?.email }));
          // const updatedData = searchedProspects?.map((item) => {
          //   const enrichedItem = responseData.find((element: any) => element?.id === item?.id);
          //   return enrichedItem ? { ...item, email: enrichedItem.email } : item;
          // }) || [];
          dispatch(setSingleProspectsData(responseData));
          toast.success('Email accessed successfully', toastState.success);
        } else {
          toast.error(_errorHandler(response?.message), toastState.error);
        }
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <Button
      LeftIcon={leftIcon}
      name={`${text}`}
      onClick={handleButtonClick}
      loading={loading}
      disable={loading}
      customClass={`access_email_prospects_btn bg-white dark:!bg-gray-100 border w-32  ${borderColor} font-semibold text-xs active:scale-95 transition-transform duration-150 ease-out ${loading ? '!text-column' : `!${textColor}`
        }`}
    />
  );
};

export default AccessDataButton;
