/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ListRow from './features/ListRow';
import { BiArrowBack } from 'react-icons/bi';
import { CustomMenu } from 'components/customMenu';
import { FiSearch } from 'react-icons/fi';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextInput } from 'views/crm/features/unmatched-emails/TextInput';
import Button from 'common/sparkle-common/Button';
import SparkleRadio from 'common/sparkle-common/Radio';
import { VscListFilter } from 'react-icons/vsc';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { setfetchAllDataTrigger, setFetchGroupListData } from 'store/unmatched';
import { checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import { useAppDispatch, useAppSelector } from 'store';
import InfiniteScrollComponent from 'components/infinityScroll';
import Groups from 'views/campaigns-v2/features/campaign-creation/settings/features/sender-settings/Groups';
import { FaAngleDown } from 'react-icons/fa';
import { Avatar, Box, SkeletonText } from '@chakra-ui/react';
import InboxSkeleton from 'views/Inbox/feature/inbox-skeleton/InboxSkeleton';
import { MdClear } from 'react-icons/md';
import { AZ_BUCKET } from 'common/constants/constants';
import { removeSelectedGroupList } from 'store/campaign-v2';
import SenderEmailList from 'views/sender-accounts';

const UnmatchedEmails = () => {
  const dispatch = useAppDispatch();
  const { fetchGrouplistTrigger } = useAppSelector((state) => state.unmatched);
  const { sideBarFullView } = useAppSelector((state) => state.custom);
  const [loading, setLoading] = useState(false);
  const { senderList, settings } = useAppSelector((state) => state.campaignv2);
  const { selectedSenderList, selectedGroupList } = settings;
  const [showGroup, setShowGroup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const closeMenu = () => {
    setShowGroup(false);
  };
  const searchRef = useRef(null);
  const [queryParams, setQueryParams] = useState({ limit: 25, offset: 2, sort: { columnName: '', order: '' }, search: '', filter: '', sender_data: '' });
  const debouncedSearchValue = useDebounce(queryParams.search, 300);
  const { mergeSearch, fetchAllDataTrigger } = useAppSelector((state) => state.unmatched);
  // const debounceMergeSearch = useDebounce(mergeSearch, 300);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({ data: [], total_count: 0 });
  const [mergeOption, setMergeOption] = useState([]);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  const [filter, setFilter] = useState({
    rules_type: 0, // 0 - filter search, 1 - ignore , 2 - ignore rule, 3 - match campaign rule, 4 - search campaign, 5 - new oppertunity
    filter_search: {
      from: '',
      to: '',
      domain: '',
      subject: '',
      keywords: '',
      date: '',
    },
    rule_ignore: {
      once: false,
      entire_domain: false,
      entire_email: false,
    },
  });
  const custom = useAppSelector((state) => state?.custom);

  const selectedsenderUuid = Object.entries(selectedSenderList)
    .map((item: any) => {
      if (item[1]) {
        return item[0];
      } else {
        return null;
      }
    })
    .filter(Boolean);
  const Groupids = Object.keys(selectedGroupList).filter((key) => selectedGroupList[key] === true);
  console.log(Groupids, selectedSenderList, selectedsenderUuid, 'condition');
  useEffect(() => {
    const searchBySender = async () => {
      try {
        // const response = await BaseApi.get(`/unmatched/inbox/by/sender/${jwtEncrypt({ sender_id: selectedsenderUuid, group_list: Groupids })}`);
        // if (response.error) {
        //   console.log(response.error);
        // } else {
        //   setData({ data: response?.data?.data?.data, total_count: response?.data?.data?.total_count });
        // }
        setQueryParams((prev) => ({
          ...prev,
          sender_data: jwtEncrypt({ sender_id: selectedsenderUuid, group_list: Groupids }),
          offset: 2,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    // (Object.keys(selectedSenderList)?.length || Groupids.length) && searchBySender();
    Object.keys(selectedsenderUuid)?.length || Groupids.length
      ? searchBySender()
      : setQueryParams((prev) => ({
          ...prev,
          sender_data: '',
          offset: 2,
        }));
  }, [selectedSenderList, selectedGroupList]);

  const [roleStatus, setRoleStatus] = useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'unmatched');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  const selectedSenderMail = senderList?.length ? senderList?.find((item: any) => item?.uuid === selectedsenderUuid[0]) : '';

  const fetchAllData = async () => {
    const query = setAndGetQueryParams([
      { key: 'search', value: queryParams.search },
      { key: 'limit', value: queryParams?.limit },
      { key: 'sender_data', value: queryParams?.sender_data },
      { key: 'filter', value: queryParams?.filter },
    ]);
    try {
      setLoading(true);
      const response = await BaseApi.get(`unmatched/inbox?${query}`);
      if (response.error) {
        console.log(response.error);
      }
      setData({ data: response.data.data, total_count: response?.data?.total_count });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [debouncedSearchValue, fetchAllDataTrigger, queryParams.sender_data, queryParams.filter]);

  // useEffect(() => {
  //   return () => {
  //     if (selectedSenderList || selectedGroupList) {
  //       console.log(Object.keys(selectedSenderList), Object.keys(selectedGroupList));
  //       dispatch(removeSelectedGroupList());
  //     }
  //   };
  // }, []);

  // const fetchMatchOptionData = async () => {
  //   const query = setAndGetQueryParams([{ key: 'search', value: mergeSearch }]);
  //   try {
  //     const response = await BaseApi.get(`/unmatched/inbox/get/campaign/prospects?${query}`);
  //     if (response.error) {
  //       console.log(response.error);
  //     } else {
  //       setMergeOption(response?.data?.data[0]?.dataList);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMatchOptionData();
  //   console.log(3333333);
  // }, [debounceMergeSearch]);

  const fetchGrouplistData = async () => {
    try {
      const response = await BaseApi.get(`/groups/linked/with/unmatch_inbox`);
      if (response?.error) {
        toast.error(response?.message, toastState.error);
      } else {
        if (response?.data?.data && response?.data?.data?.length) {
          const finalData = await response?.data?.data?.sort((a: any, b: any) =>
            a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
          );
          const states = await response?.data?.data?.reduce((acc: any, val: any) => {
            acc[val.uuid] = { show: false, rename: false };
            return acc;
          }, {});
          const payload: any = {
            finalData,
            states,
          };
          dispatch(setFetchGroupListData(payload));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchGrouplistData();
  }, [fetchGrouplistTrigger]);

  const updateIgnoreStatus = async (e: any, status: string, uuid: string, ignored_contact?: string) => {
    e.stopPropagation();
    try {
      const payload = {
        uuid: uuid,
        ignored_status: status,
        ignored_contact,
      };
      const response = await BaseApi.patch('unmatched/inbox/ignore', payload);
      if (response.error) {
        console.log(response.error);
        return;
      }
      fetchAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeStage = (stage: number) => {
    setFilter((prev) => ({
      ...prev,
      rules_type: stage,
    }));
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const formatISODate = (isoString: string) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleInputChange = (key: string, event: any) => {
    const { name, value } = event?.target;

    if (event?.target.name === 'date') {
      if (!value) {
        setSelectedDate(null);
        setFilter((prev) => {
          const finalData: any = { ...prev };
          finalData[key][name] = '';
          return finalData;
        });
      } else {
        setSelectedDate(event?.target?.value);
        const date = new Date(event?.target.value);
        const isoString = date?.toISOString();

        setFilter((prev) => {
          const finalData: any = { ...prev };
          finalData[key][name] = isoString;
          return finalData;
        });
      }
    } else {
      setFilter((prev) => {
        const finalData: any = { ...prev };
        finalData[key][name] = value;
        return finalData;
      });
    }
  };

  const handleMailSearch = (event: any) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event?.target?.value,
    }));
  };
  const handleRadioChange = (isChecked: boolean, name: string) => {
    setFilter((prev) => {
      const finalData: any = { ...prev };

      Object.keys(finalData?.rule_ignore).forEach((key) => {
        if (key !== 'apply_matched_results') {
          finalData.rule_ignore[key] = key === name ? isChecked : false;
        }
      });

      return finalData;
    });
  };
  const handleClearFilter = () => {
    // dispatch(setfetchAllDataTrigger());
    setFilter({
      rules_type: 0,
      filter_search: {
        from: '',
        to: '',
        domain: '',
        subject: '',
        keywords: '',
        date: '',
      },
      rule_ignore: {
        once: false,
        entire_domain: false,
        entire_email: false,
      },
    });
    setQueryParams((prev) => ({
      ...prev,
      filter: '',
      limit: 25,
      offset: 2,
    }));
    setSelectedDate(null);
    handleClose();
  };
  const FetchMoreUnmatcheData = useCallback(
    async (setIsFetching: any) => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams.limit },
            { key: 'offset', value: queryParams.offset },
            { key: 'search', value: queryParams.search },
            { key: 'sender_data', value: queryParams?.sender_data },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`unmatched/inbox?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData({ data: [], total_count: 0 });
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ data: [...prev?.data, ...responseData?.data], total_count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      };
      data?.data?.length && data?.data?.length < data?.total_count && fetchData();
    },
    [data?.data?.length, debouncedSearchValue, queryParams.offset, queryParams?.sender_data, queryParams?.filter]
  );

  const handleSearchandSetRules = async (action: string) => {
    if (action === 'search') {
      try {
        setQueryParams((prev) => ({
          ...prev,
          filter: jwtEncrypt({ action: 'search', ...filter.filter_search }),
          limit: 25,
          offset: 2,
        }));
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const ignoreStatus = filter.rule_ignore.once ? 'ignore_once' : filter.rule_ignore.entire_domain ? 'ignore_future_domain' : 'ignore_future_email';
        const response = await BaseApi.get(`/unmatched/inbox/by/rule/${jwtEncrypt({ action: 'rule', ...filter.filter_search, ignored_status: ignoreStatus })}`);
        if (response.status === 200) {
          // setData({ data: response?.data?.data?.data, total_count: response?.data?.data?.totalCount[0]?.count });
          handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className={`mt-2.5 cursor-default select-none rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white`}>
      <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
        <div className="flex gap-2.5">
          <div
            className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[18rem]"
            onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer" />
            <input
              ref={searchRef}
              onChange={handleMailSearch}
              autoComplete="off"
              type="text"
              placeholder="Search"
              id="campaign-search-input"
              className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree  dark:placeholder:text-white "
            />
          </div>
        </div>
        <div className="relative flex items-center gap-3">
          <div className="relative">
            <div
              className="flex w-[25rem] cursor-pointer items-center gap-1.5 rounded-md border border-blueSecondary bg-[#F9F5FF] px-3 py-2 !text-blueSecondary"
              onClick={() => setShowGroup(!showGroup)}
              id="select-sender-account"
            >
              <div className="flex w-full items-center truncate">
                {selectedSenderMail ? (
                  <>
                    <Avatar src={selectedSenderMail?.picture || ''} name="J" bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                    <h5 className="ml-2 w-[98%] truncate text-[0.825rem] !text-blueSecondary">
                      {selectedSenderMail?.name} ({selectedSenderMail?.account_details?.email})
                    </h5>
                  </>
                ) : (
                  <span className="text-xs !text-blueSecondary">Select</span>
                )}
              </div>
              {selectedsenderUuid.length > 1 ? <span className="w-fit rounded-full bg-lightPrimary p-1 text-xs text-column">+{selectedsenderUuid?.length - 1}</span> : <></>}
              <FaAngleDown className="size-2.5" onClick={() => setShowGroup(!showGroup)} />
            </div>
            {showGroup ? <Groups type="email" onClose={closeMenu} /> : null}
          </div>
          <CustomMenu
            btnId="unmatched-filters"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            btnContent="Filters"
            leftIcon={<VscListFilter size={18} />}
            btnClassName={` border-2 ${isOpen ? 'border-menuBorder' : 'border-gray-100'} text-column rounded-md text-sm py-2 px-3.5 cursor-pointer my-2 mr-2`}
            menuListClassName={`border-2 !w-[18rem] right-0 rounded-md ${isOpen ? 'border-menuBorder' : 'border-gray-100'} `}
          >
            {filter?.rules_type === 0 && (
              <>
                <div className="border-b p-2">
                  <div className="flex cursor-pointer justify-end " id="clear-filter" onClick={() => handleClearFilter()}>
                    <p className="text-sm font-medium text-blueSecondary  hover:text-blue-600">
                      Clear <MdClear className="inline-block" />
                    </p>
                  </div>
                  <TextInput
                    id="from"
                    label="From"
                    value={filter?.filter_search?.from}
                    placeholder="jay@joytechnologies"
                    name="from"
                    onChange={(event: any) => handleInputChange('filter_search', event)}
                  />
                  <TextInput
                    id="to"
                    value={filter?.filter_search?.to}
                    label="To"
                    placeholder="Sam@hello.com"
                    name="to"
                    onChange={(event: any) => handleInputChange('filter_search', event)}
                  />
                  <TextInput
                    id="domain"
                    value={filter?.filter_search?.domain}
                    label="Domain"
                    placeholder="google.com"
                    name="domain"
                    onChange={(event: any) => handleInputChange('filter_search', event)}
                  />
                  <TextInput
                    id="subject"
                    value={filter?.filter_search?.subject}
                    label="Subject"
                    placeholder="subject"
                    name="subject"
                    onChange={(event: any) => handleInputChange('filter_search', event)}
                  />
                  <TextInput
                    id="keyword"
                    value={filter?.filter_search?.keywords}
                    label="Keywords"
                    placeholder="keywords"
                    name="keywords"
                    onChange={(event: any) => handleInputChange('filter_search', event)}
                  />
                  {/* <TextInput label="Date" placeholder="-" name="date"  onChange={(event: any) => handleInputChange('filter_search', event)} /> */}
                  {/* <input type="datetime-local" className="text-sm text-gray-700" /> */}
                  <div className="p-1">
                    <label className={`text-xs font-medium text-heading dark:text-white`}>Date</label>
                    {/* filter?.filter_search?.date && formatISODate(filter?.filter_search?.date */}
                    <input
                      autoComplete="off"
                      value={selectedDate && selectedDate}
                      onChange={(event: any) => handleInputChange('filter_search', event)}
                      type="date"
                      name="date"
                      className="mt-0.5 flex h-8 w-full items-center justify-center rounded-md border bg-white/0 px-2 py-1 text-xs  text-gray-700 outline-none"
                    />
                  </div>
                </div>
                <div className="m-2 flex gap-2">
                  <Button name="Set rules" bg="white" color="gray-800" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleChangeStage(1)} />
                  <Button id="search" name="Search" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleSearchandSetRules('search')} />
                </div>
              </>
            )}

            {filter?.rules_type === 1 && (
              <>
                <div className="flex flex-col gap-3 border-b p-3">
                  <div className="flex cursor-pointer items-center gap-2 text-sm font-medium text-column dark:text-white" onClick={() => handleChangeStage(0)}>
                    <BiArrowBack className="text-xl" /> Select ignore rule{' '}
                  </div>
                  <TextInput placeholder="Rule name" name="from" onChange={(event: any) => handleInputChange('filter_search', event)} />

                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.once} name="once" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore <span className="text-blueSecondary">once</span>
                    </div>
                  </div>
                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.entire_domain} name="entire_domain" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore entire <span className="text-blueSecondary">domain for future</span>
                    </div>
                  </div>
                  <div className="flex gap-1.5">
                    <SparkleRadio checked={filter?.rule_ignore?.entire_email} name="entire_email" onChange={handleRadioChange} />{' '}
                    <div className="text-xs font-medium">
                      Ignore entire <span className="text-blueSecondary">email</span> for future
                    </div>
                  </div>
                </div>
                <div className="mx-3 my-2 flex gap-2">
                  <Button name="Save rules" customClass="w-full transition-all duration-300" py="py-1.5" onClick={() => handleSearchandSetRules('setrules')} />
                </div>
              </>
            )}
          </CustomMenu>
        </div>
      </div>

      <div className="h-[calc(100vh-180px)] overflow-auto overflow-x-hidden ">
        <div className={`mx-4 rounded-md border dark:border-darkBorder`}>
          {loading ? (
            <>
              <InboxSkeleton />
            </>
          ) : (
            <>
              {data?.data?.length ? (
                <div>
                  <div>
                    {data.data.map((item, index) => (
                      <ListRow
                        data={item}
                        index={index}
                        key={item.uuid}
                        updateIgnoreStatus={updateIgnoreStatus}
                        mergeOption={mergeOption}
                        count={data?.data?.length}
                        roleStatus={roleStatus}
                        avatarColor={colors[getRandomIndex()]}
                      />
                    ))}
                  </div>
                  <div>
                    {data?.total_count >= 25 && data?.total_count !== data?.data?.length && (
                      <InfiniteScrollComponent loadMore={FetchMoreUnmatcheData} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />
                    )}
                  </div>
                </div>
              ) : (
                <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
                  <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
                  <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
                </figure>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnmatchedEmails;
