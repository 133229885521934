/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { BaseApi } from 'common/api/common/base-api';
import { setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import MessageRow from './feature/email/MessageRow';
import CommonLayout from './feature/common-Layout/CommonLayout';
import { useAppDispatch, useAppSelector } from 'store';
import { RemoveClearSelectData, setFetchGroupListData, setInboxlistData, setOffset, setSnoozeId, setSnoozePopupState } from 'store/unified-inbox';
import InboxSkeleton from './feature/inbox-skeleton/InboxSkeleton';
import InfiniteScrollComponent from 'components/infinityScroll';
import SnoozPickerPopup from './feature/email/SnoozPickerPopup';
import { SkeletonText } from '@chakra-ui/react';
import NotificationListRow from './feature/notification/NotificationListRow';
import { AZ_BUCKET } from 'common/constants/constants';
// import ClearEmailAlertPopup from './feature/ClearEmailAlertPopup';

const AllInboxData = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [mesagedata, setMessageData] = useState({
    totalCount: 0,
    data: [],
  });
  const custom = useAppSelector((state) => state?.custom);

  const [fetchDataTrigger, setFetchDataTrigger] = useState(false);
  const { clearSelectData, inbox_status, isSnoozePopupOpen } = useAppSelector((state) => state.unifiedInboxData);
  const { searchQuery, limit, offset, fetchGrouplistTrigger, filter } = useAppSelector((state) => state.unifiedInboxData);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const debouncedSearchValue = useDebounce(searchQuery, 300);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const query = setAndGetQueryParams([
          { key: 'search', value: searchQuery },
          { key: 'limit', value: limit },
          { key: 'filter', value: filter },
        ]);
        const response = await BaseApi.get(`/unified/inbox/${inbox_status}?${query}`);
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          dispatch(setInboxlistData(response?.data?.data));
          setMessageData({
            totalCount: response?.data?.total_count,
            data: response?.data?.data,
          });
          // setMessageData(response.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [fetchDataTrigger, inbox_status, debouncedSearchValue, custom?.globalDependencyLoading, filter]);

  const hanleDeleteGroupFromSenderList = (groupId: string) => {
    setMessageData((prev) => {
      const updatedData = prev?.data?.map((list) => {
        const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
        return {
          ...list,
          groups_list: finalGroupList,
        };
      });
      return {
        ...prev,
        data: updatedData,
      };
    });
  };

  const FetchDataTrigger = () => {
    setFetchDataTrigger((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await BaseApi.get(`/groups/linked/with/unified_inbox`);
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          if (response?.data?.data && response?.data?.data?.length) {
            const finalData = await response?.data?.data?.sort((a: any, b: any) =>
              a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
            );
            const states = await response?.data?.data?.reduce((acc: any, val: any) => {
              acc[val.uuid] = { show: false, rename: false };
              return acc;
            }, {});
            const payload: any = {
              finalData,
              states,
            };
            dispatch(setFetchGroupListData(payload));
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [fetchGrouplistTrigger]);

  const handleClearConversation = async () => {
    try {
      if (clearSelectData.length) {
        const payload = {
          unified_inbox_status: 'cleared',
          uuid: clearSelectData,
        };
        const response = await BaseApi.patch(`/unified/inbox/`, payload);
        if (response.status === 200) {
          toast.success(response.message, toastState.success);
          FetchDataTrigger();
          dispatch(RemoveClearSelectData());
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const InboxFetch = useCallback(
    async (setIsFetching: any) => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: limit },
            { key: 'offset', value: offset },
            { key: 'search', value: searchQuery },
            { key: 'filter', value: filter },
          ]);
          const response = await BaseApi.get(`/unified/inbox/email/${inbox_status}?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setMessageData({ data: [], totalCount: 0 });
          if (responseData?.data?.length < 25) {
            setMessageData((prev) => ({ data: [...prev?.data, ...responseData?.data], totalCount: prev?.data?.length }));
          } else {
            setMessageData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          // setQueryParams((prev) => ({
          //   ...prev,
          //   offset: prev?.offset + 25,
          // }));
          dispatch(setOffset(1));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      mesagedata?.data?.length && mesagedata?.data?.length < mesagedata?.totalCount && fetchData();
    },
    [mesagedata?.data?.length, debouncedSearchValue, offset, filter]
  );

  return (
    <div>
      <CommonLayout handleClearConversation={handleClearConversation}>
        {loading ? (
          <InboxSkeleton />
        ) : (
          <>
            {!mesagedata?.data?.length ? (
              <figure className="mt-10 flex  h-[62vh] flex-col items-center justify-center">
                <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" className="w-1/2 md:w-1/3 lg:w-1/4" />
                <figcaption className="mt-3 font-bold text-heading">No Data Found</figcaption>
              </figure>
            ) : (
              <div className="h-[calc(100vh-180px)] overflow-auto ">
                <div className="rounded-md border dark:!border-none">
                  {mesagedata?.data?.map((messagelist: any, index) => {
                    if (messagelist?.variant === 'email') {
                      return (
                        <MessageRow
                          index={index}
                          key={messagelist.uuid}
                          inboxData={messagelist}
                          fetchTrigger={FetchDataTrigger}
                          count={mesagedata?.data?.length}
                          AvatarColor={colors[getRandomIndex()]}
                          hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList}
                        />
                      );
                    } else if (messagelist?.variant === 'notification') {
                      return (
                        <NotificationListRow
                          index={index}
                          key={messagelist.uuid}
                          inboxData={messagelist}
                          fetchTrigger={FetchDataTrigger}
                          count={mesagedata?.data?.length}
                          AvatarColor={colors[getRandomIndex()]}
                        />
                      );
                    } else {
                      return null; // Optionally handle other variants or return null for unhandled variants
                    }
                  })}
                </div>
                <div>
                  {mesagedata?.totalCount >= 25 && mesagedata?.totalCount !== mesagedata?.data?.length && (
                    <InfiniteScrollComponent loadMore={InboxFetch} loader={<SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />} />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <div>
          <SnoozPickerPopup
            isOpen={isSnoozePopupOpen}
            onClose={() => {
              dispatch(setSnoozePopupState(false));
              dispatch(setSnoozeId(''));
            }}
            FetchDataTrigger={FetchDataTrigger}
          />
        </div>
      </CommonLayout>
    </div>
  );
};

export default AllInboxData;
