import { IoMdAdd } from "react-icons/io"
import { useDispatch } from "react-redux"
import { createContext, useState } from "react"
import React from "react"

import { useAppSelector } from "store"
import CreateSequence from "./CreateSequence"
import ContentDrawer from "./Drawer/ContentDrawer"
import { SequenceBox } from "./SequenceBox"
import { setCreateSequenceModal, setCurrentSequenceID } from "store/campaign-v2"
import { SequenceItemType, SequencePropsType } from "common/dto/types/views/campaignv2"

export const SequenceContext = createContext(null)

const SequencePage = (props: SequencePropsType) => {
  const { access, disable } = props;
  const [triggerData, setTriggerData] = useState([])
  const state = useAppSelector(state => state?.campaignv2)
  const custom = useAppSelector(state => state?.custom)
  const dispatch = useDispatch()

  React.useEffect(
    () => {
      const transformedData = buildNestedItems(state?.sequence, state?.sequence?.[0]?.id);
      setTriggerData(transformedData)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.sequence]
  )

  const buildNestedItems = (data: SequenceItemType[], currentId: string): SequenceItemType[] => {
    return data
      .filter(item => item.id === currentId)
      .map(item => ({
        ...item,
        items: item.trigger
          ? [
            ...buildNestedItems(data, item.trigger.yes),
            ...buildNestedItems(data, item.trigger.no)
          ]
          : item.next
            ? buildNestedItems(data, item.next)
            : []
      }));
  };


  return (
    <SequenceContext.Provider value={access}>
      <div style={{ scale: `${state?.scale}%` }} className={`flex flex-col justify-center items-center relative py-2.5 w-full origin-top ${disable}`}>
        <div className='shadow-box w-[19.3rem] rounded-md border border-[#E9EDF7] py-[1.1rem] bg-white flex items-center justify-center text-paused-row text-[0.82rem] font-medium select-none'>Sequence start</div>
        <div className='flex flex-col justify-center items-center relative'>
          <hr className={`border-r-[0.5px] border-[#aab3ca] h-3`} />
          <button
            onClick={() => {
              if (!access) return
              dispatch(setCreateSequenceModal());
              dispatch(setCurrentSequenceID(''));

            }}
            id="add_sequence_btn_0"
          ><IoMdAdd className={`size-[1rem] p-[0.1rem] text-white bg-brand-500 rounded-full relative hover:rotate-90 transition-all duration-500`} /></button>
        </div>

        <SequenceBox items={triggerData} />

        {state?.createSequenceModal && <CreateSequence />}
        {custom?.drawerStatus && <ContentDrawer />}
      </div>
    </SequenceContext.Provider>

  )
}

export default SequencePage