import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoMdAdd } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { BiChevronDown, BiSolidCloudDownload, BiSortUp } from 'react-icons/bi';
import { Menu, MenuButton, MenuItem, MenuList, Button } from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsFillPlusCircleFill, BsThreeDotsVertical } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';

import Opportunity from './features/drag-and-drop/Opportunity';
import { useAppSelector } from 'store';
import { setBrandId, setNewOpportunity, setPipelineId, setSelectPipelineModal, setSortOpportunityData, setStage, setStageOpportunityData } from 'store/crm';
import CustomButton from 'common/sparkle-common/Button';
import { BaseApi } from 'common/api/common/base-api';
import { _getQueryParams, checkRolePermissionStatus, hexToRGBA, jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import UploadOpportunitiesPopup from 'components/crm/UploadOpportunitiesPopup';
import DeleteConfirmModal from 'components/crm/DeleteConfrimModal';
import StageEdit from './features/stage-edit';
import InfiniteScrollComponent from 'components/infinityScroll';
import SelectPipelinePopup from './features/select-pipeline-popup/SelectPipelinePopup';
import CrmSkeleton from './features/crm-skeleton/CrmSkeleton';
import { OpportunityDataDto, StageData } from 'common/dto/types/crm';
import { AZ_BUCKET } from 'common/constants/constants';

const CRM = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const custom = useAppSelector((state) => state?.custom);
  const getQueryIds = _getQueryParams('_id');
  const queryPipelineID = getQueryIds?.pipeline_id;
  const queryBrandId = getQueryIds?.brand_id;
  const { pipeline_id, brandId, pipelineName } = useAppSelector((state) => state.crm);
  const { sideBarFullView } = useAppSelector((state) => state.custom);
  const [addNewStagePopup, setAddNewStagePopup] = React.useState(false);
  const [openStageModal, setOpenStageModal] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [stageName, setStageName] = useState('');
  const [editingStageId, setEditingStageId] = useState(null);
  const [stageToDelete, setStageToDelete] = useState(null);
  const searchRef = useRef(null);
  const stageNameRef = useRef([]);
  const popupRef = useRef(null);
  const crm = useAppSelector((state) => state?.crm);
  const opportunityUploadedState = useAppSelector((state) => state.crm.isOpportunitiesUploaded);
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = React.useState<any>({});
  const [search, setSearch] = useState('');
  const debouncedSearchValue = useDebounce(search, 300);
  const [stageFormData, setStageFormData] = React.useState({
    color_code: '#44DCFD',
    stage_name: '',
  });
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];

  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  useEffect(() => {
    return () => {
      dispatch(setStage([]));
      dispatch(setNewOpportunity([]));
    };
  }, [dispatch, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    if (pipeline_id && brandId) {
      const data = jwtEncrypt({
        pipeline_id: pipeline_id,
        brand_id: brandId,
        pipelineName: pipelineName,
      });
      dispatch(setSelectPipelineModal(false));

      navigate(`/crm?_id=${data}`);
    } else if (queryPipelineID && queryBrandId) {
      const payload = {
        uuid: queryPipelineID,
        pipeline_name: getQueryIds?.pipelineName,
      };
      dispatch(setPipelineId(payload));
      dispatch(setBrandId(queryBrandId));
      dispatch(setSelectPipelineModal(false));
      return;
    } else {
      dispatch(setPipelineId(''));
      dispatch(setBrandId(''));
      dispatch(setSelectPipelineModal(true));
      // navigate('/crm');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const dropAction = async (colId: number) => {
    try {
      if (crm?.stageData[colId]?.uuid) {
        const data = {
          stage_id: crm?.stageData[colId]?.uuid,
          opportunity_id: crm?.currentOpportunityId,
        };
        const response = await BaseApi.patch('/crm/pipeline/opportunity', data);
        if (response.error) {
          toast.error(response?.message, toastState.error);
          return;
        }
      }
    } catch (error) {
      console.error('Failed to update stage');
    }
  };
  const processExcelData = (data: any) => {
    let rows: any = [];
    data.forEach((stage: StageData) => {
      stage.opportunityData.forEach((opportunity: OpportunityDataDto) => {
        let row = {
          stage_name: stage.stage_name,
          uuid: opportunity.opportunity?.uuid,
          prospect_email: opportunity.opportunity?.prospect_email,
          prospect_email_domain: opportunity?.opportunity.prospect_email_domain,
          email: opportunity.opportunity?.email,
          prospect_name: opportunity?.opportunity['prospect_name'],
        };
        rows.push(row);
      });
    });

    return rows;
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const handleExportData = async () => {
    const response = await BaseApi.get(`crm/pipeline/opportunity/export/${queryPipelineID}`);
    const rows = processExcelData(response.data);
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CRM Pipeline data');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'crm_pipeline_report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const handleSortOpportunities = async (stageName: string, uuid: string) => {
    await setQueryParams((prevState: any) => ({
      ...prevState,
      [stageName]: {
        ...prevState[stageName],
        sort: prevState[stageName].sort === '_id%asc' ? '_id%dsc' : '_id%asc',
      },
    }));

    try {
      const response = await BaseApi.get(`/crm/pipeline/opportunity/${uuid}?sort=${queryParams[stageName]?.sort}`);
      if (response?.data?.length) {
        const payload = { data: response.data, uuid };
        dispatch(setSortOpportunityData(payload));
        //setData((prev) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length, }))
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStageData = async () => {
    try {
      setIsLoading(true);
      const query = setAndGetQueryParams([{ key: 'search', value: search }]);
      if (queryPipelineID) {
        const response = await BaseApi.get(`/crm/pipeline/opportunity/by/pipeline/${queryPipelineID}?${query}`);
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          const sortedStages = await response?.data?.sort((a: any, b: any) => a.order - b.order);
          dispatch(setStage(sortedStages));
          dispatch(setNewOpportunity(sortedStages));
          const queryParamsData = await sortedStages.reduce((acc: any, curr: any) => {
            acc[curr.stage_name] = {
              limit: 25,
              offset: 0,
              search: '',
              sort: '_id%asc',
            };
            return acc;
          }, {});
          setQueryParams(queryParamsData); // query
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchStageData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPipelineID, opportunityUploadedState, debouncedSearchValue, custom?.globalDependencyLoading]);

  React.useEffect(() => { }, [stageNameRef, isEditing]);

  const dependencies = crm?.stageData?.map((item: StageData) => {
    const length = item.opportunityData?.length;
    const offset = queryParams[item.stage_name]?.offset;
    // const search = queryParams[item.stage_name]?.search;
    const sort = queryParams[item.stage_name]?.sort;
    return {
      length,
      offset,
      // search,
      sort,
    };
  });

  const fetchMoreStageData = React.useCallback(
    async (setIsFetching: any, uuid: string, stageName: string) => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'offset', value: queryParams[stageName]?.offset },
            // { key: 'search', value: queryParams[stageName]?.search },
            { key: 'sort', value: queryParams[stageName]?.sort },
          ]);
          const response = await BaseApi.get(`/crm/pipeline/opportunity/${uuid}?${query}`);
          if (!response.error) {
            //toast.success(response.message, toastState.error);
          }
          if (response?.data?.length) {
            const payload = { data: response.data, uuid };
            dispatch(setStageOpportunityData(payload));

            setQueryParams((prevState: any) => ({
              ...prevState,
              [stageName]: {
                ...prevState[stageName],
                offset: prevState[stageName].offset + 25,
              },
            }));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetching(false);
        }
      };
      const stage = crm.stageData?.find((item: { stage_name: string }) => item.stage_name === stageName);
      stage?.opportunityData?.length && stage.opportunityData?.length < stage?.total_count && fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dependencies]
  );

  function useOutsideClick(ref: any, callback: any) {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim().toLowerCase());
  };

  useOutsideClick(popupRef, () => {
    if (addNewStagePopup) {
      setAddNewStagePopup(false);
    }
  });

  const handleEditStage = (card: StageData, index: number) => {
    setIsEditing(true);
    stageNameRef.current[index]?.focus();
    setEditingStageId(index);
    setStageName(card?.stage_name);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStageName(e.target.value);
  };
  const handlestageDelete = async () => {
    if (stageToDelete) {
      try {
        const response = await BaseApi.delete(`/crm/pipeline/stage/`, { data: { delete_ids: [stageToDelete] } });
        if (response.error) {
          toast.error(response.message);
        } else {
          toast.success('Stage deleted successfully', toastState.success);
          fetchStageData();
          setStageToDelete(null);
        }
      } catch (error) {
        setStageToDelete(null);
        console.error('Error deleting stage:', error);
        toast.error('Failed to delete stage');
      }
      setIsDeleteModalOpen(false);
    }
  };

  const handleKeyChange = async (e: any) => {
    if (e.keyCode === 13) {
      await handleUpdateStageName();
    }
  };

  const handleSave = async () => {
    await handleUpdateStageName();
  };

  const handleUpdateStageName = async () => {
    let editUuid;
    setIsEditing(false);
    const updatedStages = crm.stageData.map((stage: { uuid: string }, index: number) => {
      if (index === editingStageId) {
        editUuid = stage.uuid;
        return { ...stage, stage_name: stageName };
      }
      return stage;
    });
    await dispatch(setStage(updatedStages));
    setEditingStageId(null);
    if (editUuid) {
      try {
        const response = await BaseApi.patch('/crm/pipeline/stage/', {
          stage_list: [
            {
              uuid: editUuid,
              stage_name: stageName,
            },
          ],
        });

        if (response.error) {
          toast.error(response.message, toastState.error);
        } else {
          fetchStageData();
          toast.success('Stage name updated successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error updating stage name:', error);
        toast.error('Failed to update stage name', toastState.error);
      }
    }
  };

  const handleStagechange = (field: string, value: string) => {
    setStageFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const hangleAddNewStage = async () => {
    const maxOrderNumber = crm.stageData.length > 0 ? crm.stageData.reduce((maxOrder: number, stage: { order: number }) => Math.max(maxOrder, stage.order), 0) : 0;
    const payload = {
      color_code: stageFormData.color_code,
      pipeline_id: queryPipelineID,
      stage_name: stageFormData.stage_name,
      order: maxOrderNumber + 1,
      brand_id: queryBrandId,
    };
    const response = await BaseApi.post(`/crm/pipeline/stage`, {
      stage_list: [payload],
    });
    if (response.status === 200) {
      fetchStageData();
      setAddNewStagePopup(false);
      toast.success(response.message, toastState.success);
    }
  };

  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num?.toString();
  };

  return (
    <div className={`mt-2.5 w-full cursor-default select-none overflow-x-auto rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white`}>
      <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
        <div className="flex gap-2.5">
          <div
            className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:!border-darkBorder md:w-[13rem] lg:w-[18rem]"
            onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer" />
            <input
              ref={searchRef}
              autoComplete="off"
              onChange={handleSearch}
              type="text"
              placeholder="Search"
              id="prospect-search-input"
              className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-white dark:placeholder:text-white "
            />
          </div>
        </div>
        <div className="flex gap-3">
          {(roleStatus?.is_owner || roleStatus?.add) && (
            <CustomButton
              customClass="dark:border-none"
              name="Add leads"
              id="add_leads_btn"
              LeftIcon={<IoMdAdd className="size-4 text-white dark:!border-none" />}
              onClick={() => setIsOpen(true)}
            />
          )}
          <button
            id="select-pipeline-button"
            className="relative flex !w-[9.5rem]  items-center 
        justify-center gap-1 !overflow-ellipsis
      !whitespace-nowrap rounded-md border border-[#4318FF]  bg-brand-500 bg-white   px-2.5 py-2 text-[0.7rem] !text-[#4318FF] hover:drop-shadow-md hover:filter"
            onClick={() => dispatch(setSelectPipelineModal(true))}
          >
            <span className="!w-[7rem] !overflow-hidden text-ellipsis whitespace-nowrap">{pipelineName ? pipelineName : 'Select Pipeline'}</span>
            <BiChevronDown size={18} color="#2B3674" />
          </button>

          {(roleStatus?.download_or_export || roleStatus?.edit || roleStatus?.is_owner) && pipeline_id && (
            <Menu>
              <MenuButton as={Button} className="pipeline-menu-btn !bg-white !px-2 !text-base !font-semibold dark:!bg-darkTwo ">
                <BsThreeDotsVertical id="pipeline-menu-btn" className="text-[20px] text-[#8F9BBA] dark:text-white" />
              </MenuButton>
              <MenuList
                minW="0"
                w={'122px'}
                className="!h-[73px] !w-[122px] !rounded-lg bg-white !p-0 shadow-lg dark:!border-darkBorder2 dark:!bg-darkThree "
                style={{ width: '120px', height: '73px' }}
              >
                {(roleStatus?.edit || roleStatus?.is_owner) && (
                  <MenuItem
                    id="pipeline-stage-edit-menu"
                    onClick={() => setOpenStageModal(true)}
                    className="pipeline-stage-edit-menu flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white dark:!bg-darkThree dark:!text-white dark:hover:!bg-radioPurple"
                  >
                    <MdModeEdit className="text-lg" />
                    <span>Edit</span>
                  </MenuItem>
                )}
                {(roleStatus?.download_or_export || roleStatus?.is_owner) && (
                  <MenuItem
                    onClick={() => handleExportData()}
                    className="flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white dark:!bg-darkThree dark:!text-white dark:hover:!bg-radioPurple"
                  >
                    <BiSolidCloudDownload className="text-lg" />
                    <span>Export</span>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </div>
      </div>
      <hr className="mb-3" />
      <div className={`${sideBarFullView ? 'w-[calc(100vw-15rem)]' : 'w-[calc(100vw-5rem)]'} overflow-auto`}>
        {isloading ? (
          <CrmSkeleton />
        ) : (
          <div>
            {crm?.stageData.length || queryPipelineID || pipeline_id ? (
              <div className="flex h-screen w-full gap-x-5  overflow-y-hidden whitespace-nowrap ">
                {crm?.stageData?.map((card: StageData, colId: number) => {
                  return (
                    <div
                      className={`flex min-w-[20rem] flex-col gap-3 border-t-[3px]`}
                      key={colId}
                      style={{
                        borderTop: `3px solid ${card.color_code}`,
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        dispatch(setNewOpportunity({ colId }));
                        dropAction(colId);
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.dataTransfer.dropEffect = 'move';
                      }}
                    >
                      <div className="flex items-center justify-between bg-white dark:bg-darkTwo">
                        <div className="flex items-center gap-1.5 pl-2.5">
                          <h5 className="relative rounded-full bg-[#4318FF0D] p-2.5 dark:bg-darkBg">
                            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.55rem] font-semibold">{formatNumber(card?.total_count)}</span>
                          </h5>
                          {/* Removing isEditing condition here */}
                          {/* {editingStageId === colId && */}
                          <input
                            ref={(element) => {
                              stageNameRef.current[colId] = element;
                            }}
                            onClick={() => handleEditStage(card, colId)}
                            autoComplete="off"
                            type="text"
                            value={editingStageId === colId ? stageName : card.stage_name}
                            // value={card.stage_name}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyChange}
                            onBlur={handleSave}
                            className={`text-md rounded-md p-1 font-bold text-[#101828] outline-none dark:bg-darkTwo  dark:text-white ${editingStageId === colId && 'rounded-none border border-x-0 border-b-2 border-t-0 dark:border-darkBorder2 dark:bg-darkThree dark:text-white'
                              }`}
                          />
                        </div>
                        <Menu>
                          <MenuButton as={Button} className="!bg-white !px-2 !text-base !font-semibold dark:!bg-darkTwo dark:text-white">
                            <BsThreeDotsVertical className="text-[20px] text-[#8F9BBA] dark:text-white" />
                          </MenuButton>
                          <MenuList
                            minW="0"
                            w={'122px'}
                            className="!h-[80px] !w-[122px] !rounded-lg bg-white  !p-0 shadow-lg dark:border-darkBorder2 dark:!bg-darkThree "
                            style={{ width: '122px', height: '90px' }}
                          >
                            <MenuItem
                              onClick={() => handleSortOpportunities(card.stage_name, card.uuid)}
                              className="flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF]  hover:!text-white  dark:!bg-darkThree  dark:!text-white dark:hover:!bg-radioPurple"
                            >
                              <BiSortUp className="text-lg" />
                              <span>Sort</span>
                            </MenuItem>

                            {(roleStatus?.delete || roleStatus?.is_owner) && (
                              <MenuItem
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setStageToDelete(card.uuid);
                                }}
                                className="flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF]  hover:!text-white  dark:!bg-darkThree dark:!text-white dark:hover:!bg-radioPurple"
                              >
                                <AiFillDelete className="text-lg" />
                                <span>Delete</span>
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </div>
                      <div
                        className=" min-h flex max-h-[32rem] flex-col justify-between  overflow-auto px-2 pb-2 pt-2"
                        style={{ background: card?.opportunityData?.length ? hexToRGBA(card.color_code, 0.2) : '' }}
                      >
                        <div className=" flex flex-col gap-2 ">
                          {card?.opportunityData?.map((message: OpportunityDataDto, rowId: number) => (
                            <Opportunity
                              opportunityData={message}
                              key={`${colId}-${rowId}`}
                              id={`${colId}-${rowId}`}
                              dropAction={dropAction}
                              pipeline_id={queryPipelineID}
                              AvatarColor={colors[getRandomIndex()]}
                            />
                          ))}
                        </div>
                        {!search && card?.total_count >= 25 && card?.opportunityData?.length !== card?.total_count && (
                          <InfiniteScrollComponent
                            customClassName="!p-0"
                            loadMore={(isFetching: any) => fetchMoreStageData(isFetching, card.uuid, card.stage_name)}
                            loader={<p className="!p-0 text-sm text-gray-300">loading.......</p>}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                {(roleStatus?.add || roleStatus?.is_owner) && (
                  <div className={`flex min-w-[20rem] flex-col gap-3 border-t-[3px] border-[#01B574] pb-20 pr-4 pt-1.5`}>
                    <div className="flex items-center justify-between  bg-white  dark:bg-darkTwo">
                      <div className="flex items-center gap-1.5 pl-2.5">
                        <h5 className="relative rounded-full bg-[#4318FF0D] p-2.5">
                          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.55rem] font-semibold">
                            <BsFillPlusCircleFill
                              color="#01B574"
                              size={16}
                              className="cursor-pointer"
                              onClick={() => {
                                setAddNewStagePopup(true);
                              }}
                            />
                          </span>
                        </h5>
                        {/* Removing isEditing condition here */}
                        {/* {editingStageId === colId && */}
                        <p className=" text-black font-bold">Add Stages</p>
                      </div>
                    </div>
                    {addNewStagePopup && (
                      <div className="ml-4 flex items-center gap-2 bg-white  px-4 py-7 shadow-lg dark:bg-darkTwo" ref={popupRef}>
                        <div className="relative" onClick={(event) => event.stopPropagation}>
                          <input
                            type="color"
                            name="color_code"
                            value={stageFormData.color_code}
                            onChange={(e) => handleStagechange('color_code', e.target.value)}
                            className="absolute inset-0 h-full w-full  cursor-pointer  opacity-0"
                          />
                          <div className="h-4 w-4 rounded-full" style={{ backgroundColor: `${stageFormData.color_code}` }} />
                        </div>
                        <div className="flex items-center">
                          <input
                            placeholder="stage name"
                            className="h-[2.5rem] !w-40 rounded-md rounded-e-none border border-[#D0D5DD] px-2 text-sm text-[#667085] outline-none dark:border-darkBorder2 dark:bg-darkThree dark:text-white"
                            onChange={(e) => handleStagechange('stage_name', e.target.value)}
                          />
                          <button
                            className="!border-[#D0D5DD ] h-[2.5rem] !w-12 rounded-md rounded-s-none border border-l-0 !p-0 text-sm font-medium text-[#4318FF] hover:bg-blueSecondary hover:text-white  dark:border-darkBorder2"
                            onClick={() => hangleAddNewStage()}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex h-[calc(100vh-11rem)] items-center justify-center text-base text-column ">
                <figure className="mt-10 flex flex-col items-center justify-center">
                  <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" />
                  <figcaption className="mt-3 font-bold text-heading">Please select the pipeline to view..</figcaption>
                </figure>
              </div>
            )}
          </div>
        )}
      </div>
      <SelectPipelinePopup />
      <DeleteConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setStageToDelete(null);
        }}
        handleDelete={handlestageDelete}
      />
      <UploadOpportunitiesPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <StageEdit isOpen={openStageModal} onClose={() => setOpenStageModal(false)} />
    </div>
  );
};

export default CRM;
