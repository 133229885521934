import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useRef } from 'react';
import { checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import { BaseApi } from 'common/api/common/base-api';
import { FiSearch } from 'react-icons/fi';
import Button from 'common/sparkle-common/Button';
import { MdAdd, MdEdit } from 'react-icons/md';
import CustomGroupTags from './feature/CustomGroupTags';
import { useAppDispatch, useAppSelector } from 'store';
import AddNewRoleModal from './feature/AddNewRoleModal';
import { TbTrash } from 'react-icons/tb';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { toast } from 'react-toastify';
import { resetTeamsRoleEditState, setTeamsRoleEditState } from 'store/organization';
import CustomFilter from 'components/customFilter';
import { roleManagementFilterDataList } from 'common/utils/page-filter';

const RoleManagementTable = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeColumn, setActiveColumn] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responseTable, setResponseTable]: any = React.useState({});
  const [addNewRole, setAddNewRole] = React.useState(false);
  const [data, setData] = React.useState({ data: [], count: 0, loading: false });
  const [roleStatus, setRoleStatus] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });
  const custom = useAppSelector((state) => state?.custom);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({
    selectedIds: [],
    globalOpt: false,
  });
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { teamsRoleEdit } = useAppSelector((state) => state.organization);

  const handleRoleDelete = async (rowuuid: string, rowDetails: any) => {
    try {
      const response = await BaseApi.delete('/role/management', {
        data: { uuid: [rowuuid] },
      });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        toast.success('Role Deleted Successfully', toastState.success);
        dispatch(setFlagStatus(!custom?.flagStatus));
      }
    } catch (error) {
      console.log('Role management list single delete error', error);
    }
  };
  const columnWidth = ['w-[5%]', 'w-[20%]', 'w-[50%]', 'w-[25%]', 'w-[5%]'];

  const [optionsData, setOptionsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptionsData(roleManagementFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
    });
    // const encryptedData = jwtEncrypt(filteredData);
    // setQueryParams((prev) => {
    //   if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
    //   return { ...prev, filter: encryptedData };
    // });
  };

  const handleEditRole = async (rowuuid: string, rowDetails: any) => {
    const editApiResponse = await BaseApi.get(`/role/management/${rowuuid}`);

    if (!editApiResponse?.error) {
      const existingData = editApiResponse?.data?.access_given;
      const newData = await BaseApi.get('/menu/list');
      const menuList = newData?.data?.data;

      function updateData(existingData: any, menuList: any) {
        // console.log(menuList);
        menuList.forEach((newItem: any) => {
          const existingItem = existingData.find(
            (item: any) => item.menu_type === newItem.menu_type && item.menu_name === newItem.menu_name && item.menu_list_id === newItem.menu_list_id
          );
          // console.log(existingItem);

          if (existingItem) {
            // Merge options
            const mergedOptions = [...existingItem.options];
            newItem.options.forEach((newOption: any) => {
              const existingOption = mergedOptions.find((option) => option.id === newOption.id && option.name === newOption.name && option.description === newOption.description);

              if (!existingOption) {
                mergedOptions.push(newOption); // Add new option if it doesn't exist
              }
            });

            // Update the existing item with merged options
            existingItem.options = mergedOptions;
          } else {
            // Add new item
            existingData.push(newItem);
          }
        });

        return existingData;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const updatedDatas = updateData(existingData, menuList);

      // const data = { ...editApiResponse?.data, access_given: [{ menu_name: 'Role Name' }, ...editApiResponse?.data?.access_given] };

      const data = editApiResponse?.data?.access_given?.some((item: any) => item.menu_name === 'Role Name')
        ? { ...editApiResponse?.data }
        : { ...editApiResponse?.data, access_given: [{ menu_name: 'Role Name' }, ...editApiResponse?.data?.access_given] };

      dispatch(setTeamsRoleEditState(data));
      setAddNewRole(true);
    }
  };

  const rowSettingOptions = [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: handleEditRole,
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      accessName: 'delete',
      name: 'Delete',
      functioning: handleRoleDelete,
    },
  ];

  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'role_management');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  React.useEffect(() => {
    const finalRowsettingsRole = roleStatus?.is_owner
      ? rowSettingOptions
      : rowSettingOptions.filter((item) => roleStatus?.hasOwnProperty(item?.accessName) && roleStatus?.hasOwnProperty(item.accessName));
    setOptions(finalRowsettingsRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleStatus]);

  const handleRoleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const columnHelper = createColumnHelper<any>();

  // console.log('DEbounce ', queryParams?.search);

  const fetchRoleListData = async () => {
    try {
      setLoading(true);
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
        {
          key: 'sort',
          value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
        },
        { key: 'filter', value: queryParams?.filter },
      ]);
      // console.log(query);
      const listResponse = await BaseApi.get(`/role/management/organization/list?${query}`);
      listResponse?.data?.data?.length ? setData({ ...data, data: listResponse?.data?.data, loading: false }) : setData({ ...data, data: [], loading: false });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRolemanagementData = async () => {
    const response = await BaseApi.get('/menu/list');
    // console.log('response', response?.data);
    setResponseTable(response?.data);
    setActiveColumn(response?.data?.data[0].menu_name);
  };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-3 flex  items-center">
          S.no
          {/* <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-5 cursor-pointer rounded-md accent-brand-500 dark:accent-purple-600"
          /> */}
        </div>
      ),
      cell: (info) => {
        // console.log('info?.row?.original', info?.row?.index);
        return (
          <div className="ml-4 flex items-center text-sm" onClick={(event) => event?.stopPropagation()}>
            {info?.row?.index + 1}
            {/* <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-5 cursor-pointer accent-brand-500 dark:accent-purple-600"
            /> */}
          </div>
        );
      },
    }),

    columnHelper.accessor('role', {
      id: 'role',
      enableSorting: false,
      header: () => <div className="mx-4  truncate  text-xs ">Role</div>,
      cell: (info) => <h5 className="mx-4  text-[12px] font-medium text-gray-700 dark:text-gray-500">{info?.row.original.role_name}</h5>,
    }),

    columnHelper.accessor('access_given', {
      id: 'access_given',
      header: () => <div className="mx-4 text-start text-xs ">Access Given</div>,
      cell: (info) => (
        <h5 className="mx-2 text-[10px] font-medium text-gray-700">
          <CustomGroupTags data={info?.row.original.access_given} />
        </h5>
      ),
    }),

    columnHelper.accessor('no_of_users', {
      id: 'no_of_users',
      header: () => <div className=" text-start text-[12px]">No Of Users</div>,
      cell: (info) => <h5 className=" text-[10px] font-medium text-gray-700 dark:text-gray-500">{info?.row.original.user_list_count || 0}</h5>,
    }),

    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className=" px-5 text-start text-[12px]">Actions</div>,
      cell: (row) => {
        return (
          <div className=" px-8 text-start">
            {<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={options} />}
            {/* {<RowSettings id={row.row.id} uuidRow={row?.row?.original?.uuid} rowData={row.row.original} table={table} setData={setData} />} */}
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    fetchRoleListData();
    // parseInt(SessionStorage.getString('Active_tab')) === 2 && fetchRoleListData();

    fetchRolemanagementData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sectionRefs = useRef(new Map());

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/role/management?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: prev?.data?.length,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div>
        <div className=" sticky top-[0px]  z-10 -mt-2 flex w-full items-center rounded-bl-lg rounded-br-lg bg-white px-2 py-4 shadow dark:!bg-darkOne sm:gap-32 lg:gap-32 xl:justify-between">
          <div className="flex items-center gap-2.5">
            <div
              className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              // onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-white" />
              <input
                type="text"
                placeholder="Search"
                id="donot-search-input"
                onChange={handleRoleSearch}
                className="w-full text-sm text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:!bg-darkOne dark:text-gray-300 dark:placeholder:text-white "
              />
            </div>
            <CustomFilter optionData={optionsData} handleFilter={handleFilter} id="campaign-filter-btn" />
          </div>
          {(roleStatus?.is_owner || roleStatus?.add) && (
            <Button
              name="Add New Role"
              customClass="text-xs !font-normal bg-blueSecondary add-role-btn dark:border-none active:scale-95 transition-transform duration-150 ease-out"
              LeftIcon={<MdAdd className="text-[16px]" />}
              // disable={openPopUP}
              onClick={() => {
                dispatch(resetTeamsRoleEditState());
                setAddNewRole(true);
              }}

              // onClick={() => setOpenPopUp(true)}
            />
          )}
        </div>

        <div className="my-3 h-[73vh] overflow-y-auto  scroll-smooth rounded-lg bg-white py-3 shadow dark:!bg-darkTwo">
          {!loading ? (
            <div className="scroll-smoothxl:overflow-x-hidden   min-h-[73vh] overflow-x-scroll">
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        const RowDataWidth = columnWidth;
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`${RowDataWidth[index]}  cursor-default select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[.625rem] font-semibold">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = columnWidth;
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                        No Matching Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </div>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={5}
              row={5}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50 ' }}
              columnWidth="4% 16% 60% 16% 4%"
            />
          )}
        </div>
      </div>
      {addNewRole && <AddNewRoleModal isOpen={addNewRole} onClose={() => setAddNewRole(false)} editData={teamsRoleEdit} />}
    </>
  );
};

export default RoleManagementTable;
