import React from 'react'
import Graph from './Graph'
import Cards from './Cards'
import RangePicker from './RangePicker'
import { useAppDispatch, useAppSelector } from 'store'
import { BaseApi } from 'common/api/common/base-api'
import { jwtEncrypt } from 'common/utils/utility'
import { setGraphData, setSelectedUser, setUser, setUserUsage } from 'store/account-settings'
import TableList from './TableList'

const Usage = () => {
  const usage = useAppSelector(state => state?.accountSettings?.billing?.usage);
  const workspace_id = useAppSelector(state => state?.auth?.loggedUser?.workspace_id);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const dispatch = useAppDispatch()

  const fetchUsageData = async (user_list: any[]) => {
    try {
      setPageLoading(true)
      const params = {
        user_id: user_list?.length ? user_list : usage?.user?.selected_user,
        start_date: usage?.selected_date?.start_date,
        end_date: usage?.selected_date?.end_date
      }
      const encrypytedData = jwtEncrypt(params)
      const response: any = await Promise.allSettled([
        BaseApi.get(`/billing/usage/user/history?filter_data=${encrypytedData}`),
        BaseApi.get(`/billing/usage/organization/history?filter_data=${encrypytedData}`),
      ])
      if (!response[0]?.value?.error) {
        const data = response[0]?.value?.data
        dispatch(setUserUsage({ list: data?.data, list_count: data?.totalCount?.count || 0, user_count: data?.usersCount?.count || 0 }))
      }
      if (!response[1]?.value?.error) {
        const data = response[1]?.value?.data
        dispatch(setGraphData(data?.data))
      }

    } catch (error) {
      console.log("usage fetch error", error)
    } finally {
      setPageLoading(false)
    }
  }

  React.useEffect(() => {
    const fetchUserList = async () => {

      try {
        const response = await BaseApi.get(`/workspace/${workspace_id}`);
        if (!response?.error) {
          const data = response?.data;
          await dispatch(setUser(data?.user_list))
          await data?.user_list?.forEach((element: any) => {
            dispatch(setSelectedUser(element?.uuid))
          })
          const user_list = await data?.user_list?.map((item: any) => item?.uuid)
          await fetchUsageData(user_list)
        }
      } catch (error) {
        console.log('User list error', error)
      } finally {
        setInitialLoading(false)
      }
    }
    fetchUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {

    !initialLoading && fetchUsageData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage?.selected_date?.start_date, usage?.selected_date?.end_date, usage?.user?.selected_user])

  return (
    <div className='flex flex-col'>
      <div className='mb-3'><RangePicker loading={pageLoading} initialLoading={initialLoading} /></div>
      <div className='mb-3'><Cards loading={pageLoading || initialLoading} /></div>
      <div className='mb-3'><Graph loading={pageLoading || initialLoading} /></div>
      <div className='mb-3'><TableList loading={pageLoading || initialLoading} /></div>
    </div>
  )
}

export default Usage