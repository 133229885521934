import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GlobalSettingsState {

  globalSetting?: any;
  updateSettingsData?: {
    updateState?: boolean;
    updateSettingData?: string;
  };
  environment: any
}

const initialState: GlobalSettingsState = {


  globalSetting: {},
  updateSettingsData: {
    updateState: false,
    updateSettingData: '',
  },
  environment: {}
};

const globalSettingsSlice = createSlice({
  name: 'global-settings',
  initialState,
  reducers: {

    setGlobalSetting: (state, action: PayloadAction<object>) => {
      state.globalSetting = { ...state?.globalSetting, ...action?.payload };
    },
    setUpdateSettingsData: (state, action: PayloadAction<any>) => {
      state.updateSettingsData = {
        updateState: !state?.updateSettingsData?.updateState,
        updateSettingData: action?.payload
      };
    },
    setEnvironment: (state, action: PayloadAction<object>) => {
      state.environment = action.payload;
    },
  },
});

export const { setGlobalSetting, setUpdateSettingsData, setEnvironment } = globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;
