/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'components/dropdown';
import { FiMoon, FiSearch, FiSun } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setAppSettings, setLoggedUserDetails } from 'store/authentication';
import { Avatar, Badge, SkeletonText, theme } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { ENsvg } from 'assets/img/campaign';
import Toggle from 'components/Toggle';
import { RxPerson } from 'react-icons/rx';
import { MdCampaign, MdLogout, MdMarkEmailRead } from 'react-icons/md';
import { BaseApi } from 'common/api/common/base-api';
import { setCredits } from '../../store/custom';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { Link } from 'react-router-dom';
import { IoPeopleOutline, IoReloadCircleOutline } from 'react-icons/io5';
import { BiShuffle } from 'react-icons/bi';
import { setModalView, setStepCount, setswitchOrganizationModalView } from 'store/organization';
import OrganizationAndWorkSpace from 'views/organization/feature/organization-creation';
import SwitchOrganization from 'views/organization';
import { CustomMenu } from 'components/customMenu';
import { setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import { toast } from 'react-toastify';
import { IoMdArrowBack } from 'react-icons/io';
import { LocalStorage } from 'common/api/common/storage';
import { FrSVG, InSVG, SpSVG, UsSVG } from 'assets/img/localizeCountry';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { AiOutlineBell } from 'react-icons/ai';
import Button from 'common/sparkle-common/Button';
import TabView from './features/globalSearch/TabView';
import { RiArrowDropDownLine } from 'react-icons/ri';
import BrandSearchData from './features/globalSearch/BrandSearchData';
import BlacklistSearchData from './features/globalSearch/BlackListSearchData';
import EmailVerifierSearchData from './features/globalSearch/EmailVerifierSearchData';
import SenderAccountSearchData from './features/globalSearch/SenderAccountSearchData';
import TrueVerifierSearchData from './features/globalSearch/TrueVerifierSearchData';
import CampaignSearchData from './features/globalSearch/CampaignSearchData';
import CampaignProspectSearchData from './features/globalSearch/CampaignProspectSearchData';
import CampaignSequenceSearchData from './features/globalSearch/CampaignSequenceSearchData';
import { homeSideBarData, settingsSideBarData } from 'common/dto/initial-value/views/sideBar';
import trueVerifier from 'store/true-verifier';

const Navbar = () => {
  const [langOptions, setLangOptions] = React.useState(false);
  const [percentage, setPercentage] = React.useState(0);
  const [queryParams, setQueryParams] = useState({ limit: 25, serachvalue: '' });

  // const [serachvalue, setSearchValue] = useState('');
  const [count, setCount] = useState({
    campaign: 0,
    brand: 0,
    trueVerifier: 0,
    emailVerifier: 0,
    senderAccounts: 0,
    blacklist: 0,
    menu: 16,
  });
  const debouncedSearchValue = useDebounce(queryParams?.serachvalue, 300);
  const [source, setSource] = useState('all');
  const [searchTrigger, setSearchTrigger] = React.useState(false);
  const [selectedLang, setSelectedLang] = React.useState({
    lang: 'EN',
    imgSrc: ENsvg,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchData, setSearchData] = useState(null);
  const [filteredMenu, setFilteredMenu] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const state = useAppSelector((state) => state?.campaignv2);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  // const userSettings = useAppSelector((state) => state?.auth?.userSettings);
  const [globalSearchView, setGlobalSearchView] = React.useState(false);
  const [globalSearchInitialView, setGlobalSearchInitialView] = React.useState(false);
  // console.log('loggedUser', loggedUser);
  const { organization_view } = useAppSelector((state) => state.organization);
  const { environment } = useAppSelector((state) => state.globalsettings);
  const custom = useAppSelector((state) => state?.custom);
  const notificationCount = useAppSelector((state) => state?.custom?.notificationCount);
  const dispatch = useAppDispatch();
  const searchRef = useRef(null);
  const location = useLocation();
  const isSettingsPath = location.pathname.startsWith('/settings');
  const handleLogout = () => {
    dispatch(setLoggedUserDetails());
    // dispatch(setUserSettings());
    LocalStorage.clearItem(LOGIN_STORAGE_VAR);
    window.location.reload();
  };

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  // Extract the query parameters
  const getqueryParams = getQueryParams(location.search);
  const opportunityId = getqueryParams.get('opportunityid');

  const isCrmPage = location.pathname.startsWith('/crm');
  const isCrmInbox = location.pathname.includes('/crm/inbox') && opportunityId;

  const toggleDarkMode = () => {
    const theme = LocalStorage.getItem('darkMode') === 'true' ? true : false;
    if (theme) {
      document.body.classList.remove('dark');
      dispatch(setAppSettings({ key: 'theme', value: 'light' }));
      LocalStorage.setItem('darkMode', 'false');
    } else {
      document.body.classList.add('dark');
      dispatch(setAppSettings({ key: 'theme', value: 'dark' }));
      LocalStorage.setItem('darkMode', 'true');
    }
  };

  const sparklesCount = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);

  const pageHeading = useAppSelector((state) => state?.custom?.headingTitle);
  const filteredHomeSideBarData = homeSideBarData?.filter(
    (item) =>
      !['deliverability', 'admin_tickets', 'admin_accounts', 'admin_settings', 'admin', 'coupon_settings', 'credits_settings', 'live', 'subscription_plan'].includes(item.key)
  );

  const filteredSettingsSideBarData = settingsSideBarData?.filter((item) => !['rewards', 'admin'].includes(item.key));
  const combinedSideBarData = [...filteredSettingsSideBarData, ...filteredHomeSideBarData];
  const languages = [
    { lang: 'EN', imgSrc: UsSVG },
    { lang: 'FR', imgSrc: FrSVG },
    { lang: 'ES', imgSrc: SpSVG },
    { lang: 'US', imgSrc: UsSVG },
    { lang: 'IN', imgSrc: InSVG },
  ];

  // let mini = false, hovered = true
  // const breadcrumbData = [
  //   { label: 'Home', href: '/' },
  //   { label: 'Projects', href: '/projects' },
  //   { label: 'Project Nero', href: '/projects/nero' }
  // ];
  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num?.toString();
  };
  React.useEffect(() => {
    if ((!loggedUser || !loggedUser?.organization_id || !loggedUser?.workspace_id) && environment?.app_default_feature?.is_org_enbaled) {
      dispatch(setModalView(true));
      dispatch(setStepCount(1));

      return;
    }
    const fetchCredits = async () => {
      try {
        const credits = await BaseApi.get(`subscription/payment/credits`);
        if (credits?.data?.totalSummary) {
          const percent = Math.floor((credits?.data?.totalSummary[0]?.totalAvailableCredits / credits?.data?.totalSummary[0]?.totalCredits) * 100);
          setPercentage(percent);
          dispatch(setCredits({ ...credits.data.totalSummary[0], hitCount: 0 }));
        }
      } catch (err) {
        console.log(err);
      }
    };
    // fetchCredits();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.credits?.hitCount]);

  useEffect(() => {
    const handleMenuSearch = () => {
      // If searchValue is empty, reset to original combined data
      if (!queryParams?.serachvalue) {
        setFilteredMenu(combinedSideBarData);
        setCount((prevCount) => ({
          ...prevCount,
          menu: 16,
        }));
      } else {
        const searchTerm = queryParams?.serachvalue?.toLowerCase();
        const filtered = combinedSideBarData?.filter((menuItem) => {
          return menuItem?.name?.toLowerCase()?.includes(searchTerm) || menuItem?.key?.toLowerCase()?.includes(searchTerm);
        });
        setCount((prevCount) => ({
          ...prevCount,
          menu: filtered?.length,
        }));

        setFilteredMenu(filtered);
      }
    };

    handleMenuSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, selectedTab]);

  useEffect(() => {
    const handleGlobalSearch = async () => {
      if (debouncedSearchValue) {
        try {
          setLoading(true);
          const sort = { _id: -1 };
          const query = setAndGetQueryParams([
            { key: 'search', value: queryParams.serachvalue },
            { key: 'limit', value: queryParams?.limit },
            { key: 'source', value: source },
          ]);

          const response = await BaseApi.get(`dashboard/global/search?${query}`);
          if (response?.error) {
            toast.error(response?.message);
            setLoading(false);
          } else {
            if (response?.data) {
              const campaignData = response?.data?.campaign_details?.data[0]?.total_records?.count;
              const brandData = response?.data?.brand_details?.data[0]?.total_records?.count;
              const senderData = response?.data?.sender_accounts_details?.data[0]?.total_records?.count;
              const emailVerifierData = response?.data?.email_verifier_details?.data[0]?.total_records?.count;
              const blacklistData = response?.data?.blacklist_details?.data[0]?.total_records?.count;
              const trueVerifierData = response?.data?.true_verifier_details?.data[0]?.total_records?.count;
              const sequenceData = response?.data?.campaign_sequence_details?.data[0]?.total_records?.count;
              const campaignCount = (campaignData ? campaignData : 0) + (sequenceData ? sequenceData : 0);

              setCount((prevCount) => ({
                ...prevCount,
                campaign: campaignCount ? campaignCount : 0,
                brand: brandData ? brandData : 0,
                trueVerifier: trueVerifierData ? trueVerifierData : 0,
                emailVerifier: emailVerifierData ? emailVerifierData : 0,
                senderAccounts: senderData ? senderData : 0,
                blacklist: blacklistData ? blacklistData : 0,
              }));
              setSearchData(response?.data);
              setLoading(false);
            }
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          toast.error('An error occurred while fetching data.');
        }
      } else {
        setSearchData([]);
        setCount((prevCount) => ({
          ...prevCount,
          campaign: 0,
          brand: 0,
          trueVerifier: 0,
          emailVerifier: 0,
          senderAccounts: 0,
          blacklist: 0,
        }));
      }
    };
    // handleGlobalSearch();
    if (selectedTab !== 7) {
      handleGlobalSearch();
    } else {
      setSearchData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, searchTrigger]);

  const handleTabChange = (tabIndex: number) => {
    // if (tabIndex === 1) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('campaigns');
    // } else if (tabIndex === 2) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('brand');
    // } else if (tabIndex === 4) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('email_verifier');
    // } else if (tabIndex === 3) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('true_verifier');
    // } else if (tabIndex === 5) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('sender_accounts');
    // } else if (tabIndex === 6) {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSource('blacklist');
    // } else {
    //   setQueryParams((prev) => ({
    //     ...prev,
    //     limit: 25,
    //   }));
    //   setSearchTrigger((prev) => !prev);
    //   setSource('all');
    // }
    setSelectedTab(tabIndex);
  };

  const handleGlobalSearch = async (e: any) => {
    // e.stopPropagation();
    if (e.keyCode === 32) {
      setGlobalSearchView(true);
    }
    setQueryParams((prev) => ({
      ...prev,
      serachvalue: e?.target?.value,
    }));
  };

  const changeLanguage = (idx: number) => {
    // Check the index and set the appropriate language in localStorage
    const languageCode = idx === 0 ? 'en' : idx === 1 ? 'fr' : 'es'; // If idx is 0, set English; otherwise, set Tamil
    // LocalStorage.setItem('lz_currentLanguage', languageCode);
    // console.log('languageCode', languageCode);
    LocalStorage.setString('lz_currentLanguage', languageCode);
    // window.location.reload();
  };

  const ProgressBar = (props: any) => {
    const { percent } = props;
    const numSegments = 5; // Total number of segments in the progress bar
    const segmentCoverage = 100 / numSegments; // Each segment covers this much percentage

    return (
      <div className="flex h-1 w-full rounded-full bg-gray-300">
        {[...Array(numSegments)].map((_, index) => {
          // Calculate if the current segment should be green
          const isActive = percent > index * segmentCoverage;
          return <div key={index} className={`h-full flex-1 ${isActive ? 'bg-[#05CD99]' : 'bg-gray-300'} ${index === numSegments - 1 ? 'none' : 'border-r-2 border-white'}`}></div>;
        })}
      </div>
    );
  };

  const handleClickSearchData = (url: string) => {
    navigate(url);
  };

  return (
    <nav className={`duration-175 relative flex flex-wrap items-center justify-between rounded-lg bg-white py-2.5 shadow-sm dark:bg-darkTwo`}>
      <div className="relative flex w-full items-center px-5 py-0.5 md:gap-3 lg:justify-between lg:gap-0">
        <div className="flex items-center gap-2">
          {pageHeading?.navigation?.title === 'Back' && ((isCrmPage && isCrmInbox) || (!isCrmPage && pageHeading?.navigation?.path)) && (
            <IoMdArrowBack id="page-back-btn" className="cursor-pointer dark:text-white" size={18} onClick={() => navigate(pageHeading?.navigation?.path)} />
          )}
          {pageHeading?.heading === 'campaign_name' ? (
            <h5 className="font-bold text-heading dark:text-white">{state?.campaign_name}</h5>
          ) : (
            <h5 className="font-bold text-heading dark:text-white">{pageHeading?.heading || ''}</h5>
          )}
        </div>

        {/* Profile & Dropdown */}
        <div className="flex items-center gap-5">
          {/* <div
            className="flex items-center gap-2 rounded-md border bg-customColor dark:border-darkBorder px-3
        py-1 outline outline-0 
        focus-within:outline-2 focus-within:outline-purple-900"
          >
            <FiSearch onClick={() => searchRef.current.focus()} className="size-4 cursor-pointer dark:text-white" />
            <input
              ref={searchRef}
              type="text"
              placeholder="Search"
              className="text-sm md:w-36 lg:w-48 lg:focus-within:w-96 transition-[width] duration-300
                             font-bold dark:bg-darkThree text-gray-800 dark:text-white caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:placeholder:text-white"
            />
          </div> */}
          {/* Code for the Global Search Data Custom Menu Below */}

          {environment?.site_ui_access_feature?.global_search ? (
            <CustomMenu
              open={globalSearchView}
              onOpen={() => {
                setGlobalSearchView(true);
                setGlobalSearchInitialView(true);
              }}
              onClose={() => {
                setGlobalSearchView(false);
                setGlobalSearchInitialView(false);
              }}
              menuListClassName="w-[28rem] h-96 overflow-y-auto"
              btnClassName="w-[28rem] "
              btnContentClassName="focus-within:outline-purple-900 !outline-none "
              btnContent={
                //   <div
                //     className="flex items-center gap-2 rounded-md border bg-customColor dark:border-darkBorder px-3
                // py-1 outline outline-0
                // focus-within:outline-2 "
                //   >  md:w-36 lg:w-48 border lg:focus-within:w-96 transition-[width] duration-300
                <div
                  className="flex items-center gap-2 "
                  onClick={(event) => {
                    globalSearchInitialView && event?.stopPropagation();
                  }}
                >
                  <FiSearch onClick={() => searchRef.current.focus()} className="size-4 cursor-pointer dark:text-white" />
                  <input
                    ref={searchRef}
                    onKeyUp={handleGlobalSearch}
                    type="text"
                    placeholder="Search"
                    className="text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-white dark:placeholder:text-white"
                  />
                </div>
              }
              inputValue={queryParams?.serachvalue}
            >
              <div>
                <div className="gap-2">
                  {/* {loading ? (
                  <>
                    <SkeletonText mt="4" noOfLines={15} spacing="4" skeletonHeight="2" />
                  </>
                ) : ( */}
                  <>
                    <div className={`mt-2.5  cursor-default select-none rounded-lg bg-white pb-2 pt-1 shadow-sm dark:bg-darkTwo dark:text-white `}>
                      <div className="relative mb-3 flex w-full flex-col items-start justify-start  py-2">
                        <div className="flex w-full items-center justify-between">
                          <div className="flex w-[26rem] overflow-x-auto">
                            <TabView onTabChange={handleTabChange} count={count} index={selectedTab} />
                          </div>
                        </div>
                        {/* <hr className="my-3 w-full" /> */}
                        <div className="mt-4 flex w-full items-center justify-between px-2">
                          {/* <div className="flex gap-3">
                          <Button
                            name="group"
                            customClass="bg-white  !text-column hover:bg-buttonBorder"
                            LeftIcon={<IoPeopleOutline color="#7F56D9" />}
                            RightIcon={<RiArrowDropDownLine />}
                          />
                          <Button
                            name="status"
                            customClass="bg-white  !text-column hover:bg-buttonBorder"
                            LeftIcon={<IoReloadCircleOutline color="#7F56D9" />}
                            RightIcon={<RiArrowDropDownLine />}
                          />
                        </div>
                        <div>
                          <p className="text-xs font-semibold text-heading ">5 Results</p>
                        </div> */}
                        </div>
                        {loading ? (
                          <>
                            <SkeletonText mt="4" noOfLines={12} spacing="4" skeletonHeight="2" width="100%" />
                          </>
                        ) : (
                          <div className="mt-3 w-full !rounded-md">
                            <div>
                              <div>
                                <div className="mb-3 flex items-center gap-2">
                                  <p className="inline-block text-sm font-semibold text-heading">MenuList</p>
                                  <hr className="inline-block flex-1" />
                                </div>
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                  {filteredMenu?.length > 0 ? (
                                    filteredMenu?.map((menu: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex cursor-pointer flex-col items-center justify-center rounded-lg border p-2 hover:bg-lightpurplebg"
                                          onClick={() => {
                                            handleClickSearchData(menu?.path);
                                          }}
                                        >
                                          <div className="text-column"> {menu?.icon}</div>
                                          <p className="ml-2  break-words text-center text-sm font-semibold text-column">{menu?.name}</p>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="col-span-1 my-4 flex w-full flex-col items-center justify-center md:col-span-3">
                                      <div className="flex flex-col items-center justify-center rounded-lg bg-lightpurplebg p-4 shadow-md">
                                        <p className="text-base font-semibold text-gray-700">No MenuList Found</p>
                                        {/* <p className="mt-2 text-center text-sm text-gray-500">Try refining your search or adjusting the filters.</p> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr className="mt-3 w-full" />
                            {searchData?.brand_details?.data?.length && (selectedTab === 0 || selectedTab === 2) ? (
                              <BrandSearchData data={searchData?.brand_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.blacklist_details?.data?.length && (selectedTab === 0 || selectedTab === 6) ? (
                              <BlacklistSearchData data={searchData?.blacklist_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.campaign_details?.data?.length && (selectedTab === 0 || selectedTab === 1) ? (
                              <CampaignSearchData data={searchData?.campaign_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.email_verifier_details?.data?.length && (selectedTab === 0 || selectedTab === 4) ? (
                              <EmailVerifierSearchData data={searchData?.email_verifier_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.sender_accounts_details?.data?.length && (selectedTab === 0 || selectedTab === 5) ? (
                              <SenderAccountSearchData data={searchData?.sender_accounts_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.true_verifier_details?.data?.length && (selectedTab === 0 || selectedTab === 3) ? (
                              <TrueVerifierSearchData data={searchData?.true_verifier_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.campaign_sequence_details?.data?.length && (selectedTab === 0 || selectedTab === 1) ? (
                              <CampaignSequenceSearchData data={searchData?.campaign_sequence_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                            {searchData?.campaign_prospects_details?.data?.length && (selectedTab === 0 || selectedTab === 1) ? (
                              <CampaignProspectSearchData data={searchData?.campaign_prospects_details?.data[0]?.data} handleNavigate={handleClickSearchData} />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  {/* ) */}
                  {/* } */}
                </div>
              </div>
            </CustomMenu>
          ) : null}
          <div className="group relative flex w-20 flex-col items-center justify-center gap-1" id="sparkle_count_component">
            <h5 className="text-xs font-semibold dark:text-white">
              {sparklesCount?.remaining_credits ? (
                <>
                  {formatNumber(sparklesCount?.active_credits) || 0}/{formatNumber(sparklesCount?.remaining_credits) || 0}
                </>
              ) : (
                '0 Sparkles'
              )}
            </h5>
            <ProgressBar percent={((sparklesCount?.active_credits / sparklesCount?.remaining_credits) * 100).toFixed(1)} />
            <ArrowedTooltip
              className={`hidden group-hover:block`}
              toolTipId={`nav_credits_tooltip`}
              data={`Available Sparkles: ${sparklesCount?.active_credits ?? 0} Total Sparkles: ${sparklesCount?.remaining_credits ?? 0}`}
            />
          </div>
          {/* <AiOutlineBell size={22} /> */}

          {environment?.site_ui_access_feature?.notification_bell ? (
            <div className="relative inline-block cursor-pointer " id="notification-bell-btn">
              <AiOutlineBell size={24} className="transition-transform duration-150 ease-out active:scale-95 dark:text-white  " onClick={() => navigate('/inbox/notification')} />
              {notificationCount > 0 && (
                <span
                  className={`absolute  ${
                    notificationCount?.toString()?.length > 1 ? `-right-1.5 -top-1.5 px-1 py-1` : ` -right-0.5 -top-0.5 px-1 py-0.5`
                  } flex items-center justify-center rounded-full bg-blueSecondary  text-[10px] font-semibold leading-none text-white`}
                >
                  {notificationCount}
                </span>
              )}
            </div>
          ) : null}
          {environment?.site_ui_access_feature?.dark_mode ? (
            <Toggle
              className="scale-75"
              changeFunction={toggleDarkMode}
              checkedCondition={LocalStorage.getItem('darkMode') === 'true' ? true : false}
              Icon1={FiSun}
              Icon2={FiMoon}
            />
          ) : null}

          {/* <div
            tabIndex={0}
            className="relative rounded-md border-2 px-3.5 py-1.5 focus:border-menuBorder dark:border-darkBorder"
            onClick={() => setLangOptions(true)}
            onBlur={() => setLangOptions(false)}
          >
            <div className="flex cursor-pointer items-center gap-1.5">
              <ReactSVG src={selectedLang.imgSrc} beforeInjection={(svg) => svg.setAttribute('style', 'height: 12.5px; width: 12.5px;')} />
              <h5 className="text-xs text-[#667085] dark:text-white">{selectedLang.lang}</h5>
            </div>
            {langOptions && (
              <div className="absolute left-0 top-10 z-10 rounded-md border bg-white shadow-md dark:border-darkBorder dark:bg-darkThree dark:text-white">
                {languages.map((language, idx) => (
                  <div
                    className="duration-400 flex cursor-pointer items-center gap-2 px-3.5 py-1.5 transition-all hover:bg-brand-500 hover:text-white"
                    key={language?.lang}
                    onClick={(e) => {
                      changeLanguage(idx);
                      e.stopPropagation();
                      setSelectedLang({
                        lang: language?.lang,
                        imgSrc: language?.imgSrc,
                      });
                      setLangOptions(false);
                    }}
                  >
                    <ReactSVG src={language.imgSrc} beforeInjection={(svg) => svg.setAttribute('style', 'height: 12.5px; width: 12.5px;')} />
                    <h5 className="text-xs">{language.lang}</h5>
                  </div>
                ))}
              </div>
            )}
          </div> */}
          <div className="z-20">
            <Dropdown
              id="avatar"
              button={
                <Avatar
                  cursor={'pointer'}
                  height={'32px'}
                  width={'32px'}
                  name={`${loggedUser?.first_name} ${loggedUser?.last_name}`}
                  bg="gray.200"
                  src={loggedUser?.picture}
                  className="navbar_avatar_logo"
                />
              }
              children={
                <div className="z-10 mt-3 flex h-max w-72 flex-col justify-start rounded-md bg-white bg-cover bg-no-repeat shadow-md shadow-gray-200 dark:bg-darkTwo dark:shadow-darkBorder">
                  <div className="mx-4 my-3 ">
                    <div className="flex items-center gap-2">
                      <Avatar name={`${loggedUser?.first_name} ${loggedUser?.last_name}`} bg="gray.200" src={loggedUser?.picture} className=" !h-9 !w-9" />
                      <div className="flex w-4/5 flex-col gap-0.5">
                        <p className="truncate  text-xs font-bold text-navy-700 dark:text-white">{`${loggedUser?.first_name} ${loggedUser?.last_name}`}</p>
                        {environment?.app_default_feature?.is_org_enbaled && (
                          <p className="truncate text-xs font-normal text-gray-600 dark:text-gray-300">{`${loggedUser?.organization_name} -  ${loggedUser?.workspace_name}`}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col border-t dark:border-t-darkBorder">
                    <Link
                      id="setting_btn"
                      to="/settings/personal/details"
                      className="account_settings_nav flex cursor-pointer items-center gap-2 p-2 px-4 text-xs text-gray-800 duration-300 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-blackDark"
                    >
                      <RxPerson size={15} />
                      <span>Account Settings</span>
                    </Link>
                    {environment?.app_default_feature?.is_org_enbaled && (
                      <button
                        className="flex cursor-pointer items-center gap-2 border-t p-2 px-4 text-xs text-gray-800 duration-300 hover:bg-gray-50 dark:border-t-darkBorder dark:text-gray-300 dark:hover:text-blackDark"
                        onClick={() => dispatch(setswitchOrganizationModalView(true))}
                        id="switch-organization"
                      >
                        <BiShuffle size={15} />
                        <span>Switch Organization</span>
                      </button>
                    )}
                    <div
                      className="flex cursor-pointer items-center gap-2 border-t p-2 px-4 text-xs text-gray-800 duration-300 hover:bg-gray-50 dark:border-t-darkBorder dark:text-gray-300 dark:hover:text-blackDark"
                      onClick={handleLogout}
                      id="logout"
                    >
                      <MdLogout size={15} />
                      <span>Log Out</span>
                    </div>

                    {/* <span className="cursor-pointer text-sm dark:text-white hover:dark:text-white" onClick={() => { navigate('/email/settings'); }}> Profile Settings </span>
              <span className="cursor-pointer mt-3 text-sm dark:text-white hover:dark:text-white"> Newsletter Settings </span>
              <span className="cursor-pointer mt-3 text-sm font-medium text-red-500 hover:text-red-500" onClick={() => handleLogout()}> Log Out </span> */}
                  </div>
                </div>
              }
              classNames={'py-2 top-8 -left-60 w-max'}
            />
          </div>
        </div>
      </div>

      {((!loggedUser?.organization_id && !loggedUser?.workspace_id) || organization_view) && <SwitchOrganization />}

      <OrganizationAndWorkSpace />
    </nav>
  );
};

export default Navbar;
