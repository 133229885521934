import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useAppDispatch } from 'store';
import { setUnMatchedEmails } from 'store/crm';
import LeftSideView from './LeftSideView';
import RightSideView from './RightSideView';
// import GlobalOpt from './GlobalOpt'

const UnmatchedEmailModel = () => {
  // const { view } = useAppSelector((state) => state?.crm?.unmatched_emails);
  const dispatch = useAppDispatch();
  return (
    <>
      <Modal isOpen={true} onClose={() => dispatch(setUnMatchedEmails({ view: false }))} size={'auto'}>
        <ModalOverlay />
        <ModalContent className="!w-[58rem]">
          <ModalBody className="!p-0">
            <div className="relative flex">
              <div className="w-[650px] border-r">
                <LeftSideView />
              </div>
              <div className="w-full">
                <RightSideView />
              </div>
              {/* <GlobalOpt /> */}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UnmatchedEmailModel;
