import { useState } from 'react';
import { IoMdArchive, IoMdMore, IoMdPlayCircle } from 'react-icons/io';
import { MdEdit, MdOutlineCloudDownload, MdPauseCircle } from 'react-icons/md';
import { saveAs } from 'file-saver';
import React from 'react';

import GlobalOpt from './GlobalOpt';
import { useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { _errorHandler, checkRolePermissionStatus, getCampaignStatus, toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';
import { clearStepCount } from 'store/custom';
import { clearCampaignV2 } from 'store/campaign-v2';
import { useDispatch } from 'react-redux';
interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  rowData?: any;
  fetchList?: () => void;
  fetchSingleList?: (uuid: string) => void;
}
interface csvTempData {
  campaign_name: string;
  cdate: string;
  created_by: string;
  campaign_status: string;
  campaign_progress_step_percent: string;
  campaign_delivery_message_data: string;
  campaign_statistics: { [key: string]: string },
}

const RowSettings = (props: RowData) => {
  const { status, uuidRow, rowData, id, fetchList, fetchSingleList } = props;
  const custom = useAppSelector((state) => state?.custom);
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();

  const changeCampaignStatus = async (selectedOpt: string, uuidSingle?: string) => {
    const uuidCampaign: { uuid: string[] | string, status: string } = {
      uuid: [],
      status: selectedOpt,
    };
    uuidCampaign.uuid = uuidSingle || custom?.tableRowsList.map((val: { [key: string]: string }) => val?.uuid);
    try {
      const response = await BaseApi.patch(`/campaign/v2/update/pause/resume/reject`, uuidCampaign);
      if (response?.error) {
        toast.error(_errorHandler(response?.message), toastState?.error);
      } else {
        fetchList?.();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleOptions = (selectedOpt: string, uuidSingle?: string) => {
    if (selectedOpt === 'edit') {
      dispatch(clearCampaignV2());
      dispatch(clearStepCount());
      fetchSingleList?.(uuidSingle)
    }
    if (selectedOpt === 'download') {
      downloadCsv(uuidSingle);
    }
    setShowOptions(false);
    if (selectedOpt === 'resume') {
      changeCampaignStatus('resume', uuidSingle);
    }
    if (selectedOpt === 'pause') {
      changeCampaignStatus('pause', uuidSingle);
    }
    if (selectedOpt === 'reject') {
      changeCampaignStatus('reject', uuidSingle);
    }
  };
  // Role Restrictions Enabling
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'campaigns');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapData = (downloadData: csvTempData[]) => {
    const csvData = downloadData.map((val) => {
      let {
        campaign_name: Campaign_Name,
        cdate: Created_On,
        campaign_status: Campaign_Status,
        campaign_statistics: { overall_campaign_sent_percentage: Progress },
        campaign_statistics: { total_prospects_count: Prospects },
        campaign_statistics: { sent_count: Emails_Sent },
        campaign_statistics: { sent_percentage: Emails_Sent_Percentage },
        campaign_statistics: { open_count: Opened },
        campaign_statistics: { open_percentage: Opened_Percentage },
        campaign_statistics: { replied_count: Replied },
        campaign_statistics: { replied_percentage: Replied_Percentage },
        campaign_statistics: { bounced_count: Bounced },
        campaign_statistics: { bounced_percentage: Bounced_Percentage },
        campaign_statistics: { unsubscribed_count: Unsubscribed },
        campaign_statistics: { unsubscribed_percentage: Unsubscribed_Percentage },
      } = val;
      Created_On = new Date(Created_On).toString()
      Progress += '%';
      Campaign_Status = getCampaignStatus(Number(Campaign_Status))
      Emails_Sent_Percentage += '%';
      Opened_Percentage += '%';
      Replied_Percentage += '%';
      Bounced_Percentage += '%';
      Unsubscribed_Percentage += '%';
      return {
        Campaign_Name,
        Created_On,
        Campaign_Status,
        Progress,
        Prospects,
        Emails_Sent,
        Emails_Sent_Percentage,
        Opened,
        Opened_Percentage,
        Replied,
        Replied_Percentage,
        Bounced,
        Bounced_Percentage,
        Unsubscribed,
        Unsubscribed_Percentage,
      };
    });
    return csvData;
  };

  const downloadCsv = (uuidSingle?: string) => {
    const downloadData = uuidSingle ? [rowData] : custom?.tableRowsList;
    const csvContent = convertArrayOfObjectsToCSV(mapData(downloadData));
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  const convertArrayOfObjectsToCSV = (array: { [key: string]: string }[]): string => {
    if (array.length === 0) {
      return '';
    }
    const headers = Object.keys(array[0]).join(',');
    const rows = array.map((obj) => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };
  console.log('id', `edit-action-${id}`)
  return (
    <>
      {status !== 5 && <div className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`} tabIndex={0} onBlur={() => setShowOptions(false)}>
        <IoMdMore className="z-0 text-lg text-gray-700 dark:text-white" id={`edit-btn-${id}`} onClick={(e) => { e.stopPropagation(); setShowOptions(true) }} />
        {showOptions && !custom?.globalOpt && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:bg-darkTwo dark:border-darkBorder">
            {/* Paused State */}
            {/* Role Restriction for Campaign Pause or Resume */}
            {(roleStatus?.is_owner || roleStatus?.pause_or_resume) && status === 1 && (
              <div
                className="campaign-pause-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white"
                onClick={() => handleOptions('pause', uuidRow)}
              >
                <MdPauseCircle className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Pause</h5>
              </div>
            )}
            {/* Active State */}
            {/* Role Restriction for Campaign Pause or Resume */}
            {(roleStatus?.is_owner || roleStatus?.pause_or_resume) && status === 3 && (
              <div
                className="campaign-resume-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white"
                onClick={() => handleOptions('resume', uuidRow)}
              >
                <IoMdPlayCircle className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Resume</h5>
              </div>
            )}
            {/* Role Restriction for Campaign Edit */}
            {(roleStatus?.is_owner || roleStatus?.edit) && status === 0 && (
              <div
                id = {`edit-action-${id}`}
                className="campaign-edit-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white"
                onClick={() => handleOptions('edit', uuidRow)}
              >
                <MdEdit className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Edit</h5>
              </div>
            )}

            {/* Role Restriction for Campaign Copy or Duplicate */}
            {/* Commented for local use */}
            {/* {(roleStatus?.is_owner || roleStatus?.copy_or_duplicate) && (
              <div
                className="campaign-duplicate-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white"
                onClick={() => handleOptions('', uuidRow)}
              >
                <IoIosCopy className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Duplicate</h5>
              </div>
            )} */}
            {/* Role Restriction for Campaign Archieve or Delete */}
            {(roleStatus?.is_owner || roleStatus?.archieve_or_delete) && (
              <div
                id="archive-btn"
                className={`campaign-archive-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white`}
                onClick={() => handleOptions('reject', uuidRow)}
              >
                <IoMdArchive className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Archive</h5>
              </div>
            )}
            {/* Role Restriction for Campaign Export or DownLoad */}
            {(roleStatus?.is_owner || roleStatus?.export) && (
              <div
                className="campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 dark:hover:bg-darkFour hover:text-white"
                onClick={() => handleOptions('download', uuidRow)}
              >
                <MdOutlineCloudDownload className="text-brand-500 dark:text-purple-100 group-hover:text-white" />
                <h5 className="text-xs">Download</h5>
              </div>
            )}
          </div>
        )}
      </div>}
      {custom?.globalOpt && <GlobalOpt handleOptions={handleOptions} />}
    </>
  );
};

export default RowSettings;
