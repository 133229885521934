import { Avatar, Skeleton, Spinner } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { BaseApi } from 'common/api/common/base-api';
import useClickOutsideHook from 'common/hooks/useClickOutsideHook';
import { jwtDecrypt, setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import { useAppSelector } from 'store';
import { addGroupList, addSenderList, setGroupList, setLoadGroupsData, setSelectedGroupList, setSelectedSenderList, setSenderList, setSettingsData } from 'store/campaign-v2';
import Button from 'common/sparkle-common/Button';
import InfiniteScrollComponent from 'components/infinityScroll';

type GroupsProps = {
  type?: 'email' | 'text' | 'whatsapp' | 'linkedIn' | 'twitter';
  onClose?: (val: 'email' | 'text' | 'whatsapp' | 'linkedIn' | 'twitter') => void;
};

const Groups = (props: GroupsProps) => {
  const { type, onClose } = props;
  const state = useAppSelector((state) => state?.campaignv2);
  const { selectedSenderList, selectedGroupList } = state.settings;
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const [emailSection, setEmailSection] = useState(true);
  const menuRef = useClickOutsideHook(() => onClose(type));
  const [selectAll, setSelectAll] = useState(false);
  console.log(selectAll);
  const [emailQueryParams, setEmailQueryParams] = useState({ limit: 25, offset: 2 })
  const [groupQueryParams, setGroupQueryParams] = useState({ limit: 25, offset: 2 })
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState({ emailList: 0, groupList: 0 });
  const debouncedSearch = useDebounce(search, 300);
  const initialMount = useRef(false);

  const getSendersList = async (searchKey: string = '', list?: Record<string, boolean>) => {
    try {
      setLoading(true);
      const response = await BaseApi.get(`/sender-accounts/email/connected/list?search=${searchKey}`);
      const { data, total_records } = jwtDecrypt(response?.data);
      if (data) {
        setTotalCount((prev) => ({ ...prev, emailList: total_records?.count }))
        // data.forEach((item) => item.selected = false)
        dispatch(setSenderList(data));
        dispatch(setSettingsData({
          selectedSenderList: Object.keys(list)?.length ? list : data.reduce((acc: { [key: string]: boolean }, item: { [key: string]: string }) => {
            acc[item.uuid] = selectedSenderList[item.uuid] || false;
            return acc;
          }, {})
        }));
      } else {
        dispatch(setSenderList([])
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getGroupsList = async (searchKey: string = '', list?: Record<string, boolean>) => {
    try {
      const response = await BaseApi.get(`/groups/linked/with/email?search=${searchKey}`);
      const { data, total_records } = response?.data;
      if (data) {
        setTotalCount((prev) => ({ ...prev, groupList: total_records?.count }))
        dispatch(setGroupList(data));
        dispatch(setSettingsData({
          selectedGroupList: Object.keys(list)?.length ? list : data.reduce((acc: { [key: string]: boolean }, item: { [key: string]: string }) => {
            acc[item.uuid] = selectedGroupList[item.uuid] || false;
            return acc;
          }, {})
        }))
      } else {
        dispatch(setGroupList([]));
      }
    } catch (err) {
      console.log(err);
    }
  };

  let isCheckedAllList = emailSection
    ? state?.senderList?.length > 0 && Object.values(selectedSenderList).every((item: any) => item)
    : state?.groupList?.length > 0 && Object.values(selectedGroupList).every((item: any) => item);

  useEffect(() => {
    if (!state?.loadGroups[type]) return;
    getSendersList('', selectedSenderList);
    getGroupsList('', selectedGroupList);
    return () => {
      dispatch(setLoadGroupsData(type));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialMount.current) {
      initialMount.current = true;
      return;
    }
    emailSection ? getSendersList(search) : getGroupsList(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // useEffect(
  //     () => {
  //         if (state?.senderList.length) {
  //             const condition = state?.senderList?.every(item => item?.selected === true)
  //             condition ? setSelectedStatus(true) : setSelectedStatus(false)
  //         }
  //     }, [state?.senderList, state?.groupList]
  // )

  // const selectAll = () => {
  //     dispatch(selectAllSenders(!selectedStatus))
  //     setSelectedStatus(!selectedStatus)
  // }

  const handleSelectGroupList = (name: string) => {
    // const { name, checked } = event?.target;
    dispatch(setSelectedGroupList({ [name]: !selectedGroupList[name] }));
  };

  const handleSelectSenderList = (name: string) => {
    // const { name, checked } = event?.target;
    dispatch(setSelectedSenderList({ [name]: !selectedSenderList[name] }));
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (emailSection) {
      if (state?.senderList?.length > 0 && Object.values(selectedSenderList).every((item) => item)) {
        setSelectAll(true)
      }
      else {
        setSelectAll(false)
      }
      const allSelectedsenderList = Object.keys(selectedSenderList).reduce((acc: { [key: string]: boolean }, item: string) => {
        acc[item] = checked;
        return acc;
      }, {});
      dispatch(setSettingsData({ selectedSenderList: allSelectedsenderList }));
    } else {
      if (state?.groupList?.length > 0 && Object.values(selectedGroupList).every((item) => item)) {
        setSelectAll(true)
      }
      else {
        setSelectAll(false)
      }
      const allSelectedGroupList = Object.keys(selectedGroupList).reduce((acc: { [key: string]: boolean }, item: string) => {
        acc[item] = checked;
        return acc;
      }, {});
      dispatch(setSettingsData({ selectedGroupList: allSelectedGroupList }));
    }
  };

  // const handleSelectAll = () => {
  //   if (emailSection) {

  //   }
  //   else {

  //   }
  // }


  const senderEmailFetch = async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
    const fetchEmailData = async () => {
      try {
        setIsFetching(true);
        const query = setAndGetQueryParams([
          { key: 'limit', value: emailQueryParams?.limit },
          { key: 'offset', value: emailQueryParams?.offset }
        ]);
        const response = await BaseApi.get(`/sender-accounts/email/connected/list?${query}`);
        const { data } = jwtDecrypt(response?.data);
        if (data) {
          dispatch(addSenderList(data));
          dispatch(setSelectedSenderList(data.reduce((acc: { [key: string]: boolean }, item: { [key: string]: string }) => {
            acc[item.uuid] = selectedSenderList[item.uuid] || false;
            return acc;
          }, {})))
        }
        setEmailQueryParams((prev) => ({
          ...prev,
          offset: prev?.offset + 1,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    (state?.senderList?.length < totalCount?.emailList) && fetchEmailData();
  }

  const groupsFetch = async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
    const groupData = async () => {
      try {
        setIsFetching(true);
        const query = setAndGetQueryParams([
          { key: 'limit', value: groupQueryParams?.limit },
          { key: 'offset', value: groupQueryParams?.offset }
        ]);
        const response = await BaseApi.get(`/groups/linked/with/email?${query}`);
        const data = response?.data?.data
        if (data) {
          dispatch(addGroupList(data));
          dispatch(setSelectedGroupList(data.reduce((acc: { [key: string]: boolean }, item: { [key: string]: string }) => {
            acc[item.uuid] = selectedGroupList[item.uuid] || false;
            return acc;
          }, {})
          ))
          setGroupQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        }
      }
      catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    (state?.groupList?.length < totalCount?.groupList) && groupData();
  }

  return (
    <div
      ref={menuRef}
      className="noscroll absolute left-0 top-[2.6rem] z-10 max-h-[19.5rem] w-[25rem] rounded-md border border-blueSecondary bg-white"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center justify-between p-3">
        <input type="checkbox" id="select-all-btn" checked={isCheckedAllList} onChange={handleSelectAll} />
        <div className="flex w-[15.8rem] items-center gap-1 rounded-md border border-[#D0D5DD] px-2 py-1.5">
          <FiSearch className="size-3 text-column" />
          <input
            type="text"
            placeholder="Search"
            className="w-full appearance-none text-[0.6rem] text-heading outline-none placeholder:text-heading"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </div>
        <Button
          customClass="w-24 hover:shadow-none"
          px='px-1.5'
          py='py-1.5'
          fontSize='[0.6rem]'
          onClick={() => {
            setEmailSection(!emailSection)
          }}
          name={emailSection ? 'Switch to Groups' : 'Switch to Emails'}
        />
      </div>
      <div className="flex min-h-[5rem] max-h-[16rem] flex-col overflow-y-auto">
        {state?.senderList?.length === 0 && loading && (
          <Spinner className="absolute left-[47%] top-[47%] -translate-x-1/2 -translate-y-1/2" thickness="3px" speed="0.65s" color="gray.400" size="md" />
        )}
        {emailSection
          ? state?.senderList?.length > 0 &&
          <>
            {state?.senderList?.map((item: any, id: number) => {
              return (
                <div key={id} className="flex w-full items-center justify-between cursor-pointer hover:bg-hover px-3 py-2" onClick={() => handleSelectSenderList(item?.uuid)}>
                  <div className="flex items-center gap-1" id={`selected-email-${id}`} aria-valuetext={item.uuid}>
                    <input type="checkbox" name={item.uuid} checked={selectedSenderList[item.uuid]}
                      id={`sender-select-${id}`} readOnly />
                    <Avatar src="" color="white" name={item?.name} bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                    <h5 className="w-48 truncate text-[0.7rem] text-column">{item?.account_details?.email}</h5>
                  </div>
                  <div className="flex items-center">
                    {item?.groups_list.length ? (
                      <h5 className="rounded-full border border-blueSecondary bg-white px-1.5 py-1 text-[0.5rem] text-blueSecondary">{item?.groups_list[0]?.name}</h5>
                    ) : null}
                  </div>
                </div>
              );
            })}
            <InfiniteScrollComponent
              loadMore={senderEmailFetch}
              loader={
                <div className='flex gap-2 mx-1'>
                  <Skeleton height={'10px'} className='w-3.5' />
                  <Skeleton height={'10px'} className='w-full' />
                </div>
              }
            />
          </>

          : state?.groupList?.length > 0 &&
          <>
            {state?.groupList?.map((item, id: number) => {
              return (
                <div key={id} className="flex w-full items-center justify-between gap-1 cursor-pointer px-3 py-2 hover:bg-hover" onClick={() => handleSelectGroupList(item?.uuid)}>
                  <div className="flex items-center gap-1">
                    <input type="checkbox" name={item.uuid} checked={selectedGroupList[item.uuid]} readOnly />
                    {/* <button className='text-[#5D5FEF] bg-white px-2.5 py-0.5 w-min max-w-28 truncate rounded-full border border-blueSecondary text-[0.6rem] font-medium transition-all  '>{item?.name}</button> */}
                    <h5 className="w-48 truncate text-[0.7rem] text-blueSecondary">{item?.name}</h5>
                  </div>
                  <div className="flex items-center">
                    {/* <h5 className="w-32 truncate text-[0.7rem] text-column">{item?.variant_list?.length ? item?.variant_list[0][type] : ''}</h5>
                    {item?.variant_list?.length - 1 > 0 ? (
                      <h5 className="rounded-full bg-[#F2F4F7] px-1 py-0.5 text-[0.5rem] font-medium text-[#344054]">+{item?.variant_list?.length - 1}</h5>
                    ) : null} */}
                  </div>
                </div>
              );
            }
            )}
            <InfiniteScrollComponent
              loadMore={groupsFetch}
              loader={
                <div className='flex gap-2 mx-1'>
                  <Skeleton height={'10px'} className='w-3.5' />
                  <Skeleton height={'10px'} className='w-full' />
                </div>
              }
            />
          </>
        }
      </div>
    </div>
  );
};

export default Groups;
