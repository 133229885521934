/* eslint-disable react-hooks/exhaustive-deps */
import { FiSearch } from 'react-icons/fi';
import TabView from '../TabView';
// import CustomFilter from 'components/customFilter';
import { BiCheckDouble } from 'react-icons/bi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setFilterQuery, setinboxStatus, setSearchQuery, setVariant } from 'store/unified-inbox';
import { useEffect, useState } from 'react';
import CustomFilter from 'components/customFilter';
import { jwtEncrypt } from 'common/utils/utility';
import { InboxFilterDataList } from 'common/utils/page-filter';

const CommonLayout = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sideBarFullView } = useAppSelector((state) => state.custom);
  const [optionData, setOptionData] = useState<any[]>([]);
  const { variant, inbox_status } = useAppSelector((state) => state.unifiedInboxData);

  useEffect(() => {
    setOptionData(InboxFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // This function runs on component unmount
      dispatch(setFilterQuery(''));
      dispatch(setSearchQuery(''));
    };
  }, []);

  const handleVariantClick = (variant: string, path: string) => {
    navigate(path);
    dispatch(setVariant(variant));
    dispatch(setinboxStatus('pending'));
    dispatch(setFilterQuery(''));
  };

  const handleTabSelect = (status: string) => {
    dispatch(setinboxStatus(status));
  };

  const { children, handleClearConversation } = props;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event?.target?.value;
    dispatch(setSearchQuery(query));
  };

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    if (filteredData?.data?.length) {
      dispatch(setFilterQuery(encryptedData));
    } else {
      dispatch(setFilterQuery(''));
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes('/inbox/notification')) {
      dispatch(setVariant('Notification'));
    } else if (location?.pathname?.includes('/inbox/email')) {
      dispatch(setVariant('Email'));
    } else if (location?.pathname?.includes('/inbox')) {
      dispatch(setVariant('All'));
    }
  }, []);
  return (
    <div>
      <div
        className={`mt-2.5 ${
          sideBarFullView ? 'w-[calc(100vw-15rem)]' : 'w-[calc(100vw-6.5rem)]'
        } cursor-default select-none rounded-lg bg-white pb-2 pt-1 shadow-sm dark:bg-darkTwo dark:text-white`}
      >
        <div className="relative mb-3 flex w-full flex-col items-start justify-start px-3 py-2">
          <div className="flex w-full items-center justify-between">
            <div className="flex ">
              {/* <div className='border border-red-500 w-32'> */}

              <Menu >
                <MenuButton name="Match" className={`inbox-menu-channel flex !min-w-[5rem] !px-2 `}>
                  <div className="flex items-center justify-center gap-2 rounded-xl border px-3 py-1 text-xs text-column">
                    {variant ? variant : 'Select'} <MdKeyboardArrowDown onClick={() => navigate('/inbox')} className="text-xl" />
                  </div>
                </MenuButton>

                <MenuList minW="0" className="!h-[115px] !w-[10rem] !rounded-lg  bg-white !p-0 shadow-lg  ">
                  <MenuItem
                    onClick={() => handleVariantClick('All', '/inbox')}
                    className="flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-heading hover:rounded-t-lg hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>All</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleVariantClick('Email', '/inbox/email')}
                    className="inbox-email-menu flex items-center   space-x-2 !py-2 !pe-2 !ps-4 text-sm  !text-heading hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>Email</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleVariantClick('Notification', '/inbox/notification')}
                    className="inbox-email-menu flex items-center  space-x-2 !py-2 !pe-2 !ps-4 text-sm  !text-heading hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>Notification</span>
                  </MenuItem>
                  {/* <MenuItem          //commented need for development
                    // onClick={() => handleVariantClick('Task', '/inbox/task')}
                    className="flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-heading hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>Tasks</span>
                  </MenuItem>
                  <MenuItem
                    // onClick={() => handleVariantClick('Task', '/inbox/task')}
                    className="flex items-center space-x-2 rounded-b-lg !py-2  !pe-2 !ps-4 text-sm !text-heading hover:rounded-b-lg hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>Whatsapp</span>
                  </MenuItem> */}
                </MenuList>
              </Menu>
              {/* </div> */}
              <TabView handleTabSelect={handleTabSelect} />
            </div>
            <div className="mr-4  flex gap-3 ">
              <div
                className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[18rem]"
                // onClick={() => searchRef.current.focus()}
              >
                <FiSearch className="size-4 cursor-pointer" />
                <input
                  // ref={searchRef}
                  autoComplete="off"
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Search"
                  id="campaign-search-input"
                  className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
                />
              </div>
              <CustomFilter position="left" optionData={optionData} handleFilter={handleFilter} />

              {inbox_status !== 'cleared' ? (
                <div className="flex cursor-pointer items-center justify-start gap-2  text-sm" onClick={() => handleClearConversation()}>
                  <BiCheckDouble />
                  Clear all
                </div>
              ) : null}
            </div>
          </div>
          <div className=" mt-8 w-full !rounded-md">{children}</div>
        </div>
      </div>
      {/* <ClearEmailAlertPopup isOpen={true} /> */}
    </div>
  );
};

export default CommonLayout;
