import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter } from '@chakra-ui/modal';
import TagsField from 'components/fields/TagsField';
import Uploader from 'components/uploader';
import React from 'react';
import { MdAttachEmail } from 'react-icons/md';
import { BiSolidMessageCheck } from 'react-icons/bi';
import Button from 'common/sparkle-common/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { setTicketDrawerDetails, setTicketId, setToggleDrawer } from 'store/true-verifier';
import { ticketDrawerDetailsType } from 'common/dto/types/views/true-verfier';
import { BaseApi } from 'common/api/common/base-api';
// import { GiTwoCoins } from 'react-icons/gi';
import { AiFillWarning } from 'react-icons/ai';
import PaymentModal from 'views/email-verify/features/PaymentModal';
import { getErrorMessage, setCredits } from 'store/custom';
import InputField from 'components/fields/InputField';

const TicketCreationComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [showPaymentWarning, setShowPaymentWarning] = React.useState(false);
  const [showPayment, setShowPayment] = React.useState(false);
  const { ticketDrawerToggle, ticketDrawerDetails } = useAppSelector((state) => state?.trueVerifier);
  const dispatch = useAppDispatch();
  const custom: any = useAppSelector((state) => state?.custom);

  // HandleChange function
  const handleChange = (name: string, value: string | { id: string; email: string }[]) => {
    const drawerDetails: ticketDrawerDetailsType = { ...ticketDrawerDetails };
    drawerDetails[name] = value as string | { id: string; email: string }[];
    dispatch(setTicketDrawerDetails(drawerDetails));
    // const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)
    // let message = value.length ? '' : `${capitalizedName} is required`
    // if (!message) {
    //   if (name === 'subject') {
    //     message = value?.length >= 5 ? '' : 'Subject string limit should be between 5 to 50'
    //   }else if(name === 'message'){
    //     message = value?.length >= 5 ? '' : 'Message string limit should be between 5 to 500'
    //   }
    // }
    // dispatch(getErrorMessage({ ...custom?.errorMessage, [name]: message }));
  };

  // Close drawer
  const handleCloseDrawer = () => {
    // const data: ticketDrawerDetailsType = {
    //   uuid: '',
    //   subject: '',
    //   message: '',
    //   emails: [],
    // };
    // dispatch(setTicketDrawerDetails(data));
    dispatch(setTicketId(''));
    dispatch(setToggleDrawer());
    setShowPaymentWarning(false);
  };

  // Send message
  const handleSendMessage = async () => {
    try {
      setLoading(true);
      const params: any = {
        uuid: ticketDrawerDetails?.uuid,
        subject: ticketDrawerDetails?.subject,
        message: ticketDrawerDetails?.message,
        verify_data_list: ticketDrawerDetails?.emails,
      };
      let response;
      if (params.uuid) {
        response = await BaseApi.patch('/true/verify/update/ticket', params);
      } else {
        response = await BaseApi.post('/true/verify/create/new/ticket', params);
      }
      console.log('response', response);

      if (response?.status === 200 && response?.message === 'credits_not_found') {
        setShowPaymentWarning(true);
        return;
      }
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        handleCloseDrawer();
        dispatch(
          setCredits({
            totalAvailableCredits: custom.credits.totalAvailableCredits,
            totalCredits: custom.credits.totalCredits,
            hitCount: 1,
          })
        );
      }
    } catch (error) {
      console.log('Send message error', error);
    } finally {
      setLoading(false);
    }
  };

  const buttonDisable = ticketDrawerDetails?.subject && ticketDrawerDetails?.message && ticketDrawerDetails?.emails?.length ? loading : true;
  return (
    <Drawer isOpen={ticketDrawerToggle} placement="right" onClose={handleCloseDrawer} size={'md'}>
      <DrawerOverlay />
      <DrawerContent className="">
        {/* Header */}
        <DrawerHeader className=" border-b !p-0">
          {showPaymentWarning && (
            <div
              className="flex items-center justify-between border border-b-[#FEC84B] p-5"
              style={{
                background: 'linear-gradient(90.31deg, #FFFFFF 0%, #FEF0C7 177.61%)',
              }}
            >
              <div>
                <p className="flex gap-3 text-sm text-heading">
                  <span className="text-lg text-[#F79009]">
                    {' '}
                    <AiFillWarning />
                  </span>
                  Your true verifier credits has been exhausted
                </p>
              </div>
              <div>
                <button
                  className="flex items-center gap-1 rounded bg-[#F79009] p-2 text-sm font-thin text-white"
                  id="refill"
                  onClick={() => {
                    setShowPayment(true);
                  }}
                >
                  {' '}
                  <span className="text-lg">{/* <GiTwoCoins /> */}</span>
                  Refill credits
                </button>
              </div>
            </div>
          )}
          <div className="flex items-center gap-3 p-4">
            <MdAttachEmail className="text-column" />
            <h4 className="font-bold text-heading dark:text-white">New ticket</h4>
          </div>
        </DrawerHeader>
        {/* Body */}
        <DrawerBody className="!px-2 !py-2">
          {/* subject */}
          <div className="w-full px-2">
            <h3 className="text-[.875rem] font-bold leading-6 text-heading dark:text-white">Subject</h3>
            <InputField
              value={ticketDrawerDetails?.subject}
              onChange={(event: any) => handleChange('subject', event?.target?.value)}
              type="text"
              extra={`mt-2  h-9 w-full rounded-lg bg-white/0 p-3 text-sm outline-none !p-0 `}
              inputClass="!p-2 !h-fit !mt-0 !rounded-md"
              placeholder="Subject"
              id="subject-input"
              label={''}
              errorMessage={custom?.errorMessage?.subject}
              state={custom?.errorMessage?.subject ? 'error' : ''}
            />
          </div>

          {/* Emails  */}
          <div className="mt-6 px-2 outline-none dark:!border-white/10 sm:w-full md:w-full">
            <div className="col-span-1 row-span-2  h-full">
              <TagsField
                inputId="email-input"
                id="description"
                onChange={(item: any) => {
                  const emails = item.map((email: { id: number; name: string }) => {
                    return { id: email?.id?.toString(), email: email?.name };
                  });
                  handleChange('emails', emails);
                }}
                placeholderTags={ticketDrawerDetails?.emails?.map((email, index) => {
                  return { id: index, name: email?.email };
                })}
                placeholder="Enter emails"
                pattern={
                  // eslint-disable-next-line no-useless-escape
                  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                }
                className="h-stretch relative flex min-h-10 flex-row flex-wrap rounded-lg border border-gray-200 bg-none p-1 pb-20 focus:border-brand-200 dark:!border-white/10"
              />
              <Uploader
                dependencies={[ticketDrawerDetails]}
                onUpload={(uploadedData) => {
                  const newEmails = uploadedData?.filter((item: any) => item.email);
                  handleChange('emails', [...ticketDrawerDetails?.emails, ...newEmails]);
                }}
              />
            </div>
          </div>

          {/* Message box */}
          <div className="mb-2 mt-4 w-full px-2">
            <h3 className="text-[14px] font-bold leading-6 text-heading dark:text-white">Message</h3>
            {/* <InputField
            
            /> */}
            <textarea
              id="message-input"
              className={`mt-2 flex w-full resize-none items-center justify-center rounded-lg border bg-white/0 p-3 text-sm outline-none ${
                custom?.errorMessage?.message ? '!border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400' : ''
              }`}
              placeholder="Write your message here"
              rows={6}
              value={ticketDrawerDetails?.message}
              onChange={(event) => handleChange('message', event?.target?.value)}
            />
            {custom?.errorMessage?.message && (
              <span className="ml-1 mt-1 flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{custom?.errorMessage?.message}</span>
            )}
          </div>
        </DrawerBody>
        {/* Footer */}
        <DrawerFooter className="flex gap-3">
          <Button name="Back" id="back-btn" customClass="w-full !bg-white !text-gray-800 !font-bold duration-300 !text-[.75rem" onClick={handleCloseDrawer} />
          <Button
            id="send-btn"
            name="Send message"
            LeftIcon={<BiSolidMessageCheck size={14} />}
            customClass={`w-full duration-300 !text-[.75rem] hover:bg-purple-700`}
            onClick={handleSendMessage}
            loading={loading}
            disable={buttonDisable}
          />
        </DrawerFooter>
      </DrawerContent>

      <PaymentModal isOpen={showPayment} onClose={() => setShowPayment(false)} />
    </Drawer>
  );
};

export default TicketCreationComponent;
