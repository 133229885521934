/* eslint-disable react-hooks/exhaustive-deps */
// import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';

// import { setEmailCampaignDraft } from "store/campaign";
import { useAppDispatch, useAppSelector } from 'store';
import { setFlagStatus, setGlobalOpt } from 'store/custom';

// import { EDIT_ID } from 'common/constants/constants';
// import { SessionStorage } from 'common/api/common/storage';
import { checkRolePermissionStatus, jwtDecrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';

import { MdCloudQueue, MdEdit, MdLink, MdLinkOff, MdOutlineCloudDownload } from 'react-icons/md';
// import Toggle from 'components/Toggle';
import { setCampaignSelectedCSVData } from 'store/campaign';
// import CustomFilter from 'components/customFilter';
// import { OptionDataType } from 'common/dto/types/components/customFilter';
// import EmailRowSettings from './features/email-list/EmailRowSettings';
import { Avatar } from '@chakra-ui/react';
// import { FiSearch } from 'react-icons/fi';
// import EmailGroupTags from './features/email-list/EmailGroupTags';
// import EmailEditSettings from './features/email-edit-settings';
import { setEmailEditDrawer } from 'store/email-accounts';
// import AddEmailAccountsDrawerLayout from './features/email-add';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { IoMdArchive } from 'react-icons/io';
import { SenderAccountListRowObject } from 'common/dto/types/views/sender-accounts';
import { setSenderFetchData } from 'store/sender-accounts';
import TableLoader from 'components/Loaders/TableViewLoader';
import ConfirmationPopup from 'components/confirmationPopup/ConfirmationPopup';
import { toast } from 'react-toastify';
// import { AiFillDelete } from 'react-icons/ai';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import EmailGroupTags from '../email-list/EmailGroupTags';
// import MultiChannelNavbar from '../multichannel-accounts-navbar/MultiChannelNavbar';
import EditSenderListDrawer from '../sender-list-edit-drawer/EditSenderListDrawer';
// import SelectMethodPopup from '../email-add/select-method/SelectMethodPopup';
import InfiniteScrollComponent from 'components/infinityScroll';
import GroupTagsEmail from '../email-list/GroupTagsEmail';
import ArrowedTooltip from 'components/ArrowedTooltip';

interface ColumnOption {
  _name: string;
  id: string;
  [key: string]: any; // Allows any other properties with string keys
}

const EmailAccountsList = (props: any) => {
  const columnWidth = ['w-[5%]', 'w-[20%]', 'w-[30%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[5%]'];
  const { searchData, filterData } = props;
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [loader, setLoader] = React.useState(false);
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: props?.filterData || '' });

  // const [colViewOptions, setColViewOptions] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columnOptions, setColumnOptions] = React.useState<ColumnOption[]>([
    { _name: 'Name', id: 'name', name: true, disabled: true },
    { _name: 'Email accounts', id: 'email', email: true, disabled: true },
    { _name: 'Status', id: 'mailer_status', mailer_status: true, disabled: true },
    { _name: 'Daily limits', id: 'limit', limit: true },
    // { _name: 'Health Score', id: '', none: true },   //commented need for development
    { _name: 'Email groups', id: 'groups_list', groups_list: true },
    // { _name: 'Custom tracking', id: '', none: true }, //commented need for development
    // { _name: 'Type', id: '', none: true }, //commented need for development
    // { _name: 'Sent', id: '', none: false }, //commented need for development
  ]);
  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  // const campaignSearchRef = React.useRef(null);
  // console.log("searchData",searchData)
  const debouncedSearchValue = useDebounce(searchData, 300);
  const [editRowData, setEditRowData] = React.useState({});
  const [confirm, setConfirm] = React.useState({
    isOpen: false,
    headerContent: '',
    bodyContent: '',
    bodyRedContent: '',
    descriptionContent: '',
    uuid: [],
  });
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedID, setSelectedIds] = React.useState([]);
  const [multiSelectState, setMultiSelectState] = React.useState(false);
  const dispatch = useAppDispatch();
  // const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  const columnHelper = createColumnHelper<SenderAccountListRowObject>();
  // const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  const custom = useAppSelector((state) => state?.custom);
  // const { accountType } = useAppSelector((state) => state.senderGroupListData);

  // const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const [editState, setEditState] = React.useState(false);
  const [multiOpt, setMultiOpt] = React.useState([]);
  let optionsDataRow: any = [];
  // let multiSelectOptions: any = [];

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    // console.log('tableSelectedData', tableSelectedData);
    setSelectedIds(tableSelectedData);
    if (table.getSelectedRowModel().rows.length > 1) setMultiSelectState(true);
    else setMultiSelectState(false);
  }, [table.getSelectedRowModel()]);

  const handleExportData = async (data: any) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sender Accounts data');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sender_accounts_report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
  const [multiEditIds, setMultiEditIds] = React.useState(table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid));

  const multiSelectDownload = async () => {
    const selectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    try {
      const downloadResponse = await BaseApi.post('/sender-accounts/email/download', { id_list: selectedData });
      const downloadData = downloadResponse?.data?.map((item: any, index: number) => ({
        'S.No': index + 1,
        Name: item?.name,
        'First Name': item?.first_name,
        'Last Name': item?.last_name,
        Email: item?.account_details?.email,
        'Account Status': item?.account_details?.mailer_status === 1 ? 'Connected' : 'Disconnected',
        'Daily Limit': item?.campaign_settings?.daily_limit,
        'Limit per Day': item?.campaign_settings?.limit_sent_per_day,
        Variant: item?.variant,
      }));

      handleExportData(downloadData);
    } catch (error) {
      console.error('Error in Downloading the Data', error);
    }
  };

  const multiSelectDelete = async () => {
    const selectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);

    setConfirm((prev) => ({
      ...prev,
      isOpen: true,
      headerContent: 'Do you want to archive users',
      bodyContent: 'Archive:',
      bodyRedContent: `${selectedData?.length}`,
      descriptionContent: 'Do you confirm archiving users from Sender accounts',
      uuid: selectedData,
    }));
  };

  const multiSelectEdit = async () => {
    setEditRowData({});
    const selectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    setEditState(true);
    setMultiEditIds(selectedData);
  };

  // const multiSelectOptions =
  // [
  //   {
  //     icon: <IoMdArchive />,
  //     name: 'Archieve',
  //     functioning: multiSelectDelete,
  //   },
  //   {
  //     icon: <MdOutlineCloudDownload />,
  //     name: 'Download',
  //     functioning: multiSelectDownload,
  //   },
  // ];

  const handleRowDataEdit = async (rowuuid: string, rowDetails: any) => {
    // console.log('row', rowuuid, rowDetails);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const apiEditResponse = await BaseApi.get(`/sender-accounts/email/list/${rowuuid}`);
      const decryptdata = await jwtDecrypt(apiEditResponse?.data);
      setEditRowData(decryptdata);
      setEditState(true);
    } catch (error) {
      console.error('Error form Row Edit in Sender Account:', error);
    }
  };

  // console.log('OOOO', selectedID);

  // Arcieve Function with confirm page
  const handleConfirmCancel = () => {
    setConfirm((prev) => ({ ...prev, isOpen: false }));
  };

  const handleConfirmSubmitDelete = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const apiEditResponse = await BaseApi.delete(`/sender-accounts/email`, {
        data: { id_list: confirm?.uuid },
      });
      // console.log('jjjjjjj', confirm?.uuid);
      // console.log('LLLLLLL', apiEditResponse);
      if (!apiEditResponse?.error) {
        setLoading(false);

        toast.success(apiEditResponse?.message, toastState.success);
        setConfirm((prev) => ({
          ...prev,
          isOpen: false,
          headerContent: '',
          bodyContent: '',
          bodyRedContent: '',
          descriptionContent: '',
          uuid: [],
        }));
        table.resetRowSelection();
      }
      // const decryptdata = await jwtDecrypt(apiEditResponse?.data);
      // setEditRowData(decryptdata);
      // setEditState(true);
    } catch (error) {
      console.error('Error form Row Edit in Sender Account:', error);
    }
  };

  const handleRowArchieve = async (rowuuid: string, rowDetails: any) => {
    setConfirm((prev) => ({
      ...prev,
      isOpen: true,
      headerContent: 'Do you want to archive user',
      bodyContent: 'Archive:',
      bodyRedContent: rowDetails?.account_details?.email,
      descriptionContent: 'Do you confirm archiving user from Sender accounts',
      uuid: [rowuuid],
    }));
    // console.log('>>>>>>>>>>>>', rowDetails);
    // console.log('ROE,', rowuuid);
  };

  //Download function for sender Accounts
  const handlDownload = async (rowuuid: string, rowDetails: any) => {
    try {
      const downloadResponse = await BaseApi.post('/sender-accounts/email/download', { id_list: [rowuuid] });
      // console.log('Down', downloadResponse?.data);
      const downloadData = downloadResponse?.data?.map((item: any, index: number) => ({
        'S.No': index + 1,
        Name: item?.name,
        'First Name': item?.first_name,
        'Last Name': item?.last_name,
        Email: item?.account_details?.email,
        'Account Status': item?.account_details?.mailer_status === 1 ? 'Connected' : 'Disconnected',
        'Daily Limit': item?.campaign_settings?.daily_limit,
        'Limit per Day': item?.campaign_settings?.limit_sent_per_day,
        Variant: item?.variant,
      }));
      handleExportData(downloadData);
    } catch (error) {
      console.error('Error in Downloading the Data', error);
    }
  };

  // const RowSettingOptions = [
  //   {
  //     icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
  //     accessName: 'edit',
  //     name: 'Edit',
  //     functioning: handleRowDataEdit,
  //   },
  //   {
  //     icon: <IoIosCopy className="text-brand-500 group-hover:text-white" />,
  //     accessName: 'copy',
  //     name: 'Copy',
  //     functioning: () => alert('Copy'),
  //   },
  //   {
  //     icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
  //     name: 'Archive',
  //     accessName: 'archieve_or_delete',
  //     functioning: () => alert('Archieve'),
  //   },
  //   {
  //     icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
  //     name: 'Download',
  //     accessName: 'download',
  //     functioning: () => alert('DownLoad'),
  //   },
  // ];

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'sender_accounts');
      // console.log(status)
      // setRoleStatus(status);

      if (status?.is_owner) {
        optionsDataRow = [
          {
            icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
            accessName: 'edit',
            name: 'Edit',
            functioning: handleRowDataEdit,
          },
          // {
          //   icon: <IoIosCopy className="text-brand-500 group-hover:text-white" />,
          //   accessName: 'copy',
          //   name: 'Copy',
          //   functioning: () => alert('Copy'),
          // },
          {
            icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
            name: 'Archive',
            accessName: 'archieve_or_delete',
            functioning: handleRowArchieve,
          },
          {
            icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
            name: 'Download',
            accessName: 'download',
            functioning: handlDownload,
          },
        ];
        setMultiOpt([
          {
            icon: <MdEdit />,
            name: 'Edit',
            functioning: multiSelectEdit,
          },
          {
            icon: <IoMdArchive />,
            name: 'Archive',
            functioning: multiSelectDelete,
          },
          {
            icon: <MdOutlineCloudDownload />,
            name: 'Download',
            functioning: multiSelectDownload,
          },
        ]);

        // multiSelectOptions = [
        //   {
        //     icon: <IoMdArchive />,
        //     name: 'Archieve',
        //     functioning: multiSelectDelete,
        //   },
        //   {
        //     icon: <MdOutlineCloudDownload />,
        //     name: 'Download',
        //     functioning: multiSelectDownload,
        //   },
        // ];
      } else {
        optionsDataRow = [
          {
            icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
            accessName: 'edit',
            name: 'Edit',
            functioning: handleRowDataEdit,
          },
          // {
          //   icon: <IoIosCopy className="text-brand-500 group-hover:text-white" />,
          //   accessName: 'copy',
          //   name: 'Copy',
          //   functioning: () => alert('Copy'),
          // },
          {
            icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
            name: 'Archive',
            accessName: 'archieve_or_delete',
            functioning: handleRowArchieve,
          },
          {
            icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
            name: 'Download',
            accessName: 'download',
            functioning: handlDownload,
          },
        ].filter((item) => status?.hasOwnProperty(item.accessName));

        const multiSelectOptionsNew = [
          {
            icon: <IoMdArchive />,
            accessName: 'archieve_or_delete',
            name: 'Archieve',
            functioning: multiSelectDelete,
          },
          {
            icon: <MdOutlineCloudDownload />,
            accessName: 'download',
            name: 'Download',
            functioning: multiSelectDownload,
          },
        ].filter((item) => status?.hasOwnProperty(item.accessName));
        setMultiOpt(multiSelectOptionsNew);
      }
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  // React.useEffect(() => {
  //   if (roleStatus) {
  //     console.log('L::::::::::');
  //     const filteredRowSettingOptions = roleStatus?.is_owner ? RowSettingOptions : RowSettingOptions.filter((item) => roleStatus && roleStatus.hasOwnProperty(item.accessName));
  //     setOptions(filteredRowSettingOptions);
  //     // dispatch(resetRowsettingsOptionData());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [roleStatus]);
  // const { rowsettingOptionData } = useAppSelector((state) => state.commonrow);

  // const handleSort = (column: string) => {
  // setQueryParams((prev) => ({
  //     ...prev,
  //     sort: {
  //         columnName: column,
  //         order: prev.sort.columnName === column ? (prev.sort.order === 'asc' ? 'desc' : prev.sort.order === 'desc' ? '0' : 'asc') : 'asc'
  //     }
  // }));
  // };

  // Fetch groups when user search
  // const fetchGroupList = async (query?: string) => {
  //   try {
  //     // setIsLoading(true)
  //     const { data } = await BaseApi.get(`/email/groups?${query || ''}`);
  //     if (data?.data && data?.data?.length) {
  //       const finalData = data?.data?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
  //       // setGroupList(finalData)
  //       // setSearchedGroup(finalData)
  //       const states = data?.data?.reduce((acc: any, val: any) => {
  //         acc[val.uuid] = { show: false, rename: false };
  //         return acc;
  //       }, {});
  //       // setGroupToggleState(states)
  //     } else {
  //       // setGroupList([])
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     // setIsLoading(false)
  //   }
  // };
  const fetchGroupList = async () => {
    try {
      const data = await BaseApi.get(`/groups/linked/with/email`);
      if (data?.data && data?.data?.data?.length) {
        const finalData = await data?.data?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
        );
        const states = data?.data?.data?.reduce((acc: any, val: any) => {
          acc[val.uuid] = { show: false, rename: false };
          return acc;
        }, {});
        dispatch(setSenderFetchData({ finalData, states }));
      } else {
        dispatch(setSenderFetchData([]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchGroupList();
  }, []);

  // Role Restriction Enabed fro the Row setting of Sender Accounts
  // const filteredRowSettingOptions = roleStatus?.is_owner ? RowSettingOptions : RowSettingOptions.filter((item) => roleStatus[item?.accessName]);
  // const filteredRowSettingOptions = roleStatus?.is_owner ? RowSettingOptions : RowSettingOptions.filter((item) => roleStatus[item?.accessName] ?? false);
  // const filteredRowSettingOptions = roleStatus?.is_owner
  //   ? RowSettingOptions
  //   : RowSettingOptions.filter((item) => roleStatus?.hasOwnProperty(item?.accessName) && roleStatus[item.accessName]);

  // const filteredRowSettingOptions = RowSettingOptions;
  const fetchList = async () => {
    try {
      // setLoader(true);
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        // { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: searchData },
        { key: 'filter', value: filterData },
      ]);

      const response = await BaseApi.get(`/sender-accounts/email/list?${query}`);
      const { data, total_records }: any = jwtDecrypt(response?.data);
      if (data && data?.length) {
        setData((prev) => ({
          data: data,
          count: total_records?.count,
        }));
      } else {
        setData((prev) => ({
          data: [],
          count: 0,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const updatedGroup = async () => {
    // setLoader(false);
    // fetchList();
  };

  const menuOptions = useAppSelector((state) => state?.senderGroupListData?.columnRowOptions?.emailOption);
  // console.log('menuOptions', menuOptions);

  // React.useEffect(() => {
  //   fetchList();
  //   setLoader(true);
  // }, [custom?.tableLoader, queryParams?.limit, queryParams?.offset, debouncedSearchValue, custom?.globalDependencyLoading, custom?.flagStatus]);
  React.useEffect(() => {
    setLoader(true);
    fetchList();
  }, [custom?.tableLoader, queryParams?.search, filterData, debouncedSearchValue, custom?.globalDependencyLoading, custom?.flagStatus]);

  const handleUpdateAccountStatus = async (actionType: string = '', uuid: string = '') => {
    try {
      const response = await BaseApi.post(`/sender-accounts/email/${actionType}`, { id_list: [uuid] });
      if (!response?.error || response?.status === 200) {
        const updateResponse = response?.data?.find((item: { uuid: string }) => item?.uuid === uuid);
        if (updateResponse?.data) {
          window.location.href = updateResponse?.data;
        } else {
          setData((prev) => {
            const data = prev.data?.map((item) => {
              if (item?.uuid === updateResponse?.uuid) {
                return { ...item, account_details: { ...item?.account_details, mailer_status: item?.account_details?.mailer_status ^ 1 } };
              }
              return item;
            });
            const count = prev?.count;
            return { data, count };
          });
        }
      }
    } catch (error: any) {
      console.error('handleUpdateAccountStatus Error:', error?.message);
    }
  };
  const hanleDeleteGroupFromSenderList = (groupId: string) => {
    setData((prev) => {
      const updatedData = prev?.data?.map((list) => {
        const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
        return {
          ...list,
          groups_list: finalGroupList,
        };
      });
      return {
        ...prev,
        data: updatedData,
      };
    });
  };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-6 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-6 flex items-center">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none font-semibold">Name</div>,
      cell: (info) => {
        // <div className=" relative flex w-full items-center gap-2">
        //   <Avatar size={'sm'} name={info.getValue() ? info.getValue() : info.row.original?.first_name} bg="teal.500" src={info.row.original?.picture} />
        //   <div className=" group/nameing flex w-32 flex-col ">
        //     <p className="w-full truncate text-sm font-bold text-heading dark:text-white">
        //       {info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}
        //       <ArrowedTooltip
        //     data={
        //       <>
        //         {info.getValue()
        //           ? info.getValue()
        //           : info.row.original?.first_name + ' ' + info.row.original?.last_name}
        //         <br />
        //         {info?.row?.original?.account_details?.email}
        //       </>
        //     }
        //     className="ml-7 mt-4 hidden !w-48 group-hover/nameing:block"
        //     />
        //     </p>
        //     <p className="w-full truncate text-xs text-column">{info?.row?.original?.account_details?.email}

        //     <ArrowedTooltip
        //     data={
        //       <>
        //         {info.getValue()
        //           ? info.getValue()
        //           : info.row.original?.first_name + ' ' + info.row.original?.last_name}
        //         <br />
        //         {info?.row?.original?.account_details?.email}
        //       </>
        //     }
        //     className="ml-7 mt-4 hidden !w-48 group-hover/nameing:block"
        //     />

        //     </p>

        //   </div>
        // </div>
        return (
          <div className="flex w-full items-center gap-2">
            <Avatar className="!text-white" size={'sm'} name={info.getValue() ? info.getValue() : info.row.original?.first_name} bg="teal.500" src={info.row.original?.picture} />
            <div className="flex w-32 flex-col">
              {/* Name section with its tooltip */}
              <div className="group relative">
                <p className="w-full truncate text-sm font-bold text-heading dark:text-white">
                  {info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}
                </p>
                {info.getValue()?.length > 10 && (
                  <ArrowedTooltip
                    data={<>{info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}</>}
                    className="-mt-1 ml-7 hidden !w-fit !px-4 group-hover:block"
                  />
                )}
              </div>

              {/* Email section with its tooltip */}
              <div className="group relative">
                <p className="w-full truncate text-xs text-column">{info?.row?.original?.account_details?.email}</p>
                {info?.row?.original?.account_details?.email?.length > 18 && (
                  <ArrowedTooltip data={<>{info?.row?.original?.account_details?.email}</>} className="-mt-1 ml-7 hidden !w-fit !px-4 group-hover:block" />
                )}
              </div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('groups_list', {
      id: 'groups_list',
      enableSorting: false,
      header: () => <div className=" column-groupHeader select-none font-semibold">Email groups</div>,
      cell: (row) => {
        // BaseApi.post('groups', [])
        return (
          <GroupTagsEmail
            key={row?.row?.original?.uuid}
            groupTags={row.getValue()}
            row={row?.row?.original}
            fetchList={updatedGroup}
            hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList}
          />
        );
        // return <EmailGroupTags groupTags={row.getValue()} row={row?.row?.original} fetchList={updatedGroup} />;

        //    return <></>
      },
    }),
    columnHelper.accessor('account_details', {
      id: 'mailer_status',
      enableSorting: false,
      header: () => <div className=" column-mailStatusHeader relative select-none font-semibold">Status</div>,
      cell: (info) => {
        return (
          <div className="ml-14 flex-col">
            {info?.row?.original?.account_details?.mailer_status === 0 && (
              <div
                onClick={() => handleUpdateAccountStatus('connect', info?.row?.original?.uuid)}
                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1 text-[0.7rem] font-semibold text-[#d32318]"
              >
                <MdLinkOff />
                <span className={`status-index-${info?.row?.id}`}>Disconnected</span>
              </div>
            )}
            {info?.row?.original?.account_details?.mailer_status === 1 && (
              <div
                onClick={() => handleUpdateAccountStatus('disconnect', info?.row?.original?.uuid)}
                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]"
              >
                <MdLink />
                <span className={`status-index-${info?.row?.id}`}>Connected</span>
              </div>
            )}
            {info?.row?.original?.account_details?.mailer_status === 2 && (
              <div
                onClick={() => handleUpdateAccountStatus('connect', info?.row?.original?.uuid)}
                className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.65rem] font-semibold text-[#029A48]"
              >
                <MdLink />
                <span className={`status-index-${info?.row?.id}`}>Paused</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('campaign_settings', {
      id: 'limit',
      header: () => <div className="column-limitHeader select-none font-semibold">Daily limit</div>,
      cell: (info) => (
        <p className={`text-sm font-semibold`}>{`${info?.row?.original?.campaign_settings?.limit_sent_per_day}/${info?.row?.original?.campaign_settings?.daily_limit}`}</p>
      ),
    }),
    columnHelper.accessor('account_details', {
      id: 'health_score',
      header: () => <div className="select-none font-semibold">Health Score</div>,
      cell: (info) => (
        <div
          className={`mx-auto w-min rounded-full px-2.5 py-1 text-xs text-white ${
            info?.row?.original?.account_details?.email_health_score >= 90
              ? 'bg-green-600'
              : info?.row?.original?.account_details?.email_health_score >= 70
              ? 'bg-orange-600'
              : 'bg-red-600'
          }`}
        >
          {info?.row?.original?.account_details?.email_health_score || 0}%
        </div>
      ),
    }),
    columnHelper.accessor('account_details', {
      id: 'custom_tracking',
      header: () => <div className="select-none font-semibold">Custom tracking</div>,
      cell: (info) => {
        return <MdCloudQueue className="mx-auto" />;
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return (
          // <RowSettings
          //   fetchList={fetchList}
          //   status={row.row.original.mailer_status}
          //   id={row.row.id}
          //   uuidRow={row.getValue()}
          //   rowData={row.row.original}
          //   table={table}
          //   setData={setData}
          // />
          <CommonRowSettings buttonClassName={'custom_row_btn_0'} uuidRow={row?.row?.original?.uuid} rowData={row.row.original} options={optionsDataRow} />
        );

        // console.log(table.getSelectedRowModel().rows.map(val => val.original)) //get full client-side selected rows
        // return <EmailRowSettings fetchList = {fetchList} status={row.row.original?.mailer_status} id={row.row.id} uuidRow={row.getValue()} rowData = {row.row.original} table={table} setData = {setData}/>
        //  <div id={`${info.row.original?.campaign_name}`}
        //     className="absolute top-0 bg-white border"
        // onClick={() => {
        // SessionStorage.setString(EDIT_ID, info.row.original?.uuid);
        // const id :number = Number(info.row.id);
        //     fetchCampaignList(info.row.original?.uuid)
        // }} >
        //     <MdEdit />
        // </div>
      },
    }),
  ];

  React.useEffect(() => {
    // const data = [
    //   // { id: '1', name: 'Assignee', conditions: [{ id: "1", name: 'Is' }, { id: "2", name: 'Is not' }], resultValue: [{ id: "1", name: 'Me' }, { id: "2", name: 'P1' }, { id: "3", name: 'P2' }] },
    //   {
    //     id: '2',
    //     name: 'Status',
    //     uniqueColumnName: 'campaign_status',
    //     conditions: [
    //       { id: '1', name: 'Is', uniqueName: 'is' },
    //       { id: '2', name: 'Is not', uniqueName: 'not' },
    //     ],
    //     resultValue: [
    //       { id: '1', name: 'Paused', uniqueName: '3' },
    //       { id: '2', name: 'Active', uniqueName: '1' },
    //       { id: '3', name: 'Draft', uniqueName: '0' },
    //     ],
    //   },
    //   {
    //     id: '3',
    //     name: 'Open Rate',
    //     uniqueColumnName: 'open_rate',
    //     conditions: [
    //       { id: '1', name: 'More Than', uniqueName: 'gte' },
    //       { id: '2', name: 'Less than', uniqueName: 'lte' },
    //     ],
    //     resultValue: [],
    //   },
    //   {
    //     id: '4',
    //     name: 'Created On',
    //     uniqueColumnName: 'cdate',
    //     conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    //     resultValue: [
    //       { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
    //       { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
    //       { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
    //       { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
    //       { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
    //       { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
    //       { id: '7', name: 'Date Range' },
    //     ],
    //   },
    // ];
    // setOptionData(data);
    setColumns(columnData);
  }, []);

  // React.useEffect(() => {
  //   const fetchList = async () => {
  //     try {
  //       // setIsLoading(true)
  //       const query = setAndGetQueryParams([
  //         { key: 'limit', value: queryParams?.limit },
  //         { key: 'offset', value: queryParams?.offset },
  //         { key: 'search', value: queryParams?.search },
  //       ]);
  //       const response = await BaseApi.get(`/email/settings?${query}`);
  //       const { data, total_records }: any = jwtDecrypt(response?.data);
  //       setQueryParams({ limit: 25, offset: 0, search: '', filter: '', sort: { columnName: '', order: '' } });
  //       if (data && data?.length) {
  //         setData((prev) => ({
  //           data: data,
  //           count: total_records,
  //         }));
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setIsLoading(false)
  //     }
  //   };
  //   fetchList();
  // }, [queryParams?.limit, queryParams?.offset, queryParams?.filter, flagStatus, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  React.useEffect(() => {
    const tempColumns = columnData.filter((val) => {
      const tempId = menuOptions.findIndex((option: any) => option.id === val.id);
      if (tempId === -1) return true;
      return menuOptions[tempId]?.[val.id] === true;
    });
    setColumns(tempColumns);
    // testing ss
  }, [columnOptions, menuOptions]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    dispatch(setCampaignSelectedCSVData(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
  }, [table.getSelectedRowModel()]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (parentRef.current && !parentRef.current.contains(event.target) && !childRef?.current?.contains(event.target)) {
        // setColViewOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [parentRef]);

  // const handleFilter = async (filteredData: any) => {
  //   const encryptedData = jwtEncrypt(filteredData);
  //   setQueryParams((prev) => ({
  //     ...prev,
  //     filter: encryptedData,
  //   }));
  // };

  // const handleColumnData = (colId: string, colState: boolean) => {
  //   setColumnOptions((prevOptions) => {
  //     return prevOptions.map((option) => {
  //       if (option.id === colId) {
  //         return { ...option, [colId]: !colState };
  //       }
  //       return option;
  //     });
  //   });
  // };

  // const dateFormate = (from: number | string, to: number = 1) => {
  //   const fromDate =
  //     typeof from === 'string'
  //       ? moment()
  //           .startOf(from as moment.unitOfTime.StartOf)
  //           .format('YYYY-MM-DD')
  //       : moment().subtract(from, 'days').format('YYYY-MM-DD');
  //   const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
  //   return `${fromDate},${currentDate}`;
  // };

  // console.log(dateFormate('month',0))
  // const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setQueryParams((prev) => ({
  //     ...prev,
  //     search: event.target.value.trim().toLowerCase(),
  //   }));
  // };

  const generateCodeToTokenAndUserInfo = useCallback(
    async (authCode: string) => {
      try {
        await BaseApi.patch('/sender-accounts/email/auth/code', { code: authCode });
        dispatch(setFlagStatus(true));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (authCode) {
      generateCodeToTokenAndUserInfo(authCode);
      navigate('/sender-accounts');
    }
  }, [authCode, generateCodeToTokenAndUserInfo, navigate]);

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      // console.log('HITTEFD', data?.data, data?.count);
      const fetchEntryList = async () => {
        setLoader(false);

        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 2 },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/sender-accounts/email/list?${query}`);
          // console.log(response?.data)
          const responseData: any = jwtDecrypt(response?.data);
          // console.log('responseData', responseData);
          // if (!responseData?.data) return setData((prev) => ({ ...prev, data: data, count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.count, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree  dark:text-white sm:overflow-x-auto`}>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
          {!loader ? (
            <>
              <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`
                                                ${index === 0 && 'w-[35px]'}
                                                ${index === 1 && 'w-[200px] text-start'}
                                                ${index === 2 && 'w-[130px] text-start'}
                                                ${index >= 3 && index <= 5 && 'w-[130px]'}
                                                ${index === 6 && 'w-[100px]'}
                                                ${index === arr.length - 1 && 'w-[20px]'}
                                                mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder ">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne" onClick={() => dispatch(setEmailEditDrawer(true))}>
                          {row.getVisibleCells().map((cell, i) => {
                            const RowDataWidth = columnWidth;
                            return (
                              <td
                                key={cell.id}
                                className={`${RowDataWidth[i]}border-white/0 py-3 text-heading ${(i === 1 || i === 2) && 'text-start'} text-center dark:text-white`}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                        <h6>No Data Found</h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={5}
              row={15}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 16% 60% 16% 4%"
            />
          )}
        </div>
      </div>
      {multiSelectState && (
        <MultiselectGlobalOpt
          options={[
            {
              icon: <p className="text-xs">{table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid)?.length}</p>,
              name: 'Selected',
              functioning: () => {},
            },
            ...multiOpt,
          ]}
        />
      )}
      <ConfirmationPopup
        isOpen={confirm?.isOpen}
        loading={loading}
        headerContent={confirm?.headerContent}
        bodyContent={confirm?.bodyContent}
        bodyRedContent={confirm?.bodyRedContent}
        descriptionContent={confirm?.descriptionContent}
        handleCancel={handleConfirmCancel}
        handleConfirmFunction={handleConfirmSubmitDelete}
        btn2='Archive'
      />
      {/* <EmailEditSettings /> */}
      {editState && <EditSenderListDrawer fetchList={fetchList} onClose={() => setEditState(false)} isOpen={editState} editData={editRowData} multiEditIds={multiEditIds} />}
      {/* {custom?.flagStatus && <AddEmailAccountsDrawerLayout />} */}
      {/* <AddEmailAccountsDrawerLayout /> */}
      {/* <SelectMethodPopup isOpen={accountType === 'email'} /> */}
    </>
  );
};

export default EmailAccountsList;
