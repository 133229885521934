import { useDispatch } from 'react-redux';
import { NumberInput, NumberIncrementStepper, NumberInputField, NumberDecrementStepper } from '@chakra-ui/react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { useAppSelector } from 'store';
import { clearSchedule, setDays, setSchedule, setTime } from 'store/campaign-v2';
import TimeZones from './TimeZones';
import { BaseApi } from 'common/api/common/base-api';
import useClickOutsideHook from 'common/hooks/useClickOutsideHook';
import { _errorHandler, toastState } from 'common/utils/utility';
import Button from 'common/sparkle-common/Button';

type SetScheduleProps = {
    position: string;
    onClose: () => void;
    onOutsideClose: () => void;
}

const SetSchedule = (props: SetScheduleProps) => {
    const { position, onClose, onOutsideClose } = props
    const [errorStatus, setErrorStatus] = useState<{ [key: string]: string }>({})
    const [loading, setLoading] = useState(false)
    const ref = useClickOutsideHook(() => { onOutsideClose(); dispatch(clearSchedule()) })
    const state = useAppSelector(state => state?.campaignv2)
    const dispatch = useDispatch()
    const schedule = state?.schedule
    const createSchedule = async () => {
        let { name, time_zone, days, start_time, end_time, uuid } = schedule
        const keys = Object.keys(days)
        days = keys.filter(day => days[day])
        let payload: { [key: string]: string } = { name, time_zone, start_time, end_time, days }
        try {
            setLoading(true)
            if (!name) {
                setErrorStatus({ name: 'Campaign Name is required' })
                return
            }
            if (uuid) {
                payload.uuid = uuid
                const response = await BaseApi.patch('/time/schedule', payload)
                if (response?.error) {
                    toast.error(_errorHandler(response?.error), toastState.error)
                    setErrorStatus(response?.message)
                    return
                }
                onClose()
                dispatch(clearSchedule())
            }
            else {
                const response = await BaseApi.post('/time/schedule', payload)
                if (response?.error) {
                    toast.error(_errorHandler(response?.error), toastState.error)
                    setErrorStatus(response?.message)
                    return
                }
                onClose()
                dispatch(clearSchedule())
            }
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false)
        }
    }
    return (
        <div className={`absolute ${position} z-10 border border-blueSecondary bg-white rounded-md w-[20rem]`} ref={ref}>
            <h5 className='text-[0.8rem] font-medium text-[#667085] p-3'>Active on</h5>
            <hr />

            <div className='px-3 py-2 flex flex-col gap-1'>
                <h5 className='text-[0.85rem] text-[#667085] font-medium'>Name</h5>
                <div className='border rounded-md border-[#D0D5DD] p-2 text-[0.8rem] text-[#667085]'>
                    <input id='schedule_input' type="text" className='size-full outline-none border-none' placeholder='Schedule' value={schedule?.name} onChange={(e) => dispatch(setSchedule({ key: 'name', value: e.target.value }))} />
                </div>
                {errorStatus?.name && <h5 className='text-[0.65rem] text-red-500 font-medium'>{errorStatus?.name}</h5>}
            </div>
            <hr />

            <div className='px-3 py-2 flex flex-col gap-1'>
                <h5 className='text-[0.85rem] text-[#667085] font-medium'>Select campaign day</h5>
                <div className='flex justify-around w-full'>
                    <button id='mon_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.mon ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('mon'))}>M</button>
                    <button id='tue_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.tue ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('tue'))}>T</button>
                    <button id='wed_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.wed ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('wed'))}>W</button>
                    <button id='thu_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.thu ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('thu'))}>Th</button>
                    <button id='fri_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.fri ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('fri'))}>F</button>
                    <button id='sat_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.sat ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('sat'))}>S</button>
                    <button id='sun_select' className={`rounded-md py-1 px-2.5 text-[0.8rem] hover:bg-brand-500 hover:text-white ${schedule?.days?.sun ? 'bg-brand-500 text-white' : 'text-[#8F9BBA] bg-[#8F9BBA1A]'}`} onClick={() => dispatch(setDays('sun'))}>Sun</button>
                </div>
                {errorStatus?.days && <h5 className='text-[0.65rem] text-red-500 font-medium text-center'>{errorStatus?.days}</h5>}
            </div>
            <hr />

            <div className='px-3 py-2'>
                <div className='flex'>
                    <h5 className='w-full text-[0.85rem] text-[#667085] font-medium ml-1'>From</h5>
                    <h5 className='w-full text-[0.85rem] text-[#667085] font-medium ml-4'>Till</h5>
                </div>
                <div className='flex justify-between gap-3'>
                    <div className='w-full flex justify-around items-center'>
                        <div className='w-10 border rounded-md border-[#D0D5DD] py-1 px-1.5 text-[0.8rem] text-[#667085] flex justify-between items-center'>
                            <NumberInput title='Scroll or Edit to set delay'
                                value={schedule?.start_time?.hours?.padStart(2,0)} id='hours-input'
                                onChange={(value) => dispatch(setTime({ obj: 'start_time', key: 'hours', value }))}
                                name='hours'
                                min={0} max={12} allowMouseWheel className='outline-0 caret-white flex items-center justify-between w-full'>
                                <NumberInputField p={0}
                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()}
                                    border={0}
                                    height={'15px'}
                                    width={'18px'}
                                    placeholder='00'
                                    className='font-medium shadow-red-500 focus:border-green-500  focus-visible:!shadow-none'
                                    fontSize={12} />
                                <div className='flex flex-col items-center justify-center'>
                                    <NumberIncrementStepper id='from_hours_up_btn' children={<IoIosArrowUp className='size-2' />} fontSize={8} border={"none"} />
                                    <NumberDecrementStepper id='from_hours_down_btn' children={<IoIosArrowDown className='size-2' />} fontSize={8} border={"none"} />
                                </div>
                            </NumberInput>
                        </div>
                        <div className='w-10 border rounded-md border-[#D0D5DD] py-1 px-1.5 text-[0.8rem] text-[#667085] flex justify-between items-center'>
                            <NumberInput title='Scroll or Edit to set delay'
                                value={schedule?.start_time?.minutes?.padStart(2,0)} id='minutes-input'
                                onChange={(value) => dispatch(setTime({ obj: 'start_time', key: 'minutes', value }))}
                                name='minutes'
                                min={0} max={60} allowMouseWheel className='outline-0 caret-white flex items-center justify-between w-full'>
                                <NumberInputField p={0}
                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()}
                                    border={0}
                                    height={'15px'}
                                    width={'18px'}
                                    placeholder='00'
                                    className='font-medium shadow-red-500 focus:border-green-500  focus-visible:!shadow-none'
                                    fontSize={12} />
                                <div className='flex flex-col items-center justify-center'>
                                    <NumberIncrementStepper id='from_minutes_up_btn' children={<IoIosArrowUp className='size-2' />} fontSize={8} border={"none"} />
                                    <NumberDecrementStepper id='from_minutes_down_btn' children={<IoIosArrowDown className='size-2' />} fontSize={8} border={"none"} />
                                </div>
                            </NumberInput>
                        </div>
                        <select name="" id="from_options" className='w-10 text-center border border-[#D0D5DD] rounded-md outline-none focus:border-blueSecondary appearance-none checked:bg-red-500 text-[0.7rem] font-medium py-1' value={schedule?.start_time?.period} onChange={(e) => dispatch(setTime({ obj: 'start_time', key: 'period', value: e.target.value }))}>
                            <option className='text-[0.7rem] font-semibold hover:bg-brand-500 hover:text-white' id='from_AM_btn' value={'AM'}>AM</option>
                            <option className='px-0 mx-0 text-[0.7rem] font-semibold hover:bg-brand-500 hover:text-white' id='from_AM_btn' value={'PM'}>PM</option>
                        </select>
                    </div>
                    <div className='w-full flex justify-around items-center'>
                        <div className='w-10 border rounded-md border-[#D0D5DD] py-1 px-1.5 text-[0.8rem] text-[#667085] flex justify-between items-center'>
                            <NumberInput title='Scroll or Edit to set delay'
                                value={schedule?.end_time?.hours?.padStart(2,0)} id='minutes-input'
                                onChange={(value) => dispatch(setTime({ obj: 'end_time', key: 'hours', value }))}
                                name='hours'
                                min={0} max={12} allowMouseWheel className='outline-0 caret-white flex items-center justify-between w-full'>
                                <NumberInputField p={0}
                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()}
                                    border={0}
                                    height={'15px'}
                                    width={'18px'}
                                    placeholder='0'
                                    className='font-medium shadow-red-500 focus:border-green-500  focus-visible:!shadow-none'
                                    fontSize={12} />
                                <div className='flex flex-col items-center justify-center'>
                                    <NumberIncrementStepper id='to_hours_up_btn' children={<IoIosArrowUp className='size-2' />} fontSize={8} border={"none"} />
                                    <NumberDecrementStepper id='to_hours_down_btn' children={<IoIosArrowDown className='size-2' />} fontSize={8} border={"none"} />
                                </div>
                            </NumberInput>
                        </div>
                        <div className='w-10 border rounded-md border-[#D0D5DD] py-1 px-1.5 text-[0.8rem] text-[#667085] flex justify-between items-center'>
                            <NumberInput title='Scroll or Edit to set delay'
                                value={schedule?.end_time?.minutes?.padStart(2,0)} id='minutes-input'
                                onChange={(value) => dispatch(setTime({ obj: 'end_time', key: 'minutes', value }))}
                                name='minutes'
                                min={0} max={60} allowMouseWheel className='outline-0 caret-white flex items-center justify-between w-full'>
                                <NumberInputField p={0}
                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.select()}
                                    border={0}
                                    height={'15px'}
                                    width={'18px'}
                                    placeholder='0'
                                    className='font-medium shadow-red-500 focus:border-green-500  focus-visible:!shadow-none'
                                    fontSize={12} />
                                <div className='flex flex-col items-center justify-center'>
                                    <NumberIncrementStepper id='to_minutes_up_btn' children={<IoIosArrowUp className='size-2' />} fontSize={8} border={"none"} />
                                    <NumberDecrementStepper id='to_minutes_down_btn' children={<IoIosArrowDown className='size-2' />} fontSize={8} border={"none"} />
                                </div>
                            </NumberInput>
                        </div>
                        <select name="" id="to_options" className='w-10 text-center border border-[#D0D5DD] rounded-md outline-none focus:border-blueSecondary appearance-none checked:bg-red-500 text-[0.7rem] font-medium py-1' value={schedule?.end_time?.period} onChange={(e) => dispatch(setTime({ obj: 'end_time', key: 'period', value: e.target.value }))}>
                            <option id='to_AM_btn' className='py-1.5 px-0 mx-0 text-[0.7rem] font-semibold hover:bg-brand-500 hover:text-white' value={'AM'}>AM</option>
                            <option id='to_PM_btn' className='py-1.5 px-0 mx-0 text-[0.7rem] font-semibold hover:bg-brand-500 hover:text-white' value={'PM'}>PM</option>
                        </select>
                    </div>
                </div>
                {errorStatus?.start_time && <h5 className='text-[0.65rem] text-red-500 font-medium text-center mt-1'>{errorStatus?.start_time}</h5>}
                {errorStatus?.end_time && <h5 className='text-[0.65rem] text-red-500 font-medium text-center mt-1'>{errorStatus?.end_time}</h5>}
            </div>
            <hr />
            {<TimeZones />}
            {errorStatus?.time_zone && <h5 className='text-[0.65rem] text-red-500 font-medium text-center'>{errorStatus?.time_zone}</h5>}
            <hr />
            <div className='bg-white px-7 py-2 rounded-md'>
                <Button id='save_btn' name='Save' py='py-1' customClass='bg-brand-500 text-white text-sm w-full rounded-md cursor-pointer' loading={loading} onClick={createSchedule} />
            </div>
        </div>
    )
}

export default SetSchedule