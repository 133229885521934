import { Avatar, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { calculateTimeAgo, checkRolePermissionStatus, toastState } from 'common/utils/utility';
import { FaXTwitter } from 'react-icons/fa6';
import { IoMdMore } from 'react-icons/io';
// import { IoExitOutline } from 'react-icons/io5';
import { RiMessage2Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import {
  setCurrentId,
  setDragged,
  setDraggedElement,
  setOppertunityId,
  setOppertunityDomain,
  onCrmOpportunitiesUpdated,
  setOpportunityInboxViewData,
  setProspectDetails,
} from 'store/crm';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { BiHistory } from 'react-icons/bi';
import MergeCardPopup from '../merge-cards/MergeCardPoppup';
import React, { useState } from 'react';
import { MdOutlineCallMerge } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { setSideBarFullView } from 'store/custom';
import { TiSocialLinkedin } from 'react-icons/ti';
import { BsInstagram } from 'react-icons/bs';
// import { FaCirclePlus, FaCircleMinus } from 'react-icons/fa6';

type OpportunityPropsType = {
  id: string;
  dropAction: (val: number) => void;
  opportunityData: any;
  pipeline_id: string;
  AvatarColor: string;
};

const Opportunity = (props: OpportunityPropsType) => {
  const { id, opportunityData, dropAction, pipeline_id, AvatarColor } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { cdate, task, opportunity, prospect_enrich_details } = opportunityData;
  const state = useAppSelector((state) => state?.crm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [primaryId, setPrimaryId] = useState(null);
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const custom = useAppSelector((state) => state?.custom);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const handleDragStart = (e: any) => {
    dispatch(setDragged(true));
    dispatch(setCurrentId(e.target.id));
    const check = document.getElementById(e.target.id);
    const dragImage: any = check.cloneNode(true);
    dragImage.style.position = 'absolute';
    dragImage.style.top = '-9999px';
    dragImage.style.left = '-9999px';
    document.body.appendChild(dragImage);

    e.dataTransfer.setDragImage(dragImage, 0, 0);

    // Remove the drag image after a slight delay
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    // e.target.classList.add('animate-bounce');
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.currentTarget.classList.remove('animate-bounce');
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any) => {
    const colId = e.target.id.split('-')[0];
    dropAction(colId);
    e.currentTarget.classList.remove('animate-bounce');
    dispatch(setDragged(false));
    if (state?.currentId === e.target.id) return;
    dispatch(setDraggedElement({ prev: state?.currentId, next: e.target.id }));
  };

  const handleDeleteOpportunity = async () => {
    try {
      const response = await BaseApi.delete(`/crm/pipeline/opportunity`, { data: { delete_ids: [opportunityData?.uuid] } });
      if (response?.error) {
        toast.error(response?.message, toastState.error);
      } else {
        toast.success(response?.message, toastState.success);
        dispatch(onCrmOpportunitiesUpdated());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMergeOpportunity = async (primaryEmail: string, primaryId: string) => {
    await setPrimaryEmail(primaryEmail);
    setPrimaryId(primaryId);
    setIsOpen(true);
  };

  const handleClick = () => {
    dispatch(setOppertunityId(opportunityData?.uuid));
    dispatch(setOppertunityDomain(opportunity?.prospect_email_domain));
    // const data = jwtEncrypt({
    //   opportunity_id: opportunityData?.uuid,
    //   prospect_email_domain: opportunity?.prospect_email_domain,
    //   brand_id: opportunityData.brand_id,
    //   pipeline_id: pipeline_id,
    // });

    dispatch(
      setOpportunityInboxViewData({
        opportunity_id: opportunityData?.uuid,
        opportunity_domain: opportunity?.prospect_email_domain,
        brand_id: opportunityData.brand_id,
        pipeline_id: pipeline_id,
        prospect_enrich_details: prospect_enrich_details?.[0],
      })
    );

    dispatch(setProspectDetails({ ...opportunityData, opportunity: [opportunity] }));
    dispatch(setSideBarFullView(false));
    navigate(`/crm/inbox?opportunityid=${opportunityData?.uuid}`);
  };
  const dateFormatter = (dateValue: string) => {
    const date = new Date(dateValue);
    // Convert to readable format (e.g., "20 June")
    const readableDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
    });
    return readableDate;
  };

  return (
    <div
      className="relative flex flex-col items-center justify-center"
      id={id}
      draggable
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
    >
      {/* {multicard && (
          <>
            <div
              className="absolute -top-1.5 z-50 flex cursor-pointer justify-center"
              onClick={() => handleCollapse()}
            >
              <p className="z-50 flex items-center gap-2 rounded-lg bg-[#EEF4FF] px-2 py-[0.05rem] text-[0.6rem] font-medium text-[#4318ff]">
                <span>{!viewMore ? <FaCirclePlus /> : <FaCircleMinus />}</span>
              </p>
            </div>
            {viewMultiCard && (
              <div className="absolute top-[5.5rem]  mt-0.5 w-[95%] rounded-md border-b border-[#e7ebf5] pb-3 shadow-bottom" />
            )}
          </>
        )} */}
      <div className=" mt-1 flex w-full gap-2 rounded-md border border-[#e7ebf5] bg-white  px-2.5 py-3  shadow-bottom  dark:border-darkBorder3 dark:bg-darkTwo">
        <div className="pointer-events-none flex flex-col items-center gap-1">
          <Avatar
            style={{ height: '18px', width: '18px', marginTop: '2px' }}
            size={'xs'}
            className="border"
            src={prospect_enrich_details?.[0]?.source_details?.photo_url}
            name={opportunity?.prospect_email?.split('@')[0][0]}
            bg={AvatarColor}
            color={'white'}
          />

          {prospect_enrich_details?.[0]?.source_details?.twitter_url ? (
            <a href={prospect_enrich_details?.[0]?.source_details?.twitter_url} target="_blank" rel="noopener noreferrer">
              <FaXTwitter className="size-3 rounded-full text-paused-row" />
            </a>
          ) : null}
          {prospect_enrich_details?.[0]?.source_details?.linkedin_url ? (
            <div className=" pointer-events-auto rounded-full bg-paused-row px-[1px] py-[1px] ">
              <a href={prospect_enrich_details?.[0]?.source_details?.linkedin_url} target="_blank" rel="noopener noreferrer">
                <TiSocialLinkedin className="size-3  text-white " />
              </a>
            </div>
          ) : null}
          {prospect_enrich_details?.[0]?.source_details?.instagram ? (
            <div className=" rounded-full bg-paused-row px-[2px] py-[2px]">
              <BsInstagram className="size-[10px] text-white dark:text-white" />
            </div>
          ) : null}
        </div>
        <div className="pointer-events-none mt-0.5 flex w-full flex-col gap-0.5">
          <div className="flex w-full justify-between">
            <div className="flex gap-2">
              <h5 className=" text-[0.65rem] font-bold text-heading  dark:text-white">
                {opportunity?.prospect_name ? opportunity?.prospect_name : opportunity?.prospect_email?.split('@')[0]}
              </h5>
              <p className="text-[0.65rem] font-medium text-column dark:text-darkText">{dateFormatter(cdate)}</p>
            </div>
            <div className="flex gap-1">
              <HiOutlineExternalLink
                className={`text-md rounded-full border p-0.5 text-heading  dark:border-darkBorder2  dark:bg-darkThree ${state?.dragged || 'pointer-events-auto'}  dark:text-white`}
              />
              <div className="relative flex">
                <RiMessage2Line className={`text-md rounded-full border p-0.5 text-column ${state?.dragged || 'pointer-events-auto'}`} />
                {opportunity?.latest_message?.date && (
                  <span className="absolute bottom-[0.55rem] left-[0.8rem] flex size-[0.7rem] items-center  justify-center rounded-full bg-[#ede8ff] text-[0.4rem] font-bold text-blueSecondary">
                    {opportunity?.message_total_count}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className="mt-[2px] text-[0.6rem]  text-paused-row dark:text-white">{opportunity?.prospect_email}</p>

          <p className="flex items-center gap-1 text-[0.6rem] font-medium text-heading  dark:text-white " key={id}>
            <span className="font-normal text-paused-row">
              <BiHistory className="dark:text-white" />
            </span>
            {opportunity?.latest_message?.date ? `Mail ${calculateTimeAgo(opportunity?.latest_message?.date)} ` : 'Start new conversation'}
          </p>
          <p className="relative flex w-full justify-between text-[0.6rem] font-medium text-heading  dark:text-white" key={id}>
            Next Task: {task?.cdate ? `task at ${dateFormatter(task?.cdate)}` : 'No upcomming task'}
            {(roleStatus?.edit || roleStatus?.delete || roleStatus?.is_owner) && (
              <Menu placement="top">
                <MenuButton
                  //colorScheme="blueSecondary"
                  // _hover={{ bg: '#F9F5FF' }}
                  // _active={{ bg: '#F9F5FF' }}
                  // bg="#fff"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className={`z-20 cursor-pointer !overflow-hidden border-none !text-sm !font-semibold  card_menu-${id} bg-white hover:bg-white dark:bg-darkTwo`}
                >
                  <IoMdMore
                    //  onClick={() => setIsOpen(true)}
                    className={`pointer-events-auto size-4 text-column  dark:text-white`}
                  />
                </MenuButton>
                <MenuList minW="0" w={'8rem'} className=" pointer-events-auto  !rounded-lg bg-white !py-0 shadow-lg dark:!border-darkBorder2 dark:!bg-darkThree">
                  {(roleStatus?.edit || roleStatus?.is_owner) && (
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMergeOpportunity(opportunity?.prospect_email, opportunityData.uuid);
                      }}
                      className={`merge-card flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674]  hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white dark:!border-darkBorder2  dark:!bg-darkThree dark:!text-white dark:hover:!bg-radioPurple`}
                    >
                      <MdOutlineCallMerge className="text-lg" />
                      <span>Merge</span>
                    </MenuItem>
                  )}
                  {(roleStatus?.delete || roleStatus?.is_owner) && (
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteOpportunity();
                      }}
                      className="flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674]  hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white dark:!border-darkBorder2  dark:!bg-darkThree  dark:!text-white dark:hover:!bg-radioPurple"
                    >
                      <AiFillDelete className="text-lg" />
                      <span>Delete</span>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </p>
        </div>
      </div>
      <MergeCardPopup isOpen={isOpen} onClose={() => setIsOpen(false)} primaryEmail={primaryEmail} primaryId={primaryId} />
    </div>
  );
};

export default Opportunity;
