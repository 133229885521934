import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, Textarea } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, toastState } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import React, { useState } from 'react'
import { IoChevronDown } from 'react-icons/io5';
// import { MdAdd, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

type SubscriptionPlanProps = {
    isOpen: boolean;
    onClose: () => void;
    uuid: string;
    fetchList: () => void;
}

const SubscriptionPlan = (props: SubscriptionPlanProps) => {
    const { isOpen, onClose, uuid, fetchList } = props;
    const [formData, setFormData]: any = React.useState({
        name: '',
        description: '',
        credit_value_list: [
            { price: 0, credit: 0, currency: "USD" },
        ],
        coupon_available: false,
        credit_feature_plans: [
            { credit_feature_id: "12wwwe3r4", feature_name: '', feature_status: true, visible_status: true },
            { credit_feature_id: "vxfd3443", feature_name: '', feature_status: true, visible_status: true },
            { credit_feature_id: "ejrklq432", feature_name: '', feature_status: true, visible_status: true },
        ],
        recommended_plan: false,
        support_level: false,
        api_access: false,
        analytics_access: false
    });

    const [selectAll, setSelectAll] = useState(false);

    const getSubscriptionList = async () => {
        try {
            const response = await BaseApi.get(`/subscription/plan/${uuid}`);
            if (!response?.error) {
                const { name, description, credit_value_list, credit_feature_plans, coupon_available, recommended_plan, support_level, api_access, analytics_access } = response?.data;
                setFormData({
                    name, description, credit_value_list, credit_feature_plans, coupon_available, recommended_plan, support_level, api_access, analytics_access
                })
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    const getCreditPlans = async () => {
        try {
            const response = await BaseApi.get('/credit/feature');
            if (!response?.error) {
                const data = response?.data?.data?.map((item: any) => {
                    return {
                        credit_feature_id: item?.uuid,
                        feature_name: item?.feature_name,
                        feature_status: false,
                        visible_status: false,
                    }
                })
                handleChange({ target: { id: 'credit_feature_plans', value: data } })
            }
            else {
                // toast.error(_errorHandler(response?.message), toastState.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleFeatures = (id: string, selectAll?: boolean, key?: string) => {
        const featurePlans = formData?.credit_feature_plans?.slice();
        let changedFeatures: any;
        if (!id) {
            if (selectAll) {
                changedFeatures = featurePlans.map((item: any) => {
                    return { ...item, visible_status: false }
                })
                setSelectAll(false)
            }
            else {
                changedFeatures = featurePlans.map((item: any) => {
                    return { ...item, visible_status: true }
                })
                setSelectAll(true)
            }
        }
        else {
            changedFeatures = featurePlans?.map((item: any) => {
                if (item?.credit_feature_id === id) {
                    return {
                        ...item,
                        [key]: !item?.[key]
                    }
                }
                else {
                    return item;
                }
            })
            const selectAllFeature = changedFeatures?.every((item: any) => item?.visible_status)
            selectAllFeature ? setSelectAll(true) : setSelectAll(false);
        }
        setFormData((prev: any) => ({ ...prev, credit_feature_plans: changedFeatures }))
    }

    // const handleCreditList = (id: number, key: string, value: string | number) => {
    //     const data = formData?.credit_value_list?.slice();
    //     data[id] = { ...data[id], [key]: value }
    //     setFormData((prev: any) => ({ ...prev, credit_value_list: data }))
    // }

    const handleChange = (event: any) => {
        const { id, value, type } = event.target;
        if (type === 'checkbox') {
            setFormData((prev: any) => ({ ...prev, [id]: !formData?.[id] }));
        }
        else {
            setFormData((prev: any) => ({ ...prev, [id]: value }));
        }
    };

    // const handleDelete = (id: number) => {
    //     const data = formData?.credit_value_list?.slice()
    //     if (data?.length <= 1) return;
    //     console.log(id)
    //     data?.splice(id, 1);
    //     console.log(data)
    //     setFormData((prev: any) => ({ ...prev, credit_value_list: data }))
    // }

    const handleCreate = async () => {
        try {
            const response = await BaseApi.post('/subscription/plan', formData);
            if (!response?.error) {
                fetchList()
                toast.success('Subscription Plan Added', toastState.success);
                onClose()
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleUpdate = async () => {
        try {
            const response = await BaseApi.patch('/subscription/plan', { ...formData, uuid });
            if (!response?.error) {
                fetchList()
                toast.success('Subscription Plan Changed', toastState.success);
                onClose()
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    // const handleStatus = (i: number, key: string) => {
    //     const data = formData?.credit_feature_plans?.slice();
    //     data?.splice(i, 1);
    //     console.log(data)
    //     setFormData((prev: any) => ({ ...prev, credit_feature_plans: data }))
    // }

    React.useEffect(
        () => {
            uuid && getSubscriptionList();
            !uuid && getCreditPlans();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pb={0}>
                    <div className="flex flex-col dark:!text-white text-heading">
                        <span className="text-[1.15rem] font-semibold">Subscription Plan</span>
                        <span className="text-[0.65rem]">Explore subscription options for enhanced user experience</span>
                    </div>
                    <hr className='border-t border-gray-200 dark:border-darkThree mt-4' />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <div className="mb-2 mt-1 flex w-full items-center justify-between gap-2 p-1">
                        <span className="text-sm font-medium dark:text-white w-[7rem]">
                            Name <span className="text-red-500">*</span>
                        </span>
                        <div className="flex w-full gap-3">
                            <InputField
                                autoComplete="off"
                                extra='w-full'
                                customClass="!h-9 !mt-0 w-full"
                                inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                                labelClass="!text-paused-color !font-[500]  !text-xs "
                                placeholder="Plan name"
                                id="name"
                                onChange={handleChange}
                                value={formData?.name || ''}
                                type="text"
                            //   errorMessage={errorMessage?.first_name}
                            //   state={errorMessage?.first_name ? 'error' : ''}
                            />
                        </div>
                    </div>
                    <hr className='border-t border-gray-200 dark:border-darkThree' />

                    <div className="my-4 flex w-full items-center justify-between gap-2 p-1">
                        <span className="text-sm font-medium dark:text-white w-[7rem]">
                            Description <span className="text-red-500">*</span>
                        </span>
                        <div className="flex w-full gap-3">
                            <Textarea
                                className='resize-none placeholder:text-[0.85rem] w-full'
                                autoComplete="off"
                                placeholder="Description"
                                id="description"
                                onChange={handleChange}
                                value={formData?.description || ''}
                            //   errorMessage={errorMessage?.first_name}
                            //   state={errorMessage?.first_name ? 'error' : ''}
                            />
                        </div>
                    </div>
                    <hr className='border-t border-gray-200 dark:border-darkThree' />
                    {/* <div className='flex flex-col py-2 pr-2 w-full max-h-40 overflow-scroll'>
                        {formData?.credit_value_list?.map((item: any, i: number) => {
                            return <div className="my-4 flex w-full items-center justify-between gap-2 py-1" key={`${item?.credit}${i}`}>
                                <span className="text-sm font-medium dark:text-white text-nowrap">
                                    Credit <span className="text-red-500">*</span>
                                </span>
                                <InputField
                                    autoComplete="off"
                                    customClass="!h-9 !mt-0"
                                    inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                                    labelClass="!text-paused-color !font-[500]  !text-xs "
                                    placeholder="Credit"
                                    id="credit"
                                    onChange={(e: any) => handleCreditList(i, 'credit', e.target.value)}
                                    value={item?.credit}
                                    type="number"
                                //   errorMessage={errorMessage?.first_name}
                                //   state={errorMessage?.first_name ? 'error' : ''}
                                />
                                <span className="text-sm font-medium dark:text-white text-nowrap ml-1">
                                    Price <span className="text-red-500">*</span>
                                </span>
                                <InputField
                                    autoComplete="off"
                                    customClass="!h-9 !mt-0"
                                    inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                                    labelClass="!text-paused-color !font-[500]  !text-xs "
                                    placeholder="Price"
                                    id="price"
                                    onChange={(e: any) => handleCreditList(i, 'price', e.target.value)}
                                    value={item?.price}
                                    type="number"
                                //   errorMessage={errorMessage?.first_name}
                                //   state={errorMessage?.first_name ? 'error' : ''}
                                />
                                <Button name='USD' color='text-heading' bg='bg-white' customClass='font-medium' />
                                <button onClick={
                                    () => setFormData((prev: any) => (
                                        {
                                            ...prev, credit_value_list: [...prev.credit_value_list,
                                            { price: 0, credit: 0, currency: "USD" },]
                                        }
                                    ))}>
                                    <MdAdd className='rounded-full bg-blueSecondary text-white size-5 p-1 hover:p-0.5 hover:shadow-shade transition-all' /></button>
                                {formData?.credit_value_list?.length > 1 && <button onClick={() => handleDelete(i)}>
                                    <MdDelete className='rounded-full bg-blueSecondary text-white size-5 p-1 hover:p-0.5 hover:shadow-shade transition-all' /></button>}
                            </div>
                        })}
                    </div> */}

                    {/* <hr className='border-t border-gray-200 dark:border-darkThree' /> */}

                    <div className="my-4 flex w-full items-center justify-between gap-2 p-1">
                        <h5 className="text-sm font-medium dark:text-white w-[7rem]">
                            Features
                        </h5>
                        <div className="flex w-full">
                            <CustomMenu
                                menuClassName='w-full'
                                btnContent={<div className='flex gap-2 items-center whitespace-pre w-full text-[0.85rem] font-thin text-gray-400'>Select</div>}
                                btnClassName='w-full justify-between items-center text-column !h-10'
                                rightIcon={<div className='flex items-center gap-3'><IoChevronDown className='text-[#2B3674] size-3.5 font-medium' />
                                </div>}
                                menuListClassName='w-full !p-0' >
                                <div>
                                    <div className='border-b p-2 flex items-center gap-1 text-xs text-column font-bold'>
                                        <input type='checkbox'
                                            checked={selectAll}
                                            onChange={(e) => handleFeatures('', selectAll)} className='w-fit cursor-pointer' /><span className='w-full'>Select all</span>
                                    </div>
                                    <div className='max-h-32 overflow-auto'>
                                        {formData?.credit_feature_plans?.map((item: any) => {
                                            return <div className='border-b hover:bg-hover p-2 flex items-center gap-1 text-xs text-column font-bold' key={item?.credit_feature_id}>
                                                <input type='checkbox' checked={item?.visible_status} onChange={() => handleFeatures(item?.credit_feature_id, null, 'visible_status')} className='w-fit cursor-pointer' />
                                                <span className='w-full whitespace-pre'>{item?.feature_name}</span>
                                                <span className='p-1 hover:bg-lightPrimary duration-300 rounded-md cursor-pointer text-nowrap'>
                                                    Feature Toggle
                                                </span>
                                                <Switch isChecked={item?.feature_status} size={'sm'} onChange={() => handleFeatures(item?.credit_feature_id, null, 'feature_status')} />
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </CustomMenu>
                        </div>
                    </div>
                    <hr className='border-t border-gray-200 dark:border-darkThree' />

                    <div className="my-4 flex w-full items-center justify-between gap-5 p-1">
                        <h5 className="text-sm font-medium dark:text-white w-[7rem]">
                            Additional Settings <span className="text-red-500">*</span>
                        </h5>
                        <div className="flex w-full">
                            <div className="w-full grid grid-cols-2 gap-3 h-fit">
                                <label className='flex items-center gap-1.5'>
                                    <input type="checkbox" id="coupon_available" checked={formData?.coupon_available} onChange={handleChange} className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer" />
                                    <h5 className='text-[0.85rem] text-heading font-medium text-nowrap'>Coupon Available</h5>
                                </label>
                                <label className='flex items-center gap-1.5'>
                                    <input type="checkbox" id="recommended_plan" checked={formData?.recommended_plan} onChange={handleChange} className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer" />
                                    <h5 className='text-[0.85rem] text-heading font-medium text-nowrap'>Recommended Plan</h5>
                                </label>
                                <label className='flex items-center gap-1.5'>
                                    <input type="checkbox" id="support_level" checked={formData?.support_level} onChange={handleChange} className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer" />
                                    <h5 className='text-[0.85rem] text-heading font-medium text-nowrap'>Support Level</h5>
                                </label>
                                <label className='flex items-center gap-1.5'>
                                    <input type="checkbox" id="api_access" checked={formData?.api_access} onChange={handleChange} className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer" />
                                    <h5 className='text-[0.85rem] text-heading font-medium text-nowrap'>API Access</h5>
                                </label>
                                <label className='flex items-center gap-1.5'>
                                    <input type="checkbox" id="analytics_access" checked={formData?.analytics_access} onChange={handleChange} className="size-[0.9rem] accent-brand-500 dark:accent-purple-600 cursor-pointer" />
                                    <h5 className='text-[0.85rem] text-heading font-medium text-nowrap'>Analytics Access</h5>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr className='border-t border-gray-200 dark:border-darkThree' />

                </ModalBody>

                <ModalFooter>
                    <div className="mx-auto flex w-1/6 items-center gap-3">
                        <Button
                            id="subscriptionPlan-save-btn"
                            name={uuid ? 'Update' : 'Save'}
                            onClick={() => uuid ? handleUpdate() : handleCreate()}
                            customClass="text-[.8125rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out"
                        />
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SubscriptionPlan