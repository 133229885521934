import ContentBox from "./ContentBox";

type GeneratorCompProps = {
  item : any,
}

export const GeneratorComp = (props :GeneratorCompProps) => {
    const {item} = props;
    const nestedItems = (item.items || []).map((nestedItem :any, index :number) => {
      return <GeneratorComp item={nestedItem} key={index}/>;
    });
  
    return (
      <div key={item.id} className="transition-all">
        <ContentBox content = {item} totalCount={10} />
        {nestedItems.length > 0 && <div className="flex justify-center text-center gap-[20px]">{nestedItems}</div>}
      </div>
    );
  };