// DTO - Data Transfer Object

export const SigninFormData = {
    email: '',
    password: '',
    remember: false,
    loading:false,
};

export const SignupFormData = {
    first_name: '',
    last_name: '',
    email: '',
    // mobile: '',
    password: '',
    confirm_password: '',
    loading:false,
};
export const forgotPassData = {
    email: '',
    loading:false,
};
export const resetPassData = {
    password: '',
    confirm_password: '',
    loading:false,
};