import { BiSolidSend } from 'react-icons/bi';
import { BsFillReplyFill } from 'react-icons/bs';
import { FcCancel } from 'react-icons/fc';
import { MdMarkEmailRead } from 'react-icons/md'

type StatsBoxProps = {
    content?: any;
}

const StatsBox = (props: StatsBoxProps) => {
    const { content } = props;
    return (
        <div className='absolute left-1/2 -translate-x-1/2 -top-10 shadow-shadeFull bg-white rounded-md h-9 w-[calc(100%+1rem)] flex items-center justify-around'>
            <div className='flex items-center gap-0.5'>
                <BiSolidSend className='text-[#00D8B6] size-3.5' />
                <h5 className='text-[0.85rem] text-[#1E1E1E]'>{content?.overall_stats?.sent_count}</h5>
            </div>
            <div className='flex items-center gap-0.5'>
                <MdMarkEmailRead className='text-[#008FFB] size-3.5' />
                <h5 className='text-[0.85rem] text-[#1E1E1E]'>{content?.overall_stats?.open_count}</h5>
            </div>
            <div className='flex items-center gap-0.5'>
                <BsFillReplyFill className='text-[#FFB01A] size-3.5' />
                <h5 className='text-[0.85rem] text-[#1E1E1E]'>{content?.overall_stats?.replied_count}</h5>
            </div>
            <div className='flex items-center gap-0.5'>
                <FcCancel className='text-[#775DD0] size-3.5' />
                <h5 className='text-[0.85rem] text-[#1E1E1E]'>{content?.overall_stats?.unsubscribed_count}</h5>
            </div>
        </div>
    )
}

export default StatsBox