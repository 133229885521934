import useClickOutsideHook from 'common/hooks/useClickOutsideHook';
import { formatDateIntoDDMYYYY, getDateRange } from 'common/utils/utility';
import MiniCalendar from 'components/calendar/MiniCalendar'

type DateRangeSelectorProps = {
    selectedDate: any;
    setSelectedDate: (val: any) => void;
    setShowCalendar: (val: boolean) => void;
    right?: string;
    top?: string
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const { selectedDate, setSelectedDate, setShowCalendar, right, top } = props
    const ref = useClickOutsideHook(() => setShowCalendar(false))

    const handleCalendarDate = (range: string) => {
        if (range === 'all') {
            setSelectedDate([new Date('1970-01-01T00:00:00.000Z'), new Date()])
            return
        }
        const dateRange = getDateRange(range)
        dateRange && setSelectedDate(dateRange)
    }

    // const handleDateChange = () => {
    //     setSelectedDate(localDate)
    //     setShowCalendar(false)
    // }
    return (
        <div className={`z-10 absolute ${top || 'top-0'} ${right || 'right-[7.2rem]'} border border-[#EAECF0] rounded-md shadow-bottom flex bg-white cursor-pointer`} ref={ref}>
            <div className='border-[1px] border-[#EAECF0] p-3 w-32 flex flex-col gap-2.5 cursor-pointer'>
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('today')}>Today</h5>
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('yesterday')}>Yesterday</h5>
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('this_week')}>This week</h5>
                {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('last_week')}>Last week</h5> */}
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('this_month')}>This month</h5>
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('last_month')}>Last month</h5>
                {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('this_year')}>This year</h5> */}
                {/* <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('last_year')}>Last year</h5> */}
                <h5 className='px-3 font-medium text-[0.76rem] text-heading hover:font-semibold hover:bg-[#f3f3f5] rounded-sm' onClick={() => handleCalendarDate('all')}>All time</h5>
                <div className='flex flex-col justify-center items-center p-3 gap-2.5 w-full text-column'>
                    <div className='border border-[#7090B04F] rounded-md px-1 py-1 w-full'>
                        <h5 className='text-[0.7rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.[0])}</h5>
                    </div>
                    <div className='border border-[#7090B04F] rounded-md px-1 py-1 w-full'>
                        <h5 className='text-[0.7rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.[1])}</h5>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-1 py-1'>
                {/* <h5 className='text-sm font-semibold text-heading px-3 pt-2 pb-1'>Date Range</h5> */}
                {/* <div className='border border-[#7090B04F] rounded-md px-2 py-1 mx-3 w-42'>
                        <h5 className='text-[0.8rem] font-medium text-column'>Custom Range</h5>
                    </div>
                <div className='flex justify-center items-center p-3 gap-2.5 w-full text-column'>
                    <div className='border border-[#7090B04F] rounded-md px-2 py-1 w-full'>
                        <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.from)}</h5>
                    </div>
                    <div className='border border-[#7090B04F] rounded-md px-2 py-1 w-full'>
                        <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.to)}</h5>
                    </div>
                </div> */}
                <MiniCalendar range={true} width='w-[250px]'
                    value={selectedDate}
                    onChange={(val: any) => {
                        setSelectedDate(val)
                    }
                    } />
                {/* <MiniCalendar width='w-[250px]' parentClassName='border border-[1px] border-[#EAECF0]' value={localDate.to} onChange={(val: any) => setLocalDate((prev: any) => ({ ...prev, to: val }))} /> */}
                {/* <div className='flex justify-between border-[1px] border-[#EAECF0]'>
                    <div className='flex items-center p-3 gap-2'>
                        <div className='border border-[#7090B04F] rounded-md px-2 py-1'>
                            <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.from)}</h5>
                        </div>
                        <span>-</span>
                        <div className='border border-[#7090B04F] rounded-md px-2 py-1'>
                            <h5 className='text-[0.8rem] font-medium'>{formatDateIntoDDMYYYY(selectedDate?.to)}</h5>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center p-3'>
                        <Button name='Cancel' color='heading' bg='white' customClass='px-2 font-medium' onClick={()=> setShowCalendar(false)}/>
                        <Button name='Apply' customClass='px-3 font-medium' onClick={handleDateChange} />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DateRangeSelector