import { SideBarType } from 'common/dto/types/views/sideBar';
import { AiOutlineSafety } from 'react-icons/ai';
import { BiSolidMessageCheck, BiShuffle} from 'react-icons/bi';
import { BsInbox, BsPersonCheck, BsSend } from 'react-icons/bs';
import { FaCheckToSlot } from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';
import { MdOutlineNotifications, MdOutlineBrowserNotSupported, MdCampaign, MdMarkEmailRead, MdAdminPanelSettings } from 'react-icons/md';
import { PiFlowArrowBold} from 'react-icons/pi';
import { RiBillLine, RiTeamLine } from 'react-icons/ri';
import { SiAlwaysdata } from 'react-icons/si';
// import { TbMapDiscount } from 'react-icons/tb';

export const settingsSideBarData: SideBarType[] = [
  {
    key: 'personal_details',
    name: 'Personal details',
    menu_list_id: 'personal_details',
    path: '/settings/personal/details',
    icon: <BsPersonCheck className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'billing',
    name: 'Billing',
    menu_list_id: 'billing',
    path: '/settings/billing',
    icon: <RiBillLine className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'security',
    name: 'Security',
    menu_list_id: 'security',
    path: '/settings/security',
    icon: <AiOutlineSafety className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'team',
    name: 'Team',
    menu_list_id: 'team',
    path: '/settings/team',
    icon: <RiTeamLine className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'notifications',
    name: 'Notifications',
    menu_list_id: 'notifications',
    path: '/settings/notifications',
    icon: <MdOutlineNotifications className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'do_not_email_lists',
    name: 'Do-not-Email Lists',
    menu_list_id: 'do_not_email_list',
    path: '/settings/donot/list',
    icon: <MdOutlineBrowserNotSupported className={`size-6`} />,
    nestedData: [],
  },
  // {
  //   key: 'rewards',
  //   name: 'Rewards',
  //   menu_list_id: 'rewards',
  //   path: '/settings/rewards',
  //   icon: <TbMapDiscount className={`size-6`} />,
  //   nestedData: [],
  // },
  {
    key: 'api',
    name: 'API',
    menu_list_id: 'api',
    path: '/settings/api',
    icon: <MdOutlineBrowserNotSupported className={`size-6`} />,
    nestedData: [],
  },
];

export const homeSideBarData: SideBarType[] = [
  {
    key: 'campaigns',
    name: 'Campaigns',
    menu_list_id: 'campaigns',
    path: '/campaigns',
    icon: <MdCampaign className={`size-6`} />,
    nestedData: [],
  },
  // {
  //   key: 'campaign_insights',
  //   name: 'Campaign Insights',
  //   menu_list_id: 'campaign_insights',
  //   path: '/campaigns/insights',
  //   icon: <BsInboxFill className={`size-6`} />,
  //   nestedData: [],
  // },
  {
    key: 'deliverability',
    name: 'Deliverability',
    menu_list_id: 'deliverability_blacklist',
    path: '',
    icon: <MdMarkEmailRead className={`size-6`} />,
    nestedData: [
      // { key: 'domains', name: 'Domains', path: '/domain' },
      // { key: 'reports', name: 'Reports', path: '/reports' },
      { key: 'blacklist', name: 'Blacklists', path: '/blacklist-monitor' },
    ],
  },
  {
    key: 'sender_accounts',
    name: 'Sender Accounts',
    menu_list_id: 'sender_accounts',
    path: '/sender-accounts',
    icon: <BsSend className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'email_verifier',
    name: 'Email Verifier',
    menu_list_id: 'email_verifier',
    path: '/email-verifier',
    icon: <IoMdMail className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'true_verifier',
    name: 'True Verifier',
    menu_list_id: 'true_verifier',
    path: '/true-verifier',
    icon: <FaCheckToSlot className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'live',
    name: 'Live',
    menu_list_id: 'live',
    path: '/live',
    icon: <SiAlwaysdata className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'crm',
    name: 'CRM',
    menu_list_id: 'crm',
    path: '/crm',
    icon: <PiFlowArrowBold className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'inbox',
    name: 'Inbox',
    menu_list_id: 'inbox',
    path: '/inbox',
    icon: <BsInbox className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'brands',
    name: 'Brands',
    menu_list_id: 'brands',
    path: '/brands',
    icon: <BiSolidMessageCheck className={`size-6`} />,
    nestedData: [],
  },
  {
    key: 'unmatched',
    name: 'Unmatched',
    menu_list_id: 'unmatched',
    path: '/unmatched-emails',
    icon: <BiShuffle className={`size-6`} />,
    nestedData: [],
  },

  // {
  //   key: 'credits_settings',
  //   name: 'Credits',
  //   menu_list_id: 'credits_settings',
  //   path: '/credits/settings',
  //   icon: <PiHandCoinsDuotone className={`size-6`} />,
  //   nestedData: [],
  // },

  {
    key: 'admin',
    name: 'Admin',
    menu_list_id: 'app_admin',
    path: '',
    icon: <MdAdminPanelSettings className={`size-6`} />,
    nestedData: [
      { key: 'admin_tickets', name: 'Admin Tickets', path: '/admin-tickets' },
      { key: 'admin_accounts', name: 'Admin Accounts', path: '/admin-accounts' },
      { key: 'admin_settings', name: 'Admin Settings', path: '/admin-settings' },
      { key: 'coupon_settings', name: 'Coupon Settings', path: '/admin/coupon' },
      { key: 'subscription_plan', name: 'Subscription', path: '/subscription-plan' },
      { key: 'credits_settings', name: 'Credits', path: '/credits/settings' },
      { key: 'credits_addons', name: 'Credits Addons', path: '/credit-addons' },
      { key: 'rolemenu_list', name: 'Menu List', path: '/rolemenu-list' },





      // { key: 'coupon', name: 'Coupon', path: 'admin/coupon' },
    ],
  },
  // {
  //   key: 'coupon_settings',
  //   name: 'Coupon Settings',
  //   menu_list_id: 'coupon_settings',
  //   path: '/admin/coupon',
  //   icon: <BiSolidDiscount className={`size-6`} />,
  //   nestedData: [],
  // },
  // {
  //   key: 'subscription_plan',
  //   name: 'Subscription',
  //   menu_list_id: 'subscription_plan',
  //   path: '/subscription-plan',
  //   icon: <MdOutlineSubscriptions className={`size-6`} />,
  //   nestedData: [],
  // },
];
