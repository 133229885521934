import { useEffect, useRef } from 'react'

const useClickOutsideHook = (callback: () => void) => {
    const ref = useRef(null)
    useEffect(
        () => {
            const releaseFocus = (e: any) => {
                if (ref?.current?.previousSibling.contains(e.target)) {
                    return ``
                }
                else if (!ref?.current?.contains(e.target)) {
                    callback()
                }
            }
            document.addEventListener('mousedown', releaseFocus)
            return () => document.removeEventListener('mousedown', releaseFocus)
        }
    )

    return ref
}

export default useClickOutsideHook