import useClickOutsideHook from 'common/hooks/useClickOutsideHook'
import MiniCalendar from 'components/calendar/MiniCalendar'


type setTimeProps = {
    setDateModal: (value: boolean) => void,
    value : Date,
    onChange : (value: Date) => void,
}
const SetDate = (props: setTimeProps) => {
    const { setDateModal, onChange, value } = props

    const modalRef = useClickOutsideHook(() => { setDateModal(false) })
    return (
        <div className={`absolute left-8 top-8 z-10 border border-blueSecondary bg-white rounded-md w-[18rem]`} ref={modalRef}>
           <MiniCalendar value={new Date(value)} onChange={onChange}/>
        </div>
    )
}

export default SetDate