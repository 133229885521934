import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FiSearch } from 'react-icons/fi';
import { useRef } from 'react';
import React from 'react';
import CheckBox from 'common/sparkle-common/MultiSelectCheckBox.tsx';
import { TrueVerifierRowObject } from 'common/dto/types/views/true-verfier';
import RowSettings from './features/RowSettings';
import { GoHourglass } from 'react-icons/go';
import { MdCheck } from 'react-icons/md';
import { setGlobalOpt, setTableRowsList } from 'store/custom';
import { useDispatch } from 'react-redux';
import { setTicketDetails, setTicketId, showTicketDetailsStatus } from 'store/true-verifier';
import TicketDetails from './features/TicketDetails';
import { BaseApi } from 'common/api/common/base-api';
import TicketCreationComponent from './features/TicketCreation';
import { useAppSelector } from 'store';
import TableLoader from 'components/Loaders/TableViewLoader';
import ArrowedTooltip from 'components/ArrowedTooltip';
import moment from 'moment';
import { calculateTimeAgo, setAndGetQueryParams } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';

// TrueVerifier API Keys ---------------
// const TrueVerifyData = {
//     uuid: randomString(),
//     ticketId: '2024/01/A',
//     subject: 'The list has some 10 emails which are from the user it has been given that they want',
//     message: 'The list has some 10 emails which are from the user it has been given that they want The list has some 10 emails which are from the user it has been given that they want',
//     emails: [{ id: '1', email: 'dhsanjdnfkj@dfkan.dasf' }, { id: '2', email: 'dhsanjdnfkj@dfkan.dasf' }],
//     createdOn: 'Today',
//     status: Math.floor(Math.random() * (2 - 0 + 1)) + 0,
// }
// -------------------------------

const AdminTicketTable = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });

  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });

  const dispatch = useDispatch();
  const { ticketDrawerToggle, ticketId, ticketDetailsStatus } = useAppSelector((state) => state?.trueVerifier);
  const [columns, setColumns] = React.useState([]);
  const searchRef = useRef(null);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const custom = useAppSelector((state) => state?.custom);

  const columnHelper = createColumnHelper<TrueVerifierRowObject>();

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const onSelect = (data: string[]) => {
    dispatch(setTableRowsList(data));
  };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-5 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-5 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <CheckBox onSelect={onSelect} info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('ticket_id', {
      id: 'ticket_id',
      enableSorting: false,
      header: () => <div>Ticket ID</div>,
      cell: (info) => <h5 className="text-xs">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('user_email', {
      id: 'user_email',
      enableSorting: false,
      header: () => <div>Email</div>,
      cell: (info) => <h5 className="truncate text-xs">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('firstname', {
      id: 'firstname',
      enableSorting: false,
      header: () => <div>Name</div>,
      cell: (info) => <h5 className="text-xs">{info.getValue() + ' ' + info?.row?.original?.lastname}</h5>,
    }),
    columnHelper.accessor('subject', {
      id: 'subject',
      header: () => <div>Subject</div>,

      cell: (info) => {
        return (
          <h5 className="w-80 truncate text-xs" id={`subject-${info.row.id}`}>
            {info.getValue()}
          </h5>
        );
      },
    }),

    columnHelper.accessor('variant', {
      id: 'variant',
      header: () => <div>Variant</div>,

      cell: (info) => {
        const text = info.getValue()?.replace(/_/g, ' ');
        return (
          <h5 className="text-xs capitalize" id={`variant-${info.row.id}`}>
            {text}
          </h5>
        );
      },
    }),

    columnHelper.accessor('ticket_created_date', {
      id: 'ticket_created_date',
      header: () => <div className="text-start">Created on</div>,

      cell: (info) => {
        const utcDateString = info.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));
        return (
          <div className="group relative w-[5rem] text-sm font-semibold" id="table-click">
            <span className={` row-cdate-${info?.row?.id}`}>{calculateTimeAgo(info.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('ticket_status', {
      id: 'ticket_status',
      header: () => <div>Status</div>,

      cell: (info) => (
        <div id="table-click">
          {
            <>
              {info.getValue() === 0 && (
                <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-[#EFF8FF] px-2 py-0.5">
                  <GoHourglass className="text-xs text-[#175CD3]" />
                  <h5 className={`text-xs font-semibold text-[#175CD3] `}>In progress</h5>
                </div>
              )}

              {info.getValue() === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-0.5">
                  <MdCheck className="text-xs text-completed-color" />
                  <h5 className={`text-xs font-semibold text-completed-color `}>Completed</h5>
                </div>
              )}
              {info.getValue() === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-orange-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-orange-500" />
                  <h5 className={`text-xs font-semibold text-orange-500 `}>Reopened</h5>
                </div>
              )}
              {info.getValue() === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-red-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-red-500" />
                  <h5 className={`text-xs font-semibold text-red-500 `}>Cancelled</h5>
                </div>
              )}
            </>
          }
        </div>
      ),
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return <div className=" ml-4">{<RowSettings id={row.row.id} uuidRow={row.getValue()} rowData={row.row.original} table={table} setData={setData} />}</div>;
      },
    }),
  ];

  React.useEffect(() => {
    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // Fetch true verifier list
  const fetchList = async () => {
    // try {
    //   setColumns(columnData);
    //   const response = await BaseApi.get(`/true/verify/internal/user/list`);
    //   if (!response?.error) {
    //     const responseData = response?.data?.length === 0 ? [] : response?.data?.data;
    //     setData({
    //       data: responseData,
    //       count: response?.data?.total_records,
    //     });
    //   }
    // } catch (err) {
    //   console.log(err, 'Error');
    // }

    try {
      // setLoading(true);
      // setHandleState((prevState) => ({ ...prevState, loading: true }));
      setColumns(columnData);

      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        // { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
        {
          key: 'sort',
          value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
        },
        { key: 'filter', value: queryParams?.filter },
      ]);
      const brandListResponse = await BaseApi.get(`/true/verify/internal/user/list?${query}`);
      brandListResponse?.data?.data?.length
        ? setData({ ...data, data: brandListResponse?.data?.data, count: brandListResponse?.data?.total_records?.count })
        : setData({ ...data, data: [], count: 0 });
      table.resetRowSelection();
      // setLoading(false);
      // setHandleState((prevState) => ({ ...prevState, loading: false }));

      setQueryParams((prev) => ({
        ...prev,
        offset: 2,
      }));
    } catch (error) {
      console.error('Error From Brand Table Page Catch block', error);
    }
  };
  React.useEffect(() => {
    (async () => {
      setTableLoading(true);
      await fetchList();
      setTableLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  // Fetch true verifier ticket list when creating new ticket
  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDrawerToggle, ticketDetailsStatus, custom?.globalDependencyLoading]);

  const handleChat = (row: any) => {
    dispatch(setTicketId(row?.uuid));
    const createdDate = moment(row?.ticket_created_date).format('DD/MM/YYYY');
    const closedDate = row?.ticket_closed_date ? moment(row?.ticket_created_date).format('DD/MM/YYYY') : row?.ticket_closed_date;
    const details = {
      ticket_id: row?.ticket_id,
      status: row?.ticket_status,
      closed_date: closedDate,
      created_date: createdDate,
      name: `${row.firstname} ${row.lastname}`,
    };
    dispatch(setTicketDetails(details));
    dispatch(showTicketDetailsStatus(true));
  };

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/true/verify/internal/user/list?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                type="text"
                placeholder="Search"
                id="campaign-search-input"
                className="w-full text-sm font-bold
                             text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-gray-200 dark:placeholder:text-white "
              />
            </div>
          </div>
        </div>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
          {!tableLoading ? (
            <>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px]">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      const clicked = row.getVisibleCells()[0]?.row?.original?.uuid === ticketId;
                      return (
                        <tr
                          key={row.id}
                          onClick={() => handleChat(row.getVisibleCells()[0]?.row?.original)}
                          className={`cursor-pointer hover:bg-hover dark:hover:bg-darkOne ${clicked ? 'bg-hover' : ''}`}
                        >
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = ['w-[5%]', 'w-[20%]', 'w-[30%]', 'w-[15%]', 'w-[14%]', 'w-[13%]', 'w-[3%]'];
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} border-white/0 py-3 text-xs font-semibold text-heading dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={6}
              row={13}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 25% 22% 22% 25% 4%"
            />
          )}
        </div>
      </div>
      <TicketDetails />

      {/* New ticket creation drawer */}
      <TicketCreationComponent />
    </>
  );
};

export default AdminTicketTable;
