import React from 'react'

interface PropsType {
    id?: string;
    customClass?: string;
    checked?: boolean;
    onChange?: (event: any) => void;
}

const SparkleCheckBox = (props: PropsType) => {
    const { id, customClass, checked, onChange } = props
    return (
        <input type="checkbox"
            id={id}
            checked={checked}
            className={`size-3 accent-brand-500 dark:accent-purple-600 cursor-pointer ${customClass || ''}`}
            onChange={onChange}
        />
    )
}

export default SparkleCheckBox