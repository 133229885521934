/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Menu, MenuButton, MenuItem, MenuList, SkeletonText } from '@chakra-ui/react';
import { IoMail } from 'react-icons/io5';
import GroupTags from './GroupTags';
import { LuCornerUpRight } from 'react-icons/lu';
import { AiFillDelete } from 'react-icons/ai';
import saveAs from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import moment from 'moment';
import { CgFile } from 'react-icons/cg';
import SearchBox from 'components/searchBox';
import UploadSingleLead from 'components/crm/UploadSingleLead';
import { BsReply, BsReplyAll } from 'react-icons/bs';
import Typer from 'views/crm/features/inbox/Communication/Typer';
import { useAppDispatch } from 'store';
import { setReplyThreadData } from 'store/crm';
import { toast } from 'react-toastify';
import { base64Decode, calculateTimeAgo, setAndGetQueryParams, toastState } from 'common/utils/utility';
import React from 'react';
import MergeConfrimPopUp from './MergeConfrimPopUp';
import { setMergeSearch } from 'store/unmatched';
import { jwtDecode } from 'jwt-decode';
import { TiArrowForwardOutline } from 'react-icons/ti';

interface ListRowTypes {
  data: any;
  index: number;
  updateIgnoreStatus: (e: any, status: string, uuid: string, ignored_contact?: string) => void;
  mergeOption?: Array<any>;
  count: number;
  avatarColor: string;
  roleStatus: any;
}
const ListRow = (props: ListRowTypes) => {
  const { data, index, updateIgnoreStatus, count, avatarColor, roleStatus } = props;
  // const { mergeSearch } = useAppSelector((state) => state.unmatched);
  const [isLoading, setIsLoading] = useState(false);
  const [readMessages, setReadMessages] = useState([]);
  const typerRef = useRef<HTMLDivElement>(null);
  const [rowIndex, setRowIndex] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailData, setEmailData] = useState<any>({ loading: true, profile: {} });
  const [showTyper, setShowTyper] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [primaryMergeData, setPrimaryMergeData] = useState({});
  const [secondaryMergeData, setSecondaryMergeData] = useState({});
  const [mergeOption, setMergeOption] = useState([]);
  // const debounceMergeSearch = useDebounce(mergeSearch, 300);
  const colors = ['#4318FF', '#E7C100', '#7F56D9', '#7E4400', '#12B76A'];
  function getRandomIndex(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data.read_history.is_viewed) {
      setReadMessages((prev) => [...prev, data?.uuid]);
    }
  }, [data.uuid]);

  useEffect(() => {
    if (showTyper && typerRef.current) {
      typerRef.current.scrollIntoView({ behavior: 'smooth' });
      typerRef.current.focus();
    }
  }, [showTyper]);

  const formatIndianDateTime = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString('en-IN', options).replace(',', '');

    return `${formattedDate}`;
  };
  // const fetchDbEmails = async () => {
  //   try {
  //     setIsLoading(true);
  //     setEmailData((prev: any) => ({ ...prev, loading: true }));
  //     const response = await BaseApi.get(`unmatched/inbox/thread/${data?.uuid}`);
  //     if (response?.data) {
  //       const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
  //       const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
  //       let message: string;
  //       if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
  //         message = response.data?.map((item: any) => {
  //           const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
  //             ?.map((attachment: any) => {
  //               if (attachment?.filename) {
  //                 return attachment;
  //               } else {
  //                 return null;
  //               }
  //             })
  //             .filter(Boolean);
  //           return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
  //         });
  //         // message = inbox_mail_data?.text
  //       } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
  //         message = response?.data?.map((item: any) => {
  //           const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
  //           const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
  //           // const fullMessage = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
  //           const fullMessage = base64String ? base64Decode(base64String) : base64String2 ? base64Decode(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
  //           const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
  //             ?.slice(1)
  //             .map((attachment: any) => {
  //               if (attachment?.filename) {
  //                 return {
  //                   filename: attachment.filename,
  //                   contentType: attachment.mimeType,
  //                   content: attachment.body?.content,
  //                   size: attachment.body?.size,
  //                 };
  //               } else {
  //                 return null;
  //               }
  //             })
  //             .filter((attachment: any) => attachment !== null);
  //           return { fullMessage, attachment, ...item };
  //         });
  //         // message = decodeURIComponent(atob(base64String))
  //       }
  //       const profile = {
  //         name,
  //         receiver_email: response.data[0]?.platform_message_details?.from?.address,
  //         subject: response.data[0]?.platform_message_details?.subject,
  //         message,
  //         date,
  //       };
  //       setEmailData((prev: any) => ({ ...prev, profile }));
  //     }
  //   } catch (error) {
  //     console.log('Unmatched email fetching error', error);
  //   } finally {
  //     setIsLoading(false);
  //     setEmailData((prev: any) => ({ ...prev, loading: false }));
  //   }
  // };

  const fetchDbEmails = async () => {
    try {
      setIsLoading(true);
      setEmailData((prev: any) => ({ ...prev, loading: true }));
      const response = await BaseApi.get(`unmatched/inbox/thread/${data?.uuid}`);
      if (response?.data) {
        const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
        const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
        let message: string;
        if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
          message = response.data?.map((item: any) => {
            const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
              ?.map((attachment: any) => {
                if (attachment?.filename) {
                  return attachment;
                } else {
                  return null;
                }
              })
              .filter(Boolean);
            return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
          });
          // message = inbox_mail_data?.text
        } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
          message = response?.data?.map((item: any) => {
            const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
            const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
            // const fullMessage = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
            const fullMessage = base64String ? base64Decode(base64String) : base64String2 ? base64Decode(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
            const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
              ?.slice(1)
              .map((attachment: any) => {
                if (attachment?.filename) {
                  return {
                    filename: attachment.filename,
                    contentType: attachment.mimeType,
                    content: attachment.body?.content,
                    size: attachment.body?.size,
                  };
                } else {
                  return null;
                }
              })
              .filter((attachment: any) => attachment !== null);
            return { fullMessage, attachment, ...item };
          });
          // message = decodeURIComponent(atob(base64String))
        }
        const profile = {
          name,
          receiver_email: response.data[0]?.platform_message_details?.from?.address,
          subject: response.data[0]?.platform_message_details?.subject,
          message,
          date,
        };
        setEmailData((prev: any) => ({ ...prev, profile }));
      }
    } catch (error) {
      console.log('Unmatched email fetching error', error);
    } finally {
      setIsLoading(false);
      setEmailData((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const downloadFile = (attachment: any) => {
    if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
      const byteArray = new Uint8Array(attachment.content.data);
      const blob = new Blob([byteArray], { type: attachment.contentType });
      const filename = attachment.filename || 'attachment';
      saveAs(blob, filename);
    } else {
      console.error('Invalid attachment data');
    }
  };
  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        if (!emailData?.profile?.receiver_email) {
          fetchDbEmails();
        }
        return [...prevArr, index];
      }
    });
  };

  const handleAccordionClick = (event: React.MouseEvent, index: number) => {
    // Prevent toggling if the click is on the GroupTags component
    const targetElement = event.target as Element;
    if (!targetElement.closest('.group-tags-unmatched') && !targetElement.closest('.match-search-unmatched')) {
      toggleIndexInArray(index);
    }
  };

  // const handleReplyold = async (item: any, sender_id: string) => {
  //   const smtpPattern = /<[^>]+@[^>]+>/;
  //   let payload;

  //   const findSenderEmail = await BaseApi.get(`/sender-accounts/email/list/${sender_id}`);
  //   const senderDataDecode: any = jwtDecode(findSenderEmail?.data);
  //   const senderEmail = senderDataDecode?.account_details?.email;
  //   const matchingFromAddresses = item?.inbox_mail_data?.to?.value?.filter((addressObj: any) => addressObj.address === senderEmail);
  //   console.log(matchingFromAddresses, 'matchingFromAddresses');
  //   if (item.type === 'sender') {
  //     if (smtpPattern.test(item?.main_message_id)) {
  //       payload = {
  //         from: item?.inbox_mail_data?.from?.value,
  //         to: item?.inbox_mail_data?.to?.value,
  //         delivered_to: item.delivered_to,
  //         main_message_id: item.main_message_id,
  //         subject: item.subject,
  //         sender_id: sender_id,
  //         type: 'reply',
  //       };
  //     } else {
  //       payload = {
  //         main_message_id: item.main_message_id,
  //         from: item.from,
  //         to: item.to,
  //         delivered_to: item.delivered_to,
  //         // cc: [],
  //         // bcc: [],
  //         subject: item.subject,
  //         sender_id: sender_id,
  //         type: 'reply',
  //         individual_message_id: item?.oauth_delivery_message_id,
  //       };
  //     }
  //   } else {
  //     if (smtpPattern.test(item?.main_message_id)) {
  //       payload = {
  //         main_message_id: item.main_message_id,
  //         from: item?.inbox_mail_data?.to?.value,
  //         to: item?.inbox_mail_data?.from?.value,
  //         delivered_to: item.delivered_to,
  //         subject: item.subject,
  //         sender_id: sender_id,
  //         type: 'reply',
  //       };
  //     } else {
  //       payload = {
  //         main_message_id: item.main_message_id,
  //         from: item.to,
  //         to: item.from,
  //         delivered_to: item.delivered_to,
  //         subject: item.subject,
  //         sender_id: sender_id,
  //         type: 'reply',
  //         individual_message_id: item?.oauth_delivery_message_id,
  //       };
  //     }
  //   }
  //   // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
  //   setShowTyper(true);
  //   dispatch(setReplyThreadData(payload));
  // };

  const handleReply = async (item: any, sender_id: string) => {
    // const smtpPattern = /<[^>]+@[^>]+>/;
    const createPayload = (from: any, to: any, item: any, sender_id: string) => ({
      main_message_id: item.main_message_id,
      from,
      to,
      delivered_to: item.delivered_to,
      subject: item.subject,
      sender_id,
      type: 'reply',
      individual_message_id: item?.oauth_delivery_message_id || item?.individual_message_id || '',
    });
    const getSenderEmail = async (sender_id: string) => {
      try {
        const response = await BaseApi.get(`/sender-accounts/email/list/${sender_id}`);
        if (!response.error) {
          const senderDataDecode: any = await jwtDecode(response.data);
          return senderDataDecode?.account_details?.email;
        }
        return null;
      } catch (error) {
        console.error(error);
      }
    };

    let payload;
    if (item.type === 'sender') {
      if (item?.email_type === 'smtp_imap') {
        payload = createPayload(item?.inbox_mail_data?.from?.value, item?.inbox_mail_data?.to?.value, item, sender_id);
      } else {
        payload = createPayload(item.from, item.to, item, sender_id);
      }
    } else {
      if (item?.email_type === 'smtp_imap') {
        if (item?.inbox_mail_data?.to?.value?.length > 1) {
          const senderEmail = await getSenderEmail(sender_id);
          if (senderEmail) {
            const matchingFromAddresses = await item?.inbox_mail_data?.to?.value?.filter((addressObj: any) => addressObj.address === senderEmail);
            // console.log(matchingFromAddresses, 'matchingFromAddresses');
            payload = createPayload(matchingFromAddresses, item?.inbox_mail_data?.from?.value, item, sender_id);
          }
        } else {
          payload = createPayload(item?.inbox_mail_data?.to?.value, item?.inbox_mail_data?.from?.value, item, sender_id);
        }
      } else {
        if (item.to?.length > 1) {
          const senderEmail = await getSenderEmail(sender_id);
          if (senderEmail) {
            const matchingFromAddresses = await item?.to.filter((addressObj: any) => addressObj.address === senderEmail);
            payload = createPayload(matchingFromAddresses, item?.from, item, sender_id);
          }
        } else {
          payload = createPayload(item?.to, item?.from, item, sender_id);
        }
      }
    }
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
  };

  const handleMainReply = (item: any, sender_id: string) => {
    // const smtpPattern = /<[^>]+@[^>]+>/;
    let payload;
    //item?.inbox_mail_data?.cc?.value ? item?.inbox_mail_data?.cc?.value :
    // item?.inbox_mail_data?.bcc?.value ? item?.inbox_mail_data?.bcc?.value :
    if (item.type === 'sender') {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          from: item?.from,
          to: item?.to,
          delivered_to: item.delivered_to,
          individual_message_id: item.individual_message_id,
          main_message_id: item.main_message_id,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.from,
          to: item.to,
          delivered_to: item.delivered_to,
          // cc: [],
          // bcc: [],
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    } else {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          main_message_id: item.main_message_id,
          from: item?.to,
          to: item?.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.to,
          to: item.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
  };

  const handleReplyAll = (data: any, sender_id: string) => {
    setShowTyper(false);
    let payload;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.from?.value || [data?.from],
          to: data?.inbox_mail_data?.to?.value || [data?.to],
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          main_message_id: data?.main_message_id,
          individual_message_id: data?.individual_message_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: data?.to,
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'reply',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.to?.value || data?.to,
          to: data?.inbox_mail_data
            ? [...data?.inbox_mail_data?.from?.value, ...data?.inbox_mail_data?.to?.value?.slice(1, data?.inbox_mail_data?.to?.value?.length)]
            : [data?.from, ...data?.to?.slice(1, data?.to?.length)],
          delivered_to: data?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [data?.from, ...(data?.to?.length > 1 ? data?.to.slice(1) : [])],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);
    dispatch(setReplyThreadData(payload));
    setShowTyper(true);
  };

  const handleForward = (data: any, sender_id: string, message: any) => {
    setShowTyper(false);
    let payload;
    // const smtpPattern = /<[^>]+@[^>]+>/;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        const toEmails = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');

        payload = {
          from: data?.inbox_mail_data?.from?.value,
          to: [],
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          main_message_id: data?.main_message_id,
          message: '',
          individual_message_id: data?.individual_message_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmails}<br><br>`,
        };
      } else {
        const toEmailoriginalData = Array.isArray(data?.to?.value) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: [],
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmailoriginalData}<br><br>`,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        const toEmaildata = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
        payload = {
          from: data?.inbox_mail_data?.to?.value,
          to: [],
          delivered_to: data.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          message: '',
          sender_id: sender_id,
          individual_message_id: data?.individual_message_id,
          main_message_id: data?.main_message_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildata}<br><br>`,
        };
      } else {
        const toEmaildoauth = Array.isArray(data?.to) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          sender_id: sender_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.platform_message_details?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmaildoauth}<br><br>`,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);

    dispatch(setReplyThreadData(payload));
    setTimeout(() => {
      setShowTyper(true);
    }, 500);
  };

  const handleMessageReadStatus = async (data: any) => {
    try {
      setReadMessages((prev) => [...prev, data.uuid]);
      if (data?.read_history?.is_viewed === false) {
        const payload = {
          uuid: data?.uuid,
          is_viewed: true,
        };
        const response = await BaseApi.patch(`/unmatched/inbox/`, payload);
        if (response.error === true) {
          toast.error(response.message, toastState.error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleMergeSelect = async (primaryData: object) => {
    setPrimaryMergeData(primaryData);
    setSecondaryMergeData(data);
    setOpenMergeModal(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const query = event?.target?.value;
    fetchMatchOptionData(query);
    // dispatch(setMergeSearch(query));
  };

  const fetchMatchOptionData = async (mergeSearch: string) => {
    const query = setAndGetQueryParams([{ key: 'search', value: mergeSearch }]);
    try {
      const response = await BaseApi.get(`/unmatched/inbox/get/campaign/prospects?${query}`);
      if (response.error) {
        console.log(response.error);
      } else {
        setMergeOption(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="list-row cursor-pointer dark:bg-darkTwo" id={`row-${index}`} aria-valuetext={data?.uuid}>
      <div
        className={`group/list flex justify-between ${index !== 0 ? 'border-t dark:border-darkBorder' : ''}  ${readMessages?.includes(data.uuid) && 'bg-[#f7f9fb] dark:bg-darkTwo'} 
           ${
             readMessages?.includes(data?.uuid) && rowIndex.includes(index)
               ? '!bg-[#f9f8ff] dark:!bg-darkOne '
               : readMessages?.includes(data?.uuid) && 'bg-[#f7f9fb] dark:bg-darkOne'
           }
        `}
        onClick={(e) => {
          //toggleIndexInArray(index);
          handleAccordionClick(e, index);
          handleMessageReadStatus(data);
        }}
      >
        <div className="flex gap-2 px-2 py-3">
          <div className="flex items-center justify-start gap-2 ">
            <div className={`mr-1 size-[5px] rounded-full ${!readMessages?.includes(data?.uuid) && 'bg-green-500 '} `} />
            <div>
              <Avatar className="size-2" size={'xs'} name={data?.platform_message_details?.from?.address?.split('@')[0][0]} bg={avatarColor} color={'white'} />
            </div>
            <div className="max-w-[200px] truncate text-sm font-semibold text-[#344054] dark:text-gray-100">
              {data?.platform_message_details?.from?.name || data?.platform_message_details?.from?.address?.split('@')[0]}
            </div>
            <div className="ml-4 flex size-6 items-center justify-center rounded-full bg-[#e8effb]">
              <IoMail className=" text-sm text-[#015AFF]" />
            </div>
          </div>
          <div className="ml-5 flex items-center justify-start gap-2">
            <div className="!max-w-[18rem]">
              <GroupTags row={data} />
            </div>
          </div>
          {!rowIndex.includes(index) ? (
            <div className="ml-5 flex items-center justify-start gap-2 ">
              <p className="max-w-[18rem] truncate  text-sm font-medium">{data?.platform_message_details?.subject}</p>
              <p className="max-w-[18rem] truncate  text-xs text-column dark:text-gray-500" dangerouslySetInnerHTML={{ __html: data?.platform_message_details?.message }} />
            </div>
          ) : null}
        </div>
        {/* {!rowIndex.includes(index) ? ( */}
        <div className="relative flex items-center justify-end gap-4 ">
          <div
            className={`flex ${readMessages?.includes(data?.uuid) && 'bg-[#f7f9fb] dark:bg-darkTwo'}  ${
              rowIndex.includes(index) ? 'bg-[#f9f8ff]' : 'opacity-0 transition-opacity group-hover/list:opacity-100'
            }`}
          >
            <div id={`match-menu-${index}`}>
              <Menu id={`match-menu-${index}`}>
                <MenuButton
                  name="Match"
                  id="match-menu"
                  className={`flex !px-2  ${readMessages?.includes(data.uuid) && 'bg-[#f7f9fb]'} dark:bg-darkTwo`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(setMergeSearch(data?.platform_message_details?.from?.address?.split('@')[1]));
                    fetchMatchOptionData(data?.platform_message_details?.from?.address?.split('@')[1]);
                  }}
                >
                  {/* Role Restriction for the Match in the  */}
                  {(roleStatus?.is_owner || roleStatus?.match) && (
                    <div className={`flex gap-2 text-xs `}>
                      Match
                      <LuCornerUpRight className="text-sm text-[#8F9BBA]" />
                    </div>
                  )}
                </MenuButton>
                <MenuList className={`match-search-unmatched right-0 mt-2 w-[20rem] rounded-md border-2 border-blueSecondary`}>
                  <>
                    <div className="flex flex-col gap-1.5">
                      <div className="mx-2 mt-2 flex gap-2 text-column">
                        <h5 className="cursor-default text-xs font-medium">Check match from you company</h5>
                      </div>
                      <div className="flex items-center text-column">
                        <SearchBox
                          id="search-opportunity"
                          placeholder="Search campaigns or oppotunities"
                          extraClassName="w-full"
                          inputClass="!text-xs font-normal match-search-unmatched"
                          onChange={handleSearch}
                        />
                      </div>
                      <div className="px-4 text-xs text-column">Recommended</div>
                      {!mergeOption?.length ? <div className="px-4 text-center text-xs text-column">Search prospects to match..</div> : null}

                      <div className="h-48 overflow-y-auto">
                        {mergeOption?.map((options, index) => (
                          <div
                            className="mx-1 mt-1 flex items-center px-2"
                            key={index}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMergeSelect(options);
                            }}
                          >
                            <div className="flex  w-full gap-3">
                              <div className="flex h-8 w-8  items-center justify-center rounded-full p-1 shadow-md">
                                {options?.photo ? (
                                  <img className=" h-6 w-6 rounded-full" src={''} alt="" />
                                ) : (
                                  <div className="flex h-6 w-6 items-center justify-center rounded-full border bg-gray-100 transition-all duration-300 group-hover/list:bg-brand-50">
                                    <Avatar
                                      size="xs"
                                      className={`size-4 text-sm text-gray-400 duration-500 `}
                                      name={options?.email?.split('@')[0][0]}
                                      bg={colors[getRandomIndex()]}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flex flex-col justify-center">
                                <h6 className="w-60 overflow-hidden text-ellipsis whitespace-nowrap  text-xs font-semibold">{options?.email}</h6>
                                <p className="w-60 overflow-hidden text-ellipsis whitespace-nowrap  text-[10px] text-column">
                                  {options?.campaign_name ? options?.campaign_name : ''}
                                </p>
                              </div>
                            </div>
                            {/* <div className="mt-0.5 flex w-full flex-col items-end gap-1.5">
                                <span className="text-[.625rem]">{options?.cdate && moment(options?.cdate).format('MMM DD, h:mm A')}</span>
                              </div> */}
                          </div>
                        ))}
                      </div>
                      <div className="mt-2 border-t-2 border-dashed p-2 text-center text-[10px] font-bold">
                        Not able to find your match?{' '}
                        <span
                          className="cursor-pointer select-none text-blueSecondary underline"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                          }}
                          id="add-new"
                        >
                          Add new
                        </span>
                      </div>
                    </div>
                  </>
                </MenuList>
              </Menu>
            </div>

            <Menu>
              <MenuButton
                name="Match"
                className={`!hover:text-blueSecondary flex ${data?.read_history?.is_viewed && 'bg-[#f7f9fb] dark:bg-darkTwo'} !px-2 `}
                onClick={() => toggleIndexInArray(index)}
              >
                {(roleStatus?.is_owner || roleStatus?.ignore) && (
                  <div className="flex items-center justify-center gap-2 text-xs text-blueSecondary">
                    <AiFillDelete className="text-blueSecondary" /> Ignore
                  </div>
                )}
              </MenuButton>
              <MenuList minW="0" className="!h-[111px] !w-[15rem] !rounded-lg border-blueSecondary bg-white !p-0 shadow-lg  ">
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_once', data.uuid)}
                  className="group/once flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                >
                  <span>
                    Ignore <span className="text-blueSecondary group-hover/once:text-white">once</span>
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_future_domain', data.uuid, data?.platform_message_details?.from?.address?.split('@')[1])}
                  className="group/domain flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:!bg-[#4318FF] hover:!text-white "
                >
                  <span>
                    Ignore entire <span className="text-blueSecondary group-hover/domain:text-white">domain</span> for future
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={(e) => updateIgnoreStatus(e, 'ignore_future_email', data.uuid, data?.platform_message_details?.from?.address)}
                  className="group/email flex items-center space-x-2 !py-2 !pe-2  !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF]  hover:!text-white"
                >
                  <span>
                    Ignore entire <span className="text-blueSecondary group-hover/email:text-white">email</span> for future
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <p className=" -mr-5 w-[100px] truncate text-xs text-column dark:text-gray-300">
            {data?.platform_message_details?.last_thread_date
              ? calculateTimeAgo(data?.platform_message_details?.last_thread_date)
              : calculateTimeAgo(data?.platform_message_details?.mail_sent_date_local)}
          </p>
        </div>
        {/* ) : null} */}
      </div>
      <>
        {rowIndex?.includes(index) && (
          <div className={`max-h-[30rem] ${count < 6 && 'min-h-[30rem]'} overflow-auto border-b !bg-[#f9f8ff] dark:!bg-darkTwo `} key={index}>
            {isLoading ? (
              <div className="p-4">
                <div className=" flex items-center gap-3">
                  <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
                </div>
                <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />
              </div>
            ) : (
              emailData?.profile?.message?.map((item: any, ind: number) => (
                <>
                  {item?.platform_message_details?.from?.address ? (
                    <div className="!bg-[#f9f8ff] py-4 dark:!bg-darkTwo" key={item?.uuid}>
                      <div className="flex items-center justify-start px-4">
                        <div className="flex size-7 items-center justify-center rounded-full bg-[#e3e7f2] dark:!bg-darkTwo">
                          <BsReply
                            className="text-column"
                            onClick={(e) => {
                              e?.stopPropagation();
                              handleReply(item?.platform_message_details, item?.sender_id);
                              setShowTyper(!showTyper);
                            }}
                          />
                        </div>
                        <div className="w-[20px] border bg-[#e3e7f2] dark:!bg-darkOne" />
                        <div className=" w-full rounded-md border bg-white shadow dark:border-darkBorder dark:!bg-darkThree">
                          <div className=" p-3">
                            <div className="flex cursor-pointer justify-between">
                              <div className="flex gap-3 ">
                                <Avatar
                                  className="border"
                                  style={{ height: '30px', width: '30px' }}
                                  name={item?.platform_message_details?.from?.address?.[0]}
                                  bg="#ece7ff"
                                  color={'white'}
                                />
                                <div>
                                  <p className="text-sm font-bold text-heading">
                                    {item?.platform_message_details?.from?.name || item?.platform_message_details?.from?.address?.split('@')[0]}{' '}
                                    <span className="text-xs font-medium text-column">{`<${item?.platform_message_details?.from?.address}>`}</span>
                                  </p>
                                  <div className="flex py-1">
                                    <span className=" line-clamp-2 max-w-96 select-text overflow-hidden whitespace-normal text-xs text-column">
                                      to -{' '}
                                      {!item?.platform_message_details?.to?.length
                                        ? item?.platform_message_details?.to?.address
                                        : item?.platform_message_details?.to?.map((val: { address: string }) => val.address).join(',')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div></div>
                              <div className="flex items-start">
                                <div className="mr-3 flex items-center justify-end gap-2">
                                  <div
                                    onClick={() => {
                                      handleMainReply(item?.platform_message_details, item?.sender_id);
                                    }}
                                  >
                                    <BsReply className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleReplyAll(item?.platform_message_details, item?.sender_id);
                                    }}
                                  >
                                    <BsReplyAll className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleForward(item?.platform_message_details, item?.sender_id, '');
                                    }}
                                  >
                                    <TiArrowForwardOutline className="text-column" />
                                  </div>
                                </div>
                                <p className="text-xs text-column">{formatIndianDateTime(item?.platform_message_details?.mail_sent_date)}</p>
                              </div>
                            </div>
                            <div className="textEditor mt-2 overflow-x-auto text-xs" dangerouslySetInnerHTML={{ __html: item?.fullMessage }} />
                            {/* <div className='text-xs w-full border border-red-500 leading-5 text-paused-color text-wrap'>{emailData?.profile?.message}</div> */}
                          </div>
                          {item?.attachment?.length ? (
                            <div className="mx-3 my-4 mt-1 rounded-md border">
                              <div className="bg-headerbg rounded-md px-3 py-1 text-[.6875rem] text-column">Attachment</div>
                              <div className="flex flex-wrap border-t p-3">
                                {/* files */}
                                {item?.attachment?.map((attachment: any, index: number) => {
                                  const formatBytes = (bytes: number) => {
                                    if (bytes < 1024) return `${bytes} Bytes`;
                                    let kb = bytes / 1024;
                                    if (kb < 1024) return `${kb.toFixed(3)} KB`;
                                    let mb = kb / 1024;
                                    if (mb < 1024) return `${mb.toFixed(3)} MB`;
                                    let gb = mb / 1024;
                                    return `${gb.toFixed(3)} GB`;
                                  };
                                  const size = formatBytes(attachment?.size);

                                  return (
                                    <div className="mt-2 flex w-1/2 gap-2" key={`${index}_attachment`}>
                                      <div className="h-fit rounded-full bg-hover p-2 text-sm text-blueSecondary">
                                        <CgFile />
                                      </div>
                                      <div className="flex flex-col gap-1 pt-1">
                                        <span className="text-xs font-medium">{attachment?.filename}</span>
                                        <div className="flex text-[.6875rem]">
                                          <span>{size} -</span>
                                          <span
                                            className="cursor-pointer pl-1 pr-1.5 text-blueSecondary"
                                            onClick={(e) => {
                                              e?.stopPropagation();
                                              downloadFile(attachment);
                                            }}
                                          >
                                            Download
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            )}
            {showTyper && (
              <div className="!bg-[#f9f8ff] px-2 py-2  dark:!bg-darkTwo" ref={typerRef}>
                <Typer
                  containerClass="!p-0 border-none"
                  onTyperClose={() => {
                    setShowTyper(false);
                    // onClose();
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
      <UploadSingleLead isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} textColor={'text-column'} is_campaign_required={true} editData={data} />
      <MergeConfrimPopUp
        isOpen={openMergeModal}
        onClose={() => {
          setOpenMergeModal(false);
          setPrimaryMergeData('');
          setSecondaryMergeData('');
        }}
        secondaryEmailData={secondaryMergeData}
        primaryEmailData={primaryMergeData}
      />
    </div>
  );
};
export default ListRow;
