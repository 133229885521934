import Inbox from './features/Inbox'
import InboxGraph from './features/InboxGraph'
import Outbox from './features/Outbox'
import StatsBox from './features/StatsBox'
import WorldChart from './features/WorldChart'

const Live = () => {
  return (
    <div className="mt-2 space-y-5 h-[88vh] w-full overflow-y-auto rounded-md bg-white p-5  dark:bg-darkOne dark:text-white noscroll">
      <div className='flex gap-5'>
        <Inbox />
        <Outbox />
        <InboxGraph />
      </div>
      <div className='flex gap-5'>
        <div className='space-y-5'>
          <StatsBox title='Number of email opens' section='60 minutes' value='72' />
          <StatsBox title='Number of replies' section='60 minutes' value='24' />
        </div>
        <WorldChart />
      </div>
    </div>
  )
}

export default Live