import { FormEvent, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import InputField from 'components/fields/InputField';
import Checkbox from 'components/checkbox';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import { SigninFormDTO } from 'common/dto/types/views/authentication';
import { SigninFormData } from 'common/dto/initial-value/views/authentication';
import { BaseApi } from 'common/api/common/base-api';
import { useAppSelector, useAppDispatch } from 'store';
import { getErrorMessage, setGlobalLoading } from 'store/custom';
import { setLoggedUserDetails, setLoggedUserRolePermissionList } from 'store/authentication';
import LogoCard from '../components/LogoCard';
import SignupWithGoogle from '../components/SignupWithGoogle';
import { cryptoDecrypt, jwtDecrypt, toastState } from 'common/utils/utility';
import GoogleOAuthContainer from 'components/google-oauth';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import Button from 'common/sparkle-common/Button';
import { toast } from 'react-toastify';
import React from 'react';
import { RiLockPasswordLine } from 'react-icons/ri';
import { LocalStorage } from 'common/api/common/storage';

function SignIn() {
  const dispatch = useAppDispatch();
  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const numInputs = 6;

  const [otp, setOtp] = React.useState(Array(numInputs).fill(''));
  const [focusIndex, setFocusIndex] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpPageStatus, setOtpPageStatus] = React.useState(false);
  const flagMessage = searchParams.get('flagMessage');

  React.useEffect(
  ()=>{
    if (flagMessage) {
      const flagMsgData: { error: boolean; message: string } = jwtDecrypt(flagMessage);
      if (flagMsgData?.error) {
        toast.error(flagMsgData?.message, toastState.error);
      } else {
        toast.success(flagMsgData?.message, toastState.success);
      }
      setTimeout(() => {
        searchParams.delete('flagMessage');
        setSearchParams(searchParams);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]
  )


  const [formData, setFormData] = useState<SigninFormDTO>(SigninFormData);
  const handleFormChange = (e: { target: { id: any; value: any } }): void => setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setFormData((prev) => ({ ...prev, loading: true }));
      const data = await BaseApi.post('/auth/login', formData);
      if (data?.error) {
        dispatch(getErrorMessage(data?.message));
      } else if (!data?.error && data?.status === 202 && !data?.data) {
        toast.success(data?.message, toastState.success);
        setOtpPageStatus(true);
      } else {
        LocalStorage.setString(LOGIN_STORAGE_VAR, data?.data);
        const loginData = await cryptoDecrypt(data?.data, true);
        LocalStorage.setItem('darkMode', loginData?.app_settings?.theme === 'dark' ? 'true' : 'false')
        dispatch(
          setLoggedUserDetails({
            id: loginData?.id,
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            organization_id: loginData?.organization_id,
            workspace_id: loginData?.workspace_id,
            organization_name: loginData?.organization_name,
            workspace_name: loginData?.workspace_name,
            authToken: loginData?.authToken,
            picture: loginData?.picture,
            app_settings: loginData?.app_settings,
            is_google:loginData?.is_google
          })
        );
        dispatch(setLoggedUserRolePermissionList(loginData?.role_permission_list));
        dispatch(setGlobalLoading(true));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFormData((prev) => ({ ...prev, loading: false }));
    }
  };
  const handleValidateTwoStepVerification = async () => {
    try {
      const payLoad = {
        email: formData?.email,
        otp: otp.join(''),
        type: 'login',
      };
      setFormData((prev) => ({ ...prev, loading: true }));
      const data = await BaseApi.post('/auth/verify/otp', payLoad);
      if (data?.error) {
        dispatch(getErrorMessage(data?.message));
      } else {
        LocalStorage.setString(LOGIN_STORAGE_VAR, data?.data);
        const loginData = await cryptoDecrypt(data?.data, true);
        dispatch(
          setLoggedUserDetails({
            id: loginData?.id,
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            organization_id: loginData?.organization_id,
            workspace_id: loginData?.workspace_id,
            organization_name: loginData?.organization_name,
            workspace_name: loginData?.workspace_name,
            authToken: loginData?.authToken,
            picture: loginData?.picture,
          })
        );
        dispatch(setLoggedUserRolePermissionList(loginData?.role_permission_list));
        dispatch(setGlobalLoading(true));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFormData((prev) => ({ ...prev, loading: false }));
      setOtp(Array(numInputs).fill(''));
    }
  };

  React.useEffect(() => {
    const input = document.getElementById(`otp-${focusIndex}`);
    if (input) {
      input.focus();
    }
  }, [focusIndex]);

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      setFocusIndex(index - 1);
    }
  };
  const handleChange = (index: any) => (event: any) => {
    const { value } = event.target;
    const newOtp = [...otp];

    if (/^[0-9]$/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < numInputs - 1) {
        setFocusIndex(index + 1);
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const Payload = {
        email: formData?.email,
      };

      const updateResponse = await BaseApi.patch(`/auth/resend/otp`, Payload);
      if (!updateResponse?.error) {
        toast.success('OTP has been Resent to your registered email', toastState.success);
      } else {
        toast.error(updateResponse?.message, toastState.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-darkOne">
        <main className={`mx-auto min-h-screen`}>
          <FixedPlugin />
          <LogoCard
            maincard={
              <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                {/* Sign in section */}
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                  <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">Sign In</h3>
                  <p className="mb-9 ml-1 text-base text-gray-600">Enter your email and password to sign in!</p>

                  <GoogleOAuthContainer source={<SignupWithGoogle text="Sign In with Google" />} />
                  <div className="mb-6 flex items-center  gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    {!otpPageStatus && <p className="text-base text-gray-600"> or </p>}
                    {!otpPageStatus && <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />}
                  </div>
                  {!otpPageStatus && (
                    <>
                      <form onSubmit={(e) => handleFormSubmit(e)}>
                        {/* Email */}
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Email*"
                          placeholder="example@sample.com"
                          id="email"
                          type="text"
                          onChange={handleFormChange}
                          value={formData?.email}
                          errorMessage={errorMessage?.email}
                          state={errorMessage?.email ? 'error' : ''}
                        />

                        {/* Password */}
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Password*"
                          placeholder="Min. 8 characters"
                          id="password"
                          type="password"
                          onChange={handleFormChange}
                          value={formData?.password}
                          errorMessage={errorMessage?.password}
                          state={errorMessage?.password ? 'error' : ''}
                        />
                        {/* Checkbox */}
                        <div className="mb-4 flex items-center justify-between px-2">
                          <div className="mt-2 flex items-center">
                            <Checkbox />
                            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">Keep me logged In</p>
                          </div>
                          <Link to="/forgot-password" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 hover:underline dark:text-white">
                            Forgot Password?
                          </Link>
                        </div>
                        <Button
                          loading={formData?.loading}
                          disable={formData?.loading}
                          name="Sign In"
                          type="submit"
                          customClass="w-full rounded-xl text-base font-medium transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        />
                        {/* <button id="submit" type="submit" className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                            Sign In
                                        </button> */}
                      </form>

                      <div className="mt-4">
                        <span className="text-sm font-medium text-navy-700 dark:text-gray-500">Not registered yet?</span>
                        <Link to="/sign-up" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
                          Create an account
                        </Link>
                      </div>
                    </>
                  )}

                  {/* 2FA Page */}
                  {otpPageStatus && (
                    <>
                      <h5 className="mb-2.5 text-center text-3xl font-bold text-navy-700 dark:text-white">Two-Step Verification</h5>
                      <p className="mb-6 ml-1 text-center text-base text-gray-600">Add additional verification to your account.</p>

                      <RiLockPasswordLine className="tes mx-auto my-4 text-gray-700 dark:text-white" size={78} />
                      <div className="flex items-center justify-center">
                        <span className="text-center text-sm font-medium text-navy-700 dark:text-gray-500">
                          Please enter the OTP to verify your account. A code has been sent to your registered email.
                        </span>
                      </div>

                      <span className="text-sm font-medium text-navy-700 dark:text-gray-500"></span>

                      <div className="my-4 mt-3 flex items-center justify-center gap-2">
                        {otp.map((value, index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            type="tel"
                            onKeyDown={handleKeyDown(index)}
                            maxLength={1}
                            value={value}
                            onChange={handleChange(index)}
                            className={`h-11 w-11 rounded-md border text-center ${errorMessage?.otp ? 'border-red-500' : 'border-brandLinear hover:border-2 hover:border-blue-300'
                              } dark:!bg-navy-700 dark:text-white `}
                            autoComplete="off"
                          />
                        ))}
                      </div>
                      <Button
                        name="Verify"
                        loading={formData?.loading}
                        disable={formData?.loading}
                        onClick={() => handleValidateTwoStepVerification()}
                        customClass="w-full mt-4 rounded-xl text-base font-medium transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      />
                      <div className="mt-4 flex">
                        <span className="text-sm font-medium text-navy-700 dark:text-gray-500">Not received yet?</span>
                        <div
                          onClick={handleResendOtp}
                          className="ml-1 cursor-pointer text-sm font-semibold text-brand-500 hover:text-brand-600 hover:text-darkBlue dark:text-white"
                        >
                          Resend OTP
                        </div>
                      </div>
                    </>
                  )}

                  {/* 2FA CHECK PAGE
                   */}
                </div>
              </div>
            }
          />
        </main>
      </div>
    </div>
  );
}

export default SignIn;
