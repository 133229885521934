import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, toastState } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import React from 'react'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setProspects, updatePreviewProspectsData, updateVariableData } from 'store/campaign-v2';

const UpdateVariableDrawer = () => {
    const updateVariable = useAppSelector((state) => state.campaignv2.prospects.update_variables);
    const campaignV2 = useAppSelector((state) => state.campaignv2);
    const dispatch = useAppDispatch()
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({})
    const [loading, setLoading] = React.useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = React.useState(false)
    const variablesData = Object.keys(formData)

    React.useEffect(() => {
        if (updateVariable.modal_view) {
            const formData = campaignV2.prospects.prospects_list.find((item) => item.email === updateVariable.row_email)
            setFormData(formData || {})
        }
    }, [campaignV2.prospects.prospects_list, updateVariable.row_email, updateVariable.modal_view])

    const handleClose = () => {
        dispatch(updateVariableData({ modal_view: false, row_email: '' }))
    }

    const handleChangeVariableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }))
    }

    const createProspectListApi = async (prospect_data: { [key: string]: string }) => {
        const { campaign_id } = campaignV2.ids;
        const params = {
            campaign_id,
            email: updateVariable.row_email,
            prospect_data,
        };
        // await BaseApi.patch('campaign/v2/prospect', params);
        await BaseApi.patch('campaign/prospects', params);
    }

    const handleSaveVariable = async () => {
        try {
            setLoading(true)
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isEmailValid = emailPattern.test(formData.email);
            if (isEmailValid) {
                const updatedVariableprospects = campaignV2.prospects?.prospects_list.map((item) => {
                    if (item.email === updateVariable.row_email) {
                        return { ...formData }
                    } else {
                        return item
                    }
                })
                const updatedVariablePreviewProspects = campaignV2.prospects?.preview.update_prospect_list.map((item) => {
                    if (item.email === updateVariable.row_email) {
                        return { ...formData }
                    } else {
                        return item
                    }
                })
                await createProspectListApi(formData)
                dispatch(setProspects({ prospects_list: updatedVariableprospects }))
                dispatch(updatePreviewProspectsData({ update_prospect_list: updatedVariablePreviewProspects }))
                handleClose()
            } else {
                toast.error('Please enter a valid email address to proceed.', toastState.error)
            }
        } catch (error) {
            console.log('Variable data inserting error')
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteProspect = async () => {
        try {
            setDeleteButtonLoading(true)
            const params = {
                prospect_id: campaignV2?.ids?.prospects_id,
                email: formData?.email
            }
            const response = await BaseApi.delete('campaign/prospects/single/delete', { data: params });
            if (!response?.error) {
                const prospects_list = campaignV2?.prospects?.prospects_list?.filter(item => item.email !== formData?.email)
                handleClose()
                dispatch(setProspects({ prospects_list }))
            }
            else {
                toast.error(_errorHandler(response?.message), toastState?.error);
            }
        } catch (error) {
            console.log('Prospect delete error')
        } finally {
            setDeleteButtonLoading(false)
        }
    }
    return (
        <Drawer placement="right" onClose={handleClose} isOpen={updateVariable.modal_view} size={'md'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px" className="!px-3 !py-4 ">
                    <div className=" flex items-start gap-2  border-red-500 ">
                        <div className="flex flex-col  ">
                            <p className="text-base font-semibold text-heading">Edit details</p>
                        </div>
                    </div>
                </DrawerHeader>

                <DrawerBody className="m-2 !px-2 !py-0 overflow-y-auto">
                    <div className='flex flex-col gap-8'>
                        {variablesData?.length > 0 &&
                            variablesData.filter(key => (!key.includes('verification_status') && key !== 'id' && key !== 'organization_id' && key !== '__twitter_url__' && key !== '__facebook_url__' && key !== '__facebook_url__' && key !== 'github_url')).map((key, i) => {
                                return <InputField key={i}
                                    autoComplete={'off'}
                                    type={key === 'email' ? 'email' : 'text'}
                                    label={key === 'email' ? `${key}*` : key.replaceAll("_", " ").trim().replace(/\b\w/g, char => char.toUpperCase())}
                                    placeholder={`Enter ${key === 'email' ? `${key}*` : key.replaceAll("_", " ").trim().replace(/\b\w/g, char => char.toUpperCase())}...`}
                                    value={formData[key]}
                                    name={key}
                                    onChange={handleChangeVariableValue}
                                    labelClass='capitalize !ml-0'
                                    inputClass={`!mt-1 !rounded-md !h-9 ${formData[key] ? '' : 'border border-red-500'}`} />
                            })
                        }
                    </div>
                </DrawerBody>
                <DrawerFooter className="border-t-2">
                    <Button
                        id="delete-prospect"
                        name="Delete"
                        loading={deleteButtonLoading}
                        disable={deleteButtonLoading}
                        customClass="bg-white w-full border-2 border-red-300 !font-medium mr-3 py-2 text-[0.80rem] !text-red-500"
                        onClick={handleDeleteProspect}
                    />
                    <Button
                        id="apply-senderlist-btn"
                        disable={loading}
                        loading={loading}
                        name="Save"
                        customClass="w-full !text-[#FFFFFF] !font-medium py-2 text-[0.80rem]"
                        onClick={handleSaveVariable}
                    />
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default UpdateVariableDrawer