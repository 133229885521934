export const usageCardsData: { name: "Campaigns" | "True verification" | "Enrichment" | "Prospects Search" | "Blacklist Monitor" | "CRM Reply" | "Email Verification" | "Inbox Reply" | "Pay As You Go", [key: string]: string }[] = [
    {
        name: "Campaigns",
        key: "email_sent",
        color: "bg-[#0BA5EC]",
        hex_color_code: '#0BA5EC'
    },
    {
        name: "True verification",
        key: "true_verify",
        color: "bg-[#FF692E]",
        hex_color_code: '#FF692E'
    },
    {
        name: "Enrichment",
        key: "enrichment",
        color: "bg-[#16B364]",
        hex_color_code: '#16B364'
    },
    {
        name: "Prospects Search",
        key: "search_prospects",
        color: "bg-[#7F56D9]",
        hex_color_code: '#7F56D9'
    },
    {
        name: "Blacklist Monitor",
        key: "blacklist_monitor",
        color: "bg-[#4318FF]",
        hex_color_code: '#4318FF'
    },
    {
        name: "CRM Reply",
        key: "crm_reply",
        color: "bg-[#4318FF]",
        hex_color_code: '#4318FF'
    },
    {
        name: "Email Verification",
        key: "email_verify",
        color: "bg-[#4318FF]",
        hex_color_code: '#4318FF'
    },
    {
        name: "Inbox Reply",
        key: "inbox_reply",
        color: "bg-[#4318FF]",
        hex_color_code: '#4318FF'
    },
    {
        name: "Pay As You Go",
        key: "payg",
        color: "bg-[#4318FF]",
        hex_color_code: '#4318FF'
    },
]