import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineCloudUpload } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { useAppDispatch } from 'store';
import { setFlagStatus } from 'store/custom';
import { TfiEmail } from 'react-icons/tfi';
import { setSmtpDrawerFlagStatus } from 'store/email-accounts';
import { BaseApi } from 'common/api/common/base-api';
import { convertArrayToObjectArray, toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';
const AddNewSMTP = ({ setSmtpFlag }: any) => {
    const dispatch = useAppDispatch();
    const reader = new FileReader();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [csvFlagStatus, setCsvFlagStatus] = useState(1)
    const [csvData, setCsvData] = useState([])
    const [csvFinalData, setCsvFinalData] = useState([])

    const handleFileUpload = async (e: any) => {
        const file = e.target.files[0];
        reader.onload = async (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setCsvData(jsonData);
            setCsvFlagStatus(2);
        };
        reader.readAsArrayBuffer(file);
    };

    const handleVerifyEmailAccounts = async (jsonData: any) => {
        setCsvFinalData(jsonData.map((item: any) => ({ ...item, status: 'Pending' })));
        for (let emailItem of jsonData) {
            try {
                setCsvFinalData((prev) => prev.map((item: any) => ((emailItem?.sno === item?.sno) ? { ...item, status: 'Processing' } : item)))
                const response = await BaseApi.post('/email/settings/smtp/create', emailItem);
                if (response?.error) {
                    setCsvFinalData((prev) => prev.map((item: any) => ((emailItem?.sno === item?.sno) ? { ...item, status: 'Failed' } : item)))
                } else {
                    setCsvFinalData((prev) => prev.map((item: any) => ((emailItem?.sno === item?.sno) ? { ...item, status: 'Complete' } : item)))
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const MapVariables = () => {
        const variablesList = [
            { id: "first_name", value: "FirstName" },
            { id: "last_name", value: "LastName" },
            { id: "email", value: "Email" },
            { id: "smtp_username", value: "SMTPUsername" },
            { id: "smtp_password", value: "SMTPPassword" },
            { id: "smtp_host", value: "SMTPHost" },
            { id: "smtp_port", value: "SMTPPort" },
            { id: "smtp_secure", value: "SMTPSecure" },
            { id: "limit", value: "Limit" },
            { id: "fixed_delay", value: "FixedDelay" },
            { id: "set_different_reply_to_address", value: "ReplyToAddress" },
            { id: "imap_username", value: "ImapUsername" },
            { id: "imap_password", value: "ImapPassword" },
            { id: "imap_host", value: "ImapHost" },
            { id: "imap_port", value: "ImapPort" },
            { id: "imap_secure", value: "ImapSecure" }
        ];

        const [emailAccountsList, setEmailAccountsList] = useState([]);
        useEffect(() => {
            const csvArrayOfObj = convertArrayToObjectArray(csvData);
            setEmailAccountsList(csvArrayOfObj)
        }, [])

        const handleChangeVariable = (e: { target: { value: string } }, csvIndex: string) => {
            const variableName = e?.target?.value;
            let keyExistsStatus = false;
            const finalArrResult = emailAccountsList.map((item: any) => {
                if (!item.hasOwnProperty(variableName)) {
                    item[variableName] = item[csvIndex];
                    delete item[csvIndex]
                } else {
                    keyExistsStatus = true
                }
                return item
            })
            if (keyExistsStatus) {
                toast.error("Variable is already selected", toastState.error);
            }
            setEmailAccountsList(finalArrResult);
        }
        const handleUploadAll = async () => {
            await handleVerifyEmailAccounts(emailAccountsList);
        }
        return (
            <>
                <div className="relative w-full sm:rounded-lg mt-4 h-5/6 overflow-y-auto">
                    <table className=" w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase border-b dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">COLUMN</th>
                                <th scope="col" className="px-12 py-3">VARIABLE TYPES</th>
                                <th scope="col" className="px-6 py-3">SAMPLE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {csvData?.length && csvData[0].map((csvItem: string, index: number) => (
                                <tr key={index} className="h-32 bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-default">
                                    <td className="text-center w-8 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {csvItem}
                                    </td>
                                    <td className="">
                                        <div className="">
                                            <div className="w-full mt-2 rounded-xl border border-gray-200 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 sm:w-full md:w-full">
                                                <select onChange={(e) => handleChangeVariable(e, csvItem)} className="bg-none text-lightFourth w-full rounded-xl p-3 text-sm outline-none dark:bg-navy-800 dark:text-white hover:cursor-pointer">
                                                    <option>Select</option>
                                                    {variablesList?.map((item: any, index: number) => (
                                                        <option key={index} value={item?.id}>{item?.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-sm pl-3 font-medium text-gray-900 dark:text-white">
                                        {csvData?.[1][index] ? csvData?.[1][index] : csvItem}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex gap-4 justify-center mt-8">
                    <button onClick={() => { setCsvFlagStatus(1); }} className="px-12 text-sm rounded-lg duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Back</button>
                    <button onClick={() => { handleUploadAll(); setCsvFlagStatus(3); }} className="px-12 text-sm rounded-lg duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Upload All</button>
                </div>
            </>
        )
    }

    return (
        <>
            {csvFlagStatus === 1 &&
                <div>
                    <Box className="flex flex-col items-center mt-3">
                        <div className="flex justify-between w-10/12 dark:!bg-navy-700 my-4 font-bold">
                            <h4>Upload a CSV</h4>
                            <button className="text-xs text-brand-500 bg-brand-50 px-4 py-1 rounded-lg">SEE SAMPLE</button>
                        </div>
                        <div className="flex mb-3 h-44 w-10/12 duration-300 cursor-pointer p-3 border border-dashed flex-col items-center justify-center rounded-xl border-gray-300 bg-gray-50 hover:scale-105 active:bg-gray-100 dark:!border-none dark:!bg-navy-700" onClick={() => { fileInputRef.current && fileInputRef.current.click(); }}>
                            <p className="text-[80px] text-navy-700">
                                <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
                            </p>
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                Drop your files here, or{" "} <span className="font-bold text-brand-500 dark:text-brand-400">browse</span>
                            </p>
                            <p className="pt-2 text-sm text-gray-600">
                                CSV files are allowed
                            </p>
                            <input type="file" ref={fileInputRef} className="hidden" onChange={(e) => handleFileUpload(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </div>
                        <div onClick={() => { setSmtpFlag(false); dispatch(setFlagStatus(false)); dispatch(setSmtpDrawerFlagStatus(true)) }} className="flex gap-3 shadow-xl shadow-gray-200/30 cursor-pointer items-center w-10/12 bg-white p-1 m-2 rounded-xl hover:scale-105 active:bg-gray-50 duration-300">
                            <span className="text-3xl bg-blue-50 rounded-lg text-gray-700 p-4"><TfiEmail /></span>
                            <span className="font-semibold text-md p-2">Single Account</span>
                        </div>
                    </Box>
                    <div className="flex gap-4 justify-center mt-8">
                        <button onClick={() => setSmtpFlag(false)} className="px-12 text-sm rounded-lg duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Back</button>
                    </div>
                </div>
            }

            {csvFlagStatus === 2 && <MapVariables />}

            {csvFlagStatus === 3 &&
                <div>
                    <div className="h-5/6">
                        <div className="relative w-full sm:rounded-lg mt-4 shadow-xl shadow-gray-100">
                            <div className="text-sm p-1 text-gray-600 shadow-sm shadow-gray-200 rounded-md flex justify-between px-5">
                                <span className="p-2">Email</span>
                                <span className="p-2">Status</span>
                            </div>
                            {
                                csvFinalData.map((item, index) => (
                                    <div key={index} className="text-sm p-1 font-medium border-b flex justify-between px-5">
                                        <div className={`p-2 py-6`}>{item.email}</div>
                                        <div className={`p-2 py-6`}><span className={`px-2 py-1 rounded-md text-xs ${item.status === 'Complete' ? 'bg-green-100 text-green-500' : item.status === 'Processing' ? 'bg-brand-50 text-brand-500' : item.status === 'Pending' ? 'bg-gray-100 text-gray-700' : 'bg-red-100 text-red-500'}`}>{item.status}</span></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex gap-4 justify-center mt-8">
                        <button onClick={() => { setCsvFlagStatus(2); }} className="px-12 text-sm rounded-lg duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Back</button>
                        <button onClick={() => { setSmtpFlag(false); dispatch(setFlagStatus(false)); dispatch(setSmtpDrawerFlagStatus(false)) }} className="px-12 text-sm rounded-lg duration-300 py-2 border text-white border-brand-500 bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Finish</button>
                    </div>
                </div>
            }
        </>
    )
}



export default AddNewSMTP