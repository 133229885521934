import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import ButtonMenu from 'common/sparkle-common/ButtonMenu';
// import MessageBox from 'common/sparkle-common/MessageBox';
import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor';
import { toastState } from 'common/utils/utility';
import TagInput from 'components/crm/TagInput';
import React, { useRef, useState } from 'react';
import { AiFillMail } from 'react-icons/ai';
import { BiSolidMessageSquareDetail } from 'react-icons/bi';
import { FaLinkedin } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { setMessageSent } from 'store/crm';

interface ModalTyperProps {
  onTyperClose?: () => void;
  containerClass?: string;
}
const Typer = (props: ModalTyperProps) => {
  const { onTyperClose } = props;
  const threadData = useAppSelector((state) => state?.crm?.threadMaildata);
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [primary_email, setPrimary_email] = useState<any>([]);
  const editorRef = React.useRef(null);

  const [dropdownValue, setDropDownValue] = useState({
    from: [],
    to: [],
    bcc: [],
    cc: [],
    subject: '',
    uuid: '',
    message: '',
  });

  const textareaRef = useRef(null);

  const sendMessageData = [
    {
      name: 'Email',
      Icon: <AiFillMail className="text-brand-500 group-hover:text-white" />,
    },
    {
      name: 'SMS',
      Icon: <BiSolidMessageSquareDetail className="text-brand-500 group-hover:text-white" />,
    },
    {
      name: 'Whatsapp',
      Icon: <IoLogoWhatsapp className="text-brand-500 group-hover:text-white" />,
    },
    {
      name: 'LinkedIn',
      Icon: <FaLinkedin className="text-brand-500 group-hover:text-white" />,
    },
  ];

  // useEffect(() => {
  //   const getSenderAccountList = async () => {
  //     try {
  //       const response = await BaseApi.get(`crm/pipeline/opportunity/sender/account/${opportunity_id}`);
  //       if (response?.status === 200) {
  //         setConvoList(response?.data?.prospect_account);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getSenderAccountList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const normalizeSubject = (subject: string) => {
  //   return subject?.replace(/^(Re:|Fwd:)\s*/i, '').trim();
  // };

  React.useEffect(() => {
    setDropDownValue((prevData) => ({
      ...prevData,
      to: Array.isArray(threadData?.to)
        ? threadData?.to?.map((item: any, index: any) => ({
            id: index,
            name: item?.address,
            prospect_name: item?.name ? item?.name : item?.address?.split('@')[0],
          }))
        : [{ id: 0, name: threadData?.to?.address, prospect_name: threadData?.to?.name ? threadData?.to?.name : threadData?.to?.address?.split('@')[0] }],
      from: Array.isArray(threadData?.from)
        ? threadData?.from?.map((item: any, index: any) => ({
            id: index,
            name: item?.address,
            prospect_name: item?.name ? item?.name : item?.address?.split('@')[0],
          }))
        : [{ id: 0, name: threadData?.from?.address, prospect_name: threadData?.from?.name ? threadData?.from?.name : threadData?.from?.address?.split('@')[0] }],
      // uuid: convoList?.find((item) => normalizeSubject(item?.subject)?.includes(normalizeSubject(threadData?.subject)))?.uuid,
      cc: threadData?.cc
        ? Array.isArray(threadData?.cc)
          ? threadData?.cc?.map((item: any, index: any) => ({ id: index, name: item?.address }))
          : [{ id: 0, name: threadData?.cc?.address }]
        : [],
      bcc: threadData?.bcc
        ? Array.isArray(threadData?.bcc)
          ? threadData?.bcc?.map((item: any, index: any) => ({ id: index, name: item?.address }))
          : [{ id: 0, name: threadData?.bcc?.address }]
        : [],
      subject: threadData.subject,
      message: threadData?.message ? threadData?.message : '',
    }));

    const initital_prospect = Array.isArray(threadData?.to)
      ? threadData?.to?.map((item: any, index: any) => ({
          id: index,
          email: item?.address,
        }))
      : [{ id: 0, email: threadData?.to?.address }];
    setPrimary_email(initital_prospect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadData]);

  const handleChangeTo = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id?.toString(), name: item?.name, adress: item?.email_name, prospect_name: item.prospect_name ? item?.prospect_name : item.name.split('@')[0] };
    });
    setDropDownValue((prevData) => ({
      ...prevData,
      to: email_list,
    }));
  };
  //to: [{ id: 0, name: threadData?.from?.address, prospect_name: threadData?.from?.name ? threadData?.from?.name : threadData?.from?.address?.split('@')[0] }],

  const handleChangeCC = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id?.toString(), name: item?.name, adress: item?.email_name, prospect_name: item.prospect_name ? item?.prospect_name : item.name.split('@')[0] };
    });
    setDropDownValue((prevData) => ({
      ...prevData,
      cc: email_list,
    }));
  };

  const handleChangeBcc = (emailList: Array<object>) => {
    const email_list = emailList.map((item: any) => {
      return { id: item?.id?.toString(), name: item?.name, adress: item?.email_name, prospect_name: item.prospect_name ? item?.prospect_name : item?.name?.split('@')[0] };
    });
    setDropDownValue((prevData) => ({
      ...prevData,
      bcc: email_list,
    }));
  };

  const updateMessage = async () => {
    if (threadData?.type === 'reply') {
      const isPrimaryEmailPresent = await primary_email.some((primaryEmail: any) => dropdownValue.to.some((email) => email.name === primaryEmail.email));

      if (!isPrimaryEmailPresent) {
        toast.error('Primary Reply email is required', toastState.error);
        return;
      }
    }
    const prospect_email = await dropdownValue?.to?.map((item) => ({
      email: item.name,
      name: item.prospect_name ? item?.prospect_name : item?.name.split('@')[0],
    }));

    //: `-------Forwarded Message-------<br>from: ${dropdownValue?.to[0]?.name}<br>Date: ${threadData?.date}<br>Subject: ${dropdownValue.subject} <br>To: ${dropdownValue?.from[0]?.name}<br><br> ${dropdownValue?.message}`,
    // const to_Emails = dropdownValue?.to?.map((item) => item.email).join(', ');
    // const updateTo = [...prospect_email, ...primary_email]?.filter((item, index, self) => index === self.findIndex((t) => t['email'] === item['email']));
    const updatedCC = dropdownValue?.cc?.map((item) => ({
      email: item.name,
      name: item.prospect_name ? item?.prospect_name : item?.name.split('@')[0],
    }));
    const updatedBCc = dropdownValue?.bcc?.map((item) => ({
      email: item.name,
      name: item.prospect_name ? item?.prospect_name : item?.name.split('@')[0],
    }));
    try {
      setIsLoading(true);
      if (dropdownValue?.message) {
        const payload = {
          sender_id: threadData.sender_id,
          conversation_details: {
            variant: 'email', // sms, whatsapp, linkedin, email
            message: threadData?.type === 'reply' ? dropdownValue?.message :  dropdownValue.message + threadData.originalMessagedata,

            // conversation_id: dropdownValue.uuid,
            cc: updatedCC,
            prospect_email: prospect_email,
            bcc: updatedBCc,
            subject: dropdownValue.subject,
            main_message_id: threadData.main_message_id,
            individual_message_id: threadData?.individual_message_id,
          },
        };
        const response = await BaseApi.patch(`/crm/pipeline/opportunity/update/mail/communication`, payload);
        if (response?.status === 200) {
          toast.success(response.message, toastState.success);
          setDropDownValue((prev) => ({ from: prev.from, to: prev.to, subject: prev?.subject, uuid: prev?.uuid, message: '', cc: [], bcc: [] }));
          dispatch(setMessageSent());
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);

      setShowBcc((prev) => {
        if (prev === true) {
          return false;
        }
        return prev;
      });
      setShowCc((prev) => {
        if (prev === true) {
          return false;
        }
        return prev;
      });
      onTyperClose();
      //dispatch(setisReplyEditorOpen(false));
    }
  };

  const handleCloseTyper = () => {
    setShowBcc((prev) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    setShowCc((prev) => {
      if (prev === true) {
        return false;
      }
      return prev;
    });
    onTyperClose();
  };
  const handleMessageOnChange = (e: any) => {
    setDropDownValue((prev) => ({ from: prev.from, to: prev.to, subject: prev?.subject, uuid: prev?.uuid, message: e?.toString(), cc: prev.cc, bcc: prev.bcc }));
  };

  return (
    <div>
      <div className={` w-full overflow-auto border dark:!border-darkBorder dark:bg-darkTwo bg-white p-2 ${props.containerClass}`}>
        <div className="rounded-md border border-[#D0D5DD] dark:!border-darkBorder">
          <div className="" ref={textareaRef}>
            <div className=" flex w-full justify-between border-b border-[#D0D5DD] dark:!border-darkBorder px-4 py-2 ">
              <div className="flex items-center justify-center gap-2 text-xs text-column dark:text-purple-400">
                From <span className='dark:text-gray-300'>{dropdownValue?.from[0]?.name}</span>
              </div>
              <div>
                <IoClose onClick={handleCloseTyper} className="cursor-pointer  text-column" />
              </div>
            </div>
            <div className=" mt-2 flex w-full items-center justify-between border-b border-[#D0D5DD] dark:!border-darkBorder px-4 ">
              <div className="flex w-[95%]  items-start justify-start gap-2  text-xs text-column dark:text-purple-400 ">
                To
                <TagInput
                  id="description"
                  onChange={(item: any) => {
                    handleChangeTo(item);
                  }}
                  placeholderTags={[
                    ...dropdownValue?.to?.map((email) => {
                      return { id: email?.id, name: email?.name, prospect_name: email.prospect_name ? email?.prospect_name : email?.name?.split('@')[0] };
                    }),
                  ]}
                  placeholder=""
                  pattern={
                    // eslint-disable-next-line no-useless-escape
                    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                  }
                  extra="!border-none !mb-0  -mt-2 flex  !w-[95%]"
                  tagsClass="!bg-tagsbg !h-5 !text-xs !py-0 !text-[#363F72] hover:!bg-gray-100"
                  className="h-stretch flex-rowflex-wrap tagfiled-to relative !m-0 flex rounded-lg border border-gray-200 dark:!bg-darkTwo bg-none  !p-0 focus:border-brand-200 dark:!border-white/10"
                />
              </div>
              <div className="flex gap-1">
                {!showCc ? (
                  <div className="mr-6 ms-6 cursor-pointer" onClick={() => setShowCc((prev) => !prev)}>
                    <span className="text-xs  text-column dark:text-purple-400">CC</span>
                  </div>
                ) : null}
                {!showBcc ? (
                  <div className=" cursor-pointer" onClick={() => setShowBcc((prev) => !prev)}>
                    <span className="text-xs text-column dark:text-purple-400">BCC</span>
                  </div>
                ) : null}
              </div>
            </div>
            {showBcc && (
              // <div className="flex w-[95%]  items-start justify-start gap-2  text-xs text-column  ">
              //   <span className="text-xs  text-column">BCC</span>
              //   <TagInput
              //     id="description"
              //     onChange={(item: any) => {
              //       handleChangeBcc(item);
              //     }}
              //     placeholderTags={[
              //       ...dropdownValue?.bcc?.map((email) => {
              //         return { id: parseInt(email?.id), name: email?.name, prospect_name: email?.prospect_name ? email?.prospect_name : email?.name?.split('@')[0] };
              //       }),
              //     ]}
              //     placeholder=""
              //     pattern={
              //       // eslint-disable-next-line no-useless-escape
              //       /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
              //     }
              //     extra="!border-none !mb-0  -mt-2 flex  !w-[95%]"
              //     tagsClass="!bg-tagsbg !h-5 !text-xs !py-0 !text-[#363F72] hover:!bg-gray-100"
              //     className="h-stretch flex-rowflex-wrap relative !m-0 flex rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
              //   />
              // </div>
              <div className=" mt-2 flex w-full items-center justify-between border-b border-[#D0D5DD] dark:!border-darkBorder px-4 ">
                <div className="flex w-[95%]  items-start justify-start gap-2  text-xs text-column ">
                  Bcc
                  <TagInput
                    id="description"
                    onChange={(item: any) => {
                      handleChangeBcc(item);
                    }}
                    placeholderTags={[
                      ...dropdownValue?.bcc?.map((email) => {
                        return { id: parseInt(email?.id), name: email?.name, prospect_name: email?.prospect_name ? email?.prospect_name : email?.name?.split('@')[0] };
                      }),
                    ]}
                    placeholder=""
                    pattern={
                      // eslint-disable-next-line no-useless-escape
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    }
                    extra="!border-none !mb-0  -mt-2 flex  !w-[95%]"
                    tagsClass="!bg-tagsbg !h-5 !text-xs !py-0 !text-[#363F72] hover:!bg-gray-100"
                    className="h-stretch flex-rowflex-wrap relative !m-0 flex rounded-lg border border-gray-200 dark:!bg-darkTwo bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
                  />
                </div>
              </div>
            )}
            {showCc && (
              // <div className="mt-3 flex items-start justify-start gap-2 border-b border-[#D0D5DD] px-4  pb-2 text-xs text-column ">
              //   <span className="text-xs  text-[#667085]">CC</span>
              //   <TagInput
              //     id="description"
              //     onChange={(item: any) => {
              //       handleChangeCC(item);
              //     }}
              //     placeholderTags={[
              //       ...dropdownValue?.cc?.map((email) => {
              //         return { id: parseInt(email?.id), name: email?.name, prospect_name: email?.prospect_name ? email?.prospect_name : email?.name?.split('@')[0] };
              //       }),
              //     ]}
              //     placeholder=""
              //     pattern={
              //       // eslint-disable-next-line no-useless-escape
              //       /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
              //     }
              //     extra="!border-none !mb-0  -mt-2 flex  !w-[95%]"
              //     tagsClass="!bg-tagsbg !h-5 !text-xs !py-0 !text-[#363F72] hover:!bg-gray-100"
              //     className="h-stretch flex-rowflex-wrap relative !m-0 flex rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
              //   />
              // </div>
              <div className=" mt-2 flex w-full items-center justify-between border-b border-[#D0D5DD] dark:!border-darkBorder px-4 ">
                <div className="flex w-[95%]  items-start justify-start gap-2  text-xs text-column dark:text-gray-300">
                  Cc
                  <TagInput
                    id="description"
                    onChange={(item: any) => {
                      handleChangeCC(item);
                    }}
                    placeholderTags={[
                      ...dropdownValue?.cc?.map((email) => {
                        return { id: parseInt(email?.id), name: email?.name, prospect_name: email?.prospect_name ? email?.prospect_name : email?.name?.split('@')[0] };
                      }),
                    ]}
                    placeholder=""
                    pattern={
                      // eslint-disable-next-line no-useless-escape
                      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    }
                    extra="!border-none !mb-0  -mt-2 flex  !w-[95%]"
                    tagsClass="!bg-tagsbg !h-5 !text-xs !py-0 !text-[#363F72] hover:!bg-gray-100"
                    className="h-stretch flex-rowflex-wrap relative !m-0 flex rounded-lg border border-gray-200  bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
                  />
                </div>
              </div>
            )}
            <div className="flex items-center justify-start border-t-2 bg-[#f1f3f6] dark:!border-darkBorder dark:bg-darkTwo px-4 py-1.5 text-xs text-column dark:text-purple-400">
              Subject <p className="mx-2 text-sm font-semibold text-[#363F72] dark:!text-white">{dropdownValue.subject}</p>
            </div>

            <div className="typer">
              <CustomSunEditor
                defaultValue={threadData?.message || ''}
                onFocus={() => {
                  // dispatch(setVariableArea('message'));
                }}
                height="5rem"
                editorRef={editorRef}
                onChange={(value: any) => {
                  handleMessageOnChange(value);
                }}
                leftContent={
                  <div className="send_message-btn-cls flex w-full justify-start !p-0">
                    <ButtonMenu
                      menuData={sendMessageData}
                      condition={showMenu}
                      position="left-0 bottom-11"
                      setCondition={setShowMenu}
                      Comp={
                        <Button
                          name="Send message"
                          disable={dropdownValue?.message?.length === 0 || isLoading}
                          loading={isLoading}
                          onClick={() => updateMessage()}
                          id={'send-message-btn'}
                        />
                      }
                    />
                  </div>
                }
              />
            </div>
            {/* <MessageBox
              editorRef={editorRef}
              value={dropdownValue?.message}
              editorClass="!border-none !h-[6rem]  py-3  editor-message-cls "
              toolBarClass="!py-0 pr-1"
              onChange={(e) => handleMessageOnChange(e)}
              rightContent={
                <div className="send_message-btn-cls flex w-full justify-end !p-0">
                  <ButtonMenu
                    menuData={sendMessageData}
                    condition={showMenu}
                    position="left-0 bottom-11"
                    setCondition={setShowMenu}
                    Comp={<Button name="Send message" disable={dropdownValue?.message?.length === 0 || isLoading} loading={isLoading} onClick={() => updateMessage()} />}
                  />
                </div>
              }
            /> */}
          </div>
          <div className="flex justify-end"></div>
        </div>
      </div>
    </div>
  );
};

export default Typer;
