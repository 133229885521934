import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import Button from 'common/sparkle-common/Button'
import SparkleRadio from 'common/sparkle-common/Radio';
import InputField from 'components/fields/InputField';
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store';
import { setSearchProspectsTool } from 'store/campaign-v2';
import { setCurrentStepCount } from 'store/custom';

interface propsType {
    isOpen: boolean;
    onClose: () => void;
}
const ApiKeyModal = (props: propsType) => {
    const { isOpen, onClose } = props;
    const tool_details = useAppSelector((state) => state?.campaignv2.prospects.search_prospects.tool_details);
    const dispatch = useAppDispatch();

    const handlClick = (name: string) => {
        dispatch(setSearchProspectsTool({ api_key_method: name }))
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchProspectsTool({ api_key: event?.target?.value }))
    }

    const disable = tool_details?.api_key_method === 'use_own_key' && tool_details?.api_key?.length === 0

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={'lg'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='!pb-1'>
                    <h5 className='text-[0.85rem] text-column font-medium'>API Key</h5>
                </ModalHeader>
                <hr />
                <ModalCloseButton />
                <ModalBody className='noscroll' padding={0} >
                    <div className='px-5 pt-5 pb-2 text-[0.85rem] text-column font-medium'>Choose {tool_details?.title} API Key account</div>
                    <hr />
                    <div className='mt-5 px-5'>
                        <div className='mt-2 flex gap-1.5 items-center text-[0.75rem] font-medium select-none cursor-pointer' onClick={() => handlClick('sparkle')}><SparkleRadio checked={tool_details?.api_key_method === 'sparkle'} /><span>Use Sparkle’s Apollo API key</span></div>
                        <div className='mt-2 flex gap-1.5 items-center text-[0.75rem] font-medium select-none cursor-pointer' onClick={() => handlClick('use_own_key')}><SparkleRadio checked={tool_details?.api_key_method === 'use_own_key'} /><span>Use my own Apollo API key</span></div>
                    </div>
                    {
                        tool_details?.api_key_method === 'use_own_key' &&
                        <div className='mt-2.5 px-5'>
                            <h4 className='text-[0.85rem] text-column font-medium'>Enter API key</h4>
                            <InputField label='' placeholder='Your API key' inputClass='!h-9 !rounded-md !mt-1' onChange={handleChange} value={tool_details?.api_key} />
                        </div>
                    }
                </ModalBody>

                <ModalFooter>
                    <div className='w-full flex items-center gap-3'>
                        <Button name='Cancel' color='text-heading' bg='white' customClass='w-full font-medium' onClick={onClose} />
                        <Button name='Add API Key' customClass='w-full' disable={disable} onClick={() => dispatch(setCurrentStepCount(3))} />
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ApiKeyModal