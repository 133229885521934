/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';

import * as XLSX from 'xlsx';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MdAdd, MdOutlineWatchLater } from 'react-icons/md';
import CustomFilter from 'components/customFilter';
import { FiSearch } from 'react-icons/fi';
import { FaCirclePause } from 'react-icons/fa6';
import { BlacklistRowObject } from 'common/dto/types/views/blacklist';
import { IoMdCloudDone } from 'react-icons/io';
import BlacklistRowSettings from './features/BlacklistRowSettings';
import GlobalOption from './features/GlobalOption';
// import { useNavigate } from 'react-router-dom';
import AddBlacklistModal from './features/AddBlacklistModal';
// import { jwtDecrypt } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import BlacklistGroupTags from './features/BlacklistGroupTags';
import TableLoader from 'components/Loaders/TableViewLoader';
import { calculateTimeAgo, jwtEncrypt, useDebounce, setAndGetQueryParams, checkRolePermissionStatus } from 'common/utils/utility';
import ResultModal from './features/ResultModal';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { useAppDispatch, useAppSelector } from 'store';
import { setFetchData } from 'store/blacklist';
import React from 'react';
import InfiniteScrollComponent from 'components/infinityScroll';
import { blacklistFilterDataList } from 'common/utils/page-filter';

const Blacklist = () => {
  // const navigate = useNavigate();
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 1,
    search: '',
    filter: '',
  });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const [showLoading, setShowLoading] = React.useState(true);
  const [load, setLoad] = React.useState(0);
  const [fetchTrigger, setFetchTrigger] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  const [optionData, setOptionData] = React.useState<any[]>([]);
  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showGlobalOpt, setGlobalOpt] = React.useState(false);
  const BlacklistSearchRef = React.useRef(null);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [fetchGroupListTrigger, setFetchGroupListTrigger] = React.useState(false);
  const dispatch = useAppDispatch();
  const custom = useAppSelector((state) => state?.custom);

  // const fetchGroupList = async () => {
  //   try {
  //     const data = await BaseApi.get(`/blacklist/grouplist`);
  //     // console.log('data', data);
  // const newGroups = data?.data.map((item: any, i: number) => ({
  //   id: `${i}`,
  //   name: item.name,
  //   uniqueName: item.uuid,
  // }));
  // const custom = useAppSelector((state) => state?.custom);
  const fetchGroupList = async () => {
    try {
      const response = await BaseApi.get(`/groups/linked/with/blacklist`);
      // console.log('response', response);

      if (response?.data?.data && response?.data?.data?.length) {
        const finalData = await response?.data?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
        );
        const states = response?.data?.data?.reduce((acc: any, val: any) => {
          acc[val.uuid] = { show: false, rename: false };
          return acc;
        }, {});
        dispatch(setFetchData({ finalData, states }));
      }
      // else {
      //   console.log('response', response);

      //   const payload: any = {
      //     finalData: [],
      //     state: [],
      //   };
      //   dispatch(setFetchData(payload));
      // }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchGroupList();
  }, [dispatch, fetchGroupListTrigger]);

  const grouplistTrigger = () => {
    setFetchGroupListTrigger((prev) => !prev);
  };

  const fetchList = async () => {
    const query = setAndGetQueryParams([
      { key: 'limit', value: queryParams?.limit },
      // { key: 'offset', value: queryParams?.offset },
      { key: 'search', value: queryParams?.search },
      { key: 'filter', value: queryParams?.filter },
    ]);
    try {
      setShowLoading(true);
      const response = await BaseApi.get(`/blacklist/list?${query}`);
      if (!response.data?.data) return setData({ data: [], count: 0 });
      if (response.data && response.data?.data?.length) {
        setData({
          data: response.data.data,
          count: response.data.total_records.count,
        });
      }
      setColumns(columnData);
    } catch (error) {
    } finally {
      setShowLoading(false);
    }
  };

  // fffffffffffffffff;
  React.useEffect(() => {
    fetchList();
  }, [load, fetchTrigger, queryParams?.filter, debouncedSearchValue, custom?.globalDependencyLoading]);

  const hanleDeleteGroupFromSenderList = (groupId: string) => {
    setData((prev) => {
      const updatedData = prev?.data?.map((list) => {
        const finalGroupList = list?.groups_list?.filter((group: any) => group?.uuid !== groupId);
        return {
          ...list,
          groups_list: finalGroupList,
        };
      });
      return {
        ...prev,
        data: updatedData,
      };
    });
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'deliverability_blacklist');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  // React.useEffect(() => {
  //   const tempColumns = columnData.filter((val) => {
  //     const tempId = columnOption.findIndex((option) => option.id === val.id);
  //     if (tempId === -1) return true;
  //     return columnOption[tempId]?.[val.id] === true;
  //   });
  //   setColumns(tempColumns);
  // }, [columnOption]);

  const handleBlacklistSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  React.useEffect(() => {
    setOptionData(blacklistFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
    });
  };

  const handleResultModal = async (uuid: string, blacklistValue: number) => {
    if (blacklistValue === 0) {
      return;
    }
    const fetchResult = await BaseApi.get(`/blacklist/result/${uuid}`);
    if (fetchResult.status === 200) {
      setIsOpen(true);
      setModalData(fetchResult?.data);
    }
  };

  const fetchMoreBlacklistData = React.useCallback(
    async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
      const fetchBlacklistData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/blacklist/list?${query}`);
          const responseData = response.data;
          if (!responseData?.data) return setData({ data: [], count: 0 });
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      data?.data?.length && data?.data?.length < data?.count && fetchBlacklistData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.offset]
  );

  const columnHelper = createColumnHelper<BlacklistRowObject>();
  const table = useReactTable({
    data: data.data,
    // data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-5 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-5 flex items-center pe-4 ps-2">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-[0.9rem] cursor-pointer border border-[#D0D5DD] accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('blacklist_name', {
      id: 'blacklist_name',
      enableSorting: false,
      header: () => <div className="text-xs font-medium text-[#667085]">Monitor</div>,
      cell: (info) => <p className="w-full truncate text-[0.65rem] font-semibold text-[#2B3674] dark:text-gray-200">{info.getValue()}</p>,
    }),
    columnHelper.accessor('blacklist_type', {
      id: 'blacklist_type',
      enableSorting: false,
      header: () => <div className="text-xs font-medium !text-[#667085]">Type</div>,
      cell: (info) => {
        return (
          <>
            <p className="w-full truncate text-xs text-[#667085] dark:text-gray-400">{info.getValue()}</p>
          </>
        );
      },
    }),
    columnHelper.accessor('groups_list', {
      id: 'groups_list',
      enableSorting: false,
      header: () => <div className={`relative text-xs font-medium text-[#667085]`}>Group</div>,
      cell: (row) => {
        return (
          <>
            <BlacklistGroupTags
              groupTags={row.getValue()}
              row={row?.row?.original}
              fetchList={() => { }}
              grouplistTrigger={grouplistTrigger}
              fetchGroupList={fetchGroupList}
              hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList}
            />
          </>
        );
      },
    }),
    columnHelper.accessor('no_of_blacklist_count', {
      id: 'no_of_blacklist_count',
      enableSorting: false,
      header: () => <div className="relative text-xs font-medium !text-[#667085]">No of Blacklists</div>,
      cell: (info) => {
        const blacklistValue = info.getValue();
        const defaultValue = info.row?.original.total_count;
        const textColor = blacklistValue === 0 ? '#4CBE67' : '#F50B0B';
        return (
          <p
            className={`w-full truncate text-xs font-semibold ${blacklistValue === 0 ? 'cursor-default' : 'cursor-pointer'}`}
            style={{ color: textColor }}
            onClick={() => {
              handleResultModal(info?.row.original?.uuid, blacklistValue);
            }}
          >
            {blacklistValue}/{defaultValue}
          </p>
        );
      },
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: () => <div className="text-xs font-medium text-[#667085]">Added On</div>,
      cell: (info) => {
        const date = moment(info.getValue());
        const formattedDate = date.format('DD MMM YYYY');
        return <p className="w-full truncate text-xs font-semibold text-[#2B3674] dark:text-gray-200">{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('last_checked', {
      id: 'last_checked',
      header: () => <div className="text-xs font-medium text-[#667085]">Last Checked</div>,
      cell: (info) => {
        const utcDateString = info.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));
        return (
          <div className="group relative w-full text-sm font-semibold">
            <span className="text-[ #2B3674] w-full truncate text-xs font-semibold">{calculateTimeAgo(info.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('blacklist_status', {
      id: 'blacklist_status',
      enableSorting: false,
      header: () => <div className="relative text-xs font-medium text-[#667085]">Status</div>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === 0 && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                <MdOutlineWatchLater className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color status-index-${info?.row?.id}`}>Pending</h5>
              </div>
            )}
            {info.getValue() === 1 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]">
                <IoMdCloudDone color="#12B76A" />
                <span className={`status-index-${info?.row?.id}`}>Active</span>
              </div>
            )}
            {info.getValue() === 2 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#F2F4F7] px-2 py-1 text-[.65rem] font-semibold text-[#344054]">
                <FaCirclePause />
                <span className={`status-index-${info?.row?.id}`}>Paused</span>
              </div>
            )}
            {info.getValue() === 3 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.65rem] font-semibold text-[#029A48]" >
                <FaCirclePause />
                <span className={`status-index-${info?.row?.id}`}>Resume</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative font-thin"></div>,
      cell: (row) => {
        const blacklistStatus = row.row.original.blacklist_status;
        return (
          <div onClick={(event) => event?.stopPropagation()}>
            <BlacklistRowSettings
              status={1}
              id={row.row.id}
              uuidRow={row.getValue()}
              rowData={row.row.original}
              table={table}
              setData={setData}
              setLoad={setLoad}
              blacklistStatus={blacklistStatus}
              handleListPause={handleListPause}
              handleListDelete={handleListDelete}
              handleListResume={handleListResume}
              handleListDownload={handleListDownload}
            />
          </div>
        );
      },
    }),
  ];

  // React.useEffect(() => {
  //   setColumns(columnData);
  //   const tableSelectedData = table
  //     .getSelectedRowModel()
  //     .rows.map((val: any) => val.original);
  //   // setSelectedData(tableSelectedData);

  //   if (table.getSelectedRowModel().rows.length > 1) setGlobalOpt(true);
  //   else setGlobalOpt(false);
  // }, [table.getSelectedRowModel()]);
  // global delete and download
  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setSelectedData(tableSelectedData);
    if (table.getSelectedRowModel().rows.length > 1) setGlobalOpt(true);
    else setGlobalOpt(false);
  }, [table.getSelectedRowModel()]);

  const handleListDelete = async (uuid?: Array<string>) => {
    try {
      const response = await BaseApi.delete(`/blacklist/list/delete`, {
        data: {
          delete_uuid: uuid ? uuid : selectedData?.map((val: { uuid: string }) => val.uuid),
        },
      });
      if (response.status === 200) {
        setLoad((prev: number) => prev + 1);
        table.reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleListPause = async (uuid?: Array<string>) => {
    const response = await BaseApi.patch(`/blacklist/list/pause`, {
      pause_uuid: uuid ? uuid : selectedData?.map((val: { uuid: string }) => val.uuid),
    });
    if (response.status === 200) {
      setLoad((prev: number) => prev + 1);
      table.reset();
    }
  };

  const handleListResume = async (uuid?: Array<string>) => {
    const response = await BaseApi.patch(`/blacklist/resume/validate`, {
      resume_uuid: uuid ? uuid : selectedData?.map((val: { uuid: string }) => val.uuid),
    });
    if (response.status === 200) {
      setLoad((prev: number) => prev + 1);
      table.reset();
    }
  };

  const handleListRecheck = async (uuid?: Array<string>) => {
    const response = await BaseApi.patch(`/blacklist/recheck/validate`, {
      recheck_uuid: uuid ? uuid : selectedData?.map((val: { uuid: string }) => val.uuid),
    });
    if (response.status === 200) {
      setLoad((prev: number) => prev + 1);
      table.reset();
    }
  };

  const handleListDownload = async (uuid?: Array<string>) => {
    const uuids = uuid ? jwtEncrypt(uuid) : jwtEncrypt(selectedData?.map((val: { uuid: string }) => val.uuid));
    const response = await BaseApi.get(`/blacklist/excelresult/${uuids}`);
    console.log('response', response);
    const listData = response?.data;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(listData);
    XLSX.utils.book_append_sheet(wb, ws, 'Blacllist Validation');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'blacklist_report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const handleOptions = (option: string) => {
    if (option === 'delete') {
      handleListDelete();
    }
    if (option === 'download') {
      handleListDownload();
    }
    if (option === 'pause') {
      handleListPause();
    }
    if (option === 'resume') {
      handleListResume();
    }
    if (option === 'recheck') {
      handleListRecheck();
    }
  };
  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm  dark:bg-darkThree dark:text-white sm:overflow-x-auto `}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => BlacklistSearchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={BlacklistSearchRef}
                type="text"
                placeholder="Search"
                id="blacklist-search-input"
                className="w-full text-sm font-bold
                text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
                onChange={handleBlacklistSearch}
              />
            </div>
            <CustomFilter optionData={optionData} handleFilter={handleFilter} />
          </div>
          <div className="flex items-center gap-1">
            {/* Role Access for Creating Ticket */}
            {/* {roleStatus?.is_owner && (
              <button
                className="flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => navigate('/true-verifier')}
              >
                <FaRegNoteSticky className="float-right text-lg" /> <span className="text-nowrap text-[11px]">View Tickets</span>
              </button>
            )} */}
            {/* Role Access for Adding IP or Doamin */}
            {(roleStatus?.is_owner || roleStatus?.add) && (
              <button
                id="blacklist-add-btn"
                className=" flex w-[6.5rem] items-center justify-center gap-0.5 rounded-md bg-brand-500 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => setShowCreateModal(true)}
              >
                <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add</span>
              </button>
            )}
          </div>
        </div>
        <div className="">
          {!showLoading ? (
            <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
              <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`
                       
                        mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne">
                          {row.getVisibleCells().map((cell, i) => {
                            return (
                              <td
                                key={cell.id}
                                className={`border-white/0 py-4 
                               text-heading dark:text-white 
                               ${i === 1 ? 'w-56' : i === 2 ? 'w-52' : i === 3 ? 'w-[521px]' : i === 4 ? 'w-32' : i === 5 ? 'w-36' : i === 6 ? 'w-32' : i === 7 ? 'w-44' : 'w-11'}
                               `}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={fetchMoreBlacklistData}
                loader={
                  <TableLoader
                    width="w-full"
                    border="border border-gray-100"
                    column={10}
                    row={1}
                    skeletonHeight="h-2"
                    padding="px-5 py-4"
                    columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                  />
                }
              />
            </div>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={10}
              row={13}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
            />
          )}
        </div>
        {showGlobalOpt && <GlobalOption handleOptions={handleOptions} selectedCount={selectedData}/>}
      </div>
      <AddBlacklistModal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)} onSuccess={() => setFetchTrigger(!fetchTrigger)} />
      <ResultModal isOpen={isOpen} onClose={() => setIsOpen(false)} modalData={modalData} />
    </>
  );
};

export default Blacklist;
