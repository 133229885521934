import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/modal'
import React, { useState } from 'react'
import { ImLinkedin } from 'react-icons/im'
import { IoCallSharp, IoMail } from 'react-icons/io5'
import { MdOutlineSms, MdWhatsapp } from 'react-icons/md'
import { RiTwitterXLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'

import Button from 'common/sparkle-common/Button'
import { useAppSelector } from 'store'
import { addSequence, setCreateSequenceModal } from 'store/campaign-v2'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import CustomBadge from 'components/badges/CustomBadge'

const CreateSequence = () => {
  const [type, setType] = useState({ channel: 'email', channelAction: '', trigger: '' })
  const state = useAppSelector(state => state?.campaignv2)
  const id = state?.sequence.findIndex((item) => item.id === state?.current_sequence_id)
  const content = state?.sequence[id]
  const dispatch = useDispatch()

  const handlechannelActionSelection = (channelName: string, channelActionName: string) => {
    if (type?.channel !== channelName) return
    setType(prev => ({ ...prev, channelAction: prev.channelAction === channelActionName ? '' : channelActionName }))
  }

  const handleTriggerSelection = (channelName: string, triggerName: string) => {
    if (type?.channel !== channelName) return
    setType(prev => ({ ...prev, trigger: prev.trigger === triggerName ? '' : triggerName }))
  }

  const handleInsert = () => {
    dispatch(setCreateSequenceModal());
    dispatch(addSequence(type))
  }
  return (
    <Modal isOpen={state?.createSequenceModal} onClose={() => dispatch(setCreateSequenceModal())} size={'3xl'} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <h5 className='text-xs text-column '>Channels</h5>
          <div className='flex gap-2 py-3 items-center justify-around cursor-default select-none'>
            <button id='create_sequence_email_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.channel === 'email' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => setType(prev => ({ ...prev, channel: 'email', channelAction: '' }))}><IoMail className='text-blueSecondary' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Email</h5></button>
            <button disabled={true} id='create_sequence_whatsapp_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center  ${type.channel === 'whatsapp' ? 'bg-hover border-brand-500' : 'bg-white'} relative`} onClick={() => setType(prev => ({ ...prev, channel: 'whatsapp', channelAction: '' }))}><MdWhatsapp className='fill-white bg-green-500 rounded-full' /><h5 className='text-xs font-medium text-column  '>Whatsapp </h5> <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 absolute  -top-3 right-0 !font-semibold text-[.625rem] !px-2 !py-0.5'/></button>
            <button disabled={true} id='create_sequence_calls_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center  ${type.channel === 'call' ? 'bg-hover border-brand-500' : 'bg-white'} relative`} onClick={() => setType(prev => ({ ...prev, channel: 'call', channelAction: '' }))}><IoCallSharp className='text-[#04C969]' /><h5 className='text-xs font-medium text-column  '>Calls </h5>  <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 absolute -top-3 right-0 !font-semibold text-[.625rem] !px-2 !py-0.5'/> </button>
            <button disabled={true} id='create_sequence_linkedin_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center  ${type.channel === 'linkedin' ? 'bg-hover border-brand-500' : 'bg-white'} relative`} onClick={() => setType(prev => ({ ...prev, channel: 'linkedin', channelAction: 'direct_message' }))}><ImLinkedin className='rounded-full text-blue-700' /><h5 className='text-xs font-medium text-column  '>LinkedIn </h5> <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 absolute -top-3 right-0 !font-semibold text-[.625rem] !px-2 !py-0.5'/>  </button>
            <button disabled={true} id='create_sequence_twitter_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center  ${type.channel === 'twitter' ? 'bg-hover border-brand-500' : 'bg-white'} relative`} onClick={() => setType(prev => ({ ...prev, channel: 'twitter', channelAction: '' }))}><RiTwitterXLine /><h5 className='text-xs font-medium text-column  '>Twitter </h5>  <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 absolute -top-3 right-0 !font-semibold text-[.625rem] !px-2 !py-0.5'/></button>
            <button disabled={true} id='create_sequence_sms_btn' className={`group border rounded-md px-4 py-2 flex gap-2 items-center justify-center  ${type.channel === 'sms' ? 'bg-hover border-brand-500' : 'bg-white'} relative`} onClick={() => setType(prev => ({ ...prev, channel: 'sms', channelAction: '' }))}><MdOutlineSms className='text-[#FFA51B]' /><h5 className='text-xs font-medium text-column   '>SMS </h5>  <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 absolute -top-3 right-0 !font-semibold text-[.625rem] !px-2 !py-0.5'/></button>
          </div>
          {type?.channel === 'linkedin' && <>
            <div className='flex flex-wrap gap-2 pl-2 py-4 items-center cursor-default select-none'>
              <div className={`group border rounded-md px-4 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.channelAction === 'direct_message' ? 'bg-hover border-brand-500' : 'bg-white'}`} id='direct_message' onClick={() => handlechannelActionSelection('linkedin', 'direct_message')}><ImLinkedin className='text-[#1275B1] rounded-full size-4' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Direct message </h5></div>
              <div className={`group border rounded-md px-4 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.channelAction === 'connection_request' ? 'bg-hover border-brand-500' : 'bg-white'}`} id='connection_request' onClick={() => handlechannelActionSelection('linkedin', 'connection_request')}><ImLinkedin className='text-[#1275B1] rounded-full size-4 ' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Connection Request</h5></div>
              <div className={`group border rounded-md px-4 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${(type.channelAction === 'profile_view') ? 'bg-hover border-brand-500' : 'bg-white'}`} id='profile_view' onClick={() => handlechannelActionSelection('linkedin', 'profile_view')}><ImLinkedin className='text-[#1275B1] rounded-full size-4 ' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Profile view</h5></div>
              <div className={`group border rounded-md px-4 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${(type.channelAction === 'post_engagement') ? 'bg-hover border-brand-500' : 'bg-white'}`} id='post_engagement' onClick={() => handlechannelActionSelection('linkedin', 'post_engagement')}><ImLinkedin className='text-[#1275B1] rounded-full size-4 ' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Post Engagement</h5></div>
            </div>
            <div className='text-[#6172F3] flex items-center gap-2.5 border border-[#C7D7FE] bg-[#F5F8FF] p-3 ml-2 rounded-md w-fit'>
              <IoIosInformationCircleOutline className='size-4' />
              <h5 className='text-xs font-medium'>Install Sparkle Plugin to automate bulk Linkedin task execution</h5>
              <span className='text-xs font-medium'>Learn More</span>
            </div>
          </>}
        </ModalHeader>
        <ModalCloseButton />
        {state?.current_sequence_id && !(content?.next || content?.trigger || !content?.type) && <ModalBody>
          <h5 className='text-xs text-column font-semibold'>Triggers</h5>
          <div className='flex flex-wrap gap-2 py-4 items-center justify-start cursor-default select-none'>
            <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'opened' && type.channel === 'email' ? 'bg-hover border-brand-500' : 'bg-white'}`} id='email_opened' onClick={() => handleTriggerSelection('email', 'opened')}><IoMail className='text-blueSecondary' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Opened email</h5></div>
            <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'click_on_link' && type.channel === 'email' ? 'bg-hover border-brand-500' : 'bg-white'}`} id='email_click_on_link' onClick={() => handleTriggerSelection('email', 'click_on_link')}><IoMail className='text-blueSecondary' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Clicked on link in email</h5></div>
            <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${((type.trigger === 'unsubscribe') && (type.channel === 'email')) ? 'bg-hover border-brand-500' : 'bg-white'}`} id='email_unsubscribe' onClick={() => handleTriggerSelection('email', 'unsubscribe')}><IoMail className='text-blueSecondary' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Unsub from email</h5></div>
            <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${(type.trigger === 'replied' && type.channel === 'email' && type.channel === 'email') ? 'bg-hover border-brand-500' : 'bg-white'}`} id='email_replied' onClick={() => handleTriggerSelection('email', 'replied')}><IoMail className='text-blueSecondary' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Replied to Email</h5></div>
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'booked_meeting' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('call', 'booked_meeting')}><IoCallSharp className='text-[#04C969]' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Booked a meeting</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'accepted_connection' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('linkedin', 'accepted_connection')}><ImLinkedin className='rounded-[#1275B1]ull text-blue -700' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Accepted Linkedin connect request</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'replied' && type.channel === 'linkedin' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('linkedin', 'replied')}><ImLinkedin className='rounded-[#1275B1]ull text-blue -700' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Replied to Linkedin DM / Inmail</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'replied' && type.channel === 'whatsapp' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('whatsapp', 'replied')}><MdWhatsapp className='fill-white bg-green-500 rounded-full' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Replied to Whatsapp Message</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'attend' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('call', 'attend')}><IoCallSharp className='text-[#04C969]' /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Answered Call</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'replied' && type.channel === 'twitter' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('twitter', 'replied')}><RiTwitterXLine /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Replied to twitter DM</h5></div> */}
            {/* <div className={`group border rounded-md px-3 py-2.5 flex gap-2 items-center justify-center hover:border-blueSecondary hover:bg-hover ${type.trigger === 'stop' ? 'bg-hover border-brand-500' : 'bg-white'}`} onClick={() => handleTriggerSelection('twitter', 'stop')}><RiTwitterXLine /><h5 className='text-xs font-medium text-column group-hover:text-[#4318FF] '>Stop</h5></div> */}
          </div>
        </ModalBody>}
        <ModalFooter>
          <div className='flex w-full gap-2'>
            <Button onClick={() => dispatch(setCreateSequenceModal())} name='Cancel' color='paused-color' bg='white' customClass='gap-1 w-full' />
            <Button id='create_sequence_insert_btn' onClick={handleInsert} name='Insert' customClass='gap-1 w-full' />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateSequence