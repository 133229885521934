import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, toastState } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';

const OrganizationListDropdown = (props: any) => {
  const { onClick, selectedOrganizationList } = props;
  const [open, setOpen] = React.useState({
    is_any_of: false,
  });

  const organizationsDemoData = [
    {
      name: 'Google',
      domain: 'google.com',
      website_url: 'http://www.google.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c52bd160888e0001b18ae7/picture',
      id: '5fc93db64c38d300d6aa24e6',
    },
    {
      name: 'Amazon',
      domain: 'amazon.com',
      website_url: 'http://www.amazon.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c0a1a485878600016dd0d1/picture',
      id: '5f2a39cb77a7440112460cf5',
    },

    {
      name: 'LinkedIn',
      domain: 'linkedin.com',
      website_url: 'http://www.linkedin.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66b37b7f113f670001b88ef6/picture',
      id: '5fca408962ba9b00f6d3c961',
    },
    {
      name: 'TED Conferences',
      domain: 'ted.com',
      website_url: 'http://www.ted.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c4c3ce45bcca00010115d1/picture',
      id: '54a1215e69702d77c2eee302',
    },
    {
      name: 'Microsoft',
      domain: 'microsoft.com',
      website_url: 'http://www.microsoft.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c1f44211695f00019cee4b/picture',
      id: '62337760d02af100a5ca2468',
    },
    {
      name: 'Unilever',
      domain: 'unilever.com',
      website_url: 'http://www.unilever.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66c2a23e19c66600010cca27/picture',
      id: '6131f47c6f7265000138e775',
    },
    {
      name: 'Apple',
      domain: 'apple.com',
      website_url: 'http://www.apple.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66baa34ec1a3a00001900ea3/picture',
      id: '5fcd2cf3ed78c700f9383e4e',
    },
    {
      name: 'Forbes',
      domain: 'forbes.com',
      website_url: 'http://www.forbes.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66baa3e250f3e90001020960/picture',
      id: '54a1173069702d4926150100',
    },
    {
      name: 'IBM',
      domain: 'ibm.com',
      website_url: 'http://www.ibm.com',
      logo_url: 'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/66a342d6e8dfba0001dcce92/picture',
      id: '5d33d0d1a3ae6113e19b1674',
    },
  ];

  const handleSearchChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const [handleState, setHandleState] = React.useState({
    loading: false,
    search: '',
    list: organizationsDemoData,
    selectedList: [],
  });


  React.useEffect(() => {
    // selectedOrganizationList?.length &&
      setHandleState((prevState) => ({
        ...prevState,
        selectedList: selectedOrganizationList,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationList?.length]);

  React.useEffect(() => {
    handleState?.selectedList?.length && onClick(handleState?.selectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.selectedList?.length]);

  const handleSearchOrganizationData = async (searchData: string) => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await BaseApi.post(`/prospects/organization/search?search=${searchData}`, { organization_list_type: 'search' });
      if (!response.error) {
        setHandleState((prevState) => ({
          ...prevState,
          list: response?.data,
        }));
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      } else {
        toast.error(_errorHandler(response?.message), toastState.error);
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      console.log('', error);
    }
  };

  const removeFromIsAnyOfData = (id: string, event: any) => {
    event.stopPropagation();
    setHandleState((prevState) => ({
      ...prevState,
      selectedList: prevState?.selectedList?.filter((item) => item.id !== id),
    }));
  };
  return (
    <div>
      <CustomMenu
        open={open?.is_any_of}
        onOpen={() => setOpen((prev: any) => ({ ...prev, is_any_of: true }))}
        onClose={() => {
          setOpen((prev: any) => ({ ...prev, is_any_of: false }));
          setHandleState((prevState) => ({
            ...prevState,
            search: '',
            list: organizationsDemoData,
          }));
        }}
        btnContent={
          <div className="noscroll">
            <div className="flex w-72 gap-1 overflow-x-auto ">
              {handleState?.selectedList?.length ? (
                handleState?.selectedList
                  ?.filter((item, index, self) => index === self.findIndex((items) => items?.id === item.id))
                  .map((item) => (
                    <div className="group relative  flex  items-center  rounded-md  bg-gray-100 px-1 dark:bg-navy-900 dark:text-white">
                      {item?.name}
                      <span
                        className="-mt-0.5 flex transform cursor-pointer items-center justify-center rounded-full text-xs  font-bold text-red-500 opacity-0 transition-all duration-300 group-hover:ml-1   group-hover:opacity-100"
                        onClick={(event) => removeFromIsAnyOfData(item?.id, event)}
                      >
                        x
                      </span>
                    </div>
                  ))
              ) : (
                <div className="flex items-center gap-2 dark:!bg-navy-900 dark:text-white">{'select'}</div>
              )}
            </div>
          </div>
        }
        btnClassName={`w-full noscroll justify-between dark:!bg-navy-900 items-center !h-9 !text-column !text-sm !rounded-md !font-normal truncate !bg-white ${``}`}
        menuListClassName="w-full !mt-0.5 !p-0"
        rightIcon={<BiChevronDown size={18} />}
        btnId="industry_drop_down"
      >
        <div className="!max-h-48 overflow-y-auto">
          <div className="w-11-/12 relative m-2 flex items-center rounded-md border">
            {/* <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} /> */}
            <input
              type="text"
              placeholder="Search"
              id="search"
              value={handleState?.search}
              onChange={handleSearchChange}
              className="input input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none"
            />
            <Button
              disable={handleState?.loading}
              loading={handleState?.loading}
              id="personal-details-save-btn"
              name={``}
              LeftIcon={<FiSearch className="pointer-events-none absolute mx-2 text-white" size={16} />}
              customClass="text-[.8125rem] w-8 h-6 mr-0.5 active:scale-95 transition-transform duration-150 ease-out"
              onClick={() => handleSearchOrganizationData(handleState?.search)}
            />
          </div>
          {handleState?.list?.length ? (
            handleState?.list?.map((items: any) => (
              <button
                key={items?.id}
                onClick={() => {
                  setHandleState((prevState) => ({
                    ...prevState,
                    selectedList: [...prevState.selectedList, { id: items?.id, name: items?.name }],
                  }));
                }}
                id={items?.name}
                className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary"
              >
                {`${items?.name}`}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
      </CustomMenu>
    </div>
  );
};

export default OrganizationListDropdown;
