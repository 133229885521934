import { Avatar } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa6'
import { toast } from 'react-toastify'

import Groups from './Groups'
import { useAppSelector } from 'store'
import { _errorHandler, generateUUID, toastState } from 'common/utils/utility'
import PayAsYouGoPopUp from 'components/PayAsYouGo/PayAsYouGoPopUp'
import { BaseApi } from 'common/api/common/base-api'
import { useDispatch } from 'react-redux'
import { setSettingsData } from 'store/campaign-v2'
import { CiCircleInfo } from 'react-icons/ci'
import ArrowedTooltip from 'components/ArrowedTooltip'

interface FieldsDTO {
    firstName: string;
    lastName: string;
    id: string;
}

const StepSettings = () => {

    const [showModal, setShowModal] = useState(false)
    const [options, setOptions] = useState({ email: false, whatsapp: false, text: false, linkedIn: false, twitter: false })
    const [prevAlias, setPrevAlias] = useState([])
    const [addPrevData, setAddPrevData] = useState(false)
    const [fields, setFields] = useState<FieldsDTO[]>(Array.from({ length: 1 }).map(() => ({ id: generateUUID(), firstName: '', lastName: '' })));
    const senderList = useAppSelector((state) => state.campaignv2.senderList)
    const groupList = useAppSelector((state) => state.campaignv2.groupList)
    // const prospects = useAppSelector((state) => state.campaignv2.prospects)
    const settings = useAppSelector((state) => state.campaignv2.settings)
    const dispatch = useDispatch()
    const { selectedSenderList, selectedGroupList } = settings
    const closeMenu = (type: keyof typeof options) => {
        setOptions(prev => ({ ...prev, [type]: !prev[type] }))
    }

    // selected sender mail
    const selectedsenderUuid = Object.entries(selectedSenderList).map((item) => {
        if (item[1]) {
            return item[0]
        } else {
            return null
        }
    }).filter(Boolean)
    const selectedSenderMail = senderList?.length ? senderList?.find((item) => item?.uuid === selectedsenderUuid[0]) : ''

    const selectedGroupUuid = Object.entries(selectedGroupList).map((item) => {
        if (item[1]) {
            return item[0]
        } else {
            return null
        }
    }).filter(Boolean)
    const selectedGroupMail = groupList?.length ? groupList?.find((item) => item?.uuid === selectedGroupUuid[0]) : ''

    const fetchAliasList = async () => {
        try {
            const response = await BaseApi.get('/sender-accounts/get/email/alias/sender/account/list')
            if (!response.error) {
                setPrevAlias(response?.data)
            }
            else {
                toast.error(response?.error, toastState.error)
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const handlePersonas = async (personas: FieldsDTO[]) => {
        try {
            let data: any;
            if (personas?.length) {
                // const prospect_count = prospects?.prospects_list?.length
                const personas_list = personas?.map(({ firstName, lastName }) => ({ first_name: firstName, last_name: lastName }))
                const response = await BaseApi.patch('/organization/alias', personas_list[0])
                data = response?.data;
                if (data?.error) {
                    toast.error(_errorHandler(response?.message), toastState.error)
                    return false
                }
                dispatch(setSettingsData({ email_alias: true, email_alias_name: personas_list[0]?.first_name + ' ' + personas_list[0]?.last_name }))
                toast.success('Email Alias Updated', toastState.success)
            }
            // if (addPrevData) {
            //     data = [...data, ...prevAlias]
            // }
            // const emailAliasList: { [key: string]: boolean } = {}
            // data?.forEach((item: string) => {
            //     emailAliasList[item] = true
            // })
            // dispatch(setSettingsData({ selectedEmailAliasList: emailAliasList, email_alias: true }))
            return true
        }
        catch (err) {
            console.error(err)
            return false
        }
    }

    const handleSenderAccounts = (type: string) => {
        const email_alias = type === 'alias' ? true : false;
        dispatch(setSettingsData({ email_alias }))
    }

    // React.useEffect(
    //     () => {
    //         dispatch(setSettingsData({ selectedSenderList: {}, selectedGroupList: {} }))
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //     }, [senderSelection]
    // )


    return (
        // <div className='h-[480px] w-[740px] rounded-md shadow-shade py-[1.2rem] relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white select-none'>
        <div>
            <hr />
            <div className='flex px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]'>
                    <h5 className='text-column font-medium text-[0.83rem]'>Sender account*</h5>
                    <p className='text-[0.62rem] leading-[0.8rem] text-column'>Use a recommended sequence of integrations to get the best
                        coverage for a data point</p>
                </div>
                <div className='relative flex items-center gap-4 w-[25rem]'>
                    <div className='flex items-center gap-1'>
                        <input type="radio" className='accent-[#4318FF]' id='sender' name='senderAccounts' onChange={() => { handleSenderAccounts('sender') }} value={settings?.email_alias?.toString()} checked={!settings?.email_alias} />
                        <label htmlFor='sender' className='text-[0.62rem] leading-[0.8rem] text-column'>Use accounts owned/added by me</label>
                    </div>
                    <div className='flex items-center gap-1' onClick={() => setShowModal(true)}>
                        <input type="radio" className='accent-[#4318FF]' id='alias' name='senderAccounts' onChange={() => { handleSenderAccounts('alias') }} value={settings?.email_alias?.toString()} checked={settings?.email_alias} />
                        <label htmlFor='alias' className='text-[0.62rem] leading-[0.8rem] text-column'>Use Sparkle.io email IDs (pay as you go)</label>
                    </div>
                </div>
            </div>
            <hr />
            {!settings?.email_alias && <> <div className='flex px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]'>
                    <h5 className='text-column font-medium text-[0.83rem]'>Sender for Email steps*</h5>
                    <p className='text-[0.62rem] leading-[0.8rem] text-column'>Use a recommended sequence of integrations to get the best
                        coverage for a data point</p>
                </div>
                <div className='relative'>
                    <div className='flex gap-1.5 items-center border border-[#D0D5DD] rounded-md px-3 py-2 w-[25rem] cursor-pointer' id='email-sender-select' onClick={() => setOptions(prev => ({ ...prev, email: !prev.email }))}>
                        <div className='flex items-center w-full truncate'>
                            {selectedSenderMail && <>
                                <Avatar src={selectedSenderMail?.picture || ''} name={selectedSenderMail?.name} bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                                <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{selectedSenderMail?.name} ({selectedSenderMail?.account_details?.email})</h5>
                            </>}

                            {!selectedSenderMail && selectedGroupMail && <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{selectedGroupMail?.name} ({selectedGroupMail?.account_details?.email})</h5>}

                            {!selectedSenderMail && !selectedGroupMail && <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >Select</h5>}
                        </div>
                        <div className='flex items-center gap-1'>
                            {selectedsenderUuid.length > 1 ? <span className='p-1 rounded-full text-[0.6rem] font-semibold text-[#175CD3] bg-[#EFF8FF] h-5 w-fit flex items-center justify-center box-border text-nowrap'>+{selectedsenderUuid?.length - 1} Emails</span> : <></>}
                            {selectedGroupUuid.length > 1 ? <span className='p-1 rounded-full text-[0.6rem] font-semibold text-[#B54708] bg-[#FFFAEB]  h-5 w-fit flex items-center justify-center box-border text-nowrap'>+{selectedGroupUuid?.length - 1} Groups</span> : <></>}
                        </div>
                        <FaAngleDown className='size-2.5' />
                    </div>
                    {options.email && <Groups type='email' onClose={closeMenu} />}
                </div>
            </div>
            </>}
            {settings?.email_alias && <> <div className='flex px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]'>
                    <h5 className='text-column font-medium text-[0.83rem]'>Persona</h5>
                </div>
                <div className='relative'>
                    <div className='flex gap-1.5 items-center border border-[#D0D5DD] rounded-md px-3 py-2 w-[25rem] cursor-pointer' id='email-alias-select'>
                        <div className='flex items-center w-full truncate'>
                            <>
                                <Avatar src={selectedSenderMail?.picture || ''} name={`${settings?.email_alias_name || ''}`} bg={'#00B4DB'} size={'xs'} style={{ height: '20px', width: '20px' }} />
                                <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >{settings?.email_alias_name || 'Select'}</h5>
                            </>

                            {/* {!selectedSenderMail && !selectedGroupMail && <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >Select</h5>} */}
                        </div>
                        <div className='flex relative items-center gap-1 group'>
                            <CiCircleInfo className='text-column' />
                            <ArrowedTooltip className='hidden group-hover:block text-nowrap w-fit px-3 text-[0.65rem]' data={'You can set only one persona per organization'} positionAxis='left-1/2 -translate-x-1/2 top-6' />
                        </div>
                    </div>
                </div>
            </div>
            </>}
            {/* <div className='flex     px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]'>
                    <h5 className='text-column font-medium text-[0.83rem]'>Sender for Whatsapp steps</h5>
                    <p className='text-[0.62rem] leading-[0.8rem] text-column'>Use a recommended sequence of integrations to get the best
                        coverage for a data point</p>
                </div>
                <div className='flex relative justify-between items-center border border-[#D0D5DD] rounded-md px-3 py-2 w-[25rem] cursor-pointer' onClick={() => toast.info('Feature coming soon...', toastState.info)}>
                    <div className='flex items-center'>
                        <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >Select</h5>
                        <ImLinkedin className='rounded-full text-blue-700' />
                        <h5 className='text-[0.7rem] font-medium text-column ml-2'>91321321321</h5>
                    </div>
                    <FaAngleDown className='size-2.5' />
                    {options.whatsapp && <Groups type='whatsapp' onClose={()=>{}}/>}
                </div>
            </div> */}
            {/* <hr />
            <div className='flex px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]  '>
                    <h5 className='text-column font-medium text-[0.83rem]'>Sender for Text steps</h5>
                    <p className='text-[0.62rem] leading-[0.8rem] text-column'>Use a recommended sequence of integrations to get the best
                        coverage for a data point</p>
                </div>
                <div className='flex relative justify-between items-center border border-[#D0D5DD] rounded-md px-3 py-2 w-[25rem] cursor-pointer' onClick={() => toast.info('Feature coming soon...', toastState.info)}>
                    <div className='flex items-center'>
                        <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >Select</h5>
                        <ImLinkedin className='rounded-full text-blue-700' />
                        <h5 className='text-[0.7rem] font-medium text-column ml-2'>91321321321</h5>
                    </div>
                    <FaAngleDown className='size-2.5' />
                    {options.text && <Groups />}
                </div>
            </div> */}
            {/* <hr />
            <div className='flex px-5 mt-7 justify-between items-center mb-2'>
                <div className='w-72 leading-[1.35rem]  '>
                    <h5 className='text-column font-medium text-[0.83rem]'>Sender for LinkedIn steps</h5>
                    <p className='text-[0.62rem] leading-[0.8rem] text-column'>Use a recommended sequence of integrations to get the best
                        coverage for a data point</p>
                </div>
                <div className='flex relative justify-between items-center border border-[#D0D5DD] rounded-md px-3 py-2 w-[25rem] cursor-pointer' onClick={() => toast.info('Feature coming soon...', toastState.info)}>
                    <div className='flex items-center'>
                        <h5 className='text-[0.825rem] text-column ml-2 w-[98%] truncate' >Select</h5>
                        <ImLinkedin className='rounded-full text-blue-700' />
                        <h5 className='text-[0.825rem] text-column ml-2'>../Jayvyas</h5>
                    </div>
                    <FaAngleDown className='size-2.5' />
                    {options.linkedIn && <Groups />}
                </div>
            </div> */}
            {showModal && !settings?.email_alias_name && <PayAsYouGoPopUp isOpen={showModal} onClose={() => { setShowModal(false) }} onCloseClick={() => handleSenderAccounts('sender')} callback={handlePersonas} initialLoad={fetchAliasList} prevData={prevAlias} addPrevData={addPrevData} setAddPrevData={setAddPrevData} fields={fields} setFields={setFields} />}
        </div>
    )
}

export default StepSettings