import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

type miniCalendarProps = {
  height?: string,
  width?: string,
  value?: Date,
  range?: boolean,
  onChange?: (value: Date) => void;
  className?: string;
  parentClassName?: string;
  leftIconSize?: string;
  rightIconSize?: string;
}

const MiniCalendar = (props: miniCalendarProps) => {
  const { width, height, value, range, onChange, className, parentClassName, leftIconSize, rightIconSize } = props

  return (
    <div className={parentClassName}>
      <div
        className={`flex ${width ? width : "w-full md:w-[320px]"} ${height ? height : 'h-full'} max-w-full flex-col rounded-[20px] bg-white px-3 dark:border dark:!border-white/10 dark:!bg-navy-800 ${className}`} >
        <Calendar
          selectRange={range}
          onChange={onChange}
          value={value}
          prevLabel={<MdChevronLeft className={`ml-1 ${leftIconSize || 'size-6'}`} />}
          nextLabel={<MdChevronRight className={`ml-1 ${rightIconSize || 'size-6'}`} />}
          view={"month"}
        />
      </div>
    </div>
  );
};

export default MiniCalendar;
