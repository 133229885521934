/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { toastState } from 'common/utils/utility';

interface ChildProps {
  setUploadList?: React.Dispatch<React.SetStateAction<any[]>>;
  onUpload?: (flattenedData: any) => void;
  dependencies?: any;
}
const Uploader: React.FC<ChildProps> = ({ setUploadList, onUpload, dependencies }) => {
  const fileMapUpload = new Map();
  const dependency = dependencies || [];
  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        fileMapUpload.set(reader, file);
        reader.onload = async (e: any) => {
          const fileAttr = fileMapUpload.get(e.target);
          if (!['csv', 'xlsx', 'xls'].includes(fileAttr.name.split('.').pop())) {
            toast.error('This file format is not accepted', toastState.error);
            return;
          }
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          jsonData.filter((val: any) => val?.length !== 0);
          const emailIndex = jsonData?.length > 0 && jsonData[0] ? jsonData[0]?.indexOf('Email') : null;
          // Map the email addresses into a new array of objects
          const flattenedData = jsonData.slice(1).map((row: any, index: number) => ({
            id: index?.toString(),
            email: row[emailIndex],
          }));
          // const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          // const validData = flattenedData?.filter((user: { email: string }) => emailPattern?.test(user?.email))
          // setUploadList && setUploadList(validData);
          // onUpload && onUpload(validData);

          setUploadList && setUploadList(flattenedData);
          onUpload && onUpload(flattenedData);
        };
        reader.readAsArrayBuffer(file);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [fileMapUpload]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <div className="h-full w-full pt-4">
        <div className="flex h-full !w-[700px] cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700 " {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          <button className="h-full !w-full">
            <div
              id="dropzone-file-uploader"
              className="flex h-[225px] w-full flex-col items-center justify-center gap-1 rounded-md border-[1px] border-dashed border-gray-200 
                             bg-[#FAFCFE] p-3  dark:!border-darkBorder dark:!bg-darkThree"
            >
              <p className="ml-2 pb-3 text-[65px] text-navy-700">
                <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
              </p>
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                Drop your files here, or <span className="font-bold text-brand-500 dark:text-brand-400">browse</span>
              </p>
              <p className="pt-2 text-[10px] text-gray-700 dark:text-gray-300">CSV files are allowed</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Uploader;
