import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import React from 'react';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store';
import { cleanSearchProspectsFilterData, setSearchProspectsData, setSearchType, setTotalProspectsCount } from 'store/campaign-v2';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, jwtEncrypt, toastState } from 'common/utils/utility';
import CustomTab from './CustomTab';
import FilterElements from './FilterElements';
import { TbFilterX } from 'react-icons/tb';
import { setUpdateSettingsData } from 'store/global-settings';

export const getExistAndNotExistFields: (type: "is_known" | 'is_unknown', filterData: any) => string[] = (type, filterData) => {
  // console.log('filterData?.employees?.selected_method', filterData?.employees?.selected_method)
  if (type === 'is_known') {
    return [
      ...(filterData?.job_titles?.selected_method === 'is_known' ? ['person_title_normalized'] : []),
      ...(filterData?.companies?.selected_method === 'is_known' ? ['organization_id'] : []),
      ...(filterData?.industry_keywords?.selected_method === 'is_known' ? ['organization_linkedin_industry_tag_ids'] : []),
      ...(filterData?.revenue?.selected_method === 'is_known' ? ['organization_revenue_in_thousands_int'] : []),
      ...(filterData?.funding?.selected_method === 'is_known' ? ['organization_total_funding_long'] : []),
    ]
  } else {
    return [
      ...(filterData?.job_titles?.selected_method === 'is_unknown' ? ['person_title_normalized'] : []),
      ...(filterData?.companies?.selected_method === 'is_unknown' ? ['organization_id'] : []),
      ...(filterData?.industry_keywords?.selected_method === 'is_unknown' ? ['organization_linkedin_industry_tag_ids'] : []),
      ...(filterData?.employees?.selected_method === 'employees_is_unknown' ? ['organization_estimated_number_employees'] : []),
      ...(filterData?.revenue?.selected_method === 'is_unknown' ? ['organization_revenue_in_thousands_int'] : []),
      ...(filterData?.funding?.selected_method === 'is_unknown' ? ['organization_total_funding_long'] : []),
    ]
  }
}

export const getParams = (application: string, search_type: "people" | "company", filterData: any) => {
  if (application === 'apollo') {
    if (search_type === 'people') {
      const exist_fields = getExistAndNotExistFields('is_known', filterData);
      // console.log('exist_fields', exist_fields)
      const not_exist_fields = getExistAndNotExistFields('is_unknown', filterData);
      // console.log('not_exist_fields', not_exist_fields)
      return {
        // Common
        ...(exist_fields?.length && { exist_fields: exist_fields }),
        ...(not_exist_fields?.length && { not_exist_fields: not_exist_fields }),
        // Name
        ...(filterData?.people_name?.length > 0 && { q_keywords: filterData?.people_name }),
        // Job Titles
        ...(filterData?.job_titles?.is_any_of_data?.job_titles?.length > 0 && { person_titles: filterData.job_titles.is_any_of_data.job_titles }),
        ...(filterData?.job_titles?.view?.is_not_any_of && filterData?.job_titles?.is_any_of_data?.is_not_any_of?.length > 0 && { person_not_titles: filterData?.job_titles?.is_any_of_data?.is_not_any_of }),
        ...(filterData?.job_titles?.view?.include_past_job_titles && filterData?.job_titles?.is_any_of_data?.include_past_job_titles?.length > 0 && { person_past_titles: filterData?.job_titles?.is_any_of_data?.include_past_job_titles }),
        ...(filterData?.job_titles?.management_level?.length > 0 && { person_seniorities: filterData?.job_titles?.management_level }),
        ...(filterData?.job_titles?.department_job_function?.length > 0 && { person_department_or_subdepartments: filterData?.job_titles?.department_job_function }),
        // Company
        ...(filterData?.companies?.is_any_of_data?.companies?.length > 0 && {
          organization_ids: filterData?.companies?.is_any_of_data?.companies?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.companies?.view?.is_not_any_of && filterData?.companies?.is_any_of_data?.is_not_any_of?.length > 0 && { not_organization_ids: filterData?.companies?.is_any_of_data?.is_not_any_of }),
        ...(filterData?.companies?.view?.include_past_companies && filterData?.companies?.is_any_of_data?.include_past_companies?.length > 0 && {
          person_past_organization_ids: filterData?.companies?.is_any_of_data?.include_past_companies,
        }),
        ...(filterData?.companies?.include_list_id?.length > 0 && { q_not_organization_search_list_id: filterData?.companies?.include_list_id }),
        ...(filterData?.companies?.exclude_list_id?.length > 0 && { q_organization_search_list_id: filterData?.companies?.exclude_list_id }),
        // Location
        ...(filterData?.location?.region?.include?.length > 0 && { person_locations: filterData?.location?.region?.include }),
        ...(filterData?.location?.region?.exclude?.length > 0 && { person_not_locations: filterData?.location?.region?.exclude }),
        ...(filterData?.location?.zip_code?.zip?.length > 0 && { person_location_name: filterData?.location?.zip_code?.zip }),
        ...(filterData?.location?.selected_method === 'zip_code_radius' &&
          filterData?.location?.zip_code?.range?.length > 0 && { person_location_radius: filterData?.location?.zip_code?.range }),
        // Employees
        ...(filterData?.employees?.predefined_range?.length
          ? { organization_num_employees_ranges: filterData.employees.predefined_range }
          : filterData?.employees?.custom_range?.min?.length && filterData?.employees?.custom_range?.max?.length
            ? {
              organization_num_employees_ranges: [`${filterData.employees.custom_range.min},${filterData.employees.custom_range.max}`],
            }
            : filterData?.employees?.custom_range?.min
              ? {
                organization_num_employees_ranges: [`${filterData.employees.custom_range.min},`],
              }
              : filterData?.employees?.custom_range?.max
                ? {
                  organization_num_employees_ranges: [`,${filterData.employees.custom_range.max}`],
                }
                : {}),
        // Industry and Keywords
        ...(filterData?.industry_keywords?.industries?.length > 0 && {
          organization_industry_tag_ids: filterData?.industry_keywords?.industries?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.industry_keywords?.is_not_any_of_data?.length > 0 && {
          organization_not_industry_tag_ids: filterData?.industry_keywords?.is_not_any_of_data?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.industry_keywords?.view?.include_all && filterData?.industry_keywords?.company_keywords?.include_all?.split(',')?.length > 0 && {
          q_anded_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.include_all?.split(','),
        }),
        ...(filterData?.industry_keywords?.view?.exclude && filterData?.industry_keywords?.company_keywords?.exclude?.split(',')?.length > 0 && { q_not_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.exclude?.split(',') }),
        ...(filterData?.industry_keywords?.view?.include && filterData?.industry_keywords?.company_keywords?.include?.split(',')?.length > 0 && { q_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.include?.split(',') }),
        // Technologies
        ...(filterData?.technologies?.include?.length > 0 && { currently_using_any_of_technology_uids: filterData?.technologies?.include }),
        ...(filterData?.technologies?.include_all?.length > 0 && { currently_using_all_of_technology_uids: filterData?.technologies?.include_all }),
        // Revenue
        ...(filterData?.revenue?.private_company?.length || filterData?.revenue?.public_company?.length
          ? {
            organization_trading_status: [filterData?.revenue?.private_company ? 'private' : null, filterData?.revenue?.public_company ? 'public' : null].filter(Boolean),
          }
          : {}),
        ...(filterData?.revenue?.is_between?.min?.length || filterData?.revenue?.is_between?.max?.length
          ? {
            revenue_range: {
              ...(filterData?.revenue?.is_between?.min?.length && { min: filterData.revenue.is_between.min }),
              ...(filterData?.revenue?.is_between?.max?.length && { max: filterData.revenue.is_between.max }),
            },
          }
          : {}),
        // Funding
        ...(filterData?.funding?.funding_data?.length > 0 && { organization_latest_funding_stage_cd: filterData?.funding?.funding_data }),
        ...(filterData?.funding?.last_funding_round_amount?.min?.length || filterData?.funding?.last_funding_round_amount?.max?.length
          ? {
            latest_funding_amount_range: {
              ...(filterData?.funding?.last_funding_round_amount?.min?.length && { min: filterData.funding.last_funding_round_amount.min }),
              ...(filterData?.funding?.last_funding_round_amount?.max?.length && { max: filterData.funding.last_funding_round_amount.max }),
            },
          }
          : {}),

        ...(filterData?.funding?.total?.min?.length || filterData?.funding?.total?.max?.length
          ? {
            total_funding_range: {
              ...(filterData?.funding?.total?.min?.length && { min: filterData.filterData?.funding?.total?.min }),
              ...(filterData?.funding?.total?.max?.length && { max: filterData.filterData?.funding?.total?.max }),
            },
          }
          : {}),

        ...(filterData?.funding?.last_funding_round_date?.from?.length || filterData?.funding?.last_funding_round_date?.to?.length
          ? {
            latest_funding_date_range: {
              ...(filterData?.funding?.last_funding_round_date?.from?.length && { min: filterData?.funding?.last_funding_round_date?.from }),
              ...(filterData?.funding?.last_funding_round_date?.to?.length && { max: filterData?.funding?.last_funding_round_date?.to }),
            },
          }
          : {}),
        // Job Posting
        ...(filterData?.job_posting?.job_titles?.length > 0 && { q_organization_job_titles: filterData?.job_posting?.job_titles }),
        ...(filterData?.job_posting?.locations?.length > 0 && { rganization_job_locations: filterData?.job_posting?.locations }),
        ...(filterData?.job_posting?.active_job_posting?.min?.length || filterData?.job_posting?.active_job_posting?.max?.length
          ? {
            organization_num_jobs_range: {
              ...(filterData?.job_posting?.active_job_posting?.min?.length && { min: filterData?.job_posting?.active_job_posting?.min }),
              ...(filterData?.job_posting?.active_job_posting?.max?.length && { max: filterData?.job_posting?.active_job_posting?.max }),
            },
          }
          : {}),
        ...(filterData?.job_posting?.last_funding_round_date?.from?.length || filterData?.job_posting?.last_funding_round_date?.to?.length
          ? {
            organization_job_posted_at_range: {
              ...(filterData?.job_posting?.last_funding_round_date?.from?.length && { min: filterData?.job_posting?.last_funding_round_date?.from }),
              ...(filterData?.job_posting?.last_funding_round_date?.to?.length && { max: filterData?.job_posting?.last_funding_round_date?.to }),
            },
          }
          : {}),
      };
    } else {
      return {
        // Company
        ...(filterData?.companies?.is_any_of_data?.companies?.length > 0 && {
          organization_ids: filterData?.companies?.is_any_of_data?.companies?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.companies?.is_any_of_data?.is_not_any_of?.length > 0 && { not_organization_ids: filterData?.companies?.is_any_of_data?.is_not_any_of }),
        ...(filterData?.companies?.is_any_of_data?.include_past_companies?.length > 0 && {
          person_past_organization_ids: filterData?.companies?.is_any_of_data?.include_past_companies,
        }),
        ...(filterData?.companies?.include_list?.length > 0 && { q_not_organization_search_list_id: filterData?.companies?.include_list }),
        ...(filterData?.companies?.exclude_list?.length > 0 && { q_organization_search_list_id: filterData?.companies?.exclude_list }),
        // Location
        ...(filterData?.location?.region?.include?.length > 0 && { person_locations: filterData?.location?.region?.include }),
        ...(filterData?.location?.region?.exclude?.length > 0 && { person_not_locations: filterData?.location?.region?.exclude }),
        ...(filterData?.location?.zip_code?.zip?.length > 0 && { person_location_name: filterData?.location?.zip_code?.zip }),
        ...(filterData?.location?.selected_method === 'zip_code_radius' &&
          filterData?.location?.zip_code?.range?.length > 0 && { person_location_radius: filterData?.location?.zip_code?.range }),
        // Employees
        ...(filterData?.employees?.predefined_range?.length
          ? { organization_num_employees_ranges: filterData.employees.predefined_range }
          : filterData?.employees?.custom_range?.min?.length && filterData?.employees?.custom_range?.max?.length
            ? {
              organization_num_employees_ranges: [`${filterData.employees.custom_range.min},${filterData.employees.custom_range.max}`],
            }
            : filterData?.employees?.custom_range?.min
              ? {
                organization_num_employees_ranges: [`${filterData.employees.custom_range.min},`],
              }
              : filterData?.employees?.custom_range?.max
                ? {
                  organization_num_employees_ranges: [`,${filterData.employees.custom_range.max}`],
                }
                : {}),
        // Industry and Keywords
        ...(filterData?.industry_keywords?.industries?.length > 0 && {
          organization_industry_tag_ids: filterData?.industry_keywords?.industries?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.industry_keywords?.is_not_any_of_data?.length > 0 && {
          organization_not_industry_tag_ids: filterData?.industry_keywords?.is_not_any_of_data?.map((ids: { id: string }) => ids?.id),
        }),
        ...(filterData?.industry_keywords?.company_keywords?.include_all?.split(',')?.length > 0 && {
          q_anded_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.include_all?.split(','),
        }),
        ...(filterData?.industry_keywords?.company_keywords?.exclude?.length?.split(',') > 0 && { q_not_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.exclude?.split(',') }),
        ...(filterData?.industry_keywords?.company_keywords?.include?.length?.split(',') > 0 && { q_organization_keyword_tags: filterData?.industry_keywords?.company_keywords?.include?.split(',') }),
        // Technologies
        ...(filterData?.technologies?.include?.length > 0 && { currently_using_any_of_technology_uids: filterData?.technologies?.include }),
        ...(filterData?.technologies?.include_all?.length > 0 && { currently_using_all_of_technology_uids: filterData?.technologies?.include_all }),
        // Revenue
        ...(filterData?.revenue?.private_company?.length || filterData?.revenue?.public_company?.length
          ? {
            organization_trading_status: [filterData?.revenue?.private_company ? 'private' : null, filterData?.revenue?.public_company ? 'public' : null].filter(Boolean),
          }
          : {}),
        ...(filterData?.revenue?.is_between?.min?.length || filterData?.revenue?.is_between?.max?.length
          ? {
            revenue_range: {
              ...(filterData?.revenue?.is_between?.min?.length && { min: filterData.revenue.is_between.min }),
              ...(filterData?.revenue?.is_between?.max?.length && { max: filterData.revenue.is_between.max }),
            },
          }
          : {}),
        // Funding
        ...(filterData?.funding?.funding_data?.length > 0 && { organization_latest_funding_stage_cd: filterData?.funding?.funding_data }),
        ...(filterData?.funding?.last_funding_round_amount?.min?.length || filterData?.funding?.last_funding_round_amount?.max?.length
          ? {
            latest_funding_amount_range: {
              ...(filterData?.funding?.last_funding_round_amount?.min?.length && { min: filterData.funding.last_funding_round_amount.min }),
              ...(filterData?.funding?.last_funding_round_amount?.max?.length && { max: filterData.funding.last_funding_round_amount.max }),
            },
          }
          : {}),

        ...(filterData?.funding?.total?.min?.length || filterData?.funding?.total?.max?.length
          ? {
            total_funding_range: {
              ...(filterData?.funding?.total?.min?.length && { min: filterData.filterData?.funding?.total?.min }),
              ...(filterData?.funding?.total?.max?.length && { max: filterData.filterData?.funding?.total?.max }),
            },
          }
          : {}),

        ...(filterData?.funding?.last_funding_round_date?.from?.length || filterData?.funding?.last_funding_round_date?.to?.length
          ? {
            latest_funding_date_range: {
              ...(filterData?.funding?.last_funding_round_date?.from?.length && { min: filterData?.funding?.last_funding_round_date?.from }),
              ...(filterData?.funding?.last_funding_round_date?.to?.length && { max: filterData?.funding?.last_funding_round_date?.to }),
            },
          }
          : {}),
        // Job Posting
        ...(filterData?.job_posting?.job_titles?.length > 0 && { q_organization_job_titles: filterData?.job_posting?.job_titles }),
        ...(filterData?.job_posting?.locations?.length > 0 && { rganization_job_locations: filterData?.job_posting?.locations }),
        ...(filterData?.job_posting?.active_job_posting?.min?.length || filterData?.job_posting?.active_job_posting?.max?.length
          ? {
            organization_num_jobs_range: {
              ...(filterData?.job_posting?.active_job_posting?.min?.length && { min: filterData?.job_posting?.active_job_posting?.min }),
              ...(filterData?.job_posting?.active_job_posting?.max?.length && { max: filterData?.job_posting?.active_job_posting?.max }),
            },
          }
          : {}),
        ...(filterData?.job_posting?.last_funding_round_date?.from?.length || filterData?.job_posting?.last_funding_round_date?.to?.length
          ? {
            organization_job_posted_at_range: {
              ...(filterData?.job_posting?.last_funding_round_date?.from?.length && { min: filterData?.job_posting?.last_funding_round_date?.from }),
              ...(filterData?.job_posting?.last_funding_round_date?.to?.length && { max: filterData?.job_posting?.last_funding_round_date?.to }),
            },
          }
          : {}),
      };
    }
  } else {
    return {};
  }
};


const FilterProspects = () => {
  const dispatch = useAppDispatch();
  const search_type = useAppSelector((state) => state.campaignv2.prospects.search_prospects.prospects_filter.search_type);
  const [loading, setLoading] = React.useState(false);
  const apiData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.tool_details);
  const filterData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.prospects_filter.filter_data);

  const handleClickFilterBtn = async () => {
    try {
      setLoading(true);
      const params = getParams(apiData?.name, search_type, filterData);
      if (Object.keys(params).length <= 0) {
        return toast.info('Start your people search by applying any filter', toastState.info)
      }
      const encodedData = jwtEncrypt(params);
      const response = await BaseApi.post('/prospects/search', { source: apiData?.name, search_type, filter_data: encodedData, api_key: apiData?.api_key_method === 'sparkle' ? "sparkle" : apiData?.api_key, });
      if (!response.error) {
        dispatch(setSearchProspectsData(response?.data?.data));
        dispatch(setTotalProspectsCount(response?.data?.total_entries));

      } else {
        toast.error(_errorHandler(response.message), toastState.error);
      }
      dispatch(setUpdateSettingsData('billings'));

    } catch (error) {
      console.log('Search filter prospects error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (tab: string) => {
    dispatch(setSearchType(tab));
    dispatch(cleanSearchProspectsFilterData());
  };

  const selectedTab = search_type === 'people' ? 0 : 1;
  return (
    <div className="noscroll w-2/5 pl-1.5 pr-6">
      <Tabs index={selectedTab} className="mb-3 h-[90%]">
        <TabList className='flex items-center'>
          <div className='w-full'>
            <CustomTab onClick={() => handleChangeTab('people')}>People</CustomTab>
            {/* <CustomTab onClick={() => handleChangeTab('company')}>Company</CustomTab> */}
          </div>
          <div className='w-12 flex justify-center'>
            <TbFilterX className='cursor-pointer transition-all duration-200 active:scale-90 text-blueSecondary' onClick={() => dispatch(cleanSearchProspectsFilterData())} size={18} />
          </div>
        </TabList>
        <TabPanels className="mt-1 h-[92%] overflow-y-auto">
          <TabPanel className="!py-3 !pl-0 !pr-2">
            <FilterElements searchType="people" />
          </TabPanel>
          {/* <TabPanel className="!py-3 !pl-0 !pr-2">
            <FilterElements searchType="company" />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
      <div className="flex justify-center">
        <Button
          loading={loading}
          disable={loading}
          onClick={handleClickFilterBtn}
          name={`Preview ${selectedTab === 0 ? 'people' : 'companies'}`}
          customClass="w-[80%] h-fit text-[13px] font-light hover:bg-purple-700"
        />
      </div>
    </div>
  );
};

export default FilterProspects;
