/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import InputField from 'components/fields/InputField';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { getErrorMessage } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';
import { CustomMenu } from 'components/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import TextField from 'components/fields/TextField';

const CreditsAddonsModal = (props: any) => {
  const { isOpen, onClose, editId } = props;
  const [open, setOpen] = React.useState(false);
  const [handleState, setHandleState] = React.useState({
    loading: false,
    price: 0,
    credits: 0,
    discount_percentage: 0,
    addon_type: '',
  });

  React.useEffect(() => {
    const getOneEditData = async () => {
      try {
        const response = await BaseApi.get(`/credit/addons/${editId}`);
        if (!response?.error) {

          setHandleState({
            ...handleState,
            price: response?.data?.amount,
            credits: response?.data?.credits,
            addon_type: response?.data?.addon_type,
            discount_percentage:response?.data?.discount_percentage
          });
        }
      } catch (error) {}
    };
    editId && getOneEditData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(getErrorMessage({}));
    onClose();
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCreateCreditFeature = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const Payload = {
        addon_type: handleState?.addon_type,
        credits: handleState?.credits,
        amount: handleState?.price,
        discount_percentage:handleState?.discount_percentage
      };
      if (editId) {
        const updateResponse = await BaseApi.patch(`/credit/addons`, { uuid: editId, ...Payload });
        if (!updateResponse?.error) {
          toast.success('Created Feature Successfully', toastState.success);
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          handleClose();
        } else {
          dispatch(getErrorMessage(updateResponse?.message));
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        }
      } else {
        const updateResponse = await BaseApi.post(`/credit/addons`, Payload);
        if (!updateResponse?.error) {
          toast.success('Addons Added Successfully', toastState.success);
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          handleClose();
        } else {
          dispatch(getErrorMessage(updateResponse?.message));
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        }
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="select-none dark:!bg-darkTwo">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkOne">
            <div className="flex !flex-col gap-3 dark:text-white">
              <h5 className="text-lg font-semibold">Credit Addons</h5>
            </div>
          </ModalHeader>
          <ModalBody className=" !p-3">
            <div className="mt-4 items-end gap-2 ">
              <p className=" text-[12px] font-semibold dark:text-white">Addon Type</p>

              <div className=" w-full">
                <CustomMenu
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  btnContent={`${handleState?.addon_type}` || 'select'}
                  btnClassName="w-full justify-between border-blue-500 !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                  menuListClassName="w-full !h-24 overflow-auto !mt-0.5 !p-0"
                  rightIcon={<BiChevronDown size={18} />}
                  btnId="primary_goal_drop_down"
                >
                  <button
                    id="primary_goal_0"
                    onClick={() => {
                      setHandleState({ ...handleState, addon_type: 'default' });
                      setOpen(false);
                    }}
                    className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                  >
                    Default
                  </button>
                  <button
                    id="primary_goal_0"
                    onClick={() => {
                      setHandleState({ ...handleState, addon_type: 'custom' });
                      setOpen(false);
                    }}
                    className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                  >
                    Custom
                  </button>
                </CustomMenu>
              </div>
            </div>

            <div className="mt-2 flex w-full gap-2">
              <div className="w-1/3">
                <InputField
                  autoComplete="off"
                  customClass="!h-9  !mt-0"
                  inputClass="!mt-0.5 new_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                  labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                  label="Credits"
                  placeholder="Credits"
                  type="number"
                  id="credits"
                  min={0}
                  value={handleState?.credits}
                  onChange={handleChange}
                />
              </div>

              <div className=" w-1/3">
                <InputField
                  autoComplete="off"
                  customClass="!h-9  !mt-0"
                  inputClass="!mt-0.5 new_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                  labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                  label="Price"
                  placeholder="Price"
                  type="number"
                  id="price"
                  min={0}
                  value={handleState?.price}
                  onChange={handleChange}
                />
              </div>
              <div className="w-1/3">
                <InputField
                  autoComplete="off"
                  customClass="!h-9  !mt-0"
                  inputClass="!mt-0.5 new_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                  labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                  label="Discount Percentage"
                  placeholder="Discount %"
                  type="number"
                  id="discount_percentage"
                  min={0}
                  value={handleState?.discount_percentage}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="mt-4 flex w-full justify-between gap-2 "> */}

            {/* </div> */}
          </ModalBody>
          <ModalFooter className="!mb-5 !mt-4 !h-2 w-full">
            <Button
              id="two-setp-auth-cancel"
              name="Cancel"
              onClick={handleClose}
              customClass="!bg-[#F4F7FE]  dark:!text-blackDark w-full !text-[#A3AED0]  border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            />
            <Button
              id="two-setp-auth-confirm"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name={'Confirm'}
              customClass=" !text-[#FFFFFF] dark:!border-none w-full confirm_password_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
              onClick={() => handleCreateCreditFeature()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreditsAddonsModal;
