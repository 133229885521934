/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Menu, MenuButton, MenuItem, MenuList, SkeletonText } from '@chakra-ui/react';
import { IoMail } from 'react-icons/io5';
import GroupTags from './GroupTags';
import { AiOutlineClockCircle, AiOutlineDelete, AiOutlineStar } from 'react-icons/ai';
import { LiaUndoAltSolid } from 'react-icons/lia';
import saveAs from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import moment from 'moment';
import { CgFile } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import UploadSingleLead from 'components/crm/UploadSingleLead';
import { BiCheckDouble } from 'react-icons/bi';
import { CiGrid2V } from 'react-icons/ci';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { BsReply, BsReplyAll } from 'react-icons/bs';
// import { TiArrowForwardOutline } from 'react-icons/ti';
import Typer from 'views/crm/features/inbox/Communication/Typer';
import { useAppDispatch, useAppSelector } from 'store';
import { setOpportunityInboxViewData, setProspectDetails, setReplyThreadData } from 'store/crm';
import { toast } from 'react-toastify';
import { base64Decode, calculateTimeAgo, toastState } from 'common/utils/utility';
import DeleteConfirmModal from 'components/crm/DeleteConfrimModal';
import { MdOutlineStar } from 'react-icons/md';
import { setClearSelectData, setSnoozeId, setSnoozePopupState, setTriggerCount } from 'store/unified-inbox';
import { useNavigate } from 'react-router-dom';
import { setSideBarFullView } from 'store/custom';
import { TiArrowForwardOutline } from 'react-icons/ti';
import ArrowedTooltip from 'components/ArrowedTooltip';

interface ListRowTypes {
  index: number;
  inboxData?: any;
  fetchTrigger?: () => void;
  count?: number;
  AvatarColor?: string;
  hanleDeleteGroupFromSenderList: any;
}
const MessageRow = (props: ListRowTypes) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { index, inboxData, count, AvatarColor, hanleDeleteGroupFromSenderList } = props;
  const { inbox_status } = useAppSelector((state) => state.unifiedInboxData);

  const [readMessages, setReadMessages] = useState([]);
  const [starredMessages, setStarredMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [rowIndex, setRowIndex] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //  const [emailData, setEmailData] = useState<any>({ loading: true, profile: { name: '', receiver_email: '', date: '', subject: '', message: [] } });
  const [emailData, setEmailData] = useState<any>({ loading: true, profile: {} });
  const [showTyper, setShowTyper] = useState(false);

  const dispatch = useAppDispatch();
  const typerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inboxData.read_history.is_viewed) {
      setReadMessages((prev) => [...prev, inboxData?.uuid]);
    }
    if (inboxData.is_starred_message) {
      setStarredMessages((prev) => [...prev, inboxData?.uuid]);
    }
  }, [inboxData.uuid]);

  useEffect(() => {
    if (showTyper && typerRef.current) {
      typerRef.current.scrollIntoView({ behavior: 'smooth' });
      typerRef.current.focus();
    }
  }, [showTyper]);

  const fetchDbEmails = async (main_message_id: string) => {
    try {
      setIsLoading(true);
      setEmailData((prev: any) => ({ ...prev, loading: true }));
      const response = await BaseApi.get(`crm/pipeline/opportunity/get/mail/thread/${main_message_id}`);
      if (response?.data) {
        const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
        const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
        let message: string;
        if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
          message = response.data?.map((item: any) => {
            const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
              ?.map((attachment: any) => {
                if (attachment?.filename) {
                  return attachment;
                } else {
                  return null;
                }
              })
              .filter(Boolean);
            return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
          });
          // message = inbox_mail_data?.text
        } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
          message = response?.data?.map((item: any) => {
            const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
            const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
            // const fullMessages = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
            const fullMessage = base64String ? base64Decode(base64String) : base64String2 ? base64Decode(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
            const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
              ?.slice(1)
              .map((attachment: any) => {
                if (attachment?.filename) {
                  return {
                    filename: attachment.filename,
                    contentType: attachment.mimeType,
                    content: attachment.body?.content,
                    size: attachment.body?.size,
                  };
                } else {
                  return null;
                }
              })
              .filter((attachment: any) => attachment !== null);
            return { fullMessage, attachment, ...item };
          });
          // message = decodeURIComponent(atob(base64String))
        }
        const profile = {
          name,
          receiver_email: response.data[0]?.platform_message_details?.from?.address,
          subject: response.data[0]?.platform_message_details?.subject,
          message,
          date,
        };
        setEmailData((prev: any) => ({ ...prev, profile }));
      }
    } catch (error) {
      console.log('Unmatched email fetching error', error);
    } finally {
      setIsLoading(false);
      setEmailData((prev: any) => ({ ...prev, loading: false }));
    }
  };

  const handleReply = (item: any, sender_id: string) => {
    // const smtpPattern = /<[^>]+@[^>]+>/;
    let payload;
    //item?.inbox_mail_data?.cc?.value ? item?.inbox_mail_data?.cc?.value :
    // item?.inbox_mail_data?.bcc?.value ? item?.inbox_mail_data?.bcc?.value :
    if (item.type === 'sender') {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          from: item?.inbox_mail_data?.from.value,
          to: item?.inbox_mail_data?.to?.value,
          delivered_to: item.delivered_to,
          main_message_id: item.main_message_id,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.from,
          to: item.to,
          delivered_to: item.delivered_to,
          // cc: [],
          // bcc: [],
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    } else {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          main_message_id: item.main_message_id,
          individual_message_id: item.individual_message_id,
          from: item?.inbox_mail_data?.to?.value,
          to: item?.inbox_mail_data?.from?.value,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.to,
          to: item.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
  };

  const handleMainReply = (item: any, sender_id: string) => {
    // const smtpPattern = /<[^>]+@[^>]+>/;
    let payload;
    //item?.inbox_mail_data?.cc?.value ? item?.inbox_mail_data?.cc?.value :
    // item?.inbox_mail_data?.bcc?.value ? item?.inbox_mail_data?.bcc?.value :
    if (item.type === 'sender') {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          from: item?.from,
          to: item?.to,
          delivered_to: item.delivered_to,
          individual_message_id: item.individual_message_id,
          main_message_id: item.main_message_id,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.from,
          to: item.to,
          delivered_to: item.delivered_to,
          // cc: [],
          // bcc: [],
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    } else {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          main_message_id: item.main_message_id,
          from: item?.to,
          to: item?.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.to,
          to: item.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
    setShowTyper(true);
    dispatch(setReplyThreadData(payload));
  };

  const handleReplyAll = (data: any, sender_id: string) => {
    setShowTyper(false);
    let payload;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.from?.value || [data?.from],
          to: data?.inbox_mail_data?.to?.value || [data?.to],
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          main_message_id: data?.main_message_id,
          individual_message_id: data?.individual_message_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: data?.to,
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'reply',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.to?.value || data?.to,
          to: data?.inbox_mail_data
            ? [...data?.inbox_mail_data?.from?.value, ...data?.inbox_mail_data?.to?.value?.slice(1, data?.inbox_mail_data?.to?.value?.length)]
            : [data?.from, ...data?.to?.slice(1, data?.to?.length)],
          delivered_to: data?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [data?.from, ...(data?.to?.length > 1 ? data?.to.slice(1) : [])],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);
    dispatch(setReplyThreadData(payload));
    setShowTyper(true);
  };

  const handleForward = (data: any, sender_id: string, message: any) => {
    setShowTyper(false);
    let payload;
    // const smtpPattern = /<[^>]+@[^>]+>/;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        const toEmails = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');

        payload = {
          from: data?.inbox_mail_data?.from?.value,
          to: [],
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          main_message_id: data?.main_message_id,
          message: '',
          individual_message_id: data?.individual_message_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmails}<br><br>`,
        };
      } else {
        const toEmailoriginalData = Array.isArray(data?.to?.value) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: [],
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmailoriginalData}<br><br>`,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        const toEmaildata = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
        payload = {
          from: data?.inbox_mail_data?.to?.value,
          to: [],
          delivered_to: data.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          message: '',
          sender_id: sender_id,
          individual_message_id: data?.individual_message_id,
          main_message_id: data?.main_message_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildata}<br><br>`,
        };
      } else {
        const toEmaildoauth = Array.isArray(data?.to) ? data?.to.value?.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          sender_id: sender_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.platform_message_details?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmaildoauth}<br><br>`,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);

    dispatch(setReplyThreadData(payload));
    setTimeout(() => {
      setShowTyper(true);
    }, 500);
  };

  const formatIndianDateTime = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString('en-IN', options).replace(',', '');

    return `${formattedDate}`;
  };

  const downloadFile = (attachment: any) => {
    if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
      const byteArray = new Uint8Array(attachment.content.data);
      const blob = new Blob([byteArray], { type: attachment.contentType });
      const filename = attachment.filename || 'attachment';
      saveAs(blob, filename);
    } else {
      console.error('Invalid attachment data');
    }
  };

  const toggleIndexInArray = (index: number, message_id: string) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        if (!emailData?.profile?.receiver_email) {
          fetchDbEmails(message_id);
        }
        return [...prevArr, index];
      }
    });
  };

  const handleStarredView = () => {
    setStarredMessages((prevArr) => {
      if (prevArr.includes(inboxData?.uuid)) {
        return prevArr.filter((item) => item !== inboxData?.uuid);
      } else {
        return [...prevArr, inboxData?.uuid];
      }
    });
  };

  const handleAccordionClick = (event: React.MouseEvent, index: number, message_id: string) => {
    // Prevent toggling if the click is on the GroupTags component
    const targetElement = event.target as Element;
    if (!targetElement.closest('.group-tags-inbox') && !targetElement.closest('.delete-and-view-mail')) {
      toggleIndexInArray(index, message_id);
    }
  };
  const handleMessageReadStatus = async (data: any) => {
    try {
      dispatch(setTriggerCount((prev: boolean) => !prev));
      setReadMessages((prev) => [...prev, inboxData?.uuid]);
      if (data?.read_history?.is_viewed === false) {
        const payload = {
          uuid: data?.uuid,
          is_viewed: true,
          variant: 'email',
        };
        const response = await BaseApi.patch(`/unified/inbox/`, payload);
        if (response.error === true) {
          toast.error(response.message, toastState.error);
        }
        // else {
        //   props.fetchTrigger();
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvatarClick = (e: any, uuid: string) => {
    e.stopPropagation();
    dispatch(setClearSelectData(uuid));

    setIsChecked(!isChecked);
  };

  const handleDeleteConversation = async () => {
    if (deleteId) {
      try {
        const response = await BaseApi.delete(`/unified/inbox/${deleteId}`);
        if (response.error) {
          toast.error(response.message, toastState.error);
        } else {
          toast.success(response.message, toastState.success);
          props.fetchTrigger();
        }
      } catch (error) {
        setDeleteId(null);
      } finally {
        setDeleteId(null);
        setOpenDeleteConfirmation(false);
      }
    }
  };

  const handleDeleteThreadConversation = async (uuid: string) => {
    if (uuid) {
      try {
        const response = await BaseApi.delete(`/unified/inbox/mail/thread`, {
          data: {
            delete_ids: [uuid],
          },
        });
        if (response.error) {
          toast.error(response.message, toastState.error);
        } else {
          toast.success(response.message, toastState.success);
          props.fetchTrigger();
        }
      } catch (error) {
      } finally {
      }
    }
  };

  const handleStaredStatus = async (status: boolean, id: string) => {
    handleStarredView();
    dispatch(setTriggerCount((prev: boolean) => !prev));
    try {
      const payload = {
        uuid: id,
        is_starred_message: !status,
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);

      if (response.error) {
        toast.error(response.message, toastState.error);
      }
      // else {
      //   props.fetchTrigger();
      // }
    } catch (error) {
      setDeleteId(null);
    }
  };

  const handleClearConversation = async (e: React.MouseEvent, uuid?: string) => {
    e.stopPropagation();
    dispatch(setTriggerCount((prev: boolean) => !prev));
    try {
      const payload = {
        unified_inbox_status: inbox_status === 'cleared' ? 'pending' : 'cleared',
        uuid: [uuid],
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        props.fetchTrigger();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const calculateDate = (type: string) => {
    const now = moment();
    let calculatedDate;

    switch (type) {
      case 'later':
        calculatedDate = now.add(2, 'hours');
        break;
      case 'tomorrow':
        calculatedDate = now.add(1, 'days').set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        break;
      case 'weekend':
        calculatedDate = now.day(6).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        if (calculatedDate.isBefore(now)) {
          calculatedDate.add(1, 'weeks');
        }
        break;
      case 'nextWeek':
        calculatedDate = now.day(1 + 7).set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        break;
      default:
        calculatedDate = now;
    }

    return calculatedDate.toDate();
  };

  const handleSnooze = async (uuid?: string, type?: string) => {
    try {
      const payload = {
        unified_inbox_status: 'snoozed',
        uuid: [uuid],
        snoozed_date: await calculateDate(type),
        is_viewed: false,
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        props.fetchTrigger();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleCustomSnooze = async (uuid: string) => {
    dispatch(setSnoozePopupState(true));
    dispatch(setSnoozeId(uuid));
  };

  const handlePipelineView = async (main_message_id: string) => {
    try {
      const response = await BaseApi.get(`crm/pipeline/opportunity/by/email/${main_message_id}`);
      if (response.status === 200 && response?.data?.data?.data?.[0]?.uuid) {
        dispatch(
          setOpportunityInboxViewData({
            opportunity_id: response?.data?.data?.data?.[0]?.uuid,
            opportunity_domain: response?.data?.data?.data?.[0]?.opportunity?.[0]?.prospect_email_domain,
            brand_id: response?.data?.data?.data?.[0]?.brand_id,
            pipeline_id: response?.data?.data?.data?.[0]?.pipeline_id,
          })
        );
        dispatch(setProspectDetails({ ...response?.data?.data?.data, opportunity: response?.data?.data?.data?.[0]?.opportunity }));
        dispatch(setSideBarFullView(false));
        navigate(`/crm/inbox?opportunityid=${response.data.data.data[0]?.uuid}`);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="cursor-pointer dark:border-none">
      <div
        id={`message_data_${index}`}
        onClick={(e) => {
          handleAccordionClick(e, index, inboxData?.platform_message_details?.main_message_id);
          handleMessageReadStatus(inboxData);
        }}
        className={`message_inboxdata_${index} group/list relative flex justify-between  ${index !== 0 ? 'border-t' : ''}   
         ${
           isChecked
             ? '!bg-purple-100'
             : readMessages?.includes(inboxData?.uuid) && rowIndex.includes(index)
             ? '!bg-[#f9f8ff]'
             : readMessages?.includes(inboxData?.uuid) && 'bg-[#f7f9fb]'
         } `}
      >
        <div className="flex gap-2 rounded-t-md  px-2 py-3">
          <div className="flex items-center justify-start gap-2">
            <div className={`mr-1 size-[5px] rounded-full status-index-${index} ${!readMessages?.includes(inboxData?.uuid) && 'bg-green-500'}`} />
            <div className="relative">
              <Avatar
                className="size-2"
                // bg="#3457D5"
                size={'xs'}
                name={
                  inboxData?.platform_message_details.type === 'sender'
                    ? inboxData?.platform_message_details?.to?.address?.split('@')[0][0] || inboxData?.platform_message_details?.to?.[0]?.address?.split('@')[0][0]
                    : inboxData?.platform_message_details?.from?.address?.split('@')[0][0]
                }
                // src="https://bit.ly/sage-adebayo"
                onClick={(e) => handleAvatarClick(e, inboxData?.uuid)}
                bg={AvatarColor}
                color={'white'}
              />
              <input type="checkbox" className="-left-5 hidden" checked={isChecked} onChange={(e) => handleAvatarClick(e, inboxData?.uuid)} />
              {isChecked && (
                <div className="absolute left-2 top-2 flex h-full w-full items-center justify-center">
                  <FaCheck className="size-3 rounded-full bg-purple-500 p-1 text-white" />
                </div>
              )}
            </div>
            <div className=" !max-w-[200px] text-sm font-semibold text-paused-color">
              <Menu>
                <MenuButton name="Match" className={` group/name flex max-w-[200px] `} onClick={(e) => e.stopPropagation()}>
                  <div className="ml-2 flex max-w-[195px] flex-col items-start justify-start truncate">
                    <p className="  mt-1 !max-w-[195px] overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm font-semibold text-[#344054]">
                      {inboxData.platform_message_details.type === 'sender'
                        ? inboxData?.platform_message_details?.to
                          ? inboxData?.platform_message_details?.to?.name
                            ? inboxData?.platform_message_details?.to?.name || inboxData?.platform_message_details?.to?.[0]?.name
                            : inboxData?.platform_message_details?.to?.address?.split('@')[0] || inboxData?.platform_message_details?.to?.[0]?.address?.split('@')[0]
                          : ''
                        : inboxData?.platform_message_details?.to
                        ? inboxData?.platform_message_details?.from?.name
                          ? inboxData?.platform_message_details?.from?.name
                          : inboxData?.platform_message_details?.from?.address?.split('@')[0]
                        : ''}
                    </p>
                    <p className={`${inboxData?.campaign_name && '-mt-1'} text-[10px] font-thin text-[#2E90FA]`}>{inboxData?.campaign_name ? inboxData?.campaign_name : ''}</p>
                  </div>
                </MenuButton>
                <MenuList minW="0" className="!h-[80px] !w-[9.5rem] !rounded-lg bg-white !p-0 shadow-lg  ">
                  <div className="absolute z-10 flex max-w-[23rem] flex-col  gap-4 truncate  rounded-lg bg-white px-4 py-4  shadow-2xl">
                    <p className="w-76 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-column ">
                      from :{' '}
                      <span className="group/from-pop ml-10">
                        {inboxData?.platform_message_details?.from?.address}

                        <ArrowedTooltip
                          className={`ml-32 mt-3 hidden !w-auto px-2 group-hover/from-pop:block `}
                          toolTipId={`row-cdate-tooltip-1`}
                          data={inboxData?.platform_message_details?.from?.address}
                          position="top"
                        />
                      </span>
                    </p>
                    <p className="w-76 overflow-hidden text-ellipsis text-xs text-column ">
                      to :{' '}
                      <span className="group/to-pop ml-14">
                        {inboxData?.platform_message_details?.to?.address || inboxData?.platform_message_details?.to?.map((item: any) => item?.address).join(', ')}
                        <ArrowedTooltip
                          className={`ml-32 mt-10 hidden !w-auto px-2 group-hover/to-pop:block `}
                          toolTipId={`row-cdate-tooltip-1`}
                          data={inboxData?.platform_message_details?.to?.address || inboxData?.platform_message_details?.to?.map((item: any) => item?.address).join(', ')}
                          position="top"
                        />
                      </span>
                    </p>
                    <p className="text-xs text-column">
                      date :{' '}
                      <span className="ml-10 text-xs text-column">{moment(inboxData?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A').toString()}</span>
                    </p>
                    <p className="text-xs text-column">
                      subject :{' '}
                      <span className=" ml-5 w-[9rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs text-column ">{inboxData?.platform_message_details?.subject}</span>
                    </p>
                  </div>
                </MenuList>
              </Menu>
            </div>
            <div className="ml-4 flex size-6 items-center justify-center rounded-full bg-[#e8effb]">
              <IoMail className=" text-sm text-[#015AFF]" />
            </div>
          </div>
          <div className="ml-5 flex  items-center justify-start gap-2">
            <div className="w-max-[18rem]">
              <GroupTags row={inboxData} hanleDeleteGroupFromSenderList={hanleDeleteGroupFromSenderList} />
            </div>
          </div>

          {!rowIndex?.includes(index) ? (
            <div className="ml-2 flex items-center justify-start gap-3 ">
              <p className="max-w-[21rem] overflow-hidden  truncate text-ellipsis text-sm font-medium">{inboxData?.platform_message_details?.subject}</p>
              <p
                className="max-w-[21rem] overflow-hidden  truncate text-ellipsis text-xs text-column"
                dangerouslySetInnerHTML={{ __html: inboxData?.platform_message_details?.message }}
              />
            </div>
          ) : null}
        </div>

        <div className=" relative ml-2 flex items-center justify-start gap-4  px-4 text-column">
          {!rowIndex?.includes(index) ? (
            <div
              className={`absolute right-28 flex items-center gap-4 ${readMessages?.includes(inboxData?.uuid) && 'bg-[#f7f9fb]'}  ${isChecked && '!bg-purple-100'} 
            opacity-0 transition-opacity  group-hover/list:opacity-100
             `}
            >
              <div
                onClick={(e) => {
                  handleClearConversation(e, inboxData?.uuid);
                }}
                className="group/clear"
                id={`clear_${index}`}
              >
                <ArrowedTooltip
                  className={`hidden !w-14 group-hover/clear:block `}
                  toolTipId={`row-cdate-tooltip-1`}
                  data={inbox_status === 'cleared' ? 'Unclear' : 'Clear'}
                  position="top"
                />
                {inbox_status === 'cleared' ? <LiaUndoAltSolid className="text-sm" /> : <BiCheckDouble />}
              </div>

              <div
                onClick={() => {
                  handleMainReply(inboxData?.platform_message_details, inboxData?.sender_id);
                }}
                className="group/reply"
              >
                <BsReply />
                <ArrowedTooltip className={`ml-10 hidden !w-14 group-hover/reply:block `} toolTipId={`row-cdate-tooltip-2`} data="Reply" position="top" />
              </div>

              <div
                onClick={() => {
                  handleReplyAll(inboxData?.platform_message_details, inboxData?.sender_id);
                }}
                className="group/replyall"
              >
                <BsReplyAll className="text-column" />
                <ArrowedTooltip className={`ml-[4.5rem] hidden !w-14 group-hover/replyall:block `} toolTipId={`row-cdate-tooltip-2`} data="Reply all" position="top" />
              </div>
              <Menu>
                <MenuButton name="Match" className={`snooze-menubtn group/snooz flex`} onClick={(e) => e.stopPropagation()}>
                  <div className="flex items-center justify-center  text-column">
                    <AiOutlineClockCircle />
                    <ArrowedTooltip className={`ml-[6.5rem] hidden !w-14 group-hover/snooz:block `} toolTipId={`row-cdate-tooltip-2`} data="Snooze" position="top" />
                  </div>
                </MenuButton>
                <MenuList minW="0" className="!h-[190px] !w-[12rem] !rounded-lg border border-blueSecondary bg-white !p-0 shadow-lg dark:border-none  ">
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSnooze(inboxData?.uuid, 'later');
                    }}
                    className="snooze-later flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-heading hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <span>
                      Later <span className="text-xs">in 2 hours</span>
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSnooze(inboxData?.uuid, 'tomorrow');
                    }}
                    className="flex items-center space-x-2  !py-2 !pe-2 !ps-4 text-sm !text-heading hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>Tomorrow</span>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSnooze(inboxData?.uuid, 'weekend');
                    }}
                    className="flex items-center space-x-2 !py-2 !pe-2 !ps-4 text-sm !text-heading  hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>
                      This Weekend <span className="text-xs">Sat, 8:00 AM</span>
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSnooze(inboxData?.uuid, 'nextWeek');
                    }}
                    className="flex items-center space-x-2  !py-2 !pe-2 !ps-4 text-sm !text-heading hover:!bg-blueSecondary hover:!text-white "
                  >
                    <span>
                      Next week <span className="text-xs">Mon, 8:00 AM</span>
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCustomSnooze(inboxData?.uuid);
                    }}
                    className="flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-heading hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <span>Custom Date and Time</span>
                  </MenuItem>
                </MenuList>
              </Menu>
              <div
                id={`stared_${index}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleStaredStatus(starredMessages.includes(inboxData?.uuid), inboxData?.uuid);
                }}
                className="group/star"
              >
                {starredMessages.includes(inboxData?.uuid) ? <MdOutlineStar className={'text-yellow-500'} /> : <AiOutlineStar className={`stared_${index}`} />}
                <ArrowedTooltip
                  className={`ml-[8.5rem] hidden !w-14 group-hover/star:block `}
                  toolTipId={`row-cdate-tooltip-2`}
                  data={starredMessages.includes(inboxData?.uuid) ? 'Starred' : 'Not starred'}
                  position="top"
                />

                {/* <AiOutlineStar className={inboxData?.is_starred_message ? 'text-yellow-500' : 'text-gray-500'} /> */}
              </div>

              <Menu>
                <MenuButton
                  name="Match"
                  className={`inbox-delete-pipeline-btn-${index} !hover:text-blueSecondary flex ${readMessages?.includes(inboxData?.uuid) && 'bg-[#f7f9fb]'}  ${
                    isChecked && ' !bg-purple-100'
                  } !px-2  `}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex items-center justify-center gap-2 text-xs text-column">
                    <HiOutlineDotsVertical />
                  </div>
                </MenuButton>
                <MenuList minW="0" className="delete-and-view-mail !h-[75px] !w-[9.5rem] !rounded-lg bg-white !p-0 shadow-lg  ">
                  <MenuItem
                    onClick={(e) => {
                      setOpenDeleteConfirmation(true);
                      e.stopPropagation();
                      setDeleteId(inboxData?.platform_message_details?.main_message_id);
                    }}
                    className="group/delete delete-and-view-mail inbox-delete-btn flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-heading hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <AiOutlineDelete className="font-bold text-blueSecondary group-hover/delete:text-white" />
                    <span>Delete</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handlePipelineView(inboxData?.platform_message_details?.main_message_id)}
                    className="group/item delete-and-view-mail inbox-pipeline-btn flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-heading hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white "
                  >
                    <CiGrid2V className="font-bold text-blueSecondary group-hover/item:text-white" />
                    <span>View in pipeline</span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          ) : null}
          <p className="truncate text-xs text-column">
            {inboxData?.platform_message_details?.last_thread_date
              ? calculateTimeAgo(inboxData?.platform_message_details?.last_thread_date)
              : calculateTimeAgo(inboxData?.platform_message_details?.mail_sent_date_local)}
          </p>
        </div>
      </div>
      {rowIndex?.includes(index) && (
        <>
          <div className={`max-h-[30rem]  ${count < 6 && 'min-h-[30rem]'}  overflow-auto !bg-[#f9f8ff]`} id={`${index}_thread_message`}>
            {isLoading ? (
              <div className="p-4">
                <div className=" flex items-center gap-3">
                  <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
                </div>
                <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />
              </div>
            ) : (
              emailData?.profile?.message?.map((item: any, ind: number) => (
                <>
                  {item?.platform_message_details?.from?.address ? (
                    <div className=" !bg-[#f9f8ff] py-2">
                      <div className="flex items-center justify-start px-4">
                        <div className="flex size-7 items-center justify-center rounded-full bg-[#e3e7f2]">
                          <BsReply
                            className="text-column"
                            id={`0_reply_btn`}
                            onClick={(e) => {
                              e?.stopPropagation();
                              handleReply(item?.platform_message_details, item?.sender_id);
                              setShowTyper(true);
                            }}
                          />
                        </div>
                        <div className="w-[20px] border bg-[#e3e7f2]" />
                        <div className=" w-full rounded-md border bg-white shadow">
                          <div className=" p-3">
                            <div className="flex cursor-pointer justify-between">
                              <div className="flex gap-3 ">
                                <Avatar
                                  className="border"
                                  style={{ height: '30px', width: '30px' }}
                                  name={item?.platform_message_details?.from?.address[0]}
                                  bg="#ece7ff"
                                  color={'white'}
                                />
                                <div>
                                  <p className="text-sm font-bold text-heading">
                                    {item?.platform_message_details?.from?.name || item?.platform_message_details?.from?.address?.split('@')[0]}{' '}
                                    <span className="text-sm font-normal text-[#A8A8A8]">{`<${item?.platform_message_details?.from?.address}>`}</span>
                                  </p>
                                  <div className="flex py-1 ">
                                    <span className="line-clamp-2 max-w-96  select-text  overflow-hidden text-ellipsis whitespace-normal text-xs  text-column ">
                                      to -{' '}
                                      {!item?.platform_message_details?.to?.length
                                        ? item?.platform_message_details?.to?.address
                                        : item?.platform_message_details?.to?.map((val: { address: string }) => val?.address).join(',')}
                                    </span>
                                  </div>
                                  <div className="mb-3 line-clamp-2  max-w-96 select-text overflow-hidden overflow-x-auto text-ellipsis whitespace-nowrap  text-xs text-column">
                                    subject - <span className="text-column ">{item?.platform_message_details?.subject}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="mr-3 flex items-center justify-end gap-2">
                                  <div
                                    onClick={() => {
                                      handleMainReply(item?.platform_message_details, item?.sender_id);
                                    }}
                                  >
                                    <BsReply className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleReplyAll(item?.platform_message_details, item?.sender_id);
                                    }}
                                  >
                                    <BsReplyAll className="text-column" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleForward(item?.platform_message_details, item?.sender_id, '');
                                    }}
                                  >
                                    <TiArrowForwardOutline className="text-column" />
                                  </div>
                                  {ind !== 0 ? (
                                    <div
                                      onClick={() => {
                                        handleDeleteThreadConversation(item.uuid);
                                      }}
                                    >
                                      <AiOutlineDelete className="text-sm text-column" />
                                    </div>
                                  ) : null}
                                </div>
                                <p className="text-xs text-column">{formatIndianDateTime(item?.platform_message_details?.mail_sent_date)}</p>
                              </div>
                            </div>

                            <div className="textEditor mt-2 overflow-x-auto text-xs text-[#344054]" dangerouslySetInnerHTML={{ __html: item?.fullMessage }} />
                            {/* <div className='text-xs w-full border border-red-500 leading-5 text-paused-color text-wrap'>{emailData?.profile?.message}</div> */}
                          </div>
                          {item?.attachment?.length ? (
                            <div className="mx-3 my-4 mt-1 rounded-md border">
                              <div className="bg-headerbg rounded-md px-3 py-1 text-[.6875rem] text-column">Attachment</div>
                              <div className="flex flex-wrap border-t p-3">
                                {/* files */}
                                {item?.attachment?.map((attachment: any, index: number) => {
                                  const formatBytes = (bytes: number) => {
                                    if (bytes < 1024) return `${bytes} Bytes`;
                                    let kb = bytes / 1024;
                                    if (kb < 1024) return `${kb.toFixed(3)} KB`;
                                    let mb = kb / 1024;
                                    if (mb < 1024) return `${mb.toFixed(3)} MB`;
                                    let gb = mb / 1024;
                                    return `${gb.toFixed(3)} GB`;
                                  };
                                  const size = formatBytes(attachment?.size);

                                  return (
                                    <div className="mt-2 flex w-1/2 gap-2" key={`${index}_attachment`}>
                                      <div className="h-fit rounded-full bg-hover p-2 text-sm text-blueSecondary">
                                        <CgFile />
                                      </div>
                                      <div className="flex flex-col gap-1 pt-1">
                                        <span className="text-xs font-medium">{attachment?.filename}</span>
                                        <div className="flex text-[.6875rem]">
                                          <span>{size} -</span>
                                          <span
                                            className="cursor-pointer pl-1 pr-1.5 text-blueSecondary"
                                            onClick={(e) => {
                                              e?.stopPropagation();
                                              downloadFile(attachment);
                                            }}
                                          >
                                            Download
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            )}
            {showTyper && (
              <div className="bg-[#f9f8ff]  px-2  py-2" ref={typerRef}>
                <Typer
                  containerClass={`!p-0 border-none typer_inbox `}
                  onTyperClose={() => {
                    setShowTyper(false);
                    // onClose();
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
      <DeleteConfirmModal
        isOpen={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false);
          setDeleteId(null);
        }}
        handleDelete={handleDeleteConversation}
      />
      <UploadSingleLead isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} textColor={'text-column'} />
    </div>
  );
};
export default MessageRow;
