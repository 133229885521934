import { CustomMenu } from 'components/customMenu';
import React from 'react';
import { CgFile } from 'react-icons/cg';
import { FaChevronLeft, FaChevronRight, FaUser } from 'react-icons/fa6';
import { FiCornerUpRight } from 'react-icons/fi';
import { IoMdAdd, IoMdCreate } from 'react-icons/io';
import Button from 'common/sparkle-common/Button';
import { BiArrowBack } from 'react-icons/bi';
import SearchBox from 'components/searchBox';
import { TextInput } from './TextInput';
import { useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import moment from 'moment';
import { SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import saveAs from 'file-saver';

const RightSideView = () => {
  const profile = {
    name: 'sam',
    email: 'sam@gmail.com',
    date: 'Dec 15, 10:00 AM',
    campaign_name: 'Campaign name',
    subject: 'New product or service announcement',
    message: 'Dear Ramprasad, We are thrilled to announce the launch of our new product.',
    photo: false,
  };
  const isOpen = true;
  const { unmatched_emails } = useAppSelector((state) => state?.crm);
  const [emailData, setEmailData] = React.useState<any>({ loading: true, profile: { name: '', receiver_email: '', date: '', subject: '', message: [] } });
  const [currentEmailIndex, setCurrentEmailIndex] = React.useState(1);
  const [matchCampaignData, setMatchCampaignData] = React.useState({
    stage: 0,
    searched_word: '',
    searched_campaign: [],
  });
  // const [oppertunityData, setOppertunityData] = React.useState({
  //   name: '',
  //   email: '',
  //   company_name: '',
  // })
  // console.log(oppertunityData)
  // const data = Array.from({ length: 10 }, () => {
  //   return {
  //     name: 'vijay',
  //     profile: 'dfdf',
  //     email: 'sam@gmail.com',
  //     date: 'Dec 15, 10:00 AM',
  //     time: '12:30 PM',
  //     subject: 'New product or service announcement',
  //     message: 'Dear Ramprasad, We are thrilled to announce the launch of our new product.'
  //   };
  // });

  // const emailData .profile?= data[currentEmailIndex - 1]

  React.useEffect(() => {
    const fetchDbEmails = async () => {
      try {
        setEmailData((prev: any) => ({ ...prev, loading: true }));
        const response = await BaseApi.get(`crm/pipeline/opportunity/get/mail/thread/19079bad6d5a46ed`);
        if (response?.data) {
          const name = response?.data[0]?.from.address || response?.data[0]?.from.address?.split('@')[0];
          const date = moment(response.data[0]?.mail_sent_date_local).format('MMM DD, hh:mm A');

          console.log(name, date, 'hhhh');
          let message: string;
          if (response?.data[0]?.main_message_id.includes('<')) {
            message = response.data?.map((item: any) => {
              const attachment = item?.inbox_mail_data?.attachments
                ?.map((attachment: any) => {
                  if (attachment?.filename) {
                    return attachment;
                  } else {
                    return null;
                  }
                })
                .filter(Boolean);
              return { message: item.inbox_mail_data.html, attachment };
            });
            // message = inbox_mail_data?.text
            // console.log(inbox_mail_data)
          }
          // console.log('message', message)
          else if (!response.data[0].main_message_id.includes('<')) {
            message = response?.data?.map((item: any) => {
              const base64String = item?.inbox_mail_data?.payload?.parts?.[0]?.parts[1]?.body?.data.replace(/-/g, '+').replace(/_/g, '/');
              const message = decodeURIComponent(atob(base64String));
              const attachment = item?.inbox_mail_data?.payload?.parts?.slice(1).map((attachement: any) => ({
                filename: attachement?.filename,
                contentType: attachement?.mimeType,
                content: attachement?.body?.content,
                size: attachement?.body?.size,
              }));
              return { message, attachment };
            });
            // console.log(decodeURIComponent(atob(base64String)))
            // message = decodeURIComponent(atob(base64String))
          }
          const profile = { name, receiver_email: response.data[0]?.from?.address, subject: response.data[0]?.subject, message, date };
          setEmailData((prev: any) => ({ ...prev, profile }));
        }
      } catch (error) {
        console.log('Unmatched email fetching error', error);
      } finally {
        setEmailData((prev: any) => ({ ...prev, loading: false }));
      }
    };

    fetchDbEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentEmailIndex = (count: number) => {
    // count 1 - increase , -1 - decrease
    const value = currentEmailIndex + count;
    if (value > 0 && value <= unmatched_emails?.current_length) {
      setCurrentEmailIndex(value);
    }
  };

  const handleInputChange = (event: any) => {
    // const { name, value } = event?.target
    // setOppertunityData((prev) => ({
    //   ...prev,
    //   [name]: value
    // }))
  };

  const handleChangeStage = (stage: number) => {
    setMatchCampaignData((prev) => ({
      ...prev,
      stage,
    }));
  };

  const downloadFile = (attachment: any) => {
    if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
      const byteArray = new Uint8Array(attachment.content.data);
      const blob = new Blob([byteArray], { type: attachment.contentType });
      const filename = attachment.filename || 'attachment';
      saveAs(blob, filename);
    } else {
      console.error('Invalid attachment data');
    }
  };

  console.log(emailData, 'EEEEEEEEEEEEEEEEEE');
  return (
    <div className="p-2 ">
      {/* Nav bar */}
      <div className="flex items-center pb-3  pr-1 pt-4 text-xs text-column">
        <FaChevronLeft className="w-12 cursor-pointer select-none" onClick={() => handleCurrentEmailIndex(-1)} />
        {emailData?.loading ? (
          <div className="flex w-full justify-center">
            <SkeletonText noOfLines={1} spacing="2" skeletonHeight="3" className="w-12" />
          </div>
        ) : (
          <span className="w-full text-center">
            {currentEmailIndex} of {unmatched_emails?.current_length}
          </span>
        )}
        <FaChevronRight className="w-12 cursor-pointer select-none" onClick={() => handleCurrentEmailIndex(1)} />
      </div>
      {/* Header profile */}
      {emailData?.loading ? (
        <div className="p-4">
          {/* Profile loader */}
          <div className="mt-6 flex items-center gap-3">
            <div className="flex flex-col items-center justify-center gap-2">
              <SkeletonCircle size="12" />
            </div>
            <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
          </div>
          {/* Subject loader */}
          <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />

          {/* Message loader */}
          <SkeletonText mt="4" noOfLines={20} spacing="1.5" skeletonHeight="2" className="w-full" />
        </div>
      ) : (
        <>
          <div className="ml-2 mr-4 flex items-center border-y pb-3 pt-5">
            <div className="flex w-full gap-3">
              <div className="flex h-14 w-14 items-center justify-center rounded-full p-1 shadow-md">
                {profile?.photo ? (
                  <img className=" h-12 w-12 rounded-full" src={''} alt="" />
                ) : (
                  <div className="flex h-12 w-12 items-center justify-center rounded-full border bg-gray-100 transition-all duration-300 group-hover:bg-brand-50">
                    <FaUser className={`text-2xl text-gray-400 duration-500 `} />
                  </div>
                )}
              </div>

              <div className="flex flex-col justify-center">
                <h4 className="font-semibold capitalize">{emailData?.profile?.name}</h4>
                <p className="text-xs text-column">{emailData?.profile?.receiver_email}</p>
              </div>
            </div>
            <div className="flex w-full flex-col items-end gap-1.5">
              <span className="text-[.625rem]">{emailData?.profile?.date}</span>
              <div className="flex gap-2">
                <CustomMenu
                  btnContent="Create Opportunity"
                  leftIcon={<IoMdCreate />}
                  btnClassName={`border border-gray-200 flex gap-1 justify-center items-center ${
                    isOpen ? 'border-menuBorder' : 'border-gray-100'
                  } px-3 py-1 rounded-md text-[.625rem] font-medium cursor-pointer`}
                  menuListClassName={`border-2 w-[15.625rem] rounded-md ${isOpen ? 'border-menuBorder' : 'border-gray-100'} right-0 mt-2 `}
                >
                  <div className="flex flex-col border-b p-2">
                    <TextInput label="Name*" placeholder="jay@joytechnologies" name="name" onChange={handleInputChange} />
                    <TextInput label="Email ID*" placeholder="hello@joytechnologies" name="email" onChange={handleInputChange} />
                    <TextInput label="Company name*" placeholder="hello@joytechnologies" name="company_name" onChange={handleInputChange} />
                    <button className="mb-1 ml-1 mt-2 flex w-fit select-none items-center gap-0.5 rounded-full bg-lightGray px-2 py-0.5 text-[.5625rem]">
                      <span>Add new feilds</span>
                      <IoMdAdd />
                    </button>
                  </div>
                  <div className="mx-3 my-2 flex gap-2">
                    <Button name="Create new opportunity" customClass="w-full transition-all duration-300" py="py-1.5" />
                  </div>
                </CustomMenu>
                <CustomMenu
                  btnContent="Match"
                  rightIcon={<FiCornerUpRight />}
                  btnClassName={`border flex gap-1 border-gray-200 justify-center items-center ${
                    isOpen ? 'border-menuBorder' : 'border-gray-100'
                  } px-3 py-1 rounded-md text-[.625rem] font-medium cursor-pointer`}
                  menuListClassName={`border-2 w-[15.625rem] rounded-md ${isOpen ? 'border-menuBorder' : 'border-gray-100'} right-0 mt-2`}
                >
                  {matchCampaignData?.stage === 0 && (
                    <>
                      <div className="flex flex-col gap-1.5">
                        <div className="mx-2 mt-2 flex gap-2 text-column">
                          <BiArrowBack className=" cursor-pointer" onClick={() => handleChangeStage(1)} />{' '}
                          <h5 className="cursor-default text-xs font-medium">Check match from you company</h5>
                        </div>

                        <div className="h-48 overflow-y-auto">
                          <div className="mx-1 mt-1 flex px-2">
                            <div className="flex w-full gap-3">
                              <div className="flex h-8 w-8 items-center justify-center rounded-full p-1 shadow-md">
                                {profile?.photo ? (
                                  <img className=" h-6 w-6 rounded-full" src={''} alt="" />
                                ) : (
                                  <div className="flex h-6 w-6 items-center justify-center rounded-full border bg-gray-100 transition-all duration-300 group-hover:bg-brand-50">
                                    <FaUser className={`text-sm text-gray-400 duration-500 `} />
                                  </div>
                                )}
                              </div>

                              <div className="flex flex-col justify-center">
                                <h6 className="text-xs font-semibold capitalize">{profile?.name}</h6>
                                <p className="whitespace-nowrap text-[10px] text-column">{profile?.campaign_name}</p>
                              </div>
                            </div>
                            <div className="mt-0.5 flex w-full flex-col items-end gap-1.5">
                              <span className="text-[.625rem]">{profile?.date}</span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 border-t-2 border-dashed p-2 text-center text-[10px] font-bold">
                          Not able to find your match?{' '}
                          <span className="cursor-pointer select-none text-blueSecondary underline" onClick={() => handleChangeStage(1)}>
                            search here
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {matchCampaignData?.stage === 1 && (
                    <>
                      <div className="flex flex-col gap-1.5">
                        <div className="flex items-center text-column">
                          <BiArrowBack className="ml-2 cursor-pointer" onClick={() => handleChangeStage(0)} />{' '}
                          <SearchBox placeholder="Search campaigns or oppotunities" extraClassName="w-full" inputClass="!text-xs font-normal" />
                        </div>
                        <div className="flex h-44 items-center justify-center text-[10px] text-column">no search</div>
                      </div>
                    </>
                  )}
                </CustomMenu>
              </div>
            </div>
          </div>
          {/* Message */}
          <div className="h-[29.375rem] w-[32rem] overflow-y-auto">
            <div className="p-3">
              {/* subject */}
              <h4 className="py-2 text-sm font-semibold">{emailData?.profile?.subject}</h4>
              {/* message */}
              {/* <div className='text-xs mt-2 w-[30rem] overflow-x-auto textEditor' dangerouslySetInnerHTML={{ __html: emailData?.profile?.message[0]?.message }} /> */}
              {/* <div className='text-xs w-full border border-red-500 leading-5 text-paused-color text-wrap'>{emailData?.profile?.message}</div> */}
            </div>

            {/* Attachment */}
            {emailData?.profile?.message?.[0]?.attachment?.length ? (
              <div className="mx-3 mt-1 rounded-md border">
                <div className="bg-headerbg rounded-md px-3 py-1 text-[.6875rem] text-column">Attachment</div>
                <div className="flex flex-wrap border-t p-3">
                  {/* files */}
                  {emailData?.profile?.message?.[0]?.attachment?.map((attachment: any, index: number) => {
                    const formatBytes = (bytes: number) => {
                      if (bytes < 1024) return `${bytes} Bytes`;
                      let kb = bytes / 1024;
                      if (kb < 1024) return `${kb.toFixed(3)} KB`;
                      let mb = kb / 1024;
                      if (mb < 1024) return `${mb.toFixed(3)} MB`;
                      let gb = mb / 1024;
                      return `${gb.toFixed(3)} GB`;
                    };
                    const size = formatBytes(attachment?.size);

                    return (
                      <div className="mt-2 flex w-1/2 gap-2" key={`${index}_attachment`}>
                        <div className="h-fit rounded-full bg-hover p-2 text-sm text-blueSecondary">
                          <CgFile />
                        </div>
                        <div className="flex w-full flex-col gap-1 pt-1">
                          <span className="text-xs font-medium">{attachment?.filename}</span>
                          <div className="flex text-[.6875rem]">
                            <span>{size} -</span>
                            <span className="cursor-pointer pl-1 pr-1.5 text-blueSecondary" onClick={() => downloadFile(attachment)}>
                              Download
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* file - 2 */}
                  {/* <div className='flex gap-2 w-full'>
                  <div className='rounded-full bg-hover p-2 text-blueSecondary text-sm h-fit'><CgFile /></div>
                  <div className='w-full flex flex-col gap-1 pt-1'>
                    <span className='text-xs font-medium'>Brief.pdf</span>
                    <div className='flex text-[.6875rem]'>
                      <span>101 KB -</span>
                      <span className='pl-1 pr-1.5 text-blueSecondary cursor-pointer'>Download</span>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Old messages */}
            {emailData?.profile?.message?.length > 1 ? (
              <div className="p-2">
                {/* Border */}
                <div className="flex items-center  pt-2">
                  <hr className="w-full" />
                  <div className="mx-2 whitespace-nowrap rounded-full border px-3 py-1 text-[8px]">{emailData?.profile?.message?.length - 1} Older Messages</div>
                  <hr className="w-full" />
                </div>

                {/* messages */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RightSideView;
