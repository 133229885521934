import { useDisclosure } from '@chakra-ui/hooks';
import { card1SVG, card2SVG, card3SVG, card4SVG, card5SVG, card6SVG } from 'assets/img/rewards';

import React from 'react'
import { FaCheckCircle } from 'react-icons/fa';
import { ReactSVG } from 'react-svg';
import StepsModal from './StepsModal';

const Rewards = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const scratchCards = new Array(6).fill('card');
  const svgCollection = [card1SVG, card2SVG, card3SVG, card4SVG, card5SVG, card6SVG];

  const handleCard = ()=>{
    onOpen();
  }

  return (
    <div className="mt-2.5 space-y-5 h-[88vh] w-full overflow-y-hidden rounded-md bg-white py-3 px-7  dark:bg-darkOne dark:text-white noscroll">
      <div className='h-48 bg-gradient-to-r from-[#FFFFFF] to-[#4318FF10]'>

      </div>
      <div className='h-[26rem] w-full overflow-auto grid grid-cols-4 gap-y-5'>
        {scratchCards?.map((card, i) => <div className='flex flex-col items-center h-[13.5rem] w-[18.4rem] border border-[#4286F580] rounded-md hover:scale- bg-[#4286F50A] cursor-pointer' key={i} onClick={handleCard}>
          <ReactSVG src={svgCollection[i]} beforeInjection={(svg: any) => { svg.setAttribute('width', '190'); return svg; }} />
          <h5 className='text-[0.85rem] text-heading font-semibold w-11/12 text-center -mt-0.5 leading-4'>Scratch card worth up to 50 Sparkle credits</h5>
          <h5 className='text-[0.9rem] text-column font-medium flex gap-1.5 items-center mt-2'><FaCheckCircle className='rounded-full bg-white text-[#00BA00] -mt-0.5' /><span>Unlocked</span></h5>
        </div>)}
      </div>
      <StepsModal stepOpen = {isOpen} stepClose = {onClose}/>
    </div>
  )
}

export default Rewards