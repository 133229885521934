import { AuthImg, SparkleTransparantWhiteImg, SparkleNameWhiteImg, EmailVerifierWhiteLogo } from 'assets/img/auth';
import usePageTitle from 'common/hooks/usePageTitle';
import { useAppSelector } from 'store';
function LogoCard(props: { maincard: JSX.Element }) {
  usePageTitle();
  const { maincard } = props;
  const { environment } = useAppSelector((state) => state.globalsettings);

  return (
    <div className="relative flex">
      <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
        <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
          {maincard}
          <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
            <div
              className={`absolute flex h-full w-full items-end justify-center bg-gradient-to-br from-brand-400 to-brand-600 bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]`}
            >
              <div className="relative flex h-full w-full ">
                <div style={{ backgroundImage: `url(${AuthImg})` }} className="flex h-full w-full bg-cover lg:rounded-bl-[120px] xl:rounded-bl-[200px]" />
                <div className="absolute left-[50%] top-[40%] flex translate-x-[-50%] translate-y-[-50%] flex-col items-center justify-center">
                  {/* <ReactSVG src={AuthLogoSvg} beforeInjection={(svg: any) => { svg.setAttribute('width', '200');svg.setAttribute('height', '200');return svg; }} /> */}
                  {/* <ReactSVG src={AuthLogoWithSvg} beforeInjection={(svg: any) => { svg.setAttribute('width', '200');svg.setAttribute('height', '80');return svg; }} /> */}
                  {environment?.site_ui_access_feature?.sparkle_logo_base ? (
                    <>
                      <img src={SparkleTransparantWhiteImg} alt="" className="bg-blend-plus-darker	" />
                      <img src={SparkleNameWhiteImg} alt="" />
                    </>
                  ) : environment?.site_ui_access_feature?.email_verifier_logo_base ? (
                    <>
                      {/* <img src={SparkleTransparantWhiteImg} alt="" className="bg-blend-plus-darker	" /> */}
                      <img src={EmailVerifierWhiteLogo} alt="" />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoCard;
