import { GeneratorComp } from "./GeneratorComp"

type itemProps = {
    url?: string,
    title?: string,
    items?: any,
}

export const SequenceBox = (items : any) => {
    return(
    <div className="transition-all">
      {items.items.map((item :itemProps, index :number) => (
        <GeneratorComp item={item} key={index} />
      ))}
    </div> 
  )
}