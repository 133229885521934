const ChartSkeliton = () => {
  return (
    <div className="shadow-sm m-2.5 flex rounded-md border border-[#e0ccfc] dark:border-darkBorder bg-white p-2 dark:bg-darkTwo dark:text-white sm:overflow-x-auto">
      <div className="flex w-full animate-pulse items-center space-x-4 ">
        <div className="m-2 flex h-72 w-72 items-center justify-center rounded-full bg-gray-300">
          <div className="h-36 w-36 rounded-full bg-white" />
        </div>
        <div className="flex-1 space-y-6 p-4 py-1">
          <div className="h-8 w-32 rounded bg-gray-300" />
          <div className="h-8 rounded bg-gray-300" />
          <div className="space-y-3">
            <div className="h-8 rounded bg-gray-300" />
            <div className="h-8 rounded bg-gray-300" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartSkeliton;
