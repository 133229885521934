import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor'
import { CustomMenu } from 'components/customMenu'
import React, { useContext } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { useDispatch } from 'react-redux'
import { setVariableArea, setMessageData, insertVariable } from 'store/campaign-v2'
import { ContentDrawerContext } from './ContentDrawer'
import { useAppSelector } from 'store'

const EmailDrawer = () => {
    const { id } = useContext(ContentDrawerContext);
    const state = useAppSelector(state => state?.campaignv2);
    const dispatch = useDispatch();
    const { type, delivery_data } = state?.sequence[id];
    const version = state?.current_version === 'A' ? 'version_a' : 'version_b';
    const [variableMenu, setVariableMenu] = React.useState(false);
    const editorRef = React.useRef(null);
    const variables = Object.values(state?.prospects?.selected_variable)?.filter((item) => item?.variable !== 'select')?.map((item) => item?.variable)

    const getMissedVariables = () => {
        const prospects_list = state?.prospects?.prospects_list
        if (prospects_list?.length) {
            const verification = (key: string) => {
                let validation = false
                for (let item of prospects_list) {
                    if (item[key] === '') {
                        validation = true
                        break;
                    }
                }
                return validation
            }
            const variableNames = Object.keys(prospects_list[0])?.filter((item: string) => verification(item))
            return state?.prospects?.variable_list?.filter((item) => variableNames?.includes(item?.name)).map(item => item?.variable)
        }
    }

    const missedVariable = getMissedVariables() || [];
    const handleInsertVariable = (variable: string) => {
        if (state.variable_area === 'subject') {
            dispatch(insertVariable(variable))
        } else {
            editorRef.current.insertHTML(variable);
            editorRef?.current?.core.focus()
        }
        setVariableMenu(false)
    };

    return (
        <div className='rounded-md bg-[#A3AED00F] flex flex-col gap-3 p-3.5 relative select-none'>
            <div className='flex justify-between'>
                <h5 className='text-[#667085] text-[0.85rem] font-medium capitalize'>{type} - {state?.current_version === 'A' ? 'Version A' : 'Version B'}</h5>
                {/* <button className='rounded-sm bg-[#F4F7FE] font-semibold py-1.5 px-2 text-[0.7rem] border border-[#E9EDF7]'>Preview E-Mail</button> */}
            </div>
            {id !== 0 && !delivery_data[version]?.subject && <h5 className='rounded-md text-[0.7rem] px-2 py-0.5 bg-purple-100 font-medium'> <b>Note</b> : Leave the Subject Empty to send in the same thread</h5>}
            <input
                id='content_drawer_subject_input'
                spellCheck={false}
                value={delivery_data[version]?.subject || ''}
                onFocus={() => dispatch(setVariableArea('subject'))}
                onChange={(e) => dispatch(setMessageData({ input: 'subject', value: e.target.value }))}
                placeholder='Subject' className='placeholder:text-[#667085] rounded-md border resize-none border-gray-200 p-2 text-xs text-[#667085] focus:text-blackDark selection:decoration-[1.5px] focus:outline focus:outline-2 focus:outline-menuBorder selection:decoration-yellow-400 selection:underline selection:bg-yellow-50'
            />

            <CustomSunEditor
                leftContent={<div className='flex items-start gap-2.5'>
                    {/* <BsStars className='text-white bg-brand-500 rounded-full size-5 p-[0.25rem]' /> */}
                    <CustomMenu btnId='add_variable_btn' btnContent='Variables' open={variableMenu} onOpen={() => { setVariableMenu(true) }} onClose={() => setVariableMenu(false)} rightIcon={<BiChevronDown className='size-4' />} btnClassName='!border !border-blueSecondary !font-semibold !bg-buttonBackground !text-blueSecondary' menuListClassName='!p-0 !mt-1 max-h-36 min-w-full overflow-y-auto'>
                        {Object.values(state?.prospects?.selected_variable)?.map((item, i) => {
                            return <React.Fragment key={i}>
                                {
                                    item?.variable !== 'select' ?
                                        <div id={item?.name} className='text-center p-1 text-xs font-semibold text-heading hover-shadow-shade hover:bg-blueSecondary hover:text-white w-full cursor-pointer py-1.5 rounded-md text-nowrap' onClick={() => handleInsertVariable(item.variable)} >{item.variable}</div>
                                        : <></>
                                }
                            </React.Fragment>
                        })}
                    </CustomMenu>

                    {/* <Button name='Personalisation' RightIcon={<BiChevronDown className='size-4' />} customClass='border-blueSecondary font-semibold' px='px-2' py='py-1.5' fontSize='[0.7rem]' color='[#4318FF]' bg='[#F9F5FF]' /> */}
                </div>}
                defaultValue={delivery_data[version]?.message}
                onFocus={() => { dispatch(setVariableArea('message')) }}
                editorRef={editorRef}
                onChange={(value) => { dispatch(setMessageData({ input: 'message', value })) }}
                variablePattern={true}
                variables={variables}
                missedVariables={missedVariable}
                variablesClass={['valid_variables', 'missed_variables', 'invalid_variables']}
            />
        </div>
    )
}

export default EmailDrawer