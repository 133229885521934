import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import AddGmailPopup from './add-email';
import { useState } from 'react';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

interface ModalPropsDTO {
  isOpen?: boolean;
  onClose?: any;
}

const ConfrimationPopup = (props: ModalPropsDTO) => {
  //   const [account_type, setSelectedAccountType] = useState(1);
  const { isOpen, onClose } = props;
  const [isGmailPopup, SetIsGmailPopup] = useState(false);
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'md'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="!p-0">
          <ModalHeader>
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold text-heading">Confrimation</h4>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <ModalBody className="bg-modalbg !p-0">
            <div className="flex items-center justify-center">
              <div className="w-[44rem] px-6  py-6 shadow-md">
                <div className=" flex h-24 select-none gap-5 rounded-md bg-white p-4">
                  <p className="text-blueSecondary">Have you completed the setup in Google Admin Console? </p>
                </div>
                <div className="mt-6 flex gap-4">
                  <Button
                    name="Proceed"
                    customClass="w-full bg-white border !text-white !bg-blueSecondary !font-semibold text-[.82rem]"
                    onClick={() => {
                      onClose();
                      SetIsGmailPopup(true);
                    }}
                  />
                  <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={() => onClose()} />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AddGmailPopup isOpen={isGmailPopup} onClose={() => SetIsGmailPopup(false)} />
    </div>
  );
};

export default ConfrimationPopup;
