import { BaseApi } from 'common/api/common/base-api';
import { toastState } from 'common/utils/utility';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import CommonLayout from '../common-Layout/CommonLayout';
// import SnoozPickerPopup from './feature/SnoozPickerPopup';
// import ClearEmailAlertPopup from './feature/ClearEmailAlertPopup';

const UnifiedTaskList = () => {
  useEffect(() => {
    (async () => {
      try {
        const response = await BaseApi.get('/unified/inbox/');
        if (response?.error) {
          toast.error(response?.message, toastState.error);
        } else {
          console.log(response, 'response');
        }
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CommonLayout></CommonLayout>
    </div>
  );
};

export default UnifiedTaskList;
