import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { BiSolidMessageCheck } from 'react-icons/bi';

const RowSettings = (props: any) => {
  const { handleListDelete, id, handleEdit, rowData } = props;
  const [showOptions, setShowOptions] = useState(false);

  const handleOptions = (selectedOpt: string, rowData?: any) => {
    setShowOptions(false);
    if (selectedOpt === 'delete') {
      handleListDelete(rowData?.uuid);
    }

    if (selectedOpt === 'edit') {
      handleEdit(rowData?.uuid, rowData);
    }
  };

  return (
    <>
      <div className={`relative mr-6 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`} tabIndex={0} onBlur={() => setShowOptions(false)}>
        <IoMdMore className="blacklist-row-setting-btn z-50 text-lg text-gray-700" id={`edit-btn-${id}`} onClick={() => setShowOptions(true)} />
        {showOptions && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:border-darkBorder dark:bg-darkTwo">
            {/* Role Restriction for Ticket Rise */}

            <div
              className=" group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => handleOptions('edit', rowData)}
              id={`data-${id}`}
            >
              <BiSolidMessageCheck className="text-brand-500 group-hover:text-white" />
              <h5 className="text-nowrap text-xs">Edit</h5>
            </div>

            <div
              className=" group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => handleOptions('delete', rowData)}
              id={`delete-${id}`}
            >
              <MdDelete className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Delete</h5>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RowSettings;
