import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdOutlineClose, MdOutlineRotateLeft } from 'react-icons/md';
import { KeyValuePair } from 'common/dto/types/views/email-verifier';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  modalData: ModalDataType;
}

const AnalysisModal = (props: ModalComponentProps) => {
  const { onClose, isOpen, modalData } = props;

  const getIconAndLabel = (key: any, value: any) => {
    // Determine if the value is considered valid based on the conditions dictionary
    const conditions: any = {
      isValidSyntax: true, // true is good, false is bad
      isValidDomain: true, // true is good, false is bad
      isTempEmail: false, // true is bad, false is good
      isRoleBasedAccount: false, // true is bad, false is good
      isCatchAllDomain: false, // true is bad, false is good
      isValidSmtp: true, // true is good, false is bad
      isValidEmail: true, // true is good, false is bad
      isTarpitting: false, // true is bad, false is good
      isGreyListed: false, // true is bad, false is good
      hasMxRecords: true,
      mxHosts: true,
      isSafeToSend: true,
      isDeliverable: true,
      canConnectSmtp: true,
      isFreeEmailAccount: true,
    };
    const isValid = (conditions[key] && value) || (!conditions[key] && !value);
    return {
      icon: isValid ? <AiFillCheckCircle className="size-6 text-green-600" /> : <MdOutlineClose className="size-6 text-[#F04438]" />,
    };
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalHeader>
            <div className="space-y-1">
              <h5 className="text-sm text-heading dark:text-purple-500">Real time analysis</h5>
              <p className="flex w-3/4 gap-1 text-xs font-thin text-column dark:text-gray-300">
                {modalData?.email || ''}
                <span>
                  <AiFillCheckCircle className="size-4 text-green-600" />
                </span>
              </p>
              {/* <p className="w-3/4 text-xs font-thin text-column">
                Id score:{' '}
                <b
                  className="font-semibold"
                  style={{ color: !modalData?.result ? '#2B3674' : '#667085' }}
                >
                  {modalData?.result ? 67 : 'Evaluating'}
                  
                </b>{' '}
              </p> */}
              <p className="w-3/4 text-xs font-thin text-column dark:text-gray-300">
                Classification:{' '}
                {/* <b
                  className="font-semibold"
                  style={{ color: modalData?.result ? '#2B3674 dark:#a855f7' : '#667085 dark:#a855f7' }}
                > */}
                <b className={`font-semibold ${modalData?.result ? 'text-[#2B3674] dark:text-purple-500' : 'text-[#667085] dark:text-orange-400'}`}>
                  {modalData?.result ? modalData?.result : 'Analyzing'}
                </b>{' '}
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton id="email-verify-modal-close-button" />
          <ModalBody>
            <div className="mb-3 h-96 overflow-auto">
              <div className="flex items-center border-b bg-[#F9FAFB] dark:border-darkBorder dark:bg-darkOne px-3 py-2 text-sm text-column">
                <h5 className="w-full dark:text-gray-300">Text Preferred</h5>
                <h5 className="w-full dark:text-gray-300">Status</h5>
              </div>
              {modalData?.multiResult &&
                modalData.multiResult.map(({ key, value }) => (
                  <div className="flex items-center border-b dark:border-darkBorder bg-white dark:bg-darkTwo p-3 text-sm text-column" key={key}>
                    <h5 className="flex w-full items-center gap-1 font-semibold text-heading dark:text-gray-300">
                      <span>{key}</span>
                      {/* <AiFillInfoCircle className="size-4 text-[#8F9BBA]" /> */}
                    </h5>
                    <h5 className="flex w-full items-center gap-1 font-semibold text-heading dark:text-gray-300">
                      {value === 'Verification pending' ? <MdOutlineRotateLeft className="size-6 animate-spin text-column" /> : getIconAndLabel(key, value).icon}

                      <span>{value === 'Verification pending' ? 'Verification pending' : value ? 'True' : 'False'}</span>
                    </h5>
                  </div>
                ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnalysisModal;

// const conditions = {
//   isValidSyntax -  true is good false is bad
//   isValidDomain: -  true is good false is bad
//   isTempEmail: -  true is bad false is good
//   isRoleBasedAccount: - true is bad false is good
//   isCatchAllDomain: - true is bad false is good
//   isValidSmtp: -  true is good false is bad
//   isValidEmail: -  true is good false is bad
//   isTarpitting: -  true is bad false is good
//   isGreyListed: -  true is bad false is good
// };
