import { Avatar } from '@chakra-ui/react';

// const statusText = getStatusText('campaign', item?.campaign_status);
// const badgeColor = getStatusBadgeColor('campaign', item?.campaign_status);
const CampaignProspectSearchData = (props: any) => {
  const { data, handleNavigate } = props;

  return (
    <div>
      {data?.map((item: any) => {
        return (
          <>
            <div className="my-2 cursor-pointer rounded-lg  p-2  hover:bg-lightpurplebg " onClick={() => handleNavigate(item?.url)}>
              <div className=" flex justify-between gap-4">
                <div className="self-center">
                  <Avatar className="size-2 " size={'sm'} name={item?.email.substring(0, 2)} src={item?.profile_pic ? item?.profile_pic : ''} color={'white'} bg={'#667085'} />
                </div>
                <div className=" flex flex-1 flex-col text-[13px]">
                  <p className="m-0 w-56  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">{`${item?.first_name}  ${item?.last_name}`}</p>
                  <p className="m-0 w-56  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column ">{item?.email}</p>
                </div>
                <div className="flex flex-col text-[13px]">
                  <p className="m-0 max-w-36 overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">{item?.campaign_name}</p>
                  {/* <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{moment(item?.cdate).format('D MMM YYYY')}</p> */}
                </div>
              </div>
            </div>
            <hr className=" w-full" />
          </>
        );
      })}
    </div>
  );
};

export default CampaignProspectSearchData;
