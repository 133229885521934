import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal'
import React from 'react'
import LeftSideView from './LeftSideView'
import RightSideView from './RightSideView'
import { useAppDispatch, useAppSelector } from 'store'
import { setPreview } from 'store/campaign-v2'

const PreviewModal = () => {
    const preview = useAppSelector(state => state.campaignv2.review.preview)
    const dispatch = useAppDispatch()
    
    return (
        <>
            <Modal isOpen={preview.preview_modal} onClose={() => { dispatch(setPreview({ preview_modal: false })) }} isCentered={true} size={'auto'}>
                <ModalOverlay />
                <ModalContent className='!w-[58rem]'>
                    <ModalBody className="!p-0">
                        <div className="relative flex">
                            <div className="w-[40.625rem] border-r select-none">
                                <LeftSideView />
                            </div>
                            <div className="w-full">
                                <RightSideView />
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PreviewModal