/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Button from '../Button';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { CSVFileDownloader, toastState } from 'common/utils/utility';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';

interface PropsType {
  onUpload?: (flattenedData: any) => void;
  dependencies?: any;
  keys?: string[];
  confirmation?: boolean;
  sampleCSVData?: any;
  disable?: boolean;
  customClass?: string;
}

interface CommonType {
  [key: string]: number | string | undefined | Object | null;
}

const ImageFileUploader = (props: PropsType) => {
  const { dependencies, onUpload, keys, confirmation, sampleCSVData, disable, customClass } = props;
  const fileMapUpload = new Map();
  const dependency = dependencies || [];
  const[loading,setLoading]=React.useState(false)
  const [fileData, setFileData] = React.useState({
    file_name: '',
    file_data: '',
    file_Url: '',
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const upload = (data: any) => {
    if (onUpload) {
      onUpload(data);
    }

    toast.success('File uploaded successfully', toastState.success);
  };
  const onDrop = React.useCallback(
    (acceptedFiles: any[]) => {
      //  console.log("first")

      if (!disable) {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => console.log('file reading has failed');
          fileMapUpload.set(reader, file);
          reader.onload = async (e: any) => {
            const fileAttr = fileMapUpload.get(e.target);
            const fileExtension = fileAttr.name.split('.').pop().toLowerCase();
            // Check if the file is an image
            if (!['png', 'jpg', 'jpeg'].includes(fileExtension)) {
              toast.error('This file format is not accepted', toastState.error);
              return;
            }

            //AzureBucket URL

            // if (file) {
            const formData = new FormData();
            formData.append('documents', file);
            setLoading(true)
            const uploadImg = await BaseApi.post('/helper/store/az/bucket', formData);
            setFileData((prevState) => ({
              ...prevState,
              file_name: file?.name,
              file_Url: uploadImg?.data?.url,
            }));

            // setFileName(file.name);
            // onChange?.({ file_name: file.name, data: uploadImg });
            //   console.log('file', file);
            //   const reader = new FileReader();
            //   reader.onload = (e) => {
            //     console.log('first', e);
            //     onChange?.({ file_name: file.name, data: e.target?.result });
            //   };
            //   setFileName(file.name);
            // reader.readAsDataURL(file);

            // Process the image file (you can adjust this part based on your requirements)
            const imageUrl = URL.createObjectURL(file);
            setFileData((prevState) => ({
              ...prevState,
              file_name: fileAttr.name,
              file_data: imageUrl,
            }));
            // setFileData({
            //   file_name: fileAttr.name,
            //   file_data: imageUrl, // Store image URL for preview or use
            // });

            // Optionally, display image preview or handle upload
            confirmation
              ? onOpen()
              : upload({
                  file_name: fileAttr.name,
                  file_data: imageUrl,
                  file_Url: uploadImg?.data?.url, // Pass the URL or the actual image data
                });
            // }
            setLoading(false)

            // URL.revokeObjectURL(imageUrl);
          };

          reader.readAsDataURL(file); // Changed from readAsArrayBuffer to readAsDataURL for images
        });
      }
    },
    // Dependencies
    [disable, fileMapUpload, setFileData, confirmation, onOpen, upload]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handleYes = () => {
    if (fileData?.file_data?.length) upload(fileData);
    onClose();
  };

  const handleNo = () => {
    setFileData((prevState) => ({
      ...prevState,
      file_data: '',
      file_name: '',
      file_Url: '',
    }));
    // setFileData({ file_data: '', file_name: '' });
    onClose();
  };

  const handleDownloadSampleCSV = (event: any) => {
    event?.stopPropagation();
    CSVFileDownloader(sampleCSVData, 'sample.csv');
  };

  const fileDisable = disable || loading
  return (
    <div className="relative">
      <div className="h-full w-full pt-4 dark:!border-yellow-200">
        <div
          className="flex h-full !w-[43.75rem] cursor-pointer items-center justify-center rounded-xl border-dashed border-white dark:!border-yellow-200 dark:!border-dashed "
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} accept=".jpg, .jpeg, .png" />
          <button className="h-full !w-full" disabled={fileDisable}>
            <div
              id="file_uploader"
              className={`flex h-[14.0625rem] w-full flex-col items-center justify-center gap-1 rounded-md border-[.1rem] border-dashed  
                             p-3 dark:!border-none dark:!bg-darkThree ${fileDisable ? 'border-gray-200 bg-gray-50' : 'border-brand-500 bg-bgUpload'} ${customClass}`}
            >
              <div className="pb-3">
                <IoCloudUploadOutline className={`${fileDisable ? 'text-gray-400' : 'text-darkBlue'} text-[2.8125rem]`} />
              </div>
              <p className={`text-xs font-bold dark:text-white ${fileDisable ? 'text-gray-400' : 'text-darkThree'}`}>
                Drag & drop files or <span className={`${fileDisable ? 'text-gray-400' : 'text-purple-700'} font-bold  underline dark:text-brand-400`}>Browse</span>
              </p>
              <p className={`pt-1 text-[.625rem] ${fileDisable ? 'text-gray-400' : 'text-gray-700'}`}>Supported formats: JPG, PNG</p>
              {sampleCSVData && (
                <p className={`text-[.625rem] hover:underline ${fileDisable ? 'text-gray-400' : 'text-blueSecondary'}`} onClick={handleDownloadSampleCSV}>
                  Download Sample Photo file
                </p>
              )}
              <div className="mt-2 flex justify-center">
                <div id="upload_box" className={`rounded-md px-12 py-1.5 text-[.875rem] text-white ${fileDisable ? 'bg-gray-300' : 'bg-blueSecondary'}`}>
                  Upload Photo
                </div>
              </div>
            </div>
          </button>
        </div>
        {loading&&
        <div className="absolute left-[45%] top-1/2">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-b-horizonPurple-300 border-t-brandLinear"></div>
      </div>}
      </div>
      <Modal isOpen={isOpen} onClose={handleNo} size={'sm'} isCentered={true}>
        <ModalOverlay />
        <ModalContent className="px-2 pb-6 pt-12">
          <ModalBody>
            <h4 className="select-none text-center text-[18px] font-semibold text-heading">
              Do you want to add {fileData?.file_data?.length} entries from {fileData?.file_name}?
            </h4>
          </ModalBody>

          <ModalFooter className="flex !justify-center">
            <div className="mt-3 flex w-11/12 gap-3">
              <Button name="No" customClass="w-full !bg-white !text-blueSecondary !border-blueSecondary font-semibold !text-[12px]" onClick={handleNo} />
              <Button id="modal_popup_yes_btn" name="Yes" customClass="w-full font-semibold !text-[12px]" onClick={handleYes} />
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ImageFileUploader;
