import React from 'react';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setSideBarFullView } from 'store/custom';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { homeSideBarData, settingsSideBarData } from 'common/dto/initial-value/views/sideBar';
import { setAppSettings } from 'store/authentication';
import EnvironmentHomeLogo from 'common/sparkle-common/HomeLogo';

const AsideNav = () => {
  const [nestedView, setNestedView] = useState<any>({});
  const { sideBarFullView } = useAppSelector((state) => state.custom);
  const { environment } = useAppSelector((state) => state.globalsettings);

  const { menu } = useAppSelector((state) => state.custom);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSettingsPath = location.pathname.startsWith('/settings');

  let sideBarData: any = isSettingsPath ? settingsSideBarData : homeSideBarData;
  sideBarData = sideBarData?.filter((val: any) => environment?.app_access_environment?.some((item: any) => item?.feature === val?.menu_list_id));

  return (
    <div
      className={`group/sidebar // relative flex h-screen flex-col items-center rounded-md bg-white transition-all duration-500 dark:bg-darkThree
      dark:text-white ${sideBarFullView ? `w-[24.4%] min-w-[12rem] max-w-[13.125rem]` : 'w-[4.1rem] min-w-[4rem] max-w-[5rem]'}
       `}
    >
      <EnvironmentHomeLogo />
      <div className="mx-3 cursor-default select-none space-y-1.5 font-medium text-gray-800 dark:text-white">
        {sideBarData?.length &&
          sideBarData?.map((item: any, index: number) => {
            return isSettingsPath ? (
              <div className="group relative" key={index}>
                <button
                  id={`${item?.key}_sidemenu`}
                  key={`${item?.key}_${index}`}
                  className={`text-md flex w-full items-center gap-2.5 rounded-md p-2 text-column hover:bg-brand-500 hover:text-white dark:text-white  dark:hover:bg-purple-900 ${
                    location.pathname.includes(item.path) ? 'bg-brand-500 text-white dark:bg-purple-900' : ''
                  }`}
                  name={`${item?.name}`}
                  onClick={() => {
                    if (item?.path) navigate(`${item?.path}`);
                  }}
                >
                  {item?.icon}
                  <span className={`text-sm ${!sideBarFullView && 'hidden'}`}>{item?.name}</span>
                </button>
                {!sideBarFullView ? (
                  <ArrowedTooltip position="left" className={`left-[3.5rem] hidden group-hover:block`} toolTipId={`row-cdate-tooltip-1`} data={item?.name} />
                ) : null}
              </div>
            ) : menu?.[item?.key] ? (
              item?.nestedData?.length ? (
                <div id={`${item?.key}_sidemenu`} key={`${item?.key}_${index}`} className="group relative">
                  <div
                    className={`text-md flex w-full cursor-pointer items-center gap-2.5 rounded-md p-2 text-column hover:bg-brand-500 hover:text-white dark:text-white dark:hover:bg-purple-900`}
                    onClick={() => {
                      dispatch(setSideBarFullView(true));
                      setNestedView((prev: any) => ({
                        ...prev,
                        [item?.key]: !prev[item?.key],
                      }));
                    }}
                  >
                    {item?.icon}
                    <span className={`text-sm  ${!sideBarFullView && 'hidden'}`}>{item?.name}</span>
                    {sideBarFullView && <IoIosArrowDown />}
                  </div>
                  {!sideBarFullView ? (
                    <ArrowedTooltip position="left" className={`left-[3.5rem] hidden group-hover:block`} toolTipId={`row-cdate-tooltip-1`} data={item?.name} contentclass="" />
                  ) : null}
                  <div className={`cursor-pointer space-y-2 py-1 text-xs font-bold text-heading dark:text-white ${nestedView[item?.key] ? 'block' : 'hidden'}`}>
                    {item?.nestedData?.map((nestedItem: any, index: number) => (
                      <React.Fragment key={index}>
                        {menu[item?.key] && (
                          <h5
                            id={`${nestedItem?.key}_sidemenu`}
                            key={`${nestedItem?.key}_${index}`}
                            className={`rounded-md p-1.5 pl-12 hover:bg-purple-600 hover:text-white ${!sideBarFullView && 'hidden'} ${
                              location.pathname.includes(nestedItem?.path) ? 'bg-brand-500 text-white dark:bg-purple-900' : ''
                            }`}
                            onClick={() => {
                              if (nestedItem?.path) navigate(`${nestedItem?.path}`);
                            }}
                          >
                            {nestedItem?.name}
                          </h5>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="group relative" key={`${item?.key}_${index}`}>
                  <button
                    id={`${item?.key}_sidemenu`}
                    key={`${item?.key}_${index}`}
                    className={`text-md flex w-full items-center gap-2.5 rounded-md p-2 text-column hover:bg-brand-500  hover:text-white dark:text-white  dark:hover:bg-purple-900 ${
                      item.path === location.pathname ? 'bg-brand-500 text-white dark:bg-purple-900' : ''
                    }`}
                    name={`${item?.name}`}
                    onClick={() => {
                      if (item?.path) navigate(`${item?.path}`);
                    }}
                  >
                    {item?.icon}
                    <span className={`text-nowrap text-sm ${!sideBarFullView && 'hidden'}`}>{item?.name}</span>
                  </button>
                  {!sideBarFullView ? (
                    <ArrowedTooltip position="left" className={`left-[3.5rem] hidden group-hover:block`} toolTipId={`row-cdate-tooltip-1`} data={item?.name} contentclass="" />
                  ) : null}
                </div>
              )
            ) : (
              <React.Fragment key={index}></React.Fragment>
            );
          })}

        <button
          onClick={() => {
            dispatch(setSideBarFullView(!sideBarFullView));
            dispatch(setAppSettings({ key: 'side_menu', value: { toggle: !sideBarFullView } }));
          }}
          name="Hide or Show Sidemenu"
          id="toggle-sidemenu"
          className="text-md absolute -right-6 top-1/2 z-50 hidden rounded-md bg-gradient-to-r from-white to-[#E4DEFF] p-2 group-hover/sidebar:block"
        >
          {<MdKeyboardDoubleArrowRight className={`text-blueSecondary transition-all duration-700 ${sideBarFullView ? 'rotate-180' : 'rotate-0'}`} />}
        </button>
      </div>
    </div>
  );
};
export default AsideNav;
