/* eslint-disable react-hooks/exhaustive-deps */
import { AiOutlineStar } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { FaCheck } from 'react-icons/fa';
import { BiCheckDouble } from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from 'store';
import { toast } from 'react-toastify';
import { calculateTimeAgo, toastState } from 'common/utils/utility';
import DeleteConfirmModal from 'components/crm/DeleteConfrimModal';
import { MdOutlineNotificationsActive, MdOutlineStar } from 'react-icons/md';
import { setClearSelectData, setTriggerCount } from 'store/unified-inbox';
import Button from 'common/sparkle-common/Button';
import { BsTools } from 'react-icons/bs';
import { TiCancel } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { AZ_BUCKET } from 'common/constants/constants';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { LiaUndoAltSolid } from 'react-icons/lia';

interface ListRowTypes {
  index: number;
  inboxData?: any;
  fetchTrigger?: () => void;
  count?: number;
  AvatarColor?: string;
}
const NotificationListRow = (props: ListRowTypes) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { index, inboxData, count } = props;
  const [readMessages, setReadMessages] = useState([]);
  const [starredMessages, setStarredMessages] = useState([]);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [rowIndex, setRowIndex] = useState([]);
  const { inbox_status } = useAppSelector((state) => state.unifiedInboxData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inboxData.read_history.is_viewed) {
      setReadMessages((prev) => [...prev, inboxData?.uuid]);
    }
    if (inboxData.is_starred_message) {
      setStarredMessages((prev) => [...prev, inboxData?.uuid]);
    }
  }, [inboxData.uuid]);

  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        // fetchDbEmails(message_id);
        return [...prevArr, index];
      }
    });
  };

  const handleStarredView = () => {
    setStarredMessages((prevArr) => {
      if (prevArr.includes(inboxData?.uuid)) {
        return prevArr.filter((item) => item !== inboxData?.uuid);
      } else {
        return [...prevArr, inboxData?.uuid];
      }
    });
  };

  const handleAccordionClick = (event: React.MouseEvent, index: number) => {
    // Prevent toggling if the click is on the GroupTags component
    const targetElement = event.target as Element;
    if (!targetElement.closest('.delete-and-view-mail')) {
      toggleIndexInArray(index);
    }
  };
  const handleMessageReadStatus = async (data: any) => {
    try {
      dispatch(setTriggerCount((prev: boolean) => !prev));
      setReadMessages((prev) => [...prev, inboxData?.uuid]);
      if (data?.read_history?.is_viewed === false) {
        const payload = {
          uuid: data?.uuid,
          is_viewed: true,
          variant: 'notification',
          notification_id: data?.platform_message_details?.notification_id,
        };
        const response = await BaseApi.patch(`/unified/inbox/`, payload);
        if (response.error === true) {
          toast.error(response.message, toastState.error);
        }
        // else {
        //   props.fetchTrigger();
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvatarClick = (e: any, uuid: string) => {
    e.stopPropagation();
    dispatch(setClearSelectData(uuid));

    setIsChecked(!isChecked);
  };

  const handleDeleteConversation = async () => {
    if (deleteId) {
      try {
        const response = await BaseApi.delete(`/unified/inbox/${deleteId}`);
        if (response.error) {
          toast.error(response.message, toastState.error);
        } else {
          toast.success(response.message, toastState.success);
          props.fetchTrigger();
        }
      } catch (error) {
        setDeleteId(null);
      } finally {
        setDeleteId(null);
        setOpenDeleteConfirmation(false);
      }
    }
  };

  const handleStaredStatus = async (status: boolean, id: string) => {
    handleStarredView();
    dispatch(setTriggerCount((prev: boolean) => !prev));
    try {
      const payload = {
        uuid: id,
        is_starred_message: !status,
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);

      if (response.error) {
        toast.error(response.message, toastState.error);
      }
      // else {
      //   props.fetchTrigger();
      // }
    } catch (error) {
      setDeleteId(null);
    }
  };

  // const handleClearConversation = async (e: React.MouseEvent, uuid?: string) => {
  //   e.stopPropagation();
  //   dispatch(setTriggerCount((prev: boolean) => !prev));
  //   try {
  //     const payload = {
  //       unified_inbox_status: 'cleared',
  //       uuid: [uuid],
  //     };
  //     const response = await BaseApi.patch(`/unified/inbox/`, payload);
  //     if (response.status === 200) {
  //       toast.success(response.message, toastState.success);
  //       props.fetchTrigger();
  //     }
  //   } catch (error) {
  //     console.error('error', error);
  //   }
  // };

  const handleClearConversation = async (e: React.MouseEvent, uuid?: string) => {
    e.stopPropagation();
    dispatch(setTriggerCount((prev: boolean) => !prev));
    try {
      const payload = {
        unified_inbox_status: inbox_status === 'cleared' ? 'pending' : 'cleared',
        uuid: [uuid],
      };
      const response = await BaseApi.patch(`/unified/inbox/`, payload);
      if (response.status === 200) {
        toast.success(response.message, toastState.success);
        props.fetchTrigger();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleFix = (type: string) => {
    if (type === 'email_limit_reached') {
      navigate('/sender-accounts');
    } else if (type === 'email_disconnected') {
      navigate('/sender-accounts');
    } else if (type === 'campaign_pause') {
      navigate('/campaigns');
    }
  };

  return (
    <div className="cursor-pointer dark:!border-none">
      <div
        id={`message_data_${index}`}
        onClick={(e) => {
          handleAccordionClick(e, index);
          handleMessageReadStatus(inboxData);
        }}
        className={`message_inboxdata_${index}  group/list relative flex justify-between  ${index !== 0 ? 'border-t dark:!bg-darkTwo' : 'dark:!bg-darkTwo'}   
         ${
           isChecked
             ? '!bg-purple-100'
             : readMessages?.includes(inboxData?.uuid) && rowIndex.includes(index)
             ? '!bg-[#f9f8ff]'
             : readMessages?.includes(inboxData?.uuid) && 'bg-[#f7f9fb] dark:border-darkBorder dark:!bg-darkTwo'
         } `}
      >
        <div className="flex gap-2 rounded-t-md  px-2 py-3">
          <div className="flex items-center justify-start gap-2 dark:!bg-darkTwo">
            <div className={`mr-1 size-[5px] rounded-full  status-index-${index} ${!readMessages?.includes(inboxData?.uuid) && 'bg-green-500'}`} />
            <div className="relative">
              {/* <Avatar
                className="size-2 h-7 w-7 bg-bgAvatar"
                size={'sm'}
                src="https://sparkle-dev.azurewebsites.net/api/v1/helper/az/bucket/1724654554689/logo.png"
                bg={'#4318FF0D'}
                color={'white'}
              /> */}
              <div>
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-bgAvatar">
                  <img alt="logo" src={AZ_BUCKET?.SPARKLE_LOGO_WHITE} width={16.8} height={20} />
                </div>
                <input type="checkbox" className="-left-5 hidden" checked={isChecked} onChange={(e) => handleAvatarClick(e, inboxData?.uuid)} />
              </div>

              {isChecked && (
                <div className="absolute left-2 top-2 flex h-full w-full items-center justify-center">
                  <FaCheck className="size-3 rounded-full bg-purple-500 p-1 text-white" />
                </div>
              )}
            </div>
            <div className=" !max-w-[200px] text-sm font-semibold ">Sparkle Support</div>
            <div className="ml-4 flex size-6 items-center justify-center rounded-full bg-bgNotification">
              <MdOutlineNotificationsActive className="text-column; text-sm" />
            </div>
          </div>
          <div className="ml-5 flex  items-center justify-start gap-2">
            <div className="w-max-[18rem]"></div>
          </div>

          {!rowIndex.includes(index) ? (
            <div className="ml-2 flex items-center justify-start gap-3 ">
              <p className={`max-w-[21rem] overflow-hidden  truncate text-ellipsis text-sm font-medium notify-message-${index}`}>{inboxData?.platform_message_details?.title}</p>
              <p className="max-w-[21rem] overflow-hidden  truncate text-ellipsis text-xs text-column">{inboxData?.platform_message_details?.message}</p>
            </div>
          ) : null}
        </div>

        {!rowIndex.includes(index) ? (
          <div className=" relative ml-2 flex items-center justify-start gap-4  px-4 text-column">
            <div
              className={`absolute right-28 flex items-center gap-4 ${readMessages?.includes(inboxData?.uuid) && 'bg-[#f7f9fb]'}  ${
                isChecked && '!bg-purple-100'
              } opacity-0 transition-opacity  group-hover/list:opacity-100 `}
            >
              <div
                onClick={(e) => {
                  handleClearConversation(e, inboxData?.uuid);
                }}
                className="group/clear-text"
              >
                <ArrowedTooltip
                  className={`hidden !w-14 group-hover/clear-text:block `}
                  toolTipId={`row-cdate-tooltip-1`}
                  data={inbox_status === 'cleared' ? 'Unclear' : 'Clear'}
                  position="top"
                />
                {inbox_status === 'cleared' ? <LiaUndoAltSolid className="text-sm" /> : <BiCheckDouble />}

                {/* <ArrowedTooltip className={`ml-5 hidden !w-auto px-2 group-hover/clear-text:block `} toolTipId={`row-clear-tooltip`} data={'clear'} position="top" />

                <BiCheckDouble /> */}
              </div>

              <div
                id={`stared_${index}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleStaredStatus(starredMessages.includes(inboxData?.uuid), inboxData?.uuid);
                }}
                className="group/star-text"
              >
                <ArrowedTooltip
                  className={`ml-6 hidden  max-w-20 px-2 group-hover/star-text:block `}
                  toolTipId={`row-star-tooltip`}
                  data={starredMessages.includes(inboxData?.uuid) ? 'Starred' : 'Not starred'}
                  position="top"
                />

                {starredMessages.includes(inboxData?.uuid) ? <MdOutlineStar className={'text-yellow-500'} /> : <AiOutlineStar className={`stared_${index}`} />}
              </div>
            </div>
            <p className="truncate text-xs text-column">
              {inboxData?.platform_message_details?.last_thread_date ? calculateTimeAgo(inboxData?.cdate) : calculateTimeAgo(inboxData?.cdate)}
            </p>
          </div>
        ) : null}
      </div>
      {rowIndex.includes(index) && (
        <>
          <div className={`max-h-[30rem]  ${count < 6 && 'min-h-[30rem]'}  overflow-auto !bg-[#f9f8ff] dark:!bg-darkThree`} id={`${index}_thread_message`}>
            {inboxData?.platform_message_details && (
              <>
                <div className=" !bg-[#f9f8ff] p-4 text-sm dark:!bg-darkThree">
                  <div className="w-full rounded-lg bg-white px-6 py-4 leading-6 text-column dark:!bg-darkTwo dark:text-gray-300 ">
                    <p>Hi {inboxData?.platform_message_details?.user_name},</p>
                    <p>{inboxData?.platform_message_details?.message}</p>
                    <p>Sparkle Support</p>
                    <div className="my-4 flex items-center gap-3">
                      {(inboxData?.platform_message_details?.type === 'email_disconnected' ||
                        inboxData?.platform_message_details?.type === 'email_limit_reached' ||
                        inboxData?.platform_message_details?.type === 'campaign_pause') && (
                        <Button
                          name="Fix"
                          LeftIcon={<BsTools />}
                          customClass="text-white bg-blueSecondary h-8 w-16 dark:border-none  "
                          bg="blueSecondary"
                          onClick={() => handleFix(inboxData?.platform_message_details?.type)}
                        />
                      )}
                      <div
                        onClick={(e) => {
                          handleClearConversation(e, inboxData?.uuid);
                        }}
                      >
                        <Button
                          name="Ignore"
                          LeftIcon={<TiCancel className="h-4 w-4 !text-blueSecondary " />}
                          customClass="border-blueSecondary bg-hover py-2 px-4 h-8 w-28 dark:bg-purple-50"
                          bg="hover"
                          color="blueSecondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <DeleteConfirmModal
        isOpen={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false);
          setDeleteId(null);
        }}
        handleDelete={handleDeleteConversation}
      />
    </div>
  );
};
export default NotificationListRow;
