import BagSvg from '../../assets/svg/bag.svg';

import { FaRegNoteSticky } from 'react-icons/fa6';
import { AiFillMail } from 'react-icons/ai';

import { IoLogoWhatsapp } from 'react-icons/io';
import { TfiLinkedin } from 'react-icons/tfi';
import { useAppDispatch, useAppSelector } from 'store';
import { setcomposEmailPopupOpen } from 'store/crm';

interface PropsDataType {
  handleNavigateToTab: (index: number) => void;
}

const QuickAction = (props: PropsDataType) => {
  const dispatch = useAppDispatch();
  const { handleNavigateToTab } = props;
  const opportunityData = useAppSelector((state) => state?.crm?.opportunityInboxViewData);

  const handleNavigate = (type: string) => {
    if (type === 'notes') {
      handleNavigateToTab(1);
    } else if (type === 'email') {
      dispatch(setcomposEmailPopupOpen(true));
    }
  };
  return (
    <div className="flex w-full flex-col gap-3 border-b pb-3">
      <div className="flex gap-3">
        <img src={BagSvg} alt="bag" loading="lazy" className="size-[1.125rem]" />
        <p className="text-sm font-bold text-heading">Quick Actions</p>
      </div>
      <div className="flex gap-4 text-column">
        {/* <div className="flex size-7 items-center justify-center rounded-full bg-[#f4f1ff] text-lg">
            <BiSolidPhoneCall />
          </div>
        */}

        <div className="flex size-7 cursor-pointer items-center justify-center rounded-full bg-[#f4f1ff] text-lg">
          <FaRegNoteSticky onClick={() => handleNavigate('notes')} />
        </div>

        <div className="flex size-7 items-center justify-center rounded-full bg-[#f4f1ff] text-lg" onClick={() => handleNavigate('email')}>
          <AiFillMail />
        </div>
        {/* <div className="flex size-7 items-center justify-center rounded-full bg-[#f4f1ff] text-lg">
          <BiSolidMessageSquareDetail />
        </div> */}
        {opportunityData?.prospect_enrich_details?.linkedin_url && (
          <div className="flex size-7 items-center justify-center rounded-full bg-[#f4f1ff] text-lg">
            <TfiLinkedin />
          </div>
        )}

        {opportunityData?.prospect_enrich_details?.whatsapp_url && (
          <div className="flex size-7 items-center justify-center rounded-full bg-[#f4f1ff] text-lg">
            <IoLogoWhatsapp />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickAction;
