import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/accordion";
import { Box } from "@chakra-ui/react";

interface CustomAccordionPropsType {
    icon?: React.JSX.Element;
    title?: string;
    children?: React.JSX.Element;
    bg?: string;
}

const CustomAccordion = (props: CustomAccordionPropsType) => {
    const { icon, title, children, bg } = props
    return (
        <Accordion allowMultiple className='w-full'>
            <AccordionItem className={`${bg || 'bg-lightgray'} rounded-md`}>
                <AccordionButton className={`${bg ? bg : 'bg-lightgray hover:!bg-lightgray'} border-x rounded-md`}>
                    <Box as='span' flex='1' textAlign='left'>
                        <div className='flex gap-2 items-center text-[.8125rem] font-bold'>{icon}{title}</div>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4} className={`${bg || 'bg-lightgray'} border-x border-b rounded-b-md`}>
                    {children}
                </AccordionPanel>
            </AccordionItem>

        </Accordion>
    )
}

export default CustomAccordion