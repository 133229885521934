import { BaseApi } from 'common/api/common/base-api';

import SelectEmailAccountLayOut from '.';
import Widget from 'components/widget';
import { FcGoogle } from 'react-icons/fc';
import { SiMicrosoftoffice } from 'react-icons/si';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { BsFiletypeCsv } from 'react-icons/bs';
import AddSMTPSingleAccount from '../add-smtp/features/SingleAccount';
import AddNewSMTP from '../add-smtp';
import { useState } from 'react';

const AddEmailAccounts = () => {
  const [smtp, setSmtp] = useState({ flag: false, type: '' });
  const handleGoogleOAuthSetup = async () => {
    try {
      const response = await BaseApi.get('/sender-accounts/generate/email/auth/url');
      console.log('data', response?.data);
      // window.open(response?.data, '_blank');
      window.location.href = response?.data;

      // window.open(
      //   response?.data,
      //   '_blank',
      //   `width=600,height=600,top=${window.innerHeight / 2 - 800 / 2},left=${
      //     window.innerWidth / 2 - 800 / 2
      //   },toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
      // );
    } catch (error) {
      console.error('error', error);
    }
  };
  return (
    <SelectEmailAccountLayOut>
      <div className="flex items-start justify-center  ">
        <div className="mt-4 w-3/5 rounded-md bg-white dark:!bg-darkTwo shadow-dropShadow">
          <h1 className="px-4 pb-2 pt-4 font-medium text-column dark:text-white">Add a new email account</h1>
          <hr />
          {smtp.flag && smtp.type === '' ? (
            <div className="flex flex-col justify-center gap-5 px-12 py-10">
              <div>
                <Widget
                  onClick={() => {
                    setSmtp((prev) => ({ ...prev, type: 'upload' }));
                    console.log('Drawer rendered in the DOM');
                  }}
                  icon={<BsFiletypeCsv size={35} className="text-column" />}
                  title={''}
                  subtitle={'Bulk import from  CSV'}
                />
              </div>
              <div>
                <Widget
                  icon={<MdOutlineAttachEmail size={35} className="text-column" />}
                  title={''}
                  subtitle={'Single Account'}
                  onClick={() => {
                    setSmtp((prev) => ({ ...prev, type: 'single' }));
                  }}
                />
              </div>
            </div>
          ) : smtp.flag && smtp.type === 'single' ? (
            <div className="px-4">
              <AddSMTPSingleAccount />
            </div>
          ) : smtp.flag && smtp.type === 'upload' ? (
            <div className="px-4 pb-4">
              <AddNewSMTP setSmtp={setSmtp} />
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-5 px-12 py-10">
              <div>
                <Widget
                  onClick={() => {
                    handleGoogleOAuthSetup();
                    console.log('Drawer rendered in the DOM');
                  }}
                  icon={<FcGoogle size={35} />}
                  title={'Google'}
                  subtitle={'Gmail / G-Suite'}
                />
              </div>
              <div>
                <Widget icon={<SiMicrosoftoffice size={35} color="red" />} title={'Microsoft'} subtitle={'Office 365 / Outlook'} comingSoon={true}/>
              </div>
              <div>
                <Widget
                  onClick={() => setSmtp((prev) => ({ ...prev, flag: true }))}
                  icon={<MdOutlineAttachEmail size={35} color="silver" />}
                  title={'Any Provider'}
                  subtitle={'IMAP / SMTP'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </SelectEmailAccountLayOut>
  );
};

export default AddEmailAccounts;
