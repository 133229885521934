import SetUp from "components/actions/SetUp";
import Card from "components/card";
import InputWithBtn from "components/fields/InputWithBtn";

const CustomTracking = () => {
    return (
        <Card extra={"w-full px-8 py-8"}>
            {/* Header */}
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Custom Tracking Domain
                </h4>
                <p className="mt-1 text-base text-gray-600 font-small">
                See up to 20% improvement in deliverability when you add a custom tracking domain.
                </p>
            {/* content */}
            <SetUp 
                name="Step&nbsp;1"
                value="Watch our video tutorial or read the guide first There you’ll find detailed steps to setting up a tracking domain. lf you already have one, go to step 2."
                actionName="Setup"
                py="py-6"
                border="border-b"
            />
            <SetUp 
                name="Step&nbsp;2"
                value="In your domain settings panel, add a new CNAME record snov-stream.com for your tracking
                domain/subdomain."
                actionName="Setup"
                py="py-6"
                border="border-b"
            />
            <SetUp 
                name="Step&nbsp;3"
                value="Paste your new tracking domain/subdomain in the field below and verify that it’s ready."
                actionName="Setup"
                py="py-6"
                border="border-b"
            />
        <InputWithBtn 
        label="Tracking Domain"
        id="domain"
        content="Verify"
        placeholder="http://track.yourdomain.com"/>
        </Card>
    );
};

export default CustomTracking;
