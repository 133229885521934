import SearchBox from 'components/searchBox';
import React from 'react';
// import { BsThreeDotsVertical } from 'react-icons/bs';
import { CiMail } from 'react-icons/ci';
// import { FaUser } from 'react-icons/fa6';
import { Box, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
// import moment from 'moment';
// import InfiniteScrollComponent from 'components/infinityScroll';
import { _errorHandler, toastState, useDebounce } from 'common/utils/utility';
import { useAppDispatch, useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { setPreview } from 'store/campaign-v2';
import { toast } from 'react-toastify';

const BoxSkeleton = () => {
    return (
        <Box m="2" padding="4" boxShadow="lg" bg="white" className="flex items-center gap-3">
            <div className="flex flex-col items-center justify-center gap-2">
                <SkeletonCircle size="10" />
                <SkeletonText noOfLines={1} spacing="2" skeletonHeight="3" className="w-3" />
            </div>
            <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
        </Box>
    );
};

const LeftSideView = () => {
    // const [emailsData] = React.useState({ loading: false, originalData: [], searched_word: '', searched_emails: [], count: 0 })
    const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, search: '' });
    const debouncedSearchValue = useDebounce(queryParams?.search, 300);
    const campaignV2 = useAppSelector((state) => state.campaignv2);
    const selected_index = useAppSelector((state) => state?.campaignv2?.review?.preview?.selected_index)
    const dispatch = useAppDispatch();
    const [messageList, setMessageList] = React.useState([]);

    const cardRefs = React.useRef([]);

    const setCardRef = (element: any, index: number) => {
        cardRefs.current[index] = element;
    };

    React.useEffect(() => {
        if (cardRefs?.current?.[selected_index]) {
            cardRefs?.current?.[selected_index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selected_index]);

    const replacePattern = (inputString: string, missingVariablList: any, receiver_email: string, sequencedata: any) => {
        const pattern = /\{\{(.*?)\}\}/g;
        return inputString.replace(pattern, (match, p1) => {
            const isMissing = missingVariablList.includes(`{{ ${p1.trim()} }}`);
            const className = isMissing ? `text-warnorange missed_variable_class` : `text-red-500 invalid_variable_class`;
            const id = isMissing ? 'missedVariable' : 'invalidVariable';
            const dataSet = isMissing ? receiver_email : `${sequencedata?.sequence_id},${sequencedata?.message_version}`;
            return `<button class='${className}' id='${id}' data-set='${dataSet}' >${isMissing ? "<span class='px-1 rounded-full border border-warnorange text-[.625rem]'>?</span>" : ''
                } {{ ${p1.trim()} }}</button>`;
        });
    };

    React.useEffect(() => {
        const fetchDbEmails = async () => {
            try {
                dispatch(setPreview({ loading: true }));
                const response = await BaseApi.post(`/campaign/v2/preview`, { campaign_id: campaignV2.ids.campaign_id });
                if (response?.error) {
                    toast.error(_errorHandler(response?.error?.message), toastState?.error);
                    dispatch(setPreview({ preview_modal: false }))
                    return
                }
                if (response?.data) {
                    const data = response?.data?.map((item: any) => {
                        const sequence_list = item.sequence_list.map((sequence: any) => {
                            const subject = replacePattern(sequence.subject, item.missing_variable_list, item.receiver_email, sequence)
                            const message = replacePattern(sequence.message, item.missing_variable_list, item.receiver_email, sequence);
                            return { ...sequence, subject, message };
                        });
                        return { ...item, sequence_list };
                    });
                    dispatch(setPreview({ preview_list: data }));
                    setMessageList(response.data);
                }
            } catch (error) {
                console.log('Unmatched email fetching error', error);
            } finally {
                dispatch(setPreview({ loading: false }));
            }
        };

        fetchDbEmails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const lists = campaignV2.review.preview.preview_list;
        let data = [];
        if (queryParams.search) {
            data = lists.filter((item: any) => {
                return item.receiver_email.includes(queryParams.search) || item.receiver_name?.toLowerCase()?.includes(queryParams.search);
            });
        } else {
            data = lists;
        }
        setMessageList(data);
        dispatch(setPreview({ search: queryParams?.search, selected_index: 0 }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    const handleMailSearch = (event: any) => {
        setQueryParams((prev) => ({
            ...prev,
            search: event?.target?.value?.toLowerCase(),
        }));
    };

    // const loadMoreMails = React.useCallback(async (setIsFetching: any) => {
    //     // const fetchEntryList = async () => {
    //     //     try {
    //     //         setIsFetching(true)
    //     //         const query = setAndGetQueryParams([{ key: 'limit', value: queryParams?.limit }, { key: 'offset', value: (queryParams?.offset + 25) }, { key: 'search', value: queryParams?.search }, { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' }, { key: 'filter', value: queryParams?.filter }])
    //     //         const response = await BaseApi.get(`/crm/pipeline/inbox/unmatched/list?${query}`);
    //     //         const responseData: any = response.data
    //     //         if (!responseData?.data) return setEmails(prev => ({ ...prev, originalData: [], count: 0 }))
    //     //         if (!response?.error) {
    //     //             // if (responseData?.data?.length < 25) {
    //     //             //     setEmails((prev) => ({ ...prev, originalData: [...prev?.originalData, ...responseData?.data], count: prev?.originalData?.length, }))
    //     //             // } else {
    //     //             setEmails((prev) => ({ ...prev, originalData: [...prev?.originalData, ...responseData?.data] }))
    //     //             // }
    //     //             setQueryParams((prev) => ({
    //     //                 ...prev,
    //     //                 offset: prev?.offset + 25
    //     //             }))
    //     //         }
    //     //     } catch (error) {
    //     //         console.log(error)
    //     //     } finally {
    //     //         setIsFetching(false)
    //     //     }
    //     // }

    //     // emailsData?.originalData?.length && emailsData?.originalData?.length < emailsData?.count && fetchEntryList();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleClickEmail = (index: number) => {
        dispatch(setPreview({ selected_index: index }));
    };

    return (
        <div className=''>
            {/* Header */}
            <div className='px-1'>
                <div className='px-4 pt-4 pb-2 flex justify-between'>
                    <h4 className='font-bold w-64 truncate'>{campaignV2?.campaign_name}</h4>
                    <div className='flex gap-1 text-gray-800 items-center'><CiMail size={14} /><span className='text-[.625rem]'>{`${messageList.length} Prospect${messageList.length > 1 ? 's' : ''}`}</span></div>
                </div>
                <div className='flex'>
                    <SearchBox placeholder='Search' extraClassName='w-full' onChange={handleMailSearch} />

                </div>
            </div>
            {/* Body */}
            <div>
                {/* Mails */}
                <div className='border-t mt-1 h-[31.25rem] overflow-y-auto'>
                    {
                        !campaignV2.review.preview.loading ? (messageList?.map((profile: any, index: number) => {
                            const firstName = profile?.receiver_email?.split('@')
                            const profileName = profile?.receiver_name || firstName[0]
                            const active = index === campaignV2.review.preview.selected_index
                            return (
                                <div className='border-b w-full' key={index} ref={(element) => setCardRef(element, index)}>
                                    <div
                                        id={`profile_id_${index}`}
                                        onClick={() => handleClickEmail(index)}
                                        className={`group flex rounded-[.3125rem] gap-2 m-2 p-3 cursor-pointer lg:cursor-pointer ${active ? 'bg-hover' : 'hover:bg-hover'}`}
                                    >
                                        {/* Profile picture */}
                                        <div className='flex items-center'>
                                            <div className="flex size-7 items-center justify-center rounded-full border bg-lightsky transition-all duration-300 capitalize text-md text-white">
                                                {profileName[0]}
                                            </div>
                                        </div>

                                        {/* Profile details */}
                                        <div className="w-full">
                                            <h1 id={`preview-user-${profile?.name}`} className={`text-sm w-full px-1 font-medium capitalize text-paused-color dark:text-white`}>
                                                {profileName}
                                            </h1>
                                            <h1 id={`preview-subject-${profile?.receiver_name}`} className={`w-[17.5rem] truncate px-1 text-[0.7rem] text-column duration-500 dark:text-white`}>
                                                {profile?.receiver_email}
                                            </h1>
                                            {profile.missing_variable_list.length ? (
                                                <div className="text-xs text-warnorange">missing {profile.missing_variable_list.join(', ')}</div>
                                            ) : profile.variable_not_created_list.length ? (
                                                <div className="text-xs text-red-500">invalid {profile.variable_not_created_list.join(', ')}</div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        ) : (
                            <>
                                <BoxSkeleton />
                                <BoxSkeleton />
                                <BoxSkeleton />
                                <BoxSkeleton />
                            </>
                        )
                    }
                    {/* <InfiniteScrollComponent loadMore={loadMoreMails} loader={<BoxSkeleton />} /> */}
                </div>
            </div>
        </div>
    );
};

export default LeftSideView;
