import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import PaymentAndBilling from './feature/PaymentAndBilling';
import BillingPlan from './feature/Plan';
import Usage from './feature/usage';
import { useAppSelector } from 'store';

const Billing = () => {
  const [index, setIndex] = React.useState(0);
  const { environment } = useAppSelector((state) => state.globalsettings);

  return (
    <div>
      <Tabs variant="unstyled" zIndex={0} index={index} onChange={setIndex}>
        <TabList className="my-3 rounded-md bg-white shadow-sm">
          <Tab
            className="border-b-[3px] border-y-transparent !px-3 !py-3 !text-[.8125rem] font-bold text-diableGray"
            _selected={{
              color: '#25324B',
              borderBottom: '3px solid #4640DE',
            }}
          >
            Plans
          </Tab>

          <Tab
            className="border-b-[3px] border-y-transparent !px-3 !py-3 !text-[.8125rem] font-bold text-diableGray"
            _selected={{
              color: '#25324B',
              borderBottom: '3px solid #4640DE',
            }}
          >
            Payment & Billing
          </Tab>

          {environment?.app_default_feature?.is_org_enbaled && (
            <Tab
              className="border-b-[3px] border-y-transparent !px-3 !py-3 !text-[.8125rem] font-bold text-diableGray"
              _selected={{
                color: '#25324B',
                borderBottom: '3px solid #4640DE',
              }}
            >
              Usage
            </Tab>
          )}
        </TabList>

        <TabPanels className="!z-0 h-[80vh]">
          <TabPanel className="!max-h-[80vh] !overflow-y-auto !p-0">{index === 0 && <BillingPlan />}</TabPanel>
          <TabPanel className="!max-h-[80vh] !overflow-y-auto !p-0">{index === 1 && <PaymentAndBilling />}</TabPanel>
          {environment?.app_default_feature?.is_org_enbaled && <TabPanel className="!max-h-[80vh] !overflow-y-auto !p-0">{index === 2 && <Usage />}</TabPanel>}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Billing;
