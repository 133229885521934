// import { createSlice } from '@reduxjs/toolkit';

// const rowsettingOptionData:any = []

// const userSettingsSlice = createSlice({
//   name: 'commonrow',
//   initialState:rowsettingOptionData,
//   reducers: {
//     setRowsettingsOptionData: (state, action) => {
//       state.rowsettingOptionData = action.payload;

//   }
// });

// export const {
//     setRowsettingsOptionData,

// } = userSettingsSlice.actions;
// export default userSettingsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
const initialState: any = { rowsettingOptionData: [] };
const userSettingsSlice = createSlice({
  name: 'commonrow',
  initialState,
  reducers: {
    setRowsettingsOptionData: (state, action) => {
      state.rowsettingOptionData = action.payload;
    },
    resetRowsettingsOptionData: (state) => {
      state.rowsettingOptionData = initialState.rowsettingOptionData;
    },
  },
});

export const { setRowsettingsOptionData, resetRowsettingsOptionData } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
