import React from 'react'
import Button from 'common/sparkle-common/Button'

const SectionToggle = () => {
  const [modeOfCommunication, setModeOfCommunication] = React.useState('ALL')
  const selectedClassName = 'text-blueSecondary bg-white drop-shadow-md'

  return (
    <div className='rounded-md border border-[#F2F4F7] bg-grayBorder p-0.5 flex gap-2'>
      <Button onClick={() => setModeOfCommunication('ALL')} name='All' color='column' bg='grayBorder' customClass={`font-semibold border-none hover:text-blueSecondary hover:bg-white transition-all ${(modeOfCommunication === 'ALL') && selectedClassName}`} />
      {/* <Button onClick={() => setModeOfCommunication('SMS')} name='SMS' color='column' bg='grayBorder' customClass={`font-semibold border-none hover:text-blueSecondary hover:bg-white transition-all ${(modeOfCommunication === 'SMS') && selectedClassName}`} /> */}
      {/* <Button onClick={() => setModeOfCommunication('WAP')} name='Whatsapp' color='column' bg='grayBorder' customClass={`font-semibold border-none hover:text-blueSecondary hover:bg-white transition-all ${(modeOfCommunication === 'WAP') && selectedClassName}`} /> */}
      {/* <Button onClick={() => setModeOfCommunication('LIN')} name='LinkedIn' color='column' bg='grayBorder' customClass={`font-semibold border-none hover:text-blueSecondary hover:bg-white transition-all ${(modeOfCommunication === 'LIN') && selectedClassName}`} /> */}
      <Button onClick={() => setModeOfCommunication('MAIL')} name='Email' color='column' bg='grayBorder' customClass={`font-semibold border-none hover:text-blueSecondary hover:bg-white transition-all ${(modeOfCommunication === 'MAIL') && selectedClassName}`} />
    </div>
  )
}

export default SectionToggle