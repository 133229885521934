import { BaseApi } from 'common/api/common/base-api';
import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import 'assets/scss/Editor.scss';
import customUnsubscribePlugin from './plugins/customUnsubscribePlugin';
import plugins from 'suneditor/src/plugins';
interface propsType {
  defaultValue?: string;
  onChange: (event: any) => void;
  onFocus?: (event: any) => void;
  leftContent?: any;
  rightContent?: any;
  editorRef?: any;
  height?: string;
  list?: string[];
  id?: string;
  variablePattern?: boolean;
  variables?: any;
  missedVariables?: any;
  variablesClass?: any
  placeholder ?: string;
}

export function replacePattern(html: string, variables: any, missedVariables: any, variablesClass: any) {
  let replaced = false, result = '';
  const pattern = /\{\{(.*?)\}\}/g
  const newString = html.replace(/<span[^>]*>({{\s*[\w]+\s*}})<\/span>/g, '');
  const checkItsReplaceable = pattern?.test(newString)
  if (checkItsReplaceable) {
    const unwrappedHtml = html.replace(/<span[^>]*>({{\s*[\w]+\s*}})<\/span>/g, '$1');
    console.log('unwrappedHtml', unwrappedHtml)

    result = unwrappedHtml?.replace(pattern, (match, variable) => {
      const className = missedVariables?.includes(match) ? (variablesClass[1] || '') : variables?.includes(match) ? (variablesClass[0] || '') : (variablesClass[2] || '')
      replaced = true;
      console.log('match', match)
      return `<span id="${className}" style="color:inherit;">${match}</span>${variablesClass[0] !== className ? '<span>&nbsp;</span>' : ''}`;
    });
  }

  return { replaced, result };
}

const CustomSunEditor = (props: propsType) => {
  const { defaultValue, onChange, leftContent, editorRef, onFocus, height, list, id, placeholder
    // variablePattern,
    // variables,
    // missedVariables,
    // variablesClass
  } = props;

  const handleImageUpload = async (targetImgElement: any, index: number, state: 'create' | 'update' | 'delete', imageInfo: any, remainingFilesCount: number) => {
    try {
      if (imageInfo) {
        const isBase64 = imageInfo.src.startsWith('data:image/');
        if (isBase64) {
          const base64 = imageInfo.src.split(',')[1];
          const response = await BaseApi.post('/helper/store/az/bucket', { images: base64 });
          if (response?.data) {
            targetImgElement.src = response?.data?.url;
            const content = editorRef.current.getContents();
            handleChangeEditor(content);
          }
        }
      }
    } catch (error) {
      console.error('Image uploading error', error);
    }
  };

  // const handleBlur = () => {
  //   const editor = editorRef?.current;
  //   const content = editor?.core?.getContents()
  //   if (variablePattern) {
  //     const newContent = replacePattern(content, variables, missedVariables, variablesClass)
  //     if (newContent.replaced) {
  //       editor.setContents(newContent.result);
  //       handleChangeEditor(newContent.result);
  //     }
  //   }
  // }

  const replaceImageAlignment = (content: string) => {
    const divTag = document.createElement('div');
    divTag.innerHTML = content;
    const alignmentStyles: any = {
      '__se__float-center': {
        display: 'table',
        margin: '0 auto'
      },
      '__se__float-none': {
        float: 'none',
      },
      '__se__float-left': {
        float: 'left',
        marginRight: '10px'
      },
      '__se__float-right': {
        float: 'right',
        marginLeft: '10px'
      }
    };
    Object.keys(alignmentStyles).forEach(className => {
      const elements = divTag.getElementsByClassName(`se-component se-image-container ${className}`);
      const styles = alignmentStyles[className];
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        element.removeAttribute('style')
        Object.assign(element.style, styles);
      }
    });

    return divTag.innerHTML;
  };
  const handleChangeEditor = (content: string) => {
    const widthReplacedContent = content?.replace(/width:\s*0px;?/gi, '')
    const imageAlaignReplacedContent = replaceImageAlignment(widthReplacedContent)
    onChange(imageAlaignReplacedContent)
  }

  const defaultContent = () => {
    const divTag = document.createElement('div');
    divTag.innerHTML = defaultValue;
    const elements = divTag.getElementsByClassName(`se-component se-image-container`);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      element.removeAttribute('style')
    }
    return divTag.innerHTML
  }

  const handlePaste = (event: ClipboardEvent, cleanData: string) => {
    const divTag = document.createElement('div');
    divTag.innerHTML = cleanData;
    const lastChild = divTag.lastChild
    if (lastChild) {
      if (lastChild.lastChild) {
        if (lastChild.lastChild.nodeName === 'BR') {
          lastChild.lastChild.remove()
        }
      }
    }
    editorRef?.current?.insertHTML(divTag.innerHTML)
    return false
  }


  return (
    <div style={{ position: 'relative' }} id={id}>
      <SunEditor
        getSunEditorInstance={(sunEditor: any) => {
          editorRef.current = sunEditor;
        }}
        placeholder= {placeholder || 'Type your message here...'}
        onFocus={onFocus}
        onChange={handleChangeEditor}
        onPaste={handlePaste}
        // onBlur={handleBlur}
        // onKeyUp={handleBackspace}
        defaultValue={defaultContent()}
        setOptions={{
          mode: 'classic',
          plugins: [...Object.values(plugins), customUnsubscribePlugin],
          buttonList: list ? [list] : [['bold', 'italic', 'underline', 'strike', 'align', 'list', 'lineHeight', 'fontColor', 'hiliteColor', 'link', 'image', 'font', 'fontSize']],
          defaultTag: 'div',
          showPathLabel: false,
          attributesWhitelist: {
            all: '*',
          },
          // icons: {
          //     bold: 'bold'
          // },
          resizingBar: false,
          height: height || '15rem',
          historyStackDelayTime: 0,
          imageHeight: '100px',
          imageWidth: '100px',
          // toolbarContainer:'#toolbar',
          // className:'!rounded-md bg-inherit'
          pasteTagsWhitelist: '*',
          defaultStyle: 'font-family: Arial, Helvetica, sans-serif;line-height: 1.7;',
          // font: [
          //   'Sans Serif',
          //   'Serif',
          //   'Fixed Width',
          //   'Arial Black',
          //   'Narrow',
          //   'Comic Sans MS',
          //   'Garamond',
          //   'Georgia',
          //   'Tahoma',
          //   'Trebuchet MS',
          //   'Verdana',
          // ],
        }}
        onImageUpload={handleImageUpload}
      />

      <div id="toolbar" className="mt-2">
        {leftContent}
      </div>
    </div>
  );
};

export default CustomSunEditor;
