import React from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import moment from 'moment';
import TableLoader from 'components/Loaders/TableViewLoader';
import Button from 'common/sparkle-common/Button';
import { useAppSelector } from 'store';
// import { BaseApi } from 'common/api/common/base-api';
// import { getErrorMessage } from 'store/custom';
import { calculateTimeAgo, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import { MdEdit, MdOutlineWatchLater } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';
import { FaXmark } from 'react-icons/fa6';
import { BaseApi } from 'common/api/common/base-api';
import { TbTrash } from 'react-icons/tb';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { toast } from 'react-toastify';
import ArrowedTooltip from 'components/ArrowedTooltip';
import CreditsAddonsModal from './feature/CreditsAddonsModal';

const CreditAddons = () => {
  const [open, setOpen] = React.useState(false);
  const [reRender, setReRender] = React.useState(false);
  const [rowId, setRowId] = React.useState('');

  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  //   const searchRef = React.useRef(null);
  const columnHelper = createColumnHelper<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({ selectedIds: [], globalOpt: false });

  const handleCreditFeatureEdit = async (rowuuid: string, rowDetails: any) => {
    try {
      setRowId(rowuuid);
      setOpen(true);
    } catch (error) { }
  };

  const handleCreditFeatureDelete = async (rowuuid: string, rowDetails: any) => {
    try {
      const deleteResponse = await BaseApi.delete(`/credit/addons`, {
        data: {
          uuid: [rowuuid],
        },
      });
      if (!deleteResponse?.error) {
        toast.success('Deleted Credit Feature Successfully', toastState.success);
        setReRender(!reRender);
        setOpen(false);
      } else {
        toast.error('Deleted Credit Feature Failed', toastState.error);
      }
    } catch (error) { }
  };

  const creditFeatureOptions = [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: handleCreditFeatureEdit,
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      accessName: 'delete',
      name: 'Delete',
      functioning: handleCreditFeatureDelete,
    },
  ];

  React.useEffect(() => {
    // donot entry fetch function
    const fetchEntryList = async () => {
      setData((prev) => ({ ...prev, loading: true }));

      try {
        // const brandId = sessionStorage.getItem('brandId');
        const query = setAndGetQueryParams([
          { key: 'search', value: queryParams?.search },
          { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
          { key: 'filter', value: queryParams?.filter },
        ]);
        const response = await BaseApi.get(`/credit/addons?${query}`);
        if (response?.error) {
            return
          // dispatch(getErrorMessage(response?.message));
        }
        const responseData: any = response.data;
        if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
        if (responseData?.data?.length < 25) {
          setData((prev) => ({ ...prev, data: [...responseData?.data], count: responseData?.data?.length }));
        } else {
          setData((prev) => ({ ...prev, data: [...responseData?.data], count: responseData?.total_records?.count }));
        }
        setQueryParams((prev) => ({ ...prev, offset: 25 }));
      } catch (error) {
        console.log(error);
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    !open && fetchEntryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagStatus, reRender, queryParams?.filter, open, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  // console.log('tableSelection', tableSelection,data);

  //   React.useEffect(() => {
  //     const dateFormate = (from: number | string, to: number = 1) => {
  //       const fromDate =
  //         typeof from === 'string'
  //           ? moment()
  //               .startOf(from as moment.unitOfTime.StartOf)
  //               .format('YYYY-MM-DD')
  //           : moment().subtract(from, 'days').format('YYYY-MM-DD');
  //       const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
  //       return `${fromDate},${currentDate}`;
  //     };
  //     const data = [
  //       {
  //         id: '1',
  //         name: 'Added By',
  //         uniqueColumnName: 'added_by',
  //         conditions: [
  //           { id: '1', name: 'Is', uniqueName: 'is' },
  //           { id: '2', name: 'Is not', uniqueName: 'not' },
  //         ],
  //         resultValue: [{ id: '1', name: 'Me', uniqueName: 'me' }],
  //       },
  //       {
  //         id: '2',
  //         name: 'Added On',
  //         uniqueColumnName: 'cdate',
  //         conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
  //         resultValue: [
  //           { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
  //           { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
  //           { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
  //           { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
  //           { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
  //           { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
  //           { id: '7', name: 'Date Range' },
  //         ],
  //       },
  //     ];
  //     setOptionData(data);
  //   }, []);

  const columnData = [
    // columnHelper.accessor('id', {
    //   id: 'id',
    //   header: () => (
    //     <div className="ml-3 flex items-center">
    //       <input
    //         type="checkbox"
    //         id="select_all"
    //         checked={table.getIsAllRowsSelected()}
    //         onChange={table.getToggleAllRowsSelectedHandler()}
    //         className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600"
    //       />
    //     </div>
    //   ),
    //   cell: (info) => {
    //     return (
    //       <div className="ml-3 flex items-center" onClick={(event) => event?.stopPropagation()}>
    //         <input
    //           type="checkbox"
    //           id={`row-${info?.row.id + 1}`}
    //           checked={info?.row.getIsSelected()}
    //           // disabled={!info?.row.getCanSelect()}
    //           onChange={info?.row.getToggleSelectedHandler()}
    //           className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600"
    //         />
    //       </div>
    //     );
    //   },
    // }),
    columnHelper.accessor('data', {
      id: 'invoice',
      enableSorting: false,
      header: () => <div className='ml-3'>Addon Type</div>,
      cell: (info) => {
        return <h5 className="w-60 ml-3 truncate !text-xs font-semibold text-gray-900 dark:text-gray-300">{info?.row?.original?.addon_type === 'default' ? "Default" :'Custom'}</h5>;
      },
    }),
    // columnHelper.accessor('added_by', {
    //   id: 'added_by',
    //   enableSorting: false,
    //   header: () => <div>Feature type</div>,
    //   cell: (info) => <h5 className="text-xs dark:text-gray-300">{info?.row?.original?.feature_type}</h5>,
    // }),
    columnHelper.accessor('added_by', {
      id: 'added_by',
      enableSorting: false,
      header: () => <div>Credits </div>,
      cell: (info) => <h5 className="text-xs dark:text-gray-300">{info?.row?.original?.credits}</h5>,
    }),
    columnHelper.accessor('price', {
        id: 'price',
        enableSorting: false,
        header: () => <div>Price </div>,
        cell: (info) => <h5 className="text-xs dark:text-gray-300">{info?.row?.original?.amount}</h5>,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        enableSorting: false,
        header: () => <div>Discount </div>,
        cell: (info) => <h5 className="text-xs dark:text-gray-300">{info?.row?.original?.discount_percentage}</h5>,
      }),

    // columnHelper.accessor('cdate', {
    //   id: 'cdate',
    //   header: () => <div className="text-start dark:text-gray-300">Date</div>,
    //   cell: (info) => {
    //     const utcDateString = info?.row?.original?.cdate;
    //     // const utcDateString = info.getValue();

    //     // const userTimeZone = 'Asia/Kolkata';
    //     // const utcDate = new Date(utcDateString);
    //     // const localDate = utcDate.toLocaleString('en-US', {
    //     //   timeZone: userTimeZone,
    //     // });
    //     const date = moment(utcDateString).format('MMM DD, YYYY');
    //     return <h5 className="text-xs">{date}</h5>;
    //   },
    // }),
    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: (row) => {
        return (
          <div className="column-cdate group flex size-full select-none items-center text-nowrap font-medium">
            <h5 className="relative">
              Created On

            </h5>
          </div>
        );
      },
      cell: (row) => {
        const utcDateString = row.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', { timeZone: userTimeZone });
        const date = moment(new Date(localDate));

        return (
          <div className="group relative w-full text-sm font-semibold">
            <span className={`w-28 row-cdate-${row?.row?.id}`}>{calculateTimeAgo(row?.row?.original?.cdate)}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${row?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),
    columnHelper.accessor('blacklist_status', {
      id: 'blacklist_status',
      enableSorting: false,
      header: () => <div className="relative text-start dark:text-gray-300">Status</div>,
      cell: (info) => {
        return (
          <div className="mr-3">
            {info?.row?.original?.status === 0 && (
              <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-0.5">
                <MdOutlineWatchLater className="text-xs text-draft-color" />
                <h5 className={`text-xs font-semibold text-draft-color `}>Pending</h5>
              </div>
            )}
            {info?.row?.original?.status === 1 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-0.5 text-[.7rem] font-semibold text-[#029A48]">
                <GiCheckMark color="#12B76A" />
                <span>Active</span>
              </div>
            )}
            {info?.row?.original?.status === 2 && (
              <div className="bg-red-4x00 flex w-min items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-0.5 text-[.65rem] font-semibold text-red-600">
                <FaXmark />
                <span>Inactive</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative -ml-2 w-min">Action</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={creditFeatureOptions} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  //   const handleFilter = async (filteredData: any) => {
  //     const encryptedData = jwtEncrypt(filteredData);
  //     setQueryParams((prev) => {
  //       if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
  //       return { ...prev, filter: encryptedData };
  //     });
  //   };

  //   const handleListSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setQueryParams((prev) => ({
  //       ...prev,
  //       search: event.target.value.trim().toLowerCase(),
  //     }));
  //   };

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1},
            { key: 'search', value: queryParams?.search },
            // { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/credit/feature?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list], count: prev?.data?.length }));
          } else {
            setData((prev) => ({ ...prev, data: [...prev?.data, ...responseData?.data_list] }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  //   const handleDelete = async () => {
  //     const uuids = tableSelection?.selectedIds?.map((item) => item?.uuid);
  //     // const brandId = sessionStorage.getItem('brandId');
  //     // try {
  //     //   const params: any = {
  //     //     uuid: brandId,
  //     //     data_variant_id: uuids,
  //     //   };
  //     //   const response = await BaseApi.delete('/donot/list/delete/variant/list', { data: params });
  //     //   if (response?.error) {
  //     //     dispatch(getErrorMessage(response?.message));
  //     //   } else {
  //     //     dispatch(setFlagStatus(!flagStatus));
  //     //   }
  //     // } catch (error) {
  //     //   console.log('Donot email list single delete error', error);
  //     // } finally {
  //     //   table.resetRowSelection();
  //     // }
  //   };

  // const handleExportCSV = () => {
  //   const exportData = tableSelection?.selectedIds.map((item) => ({
  //     Invoice: item?.invoice,
  //     'Sparkles Purchased': item?.sparklesPurchased,
  //     Amount: item?.amount,

  //     'Added On': `${moment(item?.cdate).format('MMM DD YYYY')}`,
  //     Status: item?.status === 0 ? 'Pending' : item?.status === 1 ? 'Paid' : 'Failed',
  //   }));
  //   CSVFileDownloader(exportData, 'donot_entries_list.csv');
  //   table.resetRowSelection();
  // };
  return (
    <div className="h-[87vh] overflow-y-auto">
      {/* Table */}
      <div className={` mt-2.5 h-[87vh] rounded-lg bg-white  pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-6 mt-1 flex items-center px-9 py-0.5 sm:gap-32 lg:gap-32 xl:justify-between">
          <div className="-pl-2 flex gap-2.5">
            <span className="font-bold">Credits Addons</span>
            {/* <div
              className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        md:w-[13rem] lg:w-[26rem] dark:border-gray-800"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                onChange={handleListSearch}
                type="text"
                placeholder="Search"
                id="donot-search-input"
                className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:placeholder:text-white "
              />
            </div>
            {<CustomFilter optionData={optionData} handleFilter={handleFilter} />} */}
          </div>
          <div className="flex items-center gap-2">
            {/* {(donotemailStatus?.is_owner || donotemailStatus?.delete)&&  */}
            {/* <Button
              disable={tableSelection?.selectedIds?.length <= 0}
              LeftIcon={<MdDeleteOutline className="float-right text-lg" />}
              name="Delete"
              customClass={`${tableSelection?.selectedIds?.length > 0 ? 'bg-white font-semibold !text-gray-800' : ''} delete_btn`}
              onClick={handleDelete}
            /> */}
            {/* } */}
            {/* <div className="relative w-32">
              <div
                className={`border-2 ${
                  showCalendar ? 'border-menuBorder' : 'border-gray-100'
                } flex h-8 cursor-pointer items-center  justify-around gap-1 rounded-md bg-white px-3 py-3.5 shadow-bottom hover:border-menuBorder`}
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <MdOutlineCalendarToday className="size-4 text-[#667085]" />
                <h5 className="text-[0.7rem] font-medium text-[#667085]">Select dates</h5>
              </div>
              {showCalendar && <DateRangeSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} setShowCalendar={setShowCalendar} right="right-0" top="top-9" />}
            </div> */}
            {/* Export button */}
            <Button name="Add Addons" customClass={``} onClick={() => { setRowId('');; setOpen(true) }} />
          </div>
        </div>
        {!data.loading ? (
          <div className="min-h-12 overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full ">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const RowDataWidth = ['w-[3%]', 'w-[32%]', 'w-[20%]', 'w-[15%]', 'w-[10%]', 'w-[10%]', 'w-[10%]'];
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${RowDataWidth[index]} cursor-default  select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[.625rem] font-semibold ">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                        {row.getVisibleCells().map((cell, i, arr) => {
                          const RowDataWidth = ['w-[3%]', 'w-[32%]', 'w-[20%]', 'w-[15%]', 'w-[10%]', 'w-[10%]', 'w-[10%]'];
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </div>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={6}
            row={3}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 25% 22% 22% 25% 4%"
          />
        )}
      </div>
      {open && (
        <CreditsAddonsModal
          editId={rowId}
          isOpen={open}
          onClose={() => {
            setRowId('');
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default CreditAddons;
