import { SelectAllType } from 'common/dto/types/sparkle-common'
import React from 'react'

const SelectAllContent = (props: SelectAllType) => {
    const { currentCount, totalCount, recordName, featureName, onClick } = props
    return (
        <div className='border py-1 flex justify-center flex-wrap text-xs select-none bg-graybg'>
            <span>All {currentCount} {recordName} on this page are selected.</span>
            <span className='cursor-pointer text-blue-500 hover:bg-blue-200 hover:text-blue-600 py-1 px-2 rounded-full' onClick={onClick}>Select all the {totalCount} {recordName} in {featureName}</span>
        </div>
    )
}

export default SelectAllContent