import React from "react"

import { useAppDispatch, useAppSelector } from "store"
import ScheduleSettings from "./features/schedule-settings"
import FinalSettings from "./features/final-settings"
import { setCurrentStepCount } from "store/custom"
import { setEdit } from "store/campaign-v2"


const CampaignSettings = () => {
  const custom = useAppSelector(state => state?.custom)
  const edit = useAppSelector((state) => state?.campaignv2.edit)
  // const ids = useAppSelector((state) => state?.campaignv2.ids)
  const scheduleSettings = useAppSelector((state) => state?.campaignv2.scheduleSettings)
  // const settings = useAppSelector((state) => state?.campaignv2.settings)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (edit) {
      dispatch(setEdit(false))
    } else {
      // const isUserSelectedSenderList = Object.values(settings?.selectedSenderList).some((sender) => sender)
      // const isUserSelectedGroupList = Object.values(settings?.selectedGroupList).some((group) => group)
      // if ((isUserSelectedSenderList || isUserSelectedGroupList) && ids?.settings_id) {
      if (scheduleSettings?.schedule_id) {
        dispatch(setCurrentStepCount(2))
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {/* {custom?.current_step_count === 1 && <StepSettings />} */}
      {custom?.current_step_count === 1 && <ScheduleSettings />}
      {custom?.current_step_count === 2 && <FinalSettings />}
    </>
  )
}

export default CampaignSettings