/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SkeletonText } from '@chakra-ui/react';
import { CustomMenuType } from 'common/dto/types/components/customMenu';

const InboxSkeleton = (props: CustomMenuType) => {
  return (
    <>
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonText mt="4" noOfLines={20} spacing="4" skeletonHeight="2" />
      </Box>
    </>
  );
};

export default InboxSkeleton;
