import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import { AddNewSenderSMTPData } from 'common/dto/initial-value/views/sender-accounts';
import { AddNewSenderSMTPDTO } from 'common/dto/types/views/sender-accounts';
import { _isObject, _isStr, toastState } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import React from 'react';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import { setSmtpDrawerFlagStatus } from 'store/email-accounts';

const AddSMTPSingleAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { errorMessage }: any = useAppSelector((state) => state.custom);
  const [formData, setFormData] = useState<AddNewSenderSMTPDTO>(AddNewSenderSMTPData);
  const handleFormChange = (e: { target: { name: any; value: any } }): void => {
    const { name, value } = e.target;
    if (name === 'smtp_username') {
      setFormData((prev) => ({ ...prev, [name]: value, email: value }));
    } else if (name === 'daily_limit') {
      setFormData((prev) => ({ ...prev, campaign_settings: { ...prev.campaign_settings, [name]: value } }));
    } else if (name === 'delay_between_emails') {
      setFormData((prev) => ({ ...prev, campaign_settings: { ...prev.campaign_settings, [name]: value } }));
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const { key } = e;
    if (key === '-' || key === 'e' || key === '+' || key === '.') {
      e.preventDefault();
    }
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await BaseApi.post('/sender-accounts/smtp/create', formData);
      if (data?.error) {
        _isObject(data?.message) && dispatch(getErrorMessage(data?.message));
        _isStr(data?.message) && toast.error('Invalid SMTP and IMAP credential', toastState.error);
      } else {
        navigate('/sender-accounts');
        dispatch(setSmtpDrawerFlagStatus(false));
        dispatch(setFlagStatus(false));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div

      // onClose={() => {
      //   dispatch(setSmtpDrawerFlagStatus(false));
      // }}
      // isOpen={state?.smtpDrawerFlag}
      // size={'lg'}
      >
        <div>
          {/* <div className="text-brand-900">Single Account</div> */}
          <div>
            <Stack spacing="24px">
              <Box className="mt-3 flex flex-col">
                <form onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="mb-2 mt-7 grid w-full grid-cols-2 gap-3">
                    <InputField
                      label="First Name"
                      placeholder="First Name"
                      id="first_name"
                      name="first_name"
                      type="text"
                      onChange={handleFormChange}
                      value={formData?.first_name}
                      errorMessage={errorMessage?.first_name}
                      inputClass="smtp_first_name"
                      state={errorMessage?.first_name ? 'error' : ''}
                    />
                    <InputField
                      label="Last Name"
                      placeholder="Last Name"
                      id="last_name"
                      type="text"
                      name="last_name"
                      onChange={handleFormChange}
                      value={formData?.last_name}
                      errorMessage={errorMessage?.last_name}
                      inputClass="smtp_last_name"
                      state={errorMessage?.last_name ? 'error' : ''}
                    />
                    <InputField
                      label="SMTP Username"
                      placeholder="Username or Email"
                      id="smtp_username"
                      type="text"
                      name="smtp_username"
                      onChange={handleFormChange}
                      value={formData?.smtp_username}
                      errorMessage={errorMessage?.smtp_username}
                      inputClass="smtp_user_name"
                      state={errorMessage?.smtp_username ? 'error' : ''}
                    />
                    <InputField
                      label="SMTP Password"
                      placeholder="Password"
                      id="smtp_password"
                      type="password"
                      name="smtp_password"
                      onChange={handleFormChange}
                      value={formData?.smtp_password}
                      errorMessage={errorMessage?.smtp_password}
                      inputClass="smtp_password"
                      state={errorMessage?.smtp_password ? 'error' : ''}
                    />
                    <InputField
                      label="SMTP Host"
                      placeholder="SMTP Host"
                      id="smtp_host"
                      type="text"
                      name="smtp_host"
                      onChange={handleFormChange}
                      value={formData?.smtp_host}
                      errorMessage={errorMessage?.smtp_host}
                      inputClass="smtp_host"
                      state={errorMessage?.smtp_host ? 'error' : ''}
                    />
                    <div className="flex items-center gap-2">
                      <InputField
                        label="SMTP Port"
                        placeholder="Port"
                        id="smtp_port"
                        type="number"
                        extra={`[&>input]:w-24`}
                        name="smtp_port"
                        onChange={handleFormChange}
                        value={formData?.smtp_port}
                        errorMessage={errorMessage?.smtp_port}
                        inputClass="smtp_port"
                        onKeyDown={handleKeyDown}
                        min={0}
                        state={errorMessage?.smtp_port ? 'error' : ''}
                      />
                      <RadioGroup
                        onChange={(value) => {
                          setFormData((prev) => ({ ...prev, smtp_secure: value }));
                        }}
                      >
                        <Stack direction="row" className="mt-7 p-2">
                          <Radio className="radio_smtp_ssl" value="ssl" size={'md'}>
                            SSL
                          </Radio>
                          <Radio className="radio_smtp_tls" value="tls" size={'md'}>
                            TLS
                          </Radio>
                          <Radio className="radio_smtp_none" value="none" size={'md'}>
                            None
                          </Radio>
                        </Stack>
                        {errorMessage?.smtp_secure && (
                          <span className="ml-1 mt-1 flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.smtp_secure}</span>
                        )}
                      </RadioGroup>
                    </div>
                    <InputField
                      label="Message per day"
                      placeholder="0"
                      id="daily_limit"
                      type="number"
                      extra="[&>label]:text-xs"
                      name="daily_limit"
                      onChange={handleFormChange}
                      onKeyDown={handleKeyDown}
                      min={0}
                      value={formData?.campaign_settings?.daily_limit}
                      errorMessage={errorMessage?.campaign_settings?.daily_limit}
                      inputClass="smtp_daily_limit"
                      state={errorMessage?.campaign_settings?.daily_limit ? 'error' : ''}
                    />
                    <InputField
                      label="Minimum time gap"
                      placeholder="0"
                      id="delay_between_emails"
                      type="number"
                      extra="[&>label]:text-xs"
                      name="delay_between_emails"
                      onChange={handleFormChange}
                      onKeyDown={handleKeyDown}
                      min={0}
                      value={formData?.campaign_settings?.delay_between_emails}
                      errorMessage={errorMessage?.fixed_delay}
                      inputClass="smtp_min_time_gap"
                      state={errorMessage?.fixed_delay ? 'error' : ''}
                    />
                  </div>
                  {/* <div className="ml-2 mt-3 flex gap-1 text-sm font-bold">
                    <input id="replycheckbox" type="checkbox" className="smtp_use_different_chackbox h-4 w-4" />
                    <label htmlFor="replycheckbox" className="cursor-pointer">
                      Set a different reply to address
                    </label>
                  </div> */}
                  {/* <InputField
                    label=""
                    placeholder=""
                    id="set_different_reply_to_address"
                    type="text"
                    extra="[&>label]:hidden"
                    name="set_different_reply_to_address"
                    inputClass="smtp_set_different_reply_to_address"
                    onChange={handleFormChange}
                    value={formData?.set_different_reply_to_address}
                    errorMessage={errorMessage?.set_different_reply_to_address}
                    state={errorMessage?.set_different_reply_to_address ? 'error' : ''}
                  /> */}
                  <div className="mb-6 mt-8 w-full px-[8px]">
                    <h4 className="text-md font-bold text-navy-700 dark:text-white">IMAP Settings (receives emails)</h4>
                  </div>
                  {/* <div className="ml-2 mt-2 flex gap-1 text-sm font-bold"> */}
                  {/* <Checkbox id="differentmailcheckbox" /> */}
                  {/* <input id="differentmailcheckbox" type="checkbox" className="h-4 w-4" />
                    <label htmlFor="differentmailcheckbox" className="cursor-pointer">
                      Use different email accounts for receiving emails
                    </label> */}
                  {/* </div> */}
                  <div className="mb-2 mt-6 grid w-full grid-cols-2 gap-3">
                    <InputField
                      label="IMAP User Name"
                      placeholder="IMAP User Name"
                      id="imap_username"
                      type="text"
                      name="imap_username"
                      onChange={handleFormChange}
                      value={formData?.imap_username}
                      errorMessage={errorMessage?.imap_username}
                      inputClass="imap_username"
                      state={errorMessage?.imap_username ? 'error' : ''}
                    />
                    <InputField
                      label="IMAP Password"
                      placeholder="IMAP Password"
                      id="imap_password"
                      type="password"
                      name="imap_password"
                      onChange={handleFormChange}
                      value={formData?.imap_password}
                      inputClass="imap_password"
                      errorMessage={errorMessage?.imap_password}
                      state={errorMessage?.imap_password ? 'error' : ''}
                    />
                    <InputField
                      label="IMAP Host"
                      placeholder="IMAP Host"
                      id="imap_host"
                      type="text"
                      name="imap_host"
                      onChange={handleFormChange}
                      value={formData?.imap_host}
                      inputClass="imap_host"
                      errorMessage={errorMessage?.imap_host}
                      state={errorMessage?.imap_host ? 'error' : ''}
                    />
                    <div className="flex items-center gap-2">
                      <InputField
                        label="IMAP Port"
                        placeholder="IMAP Port"
                        id="imap_port"
                        type="number"
                        extra={`[&>input]:w-24`}
                        name="imap_port"
                        onKeyDown={handleKeyDown}
                        min={0}
                        onChange={handleFormChange}
                        value={formData?.imap_port}
                        inputClass="imap_port"
                        errorMessage={errorMessage?.imap_port}
                        state={errorMessage?.imap_port ? 'error' : ''}
                      />
                      <RadioGroup
                        onChange={(value) => {
                          setFormData((prev) => ({ ...prev, imap_secure: value }));
                        }}
                      >
                        <Stack direction="row" className="mt-7 p-2">
                          <Radio className="radio_imap_ssl" value="ssl" size={'md'}>
                            SSL
                          </Radio>
                          <Radio className="radio_imap_tls" value="tls" size={'md'}>
                            TLS
                          </Radio>
                          <Radio className="radio_imap_none" value="none" size={'md'}>
                            None
                          </Radio>
                        </Stack>
                        {errorMessage?.imap_secure && (
                          <span className="ml-1 mt-1 flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.imap_secure}</span>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="m-2 mt-8 flex justify-end">
                    <button
                      disabled={loading}
                      type="submit"
                      className={`smtp_submit_btn rounded-lg px-12 py-2 text-sm text-white duration-300 active:bg-brand-600 ${loading ? `!bg-gray-400` : `bg-brand-500`}`}
                    >
                      {loading ? <div className="h-5 w-5 animate-spin rounded-full border-2  border-r-grayish-blue border-t-grayish-blue"></div> : 'Finish'}
                    </button>
                  </div>
                </form>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddSMTPSingleAccount;
