import Button from 'common/sparkle-common/Button'
import React from 'react'
import { searchProspectsToolLists } from 'common/dto/initial-value/views/campaign-v2'
import { useAppDispatch, useAppSelector } from 'store'
import { setSearchProspectsTool } from 'store/campaign-v2'
import ApiKeyModal from './features/ApiKeyModal'
import { useDisclosure } from '@chakra-ui/hooks'
import { setCurrentStepCount } from 'store/custom'
import { toast } from 'react-toastify'
import { toastState } from 'common/utils/utility'
import CustomBadge from 'components/badges/CustomBadge'

const ProspectDbSelectingModal = () => {
  const campaignV2 = useAppSelector((state) => state?.campaignv2)
  const dispatch = useAppDispatch()
  const { tool_details } = campaignV2.prospects.search_prospects

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleBack = () => { dispatch(setCurrentStepCount(1)) };

  const handleContinue = () => {
    const db_tools = ['db_companies', 'db_people', 'db_jobs']
    if (!db_tools.includes(tool_details?.name)) {
      onOpen()
    } else {
      toast.success('feature comming soon', toastState?.success)
      // dispatch(setCurrentStepCount(3))
    }
  };

  const handleSelect = (name: string, title: string) => {
    dispatch(setSearchProspectsTool({ name, title }))
  }



  return (
    <div className='flex justify-center items-center h-[80vh]'>
      <div className='bg-white shadow-md p-5 rounded-md w-[670px]'>
        <div className='flex flex-col'>
          <h4 className='text-heading font-bold text-[0.9rem]'>Search prospects</h4>
          <span className='text-[11px] text-column'>Select the database you want to search from</span>
        </div>

        {/* Lists */}
        <div className='flex flex-wrap gap-3 my-6'>
          {
            searchProspectsToolLists?.map((item) => (
              <div key={item?.name} className={`w-[12.5rem] border px-4 py-3 rounded-md ${(!item?.comingSoon) ? 'border-blueSecondary bg-bgInside cursor-pointer' : 'cursor-default'}`} onClick={() => { if (!item?.comingSoon) { handleSelect(item?.name, item?.title) } }}>
                <div className='flex items-center text-heading'>
                  {item?.icon || <img src={item.image} alt='nothing' className='size-5 rounded-sm' />}
                  <h4 className={` font-bold text-sm`}>{item?.title}</h4>
                </div>
                <span className={`text-grayish-blue text-xs font-medium text-center w-10/12 mt-2`}>{item?.subName}</span>
                {item?.comingSoon ? <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 !font-semibold text-[.625rem] !px-2 !py-0.5' /> : <></>}
              </div>
            ))}
        </div>
        <div className='flex gap-4 mt-2'>
          <Button name='Back' customClass='w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]' onClick={handleBack} />
          <Button id='prospect_upload_continue_btn' name='Continue' customClass='w-full text-[.82rem]' onClick={handleContinue} />
        </div>
      </div>
      <ApiKeyModal isOpen={isOpen} onClose={onClose} />
    </div>
  )
}

export default ProspectDbSelectingModal