import React from 'react';
import { IoDocumentAttach } from 'react-icons/io5';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';

interface PropsType {
  getChat?: () => void;
}
const FileAttach = (props: PropsType) => {
  const { getChat } = props;
  const fileMapUpload = new Map();
  const state = useAppSelector((state) => state?.trueVerifier);
  const [loading, setLoading] = React.useState(false);
  const sendMessage = async (emails: any) => {
    try {
      const params = {
        ticket_uuid: state?.ticketId,
        message: 'hi',
        ticket_type: 'file',
        ticket_emails: emails,
      };
      await BaseApi.post('/true/verify/internal/user/chat/message', params);
    } catch (err) {
      console.log('Error occured while sending message', err);
    }
  };

  // useDropzone hook to handle drag-and-drop
  const onDrop = React.useCallback(async (acceptedFiles: any[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      fileMapUpload.set(reader, file);
      reader.onload = async (e: any) => {
        const fileAttr = fileMapUpload.get(e.target);
        if (!['csv', 'xlsx', 'xls'].includes(fileAttr.name.split('.').pop())) {
          toast.error('This file format is not accepted', toastState.error);
          return;
        }
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        jsonData = jsonData.filter((val: any) => val?.length !== 0);

        const convertCsvToObjects = (csv: any[]) => {
          let [headers , ...rows]: string[][] = csv;
          headers = headers.map((key: string) => {
            const lowerCaseKey = key.toLowerCase()
            if(lowerCaseKey === 'name' || lowerCaseKey === 'first_name' || lowerCaseKey === 'last_name' || lowerCaseKey === 'email' || lowerCaseKey === 'signature'){
              return lowerCaseKey
            }else{
              return key
            }
          })
          return rows.map(row => {
              let obj: {[key: string]: string} = {};
              headers?.forEach((header: string, index: number) => {
                if(header?.toLowerCase() !== 'verify_mail_status'){
                  obj[header] = row[index];
                }
              });
              return obj;
          });
        }
        const flattenedData = convertCsvToObjects(jsonData)
        try {
          setLoading(true);
          await sendMessage(flattenedData);
          getChat();
        } catch (error) {
          console.log('True verifier file attach error', error);
        } finally {
          setLoading(false);
        }
        // setUploadList && setUploadList(flattenedData)
        // onUpload && onUpload(flattenedData)
      };
      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <button
      disabled={loading}
      {...getRootProps()}
      className="mr-1 flex items-center justify-center rounded-md px-1 transition-all duration-300"
    >
      <input
        {...getInputProps()}
        id="file-input"
        className="hidden"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <IoDocumentAttach
        className={`text-xl ${
          state?.ticketDetails?.status === 3
            ? 'text-brand-50'
            : 'text-brand-500'
        } hover:text-brand-600 ${loading ? 'animate-pulse' : ''}`}
      />
    </button>
  );
};

export default FileAttach;
