import React from 'react'

const InboxGraph = () => {
    return (
        <div className='h-[22.3rem] w-[28.3rem] border border-[#EAECF0] rounded-md px-4 py-2'>
            <div>
                <h5 className='text-[1rem] text-heading font-medium'>Inbox</h5>
                <h5 className='text-[0.7rem] -mt-0.5 text-[#8F9BBA] font-medium'>check your recent emails/messages here</h5>
            </div>
        </div>
    )
}

export default InboxGraph