import { BsPlusLg } from 'react-icons/bs'
import { HiMinus } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

import { useAppSelector } from 'store'
import { handleDelayCount } from 'store/campaign-v2'

type DelayCounterProps = {
    id: string;
    trigger?: boolean;
    value: number;
    menuRef?: React.MutableRefObject<HTMLDivElement>;
}
const DelayCounter = (props: DelayCounterProps) => {
    const { id, trigger, value, menuRef } = props
    const sequence = useAppSelector(state => state?.campaignv2.sequence)
    const dispatch = useDispatch()
    const targetId = sequence?.findIndex(item => item.id === id)
    return (
        <div className='z-10 absolute flex gap-1.5 p-2.5 w-32 flex-col items-center justify-center top-6 right-8 rounded-md border bg-white border-blueSecondary' ref={menuRef}>
            <h5 className='text-heading text-[0.7rem] font-semibold'>Business days</h5>
            <div className='flex justify-center items-center'>
                <button id={`sub_delay_${targetId}`} className='border size-6 w-9 flex items-center rounded-l-md justify-center hover:shadow-shade' onClick={() => dispatch(handleDelayCount({ id: targetId, operation: '-', trigger }))}><HiMinus className='size-2.5 text-[#2B3674]' /></button>
                <div className='border h-6 w-8 px-2 flex'><input id={`value_delay_${targetId}`} type="number" min={0} value={value} onChange={(e) => dispatch(handleDelayCount({ id: targetId, operation: "=", trigger, value: e.target.value }))} className='w-full appearance-none outline-none text-xs font-medium text-[#2B3674]' placeholder='0' /></div>
                <button className='border size-6 w-9 flex items-center justify-center rounded-r-md hover:shadow-shade' id={`add_delay_${targetId}`} onClick={() => dispatch(handleDelayCount({ id: targetId, operation: '+', trigger }))} ><BsPlusLg className='size-2.5 text-[#2B3674]' /></button>
            </div>
            {/* <div className='flex items-center justify-center gap-0.5 mt-1'>
                <button className='bg-brand-500 border border-[#D0D5DD] rounded-sm px-3 py-0.5 text-[0.6rem] font-medium text-white'>Save</button>
                <button className='bg-white border border-[#D0D5DD] rounded-sm px-3 py-0.5 text-[0.6rem] font-medium text-[#344054]'>Delete</button>
            </div> */}
        </div>
    )
}

export default DelayCounter