import { FcGoogle } from 'react-icons/fc';
import { AiFillMail } from 'react-icons/ai';
import Button from 'common/sparkle-common/Button';
import Outlook from '../../assets/svg/outlook.svg';
import ConnectEmailPopup from 'views/crm-onboarding/features/ConnectEmailPopup';
import React from 'react';
import { BaseApi } from 'common/api/common/base-api';
const SelectConnectionAccount = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSmtpImap = () => {
    setIsOpen(true);
  };

  const handleGoogleConnect = async () => {
    try {
      const response = await BaseApi.get('crm/pipeline/sales/auth/url');
      window.open(response?.data, '_blank');
    } catch (error) {
      console.error('Error from redirection');
    }
  };
  return (
    <>
      <div className="flex h-[19rem] w-full items-center justify-around">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="flex size-16 items-center justify-center rounded-full border border-[#E7E5E5]  text-5xl">
            <FcGoogle />
          </div>
          <p className="text-base font-bold text-heading">Google</p>
          <Button
            name="Connect account"
            color="blueSecondary"
            px="px-3"
            onClick={() => handleGoogleConnect()}
            customClass="!bg-bgUpload  border-blueSecondary !font-bold"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="flex size-16 items-center justify-center rounded-full border border-[#E7E5E5]  text-5xl">
            <img src={Outlook} alt="outlook-icon" className="w-10" />
          </div>
          <p className="text-base font-bold text-heading">Outlook</p>
          <Button
            name="Connect account"
            color="blueSecondary"
            px="px-3"
            customClass="!bg-bgUpload  border-blueSecondary !font-bold"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="flex size-16 items-center justify-center rounded-full border border-[#E7E5E5] text-[2.5rem]  text-blueSecondary">
            <AiFillMail />
          </div>
          <p className="text-base font-bold text-heading">IMAP/SMTP</p>
          <Button
            name="Connect account"
            color="blueSecondary"
            px="px-3"
            customClass="!bg-bgUpload  border-blueSecondary !font-bold"
            onClick={() => handleSmtpImap()}
          />
        </div>
      </div>
      <ConnectEmailPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
export default SelectConnectionAccount;
