import CustomBadge from 'components/badges/CustomBadge';
import Card from 'components/card';

const Widget = (props: { icon: JSX.Element; title: string; subtitle: string; onClick?: any; comingSoon?: boolean }) => {
  const { icon, title, subtitle, comingSoon, ...rests } = props;
  return (
    <Card extra={`!flex-row flex-grow items-center rounded-xl cursor-pointer hover:scale-105 duration-300 ${title || subtitle}-btn`} {...rests}>
      <div className="me-4 flex h-[75px] w-auto flex-row items-center">
        <div className="ml-2 rounded-xl bg-lightPrimary p-4 dark:bg-darkTwo">
          <span className="flex items-center text-brand-500 dark:text-white">{icon}</span>
        </div>
      </div>

      <div className="h-50 me-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <div className='flex'>
        <h4 className="text-base font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
        <span> {comingSoon && <CustomBadge content={'Coming soon'} bgColor='active-bg' color={'textBlue'} extra='h-5 ml-3 !font-semibold text-[.625rem] !px-2 !py-0.5 !my-auto ' />}</span>

        </div>
      </div>
    </Card>
  );
};

export default Widget;
