/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/aria-proptypes */
import { Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { toastState } from 'common/utils/utility';
import TagInput from 'components/crm/TagInput';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import { setDomainList, setDomainUUID } from 'store/sender-accounts';
import WarningPopup from './WarningPopup';

type DomainProps = {
  setHandleNextStep: any;
};

const CreateDomain: React.FC<DomainProps> = ({ setHandleNextStep }) => {
  const dispatch = useAppDispatch();
  const [openWarning, setOpenWarning] = useState(false);
  const [domainData, setDomainData] = useState({
    keywords: [],
    maxLength: 0,
    limit: 0,
    extension: [],
  });
  const [showData, setShowData] = useState<boolean>(false);
  const [selectedDomains, setSelectedDomains] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [generatedDomain, setGeneratedDomain] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [total, setTotal] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const tldList = ['.com', '.net', '.org', '.co', '.us', '.uk', '.de', '.au', '.ca', '.eu', '.info', '.biz'];

  const handleGenerateDomain = async () => {
    setBtnLoading(true);
    if (!domainData?.keywords?.length) {
      toast.error('Keywords required!', toastState.error);
      setBtnLoading(false);
      return;
    }
    // const keywordArray = domainData?.keywords?.map((item) => item?.keyword);
    const keyword = domainData?.keywords?.map((item) => item.keyword);
    const payload = {
      keyword: keyword,
      extension: domainData.extension,
      limit: domainData.limit,
      maxLength: domainData.maxLength,
      minLength: 1,
    };
    const response = await BaseApi.post(`/buy/domain/suggest`, payload);
    if (response?.error) {
      toast.error(response?.message, toastState.error);
      setBtnLoading(false);
    } else {
      setShowData(true);
      setBtnLoading(false);
      if (response?.data?.length) {
        console.log('data', response?.data);
        setGeneratedDomain(response?.data);
        const total = response?.data.reduce((sum: number, item: { price: number }) => sum + item.price / 100000, 0);
        setTotal(total);
      } else {
        setBtnLoading(false);
        toast.success('No Domain Data found', toastState?.success);
      }
    }
  };

  const handleTldsSelect = async (tld: string) => {
    setDomainData((prevData) => {
      // Create a copy of the current extension array
      const newExtension = prevData.extension.includes(tld)
        ? prevData.extension.filter((item) => item !== tld) // Remove tld if it exists
        : [...prevData.extension, tld]; // Add tld if it doesn't exist

      return {
        ...prevData, // Keep the other properties unchanged
        extension: newExtension, // Update the extension array
      };
    });
  };

  useEffect(() => {
    setHandleNextStep(() => handleNext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHandleNextStep, trigger]);

  const handleNext = async () => {
    setOpenWarning(true);
    // setTrigger((prev) => !prev);
    // if (selectedDomains.length) {
    //   const payload = { domain_list: selectedDomains };
    //   const insertData = await BaseApi.post(`/buy/domain/`, payload);
    //   if (insertData?.data) {
    //     console.log('Selected Domains:', selectedDomains);
    //     dispatch(setDomainList(selectedDomains));
    //     dispatch(setDomainUUID(insertData.data.insert_id));
    //     return true;
    //   }
    //   console.log('Selected Domains:', selectedDomains);
    //   return false;
    // }
  };

  const handleKeywordsChange = (name: string, value: string | { id: string; email: string }[]) => {
    const Details: any = { ...domainData };
    Details[name] = value as string | { id: string; keyword: string }[];
    setDomainData(Details);
  };

  const handleChange = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (parseInt(value) >= 0 && parseInt(value) <= 100) {
      setDomainData((prevData) => ({
        ...prevData,
        [key]: parseInt(value),
      }));
    } else if (parseInt(value) > 100) {
      toast.error('Domain limit should be within 100');
      setDomainData((prevData) => ({
        ...prevData,
        [key]: 5,
      }));
    }
  };

  const handleRangerChange = (value: number) => {
    console.log(value);
    setSliderValue(value);
    setDomainData((prevData) => ({
      ...prevData,
      maxLength: value,
    }));
  };

  const handleCheckboxChange = (domain: any) => {
    setSelectedDomains((prevSelected) => (prevSelected.includes(domain) ? prevSelected.filter((item) => item.id !== domain.id) : [...prevSelected, domain]));
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedDomains([]);
    } else {
      setSelectedDomains(generatedDomain);
    }
    setSelectAll(!selectAll);
  };
  const [sliderValue, setSliderValue] = useState(0);

  console.log(selectedDomains, 'select');

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-3/5 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">Create Domain</p>
        <hr />

        <div className="m-4 rounded-xl border border-blueSecondary bg-[#fbfaff] p-4">
          <p className="text-Base mb-2 font-medium text-[#344054]">Domain auto generator</p>
          <p className="mb-2 text-xs text-column">Auto generate number of domains you need.</p>

          <div className="flex justify-between">
            <div className=" w-5/6">
              <p className="mb-1 w-3/5 text-sm font-semibold text-heading">Keywords</p>
              <TagInput
                id="keywords"
                onChange={(item: any) => {
                  const keywords = item.map((keywords: { id: number; name: string }) => {
                    return { id: keywords?.id?.toString(), keyword: keywords?.name };
                  });
                  handleKeywordsChange('keywords', keywords);
                }}
                placeholderTags={domainData?.keywords?.map((keywords: { id: string; keyword: string }, index: number) => {
                  return { id: index, name: keywords?.keyword };
                })}
                pattern={/^[A-Za-z\d!@#$%^&*(),.?":{}|<>]*$/}
                extra=" !mb-0 w-3/4 py-2 px-2 bg-white border !rounded-md  flex  !w-[95%]"
                tagsClass="!bg-[#f9f5ff]  !h-5 !text-xs !py-0 !text-[#6941C6] hover:!bg-gray-100"
                className="h-stretch flex-rowflex-wrap bg relative  !m-0 flex rounded-lg border border-gray-200 bg-none !p-0  focus:border-brand-200 dark:!border-white/10"
              />
            </div>
            <div>
              <p className="mb-1 text-sm font-semibold text-heading">Number of domains</p>
              <input
                name="limit"
                autoComplete="off"
                type="number"
                className={`h-10 w-[10rem] rounded-md border border-gray-200 p-2 text-xs text-[#667085] focus:text-blackDark focus:outline focus:outline-2 focus:outline-menuBorder`}
                placeholder="5                            max 100"
                max="5"
                min={1}
                onChange={(e) => {
                  handleChange('limit', e);
                }}
              />
            </div>
          </div>

          <div className="mt-3">
            <p className="text-sm font-semibold text-heading">Domain Length</p>
            <div className="mt-5 flex w-full justify-between gap-5">
              <div className="flex w-full flex-col items-end justify-end">
                <p className="mb-3 text-sm font-semibold">1 to 256 characters</p>
                <Slider aria-label="slider-ex-6" min={0} max={256} defaultValue={0} onChange={(val) => handleRangerChange(val)}>
                  <SliderMark value={sliderValue} textAlign={'center'} fontSize="12px" fontWeight="bold" color="#444ce7" mt="-6" ml="-5" w="12">
                    {sliderValue ? sliderValue : ''}
                  </SliderMark>
                  <SliderTrack bg="#E2E8F0">
                    <SliderFilledTrack bg="#444CE7" />
                  </SliderTrack>
                  <SliderThumb bg="#444CE7" />
                </Slider>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="mb-2 text-base font-semibold text-heading">TLD</p>
            <div className="flex min-h-10 gap-4 rounded-md border bg-white  p-2">
              {tldList.map((item) => (
                <p
                  className={` flex h-5 min-w-10 cursor-pointer items-center justify-center rounded-full text-sm ${
                    domainData?.extension?.includes(item) ? 'bg-[#175cd3] text-white' : 'bg-[#eff8ff] text-[#175cd3]'
                  }`}
                  onClick={() => handleTldsSelect(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="mt-3 flex justify-end">
            <Button name="Generate domain" customClass="bg-blueSecondary text-white text-base" onClick={handleGenerateDomain} loading={btnLoading} />
          </div>
        </div>
        {showData && (
          <div>
            <div className="flex flex-col gap-3">
              <div className="bg-[#fafafc] p-3">
                <div className="flex items-start justify-between gap-3">
                  <div>
                    <p className="text-sm font-medium text-blackDark">Domain list</p>
                    <p className="mt-3 text-xs text-column">Auto generate number of domains you need.</p>
                  </div>

                  <div className="h-56 px-2 ">
                    <div className="p-2">
                      <div className="mb-3 flex items-center gap-3">
                        <input
                          type="checkbox"
                          id="select-domain"
                          className="size-4 border-[#D0D5DD] accent-blueSecondary outline-[#D0D5DD]"
                          onChange={handleSelectAllChange}
                          checked={selectAll}
                        />
                        <div className="!w-80 ps-2 ">
                          <p className="text-sm font-semibold">Domain</p>
                        </div>
                        <div className="!w-40">
                          <p className="ps-2 text-sm font-semibold">Price / Domain</p>
                        </div>
                        {/* <Input customClass="my-1 p-2" value={item.firstName} onChange={(e) => handleInputChange(e, item.id, 'fname')} />
                      <Input customClass="my-1 p-2 !w-56" value={item.lastName} onChange={(e) => handleInputChange(e, item.id, 'lname')} /> */}
                      </div>
                    </div>

                    <div className="h-48 overflow-auto p-2">
                      {generatedDomain?.map((item: { currency: string; domain: string; available: true; price: number }, index: number) => (
                        <div className="mb-3 flex items-center gap-3 " key={item.domain}>
                          <input
                            type="checkbox"
                            id="select-domain"
                            className="size-4 border-[#D0D5DD] accent-blueSecondary outline-[#D0D5DD]"
                            onChange={() => handleCheckboxChange(item)}
                            checked={selectedDomains.includes(item)}
                          />
                          <div className="!w-80 rounded-lg border border-[#667085] bg-white px-3 py-2">
                            <p className="text-sm text-[#667085] ">{item.domain}</p>
                          </div>
                          <div className="!w-40 rounded-lg border border-[#00B309] bg-[#00B3090F] px-3 py-2">
                            <p className="text-xs text-[#00B309] ">
                              {item.available ? <span>Available</span> : <span>Not Available</span>}, <span>${item.price / 100000}/Year</span>
                            </p>
                          </div>
                          {/* <Input customClass="my-1 p-2" value={item.firstName} onChange={(e) => handleInputChange(e, item.id, 'fname')} />
                      <Input customClass="my-1 p-2 !w-56" value={item.lastName} onChange={(e) => handleInputChange(e, item.id, 'lname')} /> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mr-10 mt-6 flex justify-end">
                  <p className="text-sm font-medium text-heading">Total sum amount: {total && Math.round(total)}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <WarningPopup isOpen={openWarning} />
    </div>
  );
};

export default CreateDomain;
