import { AccountSettingType } from "common/dto/types/store/account-settings";
import moment from "moment";

export const initialState: AccountSettingType = {
    billing: {
        usage: {
            graph_data: [],
            selected_cards: ["Campaigns", "True verification", "Enrichment", "Prospects Search", "Blacklist Monitor"],
            selected_date: {
                start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            },
            user_usage: {
                list: [],
                list_count: 0,
                user_count: 0
            },
            user: {
                list: [],
                selected_user: [],
            }
        }
    },
    api_key: {
        list: [],
        selectedLists: [],
        formData: {
            modal: false,
            uuid: '',
            name: '',
            limit: null,
        },
        dependency:false,
        loading:false,
    },
    paymentStatus: false,
    subscriptonPlanId: '',
    paymentCards: {},
    checkSparkles: false
}