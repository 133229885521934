import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/modal'
import React from 'react'
import { SiConvertio } from 'react-icons/si'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { BaseApi } from 'common/api/common/base-api'
import Button from 'common/sparkle-common/Button'
import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor'
import { toastState, useDebounce } from 'common/utils/utility'
import InputField from 'components/fields/InputField'
import TextField from 'components/fields/TextField'
import { useAppSelector } from 'store'
import { setFinalSettings } from 'store/campaign-v2'

const SignatureModal = ({ signatureModal, setSignatureModal }: { signatureModal: boolean, setSignatureModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const editorRef = React.useRef(null)
    const [signature, setSignature] = React.useState<{ name: string, value: string, editorValue: string, loading: boolean }>({ name: '', value: '', editorValue: '', loading: false })
    const debouncedEditorValue = useDebounce(signature?.editorValue, 700) as string
    const campaignV2 = useAppSelector(state => state?.campaignv2)
    const { finalSettings, ids } = campaignV2
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (finalSettings.editSignatureId) {
            const signature = finalSettings?.unsubscribeAndSignatureData?.signature_list?.find((item: { [key: string]: string }) => item?.uuid === finalSettings.editSignatureId)
            setTimeout(() => {
                if (editorRef.current) {
                    editorRef?.current?.core?.setContents(signature?.data)
                }
            }, 100)
            setSignature((prev) => ({ ...prev, name: signature.name, value: signature?.data }))
        }

    }, [finalSettings.editSignatureId, editorRef, finalSettings?.unsubscribeAndSignatureData])

    React.useEffect(() => {
        setSignature((prev) => ({ ...prev, value: debouncedEditorValue }))
    }, [debouncedEditorValue])


    const handleCloseSignatureModal = () => {
        dispatch(setFinalSettings({ editSignatureId: '' }))
        setSignature({ name: '', value: '', editorValue: '', loading: false });
        setSignatureModal(false)
    }

    const signaturePostApi = async () => {
        try {
            const params = {
                variant: 'signature',
                uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                brand_id: ids?.brand_id,
                data_list: [{ name: signature?.name, data: signature?.editorValue }]

            }
            await BaseApi.post('/channel/signature/unsubscribe', params)
        } catch (error) {
            console.log('Signature post api error', error)
        }
    }

    const signatureEditApi = async () => {
        try {
            const params = {
                variant: 'signature',
                uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                brand_id: ids?.brand_id,
                data_item: {
                    uuid: finalSettings.editSignatureId,
                    name: signature.name,
                    data: signature.editorValue
                }

            }
            await BaseApi.patch('/channel/signature/unsubscribe', params)
        } catch (error) {
            console.log('Signature edit api error', error)
        }
    }
    const handleSaveNewSignature = async () => {
        try {
            setSignature((prev) => ({ ...prev, loading: true }))
            if (signature.name) {
                if (finalSettings.editSignatureId !== "") {
                    // dispatch(updateSignatrue({ index: finalSettings.editSignature.id, signature: { name: signature.name, signature: signature.editorValue } }))
                    await signatureEditApi()
                } else {
                    // dispatch(setNewSignature({ name: signature.name, signature: signature.editorValue }))
                    await signaturePostApi()
                }
                handleCloseSignatureModal()
            } else {
                toast.error('Signature name is mandatory', toastState.error)
            }
        } catch (error) {
            console.log('Save signature error', error)
        } finally {
            setSignature((prev) => ({ ...prev, loading: false }))
        }
    }

    const handlePreview = () => {
        if (editorRef.current) {
            editorRef?.current?.core?.setContents(signature.value)
        }
    }
    return (
        <Modal isOpen={signatureModal} onClose={handleCloseSignatureModal} isCentered={true} size={'2xl'}>
            <ModalOverlay />
            <ModalContent className='!p-0'>
                <ModalHeader className='!p-0'>
                    <h5 className='text-sm text-column font-medium border-b px-4 pt-4 pb-2'>Create a signature</h5>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <InputField label={'Signature name'} id='signature_name_input' placeholder='Enter your name' labelClass='!text-heading !ml-0 !text-xs' inputClass='!mt-1 !rounded-md !h-9' value={signature.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSignature((prev) => ({ ...prev, name: e?.target?.value }))} />
                    <h4 className='text-heading text-xs font-bold mt-2.5 mb-0.5'>Enter signature</h4>
                    <div className='flex gap-2'>
                        {/* Left side view */}
                        <div className='w-full group select-none'>
                            <div className='flex justify-between p-0.5 items-end !h-8'>
                                <h4 className='text-paused-row text-xs font-medium my-1 group-focus-within:text-blueSecondary'>HTML view</h4>
                            </div>
                            <TextField id={'html_signature'} label={''} placeholder={'Enter signature...'} cols={0} rows={10} inputClass='resize-none !rounded-md focus:!border-blueSecondary' value={signature?.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSignature((prev) => ({ ...prev, value: e?.target?.value }))} />
                        </div>
                        {/* Right side view */}
                        <div className='w-full group mt-2'>
                            <div className='flex items-center gap-2'>
                                <Button RightIcon={<SiConvertio size={18} />} onClick={handlePreview} customClass='!h-6 !mb-1 !font-semibold !bg-white  !text-blueSecondary !px-1.5 !bg-buttonBackground !border-blueSecondary' />

                                <h4 className='text-paused-row text-xs font-medium my-1 group-focus-within:text-blueSecondary'>Text view</h4>
                            </div>
                            {/* <MessageBox
                                    quillRef={quillRef}
                                    editorClass='group-focus-within:border-blueSecondary !h-24'
                                    toolBarClass='group-focus-within:border-blueSecondary'
                                    value={signature.editorValue}
                                    onChange={(value) => setSignature((prev) => ({ ...prev, editorValue: value }))}
                                /> */}
                            <CustomSunEditor
                                id='signature_normal_input'
                                //  list={['bold', 'italic', 'underline', 'strike', 'align', 'list',]}
                                editorRef={editorRef}
                                height='8rem'
                                defaultValue={signature.editorValue}
                                onChange={(value) => setSignature((prev) => ({ ...prev, editorValue: value }))}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='flex gap-2 w-full'>
                        <button className='w-full py-2 text-xs rounded-md border text-heading bg-white hover:shadow-shade' id='cancel_btn' onClick={handleCloseSignatureModal}>Cancel</button>
                        <Button name='Save' disable={signature.loading} loading={signature.loading} customClass='w-full !py-1 !text-xs !text-white !bg-brand-500 hover:!shadow-shade' id='save_btn' onClick={handleSaveNewSignature} />
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SignatureModal