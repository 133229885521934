import EmailAccountsList from './features/email-accounts/EmailAccountsList';
import { useAppDispatch, useAppSelector } from 'store';
import LinkedinAccountsList from './features/linkedin-accounts/LinkedinAccountsList';
// import MultiChannelNavbar from './features/multichannel-accounts-navbar/MultiChannelNavbar';
import { FiSearch } from 'react-icons/fi';
import CustomFilter from 'components/customFilter';
import { MdAdd, MdViewWeek } from 'react-icons/md';
import Toggle from 'components/Toggle';
import React from 'react';
import { setAccountType, setColumnRowOptions } from 'store/sender-accounts';
import SelectMethodPopup from './features/email-add/select-method/SelectMethodPopup';
import { setFlagStatus } from 'store/custom';
import { SessionStorage } from 'common/api/common/storage';
import { CHANNEL_ID, EDIT_ID } from 'common/constants/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BaseApi } from 'common/api/common/base-api';
import SmsCallAccountsList from './features/sms-call-accounts/SmsCallAccountsList';
import { senderAccountsFilterDataList } from 'common/utils/page-filter';
import { checkRolePermissionStatus, jwtEncrypt } from 'common/utils/utility';

interface ColumnOption {
  _name: string;
  id: string;
  [key: string]: any; // Allows any other properties with string keys
}

const SenderEmailList = () => {
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  const [currentChannel, setCurrentChannel] = React.useState('');
  const navigate = useNavigate();
  const variant = SessionStorage.getString(CHANNEL_ID);
  const dispatch = useAppDispatch();
  const [colViewOptions, setColViewOptions] = React.useState(false);
  const childRef = React.useRef(null);
  const [searchData, setSearchData] = React.useState('');
  const [filterData, setFilterData] = React.useState('');

  const [columnOptions, setColumnOptions] = React.useState<ColumnOption[]>([
    { _name: 'Name', id: 'name', name: true, disabled: true },
    { _name: 'Email accounts', id: 'email', email: true, disabled: true },
    { _name: 'Status', id: 'mailer_status', mailer_status: true, disabled: true },
    { _name: 'Daily limits', id: 'limit', limit: true },
    // { _name: 'Health Score', id: '', none: true },
    { _name: 'Email groups', id: 'groups_list', groups_list: true },
    // { _name: 'Custom tracking', id: '', none: true },
    // { _name: 'Type', id: '', none: true },
    // { _name: 'Sent', id: '', none: false },
  ]);
  const [optionData, setOptionData] = React.useState<any[]>([]);
  const handleColumnData = (colId: string, colState: boolean) => {
    setColumnOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.id === colId) {
          return { ...option, [colId]: !colState };
        }
        return option;
      });
    });
    dispatch(
      setColumnRowOptions({
        emailOption: columnOptions.map((option) => {
          if (option.id === colId) {
            return { ...option, [colId]: !colState };
          }
          return option;
        }),
      })
    );
  };

  React.useEffect(() => {
    setOptionData(senderAccountsFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = filteredData?.data?.length ? jwtEncrypt(filteredData) : '';

    setFilterData(encryptedData);
    // setFilterData(
    //   if (filteredData?.data?.length) {
    //     return encryptedData ;
    //   } else {
    //     return filter: '' ;
    //   }
    //   // if (!prev?.filter && !filteredData?.data?.length) return { ...prev, filter:'' };
    //   // return { ...prev, filter: encryptedData };
    // );
    // const encryptedData = jwtEncrypt(filteredData);
    // setQueryParams((prev) => {
    //   if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
    //   return { ...prev, filter: encryptedData };
    // });
  };

  React.useEffect(() => {
    // console.log('first');+
    dispatch(
      setColumnRowOptions({
        emailOption: columnOptions,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCodeToTokenAndUserInfo = React.useCallback(
    async (authCode: string) => {
      try {
        await BaseApi.patch(`/sender-accounts/${variant || 'email'}/auth/code`, { code: authCode });
        dispatch(setFlagStatus(true));
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  React.useEffect(() => {
    if (authCode) {
      generateCodeToTokenAndUserInfo(authCode);
      navigate('/sender-accounts');
    }
  }, [authCode, generateCodeToTokenAndUserInfo, navigate]);

  const { accountType } = useAppSelector((state) => state.senderGroupListData);
  const senderAccountPage = useAppSelector((state) => state?.senderGroupListData?.sender_accounts_page);
  React.useEffect(() => {
    const channel = SessionStorage.getString(CHANNEL_ID) || 'email';
    setCurrentChannel(channel);
  }, [senderAccountPage]);

  const handldeAddAccount = () => {
    if (currentChannel === 'call_sms') {
      navigate('add/number');
    } else {
      dispatch(setFlagStatus(true));
      dispatch(setAccountType(currentChannel));
      SessionStorage.clearItem(EDIT_ID);
      // console.log('LL');
    }
  };

  const handleSearchAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData(event.target.value.trim().toLowerCase());
  };
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'sender_accounts');
      setRoleStatus(status)
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkTwo dark:text-white sm:overflow-x-auto`}>
      <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
        {/* <MultiChannelNavbar setCurrentChannel={setCurrentChannel} /> */}
        {/* <div className="flex items-center gap-2"> */}
        <div className="flex gap-2.5">
          {/* Custom filter */}
          <div
            className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline
              outline-0 focus-within:outline-2 focus-within:outline-brand-400
              dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
            // onClick={() => campaignSearchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer" />
            <input
              // ref={campaignSearchRef}
              type="text"
              placeholder="Search"
              id="campaign-search-input"
              className="w-full text-sm font-bold
                text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:placeholder:text-white "
              onChange={handleSearchAccount}
            />
          </div>
          <CustomFilter optionData={optionData} handleFilter={handleFilter} />
        </div>
        <div className="flex items-center gap-2">
          {(roleStatus?.is_owner || roleStatus?.add) && (
          <>
            <button
              onClick={() => handldeAddAccount()}
              className="sender_accounts_add_email_btn mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              id='add-btn'
            >
              <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add Accounts</span>
            </button>
          </>
         )}
          {currentChannel === 'email' && (
            <div
              className={`relative cursor-pointer rounded-md bg-white outline outline-2 dark:bg-darkTwo dark:outline-darkBorder  ${
                colViewOptions ? 'outline-menuBorder dark:outline-white' : 'outline-gray-300'
              }`}
            >
              <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setColViewOptions(!colViewOptions)}>
                <MdViewWeek className="text-gray-700" />
              </button>
              {colViewOptions && (
                <div
                  ref={childRef}
                  className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md
                                 border bg-white px-3 py-2 dark:border-darkBorder dark:bg-darkOne
                                 
                                 "
                >
                  {columnOptions.map((col, id) => (
                    <div key={id} className="flex items-center justify-between">
                      <h5 className="text-xs dark:text-white">{col._name}</h5>
                      <Toggle
                        checkedCondition={col?.[col.id]}
                        changeFunction={(e: Event) => {
                          handleColumnData(col.id, col?.[col.id]);
                        }}
                        disabled={col?.disabled}
                        className={`scale-50 col-list-${col?.name}`}
                        id={`col-list-${col?.name}`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
      {currentChannel === 'email' && <EmailAccountsList filterData={filterData} searchData={searchData} />}
      {currentChannel === 'linkedin' && (
        <div>
          <LinkedinAccountsList />
        </div>
      )}
      {currentChannel === 'call_sms' && (
        <div>
          <SmsCallAccountsList />
        </div>
      )}
      <SelectMethodPopup isOpen={accountType === 'email' || accountType === 'linkedin'} channelType={currentChannel} />

      {/* <EmailAccountsList /> */}
    </div>
  );
};

export default SenderEmailList;
