import { MdCloudDownload } from 'react-icons/md'

interface GlobalOptProps {
  handleOptions: any;
}

const GlobalOpt = (props: GlobalOptProps) => {
  const { handleOptions } = props;
  return (
    <div id='globalOpt' className='flex bg-blackDark rounded-md absolute bottom-8 left-1/2'>
      <div className='flex px-4 py-3.5 gap-2 items-center text-white hover:bg-brand-500 rounded-e-md transition-all cursor-default' onClick={() => handleOptions('download')}>
        <MdCloudDownload />
        <h5 className="text-sm">Download</h5>
      </div>
    </div>
  )
}

export default GlobalOpt