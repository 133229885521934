import moment from 'moment';
import { FaCheckToSlot } from 'react-icons/fa6';

// const statusText = getStatusText('campaign', item?.campaign_status);
// const badgeColor = getStatusBadgeColor('campaign', item?.campaign_status);
const TrueVerifierSearchData = (props: any) => {
  const { data, handleNavigate } = props;
  const statusMappings: { [key: string]: { [key: number]: string } } = {
    campaign: {
      0: 'Draft',
      1: 'Active',
      2: 'Rejected',
      3: 'Paused',
      4: 'Completed',
      5: 'Sender Disconnected',
      6: 'Prospects List Empty',
    },
    email_verifier: {
      0: 'Draft',
      1: 'Active',
      2: 'Rejected',
      3: 'Paused',
      4: 'Completed',
    },
    true_verifier: {
      0: 'Inprogress',
      1: 'Complete',
      2: 'Reopen',
      3: 'Cancel',
    },
  };

  const getStatusBadgeColor = (type: string, status: number) => {
    switch (type) {
      case 'campaign':
        return status === 0
          ? 'bg-indigo-400'
          : status === 1
          ? 'bg-blue-700'
          : status === 2
          ? 'bg-red-400'
          : status === 3
          ? 'bg-gray-700'
          : status === 4
          ? 'bg-successgreen'
          : status === 5
          ? 'bg-horizonTeal-700'
          : 'bg-horizonPurple-400';
      case 'email_verifier':
        return status === 0
          ? 'bg-indigo-400'
          : status === 1
          ? 'bg-blue-700'
          : status === 2
          ? 'bg-red-400'
          : status === 3
          ? 'bg-gray-700'
          : status === 4
          ? 'bg-successgreen'
          : 'bg-horizonPurple-400';
      case 'true_verifier':
        return status === 0 ? 'bg-horizonOrange-400' : status === 1 ? 'bg-successgreen' : status === 2 ? 'bg-indigo-400' : status === 3 ? 'bg-red-400' : 'bg-horizonPurple-400';
      default:
        return 'gray';
    }
  };

  const getStatusText = (type: string, status: number) => {
    return statusMappings[type]?.[status] || 'Unknown Status';
  };
  return (
    <div>
      {data?.map((item: any) => {
        const statusText = getStatusText('true_verifier', item?.ticket_status);
        const badgeColor = getStatusBadgeColor('true_verifier', item?.ticket_status);
        return (
          <>
            <div className="my-2 cursor-pointer rounded-lg  p-2  hover:bg-lightpurplebg" onClick={() => handleNavigate(item?.url)}>
              <div className=" flex justify-between gap-1">
                <FaCheckToSlot className="self-center  text-heading" size={28} />
                <div className=" flex flex-col  text-[13px]">
                  <p className="m-0 w-48  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading  ">{item?.ticket_id}</p>
                  <div
                    className={`m-0 flex min-w-20 ${badgeColor}  max-w-24 items-center justify-center overflow-hidden truncate  text-ellipsis whitespace-nowrap rounded-full p-[0.1rem] text-xs text-white `}
                  >
                    <p className="max-w-24 text-[13px]">{statusText}</p>
                  </div>
                </div>
                <div className="flex flex-col text-[13px]">
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">True Verifier</p>
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{moment(item?.cdate).format('D MMM YYYY')}</p>
                </div>
              </div>
            </div>
            <hr className=" w-full" />
          </>
        );
      })}
    </div>
  );
};

export default TrueVerifierSearchData;
