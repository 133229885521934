import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/modal'
import { ImLinkedin } from 'react-icons/im'
import { IoMail, IoCallSharp } from 'react-icons/io5'
import { MdWhatsapp, MdOutlineSms } from 'react-icons/md'
import { RiTwitterXLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import React, { createContext } from 'react'

import { useAppSelector } from 'store'
import { setDrawerStatus } from 'store/custom'
import EmailDrawer from './EmailDrawer'
import LinkedinDrawer from './LinkedinDrawer'

export const ContentDrawerContext = createContext(null);

const ContentDrawer = () => {
    const state = useAppSelector(state => state?.campaignv2)
    const custom = useAppSelector(state => state?.custom)
    const dispatch = useDispatch()
    const id = state?.sequence.findIndex(item => item.id === state?.current_sequence_id);
    const type = state?.sequence[id]?.type;
    const channelAction = state?.sequence[id]?.channelAction;
    return (
        <Drawer onClose={() => dispatch(setDrawerStatus())} isOpen={custom?.drawerStatus} size={'lg'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    <div className='flex justify-between items-center select-none'>
                        <div className='flex items-center gap-1.5'>
                            <button className='group-hover:scale-110 transition-all duration-300'>
                                {type === 'whatsapp' && <MdWhatsapp className='size-7 p-[0.45rem] fill-green-500 bg-[#015AFF0F] rounded-full' />}
                                {type === 'email' && <IoMail className='size-7 p-[0.45rem] text-[#015AFF] bg-[#015AFF0F] rounded-full' />}
                                {type === 'call' && <IoCallSharp className='text-[#04C969] bg-[#015AFF0F] size-7 p-[0.45rem] rounded-full' />}
                                {type === 'linkedin' && <ImLinkedin className='rounded-full text-blue-700 bg-[#015AFF0F] size-7 p-[0.45rem]' />}
                                {type === 'twitter' && <RiTwitterXLine className='bg-[#015AFF0F] size-7 p-[0.45rem] rounded-full' />}
                                {type === 'sms' && <MdOutlineSms className='text-[#FFA51B] bg-[#015AFF0F] size-7 p-[0.45rem] rounded-full' />}
                            </button>
                            <h5 className='text-[0.9rem] font-semibold text-heading capitalize'>{type} {channelAction?.replace('_', ' ')}</h5>
                        </div>
                        {/* <div className='flex items-center justify-end gap-2'>
                            <Button name='Select From Template' RightIcon={<BiChevronDown className='size-4' />} customClass='border-blueSecondary' color='[#4318FF]' bg='[#F9F5FF]' />
                            <IoMdMore className='text-[#8F9BBA]' />
                        </div> */}
                    </div>
                    <hr className='mt-3 border-t border-[#EAECF0]' />
                </DrawerHeader>
                <DrawerBody>
                    <ContentDrawerContext.Provider value={{ id }}>
                        {type === 'email' && <EmailDrawer />}
                        {type === 'linkedin' && <LinkedinDrawer />}
                    </ContentDrawerContext.Provider>
                </DrawerBody>
            </DrawerContent>
        </Drawer>)
}

export default ContentDrawer