import React from "react";

const CheckBoxItem = ({ list, onChange, selectedList }: { list: { id: string, name: string }[], onChange?: (selectedItems: string[]) => void; selectedList?: string[] }) => {
    const [selectedItems, setSelectedItems] = React.useState([]);

    React.useEffect(() => {
        if (selectedList) {
            setSelectedItems(selectedList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedList ? selectedList?.length : null])

    const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event?.target;
        let items = selectedItems?.filter((item) => item !== name);
        if (checked) {
            items.push(name)
        }
        setSelectedItems(items)
        onChange?.(items)
    }
    return (
        <>
            {
                list?.map((item) => (
                    <div className='flex gap-2 items-center text-sm text-column font-medium mb-1' key={item?.id}><input name={item?.id} checked={selectedItems.includes(item?.id)} onChange={handleChangeCheckBox} type="checkbox" /> <span>{item?.name}</span></div>
                ))
            }
        </>
    )
}

export default CheckBoxItem