import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import InputField from 'components/fields/InputField';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { getErrorMessage } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';

const ChangePassword = (props: any) => {
  const { isOpen, onClose } = props;
  const [handleState, setHandleState] = React.useState({
    loading: false,
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  console.log('loggedUser from Securityt', loggedUser);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(getErrorMessage({}));
    onClose();
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitChangePassword = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const Payload = {
        ...(handleState?.current_password && { current_password: handleState?.current_password }),
        ...(handleState?.new_password && { new_password: handleState?.new_password }),
        ...(handleState?.confirm_password && { confirm_password: handleState?.confirm_password }),
        // current_password: handleState?.current_password,
        // new_password: handleState?.new_password,
        // confirm_password: handleState?.confirm_password,
      };

      const updateResponse = await BaseApi.patch(`/auth/user/change/password`, Payload);
      if (!updateResponse?.error) {
        toast.success('Password Changed Successfully', toastState.success);
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        handleClose();
        dispatch(getErrorMessage({}));
      } else {
        dispatch(getErrorMessage(updateResponse?.message));
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  console.log('handleState', handleState);
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="select-none dark:!bg-darkTwo">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkOne">
            <div className="flex !flex-col gap-3 dark:text-white">
              <h5 className="text-lg font-semibold">Change password</h5>
              <span className="-mt-3 text-sm font-medium">Please enter your current password to change your password.</span>
            </div>
          </ModalHeader>
          <ModalBody className=" !p-3">
            <div>
              {/* <div className="flex mb-5 ml-2 !flex-col gap-3 dark:text-white">
              <h5 className="text-lg font-semibold">Change password</h5>
              <span className="-mt-3 text-sm font-medium">Please enter your current password to change your password.</span>
            </div> */}
              {!loggedUser?.is_google && (
                <InputField
                  autoComplete="off"
                  customClass="!h-9 !mt-0"
                  inputClass="!mt-0.5 current_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                  labelClass="!text-paused-color !font-[500]  !ml-0 !text-xs"
                  label="Current Password"
                  placeholder="Current password"
                  type="password"
                  id="current_password"
                  value={handleState?.current_password}
                  onChange={handleChange}
                  errorMessage={errorMessage?.current_password}
                  state={errorMessage?.current_password ? 'error' : ''}
                />
              )}
            </div>
            <div className="mt-4">
              <InputField
                autoComplete="off"
                customClass="!h-9 !mt-0"
                inputClass="!mt-0.5 new_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                label="New Password *"
                placeholder="New password"
                type="password"
                id="new_password"
                value={handleState?.new_password}
                onChange={handleChange}
                errorMessage={errorMessage?.new_password}
                state={errorMessage?.new_password ? 'error' : ''}
              />
            </div>
            <div className="mt-4">
              <InputField
                autoComplete="off"
                customClass="!h-9 !mt-0"
                inputClass="!mt-0.5 confirm_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                label="Confirm Password *"
                placeholder="Confirm password"
                type="password"
                id="confirm_password"
                value={handleState?.confirm_password}
                onChange={handleChange}
                errorMessage={errorMessage?.confirm_password}
                state={errorMessage?.confirm_password ? 'error' : ''}
              />
            </div>
          </ModalBody>
          <ModalFooter className="!mb-5 !mt-4 !h-2 w-full">
            <Button
              id="two-setp-auth-cancel"
              name="Cancel"
              onClick={handleClose}
              customClass="!bg-[#F4F7FE]  dark:!text-blackDark w-full !text-[#A3AED0]  border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            />
            <Button
              id="two-setp-auth-confirm"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name={'Confirm'}
              customClass=" !text-[#FFFFFF] dark:!border-none w-full confirm_password_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
              onClick={() => handleSubmitChangePassword()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ChangePassword;
