import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

interface Props {
    children: ReactJSXElement
}

interface State {
    hasError: boolean;
}

const ErrorPage = () => {
    return <div className='h-full w-full flex items-center justify-center'>
        <h2>We aware of the issue we are working on it</h2>
        <h3>Kindly go the Home page and continue again...</h3>
    </div>
}


class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log("Error catched while using this", error.message);
    }

    static getDerivedStateFromError(): Partial<State> {
        console.log("Error catched while using this");
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }
        return this.props.children
    }
}

export default ErrorBoundary;
