import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { checkRolePermissionStatus } from 'common/utils/utility';
import React from 'react';
import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { IoMdMore } from 'react-icons/io';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  fetchList?: () => void;
}

const RowUserManagementSettings = (props: RowData) => {
  const { id, rowData } = props;
  const custom = useAppSelector((state) => state?.custom);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await BaseApi.delete('/user/role/management/invited/workspace', {
        data: {
          delete_ids: [
            {
              uuid: rowData?.uuid,
              workspace_id: rowData?.all_workspaces?.workspace_id,
            },
          ],
        },
      });
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        setLoading(false);

        dispatch(setFlagStatus(!custom?.flagStatus));
      }
    } catch (error) {
      console.log('Role management list single delete error', error);
    }
  };

  const [userStatus, setUserStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'user_management');
      setUserStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'} dark:!bg-navy-800`}
        onMouseOver={(e) => e.stopPropagation}
        tabIndex={0}
        onBlur={() => {
          setShowOptions(false);
        }}
      >
        <IoMdMore
          className="user_row_settings z-0 text-lg text-gray-700"
          id={`edit-btn-${id}`}
          onClick={(event) => {
            event?.stopPropagation();
            setShowOptions(true);
          }}
        />
        {/* {showOptions && !custom?.globalOpt && (
          {(userStatus?.is_owner||userStatus?.delete)&& <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white">
            <div
              className="delete_list group flex h-8 cursor-default items-center gap-2 px-3 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={handleDelete}
            >
              <AiFillDelete className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Delete</h5>
            </div>
          </div>}
        )} */}

        {showOptions && !custom?.globalOpt && (userStatus?.is_owner || userStatus?.delete) && (
          <div className="absolute  right-0 z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:bg-darkTwo dark:border-darkBorder">
            <div
              className="delete_list user_delete_btn group flex h-8 cursor-default items-center gap-2 px-3 font-medium transition-all duration-150  hover:bg-brand-500 hover:text-white"
              onClick={() => setIsOpen(true)}
            >
              <AiFillDelete className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs text-nowrap">Remove User</h5>
            </div>
          </div>
        )}
      </div>

      {isOpen && (
        <Modal isCentered isOpen={isOpen} onClose={() => {}} size="lg">
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent overflow={'auto'} className="dark:bg-darkThree dark:!text-white">
            <ModalHeader className="mb-1 bg-[#FAFAFC] dark:!bg-darkTwo">
              <div className="flex items-center gap-2">
                <FaRegCircleCheck size={32} className="rounded-full border-4 border-red-100 bg-red-200 p-1  text-red-600 " />
                <span className="text-sm text-blackDark dark:!text-white">Delete “{rowData?.username}”</span>
              </div>
            </ModalHeader>
            <ModalBody className="!p-3">
              <>
                <div className=" text-center">
                  <h1 className="font-semibold text-blackDark dark:!text-gray-400">
                    Delete User <span className="text-red-500">{rowData?.username}</span>{' '}
                  </h1>
                  <h5 className="text-xs text-column dark:!text-gray-400">
                    Do you want to delete user <span className="font-semibold">{rowData?.email}</span>
                  </h5>
                  <h5 className="text-xs text-column dark:!text-gray-400">
                    from Workspace
                    <span className="font-semibold"> {rowData?.all_workspacess?.workspace_name}</span>
                  </h5>
                </div>
              </>
            </ModalBody>
            <ModalFooter className="!-mt-2 !mb-1">
              <>
                <Button
                  id="blacklist-close"
                  name="Cancel"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2 dark:blackDark !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
                />
                <Button
                  id="add-user-invite-btn"
                  disable={loading}
                  loading={loading}
                  name="Delete"
                  onClick={handleDelete}
                  customClass={` w-full ${loading ? '' : '!bg-danger'} dark:border-none confirm_user_delete_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out`}
                />
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RowUserManagementSettings;
