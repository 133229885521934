/* eslint-disable react-hooks/exhaustive-deps */
import { BaseApi } from 'common/api/common/base-api';
import { BlacklistGroupProps } from 'common/dto/types/views/blacklist';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { CustomMenu } from 'components/customMenu';
import SearchBox from 'components/searchBox';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useAppSelector, useAppDispatch } from 'store';
import { fetchGroupsList } from 'store/blacklist';
import { setSenderFetchData } from 'store/sender-accounts';

const GroupTagsEmail = (props: BlacklistGroupProps) => {
  const { row, fetchList, hanleDeleteGroupFromSenderList } = props;
  const [groupTags, setGroupTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showOpt, setShowOpt] = useState(false);
  const dispatch = useAppDispatch();
  const [groups, setGroups] = useState([]);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [groupsToggleState, setGroupToggleState] = useState<any>({});
  const [toggleduuid, setToggledUUID] = useState('');
  const [position, setPosition] = useState(null);
  const [searchedGroup, setSearchedGroup] = React.useState([]);

  // const state = useAppSelector((state) => state.groupListData);\
  const state = useAppSelector((state) => state.senderGroupListData);
  // const senderAccountData = useAppSelector((state) => state.senderGroupListData);
  // console.log("row",row)
  React.useEffect(() => {
    setGroups(state?.grouplist || []);
    setGroupToggleState(state?.toogleState || []);
    setSearchedGroup(state?.grouplist || []);
    // }, [state, props?.groupTags]);
  }, [state]);

  React.useEffect(() => {
    // console.log('Hitted UseEffect');
    const groups = props?.groupTags?.filter((group) => group?.status !== 2);
    setGroupTags(groups.reverse());
  }, [props?.groupTags]);

  // Delete function for group from blacklist
  const handleDelteGroup = async (uuids: string, val: any) => {
    let uuid: string;

    if (!uuids) {
      const id = state?.grouplist?.find((group: { name: string }) => group?.name === val?.name);
      setGroupTags((prev) => {
        const updatedGroup = prev?.filter((group) => group.name !== val?.name);
        return updatedGroup;
      });

      uuid = id?.uuid;
    } else {
      uuid = uuids;
      setGroupTags((prev) => {
        const updatedGroup = prev?.filter((group) => group.uuid !== uuid);
        return updatedGroup;
      });
    }


    try {
      await BaseApi.delete(`/groups/delete/into/linked/with/email`, {
        data: {
          group_id: uuid,
          variant_id: row?.uuid,
        },
      });
    } catch (error) {
      console.log('Group removing from email error', error);
    }
  };

  // Tool tip component
  const ToolTipComponent = ({ groups }: { groups: { id?: string; name?: string; uuid?: string; status?: number }[] }) => {
    return (
      <div className="relative flex flex-col">
        {groups.map(
          (
            group: {
              id?: string;
              name?: string;
              uuid?: string;
              status?: number;
            },
            index: number
          ) => (
            <>
              {
                <div key={`${index}-1`} className="group flex items-center justify-between">
                  <span className="cursor-default p-1 text-start text-xs text-gray-100"> {group.name}</span>
                  <button className="hidden px-2 text-sm hover:text-gray-500 group-hover:block" onClick={() => handleDelteGroup(group.uuid, group)}>
                    x
                  </button>
                </div>
              }
            </>
          )
        )}
      </div>
    );
  };

  // On change function for search input
  const handleChange = (event: any) => {
    if (event.key === 'Enter') {
      // Check if the searched group name exist on groupList
      // const groupName = groups.some((val) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase());
      const groupName = groups?.find((val) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase());

      if (groupName) return;
      const name = event?.target?.value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
      const params = { name: name, variant_id: row?.uuid };
      handleCreateGroup(params);
      setIsOpen(false);
    } else {
      const searchedValue = event?.target?.value?.toLowerCase();
      const searchedData = groups?.length && (searchedValue ? groups?.filter((group) => group?.name?.toLowerCase()?.includes(searchedValue)) : groups);
      setSearchedGroup(searchedData);
    }
  };

  const fetchEmailGroupList = async () => {
    try {
      const data = await BaseApi.get(`/groups/linked/with/email`);
      // console.log('dataaaa', data);
      if (data?.data && data?.data?.data?.length) {
        const finalData = await data?.data?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
        );
        // console.log('finalData', finalData);
        const states = data?.data?.data?.reduce((acc: any, val: any) => {
          acc[val.uuid] = { show: false, rename: false };
          return acc;
        }, {});
        // console.log('states', states);
        dispatch(setSenderFetchData({ finalData, states }));
      } else {
        dispatch(setSenderFetchData([]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Create new group and assaign to email when user press enter keywork in search box
  async function handleCreateGroup(params: any) {
    try {
      setGroups((prev: any) => {
        const updatedList = prev?.length
          ? [params, ...prev]?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
          : [params];
        return updatedList;
      });
      setSearchedGroup((prev: any) => {
        const updatedList = prev?.length
          ? [params, ...prev]?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0))
          : [params];
        return updatedList;
      });
      setGroupTags((prev: any) => [params, ...prev]);
      await BaseApi.patch('/groups/link/with/email', params);
      //props.grouplistTrigger();
      // dispatch(fetchGroupsList());
      fetchEmailGroupList();
    } catch (error) {
      console.error(error);
    } finally {
      fetchList();
    }
  }
  const tooltipRef = React.useRef(null);
  const toggleRef = React.useRef(null);

  // OnBlur functionality for tooltip
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !toggleRef?.current?.contains(event.target)) {
        setShowOpt(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [tooltipRef]);

  // Click function for add new group name for email
  const handleClick = async (val: any) => {
    setGroupTags((prev) => {
      // Check if group name already exist
      const groupName = prev?.some((group) => group.uuid === val.uuid);
      if (groupName) return prev;
      return [val, ...prev];
    });
    const params = { group_id: val?.uuid, variant_id: row?.uuid };
    try {
      await BaseApi.patch('/groups/link/with/email', params);
      //await dispatch(fetchGroupsList());
    } catch (error) {
      console.error(error);
    }
  };

  const parentRef = React.useRef(null);

  // Component for email to edit and rename
  const EditBtnComponent = (props: { val: any; index: number }) => {
    const { val, index } = props;
    const [value, setValue] = useState(val?.name);
    // Click function for three dot button
    const handlethreeDotBtn = async (event: any, uuid: string) => {
      event.stopPropagation();
      if (elementRef?.current) {
        const position = elementRef?.current?.getBoundingClientRect();
        setPosition(position);
      }
      try {
        setGroupToggleState((prev: any) => ({
          ...prev,
          [uuid]: { ...prev[uuid], show: true },
        }));
      } catch (error) {
        console.log('error', error);
      } finally {
        setIsEditVisible(true);
        setToggledUUID(uuid);
      }
    };

    const editComponentRef = React.useRef(null);
    const toggleComponentRef = React.useRef(null);
    const elementRef = React.useRef(null);
    // OnBlur functionality for edit component
    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (editComponentRef?.current && !editComponentRef?.current.contains(event?.target) && !toggleComponentRef?.current?.contains(event.target)) {
          setIsEditVisible(false);
          setGroupToggleState((prev: any) => ({
            ...prev,
            [toggleduuid]: { ...prev[toggleduuid], show: false },
          }));
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [editComponentRef]);

    // Click function for group delete button
    const handleDelete = async (uuid: string) => {
      setGroups((prev) => {
        const updatedList = prev?.filter((group) => group?.uuid !== uuid);
        return updatedList;
      });
      setSearchedGroup((prev) => {
        const updatedList = prev?.filter((group) => group?.uuid !== uuid);
        return updatedList;
      });
      setGroupTags((prev) => {
        const updatedGroup = prev?.filter((group) => group?.uuid !== uuid);
        return updatedGroup;
      });
      setGroupToggleState((prev: any) => ({
        ...prev,
        [uuid]: { ...prev[uuid], show: false },
      }));
      hanleDeleteGroupFromSenderList(uuid)
      setIsEditVisible(false);
      try {
        await BaseApi.delete(`/groups/${uuid}`);
        await fetchEmailGroupList()
        // dispatch(fetchGroupsList());
        // fetchList();
      } catch (error) {
        console.log('Group delete error', error);
      }
    };
    // Click function for rename button
    const handleRename = (uuid: string) => {
      setGroupToggleState((prev: any) => ({
        ...prev,
        [uuid]: { show: false, rename: true },
      }));
      setIsEditVisible(false);
    };
    const inputRef = React.useRef(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target?.value);
    };

    // Rename Group api hitting function
    const renameGroup = async (value: string) => {
      try {
        const params = {
          uuid: val?.uuid,
          name: value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase()),
        };
        setGroups((prev) => {
          const updatedList = prev
            .map((group) => {
              if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
              return group;
            })
            .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
          return updatedList;
        });
        setSearchedGroup((prev) => {
          const updatedList = prev
            .map((group) => {
              if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
              return group;
            })
            .sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0));
          return updatedList;
        });
        setGroupTags((prev) => {
          const updatedGroup = prev?.map((group) => {
            if (group?.uuid === val?.uuid) return { ...group, name: params?.name };
            return group;
          });
          return updatedGroup;
        });
        setGroupToggleState((prev: any) => ({
          ...prev,
          [val?.uuid]: { show: false, rename: false },
        }));
        await BaseApi.patch('/groups', params);
        dispatch(fetchGroupsList());
      } catch (error) {
        console.log('Group rename error', error);
      }
    };

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') renameGroup(value);
    };

    React.useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, []);

    useEffect(() => {
      if (editComponentRef?.current) {
        const childTop = position?.bottom + 9;
        const parentTop = parentRef?.current?.getBoundingClientRect()?.top;
        const top = Math.floor(childTop - parentTop);
        editComponentRef.current.style.top = `${top}px`;
      }
    }, [editComponentRef, parentRef]);
    // console.log("jkkkkkkk",toggleComponentRef)
    return (
      <div ref={toggleComponentRef}>

        {groupsToggleState[val?.uuid]?.rename ? (
          <input
            value={value}
            ref={inputRef}
            onChange={handleChange}
            id="rename-input"
            onKeyUp={handleEnter}
            className="h-8 w-full border pl-3 pr-1 text-xs font-bold text-textBlue outline-none focus:border-textBlue"
            onBlur={() => renameGroup(value)}
          />
        ) : (
          <button
            onClick={() => handleClick(val)}
            ref={elementRef}
            className={`group flex h-8 w-full cursor-pointer items-center justify-between whitespace-nowrap border-b pl-3 pr-1 text-xs text-textBlue duration-200 hover:bg-gray-50 ${groupsToggleState[val?.uuid]?.show ? 'bg-gray-100' : ''
              }`}
          >
            <span className="flex h-full w-30 truncate text-ellipsis items-center font-bold" id={`group-${index}`}>
              {val?.name}
            </span>
            <HiOutlineDotsHorizontal
              id={`group-editbtn-${index}`}
              className="hidden h-full w-7 p-1 font-bold text-gray-800 group-hover:block"
              onClick={(event) => handlethreeDotBtn(event, val?.uuid)}
            />
          </button>
        )}
        {groupsToggleState[val?.uuid]?.show && (
          <div
            ref={editComponentRef}
            className={`absolute -right-10 flex w-24 flex-col gap-2 rounded-md border bg-white p-1 shadow-md ${groupsToggleState[val?.uuid].show && 'pointer-events-auto block'}`}
          >
            <button
              id="delete-btn"
              className="flex items-center gap-2 rounded-md px-2 py-1 text-sm text-red-600 duration-300 hover:bg-gray-50"
              onClick={() => handleDelete(val?.uuid)}
            >
              <RiDeleteBin6Line />
              <span>Delete</span>
            </button>
            <button id="rename-btn" className="flex items-center gap-2 rounded-md px-2 py-1 text-sm duration-300 hover:bg-gray-50" onClick={() => handleRename(val?.uuid)}>
              <GoPencil />
              <span>Rename</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  const onClose = () => {
    setIsOpen(false);
    setIsEditVisible(false);
    //
  };


  const AddBtn = () => (
    <div>
      <IoMdAdd size={14} color="#4318FF" />
      <ArrowedTooltip data="Add Group" className="hidden !w-16 group-hover:block" />
    </div>
  );
  return (
    <div className="flex items-center gap-1" key={`${row?.uuid}-${row?.name}Variant`}>
      <CustomMenu
        open={isOpen}
        condition={!isEditVisible && !groupsToggleState?.[toggleduuid]?.rename}
        onOpen={() => setIsOpen(true)}
        onClose={() => onClose()}
        btnContent={<AddBtn />}
        // shadow-[14px_17px_40px_4px_rgba(112, 144, 176, 0.08)
        btnClassName={`Add-Group-${row?.uuid} shadow-lg bg-white p-[0.28rem] !px-1.5 text-gray-700 !rounded-full duration-300  cursor-pointer`}
        menuListClassName={`border !w-60 border-menuBorder rounded-md ${isEditVisible ? 'pointer-events-none' : ''}`}
      >
        <div ref={parentRef}>
          <SearchBox id="group-search-input" placeholder="Search or create groups" onChange={handleChange} />
          {searchedGroup?.length ? (
            <div className="mb-2 max-h-48 overflow-y-auto">
              {searchedGroup?.map((val, key) => (
                <EditBtnComponent key={val?.uuid || val?.name} val={val} index={key} />
              ))}
            </div>
          ) : (
            <span className="w-full pl-1 text-center text-xs text-gray-700">Press enter to create new group</span>
          )}
        </div>
      </CustomMenu>
      {groupTags?.slice(0, 2)?.map((val, idx) => (
        <>
          {
            <div
              key={`${val?.name}-${idx}`}
              // key={val?.uuid || val?.name}
              className={`group relative !mr-3 px-2 py-[0.23rem] pe-5 ${idx === 0 ? 'bg-[#EFF8FF] text-[#175CD3] ' : 'bg-[#F4F3FF] text-[#6941C6]'}  min-w-[5rem] cursor-default 
              whitespace-nowrap  rounded-md text-xs font-semibold `}
            >
              <span id={`added-group-${val?.uuid || val?.name}`}>{val?.name}</span>
              <span
                id={`delete-group-${val?.uuid || val?.name}`}
                className={`absolute  right-1 top-px hidden px-1 text-sm hover:cursor-pointer group-hover:inline-block ${idx === 0 ? 'bg-[##F4EBFF]' : 'bg-[#F4F7FE]'}`}
                onClick={() => handleDelteGroup(val?.uuid, val)}
              >
                x
              </span>
            </div>
          }
        </>
      ))}
      {groupTags?.length > 2 && (
        <div className="relative" ref={toggleRef}>
          <span
            className="flex cursor-pointer items-center justify-center !rounded-full bg-gray-50 px-2 text-xs font-semibold hover:bg-gray-100"
            onClick={() => setShowOpt((prev) => !prev)}
          >
            +{groupTags?.length - 2}
          </span>
          {showOpt && (
            <div ref={tooltipRef}>
              <ArrowedTooltip data={<ToolTipComponent groups={groupTags?.slice(2)} />} className="!-left-10" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default GroupTagsEmail;
