import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';
import { SessionStorage } from 'common/api/common/storage';
import { CHANNEL_ID } from 'common/constants/constants';

import { countryList } from 'common/dto/initial-value/views/campaign-v2';
import { SenderAccountNumberSearchListRowObject } from 'common/dto/types/views/sender-accounts';
import Button from 'common/sparkle-common/Button';
import InputField from 'components/fields/InputField';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';
import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { BiPurchaseTag } from 'react-icons/bi';
import { useAppDispatch } from 'store';
import { setCountryCode, setNumbersToBuy } from 'store/sender-accounts';

type SearchNumbersProps = {
  setStage: (stage: number) => void;
};
const SearchNumbers = (props: SearchNumbersProps) => {
  const { setStage } = props;
  const columnWidth = new Array(7).fill('w-full');
  const dispatch = useAppDispatch();
  const [handleState, setHandleState] = React.useState({
    country: '' || 'US',
    selectedTypeOption: 'Any',
    selectedCapablityOption: 'Any',
    prefix: '',
    table: false,
    voice_enabled: false,
    sms_enabled: false,
    mms_enabled: false,
    selectedItems: ['Any'] as string[],
  });

  const [seletedNumbers, setSelectedNUmbers] = React.useState([]);
  console.log('tableSelectedData', seletedNumbers);

  console.log('handleState', handleState);
  const [loading, setLoading] = React.useState(false);
  const handleCheckboxChange = (value: string) => {
    setHandleState((prevState) => ({
      ...prevState,
      selectedTypeOption: value,
    }));
  };

  const handleCapablityCheckboxChange = (item: string) => {
    if (item === 'Any') {
      // If 'Any' is selected, deselect all other capabilities and set 'Any' to true
      setHandleState({
        ...handleState,
        selectedItems: ['Any'], // Only 'Any' is selected
        voice_enabled: false,
        sms_enabled: false,
        mms_enabled: false,
      });
    } else {
      // If a specific capability is selected, update the selectedItems and corresponding boolean
      const updatedItems = handleState.selectedItems.includes(item)
        ? handleState.selectedItems.filter((selected) => selected !== item) // Remove if already selected
        : [...handleState.selectedItems.filter((selected) => selected !== 'Any'), item]; // Add if not selected and deselect 'Any'

      setHandleState({
        ...handleState,
        selectedItems: updatedItems,
        voice_enabled: updatedItems.includes('Voice'),
        sms_enabled: updatedItems.includes('SMS'),
        mms_enabled: updatedItems.includes('MMS'),
      });
    }
  };

  const numberTypeData = ['Any', 'Local', 'Tollfree'];
  const numberCapablityData = ['Any', 'Voice', 'SMS', 'MMS'];
  const [columns, setColumns] = React.useState([]);

  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const variantType = SessionStorage?.getString(CHANNEL_ID);

  const handleSearchNumbers = async () => {
    try {
      setLoading(true);
      const response = await BaseApi.post(`/sender-accounts/${variantType}/search/numbers`, {
        countryIso: handleState?.country,
        type: handleState?.selectedTypeOption,
        pattern: handleState?.prefix,
        voice: handleState?.voice_enabled,
        sms: handleState?.sms_enabled,
        mms: handleState?.mms_enabled,
      });
      if (!response?.error) {
        setData({ ...data, data: response?.data?.objects });
        // console.log(response, '>>>>>>>>>');
        setHandleState((prevState) => ({
          ...prevState,
          table: true,
        }));
      }

      setLoading(false);

      // console.log('data', response?.data);
      // window.open(response?.data, '_blank');
      // window.location.href = response?.data;
    } catch (error) {
      console.error('error', error);
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setSelectedNUmbers(tableSelectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);
  const columnHelper = createColumnHelper<SenderAccountNumberSearchListRowObject>();

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('number', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none font-semibold">Number</div>,
      cell: (info) => (
        <div className="flex w-full items-center gap-2">
          <div className="flex w-32 flex-col">
            <p className="w-full truncate text-xs font-semibold text-column">{info?.row?.original?.number}</p>
            <p className="w-full truncate text-xs text-column">
              {info?.row?.original?.rate_center},{info?.row?.original?.region}
            </p>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      enableSorting: false,
      header: () => <div className=" column-groupHeader select-none font-semibold">Country</div>,
      cell: (info) => {
        return (
          <div className="flex w-full items-center gap-2">
            <div className="flex w-32 flex-col">
              <p className="w-full truncate text-xs text-column">{info?.row.original?.country}</p>
            </div>
          </div>
        );
      },
    }),

    columnHelper.accessor('sub_type', {
      id: 'sub_type',
      header: () => <div className="column-limitHeader select-none font-semibold">Type</div>,
      cell: (info) => <p className={`text-sm font-semibold`}>{`${info?.row?.original?.sub_type}`}</p>,
    }),
    columnHelper.accessor('capablity', {
      id: 'health_score',
      header: () => <div className="select-none font-semibold">Capablities</div>,
      cell: (info) => (
        <div className="flex w-full items-center gap-2">
          <div className="flex w-32 flex-col">
            <p className="w-full truncate text-xs text-column">
              {' '}
              {info?.row?.original?.voice_enabled ? 'Voice' : ''}
              {info?.row?.original?.sms_enabled ? ', SMS' : ''}
              {info?.row?.original?.mms_enabled ? ', MMS' : ''}
            </p>
          </div>
        </div>
        // <div
        //   className={`mx-auto w-min rounded-full px-2.5 py-1 text-xs text-white ${
        //     info?.row?.original?.account_details?.email_health_score >= 90
        //       ? 'bg-green-600'
        //       : info?.row?.original?.account_details?.email_health_score >= 70
        //       ? 'bg-orange-600'
        //       : 'bg-red-600'
        //   }`}
        // >
        //   {info?.row?.original?.account_details?.email_health_score || 0}%
        // </div>
      ),
    }),
    columnHelper.accessor('monthly_fee', {
      id: 'monthly_fee',
      header: () => <div className="select-none font-semibold">Monthly fee</div>,
      cell: (info) => {
        return (
          <div className="flex w-full items-center gap-2">
            <div className="flex w-32 flex-col">
              <p className="w-full truncate text-xs text-column">${`${parseFloat(info?.row?.original?.monthly_rental_rate).toFixed(3)}`}</p>
            </div>
          </div>
        );
        // return <MdCloudQueue className="mx-auto" />;
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return (
          <>
            <div className="w-28">
              <Button
                name="Buy Number"
                onClick={() => {
                  dispatch(setNumbersToBuy([row?.row?.original]));
                  dispatch(setCountryCode(handleState?.country))
                  setStage(1);
                }}
                customClass="security_page_change_password bg-white dark:!bg-gray-100 border w-full !text-purple-800 font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
              />
            </div>
          </>
          // <RowSettings
          //   fetchList={fetchList}
          //   status={row.row.original.mailer_status}
          //   id={row.row.id}
          //   uuidRow={row.getValue()}
          //   rowData={row.row.original}
          //   table={table}
          //   setData={setData}
          // />
          //   <CommonRowSettings buttonClassName={'custom_row_btn_0'} uuidRow={row?.row?.original?.uuid} rowData={row.row.original} options={optionsDataRow} />
        );

        // console.log(table.getSelectedRowModel().rows.map(val => val.original)) //get full client-side selected rows
        // return <EmailRowSettings fetchList = {fetchList} status={row.row.original?.mailer_status} id={row.row.id} uuidRow={row.getValue()} rowData = {row.row.original} table={table} setData = {setData}/>
        //  <div id={`${info.row.original?.campaign_name}`}
        //     className="absolute top-0 bg-white border"
        // onClick={() => {
        // SessionStorage.setString(EDIT_ID, info.row.original?.uuid);
        // const id :number = Number(info.row.id);
        //     fetchCampaignList(info.row.original?.uuid)
        // }} >
        //     <MdEdit />
        // </div>
      },
    }),
  ];
  //   console.log(handleState);
  //   console.log(handleState);

  //   const dummyData = [
  //     { id: 1, number: '1234567890', country: 'India', type: 'local', capablity: 'calling', monthly_fee: '$10.10' },
  //     { id: 2, number: '9876543210', country: 'USA', type: 'international', capablity: 'sms', monthly_fee: '$20.20' },
  //     { id: 3, number: '2345678901', country: 'Canada', type: 'local', capablity: 'calling', monthly_fee: '$15.30' },
  //     { id: 4, number: '3456789012', country: 'Germany', type: 'international', capablity: 'data', monthly_fee: '$25.40' },
  //     { id: 5, number: '4567890123', country: 'France', type: 'local', capablity: 'calling', monthly_fee: '$12.50' },
  //     { id: 6, number: '5678901234', country: 'UK', type: 'international', capablity: 'sms', monthly_fee: '$30.60' },
  //     { id: 7, number: '6789012345', country: 'Australia', type: 'local', capablity: 'calling', monthly_fee: '$8.75' },
  //     { id: 8, number: '7890123456', country: 'Japan', type: 'international', capablity: 'data', monthly_fee: '$22.85' },
  //   ];
  //   const dummyData = [
  //     { id: 1, number: '1234567890', country: 'India', type: 'local', capablity: 'calling', monthly_fee: '$10.10' },
  //     { id: 2, number: '9876543210', country: 'USA', type: 'international', capablity: 'sms', monthly_fee: '$20.20' },
  //     { id: 3, number: '2345678901', country: 'Canada', type: 'local', capablity: 'calling', monthly_fee: '$15.30' },
  //     { id: 4, number: '3456789012', country: 'Germany', type: 'international', capablity: 'data', monthly_fee: '$25.40' },
  //     { id: 5, number: '4567890123', country: 'France', type: 'local', capablity: 'calling', monthly_fee: '$12.50' },
  //     { id: 6, number: '5678901234', country: 'UK', type: 'international', capablity: 'sms', monthly_fee: '$30.60' },
  //     { id: 7, number: '6789012345', country: 'Australia', type: 'local', capablity: 'calling', monthly_fee: '$8.75' },
  //     { id: 8, number: '7890123456', country: 'Japan', type: 'international', capablity: 'data', monthly_fee: '$22.85' },
  //   ];

  React.useEffect(() => {
    // setData({ ...data, data: dummyData });
    setColumns(columnData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const multiSelectNumbers = async () => {
    dispatch(setNumbersToBuy(seletedNumbers));
    dispatch(setCountryCode(handleState?.country))
    setStage(1);
  };

  const multiOpt = [
    {
      icon: <BiPurchaseTag />,
      name: 'Buy Numbers',
      functioning: multiSelectNumbers,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      <div className=" w-3/5 rounded-md bg-white shadow-dropShadow">
        <p className="p-3 text-sm font-semibold text-column">Search numbers</p>
        <hr />

        <div className="m-4 rounded-xl border border-blueSecondary bg-[#fbfaff] p-4">
          <div className="flex justify-between gap-2">
            <div className=" w-1/2">
              <p className="mb-1 w-3/5 text-sm font-semibold text-heading">Countries</p>
              <ReactFlagsSelect
                selectedSize={12}
                optionsSize={12}
                selectButtonClassName={`${false ? `!border !border-red-500` : ``} dark:!bg-darkOne dark:!text-white`}
                className="mt-1 w-full *:dark:!border-darkBorder *:dark:!bg-darkOne *:dark:!text-white"
                selected={handleState?.country}
                onSelect={(country) => {
                  setHandleState((prevState) => ({ ...prevState, country: country }));
                }}
                countries={countryList}
                searchable
              />
            </div>
            <div className=" w-1/2">
              <p className="mb-1 w-3/5 text-sm font-semibold text-heading">Prefered prefix</p>
              <InputField
                autoComplete="off"
                customClass="!h-9 !mt-0"
                inputClass="!mt-0.5 !rounded-md !h-9 !bg-white"
                labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                //   label="Job title"
                placeholder="Prefered prefix"
                id="occupation"
                type="text"
                value={handleState?.prefix}
                onChange={(e: any) => setHandleState({ ...handleState, prefix: e.target.value })}
              />
            </div>
          </div>

          <div className="mt-2 flex justify-between">
            <div className=" w-full">
              <p className="mb-1 w-3/5 text-sm font-normal text-heading">Type</p>
              <div className="ml-1 flex gap-2">
                <div className="flex gap-4">
                  {numberTypeData.map((item: string, index: number) => (
                    <div key={index} className="flex items-center gap-2 text-xs">
                      <input
                        type="checkbox"
                        id={`checkbox2-${index}`}
                        checked={handleState?.selectedTypeOption === item}
                        onChange={() => handleCheckboxChange(item)}
                        className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600"
                      />
                      <label htmlFor={`checkbox2-${index}`}>{item}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-between">
            <div className=" w-full">
              <p className="mb-1 w-3/5 text-sm font-normal text-heading">Capablity</p>
              <div className="ml-1 flex gap-2">
                <div className="flex gap-4">
                  {/* {numberCapablityData.map((item: string, index: number) => (
                    <div key={index} className="flex items-center gap-2 text-xs">
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        // checked={handleState?.selectedTypeOption === item}
                        onChange={() => {}}
                        className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600"
                      />
                      <label htmlFor={`checkbox-${index}`}>{item}</label>
                    </div>
                  ))} */}
                  {numberCapablityData.map((item: string, index: number) => (
                    <div key={index} className="flex items-center gap-2 text-xs">
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        checked={handleState.selectedItems.includes(item)} // Check if the item is already in the array
                        onChange={() => handleCapablityCheckboxChange(item)} // Handle checkbox change
                        className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600"
                      />
                      <label htmlFor={`checkbox-${index}`}>{item}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 flex justify-end">
            <Button name="Search" customClass="bg-blueSecondary text-white text-base" loading={loading} disable={loading} onClick={handleSearchNumbers} />
          </div>
        </div>
        {handleState?.table && (
          <div className=" h-[35vh] overflow-auto">
            <table className="w-full ">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`
                                              ${index === 0 && 'w-[35px]'}
                                              ${index === 1 && 'w-[200px] text-start'}
                                              ${index === 2 && 'w-[130px] text-start'}
                                              ${index >= 3 && index <= 5 && 'w-[130px]'}
                                              ${index === 6 && 'w-[100px]'}
                                              ${index === arr.length - 1 && 'w-[20px]'}
                                              mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center text-column dark:border-white/10 dark:bg-navy-900`}
                        >
                          <div className="text-[11px]">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className="hover:bg-hover dark:hover:bg-navy-900" onClick={() => { }}>
                        {row.getVisibleCells().map((cell, i) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]}border-white/0 py-3 text-heading ${(i === 1 || i === 2) && 'text-start'} text-center dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      <h6>No Result Data</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {seletedNumbers?.length > 1 && <MultiselectGlobalOpt options={multiOpt} />}
    </div>
  );
};

export default SearchNumbers;
