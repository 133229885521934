import React from 'react';

const PlanCardLoader = () => {
  return (
    <div className="group w-full rounded-xl bg-white shadow-lg transition-all duration-300 dark:bg-darkThree">
      <div className="h-8 rounded-t-xl bg-gray-500 py-2 text-center text-xs font-semibold text-white dark:bg-darkTwo">
        <div className="mx-auto h-4 w-1/3 rounded-lg  animate-pulse bg-gray-200  dark:bg-darkFour"></div>
      </div>

      <div className="flex items-center p-3 !px-3 pt-14">
        <div className="ml-3 flex w-7/12 flex-col space-y-2">
          <div className="h-5 w-1/2 animate-pulse rounded bg-gray-200 dark:bg-darkFour"></div>
          <div className="h-4 w-1/3 animate-pulse rounded bg-gray-200 dark:bg-darkFour"></div>
        </div>

        <div className="flex w-1/4 items-center justify-end">
          <div className="-mr-8 flex animate-pulse  flex-col items-center">
            <div className="mt-2 h-6 w-12 rounded bg-gray-200 dark:bg-darkFour"></div>
          </div>
        </div>
      </div>

      <hr className="my-2" />

      <div className="flex items-center p-3">
        <div className="flex w-9/12 flex-col space-y-2">
          <div className="h-4 w-1/3 animate-pulse rounded bg-gray-200 dark:bg-darkFour"></div>
        </div>
      </div>

      <div className="mb-2 flex h-56 flex-col flex-wrap gap-3 p-3">
        <div className="mx-2 flex h-56 w-1/2 animate-pulse flex-col flex-wrap space-y-2 overflow-auto">
          {[...Array(7)].map((_, index) => (
            <div key={index} className="h-4 w-72 rounded bg-gray-200 dark:bg-darkFour"></div>
          ))}
        </div>
        <div className="mx-2 flex h-56 w-1/2 animate-pulse flex-col flex-wrap space-y-2 overflow-auto">
          {[...Array(7)].map((_, index) => (
            <div key={index} className="h-4 w-72 rounded bg-gray-200 dark:bg-darkFour"></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanCardLoader;
