import { useState, useEffect } from 'react';
import { GrFormCheckmark } from 'react-icons/gr';

export interface stepType {
    stepNo?: number;
    name?: string;
    id?:string;
    highlighted?: boolean;
    selected?: boolean;
    completed?: boolean;
};
interface propsType {
    steps: stepType[],
    currentStep?: number;
    completedStep?: number;
    action?: any;
    onClick?: (step: stepType) => void;
    customClass?: string;
}
const SparkleStepper = (props: propsType) => {
    const { steps, onClick, currentStep, completedStep, customClass } = props;
    const [newStep, setNewStep] = useState([]);
    // const [currentHeaderStep, setCurrentHeaderSteps] = useState(1)
    const handleClick = (step: stepType) => {
        if (completedStep && (step.stepNo > completedStep)) return
        // setCurrentHeaderSteps(step.stepNo)
        onClick?.(step)
    }

    // useEffect(() => {
    //     setCurrentHeaderSteps(currentStep)
    // }, [currentStep])

    const updateStep = (stepNumber: number, steps: stepType[]) => {
        const newSteps = [...steps];
        let count = 0;
        while (count < newSteps.length) {
            //current step
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: false,
                };
                count++;
            } else if (count < stepNumber) {
                //step completed
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: true,
                };
                count++;
            } else {
                //step pending
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false,
                };
                count++;
            }
        }
        return newSteps;
    };

    useEffect(() => {
        const stepsState = steps.map((step, index) =>
            Object.assign(
                {},
                {
                    stepNo: step.stepNo,
                    description: step.name,
                    id:step.id,
                    completed: false,
                    highlighted: index === 0 ? true : false,
                    selected: index === 0 ? true : false,
                }
            )
        );

        const current = updateStep(currentStep - 1, stepsState);
        setNewStep(current);
    }, [currentStep, steps]);

    return (
        <div className={`flex items-center justify-between ${customClass || ''}`}>
            {newStep?.map((step, index) => {
                return (
                    <div key={index} className={index !== 0 ? 'flex w-full items-center' : 'flex items-center'}>
                        <div className={`flex-auto border-t-[.125rem] transition duration-300 ease-in-out ${(step.selected || step?.completed) ? 'opacity-100 border-blueSecondary' : 'opacity-40 border-gray-400'}`} />
                        <div onClick={() => handleClick(step)} className="relative group flex flex-col items-center hover:cursor-pointer">
                            {step.completed ?
                                <div className={`h-3.5 w-3.5 md:h-4 md:w-4 xl:h-5 xl:w-5 flex justify-center items-center rounded-full from-brand-400 to-brand-600 transition duration-300 ease-in-out bg-blueSecondary text-white group-hover:bg-brand-50`}>
                                    <GrFormCheckmark size={18} />
                                </div>
                                :
                                <div className={`h-3 w-3 md:h-4 md:w-4 xl:h-5 xl:w-5 flex justify-center items-center rounded-full transition duration-300 ease-in-out outline outline-offset-0 z-50 ${step?.selected ? 'outline-[.2rem] bg-buttonBackground outline-buttonBorder group-hover:bg-buttonBorder' : 'outline-2 outline-gray-50 bg-grayBorder group-hover:bg-gray-50'} dark:outline-none`}><span className={`rounded-full h-1 w-1 xl:h-1.5 xl:w-1.5 ${step?.selected ? 'bg-blueSecondary' : 'bg-graybg dark:bg-gray-200 group-hover:bg-gray-300'}`}></span></div>}
                            <div id={step?.id || ''} className={`absolute whitespace-nowrap select-none md:text-[0.62rem] xl:text-[0.72rem] font-semibold top-[.375rem] mt-[1.5625rem] hidden text-center md:block ${step.selected ? 'opacity-100 text-blueSecondary' : 'text-gray-900'} dark:text-white`}>
                                {step.description}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
export default SparkleStepper;
