import { Spinner } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import { _errorHandler, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import SearchBox from 'components/searchBox';
import React from 'react'
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { toast } from 'react-toastify';

type PropsType = {
    defaultData: any[];
    kind: string;
    placeHolder: string;
    onSelect: (data: any[]) => void;
}
const FilterDropDown = (props: PropsType) => {
    const { defaultData, kind, placeHolder, onSelect } = props;
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState([]);
    const debouncedSearch = useDebounce(search, 300)

    React.useEffect(() => {
        const queryparams = setAndGetQueryParams([{ key: "kind", value: kind }, { key: "search", value: debouncedSearch }]);
        const fetchList = async () => {
            try {
                setLoading(true)
                const response = await BaseApi.get(`/prospects/dropdown/list?${queryparams}`)
                if (!response?.error) {
                    setData(response?.data)
                }
                else {
                    toast.error(_errorHandler(response?.message), toastState?.error)
                }
            } catch (error) {
                console.log('Getting search prospect drop down error', error)
            } finally {
                setLoading(false)
            }
        }
        fetchList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch])

    React.useEffect(() => {
        setSelectedData(defaultData)
    }, [defaultData])

    React.useEffect(() => {
        onSelect?.(selectedData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData])

    const handleClickOption = (list: { name: string, id: string }) => {
        setSelectedData((prev) => {
            if (prev.includes(list?.id)) {
                return prev
            } else {
                return [...prev, list?.id]
            }
        })
    }

    const handleDelete = (event: any, list: string) => {
        event.stopPropagation()
        setSelectedData((prev) => prev.filter(item => item !== list))
    }
    return (
        <CustomMenu btnContent={
            selectedData?.length ? <div className='flex flex-wrap max-h-16 overflow-y-auto gap-1 transition-all duration-300'>
                {
                    selectedData?.map((item) => {
                        return (
                            <button className='py-0.5 pl-2 border rounded-full bg-graybg group flex h-fit text-gray-800'><span className='group-hover:pr-1'>{item}</span> <span className='transform duration-300 opacity-0 group-hover:opacity-100 pr-1 text-red-500 hover:scale-125' onClick={(event) => handleDelete(event, item)}>x</span></button>
                        )
                    })
                }
            </div> : `Select ${placeHolder}`
        } btnClassName='w-full min-h-10 flex justify-between items-center bg-white text-gray-700' menuListClassName='w-full !mt-1 relative' rightIcon={<MdOutlineArrowDropDown size={18} />}>
            <div>
                <SearchBox placeholder={`Search ${placeHolder}`} onChange={(event: any) => setSearch(event?.target?.value)} />
                {loading ? <div className='h-24 overflow-y-auto w-full flex justify-center items-center'>
                    <Spinner />
                </div>
                    :
                    <div className='h-24 overflow-y-auto'>
                        {
                            data?.filter((data) => selectedData?.every((item) => item !== data?.id))?.map((item) => {
                                return (
                                    <div key={item?.id} className='cursor-pointer py-1 px-2 hover:bg-lightPrimary rounded-md w-full text-start text-xs ' onClick={() => handleClickOption(item)}>{item?.name}</div>
                                )
                            })
                        }
                    </div>
                }

            </div>
        </CustomMenu>
    )
}

export default FilterDropDown