export const TOKEN = 'token';
export const LOGIN_STORAGE_VAR = '_logged_user_details';
export const EDIT_ID = '_edit_id';
export const CHANNEL_ID = '_channel_id';

export const AUTH_TOKEN = '_authToken';
export const USER_ID = 'logged-in-user-id';
export const USER_EMAIL = 'logged-in-user-email';

export const GOOGLE_SIGNUP = 'Continue with Google';
export const GOOGLE_LOGIN = 'Continue with Google';

export const LIVE_MODE_URL = '/dev';

export const API_STATUS = {
  initial: 'INITIAL',
  progress: 'PROGRESS',
  failed: 'FAILED',
  success: 'SUCCESS', // hello
};
export const test = 'test';

export const API_BASEURL = process.env.REACT_APP_API_URL || `http ://localhost:5000/api/v1`;
// export const API_BASEURL = `http://localhost:5000/api/v1`;
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET;
export const CRYPTO_SECRET = process.env.REACT_APP_CRYPTO_SECRET;
export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

export const PUBLIC_VAPID_KEY = process.env.REACT_APP_PUBLIC_VAPID_KEY;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_USER_ID = process.env.REACT_APP_INTERCOM_USER_ID;
export const INTERCOM_NAME = process.env.REACT_APP_INTERCOM_NAME;
export const INTERCOM_EMAIL = process.env.REACT_APP_INTERCOM_EMAIL;
export const INTERCOM_CREATED_AT = Number(process.env.REACT_APP_INTERCOM_CREATED_AT);

// export const AZ_BUCKET_URL = "https://sparkle-dev.azurewebsites.net"
export const AZ_BUCKET_URL = "https://sparkle-dev.azurewebsites.net"
export const AZ_BUCKET = {
  SPARKLE_LOGIN_PAGE_SIDE_IMG: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/auth.png`,
  SPARKLE_NAME_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739110745/sparkle-name-blue.png`,
  SPARKLE_NAME_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/sparkle-name-white.png`,
  SPARKLE_LOGO_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517758/sparkle-blue.png`,
  SPARKLE_LOGO_TRANSPARANT_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-transparant-white.png`,
  SPARKLE_LOGO_TRANSPARANT_BLUE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-trasparant.png`,
  SPARKLE_LOGO_WHITE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739517759/sparkle-white.png`,
  SPARKLE_CIRCLE_TICK_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/circle-tick-icon.png`,
  SPARKLE_FACEBOOK_HEADER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/facebook-header-icon.png`,
  SPARKLE_FACEBOOK_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/facebook-icon.png`,
  SPARKLE_INSTAGRAM_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/instagram-icon.png`,
  SPARKLE_LINKEDIN_HEADER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846947/linkedin-header-icon.png`,
  SPARKLE_LIKENDIN_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/linkedin-icon.png`,
  SPARKLE_LOCK_IMAGE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/lock-image.png`,
  SPARKLE_WORLD_MAP: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/Mapsicle-Map.png`,
  SPARKLE_: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/twitter-header-icon.png`,
  SPARKLE_TWITTER_ICON: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/twitter-icon.png`,
  SPARKLE_EMPLOYEE_IMAGE: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726739846948/work-image.png`,
  SPARKLE_APOLLO: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/apollo.mini.io.jfif`,
  SPARKLE_ILLUSTRATION: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/Illustration.jfif`,
  SPARKLE_CRM_ONBOARD_VIEW: `${AZ_BUCKET_URL}/sparkle/az/bucket/1726740647994/onboard-crm-view.jfif`,
}