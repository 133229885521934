import React from 'react';
import { BiShuffle, BiSolidMessageCheck } from 'react-icons/bi';
import { BsPersonWorkspace } from 'react-icons/bs';
import { FaUserPlus } from 'react-icons/fa';
import { GoInbox, GoPerson } from 'react-icons/go';
import { IoMdMail } from 'react-icons/io';
import { MdCampaign, MdMarkEmailRead, MdOutlineSettings } from 'react-icons/md';
import { PiFlowArrow } from 'react-icons/pi';
import { RiMailCloseLine, RiUserFollowLine } from 'react-icons/ri';
import { SiAwsorganizations } from 'react-icons/si';

function DynamicIcons({ name }: any) {
  const iconList = [
    {
      name: 'Role',
      Icon: <GoPerson />,
    },
    {
      name: 'Campaigns',
      Icon: <MdCampaign />,
    },
    {
      name: 'Deliverability Domains',
      Icon: <MdOutlineSettings />,
    },
    {
      name: 'Deliverability Blacklist',
      Icon: <MdMarkEmailRead />,
    },
    {
      name: 'Sender Accounts',
      Icon: <IoMdMail />,
    },
    {
      name: 'Email Verifier',
      Icon: <IoMdMail />,
    },
    {
      name: 'True Verifier',
      Icon: <IoMdMail />,
    },
    {
      name: 'CRM',
      Icon: <PiFlowArrow />,
    },
    {
      name: 'Inbox',
      Icon: <GoInbox />,
    },
    {
      name: 'Brands',
      Icon: <BiSolidMessageCheck />,
    },
    {
      name: 'Unmatched',
      Icon: <BiShuffle />,
    },
    {
      name: 'Organization',
      Icon: <SiAwsorganizations />,
    },
    {
      name: 'Workspace Management',
      Icon: <BsPersonWorkspace />,
    },
    {
      name: 'Role Management',
      Icon: <RiUserFollowLine />,
    },
    {
      name: 'User Management',
      Icon: <FaUserPlus />,
    },
    {
      name: 'Do Not Email List',
      Icon: <RiMailCloseLine />,
    },
  ];
  const iconData = iconList.find((element) => element.name === name);
  //   console.log('iconData>>', iconData);

  return (
    <div className="flex items-center gap-2">
      {iconData?.Icon}
      {iconData?.name}
    </div>
  );
}

export default DynamicIcons;
