import { Tabs, TabList, Tab, TabIndicator, TabPanels, TabPanel } from '@chakra-ui/react';

interface TabViewProps {
  onTabChange?: (tabIndex: number) => void;
  count: any;
  index: number;
}

const TabView = ({ count, index, onTabChange }: TabViewProps) => {
  const totalCount = count?.campaign + count?.brand + count?.trueVerifier + count?.emailVerifier + count?.senderAccounts + count?.blacklist;
  return (
    <div className="h-full border-b  pt-1">
      <Tabs position="relative" variant="unstyled" onChange={(index) => onTabChange(index)} index={index}>
        <TabList className="flex items-center justify-center font-semibold">
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-7 items-center justify-center rounded-full bg-[#8F9BBA26] text-xs">{totalCount}</span>
            <span className="text-[.85rem]">All</span>
          </Tab>

          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.campaign}</span>
            <span className="text-[.85rem]">Campaign</span>
          </Tab>

          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.brand}</span>

            <span className="text-[.85rem]">Brand</span>
          </Tab>
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.trueVerifier}</span>

            <span className="w-24 text-[.85rem]">True Verifier</span>
          </Tab>
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.emailVerifier}</span>

            <span className="w-24 text-[.85rem]">Email Verifier</span>
          </Tab>
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.senderAccounts}</span>
            <span className="w-28 text-[.85rem]">Sender Acount</span>
          </Tab>
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count?.blacklist}</span>

            <span className="text-[.85rem] ">Blacklist</span>
          </Tab>
          <Tab _selected={{ color: '#4318FF' }} color={'#7C8493'} paddingX={'.65rem'}>
            <span className="me-2 flex h-5 w-8 items-center justify-center rounded-full bg-[#8F9BBA26]  text-xs">{count.menu}</span>
            <span className="text-[.85rem] ">Menus</span>
          </Tab>
        </TabList>

        <TabIndicator mt="-2px" height="5px" className="scale-x-[85%] bg-brand-600" borderTopRadius="20px" />
        <TabPanels>
          <TabPanel padding={0}>{/* <Communication /> */}</TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default TabView;
