import { usageCardsData } from 'common/dto/initial-value/views/billing';
import moment from 'moment';
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useAppSelector } from 'store';

interface PropsType {
  loading: boolean
}
const Graph = (props: PropsType) => {
  const { loading } = props
  const selectedCards = useAppSelector((state) => state?.accountSettings?.billing?.usage?.selected_cards);
  const sideBarFullView = useAppSelector(state => state?.custom?.sideBarFullView);
  const graphData = useAppSelector((state) => state?.accountSettings?.billing?.usage?.graph_data);
  const [graphSeries, setGraphSeries] = React.useState([]);

  // const getCategories = () => {
  //   return dataSets?.filter((item: any) => isDateInRange(item?.date, selectedDate?.[0], selectedDate?.[1]))?.map((item: any) => formatDateIntoDDMM(item?.date))
  // }
  const options: any = {
    // colors: selectedCards?.map((feature) => usageCardsData?.find((data) => data?.name === feature)?.hex_color_code),
    colors: usageCardsData?.map((feature) => (selectedCards?.find((data) => data === feature?.name) ? feature.hex_color_code : null)).filter(Boolean),
    chart: {
      type: 'area',
      toolbar: { show: false },
      redrawOnParentResize: true,
      width: '100%',
      zoom: {
        autoScaleYaxis: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      type: 'datetime',
      // labels: {
      // //   // style: {
      // //   //   colors: '#667085',
      // //   //   fontWeight: 'thin',
      // //   // },
      //   datetimeFormatter: {
      //     year: 'yyyy',
      //     month: 'MMM \'yy',
      //     day: 'dd MMM',
      //     hour: 'HH:mm'
      //   }
      // },
      // title: {
      //   text: 'Month'  
      // },
      // categories: ['Jan', 'Feb', 'March', 'Apr', 'May']
    },
    yaxis: {
      labels: {
        style: {
          colors: '#667085',
          fontWeight: 'thin',
        }
      }
      // title: {
      //   text: 'Leads',
      // },
    },
    legend: {
      show: false,
      // position: 'top',
      // horizontalAlign: 'right',
      // labels: { colors: '#667085' },
      // markers: {
      //   width: 6,
      //   height: 6,
      //   radius: 10,
      //   fillColors: selectedCards?.map((feature) => usageCardsData?.find((data) => data?.name === feature)?.hex_color_code),
      // },
    },
    grid: {
      // show: false,
      strokeDashArray: 10,

    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.4,
        opacityTo: 0
      }
    },
    // fill: {
    //   opacity: 0.5,
    //   colors: ['#0BA5EC', '#FF692E', '#16B364', '#7F56D9', '#4318FF'],
    //   // gradiant: {
    //   //   // enabled: true,
    //   //   opacityFrom: 0,
    //   //   opacityTo: 0
    //   // }
    // },
    // markers: {
    //   colors: ['#0BA5EC', '#FF692E', '#16B364', '#7F56D9', '#4318FF']
    // },
    // tooltip: {
    //   y: false
    // },
  };


  // const getSeries = () => {
  //   const sentCount = dataSets?.filter((item: any) => isDateInRange(item?.date, selectedDate?.[0], selectedDate?.[1])).map((item: any) => item?.sent_count)
  //   const openCount = dataSets?.filter((item: any) => isDateInRange(item?.date, selectedDate?.[0], selectedDate?.[1])).map((item: any) => item?.open_count)
  //   const repliedCount = dataSets?.filter((item: any) => isDateInRange(item?.date, selectedDate?.[0], selectedDate?.[1])).map((item: any) => item?.replied_count)
  //   const unsubscribedCount = dataSets?.filter((item: any) => isDateInRange(item?.date, selectedDate?.[0], selectedDate?.[1])).map((item: any) => item?.unsubscribed_count)
  //   return [
  //     { name: 'Sent', data: sentCount || [5] },
  //     { name: 'Opened', data: openCount || [3] },
  //     { name: 'Replied', data: repliedCount || [2] },
  //     { name: 'Unsubscribed', data: unsubscribedCount || [1] },
  //   ]
  // }

  // const data = [
  //   { name: 'Campaigns', data: [5001, 1200, 1800, 51000, 12000] },
  //   { name: 'True verification', data: [1500, 2200, 2800, 41000, 22000] },
  //   { name: 'Enrichment', data: [2500, 32500, 38100, 13000, 23000] },
  //   { name: 'Prospects Search', data: [35800, 4200, 48000, 21000, 24000] },
  //   { name: 'Blacklist Monitor', data: [45080, 52080, 5800, 11000, 52000] },
  // ]?.filter((feautre: any) => selectedCards?.includes(feautre?.name))
  const data = graphSeries?.filter((feautre: any) => selectedCards?.includes(feautre?.name))

  React.useEffect(() => {
    const convertedData = usageCardsData?.map((feature) => {
      const featureData = graphData?.map((graph) => [moment(graph?.date)?.valueOf(), (graph[feature?.key] || 0)])
      return { name: feature?.name, data: featureData }
    });
    setGraphSeries(convertedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  return (
    <div className={`${!loading && "bg-white"} h-[320px] grid rounded-sm shadow-sm ${sideBarFullView ? "w-[80rem] lg:w-[50rem] 2xl:w-[80rem]" : 'w-[90rem] lg:w-[58rem] 2xl:w-[90rem]'} max-w-full`} id='area-chart'>
      {
        loading ?
          <div className='h-[95%] m-2 bg-gray-100 animate-pulse flex items-center'>
            <div className='m-4 border-l-4 border-b-4 h-[87%] w-full px-4 pb-3 flex flex-col justify-evenly'>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
              <div className='w-full border-t-4 border-dashed h-1'></div>
            </div>

          </div>
          :
          <ReactApexChart
            options={options}
            series={data}
            type={'area'}
            height={300}
          />
      }
    </div>
  )
}

export default Graph