/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import InputField from 'components/fields/InputField';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { getErrorMessage } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';
import { CustomMenu } from 'components/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import TextField from 'components/fields/TextField';

const AddCreditFeatureModal = (props: any) => {
  const { isOpen, onClose, editId } = props;
  const [open, setOpen] = React.useState(false);
  const [handleState, setHandleState] = React.useState({
    loading: false,
    feature_name: '',
    description: '',
    credits: 0,
    typeName: '',
    feature_type: '',
  });

  const featureType = [
    { name: 'Unlimited Emails', feature_type: 'unlimited_emails' },
    { name: 'Unlimited Mailbox', feature_type: 'unlimited_mailbox' },
    { name: 'Unlimited Sender Accounts', feature_type: 'unlimited_sender_accounts' },
    { name: 'Dynamic IP Addresses', feature_type: 'dynamic_ip_addresses' },
    { name: 'CRM Access', feature_type: 'crm_access' },
    { name: 'Connect Existing Email IDs', feature_type: 'connect_existing_email_ids' },
    { name: 'Blacklist Monitoring', feature_type: 'blacklist_monitoring' },
    { name: 'True Email Verifier', feature_type: 'true_email_verifier' },
    { name: 'Campaign Sending', feature_type: 'campaign_sending' },
    { name: 'Unlimited Seats', feature_type: 'unlimited_seats' },
    { name: 'Role-based Access', feature_type: 'role_based_access' },
    { name: 'Turn off ‘Sent with Sparkle’ Badge', feature_type: 'turn_off_sent_with_sparkle_badge' },
    { name: 'Sparkle Dynamic Email Address for Sending', feature_type: 'sparkle_dynamic_email_address_for_sending' },
    { name: 'Email Verifier', feature_type: 'email_verifier' },
    { name: 'Enrichment', feature_type: 'enrichment' },
  ];

  React.useEffect(() => {
    const getOneEditData = async () => {
      try {
        const response = await BaseApi.get(`/credit/feature/${editId}`);
        if (!response?.error) {
          console.log(response?.data?.feature_type);
          const typeName = featureType?.find((item) => item?.feature_type === response?.data?.feature_type);

          console.log('typeName', typeName);
          setHandleState({
            ...handleState,
            feature_name: response?.data?.feature_name,
            description: response?.data?.description,
            credits: response?.data?.credits,
            typeName: featureType?.find((item) => item?.feature_type === response?.data?.feature_type)?.name,
            feature_type: response?.data?.feature_type,
          });
        }
      } catch (error) {}
    };
    editId && getOneEditData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(getErrorMessage({}));
    onClose();
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCreateCreditFeature = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const Payload = {
        feature_name: handleState?.feature_name,
        description: handleState?.description,
        credits: handleState?.credits,
        feature_type: handleState?.feature_type,
      };

      if (editId) {
        const updateResponse = await BaseApi.patch(`/credit/feature`, { uuid: editId, ...Payload });
        if (!updateResponse?.error) {
          toast.success('Created Feature Successfully', toastState.success);
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          handleClose();
        } else {
          dispatch(getErrorMessage(updateResponse?.message));
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        }
      } else {
        const updateResponse = await BaseApi.post(`/credit/feature`, Payload);
        if (!updateResponse?.error) {
          toast.success('Created Feature Successfully', toastState.success);
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
          handleClose();
        } else {
          dispatch(getErrorMessage(updateResponse?.message));
          setHandleState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        }
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="select-none dark:!bg-darkTwo">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkOne">
            <div className="flex !flex-col gap-3 dark:text-white">
              <h5 className="text-lg font-semibold">Credit Feature</h5>
            </div>
          </ModalHeader>
          <ModalBody className=" !p-3">
            <div className="mt-4 items-end gap-2 ">
              <p className=" text-[12px] font-semibold dark:text-white">Feature</p>

              <div className=" w-full">
                <CustomMenu
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  btnContent={`${handleState?.typeName}` || 'select'}
                  btnClassName="w-full justify-between border-blue-500 !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                  menuListClassName="w-full !h-44 overflow-auto !mt-0.5 !p-0"
                  rightIcon={<BiChevronDown size={18} />}
                  btnId="primary_goal_drop_down"
                >
                  {featureType?.map((item) => (
                    <button
                      id="primary_goal_0"
                      onClick={() => {
                        setHandleState({ ...handleState, feature_type: item?.feature_type, feature_name: item?.name, typeName: item?.name });
                        setOpen(false);
                      }}
                      className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                    >
                      {item?.name}
                    </button>
                  ))}
                </CustomMenu>
              </div>
            </div>
            {/* <div className="mt-4">
           
              <InputField
                readOnly={true}
                autoComplete="off"
                customClass="!h-9 !mt-0"
                inputClass="!mt-0.5 current_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                labelClass="!text-paused-color !font-[500]  !ml-0 !text-xs"
                label="Feature Name"
                placeholder="Feature Name"
                type="text"
                id="feature_name"
                value={handleState?.feature_name}
                onChange={handleChange}
                errorMessage={errorMessage?.current_password}
                state={errorMessage?.current_password ? 'error' : ''}
              />
            
            </div> */}
            <div className="mt-4">
              <TextField
                extra="mb-1"
                inputClass="mt-2 dark:!bg-darkFive brand_icp_input resize-none !rounded-md"
                labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
                label="Description"
                placeholder="Enter a description..."
                id="description"
                cols={30}
                rows={4}
                value={handleState?.description}
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <InputField
                autoComplete="off"
                customClass="!h-9 !mt-0"
                inputClass="!mt-0.5 new_password_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                label="Credits"
                placeholder="Credits"
                type="number"
                id="credits"
                value={handleState?.credits}
                onChange={handleChange}
              />
            </div>
          </ModalBody>
          <ModalFooter className="!mb-5 !mt-4 !h-2 w-full">
            <Button
              id="two-setp-auth-cancel"
              name="Cancel"
              onClick={handleClose}
              customClass="!bg-[#F4F7FE]  dark:!text-blackDark w-full !text-[#A3AED0]  border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            />
            <Button
              id="two-setp-auth-confirm"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name={'Confirm'}
              customClass=" !text-[#FFFFFF] dark:!border-none w-full confirm_password_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
              onClick={() => handleCreateCreditFeature()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddCreditFeatureModal;
