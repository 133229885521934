import React from 'react';
// import BasicBilling from '../../../../../../assets/json/basic-new-billing-details.json';
// import ProBilling from '../../../../../../assets/json/pro-new-billing-details.json';

import { GiCheckMark } from 'react-icons/gi';

import { MdCancel } from 'react-icons/md';
import { CustomMenu } from 'components/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import Button from 'common/sparkle-common/Button';
import { BaseApi } from 'common/api/common/base-api';
import { useAppDispatch } from 'store';
import { setSubscriptonPlanId } from 'store/account-settings';

const PlanCard = (props: any) => {
  const { items, creditsDetails } = props;
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [credits, setCredits] = React.useState({
    price: 0,
    sparkles: 0,
  });
  const handleSelect = (value: number, sparkles: number, credit_value_id: string, plan_id: string) => {
    setPaymentPayload({ ...paymentPayload, credit_addon_id: credit_value_id, plan_id: plan_id });
    setCredits({ ...credits, price: value, sparkles: sparkles });
    setOpen(false);
  };
  const [paymentPayload, setPaymentPayload] = React.useState({
    plan_id: '',
    credit_addon_id: '',
  });
  // console.log('creditsDetails', creditsDetails,paymentPayload);

  const handlePayment = async () => {
    dispatch(setSubscriptonPlanId(paymentPayload?.plan_id));
    try {
      setLoading(true);
      const response = await BaseApi.post(`/subscription/payment`, {
        ...paymentPayload,
      });
      if(!response?.error){
        if (response.data.sessionUrl) {
          window.location.href = `${response.data.sessionUrl}`;
        }
      }else{

        setLoading(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoading(false);

    }
  };

  React.useEffect(() => {
    setCredits({
      ...credits,
      price: items?.name === 'Free' ? 0 : creditsDetails[creditsDetails?.length - 1]?.amount || 0,
      sparkles: items?.name === 'Free' ? 0 : creditsDetails[creditsDetails?.length - 1]?.credits || 0,
    });
    setPaymentPayload({ ...paymentPayload, credit_addon_id: creditsDetails[creditsDetails?.length - 1]?.uuid, plan_id: items?.uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  creditsDetails?.sort((a: { credits: number }, b: { credits: number }) => (a['credits'] > b['credits'] ? 1 : -1));
  items?.credit_feature_plans.sort((a: { feature_status: boolean }, b: { feature_status: boolean }) =>
    a['feature_status'] === b['feature_status'] ? 0 : a['feature_status'] ? -1 : 1
  );
  return (
    <div>
      <div className="group w-full rounded-xl bg-white shadow-lg transition-all duration-300">
        {items?.recommended_plan && <div className="rounded-t-xl  bg-[#1570EF] py-2 text-center text-xs font-semibold text-white">Most popular plan</div>}
        {/* <div className={`flex items-center border border-green-500 ${items?.recommended_plan ? creditsDetails?.length ?`pt-4` : creditsDetails?.length ?`pt-7` : !items?.recommended_plan ? `pt-14` :`pt-10`} p-3 `}> */}
        <div
          className={`flex items-center  ${
            items?.recommended_plan ? (creditsDetails?.length ? 'pt-4' : 'pt-7') : creditsDetails?.length ? 'pt-12' : `pt-[52px]`
          } p-3 !px-3`}
        >
          <div className="ml-3  flex w-7/12 flex-col">
            <span className=" text-xl font-semibold">{items?.name}</span>
            <span className=" text-sm font-normal text-column ">{items?.description}</span>
          </div>
          <div className="w-1/4 p-2">
            {items?.name !== 'Free' ? (
              <CustomMenu
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                btnContent={`${credits?.sparkles} sparkles` || 'select'}
                btnContentClassName="w-40 truncate "
                btnClassName="w-full  px-1 justify-between border-blue-500 !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                menuListClassName="w-full  h-36 overflow-auto !mt-0.5 !p-0"
                rightIcon={<BiChevronDown size={18} />}
                btnId="primary_goal_drop_down"
              >
                {creditsDetails?.map((item: any, idx: number) => (
                  <button
                    key={idx}
                    id="primary_goal_0"
                    onClick={() => {
                      handleSelect(item?.amount, item?.credits, item?.uuid, items?.uuid);
                    }}
                    className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                  >
                    <div className="!w-32 truncate"> {item?.credits} Sparkles</div>
                  </button>
                ))}
              </CustomMenu>
            ) : (
              <></>
            )}
          </div>
          <div className="flex w-1/4 items-center justify-end  ">
            <div className=" mr-2 flex px-2 text-lg  font-bold">
              $ <span className="ml-1 text-4xl">{credits?.price}</span>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <div className="flex items-center  p-3 ">
          <div className="flex w-9/12 flex-col ">
            <span className=" mx-2 text-sm font-semibold">FEATURES</span>
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-x-8 border border-red-500 p-3">
            {BasicBilling?.map((item, index) => (
              <div key={index} className="mb-2 flex items-center gap-2">
                {item?.access ? <GiCheckMark className="rounded-full bg-green-300 p-1 text-green-700" size={17} /> : <MdCancel className="text-paused-row" size={18} />}
                <span className="whitespace-nowrap text-sm font-normal">{item?.feature}</span>
              </div>
            ))}
          </div> */}

        <div className="mb-2 flex   h-56 flex-col flex-wrap gap-x-8 p-3">
          <div className="mx-2 flex h-56 flex-col flex-wrap overflow-auto ">
            {items?.credit_feature_plans?.map(
              (feature: any, index: any) =>
                feature?.visible_status && (
                  <div key={index} className="mb-2  flex  items-center gap-2">
                    {feature?.feature_status ? (
                      <GiCheckMark className="rounded-full bg-green-200 p-1 text-green-700" size={17} />
                    ) : (
                      <MdCancel className="text-gray-400" size={19} />
                    )}
                    <span className={`whitespace-nowrap text-sm font-normal text-column ${!feature?.feature_status && `!text-gray-400`}`}>{feature?.feature_name}</span>
                  </div>
                )
            )}
          </div>
        </div>

        {/* <hr className="my-2" /> */}

        <div className={`p-2 px-2 ${items?.recommended_plan ? `` : credits?.price === 0 ? `opacity-0` : `opacity-0 transition-all duration-300 group-hover:opacity-100`} `}>
           <Button
            name="Purchase Now"
            id="organization_next_btn"
            loading={loading}
            disable={loading || credits?.price === 0}
            customClass="!text-[.875rem] w-full organization_next_btn dark:border-none active:scale-95 transition-transform duration-150 ease-out "
            onClick={() => {
              // console.log(items)

              handlePayment();
              // dispatch(setPaymentCards(items));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
