/* eslint-disable no-useless-escape */
import React from 'react';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { TbUser } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { FiSearch } from 'react-icons/fi';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/modal';

import industyTypes from '../../../assets/json/industry.json';
import { BaseApi } from 'common/api/common/base-api';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import Button from 'common/sparkle-common/Button';
import { checkRolePermissionStatus, cryptoDecrypt, toastState } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import InputUploader from 'components/fields/inputUploader';
import { useAppDispatch, useAppSelector } from 'store';
import { setLoggedUserDetails, setLoggedUserRolePermissionList } from 'store/authentication';
import {
  resetOrganization,
  resetWorkspace,
  setModalView,
  setOrganization,
  setOrganizationId,
  setStepCount,
  setWorkspace,
  setswitchOrganizationModalView,
  setWorkspaceEditState,
  setWorkspaceInviteId,
} from 'store/organization';
import { LocalStorage } from 'common/api/common/storage';

const OrganizationAndWorkSpace = () => {
  const dispatch = useAppDispatch();
  const { organization, organization_id, modal_view, workspace, step_count, workspaceEditState, workspaceInviteId } = useAppSelector((state) => state.organization);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const [handleState, setHandleState] = React.useState({
    loading: false,
    roleData: [],
    searchIndustry: '',
    addUserData: {
      role_access_id: '',
      username: '',
      workspace_access_ids: [loggedUser?.workspace_id],
      email: '',
      role_name: '',
    },
    userStatus: null,
  });

  const filteredItems = industyTypes?.filter((item: any) => item.name.toLowerCase().includes(handleState?.searchIndustry?.toLowerCase()));
  const [open, setOpen] = React.useState({
    industry: false,
    business_type: false,
    company_size: false,
    primary_goal: false,
    assign_role: false,
  });
  const [logouploading, setLogoUploading] = React.useState(false);
  const [userSentDetails, setUserSentDetails] = React.useState({
    sent: false,
    users: [],
  });

  const handleSearchChange = (e: any) => {
    setHandleState((prevState) => ({
      ...prevState,
      searchIndustry: e.target.value,
    }));

    // setSearch(e.target.value);
  };
  // Handle organization change
  const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event?.target;
    dispatch(setOrganization({ [id]: value }));
  };
  // Handle workspace change
  const handleWorkspaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event?.target;
    dispatch(setWorkspace({ [id]: value }));
  };
  const handleSelect = (id: string, value: string) => {
    // setFormData((prev) => ({ ...prev, [id]: value }))
    setOpen((prev) => ({ ...prev, [id]: false }));
    dispatch(setOrganization({ [id]: value }));
  };
  //handle Add User Role
  const handleAddUserRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event?.target;
    setHandleState((prevState) => ({
      ...prevState,
      addUserData: {
        ...prevState.addUserData,
        [id]: value,
      },
    }));

    // setAddUserData((prev: any) => ({
    //   ...prev,
    //   [id]: value,
    // }));
  };
  const handleUploadOrganizationLogo = (id: string, data: any) => {
    // setFormData((prev) => ({ ...prev, [id]: data.data }))

    dispatch(setOrganization({ [id]: data }));
  };
  const handleUploadWorkspaceLogo = async (id: string, data: any) => {
    dispatch(setWorkspace({ [id]: data?.data?.data?.url }));
  };
  const handleOrganizationSave = async () => {
    try {
      setHandleState((prevState) => ({ ...prevState, loading: true }));

      const { upload_logo, organization_name, industry, company_size, occupation, primary_goal } = organization;

      const params = {
        name: organization_name,
        ...(upload_logo && { logo: upload_logo }),

        industry,
        business_type: 'cfvgbhnjk',
        campany_size: company_size,
        occupation,
        primary_goal,
        description: 'Description is required',
      };
      const response = await BaseApi.post('/organization', params);

      if (!response.error) {
        dispatch(setOrganizationId(response?.data?.insert_id));
        dispatch(resetOrganization());
        dispatch(setStepCount(2));
        console.log('Org created ID', organization_id);
        setHandleState((prevState) => ({
          ...prevState,
          searchIndustry: '',
        }));
      }
    } catch (error) {
      console.log('Organization creating error', error);
    } finally {
      setHandleState((prevState) => ({ ...prevState, loading: false }));
    }
  };
  const handleSaveOrganizationAndWorkspace = async (organization_id: string, workspace_id: string) => {
    try {
      const response = await BaseApi.post('/helper/generate/access/token', {
        organization_id: organization_id,
        workspace_id: workspace_id,
      });
      if (!response.error) {
        LocalStorage.setString(LOGIN_STORAGE_VAR, response?.data?.data);
        const loginData = await cryptoDecrypt(response?.data?.data, true);
        dispatch(
          setLoggedUserDetails({
            id: loginData?.id,
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            organization_id: loginData?.organization_id,
            workspace_id: loginData?.workspace_id,
            organization_name: loginData?.organization_name,
            workspace_name: loginData?.workspace_name,
            authToken: loginData?.authToken,
            is_google: loginData?.is_google,
          })
        );
        dispatch(setLoggedUserRolePermissionList(loginData?.role_permission_list));
        // dispatch(setLoggedUserDetails(loginData));
        if (handleState?.userStatus?.is_owner || (handleState?.userStatus?.view && handleState?.userStatus?.add)) {
          dispatch(setStepCount(3));
        } else {
          handleClose();
        }
      }
    } catch (error) {
      console.log('workspace switching error', error);
    }
  };
  const handleWorkSpaceSave = async () => {
    if (workspaceEditState?.editState) {
      try {
        // setLoading(true);
        setHandleState((prevState) => ({ ...prevState, loading: true }));

        const { workspace_logo, workspace_name } = workspace;
        const { editId, organization_id } = workspaceEditState;
        const params = {
          organization_id,
          uuid: editId,
          name: workspace_name,
          // logo: workspace_logo,
          ...(workspace_logo && { logo: workspace_logo }),
        };

        const response = await BaseApi.patch('/workspace', params);
        if (!response.error) {
          toast.success('WorkSpace Updated Successfully', toastState.success);
          dispatch(setModalView(false));
          dispatch(setWorkspaceEditState({ editState: false, editId: '' }));
          dispatch(setWorkspace({ file_name: '', workspace_logo: '', workspace_name: '' }));
        } else {
          dispatch(resetWorkspace());
          dispatch(setModalView(false));
        }
      } catch (error) {
        console.log('Organization creating error', error);
      } finally {
        setHandleState((prevState) => ({ ...prevState, loading: false }));

        // setLoading(false);
      }
    } else {
      try {
        // setLoading(true);
        setHandleState((prevState) => ({ ...prevState, loading: true }));

        const { workspace_logo, workspace_name } = workspace;
        // const params = {
        //   organization_id: organization_id || loggedUser?.organization_id,
        //   name: workspace_name,
        //   logo: workspace_logo,
        //   plan: 0,
        //   credits: 0,
        // };

        const params = {
          organization_id: organization_id || loggedUser?.organization_id,
          name: workspace_name,
          plan: 0,
          credits: 0,
          ...(workspace_logo && { logo: workspace_logo }),
        };

        const response = await BaseApi.post('/workspace', params);
        // console.log('responsewwwwwwwwwwwwwww', response);
        if (!response.error) {
          // if (!loggedUser?.organization_id) {
          dispatch(resetWorkspace());
          dispatch(setWorkspaceInviteId(response?.data?.insert_id));
          await handleSaveOrganizationAndWorkspace(organization_id || loggedUser?.organization_id, response?.data?.insert_id);
          // }

          // dispatch(setModalView(false));
          // dispatch(setFlagStatus(!flagStatus));
        } else {
          dispatch(resetWorkspace());
          dispatch(setModalView(false));
        }
      } catch (error) {
        console.log('Organization creating error', error);
      } finally {
        setHandleState((prevState) => ({ ...prevState, loading: false }));

        // setLoading(false);
      }
    }
  };
  const handleSendInviteUser = async () => {
    try {
      dispatch(setswitchOrganizationModalView(false));
      setHandleState((prevState) => ({ ...prevState, loading: true }));
      const apiResponse = await BaseApi.post('user/role/management', {
        ...handleState?.addUserData,
        workspace_access_ids: [workspaceInviteId],
        redirect_url: window.location.origin,
      });
      if (!apiResponse.error) {
        setUserSentDetails((prevState) => ({
          ...prevState,
          sent: true,
          users: [handleState?.addUserData, ...prevState.users],
        }));

        toast.success('Invite Sent Successfully', toastState.success);
        setHandleState((prevState) => ({
          ...prevState,
          addUserData: { role_access_id: '', username: '', workspace_access_ids: [loggedUser?.workspace_id], email: '', role_name: '' },
        }));

        setHandleState((prevState) => ({ ...prevState, loading: false }));
      } else {
        setUserSentDetails((prevState) => ({
          ...prevState,
          sent: false,
        }));
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      setUserSentDetails((prevState) => ({
        ...prevState,
        sent: false,
      }));

      console.error('Send Invite User error', error);
    }
  };
  const handleClose = () => {
    dispatch(resetWorkspace());
    dispatch(resetOrganization());
    dispatch(setModalView(false));
    setUserSentDetails((prevState) => ({
      ...prevState,
      sent: false,
      users: [],
    }));

    dispatch(setswitchOrganizationModalView(true));
  };

  const organizationDisable =
    organization.organization_name && organization.industry && organization.company_size && organization.occupation && organization.primary_goal ? false : true;

  const workspaceDisable = workspace.workspace_name ? false : true;

  const addUserDisable =
    handleState?.addUserData?.email && handleState?.addUserData?.role_access_id && handleState?.addUserData?.username && handleState?.addUserData?.workspace_access_ids?.length
      ? false
      : true;

  //fetching role in the addUser page useEffect
  React.useEffect(() => {
    loggedUser?.organization_id && loggedUser?.organization_id === organization_id && dispatch(setOrganizationId(loggedUser?.organization_id));
    const fetchRoleData = async () => {
      try {
        const response = await BaseApi.get(`/role/management`);
        if (response.data?.data?.length) {
          setHandleState((prevState) => ({
            ...prevState,
            roleData: response?.data?.data,
          }));
        } else {
          setHandleState((prevState) => ({
            ...prevState,
            roleData: [],
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    step_count === 3 && fetchRoleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_count]);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'user_management');
      setHandleState((prevState) => ({
        ...prevState,
        userStatus: status,
      }));
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step_count]);

  return (
    <Modal isOpen={modal_view} size={'auto'} onClose={loggedUser?.organization_id && loggedUser?.workspace_id && handleClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent className="h-[650px] !w-[58rem] dark:bg-darkThree">
        {loggedUser?.organization_id && loggedUser?.workspace_id && <ModalCloseButton className="close_btn_cross z-10 text-gray-700 dark:hover:text-red-500" />}
        <ModalBody className="!relative h-full !overflow-hidden !rounded-md   !bg-dottedModalPattern !bg-[length:35px_35px] !p-0 ">
          <div className="absolute -left-[57px] -top-[5px] h-[60px] w-[140px] -rotate-45 bg-blueSecondary"></div>
          <div className="absolute -bottom-[5px] -right-[58px] h-[60px] w-[140px] -rotate-45 bg-blueSecondary"></div>
          {/* Header */}
          <div className="mt-6 flex select-none flex-col items-center justify-center">
            <div className="rounded-lg p-2 text-blueSecondary shadow-inset shadow-gray-300">
              <AiFillThunderbolt size={22} />
            </div>

            {step_count === 1 ? (
              <h4 className="mt-2 text-2xl font-bold dark:text-gray-100">Let's set up your Organization</h4>
            ) : step_count === 2 ? (
              <h4 className="mt-2 text-2xl font-bold dark:text-gray-100">Let's set up your Workspace</h4>
            ) : (
              <h4 className="mt-2 text-2xl font-bold dark:text-gray-100">Let's set up your Users</h4>
            )}
            <span className="mt-2 text-xs text-column dark:text-gray-300">We'd love to help you get setup for success</span>
          </div>
          <div className="flex w-full select-none justify-center bg-cover px-8 pt-4">
            {/* <-------------------------- Organization ----------------------------->*/}
            {step_count === 1 ? (
              <div className="h-full w-3/6">
                <div className="flex flex-col gap-3">
                  {/* Logo */}
                  <div className="flex w-full items-center gap-3">
                    {/* Organization name */}
                    <div className="w-1/2">
                      <InputField
                        autoComplete="off"
                        customClass="!h-9 !mt-0"
                        inputClass="!mt-0.5 dark: !rounded-md !h-9 !bg-white"
                        labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                        label="Your Organization name"
                        placeholder="Enter Organization name"
                        id="organization_name"
                        type="text"
                        value={organization?.organization_name}
                        onChange={handleOrganizationChange}
                      />
                    </div>
                    <div className="w-1/2 ">
                      <h4 className="my-1 text-xs font-[500] text-paused-color dark:text-white ">Upload logo</h4>
                      <InputUploader
                        file_name={organization.file_name}
                        customClass="w-36 truncate"
                        setLoading={setLogoUploading}
                        logo = {organization?.upload_logo}
                        deleteLogo = {()=> handleUploadOrganizationLogo('upload_logo', '')}
                        onChange={(data: any) => {
                          handleUploadOrganizationLogo('upload_logo', data?.data?.data?.url);
                        }}
                      />
                    </div>
                  </div>
                  {/* Industry */}
                  <div>
                    <h4 className="mb-0.5 text-xs font-[500] text-paused-color dark:text-white">Industry</h4>
                    <CustomMenu
                      open={open.industry}
                      onOpen={() => setOpen((prev: any) => ({ ...prev, industry: true }))}
                      onClose={() => setOpen((prev: any) => ({ ...prev, industry: false }))}
                      btnContent={organization?.industry || 'select'}
                      btnClassName="w-full dark:!bg-darkThree justify-between !h-9 !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                      menuListClassName="w-full !mt-0.5 !p-0"
                      rightIcon={<BiChevronDown size={18} />}
                      btnId="industry_drop_down"
                    >
                      <div className="!max-h-48 overflow-y-auto">
                        <div className="w-11-/12 relative m-2 flex items-center rounded-md border dark:!bg-darkThree">
                          <FiSearch className="pointer-events-none absolute ml-2 text-gray-600 dark:bg-darkThree" size={14} />
                          <input
                            type="text"
                            placeholder="Search"
                            value={handleState?.searchIndustry}
                            onChange={handleSearchChange}
                            className="input input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none dark:bg-darkThree"
                          />
                        </div>
                        {filteredItems?.map((items, id) => (
                          <button
                            key={id}
                            onClick={() => handleSelect('industry', items?.name)}
                            id={items?.name}
                            className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                          >
                            {items?.name}
                          </button>
                        ))}
                      </div>
                    </CustomMenu>
                  </div>
                  {/* Business type */}

                  <div>
                    <h4 className="mb-0.5 text-xs font-[500] text-paused-color dark:text-white">Company size</h4>
                    <CustomMenu
                      open={open.company_size}
                      onOpen={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          company_size: true,
                        }))
                      }
                      onClose={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          company_size: false,
                        }))
                      }
                      btnContent={organization?.company_size || 'select'}
                      btnClassName="w-full justify-between !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                      menuListClassName="w-full !mt-0.5 !p-0"
                      rightIcon={<BiChevronDown size={18} />}
                      btnId="company_size_drop_down"
                    >
                      <button
                        id="company_size_0"
                        onClick={() => handleSelect('company_size', '0-10')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        0-10
                      </button>
                      <button
                        onClick={() => handleSelect('company_size', '11-50')}
                        className="text-coloumnhover:bg-lightPrimary w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        11-50
                      </button>
                      <button
                        onClick={() => handleSelect('company_size', '51-250')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        51-250
                      </button>
                      <button
                        onClick={() => handleSelect('company_size', '251-1000')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        251-1000
                      </button>
                      <button
                        onClick={() => handleSelect('company_size', '1000+')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        1000+
                      </button>
                    </CustomMenu>
                  </div>
                  {/* Job title */}
                  <div>
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="!mt-0.5 !rounded-md !h-9 !bg-white"
                      labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                      label="Job title"
                      placeholder="Enter job title"
                      id="occupation"
                      type="text"
                      value={organization?.occupation}
                      onChange={handleOrganizationChange}
                    />
                  </div>
                  {/* What is your primary goal ? */}
                  <div>
                    <h4 className="mb-0.5 text-xs font-[500] text-paused-color dark:text-white">What is your primary goal ?</h4>
                    <CustomMenu
                      open={open.primary_goal}
                      onOpen={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          primary_goal: true,
                        }))
                      }
                      onClose={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          primary_goal: false,
                        }))
                      }
                      btnContent={organization?.primary_goal || 'select'}
                      btnClassName="w-full justify-between !h-9 dark:!bg-darkThree !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                      menuListClassName="w-full !mt-0.5 !p-0"
                      rightIcon={<BiChevronDown size={18} />}
                      btnId="primary_goal_drop_down"
                    >
                      <button
                        id="primary_goal_0"
                        onClick={() => handleSelect('primary_goal', 'Generate leads')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Generate leads
                      </button>
                      <button
                        onClick={() => handleSelect('primary_goal', 'Multi channel outreach')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Multi channel outreach
                      </button>
                      <button
                        onClick={() => handleSelect('primary_goal', 'Track leads and conversions')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Track leads and conversions
                      </button>
                      <button
                        onClick={() => handleSelect('primary_goal', 'Others')}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Others
                      </button>
                    </CustomMenu>
                  </div>
                </div>
                <div className="mt-6">
                  <Button
                    name="Next"
                    id="organization_next_btn"
                    disable={organizationDisable || handleState?.loading || logouploading}
                    loading={handleState?.loading}
                    customClass="!text-[.875rem] w-full organization_next_btn dark:border-none active:scale-95 transition-transform duration-150 ease-out"
                    onClick={handleOrganizationSave}
                  />
                </div>
              </div>
            ) : step_count === 2 ? (
              //     <-------------------------- WorkSpace ----------------------------->
              <div className="flex h-full w-3/6 flex-col">
                <div className="mt-10 flex flex-col gap-3">
                  {/* Upload Workspace logo */}
                  <div>
                    <h4 className="!ml-0 mb-1 text-xs !font-[500] !text-paused-color dark:!text-white">Upload Workspace logo</h4>
                    <InputUploader
                      file_name={workspace.file_name?.match(/[^\/]+$/) || ''}
                      customClass="w-[60%] truncate"
                      setLoading={setLogoUploading}
                      onChange={(data: any) => {
                        handleUploadWorkspaceLogo('workspace_logo', data);
                      }}
                    />
                  </div>
                  {/* Workspace name */}
                  <div>
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="!mt-0.5 !rounded-md !h-9 !bg-white"
                      labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                      label="Workspace name"
                      placeholder="Enter Workspace name"
                      id="workspace_name"
                      type="text"
                      value={workspace?.workspace_name}
                      onChange={handleWorkspaceChange}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <Button
                    name={`${workspaceEditState?.editState ? 'Update' : 'Next '}`}
                    disable={workspaceDisable || handleState?.loading || logouploading}
                    loading={handleState?.loading}
                    customClass="!text-[.875rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out"
                    onClick={handleWorkSpaceSave}
                    id="workspace_next_btn"
                  />
                </div>
              </div>
            ) : (
              //     <-------------------------- Add Users ----------------------------->
              <div className="flex h-full w-3/6 flex-col">
                <div className="mt-5 flex flex-col gap-3">
                  {/* Add User Name Input Tag */}
                  <div>
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="!mt-0.5 !rounded-md !h-9 !bg-white"
                      labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                      label="Name"
                      placeholder="Enter user name"
                      id="username"
                      type="text"
                      value={handleState?.addUserData?.username}
                      onChange={handleAddUserRole}
                    />
                  </div>
                  {/* Add User Email Input Tag */}
                  <div>
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="!mt-0.5 !rounded-md !h-9 !bg-white"
                      labelClass="!text-paused-color !font-[500] !ml-0 !text-xs"
                      label="Email ID"
                      placeholder="Enter user email"
                      id="email"
                      type="email"
                      value={handleState?.addUserData?.email}
                      onChange={handleAddUserRole}
                    />
                  </div>

                  {/* Assign Role Input */}

                  <div>
                    <h4 className="mb-0.5 text-xs font-[500] text-paused-color dark:text-white">Assign Role</h4>
                    <CustomMenu
                      open={open.assign_role}
                      onOpen={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          assign_role: true,
                        }))
                      }
                      onClose={() =>
                        setOpen((prev: any) => ({
                          ...prev,
                          assign_role: false,
                        }))
                      }
                      btnContent={handleState?.addUserData?.role_name || 'select'}
                      btnClassName="w-full justify-between dark:!bg-darkThree overflow-auto !text-column !text-sm !rounded-md !font-normal truncate !bg-white"
                      menuListClassName="w-full !mt-1 !p-0  !h-28 overflow-auto"
                      rightIcon={<BiChevronDown size={18} />}
                      btnId="assign_role_drop_down"
                    >
                      {handleState?.roleData?.length ? (
                        handleState?.roleData.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setHandleState((prevState) => ({
                                ...prevState,
                                addUserData: {
                                  ...prevState.addUserData,
                                  role_access_id: item?.uuid,
                                  role_name: item?.role_name,
                                },
                              }));
                              setOpen((prev: any) => ({
                                ...prev,
                                assign_role: false,
                              }));
                              // setAddUserData((prev) => ({ ...prev, role_access_id: item?.uuid, role_name: item?.role_name }))
                            }}
                            className="text-coloumn w-full border-b p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:border-darkBorder dark:hover:text-blackDark"
                            id={`role_${index}`}
                          >
                            {item?.role_name}
                          </button>
                        ))
                      ) : (
                        <p className="text-coloumn w-full border-b p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark">No data available</p>
                      )}
                    </CustomMenu>
                  </div>
                </div>
                <div className="mt-6">
                  <Button
                    name="Send Invite"
                    id="send_invite_btn"
                    disable={addUserDisable || handleState?.loading}
                    loading={handleState?.loading}
                    customClass="!text-[.875rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out"
                    onClick={handleSendInviteUser}
                  />
                </div>
                <div className="mt-4 ">
                  {userSentDetails?.sent ? (
                    <div className="flex h-24 flex-col gap-2 overflow-auto rounded-lg border bg-white p-2 dark:border-darkBorder dark:bg-darkTwo ">
                      {userSentDetails?.users?.length > 0 &&
                        userSentDetails?.users?.map((item: any, index: number) => (
                          <div key={index} className="flex w-full items-center justify-between gap-2  text-sm">
                            <TbUser size={20} className="dark:text-gray-200" />
                            <p className=" w-2/4 truncate dark:text-gray-200">{item?.email}</p>
                            <p className="w-1/4 truncate dark:text-gray-200">{item?.role_name}</p>
                            <span className="flex w-1/4 items-center justify-end gap-1  font-semibold text-green-700 dark:text-green-500">
                              <MdOutlineMarkEmailRead size={22} className="" /> Invited
                            </span>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <button className="w-full !text-[.875rem] text-column dark:text-gray-300" onClick={handleClose} id="skip_and_continue_btn">
                      Skip & Finish
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="absolute bottom-5 mt-6 flex w-full select-none gap-5 px-12">
            <div className={`w-full ${step_count === 1 ? 'text-blueSecondary ' : 'dark:text-gray-300'}`}>
              <div className={`rounded-md p-[.1rem] ${step_count === 1 ? 'bg-blueSecondary ' : 'bg-gray-200'}`}></div>
              <div className="ml-0.5 mt-1 text-[13px]">Add Organization</div>
              <div className="ml-0.5 text-[11px]">Tell us about yourself</div>
            </div>
            <div className={`w-full ${step_count === 2 ? 'text-blueSecondary ' : 'dark:text-gray-300'}`}>
              <div className={`rounded-md p-[.1rem] ${step_count === 2 ? 'bg-blueSecondary ' : 'bg-gray-200'}`}></div>
              <div className="ml-0.5 mt-1 text-[13px]">Add Workspace</div>
              <div className="ml-0.5 text-[11px]">Invite people & manage teams</div>
            </div>
            <div className={`w-full ${step_count === 3 ? 'text-blueSecondary  ' : 'dark:text-gray-300'}`}>
              <div className={`rounded-md p-[.1rem] ${step_count === 3 ? 'bg-blueSecondary ' : 'bg-gray-200'}`}></div>
              <div className="ml-0.5 mt-1 text-[13px]">Add Users</div>
              <div className="ml-0.5 text-[11px]">Start collaborating with your team</div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrganizationAndWorkSpace;
