/* eslint-disable react-hooks/exhaustive-deps */
import { BaseApi } from 'common/api/common/base-api';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { CustomMenu } from 'components/customMenu';
import SearchBox from 'components/searchBox';
import React, { useEffect } from 'react';
import { useState } from 'react'
import { GoPencil } from 'react-icons/go';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoMdAdd } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';


interface EmailGroupProps {
  groupTags?: any[];
  row?: any;
  fetchList: () => void;
}

const EmailGroupTags = (props: EmailGroupProps) => {
  const { row, fetchList } = props
  const [groupTags, setGroupTags] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [showOpt, setShowOpt] = useState(false)
  const [groupList, setGroupList] = React.useState([])
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [groupsToggleState, setGroupToggleState] = useState<any>({})
  const [toggleduuid, setToggledUUID] = useState('')
  const [position, setPosition] = useState(null)
  const [searchedGroup, setSearchedGroup] = React.useState([])

  // Fetch groups when user search
  const fetchGroupList = async (query?: string) => {
    try {
      // setIsLoading(true)
      const { data } = await BaseApi.get(`/email/groups?${query || ''}`);
      if (data?.data && data?.data?.length) {
        const finalData = data?.data?.sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase()) ? -1 : (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : 0);
        setGroupList(finalData)
        setSearchedGroup(finalData)
        const states = data?.data?.reduce((acc: any, val: any) => { acc[val.uuid] = { show: false, rename: false }; return acc }, {})
        setGroupToggleState(states)
      } else {
        setGroupList([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const groups = props.groupTags?.filter((group) => group?.status !== 2)
    setGroupTags(groups.reverse())
    fetchGroupList();
  }, [props?.groupTags])

  // Delete function for group from email
  const handleDelteGroup = async (uuid: string) => {
    setGroupTags((prev) => { const updatedGroup = prev.filter((group) => group.uuid !== uuid); return updatedGroup })
    try {
      await BaseApi.delete(`email/groups/emails/accounts/delete/${uuid}/${row?.uuid}`)
    } catch (error) {
      console.log('Group removing from email error', error)
    }
  }

  // Tool tip component
  const ToolTipComponent = ({ groups }: { groups: { id?: string, name?: string, uuid?: string, status?: number }[] }) => {
    return (
      <div className='flex flex-col relative'>
        {groups.map((group: { id?: string, name?: string, uuid?: string, status?: number }, index: number) => (
          <>{
            <div key={index} className='flex justify-between group items-center'>
              <span className='p-1 text-xs cursor-default text-start text-gray-100'> {group.name}</span>
              <button className='text-sm hover:text-gray-500 px-2 hidden group-hover:block' onClick={() => handleDelteGroup(group.uuid)}>x</button>
            </div>
          }</>
        ))}
      </div>
    )
  }

  // On change function for search input
  const handleChange = (event: any) => {
    if (event.key === 'Enter') {
      // Check if the searched group name exist on groupList
      const groupName = groupList.some((val) => val?.name?.toLowerCase() === event?.target?.value?.toLowerCase())
      if (groupName) return
      const name = event?.target?.value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase());
      const params = { name, email_uuid: row?.uuid, }
      handleCreateGroup(params)
      setIsOpen(false)
    } else {
      const searchedValue = event?.target?.value?.toLowerCase()
      const searchedData = groupList.length && (searchedValue ? groupList?.filter((group) => group?.name?.toLowerCase()?.includes(searchedValue)) : groupList)
      setSearchedGroup(searchedData)
    }
  }

  // Create new group and assaign to email when user press enter keywork in search box
  async function handleCreateGroup(params: any) {
    try {
      const newGroup = { ...params, varient: 'email', status: 1, uuid: '123456' }
      setGroupList((prev:any) => { const updatedList = prev.length ? [newGroup, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase()) ? -1 : (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : 0) : [newGroup]; return updatedList })
      setSearchedGroup((prev:any) => { const updatedList = prev.length ? [newGroup, ...prev].sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase()) ? -1 : (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : 0) : [newGroup]; return updatedList })
      setGroupTags((prev:any) => ([newGroup, ...prev]))
      await BaseApi.patch('/email/groups/emails/accounts/create/and/update', params);
    } catch (error) {
      console.error(error)
    } finally {
      fetchList();
    }
  }
  const tooltipRef = React.useRef(null)
  const toggleRef = React.useRef(null)

  // OnBlur functionality for tooltip
  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) &&
        !toggleRef?.current?.contains(event.target)) {
        setShowOpt(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [tooltipRef]);

  // Click function for add new group name for email
  const handleClick = async (val: any) => {
    setGroupTags((prev) => {
      // Check if group name already exist
      const groupName = prev.some((group) => group.uuid === val.uuid)
      if (groupName) return prev
      return [val, ...prev]
    })
    const params = { group_uuid: val?.uuid, email_uuid: row?.uuid }
    try {
      await BaseApi.patch('/email/groups/emails/accounts/create/and/update', params);
    } catch (error) {
      console.error(error)
    }
  }

  const parentRef = React.useRef(null)

  // Component for email to edit and rename
  const EditBtnComponent = (props: { val: any, index: number }) => {
    const { val, index } = props
    const [value, setValue] = useState(val?.name)
    // Click function for three dot button
    const handlethreeDotBtn = async (event: any, uuid: string) => {
      event.stopPropagation()
      if (elementRef?.current) {
        const position = elementRef?.current?.getBoundingClientRect()
        setPosition(position)
      }
      try {
        setGroupToggleState((prev: any) => ({ ...prev, [uuid]: { ...prev[uuid], show: true } }))
      } catch (error) {
        console.log("error", error)
      } finally {
        setIsEditVisible(true);
        setToggledUUID(uuid)
      }
    };

    const editComponentRef = React.useRef(null)
    const toggleComponentRef = React.useRef(null)
    const elementRef = React.useRef(null)
    // OnBlur functionality for edit component
    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (editComponentRef?.current && !editComponentRef?.current.contains(event?.target) && !toggleComponentRef?.current?.contains(event.target)) {
          setIsEditVisible(false)
          setGroupToggleState((prev: any) => ({ ...prev, [toggleduuid]: { ...prev[toggleduuid], show: false } }))
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [editComponentRef]);

    // Click function for group delete button
    const handleDelete = async (uuid: string) => {
      setGroupList((prev) => { const updatedList = prev.filter((group) => group.uuid !== uuid); return updatedList })
      setSearchedGroup((prev) => { const updatedList = prev.filter((group) => group.uuid !== uuid); return updatedList })
      setGroupTags((prev) => { const updatedGroup = prev.filter((group) => group.uuid !== uuid); return updatedGroup })
      setGroupToggleState((prev: any) => ({ ...prev, [uuid]: { ...prev[uuid], show: false } }))
      setIsEditVisible(false);
      try {
        await BaseApi.delete(`/email/groups/${uuid}`)
      } catch (error) {
        console.log('Group delete error', error)
      }
    }
    // Click function for rename button
    const handleRename = (uuid: string) => {
      setGroupToggleState((prev: any) => ({ ...prev, [uuid]: { show: false, rename: true } }))
      setIsEditVisible(false);
    }

    const inputRef = React.useRef(null)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target?.value)
    }

    // Rename Group api hitting function
    const renameGroup = async (value: string) => {
      try {
        const params = { uuid: val?.uuid, name: value?.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase()) }
        setGroupList((prev) => { const updatedList = prev.map((group) => { if (group?.uuid === val?.uuid) return { ...group, name: params?.name }; return group }).sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase()) ? -1 : (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : 0); return updatedList })
        setSearchedGroup((prev) => { const updatedList = prev.map((group) => { if (group?.uuid === val?.uuid) return { ...group, name: params?.name }; return group }).sort((a: any, b: any) => (a?.name.toLowerCase() < b?.name.toLowerCase()) ? -1 : (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : 0); return updatedList })
        setGroupTags((prev) => { const updatedGroup = prev.map((group) => { if (group?.uuid === val?.uuid) return { ...group, name: params?.name }; return group }); return updatedGroup })
        setGroupToggleState((prev: any) => ({ ...prev, [val?.uuid]: { show: false, rename: false } }))
        await BaseApi.patch('/email/groups', params)
      } catch (error) {
        console.log('Group rename error', error)
      }
    }

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') renameGroup(value)
    }

    React.useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus()
      }
    }, [])

    useEffect(() => {
      if (editComponentRef?.current) {
        const childTop = position?.bottom + 9;
        const parentTop = parentRef?.current?.getBoundingClientRect()?.top
        const top = Math.floor(childTop - parentTop)
        editComponentRef.current.style.top = `${top}px`;
      }
    }, [editComponentRef, parentRef])
    // console.log('position', position)
    return <div ref={toggleComponentRef}>
      {groupsToggleState[val?.uuid]?.rename ?
        <input value={value} ref={inputRef} onChange={handleChange} id='rename-input' onKeyUp={handleEnter} className='font-bold outline-none border pl-3 pr-1 h-8 text-xs w-full text-textBlue focus:border-textBlue' onBlur={() => renameGroup(value)} />
        :
        <button onClick={() => handleClick(val)} ref={elementRef}
          className={`pl-3 pr-1 h-8 group hover:bg-gray-50 whitespace-nowrap flex items-center justify-between cursor-pointer duration-200 text-xs text-textBlue w-full border-b ${groupsToggleState[val?.uuid]?.show ? 'bg-gray-100' : ''}`} >
          <span className='font-bold h-full flex items-center' id={`group-${index}`}>{val?.name}</span>
          <HiOutlineDotsHorizontal id={`group-editbtn-${index}`} className='h-full font-bold text-gray-800 hidden group-hover:block w-7 p-1' onClick={(event) => handlethreeDotBtn(event, val?.uuid)} />
        </button>
      }
      {groupsToggleState[val?.uuid]?.show && <div ref={editComponentRef} className={`absolute bg-white border p-1 rounded-md -right-10 flex flex-col w-24 gap-2 shadow-md ${groupsToggleState[val?.uuid].show && 'block pointer-events-auto'}`}>
        <button id='delete-btn' className='text-red-600 text-sm flex gap-2 items-center px-2 py-1 rounded-md hover:bg-gray-50 duration-300' onClick={() => handleDelete(val?.uuid)}><RiDeleteBin6Line /><span>Delete</span></button>
        <button id='rename-btn' className='text-sm flex gap-2 items-center px-2 py-1 rounded-md hover:bg-gray-50 duration-300' onClick={() => handleRename(val?.uuid)}><GoPencil /><span>Rename</span></button>
      </div>
      }
    </div>
  }

  const onClose = () => {
    setIsOpen(false);
    setIsEditVisible(false)
    fetchGroupList()
  }

  const AddBtn = () => (<div className='group'><IoMdAdd size={11} /><ArrowedTooltip data='Add Group' className='!w-16 hidden group-hover:block' /></div>)
  return (
    <div className='flex gap-2 items-center' key={row?.uuid}>
      <CustomMenu open={isOpen} condition={!isEditVisible && !groupsToggleState[toggleduuid]?.rename} onOpen={() => setIsOpen(true)} onClose={onClose} btnContent={<AddBtn />} btnClassName={`Add-Group-${row?.uuid} border-dotted p-0.5 text-gray-700 rounded-full hover:border-gray-500 duration-300 border-2 border-gray-300 cursor-pointer`} menuListClassName={`border w-60 border-menuBorder rounded-md ${isEditVisible ? 'pointer-events-none' : ''}`}>
        <div ref={parentRef}>
          <SearchBox id='group-search-input' placeholder='Search or create groups' onChange={handleChange} />
          {searchedGroup?.length ?
            <div className='mb-2 max-h-48 overflow-y-auto'>
              {searchedGroup?.map((val, key) => (
                <EditBtnComponent key={key} val={val} index={key} />
              ))}
            </div>
            :
            <span className='pl-1 text-xs text-gray-700 text-center w-full'>Press enter to create new group</span>
          }
        </div>
      </CustomMenu>
      {groupTags?.slice(0, 2)?.map((val, key) => (
        <>{
          <div key={key} className={`relative group py-0.5 px-2 ${key === 0 ? 'bg-[#EFF8FF]' : 'bg-[#F4F3FF]'}  text-xs ${key === 0 ? 'text-[#175CD3]' : 'text-[#6941C6]'} whitespace-nowrap  font-[500] rounded-full w-min cursor-default`} >
            <span id={`added-group-${key}`}>{val.name}</span>
            <span id={`delete-group-${key}`} className={`absolute hidden group-hover:inline-block text-sm top-0 hover:cursor-pointer px-1 right-1 rounded-full ${key === 0 ? 'bg-[#e2eff9]' : 'bg-[#edecfd]'}`} onClick={() => handleDelteGroup(val?.uuid)}>x</span>
          </div>
        }</>
      ))}
      {groupTags?.length > 2 && <div className='relative' ref={toggleRef}>
        <span className='flex text-xs justify-center items-center px-2 rounded-full cursor-pointer bg-gray-50 hover:bg-gray-100 font-semibold' onClick={() => setShowOpt((prev) => (!prev))}>+{groupTags.length - 2}</span>
        {showOpt &&
          <div ref={tooltipRef}>
            <ArrowedTooltip data={<ToolTipComponent groups={groupTags?.slice(2)} />} className='!-left-10' />
          </div>
        }
      </div>}
    </div >
  )
}
export default EmailGroupTags