/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader, ModalFooter } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import { CustomMenu } from 'components/customMenu';
import Toggle from 'components/Toggle';
import { Switch } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { setNotificationData, setNotificationType } from 'store/notification-settings';

interface NotificationPropsDto {
  isOpen?: boolean;
  onClose?: () => void;
}

interface AccordionState {
  activeIndex: number | null;
  switches: {
    [accordionIndex: number]: {
      [keyIndex: number]: boolean;
    };
  };
}

const NotificationSettingsPopup = (props: NotificationPropsDto) => {
  const { onClose, isOpen } = props;
  const { notificationSettingsData, notificationSettingsType } = useAppSelector((state) => state.notificationSettings);

  const [rowIndex, setRowIndex] = useState([]);
  const [openAccordion, setOpenAccordion] = useState<AccordionState>({
    activeIndex: null,
    switches: {},
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    const initializeSettings = async () => {
      const switches: AccordionState['switches'] = {
        0: {
          0: notificationSettingsData?.campaign_pause,
          1: notificationSettingsData?.email_limit_reached,
          2: notificationSettingsData?.holiday_alert,
          3: notificationSettingsData?.email_disconnected,
          4: notificationSettingsData?.whatsapp_disconnected,
          5: notificationSettingsData?.linkedin_disconnected,
          //   6: notificationSettingsData?.twitter_disconnected,
          6: notificationSettingsData?.task_assigned,
          // 7: notificationSettingsData?.prospects_replied,
          7: notificationSettingsData?.email_verification,
        },
        1: {
          0: notificationSettingsData?.ticket_resolved,
          1: notificationSettingsData?.ticket_replied,
          2: notificationSettingsData?.blacklist_notify,
        },
        2: {
          0: notificationSettingsData?.credits_over,
          1: notificationSettingsData?.billing_end_alert,
          2: notificationSettingsData?.billing_over,
          3: notificationSettingsData?.credits_recharge,
          4: notificationSettingsData?.billing_recharge,
          5: notificationSettingsData?.trail_ended,
        },
        // 3: {
        //   0: notificationSettingsData?.blog,
        // },
        3: {
          0: notificationSettingsData?.user_accept_invite,
        },
      };

      setOpenAccordion((prevState) => ({
        ...prevState,
        switches,
      }));
    };

    initializeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleAccordionClick = (index: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      activeIndex: prevState.activeIndex === index ? null : index,
    }));
  };

  const handleSwitchToggle = (accordionIndex: number, keyIndex: number) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      switches: {
        ...prevState.switches,
        [accordionIndex]: {
          ...prevState.switches[accordionIndex],
          [keyIndex]: !prevState.switches[accordionIndex]?.[keyIndex],
        },
      },
    }));
  };
  const accordionData = [
    {
      title: 'Campaign pause',
      description: 'Assigned comments Resolved comments Comment assigned to me',
      index: 0,
      keys: [
        'Campaign paused!',
        'Email Limit Reached',
        'Holiday alert',
        'Email disconnected',
        'WhatsApp disconnected',
        'LinkedIn disconnected',
        'Task assigned',
        'Email verification',
      ],
    },
    {
      title: 'Tickets',
      index: 1,
      description: 'Assigned changes Recurring notifications Status changes',
      keys: ['Ticket resolved', 'Ticket replied', 'Blacklist notification'],
    },
    {
      title: 'Billing & credits',
      index: 2,
      description: 'Due Date changes  Due Date overdue Start date changes',
      keys: ['Credits over', 'Billing end alert', 'Billing over', 'Credits recharge', 'Billing recharge', 'Trail ended'],
    },
    // {
    //   title: 'Feature updates',
    //   index: 3,
    //   description: 'Manage feature update settings',
    //   keys: ['Blog', 'Feature update', 'LinkedIn post', 'Tools demo'],
    // },
    {
      title: 'User management',
      index: 3,
      description: 'Manage user settings',
      keys: ['Invite accepted'],
    },
  ];

  const handleCommonSwitchToggle = (accordionIndex: number) => {
    const allOn = Object.values(openAccordion.switches[accordionIndex]).every((value) => value);
    setOpenAccordion((prevState) => ({
      ...prevState,
      switches: {
        ...prevState.switches,
        [accordionIndex]: Object.keys(prevState.switches[accordionIndex]).reduce((acc, keyIndex) => {
          acc[parseInt(keyIndex)] = !allOn; // Toggle all switches based on the current state
          return acc;
        }, {} as { [keyIndex: number]: boolean }),
      },
    }));
  };
  const isCommonSwitchOn = (accordionIndex: number) => {
    const switches = openAccordion.switches[accordionIndex];
    if (!switches) {
      return false;
    }
    return Object.values(switches).every((value) => value);
  };

  const handleSave = async () => {
    const settings = {
      campaign_pause: openAccordion.switches[0][0],
      email_limit_reached: openAccordion.switches[0][1],
      holiday_alert: openAccordion.switches[0][2],
      email_disconnected: openAccordion.switches[0][3],
      whatsapp_disconnected: openAccordion.switches[0][4],
      linkedin_disconnected: openAccordion.switches[0][5],
      task_assigned: openAccordion.switches[0][6],
      // prospects_replied: openAccordion.switches[0][7],
      email_verification: openAccordion.switches[0][7],
      ticket_resolved: openAccordion.switches[1][0],
      ticket_replied: openAccordion.switches[1][1],
      blacklist_notify: openAccordion.switches[1][2],
      credits_over: openAccordion.switches[2][0],
      billing_end_alert: openAccordion.switches[2][1],
      billing_over: openAccordion.switches[2][2],
      credits_recharge: openAccordion.switches[2][3],
      billing_recharge: openAccordion.switches[2][4],
      trail_ended: openAccordion.switches[2][5],
      user_accept_invite: openAccordion.switches[3][0],
    };

    try {
      const response = await BaseApi.patch(`/notification/${notificationSettingsType}`, {
        setting_data: settings,
      });

      if (response?.error) {
        toast.error('error while updating', toastState.error);
      } else {
        toast.success('successfully updated', toastState.success);
        dispatch(setNotificationData(null));
        dispatch(setNotificationType(null));
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
          <ModalHeader className="mb-2 flex items-center justify-between !px-0 dark:bg-darkOne">
            <div className="items-center space-y-1 px-4 ">
              <h5 className="text-[18px] font-medium capitalize text-[#1E1E1E] dark:text-white">
                {notificationSettingsType ? notificationSettingsType?.split('_')[0] : 'Settings'}
              </h5>
              <p className="text-sm font-normal text-column dark:text-gray-600 ">
                Configure custom notifications settings for <span>{notificationSettingsType?.split('_')[0]}</span>
                <br /> You can find advanced options under each section.
              </p>
            </div>
            <ModalCloseButton size={'lg'} color={'#667085'} className="mr-3 scale-75 transform self-center text-[32px] hover:bg-white" />
          </ModalHeader>
          <hr className="-mt-2 dark:border-darkBorder" />
          <ModalBody className="mb-3 flex max-h-[25.5rem] cursor-pointer gap-4  overflow-y-auto  rounded-lg px-6 py-4">
            <div>
              <div className="mt-2 flex flex-col items-center justify-center gap-1 ">
                {accordionData.map((item, accordionIndex) => (
                  <div className=" mb-3 w-[610px] rounded-lg border border-[#E5E5E5] px-6 py-2 dark:border-darkBorder ">
                    <div
                      key={accordionIndex}
                      className="mb-5 flex cursor-pointer items-center gap-4 "
                      onClick={(e) => {
                        handleAccordionClick(item?.index);
                      }}
                    >
                      <div className="flex w-full items-center">
                        <Toggle
                          className="scale-50"
                          checkedCondition={isCommonSwitchOn(item?.index) || false} // Common switch condition
                          changeFunction={(e: any) => {
                            e.stopPropagation();
                            handleCommonSwitchToggle(item?.index); // Common switch toggle
                          }}
                        />
                        <div className="grow items-start">
                          <p className="font-semibold text-heading dark:text-white">{item.title}</p>
                          {openAccordion.activeIndex !== item.index && <div className="text-sm text-column dark:text-gray-600">{item.description}</div>}
                        </div>
                        <div>
                          <BiChevronDown />
                        </div>
                      </div>
                    </div>
                    {openAccordion.activeIndex === item.index && (
                      <div>
                        <div className="ml-14 mt-3 text-sm text-column">
                          {item.keys.map((key, keyIndex) => (
                            <div key={keyIndex} className="flex items-center gap-6">
                              <span className="grow font-semibold text-[#2B3674]">{key}</span>
                              <div className="-mr-5">
                                <Toggle
                                  className="scale-50 "
                                  checkedCondition={openAccordion.switches[item?.index]?.[keyIndex] || false}
                                  changeFunction={(e: any) => {
                                    e.stopPropagation();
                                    handleSwitchToggle(item?.index, keyIndex);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
          <hr className="dark:border-darkBorder" />
          <ModalFooter>
            <div className="flex w-full justify-center gap-3 px-6 pb-1">
              <Button onClick={() => onClose()} type="button" name="Cancel" fontSize="sm" px="px-6" customClass=" font-medium text-sm w-1/2 !bg-[#fff] !text-[#344054]" />
              <Button
                type="button"
                name="Save"
                fontSize="sm"
                px="px-6"
                customClass="text-column font-medium text-sm w-1/2 dark:border-none"
                onClick={() => {
                  handleSave();
                }}
              />
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationSettingsPopup;
