/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';

import { KeyValuePair } from 'common/dto/types/views/email-verifier';
import Button from 'common/sparkle-common/Button';
import ImageFileUploader from 'common/sparkle-common/ImageFileUploader';
import { _getQueryParams, toastState } from 'common/utils/utility';

import InputField from 'components/fields/InputField';
import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { onCrmOpportunitiesUpdated } from 'store/crm';
import CampaignSearchForSingleLead from './CampaignSearch';
import { setUpdateSettingsData } from 'store/global-settings';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
  uploader?: Boolean;
  textColor?: string;
  is_campaign_required?: Boolean;
  editData?: any;
}

const UploadSingleLead = (props: ModalComponentProps) => {
  const getQueryIds = _getQueryParams('_id');
  const brandId = getQueryIds?.brand_id;
  const PipelineId = getQueryIds?.pipeline_id;
  // const brandId = useAppSelector((state) => state.crm.brandId);
  //const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onClose, isOpen, uploader, is_campaign_required, textColor, editData } = props;
  const [formData, setFormData] = React.useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    linked_in: '',
    company_name: '',
    comapny_domain: '',
    job_title: '',
    email: editData ? editData?.platform_message_details?.from?.address : '',
    campaign_name: '',
    campaign_id: '',
    brand_id: '',
    profile: {
      file_data: '',
      file_name: '',
    },
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUpload = (data: any) => {
    console.log(data);
    setFormData({
      ...formData,
      profile: data,
    });
  };

  const handleImageDelete = () => {
    setFormData({
      ...formData,
      profile: {
        file_data: '',
        file_name: '',
      },
    });
  };

  const handleSubmit = async () => {
    if (!formData.first_name || !formData.last_name || !formData.email) {
      toast.error('Please fill in all required fields.', toastState.error);
      return;
    }
    const requestData: any = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      prospect_email: formData.email,
      prospect_name: `${formData.first_name} ${formData.last_name}`,
    };

    if (formData.phone_number) {
      requestData.phone_number = formData.phone_number;
    }
    if (formData.linked_in) {
      requestData.linked_in = formData.linked_in;
    }
    if (formData.company_name) {
      requestData.company_name = formData.company_name;
    }
    if (formData.comapny_domain) {
      requestData.comapny_domain = formData.comapny_domain;
    }
    if (formData.job_title) {
      requestData.job_title = formData.job_title;
    }
    if (formData.profile?.file_data && formData.profile?.file_name) {
      requestData.profile = formData.profile;
    }

    if (formData?.campaign_id) {
      requestData.campaign_id = formData.campaign_id;
      requestData.campaign_name = formData.campaign_name;
      requestData.brand_id = formData.brand_id;
      requestData.subject = editData?.platform_message_details?.subject;
      requestData.main_message_id = editData?.platform_message_details?.main_message_id;
    }
    try {
      const payload = {
        brand_id: editData ? requestData.brand_id : brandId,
        prospect_email: editData?.platform_message_details?.prospect_email,
        pipeline_id: PipelineId,
        prospect_email_list: [requestData],
      };
      let response;
      if (editData) {
        response = await BaseApi.post(`/unmatched/inbox/create/opportunity`, payload);
      } else {
        response = await BaseApi.post(`/crm/pipeline/opportunity`, payload);
        dispatch(setUpdateSettingsData('billings'));
      }
      if (response?.status === 200) {
        toast.success(response?.message, toastState.success);
        onClose();
        dispatch(onCrmOpportunitiesUpdated());
      } else {
        toast.error(`Please upload prospects with proper email id`, toastState.error);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
    console.log(formData);
  };

  const handleCampaignFormData = async (name: string, uuid: string, brand_id: string) => {
    setFormData({
      ...formData,
      campaign_name: name,
      campaign_id: uuid,
      brand_id: brand_id,
    });
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'2xl'}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent className="dark:bg-darkTwo">
        <ModalHeader className="!pb-1.5 !ps-3">
          <div className="space-y-1 px-4">
            <h5 className={`text-base font-semibold ${textColor && '!text-column'} text-heading dark:text-purple-400`}>Create new opportunity</h5>
          </div>
        </ModalHeader>
        <ModalBody className="!px-0 !pt-0">
          <div className="max-h-[80vh] overflow-auto">
            <div>
              <div className={`grid grid-cols-2 px-4  `}>
                <div className="flex flex-col px-3 !pt-1">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>First Name*</p>
                  <InputField
                    id="first-name"
                    autoComplete="off"
                    placeholder="First name"
                    label=""
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    name="first_name"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex  flex-col  justify-between px-3 !pt-1">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#101013]'} dark:text-purple-400`}>Last Name*</p>
                  <InputField
                    autoComplete="off"
                    id="last-name"
                    name="last_name"
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    placeholder="Last name"
                    label=""
                    onChange={handleChange}
                  />
                </div>
                <div className="flex  flex-col  justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>Phone Number</p>
                  <InputField
                    autoComplete="off"
                    name="phone_number"
                    inputClass="h-[40px] !rounded-md !p-2.5 text-[#667085]"
                    placeholder="Phone number"
                    label=""
                    onChange={handleChange}
                  />
                </div>
                <div className="flex  flex-col  justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>LinkedIn URL</p>
                  <InputField
                    autoComplete="off"
                    name="linked_in"
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    placeholder="e.g https://www.example.com"
                    label=""
                    onChange={handleChange}
                  />
                </div>
                <div className="flex  flex-col justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>Company Name</p>

                  <InputField
                    autoComplete="off"
                    placeholder="Company name"
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    label=""
                    name="company_name"
                    onChange={handleChange}
                    id="comapany"
                  />
                </div>
                <div className="flex flex-col justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>Company Domain</p>
                  <InputField
                    id="company-domain"
                    autoComplete="off"
                    placeholder="e.g www.example.com"
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    label=""
                    name="comapny_domain"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex  flex-col justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>Job title</p>
                  <InputField id="job" autoComplete="off" placeholder="Job title" inputClass="h-[40px] !rounded-md !p-2.5" label="" name="job_title" onChange={handleChange} />
                </div>
                <div className="flex flex-col justify-between px-3 pt-3">
                  <p className={`text-base font-semibold text-[#2B3674] ${textColor && ' !text-sm !text-[#959ab9]'} dark:text-purple-400`}>Email*</p>
                  <InputField
                    id="email"
                    autoComplete="off"
                    placeholder="e.g hello@gmail.com"
                    inputClass="h-[40px] !rounded-md !p-2.5"
                    label=""
                    name="email"
                    onChange={handleChange}
                    defaultValue={editData?.platform_message_details?.from?.address}
                    disabled={editData ? true : false}
                  />
                </div>
              </div>
            </div>
            {is_campaign_required && (
              <div className="w-full px-6 py-3">
                <CampaignSearchForSingleLead handleFormData={handleCampaignFormData} />
              </div>
            )}
            {uploader ? (
              <div className="w-full px-4 py-3">
                <p className="font-semibold text-[#2B3674] dark:text-purple-400">Upload prospect image</p>
                <ImageFileUploader
                  onUpload={handleImageUpload}
                  customClass="!h-[164px] !w-full  bg-white  hover:bg-[#F5F8FF] rounded-[10px] border-[1px]  "
                  disable={formData?.profile.file_name && formData?.profile.file_data ? true : false}
                />
              </div>
            ) : null}
            {formData?.profile.file_name && formData?.profile.file_data && (
              <>
                <div className="mt-3 px-4">
                  <div className="flex gap-3 rounded-md border p-2">
                    <div className=" flex w-full">
                      <div className="flex w-full items-center gap-2 text-xs">
                        <img src={formData?.profile?.file_data} alt={formData?.profile?.file_name} className="h-16 w-16 rounded" />
                        <h4 className="font-medium text-paused-color">{formData?.profile?.file_name}</h4>
                      </div>
                      <div className="h-fit w-fit cursor-pointer rounded-md p-1.5 text-column hover:bg-gray-50" onClick={handleImageDelete}>
                        <FiTrash2 />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex w-full gap-4 px-12 pb-3 pt-5">
              <Button
                type="button"
                name="cancel"
                fontSize="sm"
                px="px-6"
                color="text-column"
                onClick={() => onClose()}
                customClass="text-column font-medium text-base w-1/2 !bg-white"
              />
              <Button
                id="submit-single-lead"
                type="button"
                name="Upload"
                fontSize="sm"
                px="px-6"
                onClick={() => handleSubmit()}
                customClass="text-column dark:border-none font-medium text-sm w-1/2"
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadSingleLead;
