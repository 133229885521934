import React, { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'

import useClickOutsideHook from 'common/hooks/useClickOutsideHook'
import { useAppSelector } from 'store'
import { setSchedule } from 'store/campaign-v2'
import TimeZoneList from "assets/json/world-timezone-all-list.json"

const TimeZones = () => {
  const [timeZoneModal, setTimeZoneModal] = useState(false)
  const [search, setSearch] = useState('')
  const optionsRef = useClickOutsideHook(() => setTimeZoneModal(false))
  const schedule = useAppSelector(state => state?.campaignv2.schedule)
  const dispatch = useDispatch()
  return (
    <div className='px-3 py-3'>
      <h5 className='text-[0.85rem] text-[#667085] font-medium'>Time zone</h5>
      <div className='relative'>
        <div className='w-full border rounded-md border-[#D0D5DD] py-1 px-2.5 text-[0.8rem] text-[#667085] flex justify-between items-center' id='time_zone_options' onClick={() => setTimeZoneModal(!timeZoneModal)}>
          <h5 className='text-[0.7rem]'>{schedule?.time_zone || 'Select Time Zone'}</h5>
          <FaAngleDown className='size-2 font-medium' />
        </div>
        {timeZoneModal &&
          <div className='flex z-10 flex-col border absolute w-full border-blueSecondary rounded-md focus:border-blueSecondary p-1 pt-2 bg-white gap-1' ref={optionsRef} >
            <input type="text" id='time_zone_search' className='w-full h-5 text-heading appearance-none outline-none border border-gray-400 px-2 text-[0.8rem] font-medium' value={search} onChange={(e) => setSearch(e.target.value)} />
            <select size={4} name="" id='select_zones' className='w-full z-10 pl-1 outline-none' value={schedule?.time_zone} >
              {TimeZoneList.filter(zone => zone?.name?.toLowerCase().includes(search.toLowerCase())).map((zone, id) => <option id={`zone_${id}`} className={`py-1.5 px-0 mx-0 text-[0.7rem] font-semibold hover:bg-brand-500 hover:text-white checked:bg-brand-500 checked:text-white cursor-pointer`} onClick={(e: React.MouseEvent<HTMLOptionElement>) => { setTimeZoneModal(false); dispatch(setSchedule({ key: 'time_zone', value: e.currentTarget.value })) }} key={id} value={zone?.name}>{zone?.name + ' (' + zone?.utc_offset + ' )'}</option>)}
            </select>
          </div>
        }
      </div>
    </div>
  )
}

export default TimeZones