import { TabProps, useTab, Tab } from "@chakra-ui/react";

interface CustomTabProps extends TabProps {
    children: React.ReactNode;
    onClick?: () => void
  }
  
  const CustomTab = (props: CustomTabProps) => {
    const tabProps = useTab(props);
    const isSelected = !!tabProps['aria-selected'];
    return (
      <Tab
        {...tabProps}
        className={`!text-xs !font-semibold ${isSelected ? '!bg-buttonBackground !text-blueSecondary' : '!text-column'
          }`}
      >
        {tabProps.children}
      </Tab>
    );
  };

  export default CustomTab