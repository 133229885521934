import React from 'react'
import { FiTrash2, FiUploadCloud } from 'react-icons/fi'

import { BaseApi } from 'common/api/common/base-api'
import Button from 'common/sparkle-common/Button'
import FileUploader from 'common/sparkle-common/FileUploader'
import { useAppDispatch, useAppSelector } from 'store'
import { setProspects, setVariableList } from 'store/campaign-v2'
import { setCurrentStepCount } from 'store/custom'
import { toast } from 'react-toastify'
import { _errorHandler, toastState } from 'common/utils/utility'

const ProspectManualUploader = () => {
  const dispatch = useAppDispatch()
  const { prospects, ids, campaign_name } = useAppSelector((state) => state.campaignv2)
  const [loading, setLoading] = React.useState(false)
  const handleBack = () => {
    dispatch(setCurrentStepCount(1));
  }

  const handleContinue = async () => {
    try {
      setLoading(true)
      const response = await BaseApi.post('/map/variables/linked/campaign/list', { campaign_id: ids.campaign_id, campaign_name })
      if (!response.error) {
        dispatch(setVariableList(response?.data || []))
        dispatch(setCurrentStepCount(3));
      }
      else {
        toast.error(_errorHandler(response?.message), toastState.error)
      }
    } catch (error) {
      console.log('Fetching variables error', error)
    } finally {
      setLoading(false)
    }

  }
  const handleDeleteFile = async () => {
    try {
      await BaseApi.delete(`/campaign/prospects/bulk/delete/${ids?.campaign_id}`);
      dispatch(setProspects({ file_name: '', prospects_list: [], uploaded_prospects_list: [], variable_list: [], selected_variable: {} }))
    }
    catch (err) {
      console.error(err)
    }
    // dispatch(setIds({ prospects_id: '' }))
  }

  const handleUpload = (data: { file_name: string, file_data: { [key: string]: string } }) => {
    const variableInitialValue = Object.keys(data.file_data[0]).reduce((acc: any, key: string) => {
      acc[key] = { name: 'select', variable: 'select' };
      return acc;
    }, {});
    dispatch(setProspects({ uploaded_prospects_list: data.file_data, file_name: data.file_name, selected_variable: variableInitialValue }))
  }
  return (
    <div className='flex justify-center items-center h-[80vh]'>
      <div className='bg-white dark:bg-darkTwo dark:border-2 dark:border-darkBorder shadow-md p-5 rounded-md w-[40.625rem]'>
        <div className='flex flex-col'>
          <h4 className='text-heading font-semibold text-sm dark:text-white'>Prospect upload</h4>
          <span className='text-[11px] text-column dark:text-white'>Upload CSV file</span>
        </div>
        <FileUploader onUpload={handleUpload} disable={prospects?.uploaded_prospects_list?.length ? true : false} />
        {
          prospects?.uploaded_prospects_list?.length ?
            <div className='mt-6'>
              <div className='border p-2 flex gap-3 rounded-md'>
                <div className='p-2 bg-lightpurple rounded-full h-fit w-fit'><FiUploadCloud size={12} /></div>
                <div className=' w-full flex'>
                  <div className='w-full text-xs'>
                    <h4 className='text-paused-color font-medium'>{prospects?.file_name}</h4>
                    <span className='text-column'>{prospects?.uploaded_prospects_list?.length} Rows</span>
                  </div>
                  <div className='w-fit p-1.5 rounded-md hover:bg-gray-50 h-fit text-column cursor-pointer' onClick={handleDeleteFile}><FiTrash2 /></div>
                </div>
              </div>
              <div className='flex gap-4 mt-2'>
                <Button name='Back' customClass='w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]' onClick={handleBack} />
                <Button id='prospect_upload_continue_btn' name='Continue' customClass='w-full text-[.82rem]' disable={loading} loading={loading} onClick={handleContinue} />
              </div>
            </div>
            : <></>
        }
      </div>
    </div>
  )
}

export default ProspectManualUploader