import { Avatar, Spinner } from '@chakra-ui/react';
import Button from 'common/sparkle-common/Button';
import { BaseApi } from 'common/api/common/base-api';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { setChatText, setTotalMessages, setTotalMessagesCount } from 'store/true-verifier';
import FileAttach from './FileAttach';
import { FaFileDownload } from 'react-icons/fa';
import saveAs from 'file-saver';
import { generateRandomReportNumber } from 'common/utils/utility';

const ChatOptions = () => {
  const chatRef = useRef(null);
  const state = useAppSelector((state) => state?.trueVerifier);
  const dispatch = useDispatch();
  const [messageLoading, setMessageLoading] = React.useState({
    messagesFetching: false,
    sending: false,
  });

  // const date = new Date()
  async function getChat() {
    try {
      const response = await BaseApi.get(`/true/verify/external/user/chat/message/${state?.ticketId}`);
      response?.data?.data?.reverse();
      // const totalMessages: any[] = []
      // response.data.data.forEach((val: any) => {
      //     const date = new Date(val?.cdate)
      //     const chatDate = ((date.getHours() > 12) ? (date.getHours() - 12).toString().padStart(2, '0') : date.getHours().toString().padStart(2, '0')) + ":" + date.getMinutes().toString().padStart(2, '0') +
      //         ((date.getHours() < 12) ? 'AM' : 'PM')
      //     totalMessages.push({ message: val?.message, user_type: val?.user_type, cdate: chatDate })
      // })
      if (state?.totalMessagesCount !== response?.data?.total_records?.count) {
        const totalMessages = response?.data?.data?.map((message: any) => {
          const date = moment(message?.cdate).format('DD MMM YYYY, hh:mm A');
          //     const chatDate = ((date.getHours() > 12) ? (date.getHours() - 12).toString().padStart(2, '0') : date.getHours().toString().padStart(2, '0')) + ":" + date.getMinutes().toString().padStart(2, '0') +
          //         ((date.getHours() < 12) ? 'AM' : 'PM')
          return {
            message: message?.message,
            user_type: message?.user_type,
            ticket_type: message?.ticket_type,
            ticket_emails: message?.ticket_emails,
            emails: message?.emails,
            cdate: date,
          };
        });
        dispatch(setTotalMessages(totalMessages));
        dispatch(setTotalMessagesCount(response?.data?.total_records?.count));
      }
    } catch (err) {
      console.log('Error occured while collecting chat history', err);
    }
  }

  const sendMessage = async () => {
    try {
      const params = {
        ticket_uuid: state?.ticketId,
        message: state?.chatText,
        ticket_type: 'chat',
      };
      setMessageLoading((prev) => ({ ...prev, sending: true }));
      await BaseApi.post('/true/verify/internal/user/chat/message', params);
      await getChat();
      dispatch(setChatText(''));
    } catch (err) {
      console.log('Error occured while sending message', err);
    } finally {
      setMessageLoading((prev) => ({ ...prev, sending: false }));
    }
  };
  React.useEffect(() => {
    (async () => {
      setMessageLoading((prev) => ({ ...prev, messagesFetching: true }));
      await getChat();
      setMessageLoading((prev) => ({ ...prev, messagesFetching: false }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scrolling functionality
  React.useEffect(() => {
    if (!messageLoading?.messagesFetching && chatRef?.current) {
      chatRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [state?.totalMessagesCount, messageLoading?.messagesFetching]);

  React.useEffect(() => {
    const chatInterval = setInterval(() => {
      if (state?.ticketId) {
        getChat();
      }
    }, 5000);
    return () => clearInterval(chatInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const convertArrayOfObjectsToCSV = (array: any): string => {
    if (array.length === 0) {
      return '';
    }
    const headers = Object.keys(array[0]).join(',');
    const rows = array.map((obj: any) => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  const handleDownload = async (report: any) => {
    const downloadData = report.map((item: any) => {
      const transformed: any = {};
      for (const key in item) {
        if (key !== 'id') {
          transformed[key.charAt(0).toUpperCase() + key.slice(1)] = item[key];
        }
      }

      return transformed;
    });
    const csvContent = convertArrayOfObjectsToCSV(downloadData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${generateRandomReportNumber()}.csv`);
  };

  const buttonDisabled = messageLoading?.messagesFetching ? true : state?.chatText ? messageLoading?.sending : true;
  return (
    <div id="chat-portal" className="flex h-full flex-col justify-between rounded-md bg-field px-5 py-3">
      <div className="flex h-96 flex-col space-y-4 overflow-auto">
        {/* User Message */}
        {/* <div className='flex gap-2.5'>
            <Avatar size={'sm'}/>
            <div className='flex flex-col gap-1 w-10/12'>   
                <div className='flex justify-between'>
                    <h5 className='text-xs text-heading'>User 1</h5>
                    <p className='text-xs text-column'>Today 2:20pm</p>
                </div>
                <p className='text-sm bg-paused-bg text-[#101828] p-3 rounded-r-lg font-medium'>
                Hi Team,
The list has some 10 emails which are from different domains, we suspect some emails have errors, please resolve!
                </p>
            </div>
        </div> */}
        {/* Bot Message */}
        {/* <div className='flex gap-2.5 justify-end'>
        </div> */}
        {/* Bot Message */}
        {/* <div className='flex gap-2.5 justify-end'>
            <div className='flex flex-col gap-1 w-9/12'>   
                <div className='flex justify-between'>
                    <h5 className='text-xs text-heading'>Sparkle Bot</h5>
                    <p className='text-xs text-column'>Just Now</p>
                </div>
                <p className='text-sm bg-[#4318FF1A] text-[#101828] p-3 rounded-l-lg font-medium'>
                Hey user1,
Thanks for raising the ticket! it will be assigned to our team for investigation shortly. We will notify you once completed
                </p>
            </div>
        </div> */}
        {/* User Message */}
        {!messageLoading?.messagesFetching ? (
          <>
            {state?.totalMessages?.length !== 0 &&
              state?.totalMessages?.map((data, i) => {
                return (
                  <div key={i}>
                    {/* //Day Label
                                    <div className='flex justify-center items-center gap-2' >
                                        <hr className='w-5/12 border-t-[#EAECF0' />
                                        <p className='text-column text-xs text-center w-2/12'>{day(data?.created_full_date)}</p>
                                        <hr className='w-5/12 border-t-[#EAECF0]' />
                                    </div> */}

                    {data?.user_type === 'external' ? (
                      <div className="flex gap-2.5">
                        <Avatar size={'sm'} />
                        <div className="flex w-10/12 flex-col gap-1">
                          <div className="flex justify-between">
                            <h5 className="text-xs text-heading dark:text-purple-300">{state.ticketDetails.name}</h5>
                            <p className="text-xs text-column dark:text-gray-200">{data?.cdate}</p>
                          </div>
                          {data?.ticket_type === 'file' ? (
                            <div className="rounded-lg bg-paused-bg dark:bg-darkThree p-2">
                              <button
                                className="relative flex w-28 items-center justify-center gap-2 rounded-md border border-brand-400 p-1.5 text-xs transition-all duration-300 dark:hover:text-blackDark hover:bg-brand-100"
                                onClick={() => handleDownload(data?.ticket_emails)}
                              >
                                <span className="text-[#101828] dark:text-white " id="file-record">
                                  {data?.ticket_emails?.length} Records
                                </span>
                                <FaFileDownload size={15} className="text-brand-600" />
                              </button>
                            </div>
                          ) : (
                            <p id="user-chat" className="rounded-lg bg-paused-bg p-3 dark:bg-darkThree dark:text-white text-sm font-medium text-[#101828]">
                              {data?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-end gap-2.5">
                        <div className="flex w-9/12 flex-col gap-1">
                          <div className="flex justify-between">
                            <h5 className="text-xs text-heading dark:text-blue-400">You</h5>
                            <p className="text-xs text-column dark:text-gray-200">{data?.cdate}</p>
                          </div>
                          {data?.ticket_type === 'file' ? (
                            <div className="rounded-lg  bg-[#4318FF1A] dark:bg-darkThree p-2 font-medium">
                              <button 
                                className="relative flex w-28 items-center justify-center dark:bg-darkThree gap-2 rounded-md border border-brand-400 p-1.5 text-xs transition-all duration-300 hover:bg-brand-100"
                                onClick={() => handleDownload(data?.ticket_emails)}
                              >
                                <span className="text-[#101828] dark:text-white">{data?.ticket_emails?.length} Records</span>
                                <FaFileDownload size={15} className="text-brand-600" />
                              </button>
                            </div>
                          ) : (
                            <p id="bot-chat" className="rounded-lg bg-[#4318FF1A] dark:bg-darkThree dark:text-white p-3 text-sm font-medium text-[#101828]">
                              {data?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </>
        ) : (
          <div className="flex h-full items-center justify-center">
            <Spinner thickness="3px" speed="0.65s" color="gray.400" size="md" />
          </div>
        )}
        <hr ref={chatRef} className='dark:border-darkBorder' />
      </div>

      <div
        className={`${state?.ticketDetails?.status === 3 && 'pointer-events-none select-none'} dark:bg-darkThree dark:text-white dark:border-darkBorder w-full rounded-md border bg-white p-2 outline-none focus-within:border-menuBorder`}
        tabIndex={0}
      >
        <textarea
          name=""
          id="chat-input"
          rows={2}
          value={state?.chatText}
          className="w-full resize-none py-1 dark:bg-darkThree dark:text-white text-sm text-heading placeholder:text-sm placeholder:text-column focus:outline-none"
          placeholder="Send a message"
          onChange={(e) => dispatch(setChatText(e.target.value))}
        />
        <div className="flex w-full justify-end">
          <FileAttach getChat={getChat} />
          <Button  customClass='dark:border-none' name="Send" loading={messageLoading?.sending} disable={buttonDisabled} id="send-btn" fontSize="[0.8rem]" px="px-3" py="py-1.5" onClick={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatOptions;
