import TableLoader from 'components/Loaders/TableViewLoader';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import Chart from 'react-apexcharts';
import { MdVerifiedUser } from 'react-icons/md';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import AnalysisModal from './AnalysisModal';
import React, { useEffect, useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { EDIT_ID } from 'common/constants/constants';
import { EmailVerifierResultRowObject, EmailValidationResult, ModalData, StatisticsEntry } from 'common/dto/types/views/email-verifier';
import ChartSkeliton from './ChartSkeliton';
import EmailVerificationExportOptions from './EmailVerificationExportOptions';
import { BsStars } from 'react-icons/bs';
import Button from 'common/sparkle-common/Button';
import { PiArrowArcLeftThin } from 'react-icons/pi';
import TrueVerifierModel from './TrueVerifierModel';
import PaymentModal from './PaymentModal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { checkRolePermissionStatus, setAndGetQueryParams } from 'common/utils/utility';
import { ReactSVG } from 'react-svg';
import BackArrow from 'assets/svg/backArrow.svg';
import { GiCheckMark } from 'react-icons/gi';
import InfiniteScrollComponent from 'components/infinityScroll';

type BulletPropType = {
  content: string;
};

const BulletPoints = (props: BulletPropType) => {
  const { content } = props;
  return (
    <div className="flex items-center gap-2">
      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-bgGreen text-textGreen  dark:bg-green-900">
        <GiCheckMark size={11} />
      </div>
      <span className="text-sm text-column dark:text-gray-300">{content}</span>
    </div>
  );
};

const EmailVerificationResult = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState<any>({ data: [], uuid: '', listName: '' });
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState<ModalData>();
  const [isProcessing, setIsProcessing] = useState(true);
  const [chartData, setChartData] = useState<StatisticsEntry[]>();
  const [series, setSeries] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showPaymentWarning, setShowPaymentWarning] = React.useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { _id: 1 },
    search: '',
    filter: '',
  });

  // True verifier model state
  const [isTrueVerifierOpen, setIsTrueVerifierOpen] = useState({
    show: false,
    clickedData: {},
  });

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'Email Verifier');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = React.useState({
    show: false,
    resultUUID: '',
  });
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [columns, setColumns] = React.useState([]);

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const columnHelper = createColumnHelper<EmailVerifierResultRowObject>();
  const columnData = [
    columnHelper.accessor('email', {
      id: 'email',
      enableSorting: false,
      header: () => <div className="select-none truncate font-semibold text-blackDark dark:text-white">Email Accounts</div>,
      cell: (info) => <div>{<p className={`pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}>{info.getValue()}</p>}</div>,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      enableSorting: false,
      header: () => <div className="relative select-none font-semibold text-blackDark dark:text-white">Status</div>,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor('result', {
      id: 'result',
      header: () => <div className="relative select-none font-semibold text-blackDark dark:text-white">Result</div>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === 'Valid' ? (
              <div className="flex w-[80px] items-center justify-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1.5">
                <FaCheck className="text-xs font-bold text-[#12B76A]" />
                <h5 className={`text-xs font-semibold text-[#027A48] status-index-${info?.row?.id}`}>Valid</h5>
              </div>
            ) : info.getValue() === 'Invalid' ? (
              <div className="flex w-[80px] items-center  justify-center gap-1 rounded-full bg-[#FEF6EE] px-2 py-1.5">
                <IoClose className="text-xs font-bold text-[#EF6820]" />
                <h5 className={`text-xs font-semibold text-[#B93815] status-index-${info?.row?.id}`}>Invalid</h5>
              </div>
            ) : info.getValue() === 'Catch All' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#EEF4FF] px-2 py-2">
                <IoClose className="text-xs text-[#6172F3]" />
                <h5 className={`text-xs font-semibold text-[#3538CD] status-index-${info?.row?.id}`}>Catch All</h5>
              </div>
            ) : info.getValue() === 'Unknown' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#FFFAEB] px-2 py-2">
                <IoClose className="text-xs text-[#F79009]" />
                <h5 className={`text-xs font-semibold text-[#B54708] status-index-${info?.row?.id}`}>Unknown</h5>
              </div>
            ) : info.getValue() === 'Error' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1.5">
                <IoClose className="text-xs text-[#F04438]" />
                <h5 className={`text-xs font-semibold text-[#B42318] status-index-${info?.row?.id}`}>Error</h5>
              </div>
            ) : null}
          </div>
        );
      },
    }),
  ];
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const listId = sessionStorage.getItem(EDIT_ID);
        if (listId) {
          const query = setAndGetQueryParams([
            { key: 'offset', value: queryParams?.offset },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/email/verify/list/${listId}?${query}&sort=_id%asc`);
          if (response?.data?.data?.length) {
            const data = response?.data?.data?.map((item: any) => ({
              email: item.email,
              status: item?.verify_details?.status?.result ? 'Processed' : 'Processing...',
              result:
                item?.verify_details?.status?.result === 'valid'
                  ? 'Valid'
                  : item?.verify_details?.status?.result === 'catch_all'
                  ? 'Catch All'
                  : item?.verify_details?.status?.result === 'invalid'
                  ? 'Invalid'
                  : item?.verify_details?.status?.result === 'unknown'
                  ? 'Unknown'
                  : item?.verify_details?.status?.result === 'error'
                  ? 'Error'
                  : '',
              multiResult: item?.verify_details?.multi_status,
            }));

            setData({
              data: data,
              count: totalCount,
            });

            setListData((prev: any) => ({ data: data, uuid: prev.uuid, listName: prev.listName }));
            setColumns(columnData);

            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  React.useEffect(() => {
    if (isOpen?.show) {
      const initialData = {
        isValidSyntax: 'Verification pending',
        isValidDomain: 'Verification pending',
        isTempEmail: 'Verification pending',
        isRoleBasedAccount: 'Verification pending',
        isCatchAllDomain: 'Verification pending',
        isValidSmtp: 'Verification pending',
        isValidEmail: 'Verification pending',
        isTarpitting: 'Verification pending',
        isGreyListed: 'Verification pending',
        isSafeToSend: 'Verification pending',
        isDeliverable: 'Verification pending',
        canConnectSmtp: 'Verification pending',
        isFreeEmailAccount: 'Verification pending',
      };
      const multiResult = listData?.data?.filter((ele: any) => ele?.email === isOpen?.resultUUID)[0];
      delete multiResult?.mxRecord;
      const values = multiResult?.multiResult ? multiResult?.multiResult : initialData;
      const resultArray = Object.keys(values)
        .filter((key) => key !== 'mxRecord') // Exclude 'mxRecord' from the keys
        .map((key) => ({
          key,
          value: values[key as keyof EmailValidationResult], // Corrected type assertion for clarity
        }));
      setModalData({
        email: isOpen?.resultUUID,
        multiResult: resultArray,
        result: multiResult?.result,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen?.show, listData]);

  const options = {
    labels: ['Valid', 'Invalid', 'Error', 'Unknown', 'Catch All'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%', // Responsive width
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: ['#14B8A6', '#F59E0B', '#F50B0B', '#FACC15', '#6366F1', '#C9D0E3'],
    plotOptions: {
      pie: {
        donut: {
          size: '48%',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
  };

  useEffect(() => {
    const getChartData = async () => {
      try {
        const listId = sessionStorage.getItem(EDIT_ID);
        if (listId) {
          const response = await BaseApi.get(`email/verify/list/counts/${listId}`);
          if (response.data) {
            const statistics = [
              {
                count: response.data?.verify_valid_count || 0,
                color: '#14B8A6',
                label: 'Valid',
                originalName: 'valid',
              },
              {
                count: response.data?.verify_invalid_count || 0,
                color: '#F59E0B',
                label: 'Invalid',
                originalName: 'invalid',
              },
              {
                count: response.data?.verify_error_count || 0,
                color: '#F50B0B',
                label: 'Error',
                originalName: 'error',
              },
              {
                count: response.data?.verify_unknown_count || 0,
                color: '#FACC15',
                label: 'Unknown',
                originalName: 'unknown',
              },
              {
                count: response.data?.verify_catch_all_count || 0,
                color: '#6366F1',
                label: 'Catch All',
                originalName: 'catch_all',
              },
            ];
            setSeries(statistics.map((item) => item.count));
            setIsProcessing(response.data.total_email_verify_count !== response.data.verified_count);
            setChartData(statistics);

            setListData((prev: any) => ({ data: prev?.data, uuid: response?.data?.uuid, listName: response?.data?.list_name }));
            setTotalCount(response.data.total_email_verify_count);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getChartData();

    const intervalId = setInterval(() => {
      if (isProcessing) {
        getChartData();
      } else {
        getChartData();
        clearInterval(intervalId);
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [isProcessing]);

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const listId = sessionStorage.getItem(EDIT_ID);

          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 2 },
            { key: 'search', value: queryParams?.search },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/email/verify/list/${listId}?${query}&sort=_id%asc`);
          if (!response?.data?.data?.length) return setData((prev) => ({ ...prev, data: [], count: totalCount }));

          const data = response?.data?.data?.map((item: any) => ({
            email: item.email,
            status: item?.verify_details?.status?.result ? 'Processed' : 'Processing...',
            result:
              item?.verify_details?.status?.result === 'valid'
                ? 'Valid'
                : item?.verify_details?.status?.result === 'catch_all'
                ? 'Catch All'
                : item?.verify_details?.status?.result === 'invalid'
                ? 'Invalid'
                : item?.verify_details?.status?.result === 'unknown'
                ? 'Unknown'
                : item?.verify_details?.status?.result === 'error'
                ? 'Error'
                : '',
            multiResult: item?.verify_details?.multi_status,
          }));

          if (response?.data?.data?.length < 25) {
            setData((prev) => ({
              // ...prev,
              data: [...prev?.data, ...data],
              count: totalCount,
            }));
            setListData((prev: any) => ({ data: [...prev?.data, ...data], uuid: prev.uuid, listName: prev.listName }));
          } else {
            setData((prev) => ({
              // ...prev,
              data: [...prev?.data, ...data],
              count: totalCount,
            }));
            setListData((prev: any) => ({ data: [...prev.data, ...data], uuid: prev.uuid, listName: prev.listName }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < totalCount && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, totalCount, queryParams?.offset]
  );

  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
      <div className="relative mb-3 flex items-center justify-between py-1 sm:gap-32 md:px-10 lg:gap-32 lg:px-3">
        <button
          id="campaign-close-btn"
          onClick={() => {
            navigate('/email-verifier');
          }}
        >
          <ReactSVG src={BackArrow} className="scale-[0.8]" />
        </button>
        {/* Role Restriction for the Export in Email Verifier Result Page */}
        {(roleStatus?.is_owner || roleStatus?.download_or_export) && (
          <div className="flex items-center gap-2" id="email-verify-export-button">
            <EmailVerificationExportOptions totalCount={totalCount} showOptions={showOptions} setShowOptions={setShowOptions} />
          </div>
        )}
      </div>

      <div className="h-[79vh] border-t">
        {!chartData?.length ? (
          <ChartSkeliton />
        ) : (
          <div className={`m-2.5 flex rounded-md border border-[#e0ccfc] bg-[#fbfaff] shadow-sm dark:border-darkBorder dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
            <div className="flex h-full w-80 justify-center pl-4 pt-2 " id="email-verify-start-chart">
              <Chart options={options} series={series?.some((d: any) => d !== 0) ? series : [0, 0, 0, 0, 0, 1]} type="donut" width={410} />
            </div>

            <div className="flex h-full w-full flex-col justify-center gap-2 p-5 align-middle ">
              <div className="flex gap-2">
                <h4 className="text-[1.5rem] font-bold text-heading dark:text-purple-500">Total - {Intl.NumberFormat('en-US').format(totalCount)}</h4>
                {/* <RiInformationFill className="my-auto text-xl text-[#8F9BBA] dark:text-gray-300" /> */}
              </div>
              <div className="flex gap-[4.3125rem] align-middle">
                {chartData?.map((val) => (
                  <div className="ml-3 flex w-28 items-center gap-2" key={val?.label}>
                    <span className="h-3 w-3 rounded-full" style={{ background: val?.color }} />
                    <p className="text-lg font-bold text-heading dark:text-purple-500">{val?.label}</p>
                  </div>
                ))}
              </div>
              <div className="flex gap-20 border-y bg-white p-3 align-middle dark:border-darkBorder dark:bg-darkThree">
                {chartData?.map((value, index) => (
                  <div className="flex w-28 gap-3 pl-1" key={index}>
                    <p className="text-2xl font-bold text-heading dark:text-gray-200">{value?.count || 0}</p>
                  </div>
                ))}
              </div>
              {/* Sparkle verifier box */}
              <div className="relative pt-10">
                <div className="flex flex-col gap-3 rounded-xl border border-blueSecondary bg-white p-4 dark:border-darkBorder dark:bg-darkTwo">
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2 text-xl font-bold text-heading dark:text-purple-500">
                      <BsStars />
                      <h4>Sparkle Verifier</h4>
                    </div>
                    <Button
                      customClass="dark:border-none"
                      id="trueverifier-start-verification-btn"
                      name="Start Verification"
                      LeftIcon={<MdVerifiedUser size={15} />}
                      onClick={() =>
                        setIsTrueVerifierOpen({
                          show: true,
                          clickedData: {
                            uuid: listData?.uuid,
                            listName: listData?.listName,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="flex gap-4 dark:!text-gray-300 ">
                    <BulletPoints content="Prevents bounces" />
                    <BulletPoints content="Removes duplicates" />
                    <BulletPoints content="Helps protect the deliverability & reputation of the domain" />
                  </div>
                </div>
                <div className="absolute left-28 top-3 text-heading dark:text-purple-300">
                  <span className="text-xs font-bold">Most popular!</span>
                  <div className="absolute -left-10 top-0 w-9 overflow-hidden">
                    <PiArrowArcLeftThin size={50} className="-rotate-45" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading ? (
          <>
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="px-3">
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`mb-2 w-1/3 border-y border-gray-200 bg-gray-50 px-3 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                        >
                          <div className="text-[14px]">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne">
                        {row.getVisibleCells().map((cell, i, arr) => {
                          return (
                            <td
                              id={`email-verify-mail-number-${i}`}
                              key={cell.id}
                              className={`cursor-pointer border-white/0 px-3 py-3 text-start text-sm font-semibold text-heading dark:text-white`}
                              onClick={() => {
                                setIsOpen({
                                  show: true,
                                  resultUUID: row?.original?.email,
                                });
                              }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={<TableLoader width="w-full" border="border border-gray-100" column={3} row={5} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="25% 25% 25%" />}
            />
          </>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        )}
        <AnalysisModal isOpen={isOpen?.show} onClose={() => setIsOpen({ show: false, resultUUID: '' })} modalData={modalData} />
        {/* True verifier model */}
        <TrueVerifierModel
          isOpen={isTrueVerifierOpen}
          onClose={(): void => {
            setShowPaymentWarning(false);
            setIsTrueVerifierOpen((prev) => ({ ...prev, show: false }));
          }}
          verifierData={chartData?.filter((data) => data?.count !== 0)}
          setIsOpen={setIsTrueVerifierOpen}
          showPaymentWarning={showPaymentWarning}
          setShowPaymentWarning={setShowPaymentWarning}
          setPayment={setShowPayment}
        />

        <PaymentModal isOpen={showPayment} onClose={() => setShowPayment(false)} />
      </div>
    </div>
  );
};

export default EmailVerificationResult;
