import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useAppDispatch, useAppSelector } from "store";
import { handleChangeEditData } from "store/email-accounts";

const EmailEdit = () => {
  const editData = useAppSelector((state) => state?.emailAccount?.getEditData)
  const { errorMessage }: any = useAppSelector((state: { custom: any; }) => state.custom);

  const dispatch = useAppDispatch();
  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Email Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change your account information</p>
      </div>
      {/* inputs */}
      <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">
        <InputField
          extra="mb-3"
          label="Username*"
          placeholder="@parkson.adela"
          id="user_name"
          type="text"
          value={editData?.user_name}
          onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "user_name", value: e.target.value }))}
          errorMessage={errorMessage?.user_name}
          state={errorMessage?.user_name ? 'error' : ''}
        />
        <InputField
          extra="mb-3"
          label="Email Address*"
          placeholder="adela@simmmple.com"
          id="email"
          type="text"
          value={editData?.email}
          disabled

        />
        <InputField
          extra="mb-3"
          label="First Name"
          placeholder="First Name"
          id="first_name"
          type="text"
          value={editData?.first_name}
          onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "first_name", value: e.target.value }))}
          errorMessage={errorMessage?.first_name}
          state={errorMessage?.first_name ? 'error' : ''}
        />
        <InputField
          extra="mb-3"
          label="Last Name"
          placeholder="Last Name"
          id="last_name"
          type="text"
          value={editData?.last_name}
          onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "last_name", value: e.target.value }))}
          errorMessage={errorMessage?.last_name}
          state={errorMessage?.last_name ? 'error' : ''}
        />
      </div>
      {/* full width inputs */}
      <InputField
        extra="mb-3"
        label="Job"
        placeholder="What do you do?"
        id="job"
        type="text"
        value={editData?.job}
        onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "job", value: e.target.value }))}
        errorMessage={errorMessage?.job}
        state={errorMessage?.job ? 'error' : ''}
      />

      <TextField
        extra="mb-4"
        label="About Me"
        placeholder="Tell something about yourself in 150 characters!"
        id="about_me"
        cols={30}
        rows={5}
        value={editData?.about_me}
        onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "about_me", value: e.target.value }))}
        errorMessage={errorMessage?.about_me}
        state={errorMessage?.about_me ? 'error' : ''}
      />
    </Card>
  );
};

export default EmailEdit;
