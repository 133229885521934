import React, { useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/modal'
import { useDisclosure } from '@chakra-ui/hooks'
import { BiPencil } from 'react-icons/bi'
import { AiOutlineDelete } from 'react-icons/ai'
import { toast } from 'react-toastify'

import Toggle from 'components/Toggle'
import { useAppSelector } from 'store'
import { deleteCustomUnsubscribe, deleteSignature, setFinalSettings, setSettingsData } from 'store/campaign-v2'
import SparkleRadio from 'common/sparkle-common/Radio'
import { CustomMenu } from 'components/customMenu'
import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor'
import SignatureModal from './SignatureModal'
import { BaseApi } from 'common/api/common/base-api'
import { _errorHandler, toastState } from 'common/utils/utility'
import Button from 'common/sparkle-common/Button'
import { Switch } from '@chakra-ui/react'

type keyString = {
    [key: string]: string;
}

const FinalSettings = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [signatureModal, setSignatureModal] = useState(false)
    const [customData, setCustom] = useState('')
    const [unsubscribeLoading, setUnsubscribeLoading] = useState(false)
    const finalSettings = useAppSelector(state => state?.campaignv2.finalSettings)
    const settings = useAppSelector(state => state?.campaignv2.settings)
    const ids = useAppSelector(state => state?.campaignv2.ids)

    const dispatch = useDispatch()
    const { signature_type } = settings
    const unsubscribeEditorRef = React.useRef(null);

    // Fetch signature and unsubscribe data
    React.useEffect(() => {
        const fetchunsubscribeAndSignatureData = async () => {
            try {
                const response = await BaseApi.get(`/channel/signature/unsubscribe?brand_id=${ids?.brand_id}`)
                if(response?.error){
                    toast.error(_errorHandler(response?.message), toastState?.error);
                }
                else {
                    dispatch(setFinalSettings({ unsubscribeAndSignatureData: response.data }))
                }
            } catch (error) {
                console.log('Fetching unsubscribe and signature data error :', error)
            }
        }
        fetchunsubscribeAndSignatureData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureModal, isOpen])

    React.useEffect(() => {
        // Initial value for selected unsubscribe link
        const initialUnsubscribeState = finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list?.reduce((acc: { [key: string]: boolean }, item: keyString) => {
            acc[item.data] = finalSettings.selectedUnsubscribeLinks[item?.data] || false
            return acc
        }, {}) || {}

        // Initial value for selected signature
        const initialSignatureState = finalSettings?.unsubscribeAndSignatureData?.signature_list?.reduce((acc: { [key: string]: boolean }, item: keyString) => {
            acc[item.name] = finalSettings.selectedSignature[item.name] || false
            return acc
        }, {}) || {}
        dispatch(setFinalSettings({ selectedUnsubscribeLinks: initialUnsubscribeState, selectedSignature: initialSignatureState }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalSettings?.unsubscribeAndSignatureData])

    React.useEffect(() => {
        if (finalSettings.unsubscribeEditId) {
            const content = finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list?.find((item: keyString) => item?.uuid === finalSettings?.unsubscribeEditId)
            setTimeout(() => {
                if (unsubscribeEditorRef.current) {
                    unsubscribeEditorRef?.current?.core?.setContents(content?.data)
                }
            }, 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalSettings.unsubscribeEditId])
    


    const handleCloseModal = () => {
        setCustom('')
        dispatch(setFinalSettings({ unsubscribeEditId: '', selectedUnsubscribe: '' }))
        onClose()
    }

    // Unsubscribe functions
    const handleSelectUnsubscribeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event?.target
        const selectedData = { ...finalSettings?.selectedUnsubscribeLinks, [name]: checked }
        dispatch(setFinalSettings({ selectedUnsubscribeLinks: selectedData }))
    }

    const handleSelectAllUnsubscribeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event?.target;
        const allRow = Object.keys(finalSettings?.selectedUnsubscribeLinks).reduce((acc: { [key: string]: boolean }, item: string) => {
            acc[item] = checked
            return acc
        }, {})
        dispatch(setFinalSettings({ selectedUnsubscribeLinks: allRow }))
    }

    const handleSaveCustomUnsubscribeLink = async () => {
        try {
            setUnsubscribeLoading(true)
            if (customData) {
                if (finalSettings.unsubscribeEditId !== "") {
                    // dispatch(updateCustomUnsubscribe({ index: finalSettings.unsubscribeEditId, text: customData }))
                    const params = {
                        variant: 'unsubscribe',
                        uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                        brand_id: ids?.brand_id,
                        data_item: {
                            uuid: finalSettings.unsubscribeEditId,
                            data: customData
                        }

                    }
                    await BaseApi.patch('/channel/signature/unsubscribe', params)
                } else {
                    // dispatch(setCustomUnsubscribe(customData));
                    const params = {
                        variant: 'unsubscribe',
                        uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                        brand_id: ids?.brand_id,
                        data_list: [{ data: customData }]
                    }
                    await BaseApi.post('/channel/signature/unsubscribe', params)
                }
                handleCloseModal()
            } else {
                toast.error('Unsubscribe content is required', toastState.error)
            }
        } catch (error) {
            console.log('Unsubscribe save error', error)
        } finally {
            setUnsubscribeLoading(false)
        }
    }


    const handleEditUnsubcribeLink = (uuid: string) => {
        dispatch(setFinalSettings({ unsubscribeEditId: uuid }))
        onOpen()
    }

    const handleDeleteUnsubscribeLink = async (uuid: string) => {
        try {
            dispatch(deleteCustomUnsubscribe(uuid))
            const params = {
                data: {
                    uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                    brand_id: ids.brand_id,
                    delete_id_list: [uuid],
                    variant: "unsubscribe"
                }
            }
            await BaseApi.delete('/channel/signature/unsubscribe', params)

        } catch (error) {
            console.log('Delete signature error', error)
        }
    }

    // Signature functions

    const handleSelectSignatureType = (checked: boolean, name: string) => {
        if (checked) {
            dispatch(setSettingsData({ signature_type: name }))
        }
    }


    const handleSelectSignature = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event?.target
        const selectedData = { ...finalSettings?.selectedSignature, [name]: checked }
        dispatch(setFinalSettings({ selectedSignature: selectedData }))
    }

    const handleSelectAllSignature = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event?.target;
        const allRow = Object.keys(finalSettings?.selectedSignature).reduce((acc: { [key: string]: boolean }, item: string) => {
            acc[item] = checked
            return acc
        }, {})
        dispatch(setFinalSettings({ selectedSignature: allRow }))
    }

    const handleEditSignature = (uuid: string) => {
        dispatch(setFinalSettings({ editSignatureId: uuid }))
        setSignatureModal(true)
    }

    const handleDeleteSignature = async (uuid: string) => {
        try {
            dispatch(deleteSignature(uuid))
            const params = {
                data: {
                    uuid: finalSettings?.unsubscribeAndSignatureData?.uuid,
                    brand_id: ids.brand_id,
                    delete_id_list: [uuid],
                    variant: "signature"
                }
            }
            await BaseApi.delete('/channel/signature/unsubscribe', params)

        } catch (error) {
            console.log('Delete signature error', error)
        }
    }

    const isCheckedAllUnsubscribeLink = Object.values(finalSettings?.selectedUnsubscribeLinks).every((item) => item)
    const isCheckedAllSignature = Object.values(finalSettings?.selectedSignature)?.length ? Object.values(finalSettings?.selectedSignature).every((item) => item) : false
    return (
        <div className='w-[740px] rounded-md shadow-shade py-[1.2rem] relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white select-none'>
            <h5 className='px-5 mb-0.5 text-[0.9rem] font-medium text-column'>Settings</h5>
            <hr />
            <div className='flex flex-col gap-1.5 px-5 py-3'>
                <h5 className='text-column font-medium text-[0.82rem]'>Unsubscribe link & rotation</h5>
                <CustomMenu btnId='unsubscribe_select'
                    btnContent={<div className='flex gap-2 items-center whitespace-pre'>
                        {Object.keys(finalSettings?.selectedUnsubscribeLinks)?.filter(item => finalSettings?.selectedUnsubscribeLinks[item])[0]?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') || 'Select'}
                    </div>}
                    btnClassName='w-full justify-between items-center text-column !h-10' rightIcon={<div className='flex items-center gap-3'>
                        {(Object.keys(finalSettings?.selectedUnsubscribeLinks)?.filter(item => finalSettings?.selectedUnsubscribeLinks[item])?.length > 1) && <div className='border bg-hover text-[0.6rem] font-semibold rounded-full p-1 h-5 w-fit box-border flex items-center justify-center'>
                            <span>+{(Object.keys(finalSettings?.selectedUnsubscribeLinks))?.filter(item => finalSettings?.selectedUnsubscribeLinks[item]).length - 1}</span>
                        </div>}
                        <IoChevronDown className='text-[#2B3674] size-3.5 font-medium' />
                    </div>}
                    menuListClassName='w-full !p-0' >
                    <div>
                        {finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list && finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list?.length > 0 &&
                            <div className='border-b p-2 flex items-center gap-1 text-xs text-column font-bold'>
                                <input type='checkbox' id='unsub_select_all' checked={isCheckedAllUnsubscribeLink} onChange={handleSelectAllUnsubscribeLink} className='w-fit cursor-pointer' /><span className='w-full'>Select all</span>
                            </div>}
                        <div className='max-h-40 overflow-auto'>
                            {
                                finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list && finalSettings?.unsubscribeAndSignatureData?.unsubscribe_list?.map((item: { [key: string]: string }, id: number) =>
                                    <div key={id} className='border-b hover:bg-hover p-2 flex items-center gap-1 text-xs text-column font-bold'>
                                        <input type='checkbox' name={item.data} checked={finalSettings?.selectedUnsubscribeLinks[item.data]} onChange={handleSelectUnsubscribeLink} className='w-fit cursor-pointer' />
                                        <span className='w-full whitespace-pre'>{item?.data?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ')}</span>
                                        <span className='p-1 hover:bg-lightPrimary duration-300 rounded-md cursor-pointer' onClick={() => handleEditUnsubcribeLink(item?.uuid)}>
                                            <BiPencil className='w-fit text-column' size={15} /></span>
                                        <span className='p-1 hover:bg-lightPrimary duration-300 rounded-md cursor-pointer' onClick={() => handleDeleteUnsubscribeLink(item?.uuid)}>
                                            <AiOutlineDelete className='w-fit text-column' size={15} /></span>
                                    </div>)
                            }
                        </div>
                        <div className='border-t border-gray-100 p-2 hover:bg-hover text-xs text-heading font-bold cursor-pointer' onClick={onOpen}>+ Add new</div>
                    </div>
                </CustomMenu>
            </div>
            <hr />
            <div className='flex flex-col gap-1.5 px-5 py-3'>
                <div className='flex items-center'>
                    <h5 className='text-column font-medium text-[0.82rem]'>Signature & Rotation </h5>
                    <Switch size={'lg'} className='scale-[.4] -ml-2' id='signature-select' isChecked = {signature_type !== 'nothing'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setSettingsData({ signature_type: event?.target?.checked ? 'use_respective_email_id_signature' : 'nothing' }))}/>
                    {/* <Toggle className='scale-[.4] -ml-2' id='signature-select' checkedCondition={signature_type !== 'nothing'} changeFunction={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(setSettingsData({ signature_type: event?.target?.checked ? 'use_respective_email_id_signature' : 'nothing' }))} /> */}
                </div>
                <div className={`${signature_type === 'nothing' ? 'pointer-events-none' : 'pointer-events-auto'}`}>
                    <div className={`relative`}>
                        <CustomMenu
                            btnId='signature_select'
                            btnClassName={`w-full justify-between items-center  !h-10 ${signature_type === 'nothing' ? 'bg-grayprimary text-gray-600' : 'text-column'}`}
                            btnContent={<div className='flex gap-2 items-center'>
                                {Object.keys(finalSettings?.selectedSignature)?.filter(item => finalSettings?.selectedSignature[item])[0] || 'Signature'}
                            </div>}
                            rightIcon={
                                <div className='flex items-center gap-3'>
                                    {(Object.keys(finalSettings?.selectedSignature)?.filter(item => finalSettings?.selectedSignature[item])?.length > 1) && <div className='border bg-hover text-[0.6rem] font-semibold rounded-full p-1 h-5 w-fit flex items-center justify-center'>
                                        <span>+{(Object.keys(finalSettings?.selectedSignature))?.filter(item => finalSettings?.selectedSignature[item]).length - 1}</span>
                                    </div>}
                                    <IoChevronDown className='size-3.5 font-medium' />
                                </div>
                            }
                            menuListClassName='w-full !p-0' >
                            <div>
                                {finalSettings?.unsubscribeAndSignatureData?.signature_list && finalSettings?.unsubscribeAndSignatureData?.signature_list?.length > 0 &&
                                    <div className='border-b p-2 flex items-center gap-1 text-xs text-column font-bold'>
                                        <input type='checkbox' className='w-fit cursor-pointer' checked={isCheckedAllSignature} onChange={handleSelectAllSignature} /><span className='w-full'>Select all</span>
                                    </div>}
                                <div className='max-h-40 overflow-auto'>{
                                    finalSettings?.unsubscribeAndSignatureData?.signature_list && finalSettings?.unsubscribeAndSignatureData?.signature_list?.map((item: { [key: string]: string }, id: number) => <div key={id} className='border-b p-2 flex items-center gap-1 text-xs text-column font-bold hover:bg-hover'>
                                        <input type='checkbox' id={`sign_${id}`} name={item.name} checked={finalSettings?.selectedSignature[item.name]}
                                            onChange={handleSelectSignature} className='w-fit cursor-pointer' />
                                        <span className='w-full'>{item.name}</span>
                                        <span className='p-1 hover:bg-lightPrimary duration-300 rounded-md cursor-pointer' onClick={() => handleEditSignature(item?.uuid)}>
                                            <BiPencil className='w-fit text-column' size={15} /></span>
                                        <span className='p-1 hover:bg-lightPrimary duration-300 rounded-md cursor-pointer' onClick={() => handleDeleteSignature(item?.uuid)}>
                                            <AiOutlineDelete className='w-fit text-column' size={15} /></span>
                                    </div>)
                                }</div>
                                <button className='border-b p-2 hover:bg-hover text-xs text-heading font-bold w-full text-start' id='add_signature' onClick={() => setSignatureModal(true)} >+ Add new</button>
                            </div>
                        </CustomMenu>
                    </div>
                    <div className={`flex items-center justify-between mt-2 ${signature_type === 'nothing' ? 'text-gray-600' : 'text-[#344054]'} `}>
                        <div className='flex items-start justify-center gap-1'>
                            <SparkleRadio checked={signature_type === 'use_signature_for_this_campaign'} name='use_signature_for_this_campaign' onChange={handleSelectSignatureType} />
                            <label className='text-[0.68rem] font-medium' htmlFor="use_signature_for_this_campaign">Use this signature for this campaign</label>
                        </div>
                        <div className='flex items-start justify-center gap-1'>
                            <SparkleRadio checked={signature_type === 'use_respective_email_id_signature'} name='use_respective_email_id_signature' onChange={handleSelectSignatureType} />
                            <label className='text-[0.68rem] font-medium' htmlFor="use_respective_email_id_signature">Use the respective email-id's signature</label>
                        </div>
                        <div className='flex items-start justify-center gap-1'>
                            <SparkleRadio checked={signature_type === 'use_this_signature_when_email_id_signature_empty'} name='use_this_signature_when_email_id_signature_empty' onChange={handleSelectSignatureType} />
                            <label className='text-[0.68rem] font-medium' htmlFor="use_this_signature_when_email_id_signature_empty">Use this signature when email-id signature is empty</label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='px-5 mt-1 pt-2 pb-3'>
                <h5 className='text-column font-medium text-[0.82rem]'>Sparkle.io watermark</h5>
                <div className='flex items-center'>
                    <h5 className='text-[0.73rem] font-medium text-[#344054]'>Remove Sparkle.io watermark from Signature</h5>
                    <Toggle className='scale-[.4] -ml-2' checkedCondition={finalSettings?.remove_watermark_signature} changeFunction={() => dispatch(setFinalSettings({ remove_watermark_signature: !finalSettings?.remove_watermark_signature }))} />
                </div>
            </div>
            {/* Unsubscribe link */}
            <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered={true} size={'2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <h5 className='text-[0.8rem] text-column font-medium'>Custom unsubscribe link</h5>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <textarea rows={4} className='p-1 rounded-md border resize-none w-full focus:outline focus:outline-blueSecondary' value={customData} onChange={(e) => { setCustom(e.target.value) }} /> */}
                        <CustomSunEditor
                            list={['bold', 'italic', 'underline', 'strike', 'align', 'list', 'fontColor', 'font', 'fontSize']}
                            editorRef={unsubscribeEditorRef}
                            height='8rem'
                            defaultValue={customData}
                            onChange={setCustom}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <div className='flex gap-2 w-full'>
                            <button className='w-full py-2 text-xs rounded-md border text-heading bg-white hover:shadow-shade' onClick={handleCloseModal}>Cancel</button>
                            <Button loading={unsubscribeLoading} disable={unsubscribeLoading} customClass='w-full py-1' name='Save' onClick={handleSaveCustomUnsubscribeLink} />
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Signature */}
            <SignatureModal signatureModal={signatureModal} setSignatureModal={setSignatureModal} />
        </div>
    )
}

export default FinalSettings