/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import * as XLSX from 'xlsx';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';

import { useAppDispatch, useAppSelector } from 'store';
import { setGlobalOpt } from 'store/custom';

import { checkRolePermissionStatus, jwtDecrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';

import { setCampaignSelectedCSVData } from 'store/campaign';

import { setEmailEditDrawer } from 'store/email-accounts';

import TableLoader from 'components/Loaders/TableViewLoader';
import ConfirmationPopup from 'components/confirmationPopup/ConfirmationPopup';
import { toast } from 'react-toastify';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';

import EditSenderListDrawer from '../sender-list-edit-drawer/EditSenderListDrawer';
// import SelectMethodPopup from '../email-add/select-method/SelectMethodPopup';
import { SenderAccountNumberSearchListRowObject } from 'common/dto/types/views/sender-accounts';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { ImCancelCircle } from 'react-icons/im';

interface ColumnOption {
  _name: string;
  id: string;
  [key: string]: any; // Allows any other properties with string keys
}

const SmsCallAccountsList = () => {
  const columnWidth = ['', '', '', '', '', '', ''];
  // const columnWidth = new Array(7).fill('');

  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [loader, setLoader] = React.useState(false);
  // const [searchParams] = useSearchParams();
  // const authCode = searchParams.get('code');
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });
  // const [colViewOptions, setColViewOptions] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columnOptions, setColumnOptions] = React.useState<ColumnOption[]>([
    { _name: 'Name', id: 'name', name: true, disabled: true },
    { _name: 'Email accounts', id: 'email', email: true, disabled: true },
    { _name: 'Status', id: 'mailer_status', mailer_status: true, disabled: true },
    { _name: 'Daily limits', id: 'limit', limit: true },
    // { _name: 'Health Score', id: '', none: true },   //commented need for development
    { _name: 'Email groups', id: 'groups_list', groups_list: true },
    // { _name: 'Custom tracking', id: '', none: true }, //commented need for development
    // { _name: 'Type', id: '', none: true }, //commented need for development
    // { _name: 'Sent', id: '', none: false }, //commented need for development
  ]);
  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  // const campaignSearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams.search, 300);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editRowData, setEditRowData] = React.useState({});
  const [confirm, setConfirm] = React.useState({
    isOpen: false,
    headerContent: '',
    bodyContent: '',
    bodyRedContent: '',
    descriptionContent: '',
    uuid: [],
  });
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedID, setSelectedIds] = React.useState([]);
  const [multiSelectState, setMultiSelectState] = React.useState(false);
  const dispatch = useAppDispatch();
  // const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  const columnHelper = createColumnHelper<SenderAccountNumberSearchListRowObject>();
  // const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  const custom = useAppSelector((state) => state?.custom);
  // const { accountType } = useAppSelector((state) => state.senderGroupListData);

  // const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const [editState, setEditState] = React.useState(false);
  const [multiOpt, setMultiOpt] = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let optionsDataRow: any = [];
  // let multiSelectOptions: any = [];

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
    // console.log('tableSelectedData', tableSelectedData);
    setSelectedIds(tableSelectedData);
    if (table.getSelectedRowModel().rows.length > 1) setMultiSelectState(true);
    else setMultiSelectState(false);
  }, [table.getSelectedRowModel()]);

  // const multiSelectOptions =
  // [
  //   {
  //     icon: <IoMdArchive />,
  //     name: 'Archieve',
  //     functioning: multiSelectDelete,
  //   },
  //   {
  //     icon: <MdOutlineCloudDownload />,
  //     name: 'Download',
  //     functioning: multiSelectDownload,
  //   },
  // ];

  // console.log('OOOO', selectedID);

  // Arcieve Function with confirm page
  const handleConfirmCancel = () => {
    setConfirm((prev) => ({ ...prev, isOpen: false }));
  };

  const handleConfirmSubmitDelete = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const apiEditResponse = await BaseApi.delete(`/sender-accounts/email`, {
        data: { id_list: confirm?.uuid },
      });
      // console.log('jjjjjjj', confirm?.uuid);
      // console.log('LLLLLLL', apiEditResponse);
      if (!apiEditResponse?.error) {
        setLoading(false);

        toast.success(apiEditResponse?.message, toastState.success);
        setConfirm((prev) => ({
          ...prev,
          isOpen: false,
          headerContent: '',
          bodyContent: '',
          bodyRedContent: '',
          descriptionContent: '',
          uuid: [],
        }));
        table.resetRowSelection();
      }
      // const decryptdata = await jwtDecrypt(apiEditResponse?.data);
      // setEditRowData(decryptdata);
      // setEditState(true);
    } catch (error) {
      console.error('Error form Row Edit in Sender Account:', error);
    }
  };

  // const RowSettingOptions = [
  //   {
  //     icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
  //     accessName: 'edit',
  //     name: 'Edit',
  //     functioning: handleRowDataEdit,
  //   },
  //   {
  //     icon: <IoIosCopy className="text-brand-500 group-hover:text-white" />,
  //     accessName: 'copy',
  //     name: 'Copy',
  //     functioning: () => alert('Copy'),
  //   },
  //   {
  //     icon: <IoMdArchive className="text-brand-500 group-hover:text-white" />,
  //     name: 'Archive',
  //     accessName: 'archieve_or_delete',
  //     functioning: () => alert('Archieve'),
  //   },
  //   {
  //     icon: <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />,
  //     name: 'Download',
  //     accessName: 'download',
  //     functioning: () => alert('DownLoad'),
  //   },
  // ];

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'sender_accounts');
      // console.log(status)
      // setRoleStatus(status);

      if (status?.is_owner) {
        optionsDataRow = [
          {
            icon: <ImCancelCircle className="text-brand-500 group-hover:text-white" />,
            accessName: 'Unrent',
            name: 'Unrent',
            functioning: () => {},
          },
        ];
        setMultiOpt([
          {
            icon: <ImCancelCircle />,
            name: 'Unrent',
            functioning: () => {},
          },
        ]);
      } else {
        optionsDataRow = [
          {
            icon: <ImCancelCircle className="text-brand-500 group-hover:text-white" />,
            accessName: 'Unrent',
            name: 'Unrent',
            functioning: () => {},
          },
        ].filter((item) => status?.hasOwnProperty(item.accessName));

        const multiSelectOptionsNew = [
          {
            icon: <ImCancelCircle />,
            name: 'Unrent',
            accessName: 'unrent',
            functioning: () => {},
          },
        ].filter((item) => status?.hasOwnProperty(item.accessName));
        setMultiOpt(multiSelectOptionsNew);
      }
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  // const fetchGroupList = async () => {
  //   try {
  //     const data = await BaseApi.get(`/groups/linked/with/email`);
  //     if (data?.data && data?.data?.data?.length) {
  //       const finalData = await data?.data?.data?.sort((a: any, b: any) =>
  //         a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
  //       );
  //       const states = data?.data?.data?.reduce((acc: any, val: any) => {
  //         acc[val.uuid] = { show: false, rename: false };
  //         return acc;
  //       }, {});
  //       dispatch(setSenderFetchData({ finalData, states }));
  //     } else {
  //       dispatch(setSenderFetchData([]));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   fetchGroupList();
  // }, []);

  // Role Restriction Enabed fro the Row setting of Sender Accounts
  // const filteredRowSettingOptions = roleStatus?.is_owner ? RowSettingOptions : RowSettingOptions.filter((item) => roleStatus[item?.accessName]);
  // const filteredRowSettingOptions = roleStatus?.is_owner ? RowSettingOptions : RowSettingOptions.filter((item) => roleStatus[item?.accessName] ?? false);
  // const filteredRowSettingOptions = roleStatus?.is_owner
  //   ? RowSettingOptions
  //   : RowSettingOptions.filter((item) => roleStatus?.hasOwnProperty(item?.accessName) && roleStatus[item.accessName]);

  // const filteredRowSettingOptions = RowSettingOptions;
  const fetchList = async () => {
    try {
      // setLoader(true);
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
      ]);
      const response = await BaseApi.get(`/sender-accounts/call_sms/list?${query}`);
      const { data, total_records }: any = jwtDecrypt(response?.data);
      if (data && data?.length) {
        setData((prev) => ({
          data: data,
          count: total_records?.count,
        }));
      } else {
        setData((prev) => ({
          data: [],
          count: 0,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updatedGroup = async () => {
    setLoader(false);

    fetchList();
  };

  const menuOptions = useAppSelector((state) => state?.senderGroupListData?.columnRowOptions?.emailOption);
  // console.log('menuOptions', menuOptions);

  React.useEffect(() => {
    fetchList();
    setLoader(true);
  }, [custom?.tableLoader, queryParams?.limit, queryParams?.offset, debouncedSearchValue, custom?.globalDependencyLoading, custom?.flagStatus]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleUpdateAccountStatus = async (actionType: string = '', uuid: string = '') => {
  //   try {
  //     const response = await BaseApi.post(`/sender/accounts/email/${actionType}`, { id_list: [uuid] });
  //     if (!response?.error || response?.status === 200) {
  //       const updateResponse = response?.data?.find((item: { uuid: string }) => item?.uuid === uuid);
  //       if (updateResponse?.data) {
  //         window.location.href = updateResponse?.data;
  //       } else {
  //         setData((prev) => {
  //           const data = prev.data?.map((item) => {
  //             if (item?.uuid === updateResponse?.uuid) {
  //               return { ...item, account_details: { ...item?.account_details, mailer_status: item?.account_details?.mailer_status ^ 1 } };
  //             }
  //             return item;
  //           });
  //           const count = prev?.count;
  //           return { data, count };
  //         });
  //       }
  //     }
  //   } catch (error: any) {
  //     console.error('handleUpdateAccountStatus Error:', error?.message);
  //   }
  // };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('number', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none text-center  font-semibold">Number</div>,
      cell: (info) => {
        console.log('info?.row?.original', info?.row?.original);
        return (
          <div className="flex  items-center justify-center gap-2 text-center  ">
            <div className="flex  flex-col text-center ">
              <p className="w-full truncate text-center text-xs font-semibold text-column dark:text-white">{info?.row?.original?.account_details?.number}</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('country', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none text-center   font-semibold">Country</div>,
      cell: (info) => {
        console.log('info?.row?.original', info?.row?.original);
        return (
          <div className="flex w-full items-center justify-center gap-2 text-center ">
            <div className="flex  flex-col">
              <p className="w-full truncate text-xs  text-column dark:text-white">{info?.row?.original?.account_details?.country}</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('sub_type', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none text-center  font-semibold ">Type</div>,
      cell: (info) => {
        console.log('info?.row?.original', info?.row?.original);
        return (
          <div className="flex  items-center justify-center gap-2 text-center ">
            <div className="flex  flex-col ">
              <p className="w-full truncate text-xs  text-column dark:text-white">{info?.row?.original?.account_details?.sub_type}</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('sub_type', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none text-center font-semibold">Capablities</div>,
      cell: (info) => {
        console.log('info?.row?.original', info?.row?.original);
        return (
          <div className="flex items-center justify-center gap-2 text-center ">
            <div className="flex flex-col text-center">
              <p className="w-full truncate text-xs  text-column dark:text-white">
                {info?.row?.original?.account_details?.voice_enabled && <span className="ml-2 rounded-full bg-blue-100 px-1.5 text-xs text-blue-500">Voice</span>}
                {info?.row?.original?.account_details?.sms_enabled && <span className="ml-2 rounded-full bg-purple-100 px-1.5 text-xs text-purple-500">SMS</span>}
                {info?.row?.original?.account_details?.mms_enabled && <span className="ml-2 rounded-full bg-green-100 px-1.5 text-xs text-green-500">MMS</span>}
              </p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('monthly_rental_rate', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none text-center  font-semibold">Monthly Fee</div>,
      cell: (info) => {
        console.log('info?.row?.original', info?.row?.original);
        return (
          <div className="flex w-full items-center justify-center gap-2 text-center ">
            <div className="flex  flex-col">
              <p className="w-full truncate text-center text-xs text-column dark:text-white">
                ${`${parseFloat(info?.row?.original?.account_details?.monthly_rental_rate).toFixed(3)}`}
              </p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return <CommonRowSettings buttonClassName={'custom_row_btn_0'} uuidRow={row?.row?.original?.uuid} rowData={row.row.original} options={optionsDataRow} />;
      },
    }),
  ];

  React.useEffect(() => {
    // const data = [
    //   // { id: '1', name: 'Assignee', conditions: [{ id: "1", name: 'Is' }, { id: "2", name: 'Is not' }], resultValue: [{ id: "1", name: 'Me' }, { id: "2", name: 'P1' }, { id: "3", name: 'P2' }] },
    //   {
    //     id: '2',
    //     name: 'Status',
    //     uniqueColumnName: 'campaign_status',
    //     conditions: [
    //       { id: '1', name: 'Is', uniqueName: 'is' },
    //       { id: '2', name: 'Is not', uniqueName: 'not' },
    //     ],
    //     resultValue: [
    //       { id: '1', name: 'Paused', uniqueName: '3' },
    //       { id: '2', name: 'Active', uniqueName: '1' },
    //       { id: '3', name: 'Draft', uniqueName: '0' },
    //     ],
    //   },
    //   {
    //     id: '3',
    //     name: 'Open Rate',
    //     uniqueColumnName: 'open_rate',
    //     conditions: [
    //       { id: '1', name: 'More Than', uniqueName: 'gte' },
    //       { id: '2', name: 'Less than', uniqueName: 'lte' },
    //     ],
    //     resultValue: [],
    //   },
    //   {
    //     id: '4',
    //     name: 'Created On',
    //     uniqueColumnName: 'cdate',
    //     conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    //     resultValue: [
    //       { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
    //       { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
    //       { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
    //       { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
    //       { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
    //       { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
    //       { id: '7', name: 'Date Range' },
    //     ],
    //   },
    // ];
    // setOptionData(data);
    setColumns(columnData);
  }, []);

  // React.useEffect(() => {
  //   const fetchList = async () => {
  //     try {
  //       // setIsLoading(true)
  //       const query = setAndGetQueryParams([
  //         { key: 'limit', value: queryParams?.limit },
  //         { key: 'offset', value: queryParams?.offset },
  //         { key: 'search', value: queryParams?.search },
  //       ]);
  //       const response = await BaseApi.get(`/email/settings?${query}`);
  //       const { data, total_records }: any = jwtDecrypt(response?.data);
  //       setQueryParams({ limit: 25, offset: 0, search: '', filter: '', sort: { columnName: '', order: '' } });
  //       if (data && data?.length) {
  //         setData((prev) => ({
  //           data: data,
  //           count: total_records,
  //         }));
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setIsLoading(false)
  //     }
  //   };
  //   fetchList();
  // }, [queryParams?.limit, queryParams?.offset, queryParams?.filter, flagStatus, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  React.useEffect(() => {
    const tempColumns = columnData.filter((val) => {
      const tempId = menuOptions.findIndex((option: any) => option.id === val.id);
      if (tempId === -1) return true;
      return menuOptions[tempId]?.[val.id] === true;
    });
    setColumns(tempColumns);
    // testing ss
  }, [columnOptions, menuOptions]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    dispatch(setCampaignSelectedCSVData(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
  }, [table.getSelectedRowModel()]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (parentRef.current && !parentRef.current.contains(event.target) && !childRef?.current?.contains(event.target)) {
        // setColViewOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [parentRef]);

  // const handleFilter = async (filteredData: any) => {
  //   const encryptedData = jwtEncrypt(filteredData);
  //   setQueryParams((prev) => ({
  //     ...prev,
  //     filter: encryptedData,
  //   }));
  // };

  // const handleColumnData = (colId: string, colState: boolean) => {
  //   setColumnOptions((prevOptions) => {
  //     return prevOptions.map((option) => {
  //       if (option.id === colId) {
  //         return { ...option, [colId]: !colState };
  //       }
  //       return option;
  //     });
  //   });
  // };

  // const dateFormate = (from: number | string, to: number = 1) => {
  //   const fromDate =
  //     typeof from === 'string'
  //       ? moment()
  //           .startOf(from as moment.unitOfTime.StartOf)
  //           .format('YYYY-MM-DD')
  //       : moment().subtract(from, 'days').format('YYYY-MM-DD');
  //   const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
  //   return `${fromDate},${currentDate}`;
  // };

  // console.log(dateFormate('month',0))
  // const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setQueryParams((prev) => ({
  //     ...prev,
  //     search: event.target.value.trim().toLowerCase(),
  //   }));
  // };

  // const generateCodeToTokenAndUserInfo = useCallback(
  //   async (authCode: string) => {
  //     try {
  //       await BaseApi.patch('/sender/accounts/email/auth/code', { code: authCode });
  //       dispatch(setFlagStatus(true));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  //   [dispatch]
  // );
  // useEffect(() => {
  //   if (authCode) {
  //     generateCodeToTokenAndUserInfo(authCode);
  //     navigate('/sender/accounts');
  //   }
  // }, [authCode, generateCodeToTokenAndUserInfo, navigate]);

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
          {!loader ? (
            <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`
                                                ${index === 0 && 'w-[35px]'}
                                                ${index === 1 && 'w-[200px] text-start'}
                                                ${index === 2 && 'w-[130px] text-start'}
                                                ${index >= 3 && index <= 5 && 'w-[130px]'}
                                                ${index === 6 && 'w-[100px]'}
                                                ${index === arr.length - 1 && 'w-[20px]'}
                                                mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center text-column dark:border-white/10 dark:bg-navy-900`}
                        >
                          <div className="text-[11px]">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className="hover:bg-hover dark:hover:bg-navy-900" onClick={() => dispatch(setEmailEditDrawer(true))}>
                        {row.getVisibleCells().map((cell, i) => {
                          const RowDataWidth = columnWidth;
                          return (
                            <td key={cell.id} className={`${RowDataWidth[i]}border-white/0 py-3 text-heading ${(i === 1 || i === 2) && 'text-start'} text-center dark:text-white`}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                      <h6>No Data Found</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <TableLoader
              width="w-full"
              border="border border-gray-100"
              column={5}
              row={15}
              skeletonHeight="h-2"
              padding="px-5 py-4"
              tableViewBg={{ headBg: 'bg-gray-50' }}
              columnWidth="4% 16% 60% 16% 4%"
            />
          )}
        </div>
      </div>
      {multiSelectState && <MultiselectGlobalOpt options={multiOpt} />}
      <ConfirmationPopup
        isOpen={confirm?.isOpen}
        loading={loading}
        headerContent={confirm?.headerContent}
        bodyContent={confirm?.bodyContent}
        bodyRedContent={confirm?.bodyRedContent}
        descriptionContent={confirm?.descriptionContent}
        handleCancel={handleConfirmCancel}
        handleConfirmFunction={handleConfirmSubmitDelete}
      />
      {/* <EmailEditSettings /> */}
      {editState && <EditSenderListDrawer fetchList={fetchList} onClose={() => setEditState(false)} isOpen={editState} editData={editRowData} />}
      {/* {custom?.flagStatus && <AddEmailAccountsDrawerLayout />} */}
      {/* <AddEmailAccountsDrawerLayout /> */}
      {/* <SelectMethodPopup isOpen={accountType === 'email'} /> */}
    </>
  );
};

export default SmsCallAccountsList;
