import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  //   ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import Input from 'common/sparkle-common/Input';
//import { BaseApi } from 'common/api/common/base-api';
import { ModalComponentProp } from 'common/dto/types/views/blacklist';
import React from 'react';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import RoleDropdown from './RoleDropdown';
import WorkspaceDropdown from './WorkspaceDropdown';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import { CustomMenu } from 'components/customMenu';
import { BiChevronDown } from 'react-icons/bi';
import InputField from 'components/fields/InputField';
// import CommonSearchDropdown from 'components/SearchDropdown/CommonSearchDropdown';

interface CreditData {
  key: string;
  value: string | number;
}

interface PayloadData {
  role_access_id: string;
  username: string;
  workspace_access_ids: string[];
  email: string;
  credit_data: CreditData;
}

const AddUserManagementModal = (props: ModalComponentProp) => {
  const { isOpen, onClose } = props;
  const [openOption, setOpenOption] = React.useState(false);
  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);
  const modalBodyRef = React.useRef(null);
  const customLimitRef = React.useRef(null);
  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  //   const [popupdata,setPopupdata] = React.useState({   userManagemenRoleOptions,
  //     name:'',email:'',role:'',workspace:'',branch:'',credit:''
  //   })
  //   const [menuData, setMenuData] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleSelectedData, setRoleSelectedData] = React.useState([]);
  const [payloadData, setPayloadData] = React.useState<PayloadData>({
    role_access_id: '',
    username: '',
    workspace_access_ids: [],
    email: '',
    credit_data: {
      key: '',
      value: '',
    },
  });
  const [workspaceSelectedData, setWorkspaceSelectedData] = React.useState([]);
  const [placeholderName, setPlaceholderName] = React.useState({
    role: [],
    workspace: [],
  });
  const [roleoption, setroleOption] = React.useState([]);
  const [workspaceoption, setworkspaceOption] = React.useState([]);

  React.useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [openOption]);

  const handleRoleSelectionChange = (selectedOptions: any) => {
    if (selectedOptions) {
      setPlaceholderName({
        ...placeholderName,
        role: selectedOptions[selectedOptions?.length - 1].role_name,
      });
      setRoleSelectedData(selectedOptions.map((element: { uuid: string }) => element.uuid));
      setPayloadData({
        ...payloadData,
        role_access_id: selectedOptions[0]?.uuid,
      });
    } else {
      setPlaceholderName({
        ...placeholderName,
        role: [],
      });
      setPayloadData({
        ...payloadData,
        role_access_id: '',
      });
    }
  };
  const handleWorkspaceSelectionChange = (selectedOptions: any) => {
    console.log('selectedOptions', selectedOptions);
    if (selectedOptions.length) {
      setPlaceholderName({
        ...placeholderName,
        workspace: selectedOptions[selectedOptions?.length - 1].name,
      });
      setWorkspaceSelectedData(selectedOptions.map((element: { uuid: string }) => element.uuid));
      setPayloadData({
        ...payloadData,
        workspace_access_ids: selectedOptions.map((element: { uuid: string }) => element.uuid),
      });
    } else {
      setPlaceholderName({ ...placeholderName, workspace: [] });
      setPayloadData({
        ...payloadData,
        workspace_access_ids: [],
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        role_access_id: payloadData?.role_access_id,
        username: payloadData?.username,
        workspace_access_ids: payloadData?.workspace_access_ids,
        email: payloadData?.email,
        credit: payloadData?.credit_data?.value,
      };
      setLoading(true);
      const apiResponse = await BaseApi.post('user/role/management', {
        ...payload,
        redirect_url: window.location.origin,
      });
      // console.log(apiResponse);
      if (!apiResponse.error) {
        dispatch(getErrorMessage({}));
        toast.success('Invite Sent Successfully', toastState.success);
        dispatch(setFlagStatus(!custom?.flagStatus));

        handleCancel();
      } else {
        dispatch(getErrorMessage(apiResponse?.message));

        // toast.error('Invite Not Sent', toastState.error);
        // handleCancel();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await BaseApi.get('/role/management');
      if (response.data?.data?.length) {
        setroleOption(response?.data?.data);
      } else {
        setroleOption([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchWorkspaceData = async () => {
    try {
      const response = await BaseApi.get('/workspace');
      if (response.data?.data?.length) {
        setworkspaceOption(response?.data?.data);
      } else {
        setworkspaceOption([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPayloadData({
      ...payloadData,
      [name]: value,
    });
  };

  React.useEffect(() => {
    fetchRoleData();
    fetchWorkspaceData();
  }, []);

  const handleCreditChange = async (type: string) => {
    if (type === 'no_limit') {
      setPayloadData((prevState) => ({
        ...prevState,
        credit_data: {
          key: type,
          value: type,
        },
      }));
      setOpenOption(false);
    } else {
      setPayloadData((prevState) => ({
        ...prevState,
        credit_data: {
          key: type,
          value: '',
        },
      }));

      if (type === 'custom_limit') {
        setTimeout(() => {
          if (customLimitRef.current) {
            customLimitRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
      }
    }
  };

  // const disable = payloadData?.role_access_id && payloadData.username && payloadData.email && payloadData.workspace_access_ids.length ? false : true;

  const handleCancel = () => {
    onClose();
    setRoleSelectedData([]);
    setWorkspaceSelectedData([]);
    setPlaceholderName({
      role: [],
      workspace: [],
    });
    dispatch(getErrorMessage({}));
    setPayloadData({
      role_access_id: '',
      username: '',
      workspace_access_ids: [],
      email: '',
      credit_data: {
        key: '',
        value: '',
      },
    });
  };

  const handleCreditInputChange = (value: any) => {
    setPayloadData((prevState) => ({
      ...prevState,
      credit_data: {
        ...prevState.credit_data,
        value: value,
      },
    }));
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size="xl">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent className="dark:bg-darkOne ">
        <ModalHeader className="mb-1 rounded-t-md bg-[#FAFAFC] dark:bg-darkTwo">
          <div className="flex items-center  gap-3">
            <h5 className="text-lg font-semibold text-heading  dark:!text-white">Add User</h5>
          </div>
          {/* <ModalCloseButton
            size={'lg'}
            color={'#4318FF'}
            id="add-blacklist-pop-close"
            className="mr-2 mt-1"
          /> */}
        </ModalHeader>
        <ModalBody className="h-96 overflow-y-auto !p-3" ref={modalBodyRef}>
          <div className=" " id="user-management-add-modal">
            <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkThree">
              <p className="ml-2 text-[13px]  font-semibold text-[#1B2559] dark:text-white">Name <span className="text-red-500">*</span></p>
              <Input
                name="username"
                placeholder="Name"
                customClass={` w-full text-[19px] user-manage-name-input dark:bg-darkOne dark:text-white  ${
                  errorMessage?.username ? `!border-red-500 dark:!border-red-500` : `dark:border-none`
                }`}
                onChange={handleChange}
              />
              {errorMessage?.username && (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.username}</span>
              )}
            </div>

            <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkThree">
              <p className="ml-2 text-[13px] font-semibold text-[#1B2559] dark:text-white">Email <span className="text-red-500">*</span></p>
              <Input
                name="email"
                placeholder="Email"
                customClass={`w-full text-[19px]    user-manage-email-input dark:bg-darkOne dark:text-white  ${
                  errorMessage?.email ? `!border-red-500 dark:!border-red-500` : `dark:border-none`
                } `}
                onChange={handleChange}
              />
              {errorMessage?.email && <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.email}</span>}
            </div>

            <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkThree">
              <p className="ml-2 text-[13px] font-semibold text-gray-900 dark:text-white">Add Role <span className="text-red-500">*</span></p>
              <RoleDropdown
                id="Role-dropdown-btn"
                options={roleoption}
                placeholderName={placeholderName?.role?.length ? placeholderName.role : 'Select Role'}
                onSelectionChange={handleRoleSelectionChange}
                error={errorMessage?.role_access_id}
              />
              {errorMessage?.role_access_id && (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.role_access_id}</span>
              )}
            </div>

            <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkThree">
              <div className="flex justify-between">
                <p className="ml-2 text-[13px] font-semibold text-[#1B2559] dark:text-white">Add Workspace <span className="text-red-500">*</span></p>
                {workspaceSelectedData?.length > 1 && (
                  <div className="relative">
                    <span className="flex cursor-pointer items-center justify-center rounded-full  bg-[#dfd8fc] px-2 py-[0.5px] text-[.625rem] font-medium">
                      +{workspaceSelectedData?.length - 1} Workspace Selected
                    </span>
                  </div>
                )}
              </div>

              <WorkspaceDropdown
                id="workspace-dropdown-btn"
                options={workspaceoption}
                placeholderName={placeholderName?.workspace?.length ? placeholderName.workspace : 'Select Workspace'}
                onSelectionChange={handleWorkspaceSelectionChange}
                error={errorMessage?.workspace_access_ids}
              />
              {errorMessage?.workspace_access_ids && (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.workspace_access_ids}</span>
              )}
            </div>

            <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkThree ">
              <p className="ml-2 text-[13px] font-semibold text-[#1B2559] dark:text-white">Credit Limit <span className="text-red-500">*</span></p>
              <CustomMenu
                open={openOption}
                onOpen={() => {
                  setOpenOption(true);
                }}
                btnId='credit-select'
                onClose={() => setOpenOption(false)}
                btnContent={payloadData?.credit_data?.key ? payloadData?.credit_data?.key.replace('_', ' ') : 'Select Credits'}
                btnClassName="w-full capitalize justify-between !text-sm text  !rounded-md !font-normal truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF] !h-9 text-center items-cemter stage-menu"
                menuListClassName="w-full !mt-1 !p-0 border border-menuBorder rounded-md "
                rightIcon={<BiChevronDown size={18} />}
              >
                <div className="p-2">
                  <div className="max-h-25 mt-2 overflow-y-auto">
                    <div
                    id='no-limit-opt'
                      className={`w-full cursor-pointer py-1 text-center text-[.8125rem] font-medium  text-column ${
                        payloadData?.credit_data?.key === 'no_limit' ? 'bg-blueSecondary text-white' : ' hover:bg-gray-100'
                      }`}
                      onClick={() => handleCreditChange('no_limit')}
                    >
                      No Limit
                    </div>
                    <div
                      className={` w-full cursor-pointer py-1 text-center text-[.8125rem] font-medium text-column ${
                        payloadData?.credit_data?.key === 'custom_limit' ? 'bg-blueSecondary text-white' : 'hover:bg-gray-100'
                      }`}
                      onClick={() => handleCreditChange('custom_limit')}
                    >
                      Custom Limit
                    </div>

                    {payloadData?.credit_data?.key === 'custom_limit' ? (
                      <div className="mt-3" ref={customLimitRef}>
                        <div className="ml-1 text-sm text-column">Custom Credit Limit</div>
                        <InputField
                          label={''}
                          type="number"
                          value={payloadData?.credit_data?.key === 'custom_limit' ? payloadData?.credit_data?.value : ''}
                          placeholder="Enter custom credit limit"
                          inputClass="!rounded-md !h-9 "
                          customClass="!mt-0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCreditInputChange(event.target.value)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </CustomMenu>
              {errorMessage?.credit && (
                <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.credit}</span>
              )}
            </div>
          </div>

          {/* COmmon Search DropDown Data Checking */}
          {/* <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2">
              <div className="flex justify-between">
                <p className="ml-2 text-[13px] font-semibold text-[#1B2559]">Common DropDown</p>
              </div>
              <CommonSearchDropdown menuType={'radio'} />
            </div> */}

          {/* <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2">
              <p className="ml-2 text-[13px] font-semibold text-[#1B2559]">
                Credit
              </p>
              <Input
                placeholder="Credit"
                customClass="w-full text-[19px]      "
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div> */}
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-5">
          <Button
            id="blacklist-close"
            name="Cancel"
            customClass="!bg-[#F4F7FE] dark:!text-blackDark  w-full !text-[#A3AED0] border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            id="invite-btn"
            disable={loading}
            loading={loading}
            name="Send Invite"
            customClass="send_user_invite_btn w-full !text-[#FFFFFF] dark:border-none !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            onClick={() => handleSubmit()}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUserManagementModal;
