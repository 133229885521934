import OnboardBackground from 'components/crmOnboard/OnbardBackground';
import Button from 'common/sparkle-common/Button';
import React from 'react';
import Input from 'common/sparkle-common/Input';
import { Card, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { getPipelineDetail } from 'store/crm-onboard';

const OnboardCustomizePipeline = () => {
  const navigate = useNavigate();
  const getPipeline = useAppSelector((state) => state.crmOnboard);
  const dispatch = useAppDispatch();
  const [pipelineName, setPipelineName] = React.useState(getPipeline?.pipelinename);
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPipelineName(e.target.value);
  };
  const onPipelineClick = async () => {
    try {
      const response = await BaseApi.patch(`/crm/pipeline`, {
        pipeline_name: pipelineName,
        uuid: getPipeline?.pipelineid,
      });
      if (response?.status === 200) {
        dispatch(
          getPipelineDetail({
            pipelinename: pipelineName,
            pipelineid: getPipeline?.pipelineid,
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/crm/onboard/pipeline/stages');
    }
  };

  return (
    <OnboardBackground count={4}>
      <div className="flex justify-between gap-x-10">
        <div className="mt-16! flex  w-[35rem] flex-col justify-start gap-8 py-16">
          <div>
            <p className="mt-12 text-[1.8125rem] font-bold text-heading dark:text-purple-600">Customize your Pipeline</p>
            <p className="mt-3 w-[80%] text-justify text-sm font-medium text-heading">
              Your pipeline is an essential tool for tracking for your deals as the progress through the sales process- all the way from first contact, to final purchase decision.
            </p>
            <p className="mt-4 w-[80%] text-sm font-medium text-heading">The first step is to give your pipeline a clear and recognizable name.</p>
          </div>
          <div>
            <p className="mb-4 text-[1.4rem] font-semibold text-heading dark:text-purple-600">Edit the name of your pipeline</p>
            <Input placeholder={'Appointments pipeline'} customClass="!w-96" value={pipelineName} onChange={(e) => onInputChange(e)} />
          </div>
          <div>
            <Button name="Define deal stages" id={'onboard-define-stage-btn'} px="px-6" py="py-3" fontSize="xs" customClass="mt-6!" onClick={() => onPipelineClick()} />
          </div>
        </div>
        <div className="mt-8 h-full w-[52rem]">
          <div className="rounded-lg bg-[#fff] p-2">
            <Stack>
              <Card className="h-10 px-4 py-2">
                <p className="font-semibold text-[#2B3674]">{pipelineName}</p>
              </Card>
            </Stack>
            <Card className="mt-2 h-[29rem]"></Card>
          </div>
        </div>
      </div>
    </OnboardBackground>
  );
};

export default OnboardCustomizePipeline;
