import { CustomMenu } from "components/customMenu";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "store";
import { setIds, setBrandInput } from "store/campaign-v2";
import CreateBrandModal from "./CreateBrandModal";
import { getErrorMessage } from "store/custom";
import { Spinner } from "@chakra-ui/react";

type BrandHeaderComponentProps = {
  brandList: any[];
}

const BrandHeaderComponent = (props: BrandHeaderComponentProps) => {
  const { brandList } = props;
  const [modalView, setModalView] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state?.campaignv2)

  const handleClickBrand = (item: { [key: string]: string }) => {
    const { name, icp, pain_points, solution, geography_target, about_brand_details } = item;
    dispatch(setIds({ brand_id: item?.uuid }));
    dispatch(
      setBrandInput({
        name: name,
        icp: icp || '',
        pain_points: pain_points || '',
        solution: solution || '',
        geography_target: geography_target || '',
        about_brand_details: about_brand_details || '',
      })
    );
    setIsOpen(false);
  };

  return (
    <>
      <CustomMenu
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        btnId='select_brand'
        btnContent={state?.brand_input?.name || 'Select Brand'}
        rightIcon={<BiChevronDown />}
        btnClassName="flex items-center justify-between w-[25rem] text-column capitalize dark:!border-none dark:!text-white dark:bg-purple-800 dark:hover:bg-purple-600 dark:active:bg-purple-700"
        menuListClassName="!p-0 max-h-32 w-full overflow-y-auto dark:bg-darkTwo"
      >
        <button
          onClick={() => {
            dispatch(setIds({ brand_id: '' }));
            dispatch(setBrandInput({ name: '', icp: '', pain_points: '', solution: '', geography_target: '', about_brand_details: '' }))
            setModalView(true);
          }}
          key={'select'}
          className={`w-full py-2 px-3 text-nowrap text-center text-xs text-heading font-medium hover:text-heading hover:bg-lightPrimary dark:hover:bg-purple-800 dark:text-white dark:hover:text-white capitalize rounded-t-md`}>
          + Create New Brand
        </button>
        {brandList === null ? <Spinner size={'sm'} thickness="2px" speed="0.8s" className={`w-full text-brand-500 relative left-[48%] -translate-x-1/2`} /> :
          brandList?.map((item, index: number) => {
            return (
              <button
                onClick={() => handleClickBrand(item)}
                key={index}
                className={`w-full py-2 px-3 text-nowrap text-center text-xs text-heading font-medium hover:bg-lightPrimary hover:text-heading dark:text-white dark:hover:bg-purple-800 dark:hover:text-white capitalize ${index === brandList?.length - 1 ? 'rounded-b-md' : ''
                  } ${state?.brand_input?.name === item?.name && 'text-heading bg-hover dark:bg-purple-800'}`}
              >
                {item?.name}
              </button>
            );
          })}
      </CustomMenu>
      {<CreateBrandModal
        isOpen={modalView}
        onClose={(newBrandData: any) => {
          dispatch(getErrorMessage({}))
          setModalView(false);
          const brandDetails = newBrandData && Object.values(newBrandData).every(Boolean)
          brandDetails && brandList.unshift(newBrandData);
          brandDetails && dispatch(setIds({ brand_id: newBrandData?.uuid }))
        }} />}
    </>
  );
};

export default BrandHeaderComponent