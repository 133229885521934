import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBriefcase } from 'react-icons/bi';
import { BsFillPeopleFill } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';

import { useAppDispatch, useAppSelector } from 'store';
import { setFilterData } from 'store/campaign-v2';
import SparkleRadio from 'common/sparkle-common/Radio';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import CheckBoxItem from './CheckBoxItem';
import CustomAccordion from './CustomAccordian';
import OrganizationListDropdown from './OrganizationListDropdown';
import FilterDropDown from './FilterDropDown';
import Button from 'common/sparkle-common/Button';
import { BaseApi } from 'common/api/common/base-api';
import { _errorHandler, toastState } from 'common/utils/utility';
import { toast } from 'react-toastify';



interface FilterComponentType {
    searchType: 'people' | 'company';
}

const FilterElements = (props: FilterComponentType) => {
    const { searchType } = props;
    const dispatch = useAppDispatch();
    const filterData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.prospects_filter.filter_data);
    const { people_name, job_titles, companies,technologies, location, employees, industry_keywords, revenue, funding, job_posting } = filterData;
    const [localState, setLocaleState] = React.useState({
        mile_dropdown: false,
        include_list_id: false,
        exclude_list_id: false,
    })

    const handleChangeLocalState = (key: string, value: boolean) => {
        setLocaleState((prev) => ({ ...prev, [key]: value }))
    }

    const handleChange = (key1: string, key2: string, key3: string, data: any) => {
        dispatch(setFilterData({ key1, key2, key3, data }));
    };

    const handleJobTitleDepartmentChange = (data: string[]) => {
        const selectedJobTitles = job_titles?.department_job_function;
        const filteredData = data?.filter((item) => !selectedJobTitles.includes(item));
        const finalValue = [...selectedJobTitles, ...filteredData];
        handleChange('job_titles', 'department_job_function', '', finalValue);
    };

    const handleAddCompanyList = async (data: string, type: 'include_list_id' | 'exclude_list_id') => {
        try {
            handleChangeLocalState(type, true)
            const response = await BaseApi.post('/prospects/organization/search', { organization_list_type: 'include', list: data });
            if (!response?.error) {
                handleChange('companies', type, '', response?.data?.listId)
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error);
            }
        } catch (error) {
            console.log('FilterElements/handleAddCompanyList - error', error)
        } finally {
            handleChangeLocalState(type, false)
        }
    }

    return (
        <div className="flex flex-col items-center gap-3">
            {/* Name */}
            {searchType !== 'company' && (
                <CustomAccordion title="Name" icon={<AiOutlineUser size={16} />}>
                    <InputField
                        value={people_name}
                        label=""
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('people_name', '', '', event?.target?.value)}
                        customClass="!h-9 !mt-0"
                        inputClass="!rounded-md !bg-white"
                        placeholder="Enter name..."
                    />
                </CustomAccordion>
            )}

            {/* Job titles */}
            {searchType !== 'company' && (
                <CustomAccordion title="Job Titles" icon={<BiBriefcase size={16} />}>
                    <div>
                        <div>
                            <div className="mb-2 rounded-md border-2 bg-white px-2 pt-2 text-sm font-medium text-column">
                                <div
                                    className="mb-2 flex cursor-pointer items-center gap-2 text-sm font-medium text-column"
                                    onClick={() => handleChange('job_titles', 'selected_method', '', 'is_any_of')}
                                >
                                    <SparkleRadio checked={job_titles?.selected_method === 'is_any_of'} /> <span>Is any of</span>
                                </div>
                                {job_titles.selected_method === 'is_any_of' && (
                                    <div>
                                        {/* <InputField
                                            value={job_titles?.is_any_of_data?.job_titles}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_titles', 'is_any_of_data', 'job_titles', event?.target?.value)}
                                            label=""
                                            customClass="!bg-white !rounded-md !h-9 mb-2"
                                            inputClass="!rounded-md"
                                            placeholder="Search for a job title"
                                        /> */}
                                        <FilterDropDown defaultData ={job_titles?.is_any_of_data?.job_titles || []} kind='job_titles' placeHolder='Job Titles' onSelect={(data: any) => handleChange('job_titles', 'is_any_of_data', 'job_titles', data)} />
                                        <CheckBoxItem
                                            list={[{ id: 'is_not_any_of', name: 'Is not any of' }]}
                                            selectedList={job_titles?.view?.is_not_any_of ? ['is_not_any_of'] : []}
                                            onChange={(data) => handleChange('job_titles', 'view', 'is_not_any_of', data.length > 0)}
                                        />
                                        {job_titles.view.is_not_any_of && (
                                            <FilterDropDown defaultData ={job_titles?.is_any_of_data?.is_not_any_of || []} kind='job_titles' placeHolder='Job Titles' onSelect={(data: any) => handleChange('job_titles', 'is_any_of_data', 'is_not_any_of', data)} />

                                            // <InputField
                                            //     value={job_titles?.is_any_of_data?.is_not_any_of}
                                            //     onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_titles', 'is_any_of_data', 'is_not_any_of', event?.target?.value)}
                                            //     label=""
                                            //     customClass="!bg-white !rounded-md !h-9 mb-2"
                                            //     inputClass="!rounded-md"
                                            //     placeholder="Enter titles to exclude"
                                            // />
                                        )}
                                        <CheckBoxItem
                                            list={[{ id: 'include_pate_job_titles', name: 'Include past Job Titles' }]}
                                            selectedList={job_titles?.view?.include_past_job_titles ? ['include_pate_job_titles'] : []}
                                            onChange={(data) => handleChange('job_titles', 'view', 'include_past_job_titles', data.length > 0)}
                                        />
                                        {job_titles.view.include_past_job_titles && (
                                            <FilterDropDown defaultData ={job_titles?.is_any_of_data?.include_past_job_titles || []} kind='job_titles' placeHolder='Job Titles' onSelect={(data: any) => handleChange('job_titles', 'is_any_of_data', 'include_past_job_titles', data)} />

                                            // <InputField
                                            //     value={job_titles?.is_any_of_data?.include_past_job_titles}
                                            //     onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_titles', 'is_any_of_data', 'include_past_job_titles', event?.target?.value)}
                                            //     label=""
                                            //     customClass="!bg-white !rounded-md !h-9 mb-2"
                                            //     inputClass="!rounded-md"
                                            //     placeholder="Search for a past job title"
                                            // />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className="mb-2 flex cursor-pointer items-center gap-2 rounded-md border-2 bg-white p-2 text-sm font-medium  text-column"
                                onClick={() => handleChange('job_titles', 'selected_method', '', 'is_known')}
                            >
                                <SparkleRadio checked={job_titles?.selected_method === 'is_known'} /> <span>Is known</span>
                            </div>
                            <div
                                className="mb-2 flex cursor-pointer items-center gap-2 rounded-md border-2 bg-white p-2 text-sm font-medium  text-column"
                                onClick={() => handleChange('job_titles', 'selected_method', '', 'is_unknown')}
                            >
                                <SparkleRadio checked={job_titles?.selected_method === 'is_unknown'} /> <span>Is unknown</span>
                            </div>
                        </div>
                        <div className="mb-2">
                            <CustomAccordion title="Management level" bg="bg-white hover:!bg-white">
                                <CheckBoxItem
                                    selectedList={job_titles?.management_level}
                                    list={[
                                        { id: 'owner', name: 'Owner' },
                                        { id: 'founder', name: 'Founder' },
                                        { id: 'c_suite', name: 'C suite' },
                                        { id: 'partner', name: 'Partner' },
                                        { id: 'vp', name: 'Vp' },
                                        { id: 'head', name: 'Head' },
                                        { id: 'director', name: 'Director' },
                                        { id: 'manager', name: 'Manager' },
                                        { id: 'senior', name: 'Senior' },
                                        { id: 'entry', name: 'entry' },
                                        { id: 'intern', name: 'Intern' },
                                    ]}
                                    onChange={(data) => handleChange('job_titles', 'management_level', '', data)}
                                />
                            </CustomAccordion>
                        </div>
                        <div>
                            <CustomAccordion title="Department & job function" bg="bg-white hover:!bg-white">
                                <div>
                                    {/* C-Suite */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>C-suite</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'executive', name: 'Executive' },
                                                    { id: 'finance_executive', name: 'Finance Executive' },
                                                    { id: 'founder', name: 'Founder' },
                                                    { id: 'human_resources_executive', name: 'Human Resources Executive' },
                                                    { id: 'information_technology_executive', name: 'Information Technology Executive' },
                                                    { id: 'legal_executive', name: 'Legal Executive' },
                                                    { id: 'marketing_executive', name: 'Marketing Executive' },
                                                    { id: 'medical_health_executive', name: 'Medical & Health Executive' },
                                                    { id: 'operations_executive', name: 'Operations Executive' },
                                                    { id: 'sales_executive', name: 'Sales Leader' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Product */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Product</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'product_development', name: 'Product Development' },
                                                    { id: 'product_mangement', name: 'Product Management' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Engineering & Technical */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Engineering & Technical</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'artificial_intelligence_machine_learning', name: 'Artificial Intelligence / Machine Learning' },
                                                    { id: 'bioengineering', name: 'Bioengineering' },
                                                    { id: 'biometrics', name: 'Biometrics' },
                                                    { id: 'business_intelligence', name: 'Business Intelligence' },
                                                    { id: 'chemical_engineering', name: 'Chemical Engineering' },
                                                    { id: 'cloud_mobility', name: 'Cloud / Mobility' },
                                                    { id: 'data_science', name: 'Data Science' },
                                                    { id: 'devops', name: 'DevOps' },
                                                    { id: 'digital_transformation', name: 'Digital Transformation' },
                                                    { id: 'emerging_technology_innovation', name: 'Emerging Technology / Innovation' },
                                                    { id: 'engineering_technical', name: 'Engineering & Technical' },
                                                    { id: 'industrial_engineering', name: 'Industrial Engineering' },
                                                    { id: 'mechanic', name: 'Mechanic' },
                                                    { id: 'mobile_development', name: 'Mobile Development' },
                                                    { id: 'project_management', name: 'Project Management' },
                                                    { id: 'research_development', name: 'Research & Development' },
                                                    { id: 'scrum_master_agile_coach', name: 'Scrum Master / Agile Coach' },
                                                    { id: 'software_development', name: 'Software Development' },
                                                    { id: 'support_technical_services', name: 'Support / Technical Services' },
                                                    { id: 'technician', name: 'Technician' },
                                                    { id: 'technology_operations', name: 'Technology Operations' },
                                                    { id: 'test_quality_assurance', name: 'Test / Quality Assurance' },
                                                    { id: 'ui_ux', name: 'UI / UX' },
                                                    { id: 'web_development', name: 'Web Development' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Design */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Design</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'all_design', name: 'All Design' },
                                                    { id: 'product_ui_ux_design', name: 'Product or UI/UX Design' },
                                                    { id: 'graphic_design', name: 'Graphic / Visual / Brand Design' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Education */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Education</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'accounting', name: 'Accounting' },
                                                    { id: 'finance', name: 'Finance' },
                                                    { id: 'financial_planning_analysis', name: 'Financial Planning & Analysis' },
                                                    { id: 'financial_reporting', name: 'Financial Reporting' },
                                                    { id: 'financial_strategy', name: 'Financial Strategy' },
                                                    { id: 'financial_systems', name: 'Financial Systems' },
                                                    { id: 'internal_audit_control', name: 'Internal Audit & Control' },
                                                    { id: 'investor_relations', name: 'Investor Relations' },
                                                    { id: 'mergers_acquisitions', name: 'Mergers & Acquisitions' },
                                                    { id: 'real_estate_finance', name: 'Real Estate Finance' },
                                                    { id: 'financial_risk', name: 'Financial Risk' },
                                                    { id: 'shared_services', name: 'Shared Services' },
                                                    { id: 'sourcing_procurement', name: 'Sourcing / Procurement' },
                                                    { id: 'tax', name: 'Tax' },
                                                    { id: 'treasury', name: 'Treasury' },
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    {/* Finance */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Finance</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'teacher', name: 'Teacher' },
                                                    { id: 'principal', name: 'Principal' },
                                                    { id: 'superintendent', name: 'Superintendent' },
                                                    { id: 'professor', name: 'Professor' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Human resources */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Human resources</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'compensation_benefits', name: 'Compensation & Benefits' },
                                                    { id: 'culture_diversity_inclusion', name: 'Culture, Diversity & Inclusion' },
                                                    { id: 'employee_labor_relations', name: 'Employee & Labor Relations' },
                                                    { id: 'health_safety', name: 'Health & Safety' },
                                                    { id: 'human_resource_information_system', name: 'Human Resource Information System' },
                                                    { id: 'human_resources', name: 'Human Resources' },
                                                    { id: 'hr_business_partner', name: 'HR Business Partner' },
                                                    { id: 'learning_development', name: 'Learning & Development' },
                                                    { id: 'organizational_development', name: 'Organizational Development' },
                                                    { id: 'recruiting_talent_acquisition', name: 'Recruiting & Talent Acquisition' },
                                                    { id: 'talent_management', name: 'Talent Management' },
                                                    { id: 'workforce_mangement', name: 'Workforce Management' },
                                                    { id: 'people_operations', name: 'People Operations' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Information technology */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Information technology</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'application_development', name: 'Application Development' },
                                                    { id: 'business_service_management_itsm', name: 'Business Service Management / ITSM' },
                                                    { id: 'collaboration_web_app', name: 'Collaboration & Web App' },
                                                    { id: 'data_center', name: 'Data Center' },
                                                    { id: 'data_warehouse', name: 'Data Warehouse' },
                                                    { id: 'database_administration', name: 'Database Administration' },
                                                    { id: 'ecommerce_development', name: 'eCommerce Development' },
                                                    { id: 'enterprise_architecture', name: 'Enterprise Architecture' },
                                                    { id: 'help_desk_desktop_services', name: 'Help Desk / Desktop Services' },
                                                    { id: 'hr_financial_erp_systems', name: 'HR / Financial / ERP Systems' },
                                                    { id: 'information_security', name: 'Information Security' },
                                                    { id: 'information_technology', name: 'Information Technology' },
                                                    { id: 'infrastructure', name: 'Infrastructure' },
                                                    { id: 'it_asset_management', name: 'IT Asset Management' },
                                                    { id: 'it_audit_it_compliance', name: 'IT Audit / IT Compliance' },
                                                    { id: 'it_operations', name: 'IT Operations' },
                                                    { id: 'it_procurement', name: 'IT Procurement' },
                                                    { id: 'it_strategy', name: 'IT Strategy' },
                                                    { id: 'it_training', name: 'IT Training' },
                                                    { id: 'networking', name: 'Networking' },
                                                    { id: 'project_program_management', name: 'Project & Program Management' },
                                                    { id: 'quality_assurance', name: 'Quality Assurance' },
                                                    { id: 'retail_store_systems', name: 'Retail / Store Systems' },
                                                    { id: 'servers', name: 'Servers' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Legal */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Legal</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'acquisitions', name: 'Acquisitions' },
                                                    { id: 'compliance', name: 'Compliance' },
                                                    { id: 'contracts', name: 'Contracts' },
                                                    { id: 'corporate_secretary', name: 'Corporate Secretary' },
                                                    { id: 'ediscovery', name: 'eDiscovery' },
                                                    { id: 'ethics', name: 'Ethics' },
                                                    { id: 'governance', name: 'Governance' },
                                                    { id: 'governmental_affairs_regulatory_law', name: 'Governmental Affairs & Regulatory Law' },
                                                    { id: 'intellectual_property_patent', name: 'Intellectual Property & Patent' },
                                                    { id: 'labor_employment', name: 'Labor & Employment' },
                                                    { id: 'lawyer_attorney', name: 'Lawyer / Attorney' },
                                                    { id: 'legal', name: 'Legal' },
                                                    { id: 'legal_counsel', name: 'Legal Counsel' },
                                                    { id: 'legal_operations', name: 'Legal Operations' },
                                                    { id: 'litigation', name: 'Litigation' },
                                                    { id: 'privacy', name: 'Privacy' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Marketing */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Marketing</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'advertising', name: 'Advertising' },
                                                    { id: 'brand_management', name: 'Brand Management' },
                                                    { id: 'content_marketing', name: 'Content Marketing' },
                                                    { id: 'customer_experience', name: 'Customer Experience' },
                                                    { id: 'customer_marketing', name: 'Customer Marketing' },
                                                    { id: 'demand_generation', name: 'Demand Generation' },
                                                    { id: 'digital_marketing', name: 'Digital Marketing' },
                                                    { id: 'ecommerce_marketing', name: 'eCommerce Marketing' },
                                                    { id: 'event_marketing', name: 'Event Marketing' },
                                                    { id: 'field_marketing', name: 'Field Marketing' },
                                                    { id: 'lead_generation', name: 'Lead Generation' },
                                                    { id: 'marketing', name: 'Marketing' },
                                                    { id: 'marketing_analytics_insights', name: 'Marketing Analytics / Insights' },
                                                    { id: 'marketing_communications', name: 'Marketing Communications' },
                                                    { id: 'marketing_operations', name: 'Marketing Operations' },
                                                    { id: 'product_marketing', name: 'Product Marketing' },
                                                    { id: 'public_relations', name: 'Public Relations' },
                                                    { id: 'search_engine_optimization_pay_per_click', name: 'Search Engine Optimization / Pay Per Click' },
                                                    { id: 'social_media_marketing', name: 'Social Media Marketing' },
                                                    { id: 'strategic_communications', name: 'Strategic Communications' },
                                                    { id: 'technical_marketing', name: 'Technical Marketing' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Medical & health */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Medical & health</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'anesthesiology', name: 'Anesthesiology' },
                                                    { id: 'chiropractics', name: 'Chiropractics' },
                                                    { id: 'clinical_systems', name: 'Clinical Systems' },
                                                    { id: 'dentistry', name: 'Dentistry' },
                                                    { id: 'dermatology', name: 'Dermatology' },
                                                    { id: 'doctors_physicians', name: 'Doctors / Physicians' },
                                                    { id: 'epidemiology', name: 'Epidemiology' },
                                                    { id: 'first_responder', name: 'First Responder' },
                                                    { id: 'infectious_disease', name: 'Infectious Disease' },
                                                    { id: 'medical_administration', name: 'Medical Administration' },
                                                    { id: 'medical_education_training', name: 'Medical Education & Training' },
                                                    { id: 'medical_research', name: 'Medical Research' },
                                                    { id: 'medicine', name: 'Medicine' },
                                                    { id: 'neurology', name: 'Neurology' },
                                                    { id: 'nursing', name: 'Nursing' },
                                                    { id: 'nutrition_dietetics', name: 'Nutrition & Dietetics' },
                                                    { id: 'obstetrics_gynecology', name: 'Obstetrics / Gynecology' },
                                                    { id: 'oncology', name: 'Oncology' },
                                                    { id: 'opthalmology', name: 'Opthalmology' },
                                                    { id: 'optometry', name: 'Optometry' },
                                                    { id: 'orthopedics', name: 'Orthopedics' },
                                                    { id: 'pathology', name: 'Pathology' },
                                                    { id: 'pediatrics', name: 'Pediatrics' },
                                                    { id: 'pharmacy', name: 'Pharmacy' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Operations */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Operations</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[
                                                    { id: 'account_management', name: 'Account Management' },
                                                    { id: 'business_development', name: 'Business Development' },
                                                    { id: 'channel_sales', name: 'Channel Sales' },
                                                    { id: 'customer_retention_development', name: 'Customer Retention & Development' },
                                                    { id: 'customer_success', name: 'Customer Success' },
                                                    { id: 'field_outside_sales', name: 'Field / Outside Sales' },
                                                    { id: 'inside_sales', name: 'Inside Sales' },
                                                    { id: 'partnerships', name: 'Partnerships' },
                                                    { id: 'revenue_operations', name: 'Revenue Operations' },
                                                    { id: 'sales', name: 'Sales' },
                                                    { id: 'sales_enablement', name: 'Sales Enablement' },
                                                    { id: 'sales_engineering', name: 'Sales Engineering' },
                                                    { id: 'sales_operations', name: 'Sales Operations' },
                                                    { id: 'sales_training', name: 'Sales Training' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {/* Consulting */}
                                    <div>
                                        <div className="mb-1 flex items-center gap-2 text-sm font-medium text-column">
                                            <span>Consulting</span>
                                        </div>
                                        <div className="translate-x-4">
                                            <CheckBoxItem
                                                selectedList={job_titles?.department_job_function}
                                                onChange={(data) => handleJobTitleDepartmentChange(data)}
                                                list={[{ id: 'consulting', name: 'Consultant' }]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CustomAccordion>
                        </div>
                    </div>
                </CustomAccordion>
            )}

            {/* Companies */}
            <CustomAccordion title="Companies" icon={<HiOutlineBuildingOffice size={16} />}>
                <div>
                    <div className="mb-2 rounded-md border-2 bg-white px-2 pt-2 text-sm font-medium text-column">
                        <div
                            className="mb-2 flex cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('companies', 'selected_method', '', 'is_any_of')}
                        >
                            <SparkleRadio checked={companies?.selected_method === 'is_any_of'} /> <span>Is any of</span>
                        </div>
                        {companies.selected_method === 'is_any_of' && (
                            <div>
                                <OrganizationListDropdown
                                    onClick={(data: string[]) => {
                                        handleChange('companies', 'is_any_of_data', 'companies', data);
                                    }}
                                    selectedOrganizationList={companies?.is_any_of_data?.companies}
                                />
                                {/* <InputField value={companies?.is_any_of_data?.companies} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('companies', 'is_any_of_data', 'companies', event?.target?.value)} label='' customClass='!bg-white !rounded-md !h-9 mb-2' inputClass='!rounded-md' placeholder='Enter companies...' /> */}
                                <CheckBoxItem
                                    selectedList={companies?.view?.is_not_any_of ? ['is_not_any_of'] : []}
                                    onChange={(data) => handleChange('companies', 'view', 'is_not_any_of', data.length > 0)}
                                    list={[{ id: 'is_not_any_of', name: 'Is not any of' }]}
                                />
                                {companies.view.is_not_any_of && (
                                    <OrganizationListDropdown
                                        onClick={(data: string[]) => {
                                            handleChange('companies', 'is_any_of_data', 'is_not_any_of', data);
                                        }}
                                        selectedOrganizationList={companies?.is_any_of_data?.is_not_any_of}
                                    />
                                )}
                                <CheckBoxItem
                                    selectedList={companies?.view?.include_past_companies ? ['include_past_companies'] : []}
                                    onChange={(data) => handleChange('companies', 'view', 'include_past_companies', data.length > 0)}
                                    list={[{ id: 'include_past_companies', name: 'Include past companies' }]}
                                />
                                {companies.view.include_past_companies && (
                                    <OrganizationListDropdown
                                        onClick={(data: string[]) => {
                                            handleChange('companies', 'is_any_of_data', 'include_past_companies', data);
                                        }}
                                        selectedOrganizationList={companies?.is_any_of_data?.include_past_companies}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className="mb-2 flex cursor-pointer items-center gap-2 rounded-md border-2 bg-white p-2 text-sm font-medium  text-column"
                        onClick={() => handleChange('companies', 'selected_method', '', 'is_known')}
                    >
                        <SparkleRadio checked={companies?.selected_method === 'is_known'} /> <span>Is known</span>
                    </div>
                    <div
                        className="mb-2 flex cursor-pointer items-center gap-2 rounded-md border-2 bg-white p-2 text-sm font-medium  text-column"
                        onClick={() => handleChange('companies', 'selected_method', '', 'is_unknown')}
                    >
                        <SparkleRadio checked={companies?.selected_method === 'is_unknown'} /> <span>Is unknown</span>
                    </div>
                    <div>
                        <h1 className="my-1 ml-0.5 text-xs font-medium text-column">Include list of companies</h1>
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('companies', 'include_list', '', event?.target?.value)}
                            label=""
                            id={''}
                            placeholder={'e.g. http://cisco.com \nname@example.com \nsalesforce.com'}
                            cols={0}
                            rows={3}
                            inputClass="resize-none !rounded-md !py-1 !bg-white"
                        />
                        <Button name='Add include list' customClass='w-full my-1' loading={localState?.include_list_id} disable={localState?.include_list_id} onClick={() => handleAddCompanyList(companies?.include_list, 'include_list_id')} />
                    </div>
                    <div>
                        <h1 className="my-1 ml-0.5 text-xs font-medium text-column">Exclude list of companies</h1>
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('companies', 'exclude_list', '', event?.target?.value)}
                            label=""
                            id={''}
                            placeholder={'e.g. http://cisco.com \nname@example.com \nsalesforce.com'}
                            cols={0}
                            rows={3}
                            inputClass="resize-none !rounded-md !py-1 !bg-white"
                        />
                        <Button name='Add exclude list' customClass='w-full my-1' loading={localState?.exclude_list_id} disable={localState?.exclude_list_id} onClick={() => handleAddCompanyList(companies?.exclude_list, 'exclude_list_id')} />
                    </div>
                </div>
            </CustomAccordion>

            {/* Location */}
            <CustomAccordion title="Location" icon={<GrLocation size={16} />}>
                <div>
                    <div className="mb-2 rounded-md border-2 bg-white p-2 text-sm font-medium text-column">
                        <div className="flex cursor-pointer items-center gap-2 text-sm font-medium text-column" onClick={() => handleChange('location', 'selected_method', '', 'region')}>
                            <SparkleRadio checked={location?.selected_method === 'region'} /> <span>Select Region</span>
                        </div>
                        {location.selected_method === 'region' && (
                            <div>
                                <h1 className="my-1 ml-0.5 text-xs font-medium text-column">City / State / Country / ZIP</h1>
                                <FilterDropDown defaultData ={location?.region?.include || []} kind='locations' placeHolder='locations' onSelect={(data: any) => handleChange('location', 'region', 'include', data)} />

                                {/* <InputField
                                    value={location?.region?.include}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('location', 'region', 'include', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="Enter locations..."
                                /> */}
                                <div className="my-2 flex items-center gap-2 text-sm font-medium text-column">
                                    <span>Exclude locations</span>
                                </div>
                                <h1 className="my-1 ml-0.5 text-xs font-medium text-column">City / State / Country to exclude:</h1>
                                <FilterDropDown defaultData ={location?.region?.exclude || []} kind='locations' placeHolder='locations' onSelect={(data: any) => handleChange('location', 'region', 'exclude', data)} />

                                {/* <InputField
                                    value={location?.region?.exclude}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('location', 'region', 'exclude', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="Enter locations..."
                                /> */}
                            </div>
                        )}
                    </div>
                    <div className="mb-2 rounded-md border-2 bg-white p-2 text-sm font-medium text-column">
                        <div className="flex cursor-pointer items-center gap-2" onClick={() => handleChange('location', 'selected_method', '', 'zip_code_radius')}>
                            <SparkleRadio checked={location?.selected_method === 'zip_code_radius'} /> <span>Select Zip code radius</span>
                        </div>
                        {location.selected_method === 'zip_code_radius' && (
                            <div>
                                <h1 className="my-1 ml-0.5 mt-1 text-xs font-medium text-column">Address / City / ZIP</h1>
                                <InputField
                                    value={location?.zip_code?.zip}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('location', 'zip_code', 'zip', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="e.g. 94105"
                                />
                                <CustomMenu open={localState?.mile_dropdown} onOpen={() => handleChangeLocalState('mile_dropdown', true)} onClose={() => handleChangeLocalState('mile_dropdown', false)} btnContent={`within ${location?.zip_code.range} miles`} menuClassName="w-full mt-2" menuListClassName="bottom-10 !shadow-none" btnClassName="w-full h-9 items-center">
                                    <div className="cursor-pointer p-1 hover:bg-hover" onClick={() => { handleChange('location', 'zip_code', 'range', 25); handleChangeLocalState('mile_dropdown', false) }}>within 25 miles</div>
                                    <div className="cursor-pointer p-1 hover:bg-hover" onClick={() => { handleChange('location', 'zip_code', 'range', 50); handleChangeLocalState('mile_dropdown', false) }}>within 50 miles</div>
                                    <div className="cursor-pointer p-1 hover:bg-hover" onClick={() => { handleChange('location', 'zip_code', 'range', 100); handleChangeLocalState('mile_dropdown', false) }}>within 100 miles</div>
                                    <div className="cursor-pointer p-1 hover:bg-hover" onClick={() => { handleChange('location', 'zip_code', 'range', 300); handleChangeLocalState('mile_dropdown', false) }}>within 300 miles</div>
                                </CustomMenu>
                            </div>
                        )}
                    </div>
                </div>
            </CustomAccordion>

            {/* Employees */}
            <CustomAccordion title="Employees" icon={<GrLocation size={16} />}>
                <div>
                    <div className="mb-2 rounded-md border-2 bg-white p-2 text-sm font-medium text-column">
                        <div className="flex cursor-pointer items-center gap-2" onClick={() => handleChange('employees', 'selected_method', '', 'predefined_range')}>
                            <SparkleRadio checked={employees?.selected_method === 'predefined_range'} /> <span>Predefined Range</span>
                        </div>
                        {employees.selected_method === 'predefined_range' && (
                            <div className="mt-2">
                                <CheckBoxItem
                                    selectedList={employees?.predefined_range}
                                    onChange={(data) => handleChange('employees', 'predefined_range', '', data)}
                                    list={[
                                        { id: '1,10', name: '1-10' },
                                        { id: '11,20', name: '11-20' },
                                        { id: '21,50', name: '21-50' },
                                        { id: '51,100', name: '51-100' },
                                        { id: '101,200', name: '101-200' },
                                        { id: '201,500', name: '201-500' },
                                        { id: '501,1000', name: '501-1000' },
                                        { id: '1001,2000', name: '1001-2000' },
                                        { id: '2001,5000', name: '2001-5000' },
                                        { id: '5001,10000', name: '5001-10000' },
                                        { id: '10001,+', name: '10001+' },
                                    ]}
                                />
                            </div>
                        )}
                    </div>
                    <div className="mb-2 rounded-md border-2 bg-white p-2 text-sm font-medium text-column">
                        <div className="flex cursor-pointer items-center gap-2" onClick={() => handleChange('employees', 'selected_method', '', 'custom_range')}>
                            <SparkleRadio checked={employees?.selected_method === 'custom_range'} /> <span>Custom Range</span>
                        </div>
                        {employees.selected_method === 'custom_range' && (
                            <div className="mt-2 flex gap-2">
                                <InputField
                                    value={employees?.custom_range?.min}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('employees', 'custom_range', 'min', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0 !w-full"
                                    inputClass="!rounded-md !bg-white"
                                    type="number"
                                    placeholder="Min"
                                />
                                <InputField
                                    value={employees?.custom_range?.max}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('employees', 'custom_range', 'max', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0 !w-full"
                                    inputClass="!rounded-md !bg-white"
                                    type="number"
                                    placeholder="Max"
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className="mb-2 flex cursor-pointer items-center gap-2 rounded-md border-2 bg-white p-2 text-sm font-medium  text-column"
                        onClick={() => handleChange('employees', 'selected_method', '', 'employees_is_unknown')}
                    >
                        <SparkleRadio checked={employees?.selected_method === 'employees_is_unknown'} /> <span># of employees is unknown</span>
                    </div>
                </div>
            </CustomAccordion>

            {/* Industry & keywords */}
            <CustomAccordion title="Keywords" icon={<AiOutlineUser size={16} />}>
                <div>
                    {/* <div className="rounded-md border-2 p-2">
                        <OrganizationListDropdown
                            onClick={(data: string[]) => {
                                handleChange('industry_keywords', 'industries', '', data);
                            }}
                            selectedOrganizationList={industry_keywords?.industries}
                        />
                        <div>
                            <div className="mt-3 flex">
                                <div
                                    className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                                    onClick={() => handleChange('industry_keywords', 'selected_method', '', 'is_not_any_of')}
                                >
                                    <SparkleRadio checked={industry_keywords?.selected_method === 'is_not_any_of'} /> <span>Is not any of</span>
                                </div>
                                <div
                                    className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                                    onClick={() => handleChange('industry_keywords', 'selected_method', '', 'is_known')}
                                >
                                    <SparkleRadio checked={industry_keywords?.selected_method === 'is_known'} /> <span>Is known</span>
                                </div>
                                <div
                                    className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                                    onClick={() => handleChange('industry_keywords', 'selected_method', '', 'is_unknown')}
                                >
                                    <SparkleRadio checked={industry_keywords?.selected_method === 'is_unknown'} /> <span>Is unknown</span>
                                </div>
                            </div>
                            {industry_keywords.selected_method === 'is_not_any_of' && (
                                <OrganizationListDropdown
                                    onClick={(data: string[]) => {
                                        handleChange('industry_keywords', 'is_not_any_of_data', '', data);
                                    }}
                                    selectedOrganizationList={industry_keywords?.is_not_any_of_data}
                                />
                            )}
                        </div>
                    </div> */}
                    <div className="rounded-md border-2 px-2">
                        <div className="flex items-center gap-2 text-[.8125rem] font-bold">Company keywords</div>
                        <div className="my-2">
                            <CheckBoxItem
                                selectedList={industry_keywords?.view?.include ? ['include'] : []}
                                onChange={(data) => handleChange('industry_keywords', 'view', 'include', data.length > 0)}
                                list={[{ id: 'include', name: 'Include keywords' }]}
                            />
                            {industry_keywords.view.include && (
                                <InputField
                                    value={industry_keywords?.company_keywords?.include}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('industry_keywords', 'company_keywords', 'include', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="e.g cloud, AWS..."
                                />
                            )}
                        </div>
                        <div className="my-2">
                            <CheckBoxItem
                                selectedList={industry_keywords?.view?.include_all ? ['include_all'] : []}
                                onChange={(data) => handleChange('industry_keywords', 'view', 'include_all', data.length > 0)}
                                list={[{ id: 'include_all', name: 'Include ALL' }]}
                            />
                            {industry_keywords.view.include_all && (
                                <InputField
                                    value={industry_keywords?.company_keywords?.include_all}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('industry_keywords', 'company_keywords', 'include_all', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="Include all keywords..."
                                />
                            )}
                        </div>
                        <div className="my-2">
                            <CheckBoxItem
                                selectedList={industry_keywords?.view?.exclude_keywords ? ['exclude_keywords'] : []}
                                onChange={(data) => handleChange('industry_keywords', 'view', 'exclude_keywords', data.length > 0)}
                                list={[{ id: 'exclude_keywords', name: 'Exclude keywords' }]}
                            />
                            {industry_keywords.view.exclude_keywords && (
                                <InputField
                                    value={industry_keywords?.company_keywords?.exclude}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('industry_keywords', 'company_keywords', 'exclude', event?.target?.value)}
                                    label=""
                                    customClass="!h-9 !mt-0"
                                    inputClass="!rounded-md !bg-white"
                                    placeholder="Exclude keywords..."
                                />
                            )}
                        </div>
                    </div>
                </div>
            </CustomAccordion>

            {/* Technologies */}
            <CustomAccordion title="Technologies" icon={<BsFillPeopleFill size={16} />}>
                <div>
                    <FilterDropDown defaultData ={technologies?.include || []} kind='technologies' placeHolder='technologies' onSelect={(data: any) => handleChange('technologies', 'include', '', data)} />

                    {/* <InputField
                        value={technologies?.include}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('technologies', 'include', '', event?.target?.value)}
                        label=""
                        customClass="!h-9 !mt-0"
                        inputClass="!rounded-md !bg-white"
                        placeholder="Include technologies..."
                    /> */}
                    <h4 className="my-1.5 ml-0.5 text-xs font-medium text-column">Include All</h4>
                    <FilterDropDown defaultData ={technologies?.include_all || []} kind='technologies' placeHolder='technologies' onSelect={(data: any) => handleChange('technologies', 'include_all', '', data)} />
                    {/* <InputField
                        value={technologies?.include_all}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('technologies', 'include_all', '', event?.target?.value)}
                        label=""
                        customClass="!h-9 !mt-0"
                        inputClass="!rounded-md !bg-white"
                        placeholder="Include all technologies..."
                    /> */}
                </div>
            </CustomAccordion>

            {/* Revenue */}
            <CustomAccordion title="Revenue" icon={<BsFillPeopleFill size={16} />}>
                <div>
                    <div className="mb-2 flex">
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('revenue', 'selected_method', '', 'is_between')}
                        >
                            <SparkleRadio checked={revenue?.selected_method === 'is_between'} /> <span>Is between</span>
                        </div>
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('revenue', 'selected_method', '', 'is_known')}
                        >
                            <SparkleRadio checked={revenue?.selected_method === 'is_known'} /> <span>Is known</span>
                        </div>
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('revenue', 'selected_method', '', 'is_unknown')}
                        >
                            <SparkleRadio checked={revenue?.selected_method === 'is_unknown'} /> <span>Is unknown</span>
                        </div>
                    </div>
                    {revenue.selected_method === 'is_between' && (
                        <div className="mb-2 flex gap-4">
                            <InputField
                                value={revenue?.is_between?.min}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('revenue', 'is_between', 'min', event?.target?.value)}
                                label=""
                                customClass="!h-9 !mt-0 !w-full"
                                inputClass="!rounded-md !bg-white"
                                placeholder="Min"
                                type="number"
                            />
                            <InputField
                                value={revenue?.is_between?.max}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('revenue', 'is_between', 'max', event?.target?.value)}
                                label=""
                                customClass="!h-9 !mt-0 !w-full"
                                inputClass="!rounded-md !bg-white"
                                placeholder="Max"
                                type="number"
                            />
                        </div>
                    )}
                    <CheckBoxItem
                        selectedList={revenue?.private_company ? ['private'] : []}
                        onChange={(data) => handleChange('revenue', 'private_company', '', data)}
                        list={[{ id: 'private', name: 'Private company' }]}
                    />
                    <CheckBoxItem
                        selectedList={revenue?.public_company ? ['public'] : []}
                        onChange={(data) => handleChange('revenue', 'public_company', '', data)}
                        list={[{ id: 'public', name: 'Public company' }]}
                    />
                </div>
            </CustomAccordion>

            {/* Funding */}
            <CustomAccordion title="Funding" icon={<BsFillPeopleFill size={16} />}>
                <div>
                    <CheckBoxItem
                        selectedList={funding?.funding_data}
                        onChange={(data) => handleChange('funding', 'funding_data', '', data)}
                        list={[
                            { id: '0', name: 'Seed' },
                            { id: '1', name: 'Angel' },
                            { id: '2', name: 'Venture (Round not Specified)' },
                            { id: '10', name: 'Series A' },
                            { id: '3', name: 'Series B' },
                            { id: '4', name: 'Series C' },
                            { id: '5', name: 'Series D' },
                            { id: '6', name: 'Series E' },
                            { id: '7', name: 'Series F' },
                            { id: '13', name: 'Debt Financing' },
                            { id: '14', name: 'Equity Crowdfunding' },
                            { id: '15', name: 'Convertible Note' },
                            { id: '11', name: 'Private Equity' },
                            { id: '12', name: 'Other' },
                        ]}
                    />
                    <div className="mt-3 flex">
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('funding', 'selected_method', '', 'is_between')}
                        >
                            <SparkleRadio checked={funding?.selected_method === 'is_between'} /> <span>Is between</span>
                        </div>
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('funding', 'selected_method', '', 'is_known')}
                        >
                            <SparkleRadio checked={funding?.selected_method === 'is_known'} /> <span>Is known</span>
                        </div>
                        <div
                            className="flex w-full cursor-pointer items-center gap-2 text-sm font-medium text-column"
                            onClick={() => handleChange('funding', 'selected_method', '', 'is_unknown')}
                        >
                            <SparkleRadio checked={funding?.selected_method === 'is_unknown'} /> <span>Is unknown</span>
                        </div>
                    </div>
                    {
                        funding?.selected_method === 'is_between' ?
                            <div>
                                <div>
                                    <h4 className="mb-1 mt-2 text-xs font-medium text-column">Last funding round date</h4>
                                    <div className="flex gap-4">
                                        <InputField
                                            value={funding?.last_funding_round_date?.from}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('funding', 'last_funding_round_date', 'from', event?.target?.value)}
                                            label=""
                                            customClass="!h-9 !mt-0 !w-full"
                                            inputClass="!rounded-md !bg-white"
                                            placeholder="From"
                                        />
                                        <InputField
                                            value={funding?.last_funding_round_date?.to}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('funding', 'last_funding_round_date', 'to', event?.target?.value)}
                                            label=""
                                            customClass="!h-9 !mt-0 !w-full"
                                            inputClass="!rounded-md !bg-white"
                                            placeholder="To"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h4 className="mb-1 mt-2 text-xs font-medium text-column">Last funding round amount ($)</h4>
                                    <div className="flex gap-4">
                                        <InputField
                                            value={funding?.last_funding_round_amount?.min}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('funding', 'last_funding_round_amount', 'min', event?.target?.value)}
                                            label=""
                                            customClass="!h-9 !mt-0 !w-full"
                                            inputClass="!rounded-md !bg-white"
                                            placeholder="Min"
                                            type="number"
                                        />
                                        <InputField
                                            value={funding?.last_funding_round_amount?.max}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('funding', 'last_funding_round_amount', 'max', event?.target?.value)}
                                            label=""
                                            customClass="!h-9 !mt-0 !w-full"
                                            inputClass="!rounded-md !bg-white"
                                            placeholder="Max"
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
            </CustomAccordion>

            {/* Job postings */}
            <CustomAccordion title="Job postings" icon={<BsFillPeopleFill size={16} />}>
                <div>
                    <div>
                        <h4 className="mb-1 text-xs text-column first-line:font-medium">Currently hiring for</h4>
                        <FilterDropDown defaultData ={job_posting?.job_titles || []} kind='job_titles' placeHolder='Job Titles' onSelect={(data: any) => handleChange('job_posting', 'job_titles', '', data)} />
                        {/* <InputField
                            value={job_posting?.job_titles}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'job_titles', '', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0"
                            inputClass="!rounded-md !bg-white"
                            placeholder="Enter job titles..."
                        /> */}
                        <h4 className="mb-1 mt-2 text-xs font-medium text-column">Job located at</h4>
                        <FilterDropDown defaultData ={job_posting?.locations || []} kind='locations' placeHolder='locations' onSelect={(data: any) => handleChange('job_posting', 'locations', '', data)} />
                        {/* <InputField
                            value={job_posting?.locations}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'locations', '', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0"
                            inputClass="!rounded-md !bg-white"
                            placeholder="Enter locations..."
                        /> */}
                    </div>
                    <h4 className="mb-1 mt-2 text-xs font-medium text-column">Active job postings</h4>
                    <div className="flex gap-4">
                        <InputField
                            value={job_posting?.active_job_posting?.min}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'active_job_posting', 'min', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0"
                            inputClass="!rounded-md !bg-white"
                            placeholder="Min"
                            type="number"
                        />
                        <InputField
                            value={job_posting?.active_job_posting?.max}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'active_job_posting', 'max', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0"
                            inputClass="!rounded-md !bg-white"
                            placeholder="Max"
                            type="number"
                        />
                    </div>
                    <h4 className="mb-1 mt-2 text-xs font-medium text-column">Job posted at</h4>
                    <div className="flex gap-4">
                        <InputField
                            value={job_posting?.job_posted_at?.from}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'job_posted_at', 'from', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0 !w-full"
                            inputClass="!rounded-md !bg-white"
                            placeholder="From"
                        />
                        <InputField
                            value={job_posting?.job_posted_at?.to}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('job_posting', 'job_posted_at', 'to', event?.target?.value)}
                            label=""
                            customClass="!h-9 !mt-0 !w-full"
                            inputClass="!rounded-md !bg-white"
                            placeholder="To"
                        />
                    </div>
                </div>
            </CustomAccordion>
        </div>
    );
};

export default FilterElements