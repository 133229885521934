import React from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setBrandId, setIsSwitchPipeline, setPipelineId } from 'store/crm';
import { removePipelineData } from 'store/crm-onboard';
const OnboardBackground = (props: { children: React.ReactNode; count: number }) => {
  const { children, count } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleDashboardNavigation = () => {
    dispatch(setIsSwitchPipeline(false));
    const payload = {
      uuid: '',
      pipeline_name: '',
    };
    dispatch(setPipelineId(payload));
    dispatch(setBrandId(''));
    dispatch(removePipelineData());
    navigate('/crm');
  };
  return (
    <div className="flex h-screen w-full flex-col items-start justify-start bg-crmOnboardingBackground bg-cover bg-center py-10 pl-28">
      <div className="flex w-full justify-between pr-28">
        <p className="text-sm font-medium text-heading">Step {count ? count : 0} of 6</p>
        <p className="flex cursor-pointer items-center gap-3 text-sm font-medium text-heading" onClick={() => handleDashboardNavigation()}>
          <IoArrowBackOutline /> Go to dashboard
        </p>
      </div>
      <br />
      <div>{children}</div>
    </div>
  );
};

export default OnboardBackground;
