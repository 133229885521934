import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/modal';
import { toast } from 'react-toastify';

import Button from 'common/sparkle-common/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import { removeProspectListResult, resetFilteredMapData, setIsProspectUploaded } from 'store/crm-onboard';
import { _getQueryParams, toastState } from 'common/utils/utility';
import { onCrmOpportunitiesUpdated } from 'store/crm';
import { ModalComponentProps } from 'common/dto/types/crm';
import React from 'react';

const OnboardProspectPopup = (props: ModalComponentProps) => {
  const dispatch = useAppDispatch();
  const { prospect_list_result } = useAppSelector((state) => state?.crmOnboard);
  const filteredData = useAppSelector((state) => state?.crmOnboard?.filteredMapData);
  const { onClose, isOpen } = props;
  const getQueryIds = _getQueryParams('_id');
  const pipelineId = getQueryIds?.pipeline_id;
  const brandId = getQueryIds?.brand_id;
  const [loading, setLoading] = React.useState(false);
  // const brandId = useAppSelector((state) => state.crm.brandId);
  // const pipelineId = useAppSelector((state) => state.crm.pipeline_id);
  const handleNext = async () => {
    try {
      setLoading(true);
      const payload = {
        brand_id: brandId,
        pipeline_id: pipelineId,
        prospect_email_list: prospect_list_result,
      };
      const response = await BaseApi.post(`/crm/pipeline/opportunity`, payload);
      if (response?.status === 200) {
        onClose();
        dispatch(setIsProspectUploaded(true));
        dispatch(onCrmOpportunitiesUpdated());
        dispatch(removeProspectListResult());
        dispatch(resetFilteredMapData());

        setLoading(false);
      } else {
        toast.error(`Please upload prospects with proper email id`, toastState.error);
        dispatch(removeProspectListResult());
        setLoading(false);

        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalHeader className="!px-0">
            <div className="space-y-1 px-4">
              <h5 className="text-sm text-heading dark:text-purple-500">Verify</h5>
            </div>
          </ModalHeader>
          <ModalCloseButton
            id="email-verify-modal-close-button"
            onClick={() => {
              dispatch(removeProspectListResult());
              dispatch(resetFilteredMapData());
            }}
          />
          <ModalBody className="!px-2 ">
            <div className="overflow-hidden">
              <div className="mb-3 !h-96 !overflow-auto">
                <div className="w-screen ">
                  <div className=" flex w-full  items-center justify-start gap-8 border-b bg-[#F9FAFB] px-3 py-2 text-sm text-column dark:bg-darkTwo dark:text-white ">
                    {filteredData?.length &&
                      Object.keys(filteredData[0]).map((key) => (
                        <h5 className=" !w-[14rem] truncate" key={key}>
                          {key}
                        </h5>
                      ))}
                  </div>
                  {filteredData?.length &&
                    filteredData?.map((value: any, index: number) => (
                      <div
                        className="flex w-full   items-center justify-start gap-8 border-b bg-white p-3 text-sm text-column dark:border-darkBorder dark:bg-darkThree"
                        key={index}
                      >
                        {Object.keys(value).map((key) => (
                          <div key={key} className="w-[14rem] text-ellipsis whitespace-nowrap overflow-hidden">
                            <h5 className="flex !w-[14rem]  items-center !text-ellipsis !whitespace-nowrap !overflow-hidden font-semibold text-heading dark:text-gray-300">{value[key]}</h5>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex w-full gap-4 px-6 py-2">
                <Button
                  type="button"
                  name="cancel"
                  fontSize="sm"
                  px="px-6"
                  color="text-column"
                  onClick={() => {
                    dispatch(removeProspectListResult());
                    dispatch(resetFilteredMapData());
                    onClose();
                  }}
                  customClass="text-column font-medium text-sm w-1/2 !bg-white"
                />
                <Button
                  loading={loading}
                  disable={loading}
                  type="button"
                  name="Next"
                  fontSize="sm"
                  px="px-6"
                  onClick={() => handleNext()}
                  customClass="text-column dark:border-none font-medium text-sm w-1/2"
                />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardProspectPopup;
