import { createSlice } from '@reduxjs/toolkit';
import { unmatched_emails } from 'common/dto/initial-value/views/crm';
import { CrmStoreState } from 'common/dto/types/views/crm';

const initialState: CrmStoreState = {
  currentId: '',
  dragged: false,
  currentOpportunityId: '',
  opportunityData: [],
  stageData: [],
  opportunity_id: '',
  opportunity_domain: '',
  pipeline_id: '',
  pipelineName: '',
  isPipelineSwitched: false,
  isEmailFullTyperOpen: false,
  openSelectPipelinePopup: false,
  isOpportunitiesUploaded: false,
  crmStageData: [],
  brandId: '',
  is_message_sent: false,
  isReplyEditorOpen: false,
  threadMaildata: {},
  opportunity_mail_data: [],
  unmatched_emails: unmatched_emails,
  opportunityInboxViewData: {
    opportunity_id: '',
    opportunity_domain: '',
    pipeline_id: '',
    brand_id: '',
    prospect_enrich_details: {},
  },
  prospect_details: {},
  composEmailPopupOpen: false,
};

const domainSlice = createSlice({
  name: 'Crm',
  initialState,
  reducers: {
    setCurrentId: (state, action) => {
      state.currentId = action.payload;
      const [colId, rowId] = action.payload.split('-');
      state.currentOpportunityId = state?.stageData[colId]?.opportunityData[rowId]?.uuid;
    },
    setDragged: (state, action) => {
      state.dragged = action.payload;
    },
    setNewOpportunityData: (state, action) => {
      state.opportunityData = action.payload;
    },
    setBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    setStage: (state, action) => {
      state.stageData = action.payload;
    },
    setCrmStages: (state, action) => {
      state.crmStageData = action.payload;
    },
    addCrmStage: (state, action) => {
      state?.crmStageData?.push(action.payload);
    },
    deleteCrmStage: (state, action) => {
      state.crmStageData = state.crmStageData.filter((_: any, i: any) => i !== action.payload);
    },
    updateCrmStage: (state, action) => {
      const { index, stage } = action.payload;
      state.crmStageData[index] = { ...state.crmStageData[index], ...stage };
    },
    setIsSwitchPipeline: (state, action) => {
      state.isPipelineSwitched = action.payload;
    },
    setEntryElement: (state, action) => {
      const { prev, next } = action.payload;
      const [prevColId, prevRowId] = prev.split('-');
      const [nextColId, nextRowId] = next.split('-');
      const temp = JSON.parse(JSON.stringify(state.stageData));
      if (!temp[prevColId]) return;
      const data = temp[prevColId]?.opportunityData?.splice(prevRowId, 1);
      if (!data.length || !temp[nextColId]) return;
      temp[nextColId]?.opportunityData?.splice(nextRowId, 0, data[0]);
      state.stageData = temp;
    }, // Not in use currently --  Modify in future regards of Entry level pre-shift look
    setDraggedElement: (state, action) => {
      const { prev, next } = action.payload;
      const [prevColId, prevRowId] = prev.split('-');
      const [nextColId, nextRowId] = next.split('-');
      const temp = JSON.parse(JSON.stringify(state.stageData));
      if (!temp[prevColId]) return;
      const data = temp[prevColId]?.opportunityData?.splice(prevRowId, 1);
      if (!data.length || !temp[nextColId]) return;
      temp[nextColId]?.opportunityData?.splice(nextRowId, 0, data[0]);
      state.stageData = temp;
    },

    setNewOpportunity: (state, action) => {
      const { colId } = action.payload;
      const [prevColId, prevRowId] = state.currentId.split('-');
      const temp = JSON.parse(JSON.stringify(state.stageData));

      const data = temp[prevColId]?.opportunityData?.splice(prevRowId, 1);
      if (!data?.length) return;
      temp[colId]?.opportunityData?.push(data[0]);
      state.stageData = temp;
    },

    setStageOpportunityData: (state, action) => {
      const index = state.stageData.findIndex((item: any) => item.uuid === action.payload.uuid);
      // If the object is found, update its opportunityData
      if (index !== -1) {
        const updatedOpportunityData = [...state.stageData[index].opportunityData, ...action.payload.data];
        // Update the specific object's opportunityData
        state.stageData[index] = {
          ...state.stageData[index],
          opportunityData: updatedOpportunityData,
        };
      }
    },
    setSortOpportunityData: (state, action) => {
      const index = state.stageData.findIndex((item: any) => item.uuid === action.payload.uuid);
      // If the object is found, update its opportunityData
      if (index !== -1) {
        state.stageData[index] = {
          ...state.stageData[index],
          opportunityData: action?.payload?.data,
        };
      }
    },
    setUnMatchedEmails: (state, action) => {
      state.unmatched_emails = {
        ...state?.unmatched_emails,
        ...action.payload,
      };
    },

    setReplyThreadData: (state, action) => {
      state.threadMaildata = action.payload;
    },

    setForwardData: (state, action) => {
      state.threadMaildata = action?.payload;
    },

    setisReplyEditorOpen: (state, action) => {
      state.isReplyEditorOpen = action.payload;
    },

    setPipelineId: (state, action) => {
      state.pipeline_id = action.payload.uuid;
      state.pipelineName = action.payload.pipeline_name;
    },
    setOppertunityId: (state, action) => {
      state.opportunity_id = action.payload;
    },
    setOppertunityDomain: (state, action) => {
      state.opportunity_domain = action.payload;
    },

    onCrmOpportunitiesUpdated: (state) => {
      state.isOpportunitiesUploaded = !state.isOpportunitiesUploaded;
    },
    setMessageSent: (state) => {
      state.is_message_sent = !state.is_message_sent;
    },
    setOppertunityData: (state, action) => {
      state.opportunity_mail_data = action.payload;
    },
    setSelectPipelineModal: (state, action) => {
      state.openSelectPipelinePopup = action.payload;
    },
    setOpportunityInboxViewData: (state, action) => {
      state.opportunityInboxViewData = action.payload;
    },
    setProspectDetails: (state, action) => {
      state.prospect_details = action.payload;
    },
    setcomposEmailPopupOpen: (state, action) => {
      state.composEmailPopupOpen = action.payload;
    },
  },
});

export const {
  setDraggedElement,
  setCurrentId,
  setNewOpportunity,
  setEntryElement,
  setUnMatchedEmails,
  setDragged,
  setNewOpportunityData,
  setStage,
  setPipelineId,
  setOppertunityId,
  onCrmOpportunitiesUpdated,
  setCrmStages,
  addCrmStage,
  deleteCrmStage,
  setBrandId,
  updateCrmStage,
  setMessageSent,
  setOppertunityData,
  setIsSwitchPipeline,
  setStageOpportunityData,
  setSortOpportunityData,
  setOppertunityDomain,
  setReplyThreadData,
  setisReplyEditorOpen,
  setSelectPipelineModal,
  setOpportunityInboxViewData,
  setProspectDetails,
  setForwardData,
  setcomposEmailPopupOpen,
} = domainSlice.actions;
export default domainSlice.reducer;
