import { VersionABSVG, VersionASVG, VersionBSVG } from 'assets/img/campaignv2';
import React, { useContext, useState } from 'react'
import { AiOutlineClockCircle, AiFillDelete } from 'react-icons/ai';
import { BiSolidPencil, BiCopy } from 'react-icons/bi';
import { ImLinkedin } from 'react-icons/im';
import { IoMdMore, IoMdAdd } from 'react-icons/io';
import { IoMail, IoCallSharp } from 'react-icons/io5';
import { MdWhatsapp, MdOutlineSms } from 'react-icons/md';
import { RiTwitterXLine } from 'react-icons/ri';
import { ReactSVG } from 'react-svg';
import DelayCounter from './DelayCounter';
import { CgListTree, CgRemove } from 'react-icons/cg';
import { clearVersionAB, duplicateSequence, handleToggleMenu, removeSequence, removeTriggers, setCreateSequenceModal, setCurrentSequenceID, setCurrentVersion, setVersionAB } from 'store/campaign-v2';
import { useDispatch } from 'react-redux';
import { setDrawerStatus } from 'store/custom';
import { useAppSelector } from 'store';
import useClickOutsideHook from 'common/hooks/useClickOutsideHook';
import { sequenceDataType } from 'common/dto/types/views/campaignv2';
import { SequenceContext } from '.';
import StatsBox from './StatsBox';

type ContentBoxProps = {
    content: any,
    totalCount?: number,
}

const ContentBox = (props: ContentBoxProps) => {
    const access = useContext(SequenceContext)
    const [statsBox, setStatsBox] = useState(false)
    const { content } = props
    const dispatch = useDispatch()
    const state = useAppSelector(state => state?.campaignv2)
    const index = state?.sequence?.findIndex(item => item.id === content.id)
    const delayMenuRef = useClickOutsideHook(() => { content?.delayModal && dispatch(handleToggleMenu({ key: 'delayModal', targetId: content.id, condition: false })) })
    const triggerMenuRef = useClickOutsideHook(() => { content?.triggerModal && dispatch(handleToggleMenu({ key: 'triggerModal', targetId: content.id, condition: false })) })
    const deleteContentRef = useClickOutsideHook(() => { content?.deleteContentMenu && dispatch(handleToggleMenu({ key: 'deleteContentMenu', targetId: content.id, condition: false })) })
    const deleteTriggerRef = useClickOutsideHook(() => { content?.deleteTriggerMenu && dispatch(handleToggleMenu({ key: 'deleteTriggerMenu', targetId: content.id, condition: false })) })

    const findNestedIds = (id: string, items: sequenceDataType[]) => {
        const result: string[] = [];
        const queue: string[] = [id];
        const visited = new Set<string>();

        while (queue.length > 0) {
            const currentId = queue.shift();
            if (currentId && !visited.has(currentId)) {
                visited.add(currentId);
                result.push(currentId);
                const currentItem = items.find((item: any) => item.id === currentId);
                if (currentItem) {
                    if (currentItem.next) {
                        queue.push(currentItem.next);
                    }

                    if (currentItem.trigger) {
                        if (currentItem.trigger.yes) {
                            queue.push(currentItem.trigger.yes);
                        }
                        if (currentItem.trigger.no) {
                            queue.push(currentItem.trigger.no);
                        }
                    }
                }
            }
        }

        return result.slice(1);
    };

    const handleContentClick = (key: string) => {
        if (!access) {
            setStatsBox(prev => !prev)
            return
        }
        dispatch(setDrawerStatus());
        dispatch(setCurrentSequenceID(content.id));
        dispatch(setCurrentVersion(key))
    }

    const handleTriggerDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const list = findNestedIds(content.id, state?.sequence);
        dispatch(removeTriggers({ id: content.id, list }));
    }

    const handleDeleteStep = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (content.trigger_on) {
            const list = findNestedIds(content.id, state?.sequence);
            dispatch(removeTriggers({ id: content.id, list }));
        }
        dispatch(removeSequence(content.id));
    }

    return (
        <div className='flex flex-col items-center justify-center transition-all'>
            {content?.trigger_on ? <div className='flex flex-col justify-center items-center relative w-full'>
                {content?.trigger_on === 'yes' ?
                    <>
                        <div className='border-b-[0.5px] rounded-full border-[#aab3ca] relative left-[calc(50%+20px)] -translate-x-1/2 w-[calc(50%+20px)]'>
                            <div className='absolute bg-[#E5FFE9] text-[#27B43E] rounded-full px-3 py-[0.2rem] -top-[0.65rem] left-[4rem] text-[0.6rem] font-medium z-10'>Yes</div>
                        </div>
                    </>
                    :
                    <>
                        <div className='border-b-[0.5px] border-[#aab3ca] relative right-1/2 translate-x-1/2 w-1/2'>
                            <div className='absolute bg-[#FFE0E0] text-[#EC4344] rounded-full px-[0.825rem] py-[0.2rem] -top-[0.65rem] right-[4rem] text-[0.6rem] z-10'>No</div>
                        </div>
                    </>
                }
                <hr className={`border-r-[0.5px] border-[#aab3ca] h-5`} />
            </div> : <div className='flex flex-col justify-center items-center relative w-full'>
                <hr className={`border-r-[0.5px] border-[#aab3ca] h-4`} />
            </div>}

            {content?.type ? <div className='relative group flex flex-col shadow-box w-[19.3rem] rounded-md border border-[#E9EDF7] bg-white text-paused-row text-[0.82rem] font-medium hover:shadow-shade transition-all duration-300 select-none' id={`boxId-${index}`}>
                {statsBox && <StatsBox content={content} />}
                {content.id !== state?.sequence[0]?.id && <div className='flex px-2.5 py-[0.45rem] justify-between items-center bg-[#8F9BBA0F]'>
                    <div className='flex gap-1 items-center'>
                        <AiOutlineClockCircle className='size-[0.8rem] text-paused-row hover:text-brand-500' />
                        <h5 className='text-blueSecondary text-[0.65rem] font-semibold'>{content?.delay?.days ? `Wait for ${content.delay?.days} business day` : "Send immediately"}</h5>
                    </div>
                    {access && <button id={`box_delay_${index}`}
                        onClick={() => dispatch(handleToggleMenu({ key: 'delayModal', targetId: content.id }))}
                    ><BiSolidPencil className={`size-[0.8rem] hover:text-brand-500 transition-all ${content.delayModal ? 'size-[0.9rem] text-brand-500' : 'text-paused-row '}`} /></button>}
                    {content?.delayModal && <DelayCounter menuRef={delayMenuRef} id={content.id} value={content.delay?.days} />}
                </div>}
                <div className='flex items-center justify-between px-3.5 py-[0.8rem] cursor-pointer'
                    onClick={() => handleContentClick('A')}
                >
                    <div className='flex gap-2'>
                        <button className='group-hover:scale-110 transition-all duration-300'>
                            {content?.type === 'whatsapp' && <MdWhatsapp className='size-6 p-[0.35rem] fill-green-500 bg-[#015AFF0F] rounded-full' />}
                            {content?.type === 'email' && <IoMail className='size-6 p-[0.35rem] text-[#015AFF] bg-[#015AFF0F] rounded-full' />}
                            {content?.type === 'call' && <IoCallSharp className='text-[#04C969] bg-[#015AFF0F] size-6 p-[0.35rem] rounded-full' />}
                            {content?.type === 'linkedin' && <ImLinkedin className='rounded-full text-blue-700 bg-[#015AFF0F] size-6 p-[0.35rem]' />}
                            {content?.type === 'twitter' && <RiTwitterXLine className='bg-[#015AFF0F] size-6 p-[0.35rem] rounded-full' />}
                            {content?.type === 'sms' && <MdOutlineSms className='text-[#FFA51B] bg-[#015AFF0F] size-6 p-[0.35rem] rounded-full' />}
                        </button>
                        <div className='flex flex-col items-start justify-center leading-[0.95rem] transition-all'>
                            <h5 className='text-[0.7rem] font-semibold text-heading capitalize'>{content.type !== 'linkedin' ? content.type : content?.channelAction?.replace('_', ' ')}</h5>
                            {!content?.versionAB && <h5 className={`text-[0.6rem] w-32 truncate text-left ${content?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '') ? '' : 'text-[#EC4344]'}`}>
                                {content?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '') ? content?.delivery_data?.version_a?.subject : 'Action needed'}
                            </h5>
                            }
                        </div>
                    </div>
                    {access && <div className='flex items-center justify-end gap-1.5'>
                        {content?.type !== 'linkedin' ?
                            <button id={`version_btn_${index}`}
                                onClick={(e) => { e.stopPropagation(); dispatch(setVersionAB(content.id)) }}
                            ><ReactSVG src={VersionABSVG} beforeInjection={(svg) => svg.setAttribute('style', 'height: 1.65rem; width: 1.65rem;')} className='text-brand-500' /></button>
                            :
                            <button id={`pause_sequence_${index}`}
                                onClick={(e) => { e.stopPropagation(); }}
                            ><CgRemove className={`size-3.5 ${content?.pause_sequence ? 'text-brand-500' : 'text-paused-row'}`} /></button>
                        }
                        <button id={`duplicate_btn_${index}`}
                            onClick={(e) => { e.stopPropagation(); dispatch(duplicateSequence(content.id)) }}
                        ><BiCopy className='size-[0.8rem] text-paused-row hover:text-brand-500' /></button>
                        <div id={`more_btn_${index}`}
                            onClick={(e) => { e.stopPropagation(); dispatch(handleToggleMenu({ key: 'deleteContentMenu', targetId: content.id })) }}
                        ><IoMdMore className='size-5 p-[1.5px] text-paused-row hover:text-brand-500' />
                            {content?.deleteContentMenu && <button ref={deleteContentRef} className='group/more absolute right-0 w-[6.4rem] bg-white rounded-md flex items-center gap-1.5 hover:bg-brand-500 hover:text-white transition-all duration-150 font-medium px-2 py-1.5 border border-[#E9EDF7] shadow-box cursor-pointer' id={`delete_btn_${index}`}
                                onClick={handleDeleteStep}
                            >
                                <AiFillDelete className='text-brand-500 group-hover/more:text-white' />
                                <h5 className="text-xs">Delete Step</h5>
                            </button>}
                        </div>
                    </div>}
                </div>
                {content?.versionAB && <>
                    <div id={`versionA_box_${index}`}
                        onClick={() => handleContentClick('A')}
                        className='border-y px-3.5 py-2 flex justify-between items-center cursor-pointer'>
                        <div className='flex gap-1.5 items-center'>
                            <button><ReactSVG src={VersionASVG} beforeInjection={(svg) => svg.setAttribute('style', 'height: 1.2rem; width: 1.2rem;')} className='text-brand-500' /></button>
                            <h5 className='text-[0.65rem] text-heading font-semibold w-min max-w-36 truncate'>Version A</h5>
                            <h5 className={`text-[0.65rem] w-min max-w-20 truncate ${content?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '') ? 'text-heading font-semibold' : 'text-[#EC4344] font-medium'}`}>
                                {content?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '') ? content?.delivery_data?.version_a?.subject : 'Action needed'}
                            </h5>
                        </div>
                        {access && <AiFillDelete id={`versionA_delete_${index}`} className='size-3.5 text-paused-row hover:text-brand-500'
                            onClick={(e) => { e.stopPropagation(); dispatch(clearVersionAB({ id: content.id, version: 'A' })) }}
                        />}
                    </div>
                    <div id={`versionB_box_${index}`}
                        onClick={() => handleContentClick('B')}
                        className='border-y px-3.5 py-2 flex justify-between items-center cursor-pointer'>
                        <div className='flex gap-1.5 items-center'>
                            <button><ReactSVG src={VersionBSVG} beforeInjection={(svg) => svg.setAttribute('style', 'height: 1.2rem; width: 1.2rem;')} className='text-brand-500' /></button>
                            <h5 className='text-[0.65rem] text-heading font-semibold w-min max-w-36 truncate'>Version B</h5>
                            <h5 className={`text-[0.65rem] w-min max-w-20 truncate ${content?.delivery_data?.version_b?.message?.replace(/<[^>]+>/g, '') ? 'text-heading font-semibold' : 'text-[#EC4344] font-medium'}`}>{content?.delivery_data?.version_b?.message?.replace(/<[^>]+>/g, '') ? content?.delivery_data?.version_b?.subject : 'Action needed'}</h5>
                        </div>
                        {access && <AiFillDelete id={`versionB_delete_${index}`} className='size-3.5 text-paused-row hover:text-brand-500'
                            onClick={(e) => { e.stopPropagation(); dispatch(clearVersionAB({ id: content.id, version: 'B' })) }}
                        />}
                    </div></>}
            </div> : <div className='w-[19.3rem]'> </div>}
            {<div className='flex flex-col justify-center items-center relative'>
                {content?.type && <hr className={`border-r-[0.5px] border-[#aab3ca] h-4`} />}
                <button
                    id={`add_sequence_btn_${index + 1}`}
                    onClick={() => {
                        if (!access) return
                        dispatch(setCreateSequenceModal());
                        dispatch(setCurrentSequenceID(content.id))
                    }}
                ><IoMdAdd className={`size-[1rem] p-[0.1rem] text-white bg-brand-500 rounded-full relative left-1/2 -translate-x-1/2 hover:rotate-90 transition-all duration-500`} /></button>
            </div>}
            {content.trigger &&
                <>
                    <div className='flex flex-col justify-center items-center relative'>
                        <hr className={`border-r-[0.5px] border-[#aab3ca] h-4`} />
                    </div>
                    <div className='shadow-box w-[19.3rem] rounded-md border border-[#E9EDF7] bg-white text-paused-row text-[0.82rem] font-medium hover:shadow-shade transition-all duration-300 select-none'>
                        <div className='relative group flex flex-col shadow-box w-[19.3rem] rounded-md border border-[#E9EDF7] bg-white text-paused-row text-[0.82rem] font-medium hover:shadow-shade transition-all duration-300 select-none' id={`boxId-${index}`}>

                            <div className='flex items-center justify-between px-3.5 py-[0.5rem] cursor-pointer'>
                                <div className='flex items-center gap-2'>
                                    <button className='group-hover:scale-110 transition-all duration-300 size-7 flex justify-center items-center bg-[#015AFF0F] rounded-full' >
                                        <CgListTree className='size-3 text-[#4318FF]' />
                                    </button>
                                    <div className='flex flex-col items-start leading-[0.95rem] transition-all'>
                                        <div className='text-[0.65rem] text-heading font-semibold flex justify-around items-center gap-1'><span className='font-semibold text-blueSecondary'>
                                            {content?.trigger?.trigger_variant === 'email' && content?.trigger?.trigger_type === 'opened' && 'Opened email'}
                                            {content?.trigger?.trigger_variant === 'email' && content?.trigger?.trigger_type === 'click_on_link' && 'Clicked on link in email'}
                                            {content?.trigger?.trigger_variant === 'email' && content?.trigger?.trigger_type === 'unsubscribe' && 'Unsub from email'}
                                            {content?.trigger?.trigger_variant === 'call' && content?.trigger?.trigger_type === 'booked_meeting' && 'Booked a meeting'}
                                            {content?.trigger?.trigger_variant === 'email' && content?.trigger?.trigger_type === 'replied' && 'Replied to email'}
                                            {content?.trigger?.trigger_variant === 'linkedin' && content?.trigger?.trigger_type === 'accepted_connection' && 'Accepted Linkedin connect request'}
                                            {content?.trigger?.trigger_variant === 'linkedin' && content?.trigger?.trigger_type === 'replied' && 'Replied to Linkedin DM / Inmail'}
                                            {content?.trigger?.trigger_variant === 'whatsapp' && content?.trigger?.trigger_type === 'replied' && 'Replied to Whatsapp Message'}
                                            {content?.trigger?.trigger_variant === 'call' && content?.trigger?.trigger_type === 'attend' && 'Answered Call'}
                                            {content?.trigger?.trigger_variant === 'twitter' && content?.trigger?.trigger_type === 'replied' && 'Replied to twitter DM'}
                                            {content?.trigger?.trigger_variant === 'twitter' && content?.trigger?.trigger_type === 'stop' && 'Stop'}
                                        </span><span> within </span> <div onClick={() => {
                                            if (!access) return
                                            dispatch(handleToggleMenu({ key: 'triggerModal', targetId: content.id }))
                                        }} id={`trigger_delay_${index}`} className='font-semibold text-blueSecondary'>{content?.trigger?.trigger_waiting_period?.days} business day</div>
                                        </div>
                                    </div>
                                </div>
                                {access && <button id={`trigger_more_${index}`}
                                    onClick={(e) => { e.stopPropagation(); dispatch(handleToggleMenu({ key: 'deleteTriggerMenu', targetId: content.id })) }}
                                ><IoMdMore className='size-5 p-[1.5px] text-paused-row hover:text-brand-500' />
                                    {content?.deleteTriggerMenu && <button ref={deleteTriggerRef} className='group/more absolute right-0 bg-white rounded-md flex items-center gap-1.5 hover:bg-red-500 hover:text-white transition-all duration-150 font-medium px-2 py-1.5 border border-[#E9EDF7] shadow-box cursor-pointer' id={`trigger_delete_${index}`}
                                        onClick={handleTriggerDelete}
                                    >
                                        <AiFillDelete className='text-red-500 group-hover/more:text-white' />
                                        <h5 className="text-xs text-nowrap">Delete Trigger</h5>
                                    </button>}
                                </button>}
                            </div>

                            {content?.triggerModal && <DelayCounter menuRef={triggerMenuRef} id={content.id} trigger={true} value={content.trigger?.trigger_waiting_period?.days} />}
                        </div>
                    </div>
                    <div className='flex justify-center items-start relative'>
                        <div>
                            <div className='absolute w-full h-[1.86rem] border-r border-b rounded-br-3xl border-[#aab3ca] right-1/2'></div>
                            {/* <div className='absolute w-[6.8rem] h-12 border-l rounded-tl-3xl border-[#aab3ca] top-1/2 -mt-[0.13rem] right-28'></div> */}
                        </div>
                        <hr className={`h-[1.79rem]`} />
                        <div>
                            {/* <div className='absolute w-full h-[1.86rem] border-l border-b rounded-bl-3xl border-[#aab3ca] left-1/2'></div> */}
                            {/* <div className='absolute w-[6.8rem] h-12 border-r rounded-tr-3xl border-[#aab3ca] top-1/2 -mt-[0.13rem] left-28'></div> */}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ContentBox