import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setCurrentStepCount, setTotalStepCount } from 'store/custom';
import { useRef, useState } from 'react';
import SparkleStepper, { stepType } from 'common/sparkle-common/Stepper';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Button from 'common/sparkle-common/Button';
import { clearCampaignV2, setCampaignName, setHeaderCount, setIds, setProspects, setProspectsVerification, setSimilarEmailModal, updatePreviewProspectsData } from 'store/campaign-v2';
import { MdRocketLaunch } from 'react-icons/md';
import { campaignStepsCount } from 'common/dto/initial-value/views/campaign-v2';
import { BaseApi } from 'common/api/common/base-api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { _errorHandler, _patternTest, _removeDuplicates, randomNumber, toastState, useDebounce } from 'common/utils/utility';
import { ApiPayloadType } from 'common/dto/types/views/campaignv2';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackSharp } from 'react-icons/io5';
import React from 'react';
import { LocalStorage } from 'common/api/common/storage';
import { setUpdateSettingsData } from 'store/global-settings';

type CampaignHeaderProps = {
    brandList: any[];
}

const CampaignHeader = (props: CampaignHeaderProps) => {
    const { brandList } = props
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state?.campaignv2);
    const custom = useAppSelector((state) => state?.custom);
    const { campaign_name, settings, scheduleSettings, stop_reply_to, tracking_options, finalSettings, prospects } = state;
    const { campaign_id, settings_id, brand_id, prospects_id } = state?.ids
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const debounceCampaignName = useDebounce(campaign_name, 300);

    const handleSetup = async () => {
        try {
            setLoading(true);
            const campaignParams = {
                campaign_name: state?.campaign_name || `Campaign ${randomNumber(1000, 9999)}`,
                // campaign_name: state?.campaign_name || `Untitled campaign`,
                ...(campaign_id && { campaign_id }),
                brand_id: brand_id
            };
            const campaignResponse = await BaseApi.post('/campaign/v2/create', campaignParams)
            if (!campaignResponse?.error) {
                const data = campaignResponse?.data;
                dispatch(setIds(data));
                LocalStorage.setItem('edit_id', campaignResponse?.data?.campaign_id)
                dispatch(setCampaignName(campaignParams.campaign_name));

                const senderList = Object.entries(settings.selectedSenderList || {}).map((item) => item[1] ? item[0] : null).filter(Boolean)
                // const emailAliasList = Object.entries(settings.selectedEmailAliasList || {})?.map((item) => item[1] ? item[0] : null)?.filter(Boolean)
                const groupList = Object.entries(settings.selectedGroupList || {}).map((item) => item[1] ? item[0] : null).filter(Boolean)
                const params = {
                    ...(data?.campaign_id && { campaign_id: data?.campaign_id }),
                    ...(campaignParams?.campaign_name && { campaign_name: campaignParams?.campaign_name }),
                    settings_data: {
                        ...(settings_id && { settings_id: settings_id }),
                        variant: 'sender_list',
                        sender_list: {
                            email_accounts: {
                                ...(settings?.email_alias && { email_alias: settings?.email_alias }),
                                ...(!settings?.email_alias && { list: senderList, }),
                                ...(!settings?.email_alias && { group_list: groupList, }),
                            }
                        }
                    }
                }
                let error = await updateSettingsDetails(params);
                if (error) {
                    return true;
                }
            }
            else {
                toast.error(_errorHandler(campaignResponse?.message), toastState.error)
                return true;
            }
        }
        catch (error) {
            console.error('Getting brand list error', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleMapVariables = async () => {
        const selectedVariableList: { name: string, variable: string }[] = Object.values(prospects.selected_variable);
        const uploadedListEntries = prospects?.uploaded_prospects_list.map((item) => Object.entries(item));
        const isEmailExist = selectedVariableList.some((value) => (value.name === 'email' || value.variable === '{{ email }}')) // Check if user selected email variable
        let invalidEmails = false

        if (isEmailExist) {
            try {
                // setLoading(true);
                const originalList = uploadedListEntries.map((value) => {
                    const finalValue = value.reduce(
                        (acc: Record<string, string>, item, index) => {
                            const selectedData: string = selectedVariableList[index].name;
                            if (selectedData !== 'select') {
                                acc[selectedData] = item[1] as string;
                            }
                            return acc;
                        }, {}
                    );
                    return finalValue;
                });

                const uniqueProspects = _removeDuplicates(originalList, ['email'])
                const prospects_list = uniqueProspects.filter((item) => {
                    const testEmailPattern = _patternTest('email', item.email)
                    if (!testEmailPattern) {
                        invalidEmails = true
                    }
                    return testEmailPattern
                })
                dispatch(setProspects({ prospects_list }));

                const similarEmailResponse = await BaseApi.post('/campaign/prospects/added/in/active/campaigns', { brand_id, prospects_list });
                if (similarEmailResponse?.error) {
                    toast.error(_errorHandler(similarEmailResponse?.message), toastState.error);
                    return;
                }
                if (similarEmailResponse?.data?.similar_prospects_count_in_campaign) {
                    dispatch(setSimilarEmailModal(
                        {
                            view: true,
                            count: similarEmailResponse?.data?.similar_prospects_count_in_campaign,
                            list: similarEmailResponse?.data?.removed_prospects_list
                        }))
                }
                else {
                    const { file_name, manage_duplicate } = prospects;

                    const params = {
                        campaign_name: campaign_name,
                        ...(campaign_id && { campaign_id }),
                        prospects_data: {
                            ...(prospects_id && { prospects_id }),
                            file_name,
                            // csv_data: prospects?.uploaded_prospects_list,
                            selected_variable: prospects?.selected_variable,
                            prospects_total_count: prospects_list.length,
                            prospects_list,
                            manage_duplicate,
                        },
                    };

                    const response = await BaseApi.post('/campaign/prospects', params);
                    if (response?.error) {
                        toast.error(_errorHandler(response?.message), toastState.error)
                    } else {
                        dispatch(setIds(response.data))
                        dispatch(setCurrentStepCount(4));
                    }
                }
                if (invalidEmails) {
                    toast.success(`Invalid emails are removed from prospects list`, toastState.success)
                }

            } catch (error) {
                console.error('Mapping variable error', error);
            } finally {
                // setLoading(false);
            }
        } else {
            toast.error('Selecting an email is mandatory', toastState.error)
        }
    }

    const handleValidations = (stepNo: number) => {
        const validationResult = {
            error: false,
            errormessage: ''
        }

        // Setting validation
        if (stepNo > 5) {
            // const selectedSenderCondition = Object.values(state.settings.selectedSenderList).some(sender => sender)
            // if (!selectedSenderCondition) {
            //     validationResult.error = true;
            //     validationResult.errormessage = 'Select senders to continue'
            // }
            if (!state.scheduleSettings.schedule_id) {
                validationResult.error = true;
                validationResult.errormessage = 'Select schedule to continue'
            }
            // if (custom?.current_step_count === 3) {
            if (state.settings.signature_type === 'use_signature_for_this_campaign' || state.settings.signature_type === 'use_this_signature_when_email_id_signature_empty') {
                const selectedSignatureLength = Object.values(state.finalSettings.selectedSignature).some(signature => signature)
                if (!selectedSignatureLength) {
                    validationResult.error = true;
                    validationResult.errormessage = 'Select signature to continue'
                }
            }
            // }
        }
        // Sequence validation
        if (stepNo > 4 && (state?.sequence.length === 0)) {
            validationResult.error = true;
            validationResult.errormessage = 'Create sequence to continue'
        }
        // Prospects validation
        if (stepNo > 2) {
            if (state?.prospects?.prospect_type === 1 && state?.prospects?.prospects_list.length === 0) {
                validationResult.error = true;
                validationResult.errormessage = 'Map Variables to continue'
            }

            if (state?.prospects?.prospect_type === 1 && (custom?.current_step_count === 1 || custom?.current_step_count === 2) && (state?.prospects?.uploaded_prospects_list.length === 0)) {
                validationResult.error = true;
                validationResult.errormessage = 'Upload prospects to continue';
            }
        }

        // Brand validation
        if (!state?.ids?.campaign_id && !state?.ids?.brand_id) {
            validationResult.error = true;
            validationResult.errormessage = 'Save brand details to continue'
        }

        if (!sendersCheck()) {
            validationResult.error = true;
            validationResult.errormessage = 'Select Senders to continue';
        }

        return validationResult
    }

    const handleHeader = async (step: stepType) => {
        const validation = handleValidations(step?.stepNo)
        dispatch(updatePreviewProspectsData({ isPreview: false })) // preview false to show all prospects list in prospect result page
        // const validation = { error: false, errormessage: false }
        if (state.header_count < step.stepNo && validation?.error) {
            toast.error(validation?.errormessage, toastState.error)
        } else {
            let error = await handleCreateCampaign();
            if (error) return;
            const stepsCount = campaignStepsCount;
            const totalCount = stepsCount[step.stepNo - 1];
            dispatch(setHeaderCount(step.stepNo));
            dispatch(setCurrentStepCount(1));
            dispatch(setTotalStepCount(totalCount));
            if (step.stepNo === 3) {
                // dispatch(setProspectsVerification({ step_count: 2 }));
                dispatch(setProspectsVerification({ modal_view: true }));
            }
        }
    };

    const updateSettingsDetails = async (params: any) => {
        try {
            setLoading(true);
            const response = await BaseApi.post('/campaign/settings', params);
            if (!response.error && response?.data?.settings_id) {
                dispatch(setIds({ settings_id: response?.data?.settings_id }));
                dispatch(getErrorMessage({}))
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error)
            }
            return response?.error;
        } catch (error) {
            console.error('Updating campaign settings error', error);
            return true;
        }
        finally {
            setLoading(false);
        }
    };

    // const handleCreateDynamicCustomVariableAndProspects = async () => {
    //     try {
    //         const customVariableApi = async (variable: string) => {
    //             const params = {
    //                 campaign_id,
    //                 variable: variable
    //             }
    //             const response = await BaseApi.post('/map/variables/linked/campaign', params)
    //             const { insert_id, ...rest } = response.data;
    //             return { variable, data: { ...rest } }
    //         }

    //         const selected_variable = Object.values(prospects?.selected_variable)?.filter(item => item?.name !== "select").map(item => item.name);
    //         const neededCustomeVariable = selected_variable ? Object.keys(prospects?.prospects_list[0])?.filter((key) => {
    //             return (key !== "email" && !selected_variable?.includes(key))
    //         }) : Object.keys(prospects?.prospects_list[0]).filter(item => item !== "email");

    //         if (neededCustomeVariable?.length) {
    //             const createCustomVariables = await Promise.all(neededCustomeVariable?.map((item) => customVariableApi(item)));
    //             const selectedVariables = createCustomVariables.filter(Boolean)
    //             if (selectedVariables?.length) {
    //                 selectedVariables?.forEach(element => {
    //                     dispatch(setProspectsVariable({ [element?.variable]: element?.data }))
    //                 });
    //             }
    //             // console.log('prospectsData', prospects?.selected_variable)
    //             // console.log('createCustomVariables', createCustomVariables)
    //             const prospectsData = prospects?.prospects_list.map((item: any) => {
    //                 return createCustomVariables?.reduce((acc: any, variable: any) => {
    //                     acc[variable?.data?.name] = item[variable?.variable];
    //                     delete acc[variable?.variable]
    //                     return acc
    //                 }, { ...item })
    //             })
    //             // dispatch(setProspects({ prospects_list: prospectsData }))
    //             // console.log('prospectsData', prospectsData)

    //             const params = {
    //                 campaign_name: campaign_name,
    //                 ...(campaign_id && { campaign_id }),
    //                 prospects_data: {
    //                     ...(prospects_id && { prospects_id }),
    //                     prospects_total_count: prospectsData.length,
    //                     prospects_list: prospectsData,
    //                 },
    //             };
    //             const prospectAddingResponse = await BaseApi.post('/campaign/prospects', params);
    //             if (prospectAddingResponse?.error) {
    //                 toast.error(prospectAddingResponse?.message, toastState.error)
    //             } else {
    //                 dispatch(setIds({ prospects_id: prospectAddingResponse?.data?.prospects_id }))
    //             }
    //         }

    //     } catch (error) {
    //         console.log('creating dynamic custom variable and prospect error', error)
    //     }
    // };


    const handleStepNav = (count: number) => {
        const stepsCount = campaignStepsCount;
        if (count > 0 && state.header_count < 6) {
            const totalCount = stepsCount[state.header_count];
            const header_count = state.header_count + 1;
            dispatch(setHeaderCount(header_count));
            if (header_count === 3) {
                dispatch(setProspectsVerification({ modal_view: true }));
                dispatch(setProspectsVerification({ step_count: 1 }));
            }
            dispatch(setCurrentStepCount(1));
            dispatch(setTotalStepCount(totalCount));
        } else if (count <= 0 && state.header_count > 1) {
            console.log(count, state.header_count)
            const totalCount = stepsCount[state.header_count - 2];
            if (state.header_count - 1 === 3) {
                dispatch(setProspectsVerification({ modal_view: true }));
                // dispatch(setProspectsVerification({ step_count: 2 }));
            }
            dispatch(setHeaderCount(state.header_count - 1));
            dispatch(setCurrentStepCount(totalCount));
            dispatch(setTotalStepCount(totalCount));
        }
    };

    const brandCheck = () => {
        return !state?.ids?.brand_id;
        // const brandDetails = Object.values(state?.brand_input).every(Boolean)
        // return !brandDetails;
    }
    const sendersCheck = () => {
        const senderList = Object.entries(settings.selectedSenderList || {}).map((item) => item[1] ? item[0] : null).filter(Boolean)
        // const emailAliasList = Object.entries(settings.selectedEmailAliasList || {})?.map((item) => item[1] ? item[0] : null)?.filter(Boolean)
        const groupList = Object.entries(settings.selectedGroupList || {}).map((item) => item[1] ? item[0] : null).filter(Boolean)
        if (state.settings.email_alias) {
            return settings?.email_alias_name;
        }
        else {
            return senderList?.length > 0 || groupList?.length > 0;
        }
    }
    const contentCheck = () => {
        const initialStatus = state?.sequence?.slice(0, 1)?.every(item => {
            if (!item.versionAB) {
                return item?.delivery_data?.version_a?.subject
            }
            else {
                return item?.delivery_data?.version_a?.subject && item?.delivery_data?.version_b?.subject
            }
        })
        !initialStatus && toast.error('Kindly fill Intro Subject', toastState.error)

        const sequenceStatus = state?.sequence?.every(item => {
            if (!item.versionAB) {
                return item?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '')
            }
            else {
                return item?.delivery_data?.version_a?.message?.replace(/<[^>]+>/g, '') && item?.delivery_data?.version_b?.message?.replace(/<[^>]+>/g, '')
            }
        })
        !sequenceStatus && toast.error('Kindly fill messages in all sequence', toastState.error)
        return !(initialStatus && sequenceStatus)
    }

    const sequencePayload = () => {
        let apiPayload: ApiPayloadType = {
            campaign_id: state?.ids?.campaign_id,
            campaign_name: state?.campaign_name,
            ...(campaign_id && { campaign_id }),
            sequence_data: {
                sequence_list: state?.sequence.map(({ delayModal, triggerModal, deleteContentMenu, deleteTriggerMenu, items, ...item }, index) => {
                    index === 0 && (item.delay = { days: 0 })
                    return item
                }),
            },
        };
        if (state?.ids?.sequence_id) {
            apiPayload.sequence_data.sequence_id = state?.ids?.sequence_id;
        }
        return apiPayload;
    };

    const createAPI = async (apiPayload: ApiPayloadType) => {
        let response;
        try {
            setLoading(true);
            response = await BaseApi.post('/campaign/sequence', apiPayload);
            if (response?.error) {
                toast.error(_errorHandler(response?.message), toastState.error)
                return false;
            }
            return response?.data;
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false);
        }
    };

    const launchAPI = async () => {
        let response;
        try {
            setLoading(true)
            response = await BaseApi.post('/campaign/v2/launch', { campaign_id: state?.ids?.campaign_id });
            return response;
        }
        catch (err) {
            console.error(err)
        }
        finally {
            setLoading(false)
        }
    };

    const handleClick = async (count: number) => {
        let error = false;
        if (count > 0) {
            if (brandCheck()) {
                toast.error('Select Brand to continue', toastState.error);
                return;
            }
            if (!brand_id) {
                const checkBrandName = brandList.some(item => item.name?.toLowerCase() === state?.brand_input?.name?.toLowerCase())
                if (checkBrandName) {
                    toast.error('Brand Name already taken', toastState.error)
                    return
                }
            }
            if (!sendersCheck()) {
                toast.error('Select Senders to continue', toastState.error);
                return;
            }
            if ((prospects?.prospect_type === 1) && (state?.header_count === 2) && (custom?.current_step_count === 2)) {
                if ((prospects?.uploaded_prospects_list?.length === 0) || (prospects?.variable_list?.length === 0)) {
                    toast.error('Upload prospects to continue', toastState.error)
                    return
                }
            }
            if (state?.prospects?.prospect_type === 1 && (custom?.current_step_count === 3) && (state?.header_count === 2) && (state?.prospects?.prospects_list.length === 0)) {
                toast.error('Map Variables to continue', toastState.error)
                return
            }
            if ((custom?.current_step_count === 3) && (state?.header_count === 2) && !state?.ids?.prospects_id) {
                toast.error('Click continue to proceed', toastState.error)
                return
            }
            // if (state?.header_count === 3 && state?.prospects_verification?.step_count === 3) return

            if (state?.header_count === 4 && (state?.sequence.length === 0)) {
                toast.error('Create sequence to continue', toastState.error)
                return
            }
            if (state?.header_count === 4 && contentCheck()) {
                return
            }

            if (state?.header_count === 5 && custom?.current_step_count === 2) {
                const finalSettings = state?.finalSettings
                const settings = state?.settings

                const isSignatureSelected = Object.values(finalSettings?.selectedSignature)?.some(Boolean)
                if (((settings?.signature_type === 'use_signature_for_this_campaign') ||
                    (settings?.signature_type === 'use_this_signature_when_email_id_signature_empty'))
                    && !isSignatureSelected) {

                    toast.error('Kindly select Signature to proceed further', toastState.error)
                    return
                }

            }
            error = await handleCreateCampaign()
        }
        if (!error) {
            const totalCount = custom.current_step_count + count
            console.log(totalCount > 0 && totalCount <= custom.total_step_count)
            if (totalCount > 0 && totalCount <= custom.total_step_count) {
                dispatch(setCurrentStepCount(totalCount))
            }
            else {
                handleStepNav(totalCount)
            }
        }
    }

    const handleCreateCampaign = async () => {
        let error = false;
        if (state?.header_count === 1) return await handleSetup()
        if (state?.header_count === 2) {
            // if (custom.current_step_count === 4 && (prospects?.enrichment?.status || prospects?.search_prospects?.data)) await handleCreateDynamicCustomVariableAndProspects()
            if (custom.current_step_count === 8) handleMapVariables()
            // Code rework in progress for prospect handleMapvariables()
        }
        if (state?.header_count === 3 && state?.prospects_verification?.step_count === 3) {
            dispatch(setProspectsVerification({ step_count: 4 }))
            return true;
        }
        if (state?.header_count === 4) {
            if (state?.sequence.length === 0) return
            const data = await createAPI(sequencePayload());
            if (!data) return true;
            data?.sequence_id && dispatch(setIds({ sequence_id: data?.sequence_id }));
        }
        if (state?.header_count === 5) {
            if (custom.current_step_count === 1) {
                const { isCampaignStartDateSelected, isCampaignEndDateSelected, skip_holidays, sending_priority } = settings
                const campaign_start_date = moment(scheduleSettings.campaign_start_date).format('yyyy-MM-DD')
                const campaign_end_date = moment(scheduleSettings.campaign_end_date).format('yyyy-MM-DD')
                if (scheduleSettings.schedule_id) {
                    dispatch(getErrorMessage({}))
                    const params =
                    {
                        campaign_id,
                        campaign_name,
                        settings_data: {
                            variant: 'schedule_and_tracking',
                            settings_id,
                            ...(isCampaignStartDateSelected && { campaign_start_date }),
                            ...(isCampaignEndDateSelected && { campaign_end_date }),
                            schedule_id: scheduleSettings.schedule_id,
                            skip_holidays: {
                                status: skip_holidays?.status,
                                ...(skip_holidays?.status && { country: skip_holidays?.country })
                            },
                            sending_priority,
                            stop_reply_to,
                            tracking_options
                        }
                    }
                    error = await updateSettingsDetails(params)
                } else {
                    dispatch(getErrorMessage({ schedule_id: 'Select a Schedule to continue' }))
                    error = true
                }

            } else if (custom.current_step_count === 2) {
                const unsubscribe_rotation = Object.entries(finalSettings.selectedUnsubscribeLinks).map((item) => item[1] ? item[0] : null).filter(Boolean)
                const signature_list = Object.entries(finalSettings.selectedSignature).map((item, index) => item[1] ? finalSettings?.unsubscribeAndSignatureData?.signature_list[index] : null).filter(Boolean)
                const params =
                {
                    campaign_id,
                    campaign_name,
                    settings_data: {
                        settings_id,
                        variant: 'unsub_and_signature',
                        unsubscribe_rotation,
                        signature_rotation: {
                            signature_type: settings.signature_type,
                            ...(signature_list?.length > 0 && { signature_list }),
                        },
                        remove_watermark_signature: finalSettings.remove_watermark_signature
                    }
                }
                error = await updateSettingsDetails(params)
            }
        }
        if (state?.header_count === 6) {
            const campaignStatus = await launchAPI();
            if (campaignStatus?.error) {
                toast.error(_errorHandler(campaignStatus?.message), toastState.error)
                return
            }
            navigate('/campaigns')
            dispatch(setUpdateSettingsData('billings'));
            toast.success('Campaign launched successfully', toastState.success);
        }
        return error
    }

    React.useEffect(() => {
        const updateCampaignName = async () => {
            try {
                const params = {
                    ...(campaign_id && { campaign_id }),
                    campaign_name: debounceCampaignName,
                }
                const response = await BaseApi.post('/campaign/v2/create', params)
                if (response?.error) {
                    toast.error(response?.message, toastState.error)
                }
                dispatch(setIds(response?.data));
            } catch (error) {
                console.log('Campaign name change error', error)
            }
        }
        campaign_name?.length && state?.header_count > 1 && updateCampaignName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceCampaignName])
    return (
        <div className="header-2 z-10 m-3 rounded-md border-2 border-gray-50 dark:border-none shadow-bottom">
            <nav className="flex h-[5.1875rem] w-full items-center rounded-md bg-white dark:bg-darkTwo">
                <div className="flex w-full items-center justify-between px-[1.25rem] ">
                    <div className="flex items-center gap-2">
                        <IoArrowBackSharp size={20} className='text-column -mt-[0.15rem] cursor-pointer transition-all hover:text-brand-500'
                            onClick={() => { navigate('/campaigns'); dispatch(clearCampaignV2()) }} />
                        {/* <button
                            id="campaign-close-btn"
                            onClick={() => {
                                dispatch(setFlagStatus(false));
                                dispatch(clearCampaignV2());
                            }}
                            className="mr-3 rounded-md bg-buttonBackground p-[.5625rem] text-blueSecondary"
                        >
                            <RxCross2 />
                        </button> */}
                        <h3 className="relative text-[1.0625rem] font-bold text-gray-800">
                            <input
                                ref={inputRef}
                                id="campaign-name"
                                onFocus={(e) => e.target.select()}
                                type="text"
                                placeholder="Campaign"
                                autoComplete="off"
                                className="w-36 cursor-pointer select-all outline outline-0 dark:bg-darkTwo dark:text-white dark:caret-white"
                                value={state?.campaign_name}
                                onChange={(e) => dispatch(setCampaignName(e.target.value))}
                            />
                            {/* <MdModeEdit onClick={() => inputRef.current.focus()}
                                className='cursor-pointer absolute -right-5 top-1 text-gray-700' /> */}
                        </h3>
                    </div>
                    <div className="w-7/12 pl-24 pr-4 text-xs text-brand-700">
                        <SparkleStepper
                            customClass="mb-7"
                            steps={[
                                { stepNo: 1, name: 'Setup' },
                                { stepNo: 2, name: 'Prospects' },
                                { stepNo: 3, name: 'Verification' },
                                { stepNo: 4, name: 'Sequence' },
                                { stepNo: 5, name: 'Settings' },
                                { stepNo: 6, name: 'Review', id: 'review_header_class' },
                            ]}
                            onClick={handleHeader}
                            currentStep={state.header_count}
                        />
                    </div>
                    <div className="flex h-8 w-60 gap-2">
                        {state?.header_count !== 1 ? <Button
                            name="Back"
                            customClass={`w-full font-semibold bg-buttonBackground border border-blueSecondary dark:border-none !text-blueSecondary dark:!text-darkOne gap-2`}
                            onClick={() => handleClick(-1)}
                        /> : <div className='w-full'></div>}
                        <Button
                            id='next_section_or_launch_btn'
                            name={state.header_count === 6 ? 'Launch' : 'Next Section'}
                            onClick={() => handleClick(1)}
                            disable={loading}
                            loading={loading}
                            RightIcon={state?.header_count === 6 ? <MdRocketLaunch size={14} /> : <AiOutlineArrowRight size={16} />}
                            customClass="w-full duration-300 border border-blueSecondary transition-all dark:bg-purple-800 dark:border-none"
                        />
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default CampaignHeader;
