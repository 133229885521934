import moment from 'moment';
import { MdMarkEmailRead } from 'react-icons/md';

const BlacklistSearchData = (props: any) => {
  const { data, handleNavigate } = props;
  return (
    <div>
      {data?.map((item: any) => (
        <>
          <div className="my-2 cursor-pointer rounded-lg  p-2  hover:bg-lightpurplebg" onClick={() => handleNavigate(item?.url)}>
            <div className=" flex justify-between gap-1">
              <MdMarkEmailRead className="self-center  text-heading" size={28} />
              <div className=" flex flex-col  text-[13px]">
                <p className="m-0 w-48  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading  ">{item?.blacklist_name}</p>
                <p className="m-0 w-48  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{item?.blacklist_type}</p>
              </div>
              <div className="flex flex-col text-[13px] ">
                <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">{item?.variant}</p>
                <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{moment(item?.cdate).format('D MMM YYYY')}</p>
              </div>
            </div>
          </div>
          <hr className="w-full" />
        </>
      ))}
    </div>
  );
};

export default BlacklistSearchData;
