import Chart from 'react-apexcharts';

import 'assets/scss/Draggable_Grid.scss'
import React, { useState } from 'react';
import { formatDateIntoDDMM } from 'common/utils/utility';
// import { InsightsContext } from 'views/campaigns-insights';
// import { InsightsContext } from 'views/campaigns-insights';
// import { InsightsContext } from 'views/campaigns-insights';

type BubbleChartProps = {
    data: any;
}

const BubbleChart = (props: BubbleChartProps) => {
    const { data } = props
    console.log('bubblechart', data)
    // const { campaignTime } = useContext(InsightsContext)
    const [seriesData, setSeriesData] = useState([{ name: 'Sender', data: [{ x: 0, y: 1, z: 1, date: '', activity_by: '', activity: '' }] }])
    // const minDate = Math.min(...data?.map((x: any) => new Date(x?.date).getTime()));
    // const maxDate = Math.max(...data?.map((x: any) => new Date(x?.date).getTime()));
    // const rangePadding = (maxDate - minDate) * 0.01; // 10% padding

    React.useEffect(
        () => {
            let senderData: any[] = [];
            let prospectsData: any[] = [];
            data.forEach((item: any) => {
                if (item.activity_by === 'sender') {
                    senderData.push({ x: new Date(item?.date).getTime(), y: 1, z: 9, date: item?.date, activity_by: item?.activity_by, activity: item?.activity })
                }
                if (item.activity_by === 'prospect') {
                    prospectsData.push({ x: new Date(item?.date).getTime(), y: 1, z: 9, date: item?.date, activity_by: item?.activity_by, activity: item?.activity })
                }
            })
            setSeriesData([
                { name: 'Sender', data: senderData },
                { name: 'Prospect', data: prospectsData }
            ])
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )
    // const [series] = React.useState([
    //     {
    //         name: 'Sender',
    //         data: [
    //             { x: new Date('2024-08-16T00:00:00.000Z').getTime(), y: 1, z: 30 },
    //             { x: new Date('2024-08-17T00:00:00.000Z').getTime(), y: 1, z: 20 },
    //             { x: new Date('2024-08-18T00:00:00.000Z').getTime(), y: 1, z: 50 },
    //             { x: new Date('2024-08-19T00:00:00.000Z').getTime(), y: 1, z: 40 },
    //             { x: new Date('2024-08-20T00:00:00.000Z').getTime(), y: 1, z: 30 },
    //         ],
    //     },
    //     {
    //         name: 'Prospect',
    //         data: [
    //             { x: new Date('2024-08-22T00:00:00.000Z').getTime(), y: 1, z: 20 },
    //             { x: new Date('2024-08-24T00:00:00.000Z').getTime(), y: 1, z: 30 },
    //             { x: new Date('2024-08-25T00:00:00.000Z').getTime(), y: 1, z: 40 },
    //             { x: new Date('2024-08-26T00:00:00.000Z').getTime(), y: 1, z: 50 },
    //             { x: new Date('2024-08-27T00:00:00.000Z').getTime(), y: 1, z: 60 },
    //         ],
    //     },
    // ]);



    const options: any = {
        chart: {
            type: 'bubble',
            toolbar: { show: false },
            zoom: { enabled: false },
            width: '100%',
        },
        colors: ['#775DD0', '#008FFB', '#FFB01A', '#FF4405'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            // min: new Date('2024-09-03T00:00:00.000Z').getTime(),
            // max: new Date('2024-09-03T00:00:00.000Z').getTime(),
            // min: new Date(campaignTime?.campaign_first_activity_date).getTime() - 8.64e+7,
            // max: new Date(campaignTime?.campaign_last_activity_date).getTime() + 8.64e+7,
            // range: maxDate - minDate + rangePadding * 2, // add padding on both sides
            type: 'datetime',
            show: false,
            labels: {
                show: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            max: 2,
            show: false,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        legend: {
            show: false,
            position: 'top',
            offsetY: 60,
        },
        grid: {
            show: true,
        },
        fill: {
            opacity: 1,
            colors: ['#00D8B6', '#FFB01A', '#FF4405', '#775DD0']
        },
        tooltip: {
            x: {
                show: false
            },
            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
                // console.log(seriesData, seriesIndex, dataPointIndex)
                console.log(seriesData[seriesIndex]?.data[dataPointIndex]?.date)
                return `<div class='bg-[#2B3674] text-white text-[0.55rem] px-[5px] py-[3px] rounded-md chart-tooltip'">
                <span class='capitalize'>${seriesData[seriesIndex]?.data[dataPointIndex]?.activity_by} ${seriesData[seriesIndex]?.data[dataPointIndex]?.activity}
                 ${series[seriesIndex][dataPointIndex]}
                  message on ${formatDateIntoDDMM(seriesData[seriesIndex]?.data[dataPointIndex]?.date, 'long', true)}</span>
                </div>`;
            },
            // y: {
            // <span>Channel : Email</span><br />
            // <span>Date : ${w.globals.labels[dataPointIndex]}</span><br />
            //     formatter: (val: number) => val.toString(),
            // },
        },
    }

    return (
        <div className='h-[60px] min-w-[300px] w-full flex items-center overflow-hidden'>
            <Chart options={options} series={seriesData} type="bubble" height={150} width={350} />
        </div>
    );
};

export default BubbleChart;
