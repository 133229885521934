import { BaseApi } from 'common/api/common/base-api';
// import Button from 'common/sparkle-common/Button';
// import InputField from 'components/fields/InputField';
import React from 'react';
// import { FiArrowRight } from 'react-icons/fi';
import ExtraCreditsCard from './ExtraCreditsCard';
import MIniCardLoader from './MIniCardLoader';
// import { useAppSelector } from 'store';

const AddMoreCredits = () => {
  // const [credits, setCredits] = React.useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [cardLoading, setCardLoading] = React.useState(false);
  const [creditsDetails, setCreditsDetails] = React.useState([]);

  React.useEffect(() => {
    const fetchCreditPricing = async () => {
      try {
        setCardLoading(true);
        const billingresponse = await BaseApi.get(`/credit/addons`);
        if (!billingresponse?.error) {
          setCreditsDetails(billingresponse?.data?.data);
          setCardLoading(false);
        } else {
          setCardLoading(false);
        }
      } catch (error) {}
    };
    fetchCreditPricing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const plans = [
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     currency: 'USD',
  //     credit: 500,
  //     price: 15,
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 1500,
  //     price: 15,
  //     currency: 'USD',
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 2500,
  //     price: 15,
  //     currency: 'USD',
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 3500,
  //     price: 15,
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 4500,
  //     price: 15,
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 3500,
  //     price: 15,
  //     currency: 'USD',
  //   },
  //   {
  //     name: 'Extra Sparkles',
  //     description: 'Buy Extra Sparkles at least price',
  //     credit: 4500,
  //     price: 15,
  //     currency: 'USD',
  //   },
  // ];

  // const handlePayment = async (credit_addon_id:string) => {
  //   try {
  //     setLoading(true);
  //     // const response = await BaseApi.post(`/subscription/payment`, {
  //     //   price,
  //     //   credit,
  //     //   currency,
  //     //   name,
  //     //   description,
  //     //   // price:89,
  //     //   // credit:800,
  //     //   // currency:'USD',
  //     // });
  //     // if (response.data.sessionUrl) {
  //     //   window.location.href = `${response.data.sessionUrl}`;
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  creditsDetails?.sort((a: { credits: number }, b: { credits: number }) => (a['credits'] > b['credits'] ? 1 : -1));

  return (
    <div className="mt-4 grid grid-cols-5 gap-4">
      {cardLoading ? (
        <>
          {[...Array(5)].map((_, idx) => (
            <MIniCardLoader key={idx} />
          ))}
        </>
      ) : (
        <>
          {creditsDetails?.map((item: any, idx: number) => (
            <ExtraCreditsCard plan={item} />
          ))}
          {/* <div className="mt-4">
            <div className="w-full rounded-md border bg-white p-4 text-center shadow dark:!border-darkBorder dark:bg-darkThree">
              <div className="my-1 text-2xl font-bold dark:text-white">
                <InputField
                  autoComplete="off"
                  customClass="!h-9 !mt-0"
                  inputClass="!mt-0.5 sparkles_input !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                  placeholder="Sparkles"
                  type="number"
                  id="sparkles"
                  min={0}
                  value={credits}
                  onChange={(e: any) => setCredits(e?.target?.value)}
                  //   errorMessage={errorMessage?.current_password}
                  //   state={errorMessage?.current_password ? 'error' : ''}
                />
              </div>
              <div className="mt-2 font-bold text-blueSecondary dark:text-purple-500">${credits * 0.01 || 0}</div>

              <div className="mt-2">
                <Button
                  name={`Purchase Now`}
                  loading={loading}
                  disable={loading}
                  RightIcon={<FiArrowRight size={15} />}
                  // onClick={() => handlePayment(credits * 0.01, credits, 'USD', 'Custom Sparkles', 'Sparkles purchased in custom')}
                  onClick={() => {}}
                  customClass="security_page_change_password bg-white dark:!bg-purple-800  w-full !text-blueSecondary border border-blueSecondary dark:!border-none dark:!text-white font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
                />
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default AddMoreCredits;
