/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader } from '@chakra-ui/modal';
import { KeyValuePair } from 'common/dto/types/views/email-verifier';
import { BsPersonCircle } from 'react-icons/bs';
import UploadSingleLead from './UploadSingleLead';
import React from 'react';
import UploadOpportunitiesCsvPopup from './UploadOpportunitiesCsvPopup';
import { IoCloudUploadOutline } from 'react-icons/io5';

interface ModalDataType {
  email: string;
  multiResult: KeyValuePair[];
  result: string;
}
interface ModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalData?: ModalDataType;
}

const UploadOpportunitiesPopup = (props: ModalComponentProps) => {
  //const navigate = useNavigate();
  const [Openmodal, setOpenModal] = React.useState(false);
  const [openCsvModel, setOpenCsvModel] = React.useState(false);
  const { onClose, isOpen } = props;

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'3xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
          <ModalHeader className="!px-0 !pb-0">
            <div className="space-y-1 px-4">
              <h5 className="ms-2 text-sm text-heading dark:!text-white">Upload Opportunities</h5>
            </div>
          </ModalHeader>
          <ModalBody className="!px-0 !pt-0">
            <div className="flex w-full gap-4 px-6 pb-3">
              <div
                className="mt-4 flex h-[164px] w-2/4 cursor-pointer flex-col items-center justify-center gap-1 rounded-[10px] border dark:!bg-darkThree border-gray-300 bg-white p-[36px_20px_16px_20px] text-[#707EAE]  shadow-lg hover:border-[#4318FF]  hover:bg-[#F5F8FF] hover:text-heading  dark:border-darkBorder2   dark:hover:bg-darkOne dark:hover:text-radioPurple"
                onClick={() => {
                  setOpenCsvModel(true);
                  onClose();
                }}
              >
                <div className="rounded-full bg-[#Fff] p-2 dark:!bg-darkTwo">
                  <IoCloudUploadOutline className="text-[40px] text-[#2B3674] " />
                </div>
                <div>
                  <p className="mt-0 pt-0 text-base font-bold">Upload CSV</p>
                </div>
                <div className="mb-3">
                  <p className="text-sm ">Upload your prospects data from a CSV file.</p>
                </div>
              </div>

              <div
                className="mt-4 flex h-[164px] w-2/4 cursor-pointer flex-col items-center justify-center gap-1 rounded-[10px] border border-gray-300 bg-white p-[36px_20px_16px_20px] text-[#707EAE]  shadow-lg hover:border-[#4318FF] hover:bg-[#F5F8FF]  hover:text-heading dark:border-darkBorder2 dark:bg-darkThree  dark:hover:bg-darkOne dark:hover:text-radioPurple"
                onClick={() => {
                  setOpenModal(true);
                  onClose();
                }}
                id="single_lead_add_btn"
              >
                <div className="rounded-full bg-[#Fff] p-2">
                  <div className="rounded-full bg-[#F2F4F7] p-3">
                    <BsPersonCircle className="text-[36px]  text-heading " />
                  </div>
                </div>
                <div>
                  <p className="mt-0 pt-0 text-base font-bold ">Add single lead</p>
                </div>
                <div className="mb-3">
                  <p className="text-sm">Enter opportunity details manually</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <UploadSingleLead isOpen={Openmodal} onClose={() => setOpenModal(false)} uploader={true} />
      <UploadOpportunitiesCsvPopup isOpen={openCsvModel} onClose={() => setOpenCsvModel(false)} />
    </>
  );
};

export default UploadOpportunitiesPopup;
