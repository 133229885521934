import React from 'react'
import { MdEmail } from 'react-icons/md'

const Inbox = () => {
    return (
        <div className='h-[22.3rem] w-[28.3rem] border border-[#EAECF0] rounded-md px-4 py-2'>
            <div className='flex items-center justify-between'>
                <div>
                    <h5 className='text-[1rem] text-heading font-medium'>Inbox</h5>
                    <h5 className='text-[0.7rem] -mt-0.5 text-[#8F9BBA] font-medium'>check your recent emails/messages here</h5>
                </div>
                <button className='underline underline-offset-1 text-[0.8rem] text-brand-500 font-semibold'>View all</button>

            </div>
            <div className='py-3'>
                <div className='w-full border p-3 rounded-md'>
                    <div className="rounded-full border p-0.5 w-fit">
                        {/* <img src={info?.row?.original?.photo_url} alt="" className="size-6 rounded-full" /> */}
                        <div className="size-6 rounded-full text-orange-400 font-bold text-center bg-orange-100">D</div>
                    </div>
                    <div className='w-full border-red-500'>
                        <div>
                            <h4>Email 1</h4>
                            <span>Campaign name (Brand)</span>
                        </div>
                        <div>
                            <MdEmail />
                        </div>
                    </div>
                    <div className='w-20 border-red-500'></div>
                </div>
            </div>
        </div>
    )
}

export default Inbox