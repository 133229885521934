import { Avatar, SkeletonText } from '@chakra-ui/react';
import Button from 'common/sparkle-common/Button';
import { FaCaretDown } from 'react-icons/fa';
// import { IoMdMore } from 'react-icons/io';
import SectionToggle from './SectionToggle';
import { useEffect, useState } from 'react';
import { AiFillMail } from 'react-icons/ai';
import { BiSolidMessageSquareDetail } from 'react-icons/bi';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaLinkedin } from 'react-icons/fa';
import Typer from './Typer';
import ButtonMenu from 'common/sparkle-common/ButtonMenu';
import ChatSection from './ChatSection';
import { BaseApi } from 'common/api/common/base-api';
import { useAppDispatch, useAppSelector } from 'store';
import ComposeMailPopup from './ComposeMailPopup';
import EmailFullView from './EmailFullView';
import { setcomposEmailPopupOpen, setisReplyEditorOpen } from 'store/crm';
import { AZ_BUCKET } from 'common/constants/constants';
const Communication = () => {
  const [showOptions, setShowOptions] = useState(false);
  const { prospect_details } = useAppSelector((state) => state.crm);

  // const [isComponseOpen, setIsComposeOpen] = useState(false);
  const [mailFullViewOpen, setMailFullView] = useState(false);
  const [main_message_id, setMainMessageId] = useState('');
  const dispatch = useAppDispatch();
  const [chatMessages, setChatMessages] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showEditor, setShowEditor] = useState(false);
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_id } = opportunityInboxViewData;
  const { is_message_sent } = useAppSelector((state) => state.crm);
  const showReplyEditor = useAppSelector((state) => state?.crm?.isReplyEditorOpen);
  const composEmailPopupOpen = useAppSelector((state) => state.crm.composEmailPopupOpen);

  const modalOpenClick = () => {
    dispatch(setcomposEmailPopupOpen(true));
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    return () => {
      dispatch(setisReplyEditorOpen(false));
    };
  }, [dispatch]);

  const sendMessageData = [
    {
      name: 'Email',
      Icon: <AiFillMail className="text-brand-500 group-hover:text-white" />,
      onclick: modalOpenClick,
    },
    {
      name: 'SMS',
      Icon: <BiSolidMessageSquareDetail className="text-brand-500 group-hover:text-white" />,
    },
    {
      name: 'Whatsapp',
      Icon: <IoLogoWhatsapp className="text-brand-500 group-hover:text-white" />,
    },
    {
      name: 'LinkedIn',
      Icon: <FaLinkedin className="text-brand-500 group-hover:text-white" />,
    },
  ];

  const getChatMessages = async () => {
    setIsLoading(true);
    try {
      if (opportunity_id) {
        const data = await BaseApi.get(`/crm/pipeline/opportunity/get/mail/communication/list/${opportunity_id}`);
        if (data?.data) {
          setChatMessages(data.data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOnClickChat = (id: string) => {
    setMailFullView(true);
    setMainMessageId(id);
  };
  useEffect(() => {
    getChatMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_message_sent, opportunity_id]);

  return (
    <div className="flex h-[calc(100vh-120px)] flex-col">
      <header className="relative z-10 mt-[1px] flex items-center justify-between border bg-white p-4">
        <div className="flex items-center gap-2">
          <Avatar
            bg="#3457d5"
            src={opportunityInboxViewData?.prospect_enrich_details?.source_details?.photo_url}
            width="40px"
            height="40px"
            className="size-3 border"
            name={prospect_details?.opportunity?.[0]?.prospect_email?.split('@')[0][0]}
          />
          <h5 className="w-[150px] truncate text-[0.9rem] font-semibold capitalize text-heading">
            {opportunityInboxViewData?.prospect_enrich_details?.source_details?.first_name
              ? `${opportunityInboxViewData?.prospect_enrich_details?.source_details?.first_name} ${opportunityInboxViewData?.prospect_enrich_details?.source_details?.last_name}`
              : prospect_details?.opportunity?.[0]?.prospect_name || prospect_details?.opportunity?.[0]?.prospect_email?.split('@')[0]}
          </h5>
        </div>
        <div className="flex w-1/2 items-center justify-end gap-4">
          <SectionToggle />
          <ButtonMenu
            menuData={sendMessageData}
            condition={showOptions}
            position="left-0 top-10"
            setCondition={setShowOptions}
            Comp={<Button name="Create message" id="compose-btn" RightIcon={<FaCaretDown className="size-2" />} onClick={() => dispatch(setcomposEmailPopupOpen(true))} />}
          />
          {/* <IoMdMore className="size-5 cursor-pointer text-column" /> */}
        </div>
      </header>
      <main className={`flex ${chatMessages?.length && 'h-full'} flex-col gap-5 overflow-y-auto bg-[#fcfcfd] px-5 pt-2.5`}>
        {isLoading ? (
          <div className="p-4">
            <div className="mt-6 flex items-center gap-3">
              <SkeletonText mt="2" noOfLines={4} skeletonHeight="2" className="w-full" />
            </div>

            <SkeletonText mt="8" noOfLines={5} skeletonHeight="2" className="w-full" />

            <SkeletonText mt="4" noOfLines={7} spacing="1.5" skeletonHeight="2" className="w-full" />
          </div>
        ) : (
          <>
            {chatMessages?.length ? (
              chatMessages?.map((message, id) => <ChatSection key={id} message={message} handleOnClickChat={handleOnClickChat} index={id} />)
            ) : (
              <figure className="mt-10 flex flex-col items-center justify-center">
                <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" />
                <figcaption className="mt-3 font-bold text-heading">"Start a new conversation here'</figcaption>
                <figcaption className="mt-3 text-xs font-medium text-heading">All you multi-channel conversations with this prospect will appear here</figcaption>
              </figure>
            )}
          </>
        )}

        <ComposeMailPopup isOpen={composEmailPopupOpen} onClose={() => dispatch(setcomposEmailPopupOpen(false))} />
        <EmailFullView isOpen={mailFullViewOpen} onClose={() => setMailFullView(false)} main_message_id={main_message_id} />
      </main>
      <footer>
        {showReplyEditor && (
          <Typer
            onTyperClose={() => {
              dispatch(setisReplyEditorOpen(false));
            }}
          />
        )}
      </footer>
    </div>
  );
};

export default Communication;
