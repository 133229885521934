import { toastState } from 'common/utils/utility';
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { handleChangeEditData } from 'store/email-accounts';

const SignCanva = () => {
  const signatureCanvas = useRef(null);
  const editData = useAppSelector((state) => state?.emailAccount?.getEditData)
  const dispatch = useAppDispatch()
React.useEffect(
  ()=>{
    signatureCanvas.current.fromDataURL(editData.signature)
  }
)
  const clearSignature = () => {
    signatureCanvas.current.clear();
  };

  const saveSignature = async () => {
    const dataUrl = await signatureCanvas.current.toDataURL();
    dispatch(handleChangeEditData({ key: "signature", value: dataUrl }))
    toast.success('Signature added', toastState.success)
    // const link = document.createElement('a');
    // link.href = dataUrl;
    // console.log(dataUrl)
    // link.download = 'signature.png';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <div>
      <SignatureCanvas
        ref={signatureCanvas}
        penColor='black'
        canvasProps={{height: 200, className: 'signature-canvas w-full' }
    }
      />
  <button onClick={saveSignature} className="ml-3 rounded-2xl bg-brand-500 px-5 py-2 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
           Save Signature
          </button>
  <button onClick={clearSignature} className="ml-3 rounded-2xl bg-brand-500 px-5 py-2 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
           Clear
          </button>
    </div>
  );
};

export default SignCanva;
