import Card from "components/card";
import InputField from "components/fields/InputField";
import { useAppDispatch, useAppSelector } from "store";
import { handleChangeEditData } from "store/email-accounts";

const OptionalSettings = () => {
  const editData = useAppSelector((state) => state?.emailAccount?.getEditData)
  const { errorMessage }: any = useAppSelector((state: { custom: any; }) => state.custom);

  const dispatch = useAppDispatch();
  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-[8px] mb-[37px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Optional Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your social profiles      </p>
      </div>
      {/* inputs */}

      {/* full width inputs */}
      <InputField
        extra="mb-3"
        label="Messages per day"
        placeholder="400"
        id="limit"
        type="text"
        value={editData?.limit}
        onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "limit", value: e.target.value }))}
        errorMessage={errorMessage?.limit}
        state={errorMessage?.limit ? 'error' : ''}
      />
      {/* <InputField
        extra="mb-4"
        label="Fixed delay between emails"
        placeholder="400"
        id="fixed_delay"
        type="number"
        value={editData?.fixed_delay}
        onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "fixed_delay", value: e.target.value }))}
        errorMessage={errorMessage?.fixed_delay}
        state={errorMessage?.fixed_delay ? 'error' : ''}
      /> */}
      <div className=" grid grid-cols-1 gap-3 md:grid-cols-[240px_20px_240px] items-center">
        <InputField
          extra="mb-3"
          label="Random delay between emails"
          placeholder="Enter in min."
          id="random_delay_start"
          type="number"
          value={editData?.random_delay_start}
          onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "random_delay_start", value: e.target.value }))}
          errorMessage={errorMessage?.random_delay_start}
          state={errorMessage?.random_delay_start ? 'error' : ''}
        />
        <p className="text-base text-gray-600 pt-5"> to  </p>
        <InputField
          extra="mb-3"
          label=""
          placeholder="Enter in min."
          id="random_delay_end"
          type="number"
          value={editData?.random_delay_end}
          onChange={(e: { target: { value: string; }; }) => dispatch(handleChangeEditData({ key: "random_delay_end", value: e.target.value }))}
          errorMessage={errorMessage?.random_delay_end}
          state={errorMessage?.random_delay_end ? 'error' : ''}
        />
      </div>
    </Card>
  );
};

export default OptionalSettings;
