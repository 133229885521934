/* eslint-disable react-hooks/exhaustive-deps */
import { SkeletonText } from '@chakra-ui/react';
import { CustomMenuType } from 'common/dto/types/components/customMenu';

const CrmSkeleton = (props: CustomMenuType) => {
  return (
    <>
      <div className=" p-4 ">
        <div className="flex gap-3">
          {Array.from({ length: 5 }).map(() => (
            <div className="w-1/4">
              <SkeletonText mt="2" noOfLines={30} spacing="2" skeletonHeight="2" className="w-full" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CrmSkeleton;
