import React from 'react'
// import Button from '../Button'
// import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io'
import { useAppSelector } from 'store'
// import { useAppDispatch, useAppSelector } from 'store'
// import { setCurrentStepCount } from 'store/custom'

interface StepNavType {
    onClick?: (count: number) => void;
    handleFetchApi?: () => Promise<boolean>;
    id?:string;
}
const StepNav = (props: StepNavType) => {
    // const { onClick, handleFetchApi } = props
    const custom = useAppSelector((state) => state.custom)
    const campaignv2 = useAppSelector((state) => state.campaignv2)
    const { current_step_count, total_step_count } = custom;
    const { header_count } = campaignv2;
    const headerTitle = ['Setup', 'Prospects', 'Verification', 'Sequence', 'Settings', 'Review']
    // const dispatch = useAppDispatch()
    // const handleClick = async (count: number) => {
    //     let error = false;
    //     if (count) {
    //         error = await handleFetchApi()
    //     }
    //     if (!error) {
    //         const totalCount = current_step_count + count
    //         if (totalCount && totalCount <= total_step_count) {
    //             dispatch(setCurrentStepCount(totalCount))
    //         } else {
    //             onClick?.(totalCount)
    //         }
    //     }
    // }
    return (
        <div className='flex gap-3 justify-center items-center p-2'>
            <div className='text-xs text-white p-2 rounded-md bg-darkBorder' id={props.id || ''}>{headerTitle[header_count - 1]} ( Step {current_step_count}/{total_step_count} )</div>
            {/* <div className='flex gap-4'>
                <Button LeftIcon={<IoMdArrowBack size={23} />} px='px-[.4375rem]' py='py-[.4375rem]' bg='white' color='gray-700' customClass='transition-all duration-300' onClick={() => handleClick(-1)} />
                <Button LeftIcon={<IoMdArrowForward size={23} />} px='px-[.4375rem]' py='py-[.4375rem]' customClass='transition-all duration-300' onClick={() => handleClick(1)} />
            </div> */}
        </div>
    )
}

export default StepNav