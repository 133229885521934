import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { useAppSelector } from 'store';
import SignIn from 'views/auth/sign-in';
import SignUp from 'views/auth/sign-up';
import HomeLayout from 'views/home-layout';
import EmailAccountEditSettings from 'views/email-accounts/features/email-edit';
import EmailList from 'views/email-accounts';
import EmailVerificationList from 'views/email-verify';
import UploadEmailList from 'views/email-verify/features/UploadEmailList';
import EmailVerificationResult from 'views/email-verify/features/EmailVerificationResult';
import TrueVerifierTable from 'views/true-verifier';
import CRM from 'views/crm';
import UnmatchedEmails from 'views/unmatched-emails';
// import DomainSetup from 'views/domain-setup';
import Blacklist from 'views/blacklist';
import AccountSettings from 'views/account-settings';
import AddDoNotEmailList from 'views/account-settings/features/do-not-email/AddDonotEmails';
import Inbox from 'views/crm/features/inbox';
import Team from 'views/account-settings/features/team';
import PersonalDetails from 'views/account-settings/features/personal-details';
// import Billing from 'views/account-settings/features/billing/feature/OldBillingPage';
import Security from 'views/account-settings/features/security';
import Notifications from 'views/account-settings/features/notifications';
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword';
import ResetPassword from 'views/auth/forgotPassword/ResetPassword';
import CampaignListv2 from 'views/campaigns-v2';
import OnboardSelectBrand from 'views/crm-onboarding';
import OnboardUploadProspects from 'views/crm-onboarding/features/OnboardUploadProspects';
import OnboardConnectSalesAccount from 'views/crm-onboarding/features/OnboardConnectSalesAccount';
import OnboardConnectCalendar from 'views/crm-onboarding/features/OnboardConnectCalendar';
import OnboardCustomizePipeline from 'views/crm-onboarding/features/OnboardCustomizePipeline';
import OnboardDefineStages from 'views/crm-onboarding/features/OnboardDefineStages';
import OnboardCloseStage from 'views/crm-onboarding/features/OnboardCloseStage';
import BrandList from 'views/brands';
import DonotEmailList from 'views/account-settings/features/do-not-email';
import SenderEmailList from 'views/sender-accounts';
import UnmatchedEmailModel from 'views/crm/features/unmatched-emails/UnmatchedEmailModel';
import AllInboxData from 'views/Inbox';
import UnifiedEmailInboxData from 'views/Inbox/feature/inboxVariant/UnifiedEmailInboxData';
import UnifiedLinkedInInboxData from 'views/Inbox/feature/inboxVariant/UnifiedLinkedInInboxData';
import UnifiedTaskList from 'views/Inbox/feature/inboxVariant/UnifiedTaskList';
import CampaignInsights from 'views/campaigns-insights';
import AddEmailAccounts from 'views/sender-accounts/features/email-add/select-method/AddEmailAccounts';
import BuyDomain from 'views/sender-accounts/features/buy-domain';
// import AdminSenderEmailList from 'views/admin/sender-account';
import AdminSenderAccounts from 'views/admin/sender-account';
import UnifiedNotificationInboxData from 'views/Inbox/feature/inboxVariant/UnifiedNotificationInboxData';
import CampaignCreatePage from 'views/campaigns-v2/features/campaign-creation';
import AdminTicketTable from 'views/admin/ticket-verification';
import AdminSettings from 'views/admin/admin-settings';
import Rewards from 'views/account-settings/features/rewards';
import Live from 'views/live';
import PurchaseNumbersList from 'views/sender-accounts/features/sms-call-accounts/features/PurchaseNumbersList';
import { HelmetProvider } from 'react-helmet-async';
import EmailTrackOpen from 'views/email-track-ui';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { LocalStorage } from 'common/api/common/storage';
import Billing from 'views/account-settings/features/billing';
import CouponSettings from 'views/admin/coupon-settings';
import Subscription from 'views/admin/subscription-plan';
import CreditsettingsList from 'views/admin/credits-settings';
import CreditAddons from 'views/admin/addons-settings';
import RoleMenuList from 'views/admin/role-menu-settings';
import ApiKeyGeneratingComponent from 'views/account-settings/features/api-key';

const MainRoutes = (): JSX.Element => {
  const { environment } = useAppSelector((state) => state.globalsettings);
  const isAuthenticated = () => {
    return LocalStorage.getString(LOGIN_STORAGE_VAR);
  };

  const authLoader = async () => {
    if (isAuthenticated()) {
      return redirect(environment?.app_default_feature?.base_url);
    }
    return null;
  };
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <HomeLayout />, //<{item.component}/>
      children: [
        {
          path: '',
          element: <Navigate to={environment?.app_default_feature?.base_url} />,
        },
        {
          path: '/campaigns',
          children: [
            {
              path: '',
              element: <CampaignListv2 />,
            },
            {
              path: '/campaigns/create',
              element: <CampaignCreatePage />,
            },
            {
              path: '/campaigns/insights/:uuid',
              element: <CampaignInsights />,
            },
          ],
        },

        // {
        //   path: '/domain',
        //   element: <DomainSetup />,
        // },
        {
          path: '/blacklist-monitor',
          element: <Blacklist />,
        },
        {
          path: '/crm',
          children: [
            {
              path: '',
              element: <CRM />,
            },
            {
              path: 'inbox',
              element: <Inbox />,
            },
          ],
        },
        {
          path: '/unmatched-emails',
          children: [
            {
              path: '',
              element: <UnmatchedEmails />,
            },
            {
              path: 'test',
              element: <UnmatchedEmailModel />,
            },
          ],
        },
        {
          path: '/inbox',
          children: [
            {
              path: '',
              element: <AllInboxData />,
            },
            {
              path: 'email',
              element: <UnifiedEmailInboxData />,
            },
            {
              path: 'linkedin',
              element: <UnifiedLinkedInInboxData />,
            },
            {
              path: 'task',
              element: <UnifiedTaskList />,
            },
            {
              path: 'notification',
              element: <UnifiedNotificationInboxData />,
            },
          ],
        },
        {
          path: '/live',
          element: <Live />,
        },

        {
          path: '/email-verifier',
          children: [
            {
              path: '',
              element: <EmailVerificationList />,
            },
            {
              path: 'create',
              element: <UploadEmailList />,
            },
            {
              path: 'result',
              element: <EmailVerificationResult />,
            },
          ],
        },
        {
          path: '/true-verifier',
          element: <TrueVerifierTable />,
        },
        {
          path: 'email',
          children: [
            {
              path: 'settings',
              element: <EmailList />,
            },
            {
              path: 'settings/edit',
              element: <EmailAccountEditSettings />,
            },
          ],
        },
        {
          path: 'sender-accounts',
          children: [
            {
              path: '',
              element: <SenderEmailList />,
            },
            {
              path: 'add/email',
              element: <AddEmailAccounts />,
            },
            {
              path: 'buy/domain',
              element: <BuyDomain />,
            },
            {
              path: 'add/number',
              element: <PurchaseNumbersList />,
            },
          ],
        },
        {
          path: 'settings',
          element: <AccountSettings />,
          children: [
            {
              path: 'personal/details',
              index: true,
              element: <PersonalDetails />,
            },
            {
              path: 'billing',
              element: <Billing />,
            },
            {
              path: 'security',
              element: <Security />,
            },
            {
              path: 'team',
              element: <Team />,
            },
            {
              path: 'notifications',
              element: <Notifications />,
            },
            {
              path: 'rewards',
              element: <Rewards />,
            },
            {
              path: 'donot/list',
              element: <DonotEmailList />,
            },
            {
              path: 'donot/list/add',
              element: <AddDoNotEmailList />,
            },
            {
              path:'api',
              element:<ApiKeyGeneratingComponent/>
            }
          ],
        },
        {
          path: '/brands',
          element: <BrandList />,
        },
        {
          path: 'admin-tickets',
          element: <AdminTicketTable />,
        },
        {
          path: 'admin-accounts',
          element: <AdminSenderAccounts />,
        },
        {
          path: 'admin-settings',
          element: <AdminSettings />,
        },
        {
          path: 'admin/coupon',
          element: <CouponSettings />,
        },
        {
          path: '/credits/settings',
          element: <CreditsettingsList />,
        },
        {
          path: 'subscription-plan',
          element: <Subscription />,
        },
        {
          path: 'credit-addons',
          element: <CreditAddons />,
        },
        {
          path: 'rolemenu-list',
          element: <RoleMenuList />,
        },
      ],
    },
    {
      path: '/user/icon',
      element: <EmailTrackOpen />,
    },
    {
      path: '/sign-up',
      element: <SignUp />,
      loader: authLoader,
    },
    {
      path: '/sign-in',
      element: <SignIn />,
      loader: authLoader,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
      loader: authLoader,
    },
    {
      path: '/reset/password/:token/:uuid',
      element: <ResetPassword />,
    },

    {
      path: '/crm/onboard',
      children: [
        {
          path: '',
          element: <OnboardSelectBrand />,
        },
        {
          path: 'upload/prospect',
          element: <OnboardUploadProspects />,
        },
        {
          path: 'connect/accounts',
          element: <OnboardConnectSalesAccount />,
        },
        {
          path: 'connect/calendars',
          element: <OnboardConnectCalendar />,
        },
        {
          path: 'connect/pipeline',
          element: <OnboardCustomizePipeline />,
        },
        {
          path: 'pipeline/stages',
          element: <OnboardDefineStages />,
        },
        {
          path: 'stages/close',
          element: <OnboardCloseStage />,
        },
      ],
    },

    {
      path: '*',
      element: <Navigate replace to={environment?.app_default_feature?.base_url} />,
    },
  ]);
  return (
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={appRouter} />
      </Provider>
    </HelmetProvider>
  );
};
export default MainRoutes;
