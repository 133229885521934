import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import ArrowedTooltip from 'components/ArrowedTooltip';
// import CustomBadge from 'components/badges/CustomBadge';
import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { useAppSelector } from 'store';

const UploadLeads = (props: any) => {
  const { isOpen, onClose } = props;

  const prospectsList = useAppSelector(state => state?.campaignv2?.prospects?.prospects_list);
  const uploadLeads = useAppSelector((state) => state?.campaignv2?.prospects?.uploaded_leads_count);

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size="md">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="dark:bg-darkThree">
        <ModalCloseButton className="text-gray-700 dark:hover:text-red-500" />
        <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkTwo">
          <div className="flex items-center gap-2 ">
            <span className="text-lg text-heading dark:text-gray-200">Uploaded leads</span>
          </div>
        </ModalHeader>
        <ModalBody className="!p-3">
          <>
            <div className="mx-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-bold text-heading dark:text-gray-200">
                Total Uploaded lead
                <span></span>
              </div>
              <div className="min-w-10 text-center text-sm  font-bold text-heading dark:text-gray-200">{uploadLeads?.totalCount}</div>
            </div>
            <hr className="my-3" />
            {/* <div className="mx-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Total duplicate leads detected:{' '}
                <span>
                  <MdInfoOutline className="text-column group" />
                  <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-1`}
              data={`jbskj`}
            />
                </span>
              </div>
              <div className=" min-w-10 text-center text-sm  text-heading dark:text-gray-200">{uploadLeads?.total_duplicates_count}</div>
            </div> */}
                 <div className="mx-2 my-1.5 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Invalid email addresses:
                <div className="group relative flex items-center">
                  <MdInfoOutline className="cursor-pointer text-column" />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full z-10 mt-1 hidden !min-w-fit -translate-x-1/2 transform text-nowrap rounded bg-gray-800 !px-4 text-xs text-white group-hover:block"
                    toolTipId={`row-cdate-tooltip-1`}
                    data={` Emails skipped due to formatting errors during upload`}
                  />
                </div>
              </div>
              <div className="min-w-10 text-center text-sm text-heading dark:text-gray-200">{uploadLeads?.invalid_count}</div>
            </div>
            <div className="mx-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Total duplicate leads detected:
                <div className="group relative flex items-center">
                  <MdInfoOutline className="cursor-pointer text-column" />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full z-10 mt-1 hidden !min-w-fit -translate-x-1/2 transform text-nowrap rounded bg-gray-800 !px-4 text-xs text-white group-hover:block"
                    toolTipId={`row-cdate-tooltip-1`}
                    data={`Leads identified as duplicates within uploaded prospects`}
                  />
                </div>
              </div>
              <div className="min-w-10 text-center text-sm text-heading dark:text-gray-200">{uploadLeads?.total_duplicates_count}</div>
            </div>

            {/* <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Removed duplicates from other campaigns:{' '}
                <span>
                  <MdInfoOutline className="text-column" />
                </span>
              </div>
              <div className="min-w-10 text-center text-sm  text-heading dark:text-gray-200">{uploadLeads?.contacts_in_donot_count}</div>
            </div> */}

            <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Removed duplicates from other campaigns:
                <div className="group relative flex items-center">
                  <MdInfoOutline className="cursor-pointer text-column" />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full z-10 mt-1 hidden !min-w-fit -translate-x-1/2 transform text-nowrap rounded bg-gray-800 !px-4 text-xs text-white group-hover:block"
                    toolTipId={`row-cdate-tooltip-1`}
                    data={`Leads identified as duplicates from other campaigns`}
                  />
                </div>
              </div>
              <div className="min-w-10 text-center text-sm text-heading dark:text-gray-200">{uploadLeads?.skip_prospect_from_active_campaigns_count}</div>
            </div>
            {/* <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Invalid email addresses:{' '}
                <span>
                  <MdInfoOutline className="text-column" />
                </span>
              </div>
              <div className=" min-w-10 text-center text-sm  text-heading dark:text-gray-200">{uploadLeads?.invalid_count}</div>
            </div> */}
       
            {/* <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Contacts in 'Do Not Send' list:{' '}
                <span>
                  <MdInfoOutline className="text-column" />
                </span>
              </div>
              <div className="min-w-10 text-center text-sm  text-heading dark:text-gray-200">{uploadLeads?.contacts_in_donot_count}</div>
            </div> */}

            <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 font-normal text-heading dark:text-gray-200">
                Contacts in 'Do Not Send' list:
                <div className="group relative flex items-center">
                  <MdInfoOutline className="cursor-pointer text-column" />
                  <ArrowedTooltip
                    className="absolute left-1/2 top-full z-10 mt-1 hidden !min-w-fit -translate-x-1/2 transform text-nowrap rounded bg-gray-800 !px-4 text-xs text-white group-hover:block"
                    toolTipId={`row-cdate-tooltip-1`}
                    data={`Emails that are flagged and excluded from future campaigns`}
                  />
                </div>
              </div>
              <div className="min-w-10 text-center text-sm text-heading dark:text-gray-200">{uploadLeads?.contacts_in_donot_count}</div>
            </div>
            <hr className="my-3" />
            <div className="mx-2 mt-2 flex items-center justify-between text-sm">
              <div className="font-bold text-heading dark:text-gray-200">Confirmed leads</div>
              <div className=" min-w-10 text-center text-sm  text-heading dark:text-gray-200">{uploadLeads?.donot_removed_prospects_list?.length || prospectsList?.length}</div>
            </div>
            <hr className="my-3" />
            <Button
              name={`Continue`}
              customClass="!text-[.875rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out"
              onClick={() => onClose()}
              id="upload_leads_continue_btn"
            />
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadLeads;
