import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Radio, RadioGroup, Stack } from "@chakra-ui/react"
import { BaseApi } from "common/api/common/base-api";
import { AddNewSMTPData } from "common/dto/initial-value/views/email-accounts";
import { AddNewSMTPDTO } from "common/dto/types/views/email-accounts";
import { _isObject, _isStr, toastState } from "common/utils/utility";
import InputField from "components/fields/InputField"
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store"
import { getErrorMessage, setFlagStatus } from "store/custom";
import { setSmtpDrawerFlagStatus } from "store/email-accounts";

const AddSMTPSingleAccount = () => {
    const state = useAppSelector((state) => state?.emailAccount);
    const dispatch = useAppDispatch();
    const { errorMessage }: any = useAppSelector((state) => state.custom);
    const [formData, setFormData] = useState<AddNewSMTPDTO>(AddNewSMTPData);
    const handleFormChange = (e: { target: { name: any; value: any; }; }): void => {
        const { name, value } = e.target
        if (name === 'smtp_username') {
            setFormData((prev) => ({ ...prev, [name]: value, email: value }))
        }
        setFormData((prev) => ({ ...prev, [name]: value }))
    }
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const data = await BaseApi.post('/email/settings/smtp/create', formData);
            if (data?.error) {
                _isObject(data?.message) && dispatch(getErrorMessage(data?.message))
                _isStr(data?.message) && toast.error("Invalid SMTP and IMAP credential", toastState.error);
            } else {
                dispatch(setSmtpDrawerFlagStatus(false));
                dispatch(setFlagStatus(false));
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <Drawer placement="right" onClose={() => { dispatch(setSmtpDrawerFlagStatus(false)) }} isOpen={state?.smtpDrawerFlag} size={'lg'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton className="text-brand-500" />
                    <DrawerHeader borderBottomWidth='1px' className="text-brand-900">
                        Single Account
                    </DrawerHeader>
                    <DrawerBody>
                        <Stack spacing='24px'>
                            <Box className="flex flex-col mt-3">
                                <form onSubmit={(e) => handleFormSubmit(e)}>
                                    <div className="w-full px-[8px]">
                                        <h4 className="text-md font-bold text-navy-700 dark:text-white">
                                            IMAP/SMTP
                                        </h4>
                                    </div>
                                    <div className="mt-7 mb-2 grid grid-cols-2 gap-3 w-full">
                                        <InputField
                                            label="First Name"
                                            placeholder="First Name"
                                            id="first_name"
                                            name="first_name"
                                            type="text"
                                            onChange={handleFormChange}
                                            value={formData?.first_name}
                                            errorMessage={errorMessage?.first_name}
                                            state={errorMessage?.first_name ? 'error' : ''}
                                        />
                                        <InputField
                                            label="Last Name"
                                            placeholder="Last Name"
                                            id="last_name"
                                            type="text"
                                            name="last_name"
                                            onChange={handleFormChange}
                                            value={formData?.last_name}
                                            errorMessage={errorMessage?.last_name}
                                            state={errorMessage?.last_name ? 'error' : ''}
                                        />
                                        <InputField
                                            label="Username or Email"
                                            placeholder="Username or Email"
                                            id="smtp_username"
                                            type="text"
                                            name="smtp_username"
                                            onChange={handleFormChange}
                                            value={formData?.smtp_username}
                                            errorMessage={errorMessage?.smtp_username}
                                            state={errorMessage?.smtp_username ? 'error' : ''}
                                        />
                                        <InputField
                                            label="Password"
                                            placeholder="Password"
                                            id="smtp_password"
                                            type="password"
                                            name="smtp_password"
                                            onChange={handleFormChange}
                                            value={formData?.smtp_password}
                                            errorMessage={errorMessage?.smtp_password}
                                            state={errorMessage?.smtp_password ? 'error' : ''}
                                        />
                                        <InputField
                                            label="SMTP Host"
                                            placeholder="SMTP Host"
                                            id="smtp_host"
                                            type="text"
                                            name="smtp_host"
                                            onChange={handleFormChange}
                                            value={formData?.smtp_host}
                                            errorMessage={errorMessage?.smtp_host}
                                            state={errorMessage?.smtp_host ? 'error' : ''}
                                        />
                                        <div className="flex gap-2 items-center">
                                            <InputField
                                                label="SMTP Port"
                                                placeholder="Port"
                                                id="smtp_port"
                                                type="number"
                                                extra={`[&>input]:w-24`}
                                                name="smtp_port"
                                                onChange={handleFormChange}
                                                value={formData?.smtp_port}
                                                errorMessage={errorMessage?.smtp_port}
                                                state={errorMessage?.smtp_port ? 'error' : ''}
                                            />
                                            <RadioGroup onChange={(value) => { setFormData((prev) => ({ ...prev, smtp_secure: value })) }} >
                                                <Stack direction='row' className="p-2 mt-7">
                                                    <Radio value='ssl' size={'md'}>SSL</Radio>
                                                    <Radio value='tls' size={'md'}>TLS</Radio>
                                                    <Radio value='none' size={'md'}>None</Radio>
                                                </Stack>
                                                {errorMessage?.smtp_secure && <span className="flex items-center font-medium tracking-wide text-red-500 dark:!text-red-400 text-xs mt-1 ml-1">
                                                    {errorMessage?.smtp_secure}
                                                </span>}
                                            </RadioGroup>
                                        </div>
                                        <InputField
                                            label="Message per day (Warmups not included)"
                                            placeholder="0"
                                            id="limit"
                                            type="number"
                                            extra="[&>label]:text-xs"
                                            name="limit"
                                            onChange={handleFormChange}
                                            value={formData?.limit}
                                            errorMessage={errorMessage?.limit}
                                            state={errorMessage?.limit ? 'error' : ''}
                                        />
                                        <InputField
                                            label="Minimum time gap"
                                            placeholder="0"
                                            id="fixed_delay"
                                            type="number"
                                            extra="[&>label]:text-xs"
                                            name="fixed_delay"
                                            onChange={handleFormChange}
                                            value={formData?.fixed_delay}
                                            errorMessage={errorMessage?.fixed_delay}
                                            state={errorMessage?.fixed_delay ? 'error' : ''}
                                        />
                                    </div>
                                    <div className="flex gap-1 text-sm font-bold mt-3 ml-2">
                                        <input id="replycheckbox" type="checkbox" className="w-4 h-4" />
                                        <label htmlFor="replycheckbox" className="cursor-pointer">Set a different reply to address</label>
                                    </div>
                                    <InputField
                                        label=''
                                        placeholder=""
                                        id="set_different_reply_to_address"
                                        type="text"
                                        extra="[&>label]:hidden"
                                        name="set_different_reply_to_address"
                                        onChange={handleFormChange}
                                        value={formData?.set_different_reply_to_address}
                                        errorMessage={errorMessage?.set_different_reply_to_address}
                                        state={errorMessage?.set_different_reply_to_address ? 'error' : ''}
                                    />
                                    <div className="w-full px-[8px] mt-8 mb-6">
                                        <h4 className="text-md font-bold text-navy-700 dark:text-white">
                                            IMAP Settins (receives emails)
                                        </h4>
                                    </div>
                                    <div className="flex gap-1 text-sm font-bold mt-2 ml-2">
                                        {/* <Checkbox id="differentmailcheckbox" /> */}
                                        <input id="differentmailcheckbox" type="checkbox" className="w-4 h-4" />
                                        <label htmlFor="differentmailcheckbox" className="cursor-pointer">Use different email accounts for receiving emails</label>
                                    </div>
                                    <div className="mt-6 mb-2 grid grid-cols-2 gap-3 w-full">
                                        <InputField
                                            label="IMAP User Name"
                                            placeholder="IMAP User Name"
                                            id="imap_username"
                                            type="text"
                                            name="imap_username"
                                            onChange={handleFormChange}
                                            value={formData?.imap_username}
                                            errorMessage={errorMessage?.imap_username}
                                            state={errorMessage?.imap_username ? 'error' : ''}
                                        />
                                        <InputField
                                            label="IMAP Password"
                                            placeholder="IMAP Password"
                                            id="imap_password"
                                            type="password"
                                            name="imap_password"
                                            onChange={handleFormChange}
                                            value={formData?.imap_password}
                                            errorMessage={errorMessage?.imap_password}
                                            state={errorMessage?.imap_password ? 'error' : ''}
                                        />
                                        <InputField
                                            label="IMAP Host"
                                            placeholder="IMAP Host"
                                            id="imap_host"
                                            type="text"
                                            name="imap_host"
                                            onChange={handleFormChange}
                                            value={formData?.imap_host}
                                            errorMessage={errorMessage?.imap_host}
                                            state={errorMessage?.imap_host ? 'error' : ''}
                                        />
                                        <div className="flex gap-2 items-center">
                                            <InputField
                                                label="IMAP Port"
                                                placeholder="IMAP Port"
                                                id="imap_port"
                                                type="number"
                                                extra={`[&>input]:w-24`}
                                                name="imap_port"
                                                onChange={handleFormChange}
                                                value={formData?.imap_port}
                                                errorMessage={errorMessage?.imap_port}
                                                state={errorMessage?.imap_port ? 'error' : ''}
                                            />
                                            <RadioGroup onChange={(value) => { setFormData((prev) => ({ ...prev, imap_secure: value })) }}>
                                                <Stack direction='row' className="p-2 mt-7">
                                                    <Radio value='ssl' size={'md'}>SSL</Radio>
                                                    <Radio value='tls' size={'md'}>TLS</Radio>
                                                    <Radio value='none' size={'md'}>None</Radio>
                                                </Stack>
                                                {errorMessage?.imap_secure && <span className="flex items-center font-medium tracking-wide text-red-500 dark:!text-red-400 text-xs mt-1 ml-1">
                                                    {errorMessage?.imap_secure}
                                                </span>}
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div className="m-2 mt-8 flex justify-end">
                                        <button type="submit" className="py-2 px-12 bg-brand-500 active:bg-brand-600 text-white text-sm rounded-lg duration-300">Finish</button>
                                    </div>
                                </form>
                            </Box>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
export default AddSMTPSingleAccount;