import { MdCloudDownload, MdDelete } from 'react-icons/md';

interface GlobalOptProps {
  handleOptions?: any;
  roleAccess?: any;
}

const GlobalOpt = (props: GlobalOptProps) => {
  const { handleOptions, roleAccess } = props;
  return (
    <div id="globalOpt" className="absolute bottom-8 left-1/2 flex rounded-md bg-blackDark">
      {/* Role Restriction for Email Verify Delete */}
      {(roleAccess?.is_owner || roleAccess?.archieve_or_delete) && (
        <div className="flex cursor-default items-center gap-2 px-4 py-3.5 text-white transition-all hover:bg-brand-500" onClick={() => handleOptions('delete')}>
          <MdDelete />
          <h5 className="text-sm">Delete</h5>
        </div>
      )}
      {(roleAccess?.is_owner || roleAccess?.download_or_export) && (
        <div className="flex cursor-default items-center gap-2 rounded-e-md px-4 py-3.5 text-white transition-all hover:bg-brand-500" onClick={() => handleOptions('download')}>
          <MdCloudDownload />
          <h5 className="text-sm">Download</h5>
        </div>
      )}
    </div>
  );
};

export default GlobalOpt;
