import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdOutlineCloudDownload } from 'react-icons/md';
import GlobalOpt from './GlobalOpt';
import { useAppSelector } from 'store';
import { saveAs } from 'file-saver';
import { checkRolePermissionStatus } from 'common/utils/utility';
import React from 'react';
// import { setTicketDetails, setTicketId, showTicketDetailsStatus } from 'store/true-verifier';
// import { setTicketDrawerDetails, setToggleDrawer } from 'store/true-verifier';
// import { ticketDrawerDetailsType } from 'common/dto/types/views/true-verfier';
// import moment from 'moment';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  fetchList?: () => void;
}
interface csvTempData {
  ticket_id: string;
  subject: string;
  ticket_created_date: string;
  ticket_status: number;
}

const RowSettings = (props: RowData) => {
  const { uuidRow, rowData, id } = props;
  const custom = useAppSelector((state) => state?.custom);
  const [showOptions, setShowOptions] = useState(false);
  // const dispatch = useAppDispatch()
  const handleOptions = (selectedOpt: string, uuidSingle?: string) => {
    if (selectedOpt === 'download') {
      // console.log("uuidSingle",uuidSingle)
      downloadCsv(uuidSingle);
    }
    setShowOptions(false);
  };
  const mapData = (downloadData: csvTempData[]) => {
    const csvData = downloadData.map((val) => {
      let { ticket_id: Ticket_ID, subject: Subject, ticket_created_date: Created_on, ticket_status: Status } = val;
      return { Ticket_ID, Subject, Created_on, Status };
    });
    return csvData;
  };

  const downloadCsv = (uuidSingle?: string) => {
    const downloadData = [rowData];
    console.log("downloadData",downloadData)
    const csvContent = convertArrayOfObjectsToCSV(mapData(downloadData));
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  const convertArrayOfObjectsToCSV = (array: any): string => {
    if (array.length === 0) {
      return '';
    }
    const headers = Object.keys(array[0]).join(',');
    const rows = array.map((obj: any) => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //     event.stopPropagation();
  //     setShowOptions((prev: boolean) => !prev);
  // }

  // const handleEdit = () => {
  //     const data: ticketDrawerDetailsType = {
  //         uuid: uuidRow,
  //         subject: rowData?.subject,
  //         message: rowData?.message,
  //         emails: rowData?.verify_data_list || []
  //     }
  //     dispatch(setTicketId(uuidRow))
  //     dispatch(setTicketDrawerDetails(data))
  //     dispatch(setToggleDrawer())
  // }

  const [roleStatus, setRoleStatus] = useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'True Verifier');
      setRoleStatus(status);
      //   console.log('status', status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`}
        onMouseOver={(e) => e.stopPropagation}
        tabIndex={0}
        onBlur={() => {
          setShowOptions(false);
        }}
      >
        <IoMdMore
          className="z-0 text-lg text-gray-700"
          id={`edit-btn-${id}`}
          onClick={(event) => {
            event?.stopPropagation();
            setShowOptions(true);
          }}
        />
        {showOptions && !custom?.globalOpt && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white">
            {/* <div id='chat-btn' className='group flex gap-2 hover:bg-brand-500 hover:text-white transition-all duration-150 font-medium px-3 py-2 cursor-default campaign-download-status-btn' onClick={handleChat}>
                        <MdMessage className='text-brand-500 group-hover:text-white mt-0.5' />
                        <h5 className="text-xs">Chat</h5>
                    </div>
                    <div id='ticket-btn' className='group flex gap-2 hover:bg-brand-500 hover:text-white transition-all duration-150 font-medium px-3 py-2 cursor-default campaign-download-status-btn' onClick={handleEdit}>
                        <MdEdit className='text-brand-500 group-hover:text-white mt-0.5' />
                        <h5 className="text-xs">Edit</h5>
                    </div> */}
            {(roleStatus?.download_or_export || roleStatus?.is_owner) && (
              <div
                className="campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                onClick={(event) => {
                  event?.stopPropagation();
                  handleOptions('download', uuidRow);
                }}
              >
                <MdOutlineCloudDownload className="mt-0.5 text-brand-500 group-hover:text-white" />
                <h5 className="text-xs">Download</h5>
              </div>
            )}
          </div>
        )}
      </div>
      {custom?.globalOpt && (
        <div onClick={(event) => event?.stopPropagation()}>
          <GlobalOpt handleOptions={handleOptions} />
        </div>
      )}
    </>
  );
};

export default RowSettings;
