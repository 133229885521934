import { BaseApi } from 'common/api/common/base-api';
import { setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import SearchBox from 'components/searchBox';
import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';

interface SearchTypes {
  handleFormData: (name: string, uuid: string, brand_id: string) => void;
}
const CampaignSearchForSingleLead = (props: SearchTypes) => {
  const { handleFormData } = props;
  const [openCampaignOption, setOpenCampaignOption] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [campaignSearchdata, setCampaignSearchData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    limit: 25,
    offset: 0,
    search: '',
    filter: '',
  });
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  useEffect(() => {
    const query = setAndGetQueryParams([
      { key: 'limit', value: queryParams?.limit },
      { key: 'offset', value: queryParams?.offset },
      { key: 'search', value: queryParams?.search },
      { key: 'filter', value: queryParams?.filter },
    ]);
    const fetchCampaignData = async () => {
      try {
        const data: any = await BaseApi.get(`campaign/v2/list?${query}`);
        if (data?.data?.data?.length) {
          setCampaignSearchData(data.data.data);
        } else {
          setCampaignSearchData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const handleCampaignChange = (uuid: string, name: string, brand_id: string) => {
    setSelectedCampaign(name);
    setOpenCampaignOption(false);
    handleFormData(name, uuid, brand_id);
    // setFormData({
    //   ...formData,
    //   secondary_opportunity_id: uuid,
    // });
  };

  return (
    <div className="w-full">
      <div className="mt-2 flex flex-col gap-1">
        <p className="text-sm font-medium  !text-[#959ab9]">Select a campaign</p>
        <CustomMenu
          // btnId="select-campaign"
          open={openCampaignOption}
          onOpen={() => setOpenCampaignOption(true)}
          onClose={() => setOpenCampaignOption(false)}
          btnContent={selectedCampaign ? selectedCampaign : 'Campaign'}
          btnClassName="w-full justify-between !text-sm !rounded-md !font-normal truncate mt-2 border border-gray-200 text-[#667085] focus:border-[#4318FF] !h-10 text-center items-cemter campaign-search-btn"
          menuListClassName="w-full h-44 overflow-auto !mt-1 !p-0 border border-menuBorder rounded-md "
          rightIcon={<BiChevronDown size={18} />}
        >
          <div className="p-2">
            <SearchBox id="prospect-search-input" placeholder="Search campaign" onChange={(e) => handleCampaignSearch(e)} />
            {campaignSearchdata.length ? (
              <div className="max-h-25 mt-2 overflow-y-auto">
                {campaignSearchdata.map((item: any, index) => (
                  <div
                    key={item.uuid}
                    id={`choose-${0}`}
                    className={`campaign-select-option-${index} w-full cursor-pointer py-1 pl-2 text-left text-[.8125rem] font-medium text-column ${
                      selectedCampaign === item?.campaign_name ? 'bg-menuBorder text-white' : 'hover:bg-buttonBackground'
                    }`}
                    onClick={() => handleCampaignChange(item.uuid, item.campaign_name, item.brand_id)}
                  >
                    {item.campaign_name}
                  </div>
                ))}
              </div>
            ) : (
              <span className="w-full pl-4 text-center  text-xs text-gray-700 ">No results found</span>
            )}
          </div>
        </CustomMenu>
      </div>
    </div>
  );
};

export default CampaignSearchForSingleLead;
