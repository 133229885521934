import React from 'react'
import APIHeader from './feature/APIHeader'
import { useAppDispatch, useAppSelector } from 'store'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { MdEdit } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { setApiFormData, setApiList, setApiSelectedList, toggleDependency } from 'store/account-settings';
import TableLoader from 'components/Loaders/TableViewLoader';
import InfiniteScrollComponent from 'components/infinityScroll';
import { BaseApi } from 'common/api/common/base-api';
import { setAndGetQueryParams } from 'common/utils/utility';
import MultiselectGlobalOpt from 'components/muitiSelectcomponent/MultiselectGlobalOpt';
import { AiFillDelete } from 'react-icons/ai';

const ApiKeyGeneratingComponent = () => {
  const list = useAppSelector((state) => state?.accountSettings?.api_key?.list);
  const selectedLists = useAppSelector((state) => state?.accountSettings?.api_key?.selectedLists);
  const loading = useAppSelector((state) => state?.accountSettings?.api_key?.loading);
  const dispatch = useAppDispatch();
  const columnHelper = createColumnHelper<any>();

  const handleChangeLimit = (uuid: string, data: any) => {
    dispatch(setApiFormData({ uuid, modal: true, name: data?.name, limit: data?.limit }))
  }

  const handleDeleteApiKey = (uuid: string, data: any) => {
    const remainsList = list?.filter((item: any) => item?.uuid !== uuid);
    dispatch(setApiList(remainsList))
    dispatch(toggleDependency())
  }

  const columnOption = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="!ml-7 flex items-center">
          <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="!ml-7 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <input
              type="checkbox"
              id={`row-${info?.row.id}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Name
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs font-semibold !text-gray-800 row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('api_key', {
      id: 'api_key',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Key
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('added_by', {
      id: 'added_by',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Added by
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('sparkle_consumed', {
      id: 'sparkle_consumed',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Sparkle consumed
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('limit', {
      id: 'limit',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Limit
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('added_date', {
      id: 'added_date',
      header: (row) => (
        <div className="column-replied group mr-5 flex size-full select-none items-center font-medium">
          <h5 className="relative">
            Added date
          </h5>
        </div>
      ),
      cell: (info) => {
        return (
          <p className={`mr-5 text-xs row-replied-${info?.row?.id}`}>
            {info?.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: (row) => (
        <></>
      ),
      cell: (info) => {
        return <>{<CommonRowSettings bodyClassName='w-36 !-left-[130px]' uuidRow={info?.row?.original?.uuid} rowData={info?.row?.original} options={
          [
            {
              icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
              accessName: 'edit',
              name: 'Change limit',
              functioning: handleChangeLimit,
            },
            {
              icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
              accessName: 'delete',
              name: 'Delete',
              functioning: handleDeleteApiKey,
            },
          ]
        } />}</>;

      },
    }),
  ];

  const table = useReactTable({
    data: list,
    columns: columnOption,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });



  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'offset', value: 1 },
            // { key: 'search', value: queryParams?.search },
          ]);
          const response = await BaseApi.get(`/brand/statistics/list?${query}`);
          const responseData: any = response.data;
          console.log('responseData', responseData);
          // if (!responseData?.data) return setBrandData((prev) => ({ ...prev, data: [], count: 0 }));
          // if (responseData?.data?.length < 25) {
          //   setBrandData((prev) => ({
          //     ...prev,
          //     data: [...prev?.data, ...responseData?.data],
          //     count: responseData?.total_records?.count,
          //   }));
          // } else {
          //   setBrandData((prev) => ({
          //     ...prev,
          //     data: [...prev?.data, ...responseData?.data],
          //   }));
          // }
          // setQueryParams((prev) => ({
          //   ...prev,
          //   offset: prev?.offset + 1,
          // }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      list?.length && list?.length < 1 && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );

  const handleBrandMultipleDeletes = async () => {
    const remainsList = list?.filter((item: any) => !selectedLists?.includes(item?.uuid));
    dispatch(setApiList(remainsList))
  }

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original?.uuid);
   dispatch(setApiSelectedList(tableSelectedData))

    // if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    // else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  return (
    <div className='h-[90vh] overflow-y-auto'>
      {/* Header */}
      <APIHeader />
      {/* Table List */}
      <div className='border bg-white'>
        {!loading ? (
          <>
            <div className="mb-2 h-[78vh]  overflow-y-auto overflow-x-scroll  scroll-smooth xl:overflow-x-hidden">
              <table className="w-full ">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        const RowDataWidth = ['w-[5%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[5%]'];
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-3.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[12px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className=" divide-y-[1px] dark:divide-darkBorder ">
                  {list?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className={`cursor-default select-none text-start hover:bg-hover dark:hover:bg-darkOne`}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = ['w-[5%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[15%]', 'w-[5%]'];
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9} className="pt-2 text-center text-[12px] font-medium text-gray-700">
                        No Result Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                }
              />
            </div>
          </>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={9}
            row={3}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
          />
        )}
        {selectedLists?.length > 1 && <MultiselectGlobalOpt options={[
          {
            icon: <AiFillDelete />,
            name: 'Delete',
            functioning: handleBrandMultipleDeletes,
          },
        ]} />}
      </div>
    </div>
  )
}

export default ApiKeyGeneratingComponent