
const InputWithBtn = (props: {
    label: string,
    id: string;
    content: string;
    placeholder?: string;
    extra?: string;
    type?: string;
    value?: string | number;
    link?: string;
}) => {
    const {
        label,
        id,
        extra,
        type,
        placeholder,
        value,
        content,
        link,
    } = props;
    return <div className="mt-[31px] ">
        <label
            htmlFor={id}
            className={`text-base font-bold text-navy-700 dark:text-white md:mb-0}`}>
            {label}
        </label>
        <span className="text-base font-bold text-navy-700 underline float-right dark:text-white md:mb-0">{link}</span>
        <div className={`mt-[10px] flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[20px] dark:!border-white/10`}>
            <input
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                className={`placeholder:text-lightFourth h-full w-full text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white ${extra}`}
            />
            <button className="linear flex items-center justify-center rounded-2xl bg-brand-500 p-3 px-20 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                {content}
            </button>
        </div>
    </div>

}


export default InputWithBtn