import React, { useState } from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { FaArrowLeft, FaRegCircleCheck } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store';
import { setFlagStatus } from 'store/custom';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { OtpComponentProp } from 'common/dto/types/views/otppage';
import { toastState } from 'common/utils/utility';

const OtpDeletePage = (props: OtpComponentProp) => {
  const { isOpen, onClose, workspacedata } = props;
  const custom = useAppSelector((state) => state?.custom);

  const [handleState, setHandleState] = React.useState({
    loading: false,
    otpPage: false,
  });

  const numInputs = 6;
  const [otp, setOtp] = useState(Array(numInputs).fill(''));
  const [focusIndex, setFocusIndex] = useState(0);
  const dispatch = useAppDispatch();

  const handleChange = (index: any) => (event: any) => {
    const { value } = event.target;
    const newOtp = [...otp];

    if (/^[0-9]$/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < numInputs - 1) {
        setFocusIndex(index + 1);
      }
    }
  };

  const handleConfirmRequest = async () => {
    try {
      setHandleState((prevState) => ({ ...prevState, loading: true }));

      const deleteResponse = await BaseApi.delete(`/workspace/delete/request/otp/${workspacedata?.uuid}`);
      if (!deleteResponse?.error) {
        toast.success('OTP Sent Successfully', toastState.success);
        setHandleState((prevState) => ({ ...prevState, otpPage: true }));
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      } else {
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      setHandleState((prevState) => ({ ...prevState, loading: false }));
      console.error('Erro for Request OTP page', error);
    }
  };

  const handleSubmitDeleteOTP = async () => {
    try {
      setHandleState((prevState) => ({ ...prevState, loading: true }));
      const otpData = {
        otp: otp.join(''),
        uuid: workspacedata?.uuid,
      };
      const otpConfirmResponse = await BaseApi.delete(`/workspace/delete/verify/otp`, { data: otpData });
      if (!otpConfirmResponse?.error) {
        setOtp(Array(numInputs).fill(''));
        toast.success('Workspace Deleted Successfully', toastState.success);
        handleClose();
        dispatch(setFlagStatus(!custom?.flagStatus));
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      } else {
        // toast.error('OTP is Required', toastState.error);
        setHandleState((prevState) => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      console.error('Erro for ENter OTP page', error);
    }
  };

  const handleClose = () => {
    onClose();
    setOtp(Array(numInputs).fill(''));
  };

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      setFocusIndex(index - 1);
    }
  };

  React.useEffect(() => {
    const input = document.getElementById(`otp-${focusIndex}`);
    if (input) {
      input.focus();
    }
  }, [focusIndex]);

  const disable = false;

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkTwo">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkThree">
            {!handleState?.otpPage ? (
              <div className="flex items-center gap-2">
                <FaArrowLeft size={16} className="dark:text-gray-200" onClick={handleClose} />
                <FaRegCircleCheck size={32} className="rounded-full border-4 border-red-100 bg-red-200 p-1  text-red-600 " />
                <span className="text-sm text-blackDark dark:text-gray-200">Delete workspace “{workspacedata?.name}”</span>
              </div>
            ) : (
             
              <div className="flex items-center justify-center ">
                <HiOutlineMail size={38} className="rounded-full border-4 border-blue-100 bg-blue-200 p-1  text-blue-600 " />
              </div>
            )}
          </ModalHeader>
          <ModalBody className="!p-3">
            {!handleState?.otpPage ? (
              <div className=" p-4 text-column dark:text-gray-400">Do you want to delete "{<span className="text-red-500">{workspacedata?.name}</span>}" workspace from your organization?</div>
            ) : (
              <>
                <div className=" text-center">
                  <h1 className="font-semibold text-blackDark dark:text-white">Check your email</h1>
                  <h5 className="text-xs text-column dark:text-gray-300">We sent a temporary code to</h5>
                  <h5 className="text-xs font-semibold text-column dark:text-gray-300">{workspacedata?.organization_owner_data?.email}</h5>
                </div>

                <div className="mt-4 flex items-center justify-center gap-2">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      id={`otp-${index}`}
                      type="tel"
                      maxLength={1}
                      value={value}
                      onKeyDown={handleKeyDown(index)}
                      onChange={handleChange(index)}
                      className="h-11 w-11 rounded-md border border-brandLinear text-center hover:border-2 hover:border-blue-300 dark:bg-navy-900 dark:text-white"
                      autoComplete="off"
                    />
                  ))}
                </div>
                <div className=" mt-4 text-center">
                  <h5 className="text-xs  text-column dark:text-gray-300">
                    Didn’t receive the email?
                    {
                      <span onClick={handleConfirmRequest} className="ml-1 text-xs font-semibold text-blueSecondary">
                        Click to resend
                      </span>
                    }
                  </h5>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="!-mt-2 !mb-1">
            {!handleState?.otpPage ? (
              <>
                <Button
                  id="blacklist-close"
                  name="Cancel"
                  onClick={() => {
                    setHandleState((prevState) => ({ ...prevState, otpPage: false }));

                    // setOtpPage(false);
                    handleClose();
                    setOtp(Array(numInputs).fill(''));
                  }}
                  customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
                />
                <Button
                  id="add-user-invite-btn"
                  disable={disable || handleState?.loading}
                  loading={handleState?.loading}
                  name="Confirm"
                  onClick={handleConfirmRequest}
                  customClass={` w-full ${handleState?.loading ? '' : '!bg-danger'} dark:border-none confirm_btn_otp_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out`}
                />
              </>
            ) : (
              <>
                <Button
                  id="blacklist-close"
                  name="Cancel"
                  onClick={() => {
                    setHandleState((prevState) => ({ ...prevState, otpPage: false }));
                    handleClose();
                    setOtp(Array(numInputs).fill(''));
                  }}
                  customClass="!bg-[#F4F7FE] w-full !text-blackDark border cancel_btn_otp_page border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
                />
                <Button
                  id="add-user-invite-btn"
                  disable={disable || handleState?.loading}
                  loading={handleState?.loading}
                  name="Delete"
                  onClick={handleSubmitDeleteOTP}
                  customClass={` w-full ${handleState?.loading ? '' : '!bg-danger'} dark:border-none delete_btn_otp_page !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out`}
                />
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OtpDeletePage;
