import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import moment from 'moment';
import React from 'react';
import { MdVerifiedUser } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
// import { GiTwoCoins } from 'react-icons/gi';
// import { AiFillWarning } from 'react-icons/ai';
import {
  setTicketDetails,
  showTicketDetailsStatus,
  setTicketId,
} from 'store/true-verifier';
import { setCredits } from 'store/custom';
import { AiFillWarning } from 'react-icons/ai';
import { setUpdateSettingsData } from 'store/global-settings';

interface ModalComponentProps {
  isOpen: { show: boolean; clickedData: { [key: string]: string } };
  onClose: () => void;
  verifierData?: any;
  setIsOpen?: any;
  showPaymentWarning: boolean;
  setShowPaymentWarning: any;
  setPayment: any;
}

interface handleChangeType {
  type?: string;
  item?: any;
}

const TrueVerifierModel = (props: ModalComponentProps) => {
  const {
    onClose,
    isOpen,
    verifierData,
    setIsOpen,
    showPaymentWarning,
    setShowPaymentWarning,
    setPayment,
  } = props;
  const [selectedRow, setSelectedRow] = React.useState<any>({});
  const [isFetching, setIsfetching] = React.useState(false);

  const custom = useAppSelector((state) => state?.custom);
  const [totalSelectedCount, setTotalSelectedCount] = React.useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Select function
  const handleSelect = (props: handleChangeType) => {
    const { type, item } = props;
    if (type === 'all') {
      const selectedData = verifierData?.reduce(
        (acc: { [key: string]: boolean }, val: { [key: string]: string }) => {
          if (selectedRow?.hasOwnProperty(val?.originalName)) {
            acc[val?.originalName] = !selectedRow?.all;
          } else {
            acc[val?.originalName] = true;
          }
          return acc;
        },
        {}
      );
      setSelectedRow((prev: any) => ({
        ...selectedData,
        all: !prev?.all,
      }));
      return;
    }
    setSelectedRow((prev: any) => {
      const objectLength = Object.keys(prev).length;
      let selectedObj: { [key: string]: boolean } = {
        [item?.originalName]: !prev[item?.originalName],
      };
      if (objectLength) {
        selectedObj = {
          ...prev,
          ...selectedObj,
        };
      } else {
        const selectedData = verifierData?.reduce(
          (acc: { [key: string]: boolean }, val: { [key: string]: string }) => {
            acc[val?.originalName] = false;
            return acc;
          },
          {}
        );
        selectedObj = {
          ...selectedData,
          ...selectedObj,
        };
      }
      if (selectedObj?.hasOwnProperty('all')) {
        delete selectedObj['all'];
      }
      const all = Object.values(selectedObj).every((val: any) => val === true);
      return { ...selectedObj, all };
    });
  };
  // creating new ticket
  const handleClick = async () => {
    const selectedData = Object.keys(selectedRow).filter(
      (key) => key !== 'all' && selectedRow[key]
    );
    try {
      setIsfetching(true);
      const params = {
        email_verifier_id: isOpen?.clickedData?.uuid,
        subject: isOpen?.clickedData?.listName,
        message: 'Please truly verify the attached emails.',
        verify_ticket_list: selectedData,
        variant_uuid: isOpen?.clickedData?.uuid,
      };

      const response = await BaseApi.post(
        '/true/verify/create/ticket/with/email/verify',
        params
      );
      if (
        response?.status === 200 &&
        response?.message === 'credits_not_found'
      ) {
        setShowPaymentWarning(true);
        dispatch(setUpdateSettingsData('billings'));

        return;
      }

      if (!response?.error) {
        dispatch(setTicketId(response?.data[0]?.uuid));
        const createdDate = moment(response?.data?.ticket_created_date).format(
          'DD/MM/YYYY'
        );
        const closedDate = response?.data?.ticket_closed_date
          ? moment(response?.data?.ticket_created_date).format(
            'DD/MM/YYYY HH:mm'
          )
          : response?.data?.ticket_closed_date;
        const details = {
          ticket_id: response?.data?.ticket_id,
          status: response?.data?.ticket_status,
          closed_date: closedDate,
          created_date: createdDate,
        };
        dispatch(setTicketDetails(details));
        dispatch(
          setCredits({
            totalAvailableCredits: custom.credits.totalAvailableCredits,
            totalCredits: custom.credits.totalCredits,
            hitCount: 1,
          })
        );
        navigate('/true-verifier');
        dispatch(showTicketDetailsStatus(true));
      }
    } catch (error) {
      console.log('True verifier model error', error);
    } finally {
      dispatch(setUpdateSettingsData('billings'));
      setIsfetching(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedRow({});
    setTotalSelectedCount(0);
    setIsOpen((prev: any) => ({ ...prev, show: false }));
  };

  const handleCount = (type: string, e: any, count?: number) => {
    if (type === 'all' && e.target.checked) {
      const count = verifierData.reduce((a: any, c: any) => c.count + a, 0);
      setTotalSelectedCount(count);
    } else if (type === 'all' && !e.target.checked) {
      setTotalSelectedCount(0);
    } else if (e?.target?.checked) {
      setTotalSelectedCount((prev) => prev + count);
    } else {
      setTotalSelectedCount((prev) => prev - count);
    }
  };

  const buttonDisable = Object.values(selectedRow).some((val) => val === true)
    ? isFetching
    : true;
  return (
    <Modal isCentered isOpen={isOpen?.show} onClose={handleClose} size="2xl">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className='dark:bg-darkTwo'>
        <ModalHeader padding={0}>
          {showPaymentWarning && (
            <div
              className="flex items-center justify-between border border-b-[#FEC84B] p-5"
              style={{
                background:
                  'linear-gradient(90.31deg, #FFFFFF 0%, #FEF0C7 177.61%)',
              }}
            >
              <div>
                <p className="flex gap-3 text-sm text-heading">
                  <span className="text-lg text-[#F79009]">
                    {' '}
                    <AiFillWarning />
                  </span>
                  Your true verifier credits has been exhausted
                </p>
              </div>
              <div>
                <button
                  className="flex items-center gap-1 rounded bg-[#F79009] p-2 text-sm font-thin text-white"
                  id="refill"
                  onClick={() => {
                    // onClose();
                    setPayment(true);
                  }}
                >
                  {' '}
                  <span className="text-lg">{/* <GiTwoCoins /> */}</span>
                  Refill credits
                </button>
              </div>
            </div>
          )}
          <div className="p-5">
            <div className="flex items-start justify-between space-y-1">
              <div>
                <h5 className="text-[1rem] text-heading dark:text-purple-500">True Verifier</h5>
                <p className="text-sm font-thin text-gray-600 dark:text-gray-300">
                  Select issues to verify
                </p>
              </div>
              <div>
                <p className=" mr-7 rounded-full bg-[#F2F4F7] dark:bg-purple-900 px-2 py-1 text-xs dark:text-white text-[#344054]">
                  {totalSelectedCount} emails selected{' '}
                  <span>
                    <ModalCloseButton
                    className='dark:hover:text-red-500'
                      position="absolute"
                      top={showPaymentWarning ? '97px' : '18px'}
                      right="12px"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <div className="flex items-center border-b border-graybg bg-[#F9FAFB] dark:bg-darkThree dark:border-darkBorder px-3 py-4 text-xs text-column">
              <div className="ml-6 flex w-full items-center gap-3">
                <input
                  id="select-all-input"
                  type="checkbox"
                  className="h-[.9375rem] w-[.9375rem] cursor-pointer border border-red-500 accent-brand-500 outline-none dark:accent-purple-600"
                  onChange={(e) => {
                    handleSelect({ type: 'all' });
                    handleCount('all', e, 0);
                  }}
                  checked={selectedRow?.all || false}
                />
                <span className="cursor-default dark:text-gray-200">Status</span>
              </div>
              <h5 className="w-[80%] cursor-default dark:text-gray-200">Numbers of emails</h5>
            </div>
            {verifierData?.map((item: any) => {
              return (
                <div
                  key={item?.originalName}
                  className="flex items-center border-b border-graybg dark:border-darkBorder px-3 py-4 text-sm font-bold text-heading dark:text-purple-500"
                >
                  <div className="flex w-full items-center gap-3">
                    <span
                      className="h-3 w-3 rounded-full border"
                      style={{ background: item?.color }}
                    ></span>
                    <input
                      type="checkbox"
                      className="h-[.9375rem] w-[.9375rem] cursor-pointer border border-red-500 accent-brand-500 outline-none dark:accent-purple-600"
                      onChange={(e) => {
                        handleSelect({ item });
                        handleCount('single', e, item.count);
                      }}
                      checked={selectedRow[item?.originalName] || false}
                    />
                    <span className="cursor-default">{item?.label}</span>
                  </div>
                  <h5 className="w-[73%] cursor-default">{item?.count}</h5>
                </div>
              );
            })}
          </div>
          <div className="flex gap-2 px-4 py-2">
            <Button
              id="credit-close"
              name="Cancel"
              customClass="w-full !bg-white !text-gray-800 !font-bold duration-300"
              onClick={() =>
                setIsOpen(
                  (prev: {
                    show: boolean;
                    clickedData: { [key: string]: string };
                  }) => ({ ...prev, show: false })
                )
              }
            />
            <Button
              id="start-verification-btn"
              disable={buttonDisable}
              loading={isFetching}
              name="Start Verification"
              LeftIcon={<MdVerifiedUser size={15} />}
              customClass="w-full duration-300 hover:bg-purple-700"
              onClick={handleClick}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrueVerifierModel;
