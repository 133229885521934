import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';
import { UserCreditUsageList } from 'common/dto/types/views/credit-usage';
import Button from 'common/sparkle-common/Button';
import CheckBox from 'common/sparkle-common/MultiSelectCheckBox.tsx';
import { CSVFileDownloader, jwtEncrypt, setAndGetQueryParams } from 'common/utils/utility';
import ArrowedTooltip from 'components/ArrowedTooltip';
import CustomBadge from 'components/badges/CustomBadge';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import moment from 'moment';
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store';
import { setUserUsage } from 'store/account-settings';

interface PropsType {
  loading: boolean
}
const TableList = (props: PropsType) => {
  const { loading } = props
  // Redux State
  const user_usage = useAppSelector(state => state?.accountSettings?.billing?.usage?.user_usage);
  const selected_date = useAppSelector(state => state?.accountSettings?.billing?.usage?.selected_date);
  const selected_user = useAppSelector(state => state?.accountSettings?.billing?.usage?.user?.selected_user);
  // Local State
  const [selectedList, setSelectedList] = React.useState([])
  const [exportLoading, setExportLoading] = React.useState(false)
  const [queryParams, setQueryParams] = React.useState({ offset: 1, sort: { columnName: '', order: '' } })
  // Variable
  const columnHelper = createColumnHelper<UserCreditUsageList>();
  const dispatch = useAppDispatch()

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-[0.6rem] flex items-center size-full">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-3.5 accent-brand-500 dark:accent-purple-600 cursor-pointer w-full block"
          />
        </div>
      ),
      cell: (info: any) => {
        return (
          <div className="ml-5 flex items-center">
            <CheckBox onSelect={() => { }} info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('first_name', {
      id: 'first_name',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium ">Users</div>,
      cell: (info) => (
        <div className="group relative flex w-[15rem] items-center gap-2 font-semibold">
          <div className="rounded-full border p-0.5 size-8 flex justify-center items-center">
            {info?.row?.original?.picture ? <img src={info?.row?.original?.picture} alt="" className="size-6 rounded-full" /> : <div>{info?.getValue()?.[0]}{info?.row?.original?.last_name[0]}</div>}
          </div>{' '}
          <div className="truncate text-xs text-column font-semibold w-[11.25rem]">{info.getValue() ? `${info.getValue()} ${info?.row?.original?.last_name[0]}` : "-"}</div>
          {info.getValue()?.length > 24 && (
            <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-${info?.row?.id}`}
              data={`${info.getValue()} ${info?.row?.original?.last_name[0]}`}
            />
          )}
        </div>
      ),
    }),
    columnHelper.accessor('feature', {
      id: 'feature',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium">Features</div>,
      cell: (info) => (
        <div className='group relative'>
          <p className={`truncate capitalize text-xs text-column font-semibold row-credits-${info?.row?.id} w-[12.5rem]`} id={`credits - ${info.getValue()}`}>
            {info.getValue()?.replaceAll('_', " ")}
          </p>
          {/* {info.getValue()?.length > 24 && (
            <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-${info?.row?.id}`}
              data={info.getValue()}
            />
          )} */}
        </div>
      ),
    }),
    columnHelper.accessor('details', {
      id: 'details',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium">Details</div>,
      cell: (info) => (
        <div className='group relative'>

          <p className={`truncate text-xs w-[12.5rem] text-column font-medium row-credits-${info?.row?.id}`} id={`credits - ${info.getValue()}`}>
            {info.getValue()}
          </p>
          {info.getValue()?.length > 37 && (
            <ArrowedTooltip
              className="absolute left-0 top-full mt-1 hidden !min-w-fit !px-4 group-hover:block"
              toolTipId={`row-cdate-tooltip-${info?.row?.id}`}
              data={info.getValue()}
            />
          )}
        </div>
      ),
    }),
    columnHelper.accessor('credit_count', {
      id: 'credit_count',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium">Sparkles used</div>,
      cell: (info) => (
        <div className='group relative'>
          <p className={`text-xs text-column w-[12.5rem] truncate`} id={`credits - ${info.getValue()}`}>
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      enableSorting: false,
      header: (row) => <div className="flex size-full select-none items-center truncate font-medium">Date & time</div>,
      cell: (info) => (
        <div className='group relative'>
          <p className={`truncate text-xs w-[18.75rem] text-column font-medium row-credits-${info?.row?.id}`}>
            {moment(info.getValue()).format('DD-MM-YYYY HH:MM')}
          </p>

        </div>
      ),
    }),
  ]

  const table = useReactTable({
    data: user_usage?.list,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });



  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val) => val.original);
    setSelectedList(tableSelectedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  const handleExportBtn = async () => {
    try {
      setExportLoading(true)
      let data: any[] = []
      if (selectedList?.length) {
        data = selectedList
      } else {
        if (user_usage?.list_count <= 25) {
          data = user_usage?.list
        } else {
          const params = {
            user_id: selected_user,
            start_date: selected_date?.start_date,
            end_date: selected_date?.end_date
          }
          const encrypytedData = jwtEncrypt(params)
          const response = await BaseApi.get(`/billing/usage/user/history?limit=${user_usage?.list_count}&filter_data=${encrypytedData}`);
          if (!response?.error) {
            data = response?.data?.data;
          }
        }
      }
      const formattedData = data?.map((data) => ({
        "Name": `${data?.first_name} ${data?.last_name}`,
        "Feature": data?.feature?.split('_')?.map((char: string) => (char?.[0]?.toUpperCase() + char?.slice(1, char.length)))?.join(' '),
        "Details": data?.details,
        "Sparkles": data?.credit_count,
        "Date": moment(data?.date).format("DD-MM-YYYY HH:MM")?.toString(),
      }));

      CSVFileDownloader(formattedData, 'user usage')
    } catch (error) {
      console.log('Export usage error', error)
    } finally {
      setExportLoading(false)
    }
  }

  const creditsFetch = React.useCallback(
    async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
      const fetchCampaignData = async () => {
        try {
          setIsFetching(true);
          const params = {
            user_id: selected_user,
            start_date: selected_date?.start_date,
            end_date: selected_date?.end_date
          };

          const encrypytedData = jwtEncrypt(params);

          const query = setAndGetQueryParams([
            // { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            // { key: 'search', value: queryParams?.search },
            { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
            { key: "filter_data", value: encrypytedData }
            // { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/billing/usage/user/history?${query}`);
          const responseData = response?.data;
          dispatch(setUserUsage({ list: [...user_usage?.list, ...responseData?.data] }))
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };
      user_usage?.list?.length && user_usage?.list?.length < user_usage?.list_count && fetchCampaignData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected_date?.start_date, selected_date?.end_date, selected_user, user_usage?.list, queryParams?.offset, queryParams?.sort?.columnName, queryParams?.sort?.order]
  );

  return (
    <div className='shadow-sm border-2 border-gray-100 rounded-md '>
      <div className=' flex justify-between bg-white items-center px-5'>
        <div className='h-16 bg-white flex gap-2 items-center font-medium rounded-md select-none'>
          <h5>Usage user</h5><CustomBadge content={`${user_usage?.user_count} user${user_usage?.user_count > 1 ? 's' : ''}`} bgColor='buttonBackground' color={''} extra='h-5 !font-semibold text-[.625rem] text-navActive !px-2 !py-0.5' />
        </div>
        <Button loading={exportLoading} disable={exportLoading || loading} name={`Export ${selectedList?.length ? `(${selectedList?.length})` : "all"}`} customClass={`bg-white ${!loading && '!text-gray-800'} border-2 border-gray-100  !px-2 !py-1 !font-medium`} onClick={handleExportBtn} />
      </div>
      <div>
        {loading ? (
          <TableLoader
            width="w-full"
            border="border border-gray-100 dark:border-darkBorder"
            column={6}
            row={8}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50 dark:bg-darkOne' }}
            columnWidth="4% 18% 18% 18% 18% 22%"
          />
        ) : (
          <div className="max-h-[65vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}
                    className='sticky z-10 top-0'
                  // onClick={() => onSelect('dfghj')}
                  >
                    {headerGroup.headers.map((header, index, arr) => {
                      const width = ['w-[4%]', 'w-[18%]', 'w-[18%]', 'w-[18%]', 'w-[17%]', 'w-[23%]']
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`${width[index]} truncate mb-2 px-0 cursor-pointer border-gray-200 dark:border-darkThree text-[#667085] dark:text-white bg-grayBorder dark:bg-darkOne h-12 text-start`}
                        >
                          <hr />
                          <div className="text-xs size-full">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          <hr />
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px] dark:divide-darkOne">
                {user_usage?.list?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className="cursor-pointer select-none hover:bg-hover dark:hover:bg-darkTwo bg-white" onClick={() => row?.toggleSelected()}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={`border-white/0 py-3 text-start `}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              customClassName='!bg-white'
              loadMore={creditsFetch}
              loader={
                <TableLoader
                  width="w-full"
                  border="border border-gray-100"
                  column={10}
                  row={1}
                  skeletonHeight="h-2"
                  padding="px-5 py-4"
                  columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TableList