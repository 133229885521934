import { FaProjectDiagram } from 'react-icons/fa';

// const statusText = getStatusText('campaign', item?.campaign_status);
// const badgeColor = getStatusBadgeColor('campaign', item?.campaign_status);
const CampaignSequenceSearchData = (props: any) => {
  const { data, handleNavigate } = props;

  return (
    <div>
      {data?.map((item: any) => {
        return (
          <>
            <div className="my-2 cursor-pointer rounded-lg  p-2  hover:bg-lightpurplebg" onClick={() => handleNavigate(item?.url)}>
              <div className=" flex justify-between gap-5">
                <FaProjectDiagram className="self-center  text-heading" size={24} />
                <div className=" flex flex-1 flex-col text-[13px]">
                  <p className="m-0 w-72  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading  ">{item?.version_a_subject}</p>
                  <p
                    className="m-0 w-60  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column "
                    dangerouslySetInnerHTML={{ __html: item?.version_a_message.slice(0, 100).replace(/<br\s*\/?>/gi, '') }}
                  />
                </div>
                {/* <div className="flex flex-col text-[13px]">
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold text-heading ">{item?.variant}</p>
                  <p className="m-0 max-w-40  overflow-hidden truncate text-ellipsis whitespace-nowrap  text-column">{moment(item?.cdate).format('D MMM YYYY')}</p>
                </div> */}
              </div>
            </div>
            <hr className=" w-full" />
          </>
        );
      })}
    </div>
  );
};

export default CampaignSequenceSearchData;
