import { Spinner } from '@chakra-ui/react'
import SparkleCheckBox from 'common/sparkle-common/checkBox'
import { CustomMenu } from 'components/customMenu'
import SearchBox from 'components/searchBox'
import moment from 'moment'
import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { MdOutlineCalendarToday } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from 'store'
import { setSelectedDate, setSelectedUser } from 'store/account-settings'
import DateRangeSelector from 'views/campaigns-insights/analytics/charts/DateRangeSelector'

interface PropsType {
    loading: boolean;
    initialLoading: boolean;
}

const RangePicker = (props: PropsType) => {
    const { initialLoading } = props
    const users = useAppSelector(state => state?.accountSettings?.billing?.usage?.user?.list);
    const selected_user = useAppSelector(state => state?.accountSettings?.billing?.usage?.user?.selected_user);
    const selected_date = useAppSelector(state => state?.accountSettings?.billing?.usage?.selected_date);
    const [search, setSearch] = React.useState('')

    const [showCalendar, setShowCalendar] = React.useState(false)
    const [selectedDate, setDate] = React.useState([new Date(selected_date?.start_date), new Date(selected_date?.end_date)])
    const dispatch = useAppDispatch()
    // const users = [
    //     { name: "Dhanush", selected: true },
    //     { name: "Vignesh", selected: true },
    //     { name: "Palani", selected: false },
    //     { name: "Yogesh", selected: false },
    //     { name: "Vimal", selected: false },
    //     { name: "Gayathri", selected: false },
    // ]
    React.useEffect(() => {
        dispatch(setSelectedDate({ start_date: moment(selectedDate[0]).format('YYYY-MM-DD'), end_date: moment(selectedDate[1]).format('YYYY-MM-DD') }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <div className='w-full flex gap-4'>
            <div className='relative w-[7.1875rem]'>
                <div className={`border-2 ${showCalendar ? "border-menuBorder" : "border-gray-100"} bg-white hover:border-menuBorder rounded-md h-8  px-3 py-3.5 gap-1 flex items-center justify-around shadow-bottom cursor-pointer`} onClick={() => { !initialLoading && setShowCalendar(!showCalendar) }}>
                    <MdOutlineCalendarToday className='text-[#667085] size-4' />
                    <h5 className='text-[0.7rem] text-[#667085] font-medium'>Select dates</h5>
                </div>
                {showCalendar && <DateRangeSelector selectedDate={selectedDate} setSelectedDate={setDate} setShowCalendar={setShowCalendar} right='left-0' top='top-9' />}
            </div>
            <CustomMenu btnContent='Users' btnClassName='h-8 bg-white !justify-between w-20 items-center text-column' menuListClassName='!mt-1 !p-0 min-w-44' rightIcon={<IoIosArrowDown size={14} className='text-column ' />} >
                <div>
                    <SearchBox placeholder='Search users...' onChange={(event) => setSearch(event?.target?.value)} />
                    <div className='max-h-32 overflow-y-auto'>
                        {
                            initialLoading ?
                                <div className='flex justify-center items-center p-2'>
                                    <Spinner className='text-gray-400' />
                                </div>
                                :
                                users?.filter((item) => search ? (item?.first_name + " " + item?.last_name)?.toLowerCase()?.includes(search?.toLowerCase()?.trim()) : true)?.map((user, i) => {
                                    return (
                                        <div key={i} className={`flex select-none gap-1.5 justify-start items-center py-2 px-3 cursor-pointer hover:bg-hover border-b ${i === (users?.length - 1) ? 'rounded-md' : ''}`} onClick={() => dispatch(setSelectedUser(user?.uuid))}>
                                            <SparkleCheckBox checked={selected_user?.includes(user?.uuid)} onChange={() => { }} />
                                            <span className='w-full text-xs font-medium text-heading'>{user?.first_name} {user?.last_name}</span>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </CustomMenu>
        </div>
    )
}

export default RangePicker