import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { GoCheckCircle } from 'react-icons/go';
import { PiArrowBendDownRightThin } from 'react-icons/pi';
import { toast } from 'react-toastify';
import io from 'socket.io-client';

import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { _errorHandler, restrictionPlan, toastState } from 'common/utils/utility';
import ArrowedTooltip from 'components/ArrowedTooltip';
import { useAppDispatch, useAppSelector } from 'store';
import { setHeaderCount, setProspects, setProspectsVerification, setVerificationToProspects } from 'store/campaign-v2';
import { setCurrentStepCount, setTotalStepCount } from 'store/custom';
import { API_BASEURL } from 'common/constants/constants';
import ProspectsList from '../prospect/features/prospect-list';
// import SocketVerify from './SocketVerify'

const ProspectsVerification = () => {
  const socket = io(API_BASEURL.replace('/api/v1', ''), {
    transports: ['websocket'], // Use WebSocket as the transport
    reconnectionAttempts: 5, // Number of reconnection attempts
    reconnectionDelay: 1000, // Delay between attempts
  });
  console.log(socket);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { ids, campaign_name, prospects, prospects_verification } = useAppSelector((state) => state.campaignv2);

  const { modal_view, step_count, verifierData } = prospects_verification;
  const verifierStaticData = [
    { label: 'Valid', originalName: 'valid', color: '#14B8A6' },
    { label: 'Invalid', originalName: 'invalid', color: '#F59E0B' },
    { label: 'Error', originalName: 'error', color: '#F50B0B' },
    { label: 'Unknown', originalName: 'unknown', color: '#FACC15' },
    { label: 'Catch all', originalName: 'catch_all', color: '#6366F1' },
  ];
  const [selectedRow, setSelectedRow] = React.useState<{ [key: string]: boolean }>({
    valid: false,
    in_valid: false,
    error: false,
    unknown: false,
    catch_all: false,
  });
  const [isFetching, setIsfetching] = React.useState(false);
  const [totalSelectedCount, setTotalSelectedCount] = React.useState<number>(0);
  const dispatch = useAppDispatch();

  React.useEffect(() => { }, []);

  // useEffect(
  //   () => {
  //     if (prospects?.prospects_list[0]?.verification_status && (verifierData?.length > 0)) {
  //       dispatch(setProspectsVerification({ modal_view: false }))
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []
  // )

  const alreadySelectAll = verifierData.every((value) => {
    if (value.count) {
      if (selectedRow[value.originalName] === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
  // const alreadySelectAll = Object.values(selectedRow).every((value) => value === true)
  const handleClose = () => {
    if (step_count !== 1 && step_count !== 4) {
      dispatch(setProspectsVerification({ modal_view: false }));
      dispatch(setHeaderCount(4));
      dispatch(setCurrentStepCount(0));
      dispatch(setTotalStepCount(0));
    }
  };
  // Select function
  const handleSelectAll = () => {
    let selectedValue = { valid: false, in_valid: false, error: false, unknown: false, catch_all: false };
    let count = 0;
    if (alreadySelectAll) {
      selectedValue = { valid: false, in_valid: false, error: false, unknown: false, catch_all: false };
    } else {
      count = verifierData.reduce((acc: number, value) => value.count + acc, 0);
      selectedValue = verifierData.reduce((acc, value) => {
        acc[value.originalName] = value.count ? true : false;
        return acc;
      }, {});
      // selectedValue = { valid: true, in_valid: true, error: true, unknown: true, catch_all: true }
    }
    setTotalSelectedCount(count);
    setSelectedRow(selectedValue);
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const { count } = verifierData?.find((value) => value?.originalName === id);
    setTotalSelectedCount((prev) => (checked ? prev + count : prev - count));
    setSelectedRow((prev) => ({ ...prev, [id]: checked }));
  };

  const userType = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);

  const handleEmailVerify = async () => {
    try {
      const restriction = restrictionPlan('email_verify',userType)
      if(!restriction) return;
      setIsfetching(true);
      dispatch(setProspectsVerification({ step_count: 2 }));
      socket.on('prospects_verify_socket', (data) => {
        setData(data?.data);
      });
      socket.on('error', (err) => {
        socket.disconnect();
        console.error('Error happened');
      });
      const response = await BaseApi.post(`/campaign/prospects/email/verify?client_socket_id=${socket.id}`, { campaign_id: ids.campaign_id });
      if (!response.error) {
        const result = response?.data;
        if (result?.campaign_id !== ids?.campaign_id) return;
        const prospects_list = result?.verify_prospects_list?.map(({ email, verification_status, ...item }: { email?: string; verification_status?: string }) => {
          return { email, verification_status, ...item };
        });
        dispatch(setProspects({ prospects_list: prospects_list }));
        const data = verifierStaticData.map((item) => {
          return { ...item, count: result?.verify_result?.[item.originalName] || 0 };
        });
        dispatch(setProspectsVerification({ verifierData: data }));
        dispatch(setProspectsVerification({ step_count: 3 }));
        // socket.off('prospects_verify_socket')
        socket.disconnect();
      } else {
        // socket.off('prospects_verify_socket')
        socket.disconnect();
        toast.error(_errorHandler(response?.message), toastState.error);
        dispatch(setHeaderCount(4));
        dispatch(setProspectsVerification({ step_count: 1 }));
        dispatch(setCurrentStepCount(0));
        dispatch(setTotalStepCount(0));
      }
    } catch (error) {
      console.log('Email verify error', error);
    } finally {
      // socket.off('prospects_verify_socket')
      socket.disconnect();
      setIsfetching(false);
    }
  };

  const handleTrueVerifier = async () => {
    if (totalSelectedCount === 0) {
      return toast.error('Please select at least one item.', toastState.error);
    }
    dispatch(setProspectsVerification({ step_count: 6 }));
  };

  const addCampaign = async () => {
    let response;
    try {
      setIsfetching(true);
      const campaign_prospects_list = Object.entries(selectedRow)
        .filter((item) => item[1] === true)
        .map((item) => item[0]);
      const { campaign_id, prospects_id } = ids;
      const params = {
        campaign_name: campaign_name,
        ...(campaign_id && { campaign_id }),
        verification_data: {
          ...(prospects_id && { prospects_id }),
          campaign_prospects_list,
        },
      };
      response = await BaseApi.post('/campaign/prospects/add/verified/emails', params);
      if (!response.error) {
        setIsfetching(true);
        dispatch(setHeaderCount(4));
        dispatch(setCurrentStepCount(0));
        dispatch(setTotalStepCount(0));
        dispatch(setProspectsVerification({ step_count: 4 }))
        toast.success(response?.message, toastState.success);
      } else {
        toast.error(_errorHandler(response?.message), toastState?.error);
      }
    } catch (error) {
      console.log('Add campaign error');
    } finally {
      setIsfetching(false);
    }
  };

  // const handleErrorConfirm = async () => {
  //   try {
  //     setIsfetching(true)
  //   } catch (error) {
  //     console.log('Email verify error', error)
  //   } finally {
  //     setIsfetching(false)
  //     dispatch(setHeaderCount(4))
  //     dispatch(setCurrentStepCount(0))
  //     dispatch(setTotalStepCount(0))
  //   }
  // }

  const handleAddCampaign = async () => {
    if (totalSelectedCount === 0) {
      return toast.error('Please select at least one item.', toastState.error);
    }
    if (selectedRow.error || selectedRow.in_valid || selectedRow.unknown || selectedRow.catch_all) {
      return dispatch(setProspectsVerification({ step_count: 5 }));
    }
    addCampaign();
  };

  const handleCreateTicket = async () => {
    try {
      setIsfetching(true);
      const campaign_prospects_list = Object.entries(selectedRow)
        .filter((item) => item[1] === true)
        .map((item) => item[0]);
      const params = {
        campaign_id: ids.campaign_id,
        prospects_id: ids.prospects_id,
        campaign_prospects_list,
      };
      const response = await BaseApi.post('/campaign/v2/true/verify/raise/ticket', params);
      if (!response.error) {
        toast.success('Ticket has been created successfully', toastState.success);
        dispatch(setProspectsVerification({ step_count: 4 }));
      } else {
        toast.error(_errorHandler(response?.message), toastState.error);
      }
    } catch (error) {
      console.log('Email verify error', error);
    } finally {
      setIsfetching(false);
    }
  };

  const errorCount = verifierData?.reduce((acc: number, value) => {
    if (value.originalName !== 'valid' && selectedRow[value.originalName]) {
      acc = acc + value?.count;
    }
    return acc;
  }, 0);

  const handleSkipVerify = async () => {
    try {
      setLoading(true);
      const response = await BaseApi.post('/campaign/prospects/skip/verification', { campaign_id: ids?.campaign_id });
      if (!response.error) {
        toast.success('Email verification was skipped', toastState.success);
      } else {
        toast.error(_errorHandler(response?.message), toastState?.error);
      }
    } catch (error) {
      console.log('Email verify error', error);
    } finally {
      setLoading(false);
    }
    dispatch(setHeaderCount(4));
    dispatch(setProspectsVerification({ step_count: 1 }));
    dispatch(setCurrentStepCount(0));
    dispatch(setTotalStepCount(0));
  };
  return (
    <div>
      {/* <VerifiedTable /> */}
      <ProspectsList />
      {step_count !== 3 && (!('verification_status' in prospects?.prospects_list[0]) || step_count === 5 || step_count === 4 || step_count === 6) && (
        <Modal isOpen={modal_view} size={'auto'} onClose={handleClose} isCentered={true}>
          <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
          <ModalContent className="!w-fit p-2">
            {step_count !== 1 && step_count !== 4 && step_count !== 2 && <ModalCloseButton onClick={handleClose} className="z-10 text-gray-700" top="22px" right="12px" />}
            <ModalBody className="!relative !rounded-md  !p-2 ">
              {/* Initial view */}
              {step_count === 1 && (
                <div>
                  <div className="flex items-center gap-2">
                    <div className="rounded-full bg-completed-bg p-[.375rem]">
                      <div className="rounded-full bg-bgGreen p-[.375rem] text-completed-color">
                        <GoCheckCircle size={19} />
                      </div>
                    </div>
                    <h4 className="font-semibold">Email verification</h4>
                  </div>
                  <p className="my-4 w-72 text-wrap text-[15px] text-column">Please verify your prospects' email addresses to continue the campaign. This may take a few minutes</p>
                  <div className="flex w-full flex-col gap-2 pt-2">
                    <Button
                      name="Continue"
                      customClass="w-full !text-[.8125rem]"
                      id="email_verification_modal_continue_btn"
                      onClick={handleEmailVerify}
                      // onClick={()=> dispatch(setProspectsVerification({ step_count: 2 }))}
                      loading={isFetching}
                      disable={isFetching}
                    />
                    <Button
                      name="Skip"
                      customClass="w-full !text-[.8125rem] font-medium"
                      bg="bg-white"
                      color="text-heading"
                      loading={loading}
                      disable={loading}
                      id="skip_btn"
                      onClick={handleSkipVerify}
                    />
                  </div>
                </div>
              )}
              {
                step_count === 2 && (
                  <div>
                    <div className="mb-3 flex items-center gap-2">
                      <div className="rounded-full bg-completed-bg p-[.375rem]">
                        <div className="rounded-full bg-bgGreen p-[.375rem] text-completed-color">
                          <GoCheckCircle size={19} />
                        </div>
                      </div>
                      <h4 className="font-semibold">Email verification</h4>
                    </div>
                    <p className="w-72 text-wrap text-[15px] text-column">Email verification in progress. Please wait.</p>
                    <h5 className="mt-2 text-right text-[0.65rem] text-[#344054]">
                      {data?.verify_prospects_count || 0}/{prospects?.prospects_list?.length} emails verified
                    </h5>
                    <div className="mt-1 h-1.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                      <div style={{ width: `${(data?.verify_prospects_count / data?.total_prospects_count) * 100}%` }} className={`h-1.5 w-0 rounded-full bg-green-400`}></div>
                    </div>
                    <Button
                      name="Skip"
                      customClass="w-full mt-3 !text-[.8125rem] font-medium"
                      py="py-1.5"
                      bg="bg-white"
                      color="text-heading"
                      id="email_verification_modal_skip_btn"
                      onClick={() => {
                        handleSkipVerify();
                        dispatch(setVerificationToProspects());
                      }}
                    />
                  </div>
                )
                // <SocketVerify />
              }
              {/* Select emails popup view */}
              {step_count === 4 && (
                <div className="w-[530px]">
                  <div className="flex w-[90%] items-center justify-between">
                    <div>
                      <h5 className="text-[1rem] font-bold text-heading">Email verification</h5>
                      <p className="text-sm font-thin text-gray-600">Select a status to continue</p>
                    </div>
                    <div>
                      <p className=" rounded-full bg-[#F2F4F7] px-2 py-1 text-[.6875rem] font-medium text-[#344054]">{totalSelectedCount} emails selected </p>
                    </div>
                  </div>

                  <div className="my-3">
                    <div className="flex items-center border-b border-graybg bg-[#F9FAFB] px-3 py-4 text-xs text-column">
                      <div className="ml-6 flex w-full items-center gap-3">
                        <input
                          id="select-all-input"
                          type="checkbox"
                          className="h-[.9375rem] w-[.9375rem] cursor-pointer border border-red-500 accent-brand-500 outline-none dark:accent-purple-600"
                          onChange={handleSelectAll}
                          checked={alreadySelectAll}
                        />
                        <span className="cursor-default">Status</span>
                      </div>
                      <h5 className="w-[80%] cursor-default">Number of Emails</h5>
                    </div>
                    {verifierData?.map((item) => {
                      return (
                        <div key={item?.originalName} className="flex items-center border-b border-graybg px-3 py-4 text-sm font-bold text-heading">
                          <div className="flex w-full items-center gap-3">
                            <span className="h-3 w-3 rounded-full border" style={{ background: item?.color }}></span>
                            <input
                              type="checkbox"
                              id={item.originalName}
                              disabled={item?.count === 0}
                              className={`h-[.9375rem] w-[.9375rem] border border-red-500 accent-brand-500 outline-none dark:accent-purple-600 ${item?.count === 0 ? '' : 'cursor-pointer'
                                }`}
                              onChange={handleSelect}
                              checked={selectedRow[item?.originalName] || false}
                            />
                            <span className="cursor-default">{item?.label}</span>
                          </div>
                          <h5 className="w-[73%] cursor-default">{item?.count}</h5>
                        </div>
                      );
                    })}
                  </div>
                  <div className="relative flex gap-2">
                    <Button
                      id="add-campaign-btn"
                      name="Add to Campaign"
                      customClass={`w-full duration-300 !text-[.8125rem] ${isFetching ? '' : '!bg-white !text-gray-800 !font-bold'}`}
                      onClick={handleAddCampaign}
                      loading={isFetching}
                      disable={isFetching}
                    />
                    <Button
                      id="start-verification-btn"
                      name="Start True Verification"
                      customClass="w-full duration-300 hover:bg-purple-700 !text-[.8125rem]"
                      onClick={handleTrueVerifier}
                    />
                    <div className="absolute -top-12 right-12">
                      <div className=" text-heading">
                        <div className="relative ml-1 flex items-center gap-1 text-xs font-bold">
                          <span className="text-lightgreen">Recommended</span>
                          <div className="group">
                            <AiOutlineInfoCircle className="text-gray-600" size={12} />
                            <ArrowedTooltip
                              data={`What is true verification? True verification confirms whether an email address exists and can receive messages or not. It will not affect your campaign.`}
                              position="bottom"
                              className="!-top-20 !left-0 hidden w-48 group-hover:block"
                              contentclass="!text-[.5625rem] !font-normal"
                            />
                          </div>
                        </div>
                        <div className="absolute -left-10 -top-[.125rem] w-9 rotate-12 overflow-hidden">
                          <PiArrowBendDownRightThin size={50} className=" rotate-90 text-lightgreen" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Error emails selected popup view */}
              {step_count === 5 && (
                <div>
                  <div className="flex items-center gap-2">
                    <div className="rounded-full bg-draft-bg p-[.375rem]">
                      <div className="rounded-full bg-warningbg p-2 text-warningtext">
                        <FiAlertTriangle size={19} />
                      </div>
                    </div>
                    <h4 className="font-semibold">Email verification</h4>
                  </div>
                  <p className="my-4 w-72 text-wrap text-[15px] text-column">You have selected {errorCount} invalid email addresses. Do you want to continue?</p>
                  <div className="flex w-full gap-2 pt-2">
                    <Button
                      id="back-btn"
                      name="Back"
                      customClass="w-full !bg-white !text-gray-800 !font-bold duration-300 !text-[.8125rem] !h-8"
                      onClick={() => dispatch(setProspectsVerification({ step_count: 4 }))}
                    />
                    <Button
                      name="Continue"
                      id="continue_id_btn"
                      customClass="w-full !text-[.8125rem] hover:bg-purple-700 duration-300 !h-8"
                      loading={isFetching}
                      disable={isFetching}
                      onClick={addCampaign}
                    />
                  </div>
                </div>
              )}

              {/* True verifier popup view */}
              {step_count === 6 && (
                <div>
                  <div className="flex items-center gap-2">
                    <div className="rounded-full bg-completed-bg p-[.375rem]">
                      <div className="rounded-full bg-bgGreen p-[.375rem] text-completed-color">
                        <GoCheckCircle size={19} />
                      </div>
                    </div>
                    <h4 className="font-semibold">True verification</h4>
                  </div>
                  <p className="my-4 w-72 text-wrap text-[15px] text-column">
                    True verification will take upto 24 hours. All verified emails will be added to the campaign automatically. You can check the status in the review tab.
                  </p>
                  <div className="flex w-full gap-2 pt-2">
                    <Button
                      id="back-btn"
                      name="Back"
                      customClass="w-full !bg-white !text-gray-800 !font-bold duration-300 !text-[.8125rem]"
                      onClick={() => {
                        dispatch(setProspectsVerification({ step_count: 4 }));
                      }}
                    />
                    <Button
                      name="Continue"
                      id='true-verification-confirm-button'
                      customClass="w-full !text-[.8125rem] hover:bg-purple-700 duration-300"
                      loading={isFetching}
                      disable={isFetching}
                      onClick={handleCreateTicket}
                    />
                  </div>
                </div>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default ProspectsVerification;
