/* eslint-disable no-useless-escape */
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Input from 'common/sparkle-common/Input';
import React from 'react';
// import CustomDropdown from './CustomDropdown';
import Button from 'common/sparkle-common/Button';
import { ModalComponentProp } from 'common/dto/types/views/blacklist';
import { FiUploadCloud } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';
// import { teamsWorkspaceEdit } from 'common/dto/initial-value/store/organization';
import { resetTeamsWorkspaceEditState } from 'store/organization';

function AddWorkspaceModal(props: ModalComponentProp) {
  const { isOpen, onClose } = props;
  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [base64, setBase64] = React.useState('');
  const fileInputRef = React.useRef(null); // Reference to the file input for resetting
  const [fileName, setFileName] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedData, setSelectedData] = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpenstate, setIsOpenState] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [option, setOption] = React.useState([]);

  const { teamsWorkspaceEdit } = useAppSelector((state) => state.organization);
  const [payloadData, setPayloadData] = React.useState(teamsWorkspaceEdit);
  const [loading, setLoading] = React.useState(false);

  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();

  // console.log('teamsWorkspaceEdit', teamsWorkspaceEdit);

  // const convertToBase64 = (file: any) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);

  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = event.target;
    let base64String;

    if (name === 'logo') {
      setFileName('Uploading...');
      console.log(files);
      const formData = new FormData();
      formData.append('documents', files[0]);
      const uploadImg = await BaseApi.post('/helper/store/az/bucket', formData);
      setFileName(files[0].name);

      base64String = uploadImg?.data?.url;
      // base64String = await convertToBase64(files[0]);
    }

    setPayloadData({
      ...payloadData,
      [name]: name === 'logo' ? base64String : name === 'credit' ? +value : value,
    });
  };

  // console.log('payload', payloadData);

  // const handleFileChange = async (event: any) => {
  //   const file = event.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     const base64String = await convertToBase64(file);
  //     setBase64(base64String as string);
  //     setFileName(file.name);
  //     setPayloadData({ ...payloadData, logo: base64String as string });
  //   }
  // };

  const handleRemove = () => {
    setBase64(''); // Clear the Base64 string
    setFileName('');
    setPayloadData({ ...payloadData, logo: '' });
    fileInputRef.current.value = ''; // Reset the file input
  };

  const handleSelectionChange = (selectedOptions: any) => {
    setSelectedData(selectedOptions.map((element: { uuid: string }) => element.uuid));
  };

  // console.log('SELECTED DATA', selectedData);

  const handleClose = () => {
    dispatch(resetTeamsWorkspaceEditState());
    dispatch(getErrorMessage({}));

    onClose();
  };
  const fetchData = async () => {
    try {
      const response = await BaseApi.get('/user/role/management');
      // console.log('first res', response);
      setOption(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  // const disable = payloadData?.name && payloadData?.credits ? false : true;

  const handleSubmitPayload = async () => {
    if (teamsWorkspaceEdit?.editState) {
      try {
        setLoading(true);
        // setPayloadData({ ...payloadData, users: selectedData });
        const workspaceData = {
          ...(payloadData?.description && {
            description: payloadData?.description,
          }),
          ...(payloadData?.plan && { plan: payloadData?.plan }),
          ...(payloadData?.logo && { logo: payloadData?.logo }),
          ...(payloadData?.name && { name: payloadData?.name }),
          // ...(payloadData?.credits && { credits: payloadData?.credits }),
          ...(payloadData?.uuid && { uuid: payloadData?.uuid }),
        };

        // console.log('workspaceData==============================', workspaceData);
        const apiresponse = await BaseApi.patch('/workspace', workspaceData);
        // console.log(apiresponse);
        if (!apiresponse.error) {
          toast.success(`${apiresponse.message}`, toastState.success);
          dispatch(getErrorMessage({}));

          dispatch(resetTeamsWorkspaceEditState());

          dispatch(setFlagStatus(!custom?.flagStatus));
          handleRemove();
          onClose();
        } else {
          dispatch(getErrorMessage(apiresponse?.message));

          // toast.error(`${apiresponse.message}`, toastState.error);
          dispatch(resetTeamsWorkspaceEditState());

          handleRemove();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        // setPayloadData({ ...payloadData, users: selectedData });
        const workspaceData = {
          ...(payloadData?.description && {
            description: payloadData?.description,
          }),
          ...(payloadData?.plan && { plan: payloadData?.plan }),
          ...(payloadData?.logo && { logo: payloadData?.logo }),
          ...(payloadData?.name && { name: payloadData?.name }),
          // ...(payloadData?.credits && { credits: payloadData?.credits }),
        };
        const apiresponse = await BaseApi.post('/workspace', workspaceData);
        console.log(apiresponse);
        if (!apiresponse.error) {
          toast.success(`${apiresponse.message}`, toastState.success);
          dispatch(resetTeamsWorkspaceEditState());
          dispatch(getErrorMessage({}));

          dispatch(setFlagStatus(!custom?.flagStatus));
          handleRemove();
          onClose();
        } else {
          dispatch(getErrorMessage(apiresponse?.message));

          // toast.error(`${apiresponse.message}`, toastState.error);
          dispatch(resetTeamsWorkspaceEditState());

          handleRemove();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size="2xl">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className=" dark:bg-darkOne">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkTwo">
            <div className="flex items-center  gap-3">
              <h5 className="text-lg font-semibold text-heading dark:text-white">{payloadData?.editState ? 'Edit Workspace' : 'Add Workspace'}</h5>
            </div>
          </ModalHeader>
          <ModalBody className="!p-3">
            <div className=" " id="user-management-add-modal">
              <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2 dark:bg-darkTwo">
                <p className="ml-2 text-[13px]  !font-semibold text-[#1B2559] dark:text-white">Workspace Name <span className="text-red-500">*</span></p>
                <Input
                  name="name"
                  value={payloadData?.name}
                  placeholder="Workspace Name"
                  customClass={` w-full text-[19px] add-gruop-name-blacklist workspace-name-input dark:bg-darkOne dark:text-white  ${
                    errorMessage?.name ? `border-red-500 dark:!border-red-500` : `dark:border-none`
                  }`}
                  onChange={handleChange}
                />
                {errorMessage?.name && <span className="ml-1 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.name}</span>}
              </div>

              {/* <div className="!mt-1 mb-3 flex flex-col rounded-md bg-[#FAFAFC] px-2 py-2">
                <p className="ml-2 text-[13px] font-semibold text-gray-900">
                  Users
                </p>
                <CustomDropdown
                  // isOpenstate={isOpenstate}
                  options={option}
                  // mapvariable=
                  placeholderName={'Select Users'}
                  onSelectionChange={handleSelectionChange}
                  // valueField="id" // Specify the field in data objects to use as the value
                  // labelField="label" // Specify the field in data objects to use as the label
                />
              </div> */}

              <div className="boreder !mt-1 mb-3 flex flex-col rounded-md  bg-[#FAFAFC] px-2 py-2 dark:bg-darkTwo">
                <p className="ml-2 text-[13px] font-semibold text-gray-900 dark:!text-white">Upload Logo</p>
                {/* <div className="flex flex-row">
                  <p className="add-gruop-name-blacklist w-full border border-red-500 text-[19px]">
                    {fileName}
                    <button
                      onClick={handleRemove}
                      style={{ display: 'block', marginTop: '10px' }}
                    >
                      Remove Image
                    </button>
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className=""
                    ref={fileInputRef} // Connect the ref to the input element
                  />
                </div> */}
                <div className="flex w-full flex-row items-center  ">
                  <p
                    className="flex h-9 w-11/12 items-center justify-between  rounded-md border bg-white px-2 py-2 !text-sm dark:border-darkBorder dark:bg-darkOne"
                    onClick={fileName ? null : () => fileInputRef.current.click()}
                  >
                    <span className="w-3/4 truncate dark:text-white ">{fileName || payloadData?.logo?.match(/[^\/]+$/)}</span>
                    {fileName || payloadData?.logo ? <FaTrash onClick={handleRemove} size={15} className="ml-2 text-red-500" /> : null}
                  </p>

                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="ml-2 items-center justify-center rounded-full border border-[#dfd8fc] bg-[#e8e3fc] p-2 text-blue-500 hover:text-blue-700"
                  >
                    <FiUploadCloud className="" size={20} />
                  </button>
                  <input
                    name="logo"
                    type="file"
                    accept="image/*,image/heic"
                    onChange={handleChange}
                    className="hidden "
                    ref={fileInputRef} // Connect the ref to the input element
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="!-mt-2 !mb-5">
            <Button
              id="cancel-workspace-management"
              name="Cancel"
              customClass="!bg-[#F4F7FE] w-full !text-[#A3AED0] border border-2 dark:!text-blackDark  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
              onClick={() => {
                handleRemove();
                handleSelectionChange([]);
                setSelectedData([]);
                setIsOpenState(false);
                handleClose();
              }}
            />
            <Button
              id="save-workspace-management"
              disable={loading}
              loading={loading}
              name={`${payloadData?.editState ? 'Update' : 'Save'}`}
              customClass="w-full !text-[#FFFFFF] !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem] dark:border-none"
              onClick={() => handleSubmitPayload()}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AddWorkspaceModal;
