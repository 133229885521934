import { PropType } from 'common/dto/types/crm';

export const TextInput = ({ id, label, onChange, name, value, placeholder }: PropType) => (
  <div className="p-1">
    <label htmlFor={id} className={`text-xs font-medium text-heading dark:text-white`}>
      {label}
    </label>
    <input
      autoComplete="off"
      onChange={onChange}
      // onKeyDown={onKeyDown}
      // disabled={disabled}
      type="text"
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      className="mt-0.5 flex h-8 w-full items-center justify-center rounded-md border bg-white/0 px-2 py-1 text-[.625rem] outline-none"
    />
  </div>
);
