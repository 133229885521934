import { IoMdArchive } from 'react-icons/io'
import { MdCloudDownload, MdPauseCircle } from 'react-icons/md'
import { useAppSelector } from 'store';

interface GlobalOptProps {
  handleOptions: (action: string, uuid ?:string) => void;
}

const GlobalOpt = (props: GlobalOptProps) => {
  const { handleOptions } = props;
  const custom = useAppSelector(state => state?.custom)
  return (
    <div id='globalOpt' className='flex bg-blackDark dark:bg-purple-900 dark:border dark:border-darkBorder rounded-md absolute bottom-8 left-1/2 -translate-x-1/2'>
      <div className='flex px-4 py-3.5 select-none gap-2 items-center text-white rounded-s-md transition-all cursor-default'>
        <h5 className="text-sm">{custom?.tableRowsList?.length} Selected</h5>
      </div>
      <div className='flex px-4 py-3.5 gap-2 items-center text-white hover:bg-brand-500 dark:hover:bg-purple-600 transition-all cursor-pointer' onClick={() => handleOptions('pause')}>
        <MdPauseCircle />
        <h5 className="text-sm">Pause</h5>
      </div>
      <div className='flex px-4 py-3.5 gap-2 items-center text-white hover:bg-brand-500 dark:hover:bg-purple-600 transition-all cursor-pointer' onClick={() => handleOptions('reject')}>
        <IoMdArchive />
        <h5 className="text-sm">Archive</h5>
      </div>
      <div className='flex px-4 py-3.5 gap-2 items-center text-white hover:bg-brand-500 dark:hover:bg-purple-600 rounded-e-md transition-all cursor-pointer' onClick={() => handleOptions('download')}>
        <MdCloudDownload />
        <h5 className="text-sm">Download</h5>
      </div>
    </div>
  )
}

export default GlobalOpt