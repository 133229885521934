import Card from 'components/card'
import React from 'react'
import RevenueChart from './RevenueChart'

const EmailWarmup = () => {
  return (
    <Card extra={"w-full px-8 py-8"}>
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        Email Warm-up
      </h4>
      <p className="mt-1 text-base text-gray-600">
        At dui risus quisque morbi ut vestibulum augue tincidunt. Amet.
      </p>
      <div>
        <RevenueChart />
        <div>

        </div>
      </div>
    </Card>
  )
}

export default EmailWarmup