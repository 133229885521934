import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdOutlineCloudDownload, MdDelete } from 'react-icons/md';
import { BaseApi } from 'common/api/common/base-api';
import * as XLSX from 'xlsx';
import React from 'react';
import { useAppSelector } from 'store';
import { checkRolePermissionStatus } from 'common/utils/utility';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  fetchList?: () => void;
  setLoad?: any;
  handleListDelete?: (uuid: Array<string>) => Promise<void>;
  count: number;
}

// interface EmailValidationResult {
//   isValidSyntax: boolean;
//   isTempEmail: boolean;
//   isRoleBasedAccount: boolean;
//   isValidDomain: boolean;
//   mxRecord: string;
//   isCatchAllDomain: boolean;
//   isValidSmtp: boolean;
//   isGreyListed: boolean;
//   isValidEmail: boolean;
//   isTarpitting: boolean;
// }
interface ListDataType {
  email: string;
  verify_details: any;
}

const EmailRowSettings = (props: RowData) => {
  const { uuidRow, handleListDelete, id, count } = props;
  const [showOptions, setShowOptions] = useState(false);
  const custom = useAppSelector((state) => state?.custom);
  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'Email Verifier');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const fetchData = async (listId: string) => {
    if (listId) {
      try {
        const response = await BaseApi.get(`/email/verify/list/${listId}?limit=${count}&sort=_id%asc`);
        return response?.data?.data;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  };

  const handleDownload = async (id: string) => {
    const listData = await fetchData(id);
    const report = listData?.map((item: ListDataType) => ({
      email: item?.email,
      result: item?.verify_details?.status?.result,
      ...item?.verify_details?.multi_status,
    }));

    const formatHeaders = (obj: any) => {
      const formattedObj: any = {};
      Object?.keys(obj)?.forEach((key) => {
        // Convert camelCase or snake_case to words with spaces
        const formattedKey = key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/_/g, ' ');
        formattedObj[formattedKey] = obj[key];
      });
      return formattedObj;
    };

    // Format each row in the report
    const formattedReport = report?.map((row: any) => formatHeaders(row));
    const wb = XLSX?.utils?.book_new();
    const ws = XLSX?.utils?.json_to_sheet(formattedReport);
    XLSX.utils?.book_append_sheet(wb, ws, 'Email Verification');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'email_verification_report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const handleOptions = (selectedOpt: string, uuidSingle?: string) => {
    if (selectedOpt === 'download') {
      handleDownload(uuidSingle);
    }
    setShowOptions(false);
    if (selectedOpt === 'delete') {
      handleListDelete([uuidSingle]);
    }
  };

  return (
    <>
      <div className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`} tabIndex={0} onBlur={() => setShowOptions(false)}>
        <IoMdMore className="z-50 text-lg text-gray-700" id={`edit-btn-${id}`} onClick={() => setShowOptions(true)} />
        {showOptions && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:border-darkBorder dark:bg-darkTwo">
            {/* Role Restriction for the Email Verifier Export */}
            {(roleStatus?.is_owner || roleStatus?.download_or_export) && (
              <div
                className="campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                onClick={(e) => handleOptions('download', uuidRow)}
              >
                <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />
                <h5 className="text-xs">Download</h5>
              </div>
            )}
            {/* Role Restriction for the Email Verifier Delete */}

            {(roleStatus?.is_owner || roleStatus?.archieve_or_delete) && (
              <div
                className="campaign-pause-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
                onClick={() => handleOptions('delete', uuidRow)}
                id={`delete-${id}`}
              >
                <MdDelete className="text-brand-500 group-hover:text-white" />
                <h5 className="text-xs">Delete</h5>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EmailRowSettings;
