import { Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import React from 'react';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store';
import { setCurrentStepCount, setTotalStepCount } from 'store/custom';
import CampaignHeader from './header';
import Prospects from './prospect';
import ProspectsVerification from './prospects-verification';
import SequencePage from './sequence';
import CampaignSettings from './settings';
import CampaignReview from './review';
import StepNav from 'common/sparkle-common/StepNav';
import { clearCampaignV2, setCampaignV2, setEditLoading, setHeaderCount, setIds, setProspectsVerification } from 'store/campaign-v2';
import { campaignStepsCount } from 'common/dto/initial-value/views/campaign-v2';
import { BaseApi } from 'common/api/common/base-api';
import BrandInputData from './setup';
import { _errorHandler, toastState } from 'common/utils/utility';
import { settingParamsType } from 'common/dto/types/views/campaignv2';
import { LocalStorage } from 'common/api/common/storage';

const CampaignCreatePage = () => {
  const campaignv2 = useAppSelector((state) => state?.campaignv2);
  const custom = useAppSelector((state) => state?.custom);
  const [brandList, setBrandList] = React.useState(null);
  const dispatch = useAppDispatch();

  const getBrandList = async () => {
    try {
      const response = await BaseApi.get('/brand');
      if (!response.error) {
        setBrandList(response?.data?.data || []);
      }
      else {
        console.error(response?.error)
      }
    } catch (error) {
      console.log('Getting brand list error', error);
    }
  };

  const handleEdit = async () => {
    try {
      const uuid = LocalStorage.getItem('edit_id')
      if (!uuid) return
      dispatch(setEditLoading(true));
      const response = await BaseApi.get(`/campaign/v2/list/${uuid}`);
      dispatch(setCampaignV2(response?.data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setEditLoading(false));
    }
  }

  React.useEffect(() => {
    handleEdit()
    getBrandList();

    return () => {
      dispatch(clearCampaignV2())
      LocalStorage.clearItem('edit_id')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStepNav = (count: number) => {
    const stepsCount = campaignStepsCount;
    if (count && campaignv2.header_count < 6) {
      const totalCount = stepsCount[campaignv2.header_count];
      const header_count = campaignv2.header_count + 1;
      dispatch(setHeaderCount(header_count));
      if (header_count === 3) {
        dispatch(setProspectsVerification({ modal_view: true }));
        dispatch(setProspectsVerification({ step_count: 1 }));
      }
      dispatch(setCurrentStepCount(1));
      dispatch(setTotalStepCount(totalCount));
    } else if (!count && campaignv2.header_count > 1) {
      const totalCount = stepsCount[campaignv2.header_count - 2];
      dispatch(setHeaderCount(campaignv2.header_count - 1));
      if (campaignv2.header_count - 1 === 3) {
        dispatch(setProspectsVerification({ modal_view: true }));
        dispatch(setProspectsVerification({ step_count: 1 }));
      }
      dispatch(setCurrentStepCount(totalCount));
      dispatch(setTotalStepCount(totalCount));
    }
  };

  const updateSettingsDetails = async (params: settingParamsType) => {
    const { campaign_id, settings_id } = campaignv2?.ids;
    let response;
    try {
      const commonParams = {
        ...(campaign_id && { campaign_id }),
        campaign_name: campaignv2?.campaign_name,
        variant: 'settings',
        settings_data: {
          ...(settings_id && { settings_id }),
          ...params,
        },
      };
      response = await BaseApi.post('/campaign/v2/create', commonParams);
      if (!response.error && response?.data?.settings_id) {
        console.log(response);
        dispatch(setIds({ settings_id: response?.data?.settings_id }));
      }
      return response?.error;
    } catch (error) {
      toast.error(_errorHandler(response?.message), toastState.error)
      console.log('Updating campaign settings error', error);
      return true;
    }
  };

  const handleCreateCampaign = async () => {
    let error = false;
    if (campaignv2?.header_count === 5) {
      if (custom.current_step_count === 1) {
        const senderList = Object.entries(campaignv2?.settings.selectedSenderList)
          .map((item) => (item[1] ? item[0] : null))
          .filter(Boolean);
        const groupList = Object.entries(campaignv2?.settings.selectedGroupList)
          .map((item) => (item[1] ? item[0] : null))
          .filter(Boolean);
        if (senderList.length || groupList?.length) {
          const params = {
            sender_list: {
              email_accounts: {
                ...(senderList?.length > 0 && { list: senderList }),
                ...(groupList?.length > 0 && { group_list: groupList }),
              },
            },
          };
          error = await updateSettingsDetails(params);
        } else {
          error = true;
        }
      } else if (custom.current_step_count === 2) {
        const { isCampaignStartDateSelected, isCampaignEndDateSelected, skip_holidays, sending_priority } = campaignv2?.settings;
        // const campaign_start_date = moment(scheduleSettings.campaign_start_date).format('yyyy-MM-DD')
        // const campaign_end_date = moment(scheduleSettings.campaign_end_date).format('yyyy-MM-DD')
        if (campaignv2?.scheduleSettings.schedule_id) {
          const params = {
            ...(isCampaignStartDateSelected && { campaign_start_date: campaignv2?.scheduleSettings?.campaign_start_date }),
            ...(isCampaignEndDateSelected && { campaign_end_date: campaignv2?.scheduleSettings?.campaign_end_date }),
            schedule_id: campaignv2?.scheduleSettings.schedule_id,
            skip_holidays: {
              status: skip_holidays?.status,
              ...(skip_holidays?.status && { country: skip_holidays?.country }),
            },
            sending_priority,
            stop_reply_to: campaignv2?.stop_reply_to,
            tracking_options: campaignv2?.tracking_options,
          };
          error = await updateSettingsDetails(params);
        } else {
          error = true;
        }
      } else if (custom.current_step_count === 3) {
        const unsubscribe_rotation = Object.entries(campaignv2?.finalSettings.selectedUnsubscribeLinks)
          .map((item) => (item[1] ? item[0] : null))
          .filter(Boolean);
        const signature_list = Object.entries(campaignv2?.finalSettings.selectedSignature)
          .map((item, index: number) => (item[1] ? campaignv2?.finalSettings?.unsubscribeAndSignatureData?.signature_list[index] : null))
          .filter(Boolean);
        const params = {
          ...(unsubscribe_rotation?.length > 0 && { unsubscribe_rotation }),
          signature_rotation: {
            signature_type: campaignv2?.settings.signature_type,
            ...(signature_list?.length > 0 && { signature_list }),
          },
          remove_watermark_signature: campaignv2?.finalSettings.remove_watermark_signature,
        };
        error = await updateSettingsDetails(params);
      }
    }
    return error;
  };

  return (
    <Modal onClose={() => { }} size={'full'} isOpen={true}>
      <ModalContent className="!bg-grayBorder dark:!bg-darkOne">
        <CampaignHeader brandList={brandList} />
        <div className="overflow-auto">
          <div className="relative">
            <ModalBody className="mx-3 mb-3 h-[83vh] overflow-y-auto rounded-md border-graybg bg-modelBg dark:bg-darkTwo !p-0">

              {campaignv2?.header_count === 1 && <BrandInputData brandList={brandList} />}

              {campaignv2?.header_count === 2 && <Prospects />}

              {campaignv2?.header_count === 3 && <ProspectsVerification />}

              {campaignv2?.header_count === 4 && <SequencePage access={true} />}

              {campaignv2?.header_count === 5 && <CampaignSettings />}

              {campaignv2?.header_count === 6 && <CampaignReview />}

              {/* Nav for each section */}
              {custom.total_step_count ? (
                <div className="absolute -bottom-3 right-0 px-3">
                  <StepNav onClick={handleStepNav} handleFetchApi={handleCreateCampaign} id='setting_page_footer' />
                </div>
              ) : (
                <></>
              )}
            </ModalBody>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CampaignCreatePage;
