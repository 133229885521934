export const AddNewSenderSMTPData = {
  first_name: '',
  last_name: '',
  email: '',
  smtp_username: '',
  smtp_password: '',
  smtp_host: '',
  smtp_port: '',
  smtp_secure: '',
  campaign_settings: { daily_limit: 0, delay_between_emails: 0 },
  // set_different_reply_to_address: '',
  imap_username: '',
  imap_password: '',
  imap_host: '',
  imap_port: '',
  imap_secure: '',
};
