import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/modal';
import { GoHourglass } from 'react-icons/go';
import { MdAttachEmail, MdCheck } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { setChatText, setTicketDetails, setTicketId, setTotalMessages, setTotalMessagesCount, showTicketDetailsStatus } from 'store/true-verifier';
import ChatOptions from './ChatOptions';
import Button from 'common/sparkle-common/Button';
import { BaseApi } from 'common/api/common/base-api';
import React from 'react';
// import { useNavigate } from 'react-router-dom';

const TicketDetails = () => {
  // const navigate = useNavigate()
  const dispatch = useDispatch();
  const { ticketDetailsStatus, ticketDetails, ticketId } = useAppSelector((state) => state?.trueVerifier);
  const [closeTicketLoading, setCloseTicketLoading] = React.useState(false)

  const handleTicketClose = async () => {
    try {
      setCloseTicketLoading(true)
      const response = await BaseApi.patch(`/true/verify/close/ticket`, {
        uuid: ticketId,
      });
      if (response?.status === 200) {
        dispatch(showTicketDetailsStatus(false));
        // navigate('/admin/tickets')

      }
    } catch (error) {
      console.log(error);
    } finally {
      setCloseTicketLoading(false)
    }
  };
  return (
    <Drawer
      isOpen={ticketDetailsStatus}
      placement="right"
      onClose={() => {
        dispatch(showTicketDetailsStatus(false));
        dispatch(setTicketId(''));
        dispatch(setTotalMessages([]));
        dispatch(setChatText(''));
        dispatch(setTotalMessagesCount(0));
        const details = {
          ticket_id: '',
          status: 0,
          closed_date: '',
          created_date: '',
        };
        dispatch(setTicketDetails(details));
      }}
      size={'auto'}
    >
      <DrawerOverlay />
      <DrawerContent className="rounded-md dark:bg-darkThree" style={{ width: '35rem' }} paddingX={3} paddingY={5}>
        <DrawerHeader padding={0}>
          <MdAttachEmail className="float-left mr-3 mt-0.5 text-column dark:text-white" />
          <div className="mb-3 flex justify-between gap-1">
            <h5 className="text-base text-heading dark:text-purple-500">New Ticket</h5>
            {ticketDetails?.status !== 1 && <Button name="Close Ticket" loading={closeTicketLoading} disable={closeTicketLoading} customClass="mr-3 bg-red-500 border-none" onClick={() => handleTicketClose()} />}
          </div>
          <hr className='dark:border-darkBorder' />
        </DrawerHeader>
        <DrawerBody paddingX={0} paddingY={5} className="flex flex-col gap-5">
          <div className="flex rounded-md bg-field">
            <div className="w-1/4 space-y-1 border-r dark:border-darkBorder px-5 py-2.5">
              <h5 className="text-sm font-medium text-column dark:text-gray-200">Created</h5>
              <h5 className="text-sm font-bold text-heading dark:text-purple-500">{ticketDetails?.created_date}</h5>
            </div>
            <div className="w-1/4 space-y-1 border-r dark:border-darkBorder px-5 py-2.5">
              <h5 className="text-sm font-medium text-column dark:text-gray-200">Closed</h5>
              <h5 className="text-sm font-bold text-heading dark:text-purple-500">{ticketDetails?.closed_date || '-'}</h5>
            </div>
            <div className="w-1/4 space-y-1.5 border-r dark:border-darkBorder px-5 py-2.5">
              <h5 className="text-sm font-medium text-column dark:text-gray-200">Status</h5>
              {ticketDetails?.status === 0 && (
                <div className="flex w-min items-center gap-0.5 text-nowrap rounded-full bg-[#EFF8FF] px-2 py-0.5">
                  <GoHourglass className="text-xs text-[#175CD3]" />
                  <h5 className={`text-xs font-medium text-[#175CD3] `}>In progress</h5>
                </div>
              )}

              {ticketDetails?.status === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-0.5">
                  <MdCheck className="text-xs text-completed-color" />
                  <h5 className={`text-xs font-semibold text-completed-color `}>Completed</h5>
                </div>
              )}
              {ticketDetails?.status === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-orange-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-orange-500" />
                  <h5 className={`text-xs font-semibold text-orange-500 `}>Reopened</h5>
                </div>
              )}
              {ticketDetails?.status === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-red-50 px-2 py-0.5">
                  <MdCheck className="text-xs text-red-500" />
                  <h5 className={`text-xs font-semibold text-red-500 `}>Cancelled</h5>
                </div>
              )}
            </div>
            <div className="w-1/4 space-y-1 px-5 py-2.5">
              <h5 className="text-sm font-medium text-column dark:text-gray-200">Ticket ID</h5>
              <h5 className="w-24 truncate text-sm font-bold text-heading dark:text-purple-500">{ticketDetails?.ticket_id}</h5>
            </div>
          </div>
          <ChatOptions />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TicketDetails;
