import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Stack } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import SparkleRadio from 'common/sparkle-common/Radio';
import { toastState } from 'common/utils/utility';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { toast } from 'react-toastify';

interface ModalPropsDTO {
  isOpen?: boolean;
  onClose?: any;
  couponData?: any;
  trigerFetchFunction?: any;
}

const CreateAndEditCoupons = (props: ModalPropsDTO) => {
  const { isOpen, onClose, couponData, trigerFetchFunction } = props;

  const [couponFormData, setCouponFromData] = useState({
    coupon_id: '',
    description: '',
    discount_type: '',
    discount_value: 0,
    usage_limit: '',
    valid_from: '',
    valid_until: '',
  });

  const formatDate = (dateString: string) => {
    return dateString ? new Date(dateString).toISOString().slice(0, 10) : '';
  };

  // useEffect(() => {
  //   if (couponData) {
  //     setCouponFromData({
  //       ...couponData,
  //       valid_from: formatDate(couponData?.valid_from),
  //       valid_until: formatDate(couponData?.valid_until),
  //     });
  //   }
  // }, [couponData]);

  useEffect(() => {
    if (isOpen) {
      if (couponData) {
        setCouponFromData({
          coupon_id: couponData?.coupon_id || '',
          description: couponData?.description || '',
          discount_type: couponData?.discount_type || '',
          discount_value: couponData?.discount_value || 0,
          usage_limit: couponData?.usage_limit || '',
          valid_from: formatDate(couponData?.valid_from) || '',
          valid_until: formatDate(couponData?.valid_until) || '',
        });
      } else {
        setCouponFromData({
          coupon_id: '',
          description: '',
          discount_type: '',
          discount_value: 0,
          usage_limit: '',
          valid_from: '',
          valid_until: '',
        });
      }
    }
  }, [isOpen, couponData]);

  const handleSelectDiscountType = (value: string) => {
    setCouponFromData((prevData: any) => ({
      ...prevData,
      discount_type: value,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCouponFromData({
      ...couponFormData,
      [name]: value,
    });
  };

  const handleSelectUsage = (value: string) => {
    setCouponFromData((prevData: any) => ({
      ...prevData,
      usage_limit: value,
    }));
  };
  const discountTypeOption = [
    {
      name: 'percentage',
      id: 1,
    },
    {
      name: 'points',
      id: 2,
    },
    {
      name: 'fixed',
      id: 3,
    },
  ];
  const handleDateChange = (name: string, e: any) => {
    setCouponFromData((prevData: any) => ({
      ...prevData,
      [name]: e.target.value,
    }));
    // setDateInput(e.target.value);
  };

  const onHandleClose = () => {
    setCouponFromData({
      coupon_id: '',
      description: '',
      discount_type: '',
      discount_value: 0,
      usage_limit: '',
      valid_from: '',
      valid_until: '',
    });
  };
  //

  const handleCreateAndEditData = async (type: string) => {
    if (type === 'add') {
      try {
        const payload = {
          coupon_id: couponFormData.coupon_id,
          description: couponFormData.description,
          discount_type: couponFormData.discount_type,
          discount_value: couponFormData.discount_value,
          usage_limit: couponFormData.usage_limit,
          valid_from: couponFormData.valid_from,
          valid_until: couponFormData.valid_until,
        };
        console.log(payload, 'payload');
        const response = await BaseApi.post(`/coupons/`, payload);
        if (!response.error) {
          trigerFetchFunction();
          // onClose();
        } else {
          toast.error(response.message, toastState.error);
        }
      } catch (error) {
      } finally {
        onClose();
        onHandleClose();
      }
    } else {
      try {
        const payload = {
          coupon_id: couponFormData.coupon_id,
          description: couponFormData.description,
          discount_type: couponFormData.discount_type,
          discount_value: couponFormData.discount_value,
          usage_limit: couponFormData.usage_limit,
          valid_from: couponFormData.valid_from,
          valid_until: couponFormData.valid_until,
        };
        console.log(payload, 'payload');
        const response = await BaseApi.patch(`/coupons/${couponData.uuid}`, payload);
        if (!response.error) {
          trigerFetchFunction();
          // onClose();
        } else {
          toast.error(response.message, toastState.error);
        }
      } catch (error) {
      } finally {
        onClose();
        onHandleClose();
      }
    }
  };

  console.log(couponFormData.valid_from, couponFormData.valid_until);
  return (
    <div>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onHandleClose();
        }}
        size={'xl'}
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="!p-0">
          <ModalHeader>
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold text-heading">{couponData ? 'Edit Coupon' : 'Create Coupons'}</h4>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <div className="!h-[450px] overflow-y-auto">
            <ModalBody className=" bg-modalbg  !p-5 ">
              <div className="flex  w-full items-center justify-center rounded-lg bg-white p-4">
                <Stack spacing={4} className="w-full">
                  <InputField
                    inputClass="!h-10"
                    placeholder="Coupon Id"
                    name="coupon_id"
                    label="Coupon id"
                    value={couponFormData?.coupon_id ? couponFormData?.coupon_id : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e);
                    }}
                  />
                  <TextField
                    placeholder="Description "
                    id="description"
                    label="Description"
                    rows={5}
                    cols={0}
                    value={couponFormData?.description}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e);
                    }}
                  />
                  <div className="flex items-center gap-4">
                    <div className="flex-1">
                      <InputField
                        inputClass="!h-10"
                        value={couponFormData?.discount_value}
                        placeholder="Discount value"
                        name="discount_value"
                        label="Discount value"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>

                    <CustomMenu
                      //   open={openProspectsOption}
                      //   onOpen={() => setOpenprosPectsOption(true)}
                      //   onClose={() => setOpenprosPectsOption(false)}
                      btnContent={couponFormData?.discount_type ? couponFormData?.discount_type : 'Discount Type'}
                      btnClassName="w-28 !h-10 justify-between !text-sm !rounded-lg !font-normal truncate mt-8 border border-gray-200 text-[#667085] focus:border-[#4318FF] !h-10 text-center items-cemter prospect-menu "
                      menuListClassName="!w-28 h-24  !mt-1 !p-0 border border-menuBorder rounded-md "
                      rightIcon={<BiChevronDown size={18} />}
                    >
                      <div className="p-2">
                        {discountTypeOption.map((item: any, index: number) => (
                          <div
                            key={item.id}
                            id={`prospect-email-${index}`}
                            className={`w-full cursor-pointer py-1 pl-2 text-left text-[.8125rem] font-medium text-column ${
                              item.name === couponFormData?.discount_type ? 'bg-blueSecondary text-white' : 'hover:bg-gray-100'
                            }`}
                            onClick={() => handleSelectDiscountType(item.name)}
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </CustomMenu>
                  </div>
                  <div className="flex gap-3">
                    <div style={{ marginBottom: '15px' }}>
                      <label htmlFor="datePicker" className="mb-2 ml-3 text-sm  font-bold text-navy-700">
                        Vaid From
                      </label>
                      <input
                        type="date"
                        id="datePicker"
                        value={couponFormData?.valid_from}
                        placeholder="Enter Date"
                        onChange={(e) => {
                          handleDateChange('valid_from', e);
                        }}
                        className="mt-3 h-10 w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2"
                      />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                      <label htmlFor="datePicker" className="mb-2 ml-3 text-sm  font-bold text-navy-700">
                        Vaid Until
                      </label>
                      <input
                        type="date"
                        id="datePicker"
                        value={couponFormData?.valid_until}
                        placeholder="Enter Date"
                        onChange={(e) => {
                          handleDateChange('valid_until', e);
                        }}
                        className="mt-3 h-10 w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="ml-3 text-sm font-bold  text-navy-700">Usage</p>
                    <div className={`ml-3 mt-2 flex w-48 items-center justify-between `}>
                      <div className="flex items-start justify-center gap-1">
                        <SparkleRadio checked={couponFormData?.usage_limit === 'one_time'} name="one_time" onChange={() => handleSelectUsage('one_time')} />
                        <label className="text-[0.68rem] font-medium" htmlFor="one_time">
                          Single Time
                        </label>
                      </div>
                      <div className="flex items-start justify-center gap-1">
                        <SparkleRadio checked={couponFormData?.usage_limit === 'multiple'} name="multiple" onChange={() => handleSelectUsage('multiple')} />
                        <label className="text-[0.68rem] font-medium" htmlFor="multiple">
                          {' '}
                          Multiple
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex gap-4">
                    <Button
                      name="Cancel"
                      customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]"
                      onClick={() => {
                        onClose();
                        onHandleClose();
                      }}
                    />
                    <Button
                      name={couponData ? 'Edit' : 'Add'}
                      customClass="w-full bg-white border !text-white !font-semibold text-[.82rem] !bg-blueSecondary"
                      onClick={() => {
                        couponData ? handleCreateAndEditData('edit') : handleCreateAndEditData('add');
                      }}
                    />
                  </div>
                </Stack>
              </div>
            </ModalBody>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreateAndEditCoupons;
