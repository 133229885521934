import Card from "components/card";
import InputWithBtn from "components/fields/InputWithBtn";

const CRMEmail = () => {
    return (
        <Card extra={"w-full px-8 py-8"}>
            {/* Header */}
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                BBC to CRM
                </h4>
                <p className="mt-1 text-base text-gray-600 font-small">
                Add a BCC email address to forward all emails sent by Snov.o through this account to your CRM.
                </p>
        <InputWithBtn 
        label="CRM Email Address"
        id="CRM-Email"
        content="Verify"
        placeholder="example@bcc.your-crm.com"
        link="Where to find your CRM email"/>
        </Card>
    );
};

export default CRMEmail