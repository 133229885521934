import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { toastState } from 'common/utils/utility';
import InputField from 'components/fields/InputField';
import React from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage } from 'store/custom';

const TwoStepAuth = (props: any) => {
  const { isOpen, onClose, email, setState } = props;

  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);

  const dispatch = useAppDispatch();
  const [handleState, setHandleState] = React.useState({
    loading: false,
    otpLoading: false,
    inputOtpPage: false,
    password: '',
  });

  const handlepasswordChange = (event: any) => {
    const { id, value } = event.target;

    setHandleState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const numInputs = 6;

  const [otp, setOtp] = React.useState(Array(numInputs).fill(''));
  const [focusIndex, setFocusIndex] = React.useState(0);

  // console.log('otp', otp.join(''));
  const handleverifyUserPasswordForTwoFactorAuthetication = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const Payload = {
        password: handleState?.password,
      };

      const updateResponse = await BaseApi.patch(`/auth/user/2fa/enable`, Payload);
      if (!updateResponse?.error) {
        toast.success('OTP sent to email Successfully', toastState.success);
        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setHandleState((prevState) => ({
          ...prevState,
          inputOtpPage: true,
        }));
        dispatch(getErrorMessage({}));
      } else {
        dispatch(getErrorMessage(updateResponse?.message));

        setHandleState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };

  const handleVerifyOtpForTwoFactorAuthetication = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        otpLoading: true,
      }));

      const payload: String = otp.join('');
      console.log(payload);

      const updateOtpResponse = await BaseApi.post(`/auth/verify/otp`, { otp: otp.join(''), type: '2fa' });
      if (!updateOtpResponse?.error) {
        toast.success('2FA Enabled Successfully', toastState.success);
        setHandleState((prevState) => ({
          ...prevState,
          otpLoading: false,
        }));
        dispatch(getErrorMessage({}));
        setOtp(Array(numInputs).fill(''));
        setState((prev: any) => ({ ...prev, logDetails: { ...prev?.logDetails, twoFactorAuthenticationStatus: true } }));
        handleClose();
      } else {
        // toast.error('OTP Required', toastState.error);
        dispatch(getErrorMessage(updateOtpResponse?.message));

        setHandleState((prevState) => ({
          ...prevState,
          otpLoading: false,
        }));
      }
    } catch (error) {
      setHandleState((prevState) => ({
        ...prevState,
        otpLoading: false,
      }));
      console.log(error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const Payload = {
        email: email,
      };

      const updateResponse = await BaseApi.patch(`/auth/resend/otp`, Payload);
      if (!updateResponse?.error) {
        toast.success('OTP has been Resent to your registered email', toastState.success);
      } else {
        toast.error(updateResponse?.message, toastState.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const input = document.getElementById(`otp-${focusIndex}`);
    if (input) {
      input.focus();
    }
  }, [focusIndex]);

  const handleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      setFocusIndex(index - 1);
    }
  };
  const handleChange = (index: any) => (event: any) => {
    const { value } = event.target;
    const newOtp = [...otp];

    if (/^[0-9]$/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < numInputs - 1) {
        setFocusIndex(index + 1);
      }
    }
  };
  const handleClose = () => {
    setHandleState((prevState) => ({
      ...prevState,
      inputOtpPage: false,
    }));
    onClose();
  };
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => {}} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="select-none dark:!bg-darkTwo">
          <ModalHeader className="mb-1 bg-[#FAFAFC] dark:!bg-darkOne dark:text-white">
            <div className=" flex !flex-col gap-3">
              <h5 className="text-lg font-semibold">Authentication Required</h5>
              {handleState?.inputOtpPage ? (
                <span className="-mt-3 text-sm font-medium">Enter the 6-digit authentication code generated by you app.</span>
              ) : (
                <span className="-mt-3 text-sm font-medium">Please confirm your identity before enabling authentication</span>
              )}
            </div>
          </ModalHeader>
          <ModalBody className=" !p-3 dark:!bg-darkTwo">
            <div className="p-2 dark:!text-white ">
              {handleState?.inputOtpPage ? (
                <>
                  <div className=" text-center dark:!text-white">
                    <h1 className="font-semibold text-blackDark dark:!text-white">Check your email</h1>
                    <h5 className="text-xs text-column dark:!text-gray-300">We sent a code to your email</h5>
                    <h5 className="text-xs font-semibold text-column dark:!text-gray-300">{email}</h5>
                  </div>
                  <div className="mt-4 flex items-center justify-center gap-2">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="tel"
                        onKeyDown={handleKeyDown(index)}
                        maxLength={1}
                        value={value}
                        onChange={handleChange(index)}
                        // className="h-11 w-11 rounded-md border border-brandLinear text-center hover:border-2 hover:border-blue-300"
                        className={`h-11 w-11 rounded-md border text-center dark:bg-darkTwo ${
                          errorMessage?.otp ? 'border-red-500' : 'border-brandLinear hover:border-2 hover:border-blue-300'
                        }`}
                        autoComplete="off"
                      />
                    ))}
                  </div>
                  {errorMessage?.otp && (
                    <span className="ml-1 mt-1 flex h-full w-full items-center justify-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">
                      {errorMessage?.otp}
                    </span>
                  )}

                  <div className="mt-2 flex justify-center text-center ">
                    <span className="text-xs font-medium text-navy-700 dark:text-gray-500">Not received yet?</span>
                    <div
                      onClick={handleResendOtp}
                      className="2fa_resend_otp_request_btn ml-1 cursor-pointer text-xs font-semibold text-brand-500 hover:text-brand-600 hover:text-blue-600"
                    >
                      Resend OTP
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* <div className=" text-center">
                <h1 className="font-semibold text-blackDark">Check your email</h1>
                <h5 className="text-xs text-column">We sent a code to your email</h5>
              </div> */}
                  <InputField
                    autoComplete="off"
                    customClass="!h-9 !mt-0"
                    inputClass="!mt-0.5 password_for_2fa !rounded-md !h-9 !bg-white dark:!bg-darkTwo"
                    labelClass="!text-paused-color  !font-[500] !ml-0 !text-xs"
                    label="Password *"
                    placeholder="Password"
                    id="password"
                    type="password"
                    value={handleState?.password}
                    onChange={handlepasswordChange}
                    errorMessage={errorMessage?.password}
                    state={errorMessage?.password ? 'error' : ''}
                  />
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="!mb-5 !mt-2 !h-2 w-full ">
            <Button
              id="two-setp-auth-cancel"
              name="Cancel"
              onClick={handleClose}
              customClass="!bg-[#F4F7FE] dark:!text-blackDark  w-full !text-[#A3AED0] cancel_2fa_btn  border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
            />
            {handleState?.inputOtpPage ? (
              <Button
                id="two-setp-auth-confirm"
                disable={handleState?.otpLoading}
                loading={handleState?.otpLoading}
                name={'Confirm'}
                customClass=" !text-[#FFFFFF] dark:!border-none w-full confirm_2fa_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
                onClick={() => handleVerifyOtpForTwoFactorAuthetication()}
              />
            ) : (
              <Button
                id="two-setp-auth-confirm"
                disable={handleState?.loading}
                loading={handleState?.loading}
                name={'Enable'}
                customClass=" !text-[#FFFFFF] dark:!border-none w-full enable_2fa_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem] active:scale-95 transition-transform duration-150 ease-out"
                onClick={() => {
                  handleverifyUserPasswordForTwoFactorAuthetication();
                }}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TwoStepAuth;
