/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';

interface PropsType {
  isOpen: boolean;
  onClose: () => void;
  handleDelete?: () => void;
  content?: string;
  title?: string;
}

const DeleteConfirmModal = (props: PropsType) => {
  const { isOpen, onClose, handleDelete } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </p>
        </ModalBody>

        <ModalFooter>
          <div className="mt-3 flex w-11/12 gap-3">
            <Button
              name="Cancel"
              customClass="w-full !bg-white !text-blueSecondary !border-blueSecondary font-semibold !text-[12px]"
              onClick={onClose}
            />
            <Button
              id="modal_popup_yes_btn"
              name="Delete"
              customClass="w-full font-semibold !text-[12px]"
              onClick={handleDelete}
            />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmModal;
