import React from 'react'
import SequencePage from '../sequence'
import ReviewAside from './ReviewAside'

const CampaignReview = () => {
  return (
    <div className='flex items-start w-full h-full'>
        <div className='h-full w-[60%] overflow-scroll p-10'><SequencePage disable={'pointer-events-none'}/></div>
        <ReviewAside />
    </div>
)
}

export default CampaignReview