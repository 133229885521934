import { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useAppSelector } from 'store';
import React from 'react';
// import GlobalOpt from './GlobalOpt';

interface RowData {
  id?: string;
  uuidRow?: string;
  status?: number;
  table?: any;
  setData?: any;
  rowData?: any;
  fetchList?: () => void;
  deleteList?: (val: string) => void;
  onOpen?: () => void;
  setId?: (val: string) => void;
}

const RowSettings = (props: RowData) => {
  const { uuidRow, id, onOpen, setId, deleteList } = props;
  const custom = useAppSelector((state) => state?.custom);
  const [showOptions, setShowOptions] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOptions = (selectedOpt: string, uuidSingle?: string) => {
    setShowOptions(false);
  };

  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A] '}`}
        onMouseOver={(e) => e.stopPropagation}
        tabIndex={0}
        onBlur={() => {
          setShowOptions(false);
        }}
      >
        <IoMdMore
          className="z-0 text-lg text-gray-700"
          id={`edit-btn-${id}`}
          onClick={(event) => {
            event?.stopPropagation();
            setShowOptions(true);
          }}
        />
        {showOptions && !custom?.globalOpt && (
          <div className="absolute -right-0 top-full z-10 flex flex-col overflow-hidden rounded-md border bg-white dark:border-darkBorder dark:bg-darkTwo">
            <div
              id="edit-btn"
              className="group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => {
                setId(uuidRow);
                onOpen();
              }}
            >
              <MdEdit className="mt-0.5 text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Edit</h5>
            </div>
            <div
              id="delete-btn"
              className="group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => {
                deleteList(uuidRow);
              }}
            >
              <MdDelete className="mt-0.5 text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Delete</h5>
            </div>
          </div>
        )}
      </div>
      {/* {custom?.globalOpt && (
        <div onClick={(event) => event?.stopPropagation()}>
          <GlobalOpt handleOptions={handleOptions} />
        </div>
      )} */}
    </>
  );
};

export default RowSettings;
