import Card from "components/card";
import { useAppSelector } from "store";
const Profile = () => {
  const editData = useAppSelector((state) => state?.emailAccount?.getEditData)

  return (
    <Card extra={"py-[18px] px-[18px] h-[140px]"}>
      <div className="flex items-center gap-3">
        <div className="flex h-[90px] w-[90px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full"
            src={editData?.picture}
            alt="phot"
          />
        </div>
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {editData?.name}
          </p>
          <p className="text-base text-gray-600">{editData?.email}</p>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
