import React from 'react';
// import { toast } from 'react-toastify';
// import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import PlanCard from './PlanCard';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import PlanCardLoader from './PlanCardLoader';
// import { useAppDispatch } from 'store';
// import { setCheckSparkles, setPaymentCards, setPaymentStatus } from 'store/account-settings';

const PlanOptions = () => {
  const [cardLoadind, setCardLoading] = React.useState(false);
  const [data, setData] = React.useState<{ data: any; count: number }>({
    data: [],
    count: 0,
  });
  const [creditsDetails,setCreditsDetails] = React.useState([])

  // const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const orderId = searchParams.get('order_id');
  // const dispatch = useAppDispatch();

  // const updateResponse = async () => {
  //   try {
  //     const response = await BaseApi.get(`/subscription/payment/status?order_id=${orderId}`);
  //     if (response?.data?.payment_status === 'SUCCESS') {
  //       toast.success('Credits added successfully', toastState.success);
  //       dispatch(setPaymentStatus(true));
  //       dispatch(setCheckSparkles())
  //     } else {
  //       toast.error('Failed adding Credits ', toastState.error);
  //       dispatch(setPaymentCards({}));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // React.useEffect(() => {
  //   if (orderId) {
  //     orderId && updateResponse();
  //     navigate('/settings/billing');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderId]);

  // console.log('Data', data);

  const fetchCreditPricing = async ()=>{
    try {
      setCardLoading(true);
      const billingresponse = await BaseApi.get(`/credit/addons`);
      console.log("billingresponse",billingresponse)
      if (!billingresponse?.error) {
        setCreditsDetails( billingresponse?.data?.data || [])
        setCardLoading(false);
      } else {
        setCardLoading(false);
      }
    } catch (error) {}
  }
  React.useEffect(() => {
    fetchCreditPricing();

    const fetchBillingPlan = async () => {
      try {
        setCardLoading(true);
        const billingresponse = await BaseApi.get(`/subscription/plan/billing/plan`);
        if (!billingresponse?.error) {
          // console.log('>>>>>>>>>>>>>>', billingresponse?.data?.data);
          billingresponse?.data?.data?.length
            ? setData({ ...data, data: billingresponse?.data?.data, count: billingresponse?.data?.total_records?.count })
            : setData({ ...data, data: [], count: 0 });
          // setCredits({ ...credits, price: billingresponse?.data?.data?.credit_value_list[0]?.price, sparkles: billingresponse?.data?.data?.credit_value_list?.[0]?.credit });
          setCardLoading(false);
        } else {
          setCardLoading(false);
        }
      } catch (error) {}
    };
    fetchBillingPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="mt-5  grid grid-cols-2  gap-5">
        {cardLoadind ? (
          <>
            {[...Array(2)].map((_, idx) => (
              <PlanCardLoader key={idx} />
            ))}
          </>
        ) : (
          <>{data?.data?.length ? data?.data?.map((items: any, idx: number) => <PlanCard key={`planCard-${idx}`} items={items} creditsDetails={creditsDetails} />) : <></>}</>
        )}
      </div>
    </div>
  );
};

export default PlanOptions;
