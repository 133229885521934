import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
// import { toastState } from 'common/utils/utility';
import CustomBadge from 'components/badges/CustomBadge';
import React from 'react';
import { FiArrowRight } from 'react-icons/fi';


const ExtraCreditsCard = (props: any) => {
  const { plan } = props;

  const [loading, setLoading] = React.useState(false);

 

  const handlePayment = async (credit_addon_id: string) => {
    try {
      setLoading(true);
      const response = await BaseApi.post(`/subscription/payment`, {
        credit_addon_id:credit_addon_id
        
      });
      if(!response?.error){
        if (response.data.sessionUrl) {
          window.location.href = `${response.data.sessionUrl}`;
        }
      }else{

        setLoading(false);
      }
    //   if (response.data.sessionUrl) {
    //     window.location.href = `${response.data.sessionUrl}`;
    //   }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="mt-4 ">
      <div className="w-full rounded-md border bg-white p-4 text-center shadow dark:!border-darkBorder dark:bg-darkThree">
        <>
          <div className="my-1 text-2xl font-bold dark:text-white">{plan?.credits} Sparkles</div>
          <div className='flex justify-center items-end gap-2'>
          <div className="mt-2 font-bold text-blueSecondary items-center dark:text-purple-500">${plan?.amount}</div>
          {plan?.discount_percentage !==0 && <CustomBadge content={`Save ${plan?.discount_percentage} %`} bgColor='green-100' color={'green-600'} extra='h-5 !font-semibold text-[.625rem] !mb-0.5 !px-2 !py-0.5'/>}
          </div>
        </>

        <div className="mt-3">
          <Button
            name={`Purchase Now`}
            loading={loading}
            disable={loading}
            RightIcon={<FiArrowRight size={15} />}
            onClick={() => handlePayment(plan?.uuid)}
            customClass="security_page_change_password bg-white dark:!bg-purple-800  w-full !text-blueSecondary border border-blueSecondary dark:!border-none dark:!text-white font-semibold text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
          />
        </div>
      </div>
    </div>
  );
};

export default ExtraCreditsCard;
