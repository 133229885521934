export const seriesDataDummy = [
  {
    name: 'Sent',
    data: [10, 20, 30, 40, 50, 30, 60, 50, 30, 40, 10, 20],
  },
  {
    name: 'Opened',
    data: [15, 25, 35, 45, 55, 30, 60, 40, 30, 40, 15, 25],
  },
  {
    name: 'Reply',
    data: [10, 20, 30, 40, 50, 30, 60, 50, 30, 40, 10, 20],
  },
  {
    name: 'Bounced',
    data: [15, 25, 35, 45, 55, 30, 60, 40, 30, 40, 15, 25],
  },
]
export const initialLayout = [
  { i: '1', x: 0, y: 0, w: 16, h: 3, minW: 2, minH: 2, maxH: 10, maxW: 16 },
  { i: '2', x: 0, y: 0, w: 3, h: 7, minW: 2, minH: 6, maxW: 4, maxH: 10 },
  { i: '3', x: 3, y: 0, w: 3, h: 7, minW: 2, minH: 6, maxW: 4, maxH: 10 },
  { i: '4', x: 6, y: 0, w: 3, h: 7, minW: 2, minH: 6, maxW: 4, maxH: 10 },
  { i: '5', x: 9, y: 0, w: 3, h: 7, minW: 2, minH: 6, maxW: 4, maxH: 10 },
  { i: '6', x: 12, y: 0, w: 4, h: 7, minW: 2, minH: 6, maxW: 4, maxH: 10 },
  { i: '7', x: 0, y: 1, w: 16, h: 19, minW: 2, minH: 4, maxW: 16 },
  { i: '8', x: 0, y: 2, w: 16, h: 19, minW: 2, minH: 4, maxH: 20, maxW: 16 },
  { i: '9', x: 0, y: 3, w: 16, h: 19, minW: 2, minH: 4, maxH: 20, maxW: 16 },
  // { i: '10', x: 0, y: 3, w: 12, h: 19, minW: 2, minH: 4, maxH: 20, maxW: 12 },
]

export const categoriesDataDummy = ['1 July', '2 July', '3 July', '4 July', '5 July', '6 July', '7 July', '8 July', '9 July', '10 July', '11 July', '12 July']
