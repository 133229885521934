/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Avatar, Menu, MenuButton, MenuList, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import React, { useEffect, useRef, useState } from 'react';
import { setReplyThreadData } from 'store/crm';
import { MdOutlineOpenInFull } from 'react-icons/md';
import { FaWindowMinimize } from 'react-icons/fa6';
import { LuForward, LuReply, LuReplyAll } from 'react-icons/lu';
import { FaCaretDown } from 'react-icons/fa';
import moment from 'moment';
import saveAs from 'file-saver';
import Typer from './Typer';
import { useDispatch } from 'react-redux';
import { CgFile } from 'react-icons/cg';
import { base64Decode, checkRolePermissionStatus } from 'common/utils/utility';
import { useAppSelector } from 'store';
import ArrowedTooltip from 'components/ArrowedTooltip';

const EmailFullView = (props: any) => {
  const { isOpen, onClose, main_message_id } = props;
  const [viewSize, setViewSize] = useState('4xl');
  const [isTyperOpen, setisTyperOpen] = useState(false);
  const custom = useAppSelector((state) => state?.custom);
  const [rowIndex, setRowIndex] = useState([]);
  const [emailData, setEmailData] = useState<any>({ loading: true, profile: { name: '', receiver_email: '', date: '', subject: '', message: [] } });
  const dispatch = useDispatch();
  const typerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isTyperOpen && typerRef.current) {
      typerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTyperOpen]);

  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        return [...prevArr, index];
      }
    });
  };

  const downloadFile = (attachment: any) => {
    if (attachment?.content?.data && attachment?.filename && attachment?.contentType) {
      const byteArray = new Uint8Array(attachment.content.data);
      const blob = new Blob([byteArray], { type: attachment.contentType });
      const filename = attachment.filename || 'attachment';
      saveAs(blob, filename);
    } else {
      console.error('Invalid attachment data');
    }
  };

  useEffect(() => {
    const fetchDbEmails = async () => {
      try {
        setIsLoading(true);
        setEmailData((prev: any) => ({ ...prev, loading: true }));
        const response = await BaseApi.get(`crm/pipeline/opportunity/get/mail/thread/${main_message_id}`);
        if (response?.data) {
          const name = response?.data[0]?.platform_message_details?.from?.address || response?.data[0]?.platform_message_details?.from?.address?.split('@')[0];
          const date = moment(response?.data[0]?.platform_message_details?.mail_sent_date_local).format('MMM DD, hh:mm A');
          let message: string;
          if (response?.data[0]?.platform_message_details?.email_type === 'smtp_imap') {
            message = response.data?.map((item: any) => {
              const attachment = item?.platform_message_details?.inbox_mail_data?.attachments
                ?.map((attachment: any) => {
                  if (attachment?.filename) {
                    return attachment;
                  } else {
                    return null;
                  }
                })
                .filter(Boolean);
              return { fullMessage: item?.platform_message_details?.inbox_mail_data?.html || '', attachment, ...item };
            });
            // message = inbox_mail_data?.text
          } else if (response.data[0]?.platform_message_details?.email_type === 'oauth2') {
            message = response?.data?.map((item: any) => {
              const base64String = item?.platform_message_details?.inbox_mail_data?.payload?.parts?.[1]?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
              const base64String2 = item?.platform_message_details?.inbox_mail_data?.payload?.body?.data?.replace(/-/g, '+')?.replace(/_/g, '/');
              // const fullMessage = base64String ? atob(base64String) : base64String2 ? atob(base64String2) : item?.platform_message_details?.inbox_mail_data?.snippet;
              const fullMessage = base64String
                ? base64Decode(base64String)
                : base64String2
                ? base64Decode(base64String2)
                : item?.platform_message_details?.inbox_mail_data?.snippet;

              const attachment = item?.platform_message_details?.inbox_mail_data?.payload?.parts
                ?.slice(1)
                .map((attachment: any) => {
                  if (attachment?.filename) {
                    return {
                      filename: attachment.filename,
                      contentType: attachment.mimeType,
                      content: attachment.body?.content,
                      size: attachment.body?.size,
                    };
                  } else {
                    return null;
                  }
                })
                .filter((attachment: any) => attachment !== null);
              return { fullMessage, attachment, ...item };
            });
            // message = decodeURIComponent(atob(base64String))
          }
          const profile = {
            name,
            receiver_email: response.data[0]?.platform_message_details?.from?.address,
            subject: response.data[0]?.platform_message_details?.subject,
            message,
            date,
          };
          setEmailData((prev: any) => ({ ...prev, profile }));
        }
      } catch (error) {
        console.log('Unmatched email fetching error', error);
      } finally {
        setIsLoading(false);
        setEmailData((prev: any) => ({ ...prev, loading: false }));
        setRowIndex([emailData?.profile?.message?.length - 1]);
      }
    };

    main_message_id && fetchDbEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [main_message_id, isOpen]);

  // Reply
  const handleReply = (item: any, sender_id: string) => {
    setisTyperOpen(false);
    // const smtpPattern = /<[^>]+@[^>]+>/;
    let payload;
    //item?.inbox_mail_data?.cc?.value ? item?.inbox_mail_data?.cc?.value :
    // item?.inbox_mail_data?.bcc?.value ? item?.inbox_mail_data?.bcc?.value :
    if (item.type === 'sender') {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          from: item?.inbox_mail_data?.from?.value,
          to: item?.inbox_mail_data?.to?.value,
          delivered_to: item.delivered_to,
          sender_id: sender_id,
          message: '',
          main_message_id: item?.main_message_id,
          individual_message_id: item?.individual_message_id,
          subject: item.subject,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.from,
          to: item.to,
          message: '',
          delivered_to: item.delivered_to,
          sender_id: sender_id,
          // cc: [],
          // bcc: [],
          subject: item.subject,
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    } else {
      if (item?.email_type === 'smtp_imap') {
        payload = {
          from: item?.inbox_mail_data?.to?.value,
          to: item?.inbox_mail_data?.from?.value,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          message: '',
          main_message_id: item?.main_message_id,
          individual_message_id: item?.individual_message_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: item.main_message_id,
          from: item.to,
          to: item.from,
          delivered_to: item.delivered_to,
          subject: item.subject,
          sender_id: sender_id,
          message: '',
          type: 'reply',
          individual_message_id: item?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item?.from, item?.to) : createPayload(item?.to, item?.from);
    dispatch(setReplyThreadData(payload));
    // setisTyperOpen(true);
    setTimeout(() => {
      setisTyperOpen(true);
    }, 500);
  };

  const handleReplyAll = (data: any, sender_id: string) => {
    setisTyperOpen(false);

    let payload;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.from?.value,
          to: data?.inbox_mail_data?.to?.value,
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          main_message_id: data?.main_message_id,
          individual_message_id: data?.individual_message_id,
          type: 'reply',
        };
      } else {
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: data?.to,
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'reply',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        payload = {
          from: data?.inbox_mail_data?.to?.value,
          to: [...data?.inbox_mail_data?.from?.value, ...data?.inbox_mail_data?.to?.value?.slice(1, data?.inbox_mail_data?.to?.value?.length)],
          delivered_to: data?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.individual_message_id,
        };
      } else {
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [data?.from, ...data?.to?.slice(1, data?.to?.length)],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          sender_id: sender_id,
          type: 'reply',
          message: '',
          individual_message_id: data?.oauth_delivery_message_id,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);
    dispatch(setReplyThreadData(payload));
    setisTyperOpen(true);
  };

  const handleForward = (data: any, sender_id: string, message: any) => {
    setisTyperOpen(false);
    let payload;
    // const smtpPattern = /<[^>]+@[^>]+>/;
    if (data.type === 'sender') {
      if (data?.email_type === 'smtp_imap') {
        const toEmails = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
        payload = {
          from: data?.inbox_mail_data?.from?.value,
          to: [],
          delivered_to: data?.platform_message_details?.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data?.subject,
          sender_id: sender_id,
          main_message_id: data?.main_message_id,
          individual_message_id: data?.individual_message_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmails}<br><br>`,
        };
      } else {
        const toEmailoriginalData = Array.isArray(data?.to) ? data?.to.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data?.main_message_id,
          from: data?.from,
          to: [],
          delivered_to: data?.delivered_to,
          cc: data?.cc ? data?.cc : [],
          bcc: data?.bcc ? data?.bcc : [],
          subject: data?.subject,
          sender_id: sender_id,
          message: '',
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmailoriginalData}<br><br>`,
        };
      }
    } else {
      if (data?.email_type === 'smtp_imap') {
        const toEmaildata = data?.inbox_mail_data?.to?.value?.map((item: any) => item.address).join(', ');
        payload = {
          from: data?.inbox_mail_data?.to?.value,
          to: [],
          delivered_to: data.delivered_to,
          cc: data?.inbox_mail_data?.cc?.value ? data?.inbox_mail_data?.cc?.value : [],
          bcc: data?.inbox_mail_data?.bcc?.value ? data?.inbox_mail_data?.bcc?.value : [],
          subject: data.subject,
          message: '',
          sender_id: sender_id,
          individual_message_id: data?.individual_message_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.inbox_mail_data?.from?.value?.[0]?.address}<br>Date: ${moment(
            data?.platform_message_details?.date
          ).format('MMM DD, hh:mm A')}<br>Subject: ${data?.subject} <br>To: ${toEmaildata}<br><br>`,
        };
      } else {
        const toEmaildoauth = Array.isArray(data?.to) ? data?.to.map((item: any) => item.address).join(', ') : data?.to?.address || '';
        payload = {
          main_message_id: data.main_message_id,
          from: data.to,
          to: [],
          delivered_to: data.delivered_to,
          cc: data.cc ? data.cc : [],
          bcc: data.bcc ? data.bcc : [],
          subject: data.subject,
          message: '',
          sender_id: sender_id,
          type: 'forward',
          date: moment(data?.platform_message_details?.date).format('MMM DD, hh:mm A'),
          individual_message_id: data?.platform_message_details?.oauth_delivery_message_id,
          originalMessagedata: `<br> -------Forwarded Message-------<br>from: ${data?.from?.address}<br>Date: ${moment(data?.platform_message_details?.date).format(
            'MMM DD, hh:mm A'
          )}<br>Subject: ${data?.subject} <br>To: ${toEmaildoauth}<br><br>`,
        };
      }
    }
    // const payload = item.type === 'sender' ? createPayload(item.from, item.to) : createPayload(item.to, item.from);

    dispatch(setReplyThreadData(payload));
    setTimeout(() => {
      setisTyperOpen(true);
    }, 500);
  };

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setisTyperOpen(false);
        }}
        size={viewSize}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent className="!p-0" overflow={'auto'}>
          <ModalHeader className="!m-0 !p-0">
            <div className="flex items-center justify-between space-y-1 overflow-hidden  bg-heading p-4">
              <h5 className=" text-sm font-thin text-white ">{emailData?.profile?.subject}</h5>
              <div className="flex gap-2">
                <FaWindowMinimize className="size-3 cursor-pointer text-[#aaafc7]" onClick={() => setViewSize('4xl')} />
                <MdOutlineOpenInFull className=" mr-5 size-3 cursor-pointer text-[#aaafc7]" onClick={() => setViewSize('full')} />
                <ModalCloseButton size="sm" className={`full-view-modal-close  mt-2 cursor-pointer text-[#aaafc7]`} />
              </div>
            </div>
          </ModalHeader>
          <hr />

          <ModalBody className=" !px-0">
            {isLoading ? (
              <div className="p-4">
                {/* Profile loader */}
                <div className="mt-6 flex items-center gap-3">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <SkeletonCircle size="12" />
                  </div>
                  <SkeletonText mt="2" noOfLines={4} spacing="2" skeletonHeight="2" className="w-full" />
                </div>
                {/* Subject loader */}
                <SkeletonText mt="8" noOfLines={3} spacing="1.5" skeletonHeight="2.5" className="w-full" />

                {/* Message loader */}
                <SkeletonText mt="4" noOfLines={20} spacing="1.5" skeletonHeight="2" className="w-full" />
              </div>
            ) : (
              <>
                <div className={`${viewSize === '4xl' && 'h-[30rem]'} overflow-auto p-5`}>
                  {emailData?.profile?.message?.map((item: any, index: number) => (
                    <>
                      {item?.platform_message_details?.from?.address ? (
                        <div className="border-t py-4" key={item?.uuid}>
                          <div className="flex justify-between" onClick={() => toggleIndexInArray(index)}>
                            <div className="flex gap-3">
                              <Avatar className="border" style={{ height: '33px', width: '33px' }} name={'G'} bg="#ece7ff" color={'white'} />
                              <div>
                                <p className="text-sm font-medium text-heading">
                                  {item?.platform_message_details?.from?.name || item?.platform_message_details?.from?.address.split('@')[0]}
                                </p>
                                <div className="flex justify-start">
                                  <Menu>
                                    <MenuButton className="!w-[10rem] truncate rounded-lg !text-xs !font-medium !text-heading  " onClick={(e) => e.stopPropagation()}>
                                      <div className="flex py-1">
                                        <span className="truncate">
                                          to -{' '}
                                          {!item?.platform_message_details?.to?.length
                                            ? item?.platform_message_details?.to?.name || item?.platform_message_details?.to?.address.split('@')[0]
                                            : item?.platform_message_details?.to?.map((val: { name: string }) => val.name).join(',')}
                                        </span>
                                        <FaCaretDown className="size-4 !text-[#2B3674]" />
                                      </div>
                                    </MenuButton>
                                    <MenuList minW="0" className="!h-min-[9rem] !w-[24rem] truncate !rounded-lg border border-blueSecondary bg-white !p-0 px-2 shadow-lg  ">
                                      <div className="flex flex-col gap-4 p-4">
                                        <div className="group/from flex w-full items-start  gap-8 text-wrap text-sm font-medium text-heading">
                                          <p className=" text-sm  text-column ">From:</p>{' '}
                                          <p className="!overflow-hidden text-ellipsis whitespace-nowrap">
                                            {item?.platform_message_details?.from?.name || item?.platform_message_details?.from?.address.split('@')[0]}{' '}
                                            {`<${item?.platform_message_details?.from?.address}>`}
                                          </p>
                                          {/* <ArrowedTooltip
                                            className={` ml-8 mt-4 hidden  !w-auto group-hover/from:block `}
                                            toolTipId={`row-from-email`}
                                            data={item?.platform_message_details?.from?.address}
                                            position="top"
                                          /> */}
                                        </div>
                                        <div className="group/to  flex items-start gap-12 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-heading">
                                          <span className="text-sm text-column">To:</span>
                                          <div className="flex flex-wrap gap-2">
                                            {item?.platform_message_details?.to?.length ? (
                                              item.platform_message_details?.to?.map((toAdd: { name: string; address: string }, index: number) => (
                                                <span key={index} className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                                                  {toAdd.name} {`<${toAdd.address}>`}
                                                </span>
                                              ))
                                            ) : (
                                              <span className="max-w-72 overflow-hidden text-ellipsis whitespace-nowrap">{`${item?.platform_message_details?.to?.name} <${item?.platform_message_details?.to?.address}>`}</span>
                                            )}
                                          </div>
                                          {/* <ArrowedTooltip
                                            className={` ml-8 absolute hidden  !w-auto group-hover/to:block `}
                                            toolTipId={`row-from-email`}
                                            data={
                                              item?.platform_message_details?.to?.length
                                                ? item.platform_message_details?.to?.map((toAdd: { name: string; address: string }) => toAdd.address)
                                                : item?.platform_message_details?.to?.address
                                            }
                                            position="top"
                                          /> */}
                                        </div>
                                        {item?.platform_message_details?.cc ? (
                                          <div className="flex items-start gap-12 overflow-hidden text-sm font-medium text-heading">
                                            <span className="text-sm text-column">Cc:</span>
                                            <div className="flex flex-wrap gap-2">
                                              {item?.platform_message_details?.cc?.length > 1 ? (
                                                item.platform_message_details?.cc?.map((toAdd: { name: string; address: string }, index: number) => (
                                                  <span key={index} className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {toAdd.name} {`<${toAdd.address}>`}
                                                  </span>
                                                ))
                                              ) : (
                                                <span className="max-w-72 overflow-hidden text-ellipsis whitespace-nowrap">{`${item?.platform_message_details?.cc?.name} <${item?.platform_message_details?.cc?.address}>`}</span>
                                              )}
                                            </div>
                                          </div>
                                        ) : null}
                                        <p className="flex items-start gap-4 overflow-ellipsis whitespace-nowrap text-wrap text-sm font-medium text-heading ">
                                          <span className="max-w-72 overflow-ellipsis whitespace-nowrap text-sm text-column">Subject:</span>
                                          {item?.platform_message_details?.subject}
                                        </p>
                                      </div>
                                    </MenuList>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center gap-3">
                              {(roleStatus?.edit || roleStatus?.is_owner) && (
                                <>
                                  <span className="group/reply-text relative">
                                    <LuReply
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleReply(item?.platform_message_details, item?.sender_id);
                                      }}
                                      className={`cursor-pointer text-base text-column chat-message-reply-${index}`}
                                    />
                                    <ArrowedTooltip className={` hidden !w-14 group-hover/reply-text:block  `} toolTipId={`row-cdate-tooltip-2`} data={'Reply'} position="top" />
                                  </span>
                                  <span className="group/replyall-text relative">
                                    <LuReplyAll
                                      className="cursor-pointer text-base text-column"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleReplyAll(item?.platform_message_details, item?.sender_id);
                                      }}
                                    />
                                    <ArrowedTooltip
                                      className={` hidden !w-14 group-hover/replyall-text:block `}
                                      toolTipId={`row-cdate-tooltip-2`}
                                      data={'Reply All'}
                                      position="top"
                                    />
                                  </span>
                                  <span className="group/forward-text relative">
                                    <LuForward
                                      className={`cursor-pointer text-base text-column chat-message-forward-${index}`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleForward(item?.platform_message_details, item?.sender_id, item);
                                      }}
                                    />
                                    <ArrowedTooltip className={`hidden !w-14 group-hover/forward-text:block `} toolTipId={`row-cdate-tooltip-2`} data={'Forward'} position="top" />
                                  </span>
                                </>
                              )}
                              <p className="text-xs">{moment(item?.platform_message_details?.date).format('MMM DD, hh:mm A')}</p>
                            </div>
                          </div>

                          <div className="pt-5">
                            <p className=" text-sm text-heading" dangerouslySetInnerHTML={{ __html: item?.fullMessage }} />
                            {item?.attachment?.length ? (
                              <div className="mx-3 my-4 mt-1 rounded-md border">
                                <div className="bg-headerbg rounded-md px-3 py-1 text-[.6875rem] text-column">Attachment</div>
                                <div className="flex flex-wrap border-t p-3">
                                  {/* files */}
                                  {item?.attachment?.map((attachment: any, index: number) => {
                                    const formatBytes = (bytes: number) => {
                                      if (bytes < 1024) return `${bytes} Bytes`;
                                      let kb = bytes / 1024;
                                      if (kb < 1024) return `${kb.toFixed(3)} KB`;
                                      let mb = kb / 1024;
                                      if (mb < 1024) return `${mb.toFixed(3)} MB`;
                                      let gb = mb / 1024;
                                      return `${gb.toFixed(3)} GB`;
                                    };
                                    const size = formatBytes(attachment?.size);

                                    return (
                                      <div className="mt-2 flex w-1/2 gap-2" key={`${index}_attachment`}>
                                        <div className="h-fit rounded-full bg-hover p-2 text-sm text-blueSecondary">
                                          <CgFile />
                                        </div>
                                        <div className="flex flex-col gap-1 pt-1">
                                          <span className="text-xs font-medium">{attachment?.filename}</span>
                                          <div className="flex text-[.6875rem]">
                                            <span>{size} -</span>
                                            <span
                                              className="cursor-pointer pl-1 pr-1.5 text-blueSecondary"
                                              onClick={(e) => {
                                                e?.stopPropagation();
                                                downloadFile(attachment);
                                              }}
                                            >
                                              Download
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                  {isTyperOpen && (
                    <div ref={typerRef}>
                      <Typer
                        onTyperClose={() => {
                          setisTyperOpen(false);
                          // onClose();
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EmailFullView;
