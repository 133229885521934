

const LinkedinAccountsList = () => {
  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex items-center gap-2">
            Hai linkedin
          </div>
        </div></div>
  )
}

export default LinkedinAccountsList