import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import { checkRolePermissionStatus, toastState } from 'common/utils/utility';
import DeleteConfirmModal from 'components/crm/DeleteConfrimModal';
import EditorPopup from 'components/crm/EditorPopup';
import { FaPlus, FaRegCircleCheck } from 'react-icons/fa6';
import { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdModeEdit } from 'react-icons/md';
import { RiArrowUpDownFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store';
import { FaCircleCheck } from 'react-icons/fa6';
import TaskCreate from './TaskCreate';
import { AZ_BUCKET } from 'common/constants/constants';

interface modalTypeDTO {
  isOpen: boolean;
  uuid?: string;
}
const Tasks = () => {
  const { opportunityInboxViewData } = useAppSelector((state) => state.crm);
  const { opportunity_id } = opportunityInboxViewData;
  const { loggedUser } = useAppSelector((state) => state.auth);
  const [openCreateModal, setOpenCreateModal] = useState<modalTypeDTO>({ isOpen: false, uuid: '' });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({ uuid: '', isOpen: false });
  const [assigneeList, setAssigneeList] = useState([]);
  const [taskData, setTaskData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState({ title: '', desc: '', due: 'Select due date', reminder: 'No reminder', assingee: 'Select Assingnee', assignee_id: '', uuid: '' });
  const [rowIndex, setRowIndex] = useState([]);

  const [sort, setSort] = useState({ text: 'New-Old', query: '_id%dsc', search: '' });
  const [roleStatus, setRoleStatus] = useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const custom = useAppSelector((state) => state?.custom);

  useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'crm');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  const fetchTasksData = async () => {
    try {
      if (opportunity_id) {
        const response = await BaseApi.get(`/crm/task/${opportunity_id}?sort=${sort?.query}&search=${sort?.search}`);
        if (response?.error) {
          toast.error('Failed to fetch Tasks');
        }
        setTaskData(response?.data);
      }
    } catch (error) {
      toast.error('Failed to fetch Tasks');
      console.log(error);
    }
  };

  const handleTasksDelete = async (id: string) => {
    if (id) {
      try {
        const response = await BaseApi.delete(`/crm/task/`, { data: { delete_ids: [id] } });
        if (response.error) {
          toast.error(response.message);
        } else {
          toast.success('Task deleted successfully', toastState?.success);
        }
      } catch (error) {
        console.error('Error deleting Task:', error);
        toast.error('Failed to delete Task');
      }
      setIsDeleteModalOpen({ uuid: '', isOpen: false });
    }
  };

  const formateDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleTasksChange = (value: string) => {
    const checkValue = stripHtmlTags(value);
    if (checkValue) {
      setTask((prev) => ({ title: prev.title, desc: value, due: prev.due, reminder: prev.reminder, assingee: prev.assingee, assignee_id: prev.assignee_id, uuid: prev.uuid }));
    }
  };

  const stripHtmlTags = (html: string) => {
    // return html.replace(/<[^>]*>?/gm, '');
    return html.replace(/&nbsp;/g, ' ').replace(/<[^>]*>?/gm, '');
  };
  const handleOnclickTaskCreate = async () => {
    setIsLoading(true);
    if (opportunity_id) {
      try {
        const payload = {
          opportunity_id,
          task_heading: task.title,
          task_description: task.desc,
          due_date: task.due,
          reminder: task.reminder,
          assigned_to: task.assignee_id,
          assingee: task.assingee,
        };
        const response = await BaseApi.post(`/crm/task`, payload);
        if (response.error) {
          toast.error(response.message);
        } else {
          fetchTasksData();
          toast.success('Task created successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error creating Task:', error);
        toast.error('Failed to create Task');
      } finally {
        setIsLoading(false);
        setOpenCreateModal({ isOpen: false, uuid: '' });
        setTask({ title: '', desc: '', due: 'Select due date', reminder: 'No reminder', assingee: 'Select Assingnee', assignee_id: '', uuid: '' });
      }
    }
  };

  const resetSatates = () => {
    setTask({ title: '', desc: '', due: 'Select due date', reminder: 'No reminder', assingee: 'Select Assingnee', assignee_id: '', uuid: '' });
  };
  const handleOnclickTaskEdit = async (uuid: string) => {
    if (uuid) {
      try {
        const payload = {
          uuid,
          opportunity_id,
          task_heading: task.title,
          task_description: task.desc,
          due_date: task.due,
          reminder: task.reminder,
          assigned_to: task.assignee_id,
        };
        const response = await BaseApi.patch(`/crm/task`, payload);
        if (response.error) {
          toast.error(response.message);
        } else {
          fetchTasksData();
          toast.success('Task created successfully', toastState.success);
        }
      } catch (error) {
        console.error('Error creating Task:', error);
        toast.error('Failed to create Task');
      } finally {
        setIsLoading(false);
        setOpenCreateModal({ isOpen: false, uuid });
      }
    }
  };

  const handleChangeTitle = async (value: string) => {
    setTask((prev) => ({ title: value, desc: prev.desc, due: prev.due, reminder: prev.reminder, assingee: prev.assingee, assignee_id: prev.assignee_id, uuid: prev.uuid }));
  };

  const handleAssigneeSelect = (value: any) => {
    setTask((prev) => ({ title: prev.title, desc: prev.desc, due: prev.due, reminder: prev.reminder, assingee: value?.name, assignee_id: value?.uuid, uuid: prev.uuid }));
  };
  const handleDueSelect = (value: string) => {
    setTask((prev) => ({ title: prev.title, desc: prev.desc, due: value, reminder: prev.reminder, assingee: prev.assingee, assignee_id: prev.assignee_id, uuid: prev.uuid }));
  };
  const handleReminderSelect = (value: string) => {
    setTask((prev) => ({ title: prev.title, desc: prev.desc, due: prev.due, reminder: value, assingee: prev.assingee, assignee_id: prev.assignee_id, uuid: prev.uuid }));
  };

  const fetchUsersList = async () => {
    try {
      const response = await BaseApi.get(`/workspace/${loggedUser?.workspace_id}`);
      if (response?.error) {
        toast.error('Failed to fetch Tasks');
      }
      setAssigneeList(response?.data?.user_list);
    } catch (error) {
      toast.error('Failed to fetch Tasks');
      console.log(error);
    }
  };

  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        return [...prevArr, index];
      }
    });
  };
  useEffect(() => {
    if (openCreateModal.isOpen) {
      fetchUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCreateModal.isOpen]);
  useEffect(() => {
    fetchTasksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteModalOpen?.isOpen, sort, sort.search, opportunity_id]);
  return (
    <div>
      <div>
        <header className="z-10 mt-[1px] flex justify-between border bg-white p-3">
          <div className="flex items-center justify-start gap-3 text-base font-bold">Tasks</div>
          {}
          <div className="flex gap-4">
            {roleStatus?.add ||
              (roleStatus?.is_owner && <Button name="Create Task" id="create-task-btn" LeftIcon={<FaPlus />} onClick={() => setOpenCreateModal({ isOpen: true })} />)}
            <div
              className="flex cursor-pointer items-center gap-1.5"
              onClick={() =>
                setSort((prev) =>
                  prev.query === '_id%asc' ? { text: 'New-Old', query: '_id%dsc', search: prev.search } : { text: 'Old-New', query: '_id%asc', search: prev.search }
                )
              }
            >
              <RiArrowUpDownFill className="size-4 text-column" />
              <h5 className="text-[0.8rem] text-column">{sort.text}</h5>
            </div>
          </div>
        </header>
        {!taskData?.upcomingTasks?.length && !taskData?.completedTasks?.length ? (
          <figure className="mt-10 flex flex-col items-center justify-center">
            <img src={AZ_BUCKET?.SPARKLE_ILLUSTRATION} alt="not found" />
            <figcaption className="mt-3 font-bold text-heading">Tasks you add appear here</figcaption>
          </figure>
        ) : (
          <main className="h-[32rem] space-y-4 overflow-auto p-4">
            {/* TimeStamp */}
            <div className="mt-1 flex items-center justify-center gap-2">
              <hr className="border-t-[#EAECF0 w-full" />
              <p className="text-nowrap text-center text-xs text-column">Upcomming Tasks</p>
              <hr className="w-full border-t-[#EAECF0]" />
            </div>
            {/* Lead generation */}
            {taskData?.upcomingTasks?.map((data: any, index: any) => (
              <div className="flex flex-col gap-4 rounded-md border border-[#EAECF0] py-2" key={data?.uuid} id={`task-${index}`} aria-valuetext={opportunity_id}>
                <div className="flex items-start justify-between">
                  <div className="flex flex-col ">
                    <div className="flex items-center justify-start">
                      {rowIndex.includes(data?.uuid) ? (
                        <BiChevronDown className="size-7 cursor-pointer items-start text-[#A3AED0]" onClick={() => toggleIndexInArray(data.uuid)} />
                      ) : (
                        <BiChevronRight className="size-7 cursor-pointer text-[#A3AED0]" onClick={() => toggleIndexInArray(data.uuid)} />
                      )}
                      <h5 className="text-[0.93rem] font-semibold text-heading">
                        <span className="font-bold">Tasks</span> <span className="font-thin">assigned to</span> {data?.assignee?.first_name} {data?.assignee?.last_name}
                      </h5>
                    </div>
                    <p className="mt-2 flex items-center gap-2 truncate px-3 text-[0.8rem] font-bold text-[#535c8e] ">
                      <FaRegCircleCheck className=" size-4 text-[#4318FF]" />
                      {data.task_heading}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <h5 className="text-[0.6rem] font-medium text-column">Due at {formateDate(data?.due_date)}</h5>
                    {(roleStatus?.edit || roleStatus?.delete || roleStatus?.is_owner) && (
                      <Menu>
                        <MenuButton _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className={`bg-white !px-2 !text-base !font-semibold task-menu-btn-${index} `}>
                          <BsThreeDotsVertical className="text-sm text-[#8F9BBA]" />
                        </MenuButton>
                        <MenuList minW="0" w={'122px'} className="!h-[80px] !w-[122px] !rounded-lg bg-white !p-0 shadow-lg  " style={{ width: '120px', height: '109px' }}>
                          {(roleStatus?.edit || roleStatus?.is_owner) && (
                            <MenuItem
                              onClick={() => {
                                setTask({
                                  title: data.task_heading,
                                  desc: data?.task_description,
                                  due: data.due_date,
                                  reminder: data.reminder,
                                  assingee: data?.assignee?.first_name + data?.assignee?.first_name,
                                  assignee_id: data?.assignee?.uuid,
                                  uuid: data.uuid,
                                });
                                setOpenCreateModal({ isOpen: true, uuid: data.uuid });
                              }}
                              className={`task-edit-btn flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white`}
                            >
                              <MdModeEdit className="text-lg" />
                              <span>Edit</span>
                            </MenuItem>
                          )}

                          {(roleStatus?.delete || roleStatus?.is_owner) && (
                            <MenuItem
                              onClick={() => setIsDeleteModalOpen({ uuid: data.uuid, isOpen: true })}
                              className={`task-delete-btn flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white`}
                            >
                              <AiFillDelete className="text-lg" />
                              <span>Delete</span>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </div>
                </div>
                {rowIndex.includes(data?.uuid) ? (
                  <div>
                    <div className="mx-3 flex border-y border-[#EDEFF1] py-2  ">
                      <div className="flex w-1/2 flex-col gap-2">
                        <p className="text-[0.6rem] font-medium text-heading">Due Date</p>
                        <p className="text-[0.7rem] font-bold text-blueSecondary ">{formateDate(data?.due_date)}</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-[0.6rem] font-medium text-heading">Reminder</p>
                        <p className="text-[0.7rem] font-bold text-blueSecondary ">{data?.reminder}</p>
                      </div>
                    </div>
                    <div className="relative mx-3 w-[38rem] py-2 text-justify">
                      <p className=" text-[0.8rem] font-medium leading-4 text-heading">{stripHtmlTags(data?.task_description)}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}

            {taskData?.completedTasks?.length ? (
              <div className="mt-1 flex items-center justify-center gap-2">
                <hr className="border-t-[#EAECF0 w-full" />
                <p className="text-nowrap text-center text-xs text-column">Completed Tasks</p>
                <hr className="w-full border-t-[#EAECF0]" />
              </div>
            ) : null}

            {/* Lead generation */}
            {taskData?.completedTasks?.map((data: any) => (
              <div className="flex flex-col gap-4 rounded-md border border-[#EAECF0] py-2" key={data?.uuid}>
                <div className="flex items-start justify-between">
                  <div className="flex flex-col ">
                    <div className="flex items-center justify-start">
                      {rowIndex.includes(data?.uuid) ? (
                        <BiChevronDown className="size-7 cursor-pointer items-start text-[#A3AED0]" onClick={() => toggleIndexInArray(data.uuid)} />
                      ) : (
                        <BiChevronRight className="size-7 cursor-pointer text-[#A3AED0]" onClick={() => toggleIndexInArray(data.uuid)} />
                      )}
                      <h5 className="text-[0.93rem] font-semibold text-heading">
                        <span className="font-bold">Tasks</span> <span className="font-thin">assigned to</span> {data?.assignee?.first_name} {data?.assignee?.last_name}
                      </h5>
                    </div>
                    <p className="mt-2 flex items-center gap-2 truncate px-3 text-[0.8rem] font-bold text-[#535c8e] ">
                      <FaCircleCheck className=" size-4 text-[#4318FF]" />
                      {data.task_heading}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <h5 className="text-[0.6rem] font-medium text-column">Due at {formateDate(data?.due_date)}</h5>
                    {(roleStatus?.edit || roleStatus?.delete || roleStatus?.is_owner) && (
                      <Menu>
                        <MenuButton _hover={{ bg: '#fff' }} _active={{ bg: '#fff' }} bg="#fff" className="bg-white !px-2 !text-base !font-semibold  ">
                          <BsThreeDotsVertical className="text-sm text-[#8F9BBA]" />
                        </MenuButton>
                        <MenuList minW="0" w={'122px'} className="!h-[80px] !w-[122px] !rounded-lg bg-white !p-0 shadow-lg  " style={{ width: '120px', height: '109px' }}>
                          {(roleStatus?.edit || roleStatus?.is_owner) && (
                            <MenuItem
                              onClick={() => {
                                setOpenCreateModal({ isOpen: true, uuid: data.uuid });
                                setTask((prev) => ({
                                  title: prev.title,
                                  desc: data.task_description,
                                  due: prev.due,
                                  reminder: prev.reminder,
                                  assingee: prev.assingee,
                                  assignee_id: prev.assignee_id,
                                  uuid: prev.uuid,
                                }));
                              }}
                              className="flex items-center space-x-2 rounded-t-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-t-lg hover:!bg-[#4318FF] hover:!text-white "
                            >
                              <MdModeEdit className="text-lg" />
                              <span>Edit</span>
                            </MenuItem>
                          )}
                          {(roleStatus?.delete || roleStatus?.is_owner) && (
                            <MenuItem
                              onClick={() => setIsDeleteModalOpen({ uuid: data.uuid, isOpen: true })}
                              className="flex items-center space-x-2 rounded-b-lg !py-2 !pe-2 !ps-4 text-sm !text-[#2B3674] hover:rounded-b-lg hover:!bg-[#4318FF] hover:!text-white"
                            >
                              <AiFillDelete className="text-lg" />
                              <span>Delete</span>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </div>
                </div>
                {rowIndex.includes(data?.uuid) ? (
                  <div>
                    <div className="mx-3 flex border-y border-[#EDEFF1] py-2  ">
                      <div className="flex w-1/2 flex-col gap-2">
                        <p className="text-[0.6rem] font-medium text-heading">Due Date</p>
                        <p className="text-[0.7rem] font-bold text-blueSecondary ">{formateDate(data?.due_date)}</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-[0.6rem] font-medium text-heading">Reminder</p>
                        <p className="text-[0.7rem] font-bold text-blueSecondary ">{data?.reminder}</p>
                      </div>
                    </div>
                    <div className="relative mx-3 w-[38rem] py-2 text-justify">
                      <p className=" whitespace-pre text-[0.8rem] font-medium leading-4 text-heading">{stripHtmlTags(data?.task_description)}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </main>
        )}
      </div>
      <DeleteConfirmModal
        isOpen={isDeleteModalOpen.isOpen}
        onClose={() => {
          setIsDeleteModalOpen({ uuid: '', isOpen: false });
        }}
        handleDelete={() => handleTasksDelete(isDeleteModalOpen.uuid)}
      />
      {openCreateModal?.uuid ? (
        <EditorPopup
          handleMessageOnChange={handleTasksChange}
          title="Edit Tasks"
          isOpen={openCreateModal.isOpen}
          onClose={() => {
            setOpenCreateModal({ isOpen: false, uuid: '' });
            resetSatates();
          }}
          message={task.desc}
          isLoading={isLoading}
          disableCondition={task.due === 'Select due date' || task.assingee === 'Select Assingnee' || task.reminder === 'No reminder' ? true : false}
          submitText="Apply changes"
          onSubmit={() => handleOnclickTaskEdit(openCreateModal.uuid)}
          TopNode={
            <TaskCreate
              handleChangeTitle={handleChangeTitle}
              taskData={task}
              handleAssigneeSelect={handleAssigneeSelect}
              handleReminderSelect={handleReminderSelect}
              handleDueSelect={handleDueSelect}
              assigneeList={assigneeList}
            />
          }
        />
      ) : (
        <EditorPopup
          editorClass={`create-editor`}
          handleMessageOnChange={handleTasksChange}
          title="Create Tasks"
          isOpen={openCreateModal.isOpen}
          onClose={() => {
            setOpenCreateModal({ isOpen: false, uuid: '' });
            resetSatates();
          }}
          isLoading={isLoading}
          submitText="Create Task"
          message={task?.desc}
          disableCondition={task.due === 'Select due date' || task.assingee === 'Select Assingnee' || task.reminder === 'No reminder' || task.title === '' ? true : false}
          onSubmit={() => handleOnclickTaskCreate()}
          TopNode={
            <TaskCreate
              handleChangeTitle={handleChangeTitle}
              taskData={task}
              handleAssigneeSelect={handleAssigneeSelect}
              handleReminderSelect={handleReminderSelect}
              handleDueSelect={handleDueSelect}
              assigneeList={assigneeList}
            />
          }
        />
      )}
    </div>
  );
};

export default Tasks;
