import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import { useState } from 'react';
import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { IoWarningOutline } from 'react-icons/io5';
import ConfrimationPopup from './ConfrimationPopup';
// import { useNavigate } from 'react-router-dom';

interface ModalPropsDTO {
  isInstructionOpen?: boolean;
  onClose?: any;
}

const AccountDeligationPopup = (props: ModalPropsDTO) => {
  //   const [account_type, setSelectedAccountType] = useState(1);
  const { isInstructionOpen, onClose } = props;
  const [confrimopen, setConfrimOpen] = useState(false);

  return (
    <div>
      <Modal isCentered isOpen={isInstructionOpen} onClose={() => onClose()} size={'3xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="!p-0">
          <ModalHeader>
            <div className="flex flex-col">
              <div className="flex items-center text-lg font-semibold text-red-700">
                <IoWarningOutline className="mr-1 inline-block" />
                <span>Instruction</span>
              </div>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <ModalBody className="bg-modalbg !p-0">
            <div className="flex items-center justify-center">
              <div className="w-[44rem] px-6  py-6 shadow-md">
                <div className="h-96  gap-5 overflow-auto rounded-md bg-white px-3 py-2">
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Steps to Add Scopes in Google Admin Console (For Admin Accounts)
                  </Text>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Access the Google Admin Console:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      Visit the{' '}
                      <Link href="https://admin.google.com/" color="blue.500" isExternal>
                        Google Admin Console
                      </Link>
                      .
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Navigate to Security Settings:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      Go to <strong>Security &gt; Access and data control &gt; API controls</strong>.
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Manage Domain-Wide Delegation:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      Click <strong>Manage domain-wide delegation</strong>.
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Add a New Client:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      Click <strong>Add New</strong>.
                    </ListItem>
                    <ListItem>
                      In the <strong>Client ID</strong> field, enter the Client ID from the service account JSON file.
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Specify OAuth Scopes:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      In the OAuth scopes field, enter the following scopes (separate by commas):
                      <Box mt={2} p={2} bg="gray.100" borderRadius="md">
                        <code>
                          https://www.googleapis.com/auth/admin.directory.user.alias,
                          <br />
                          https://www.googleapis.com/auth/gmail.settings.sharing,
                          <br />
                          https://www.googleapis.com/auth/gmail.settings.basic,
                          <br />
                          https://www.googleapis.com/auth/gmail.send,
                          <br />
                          https://www.googleapis.com/auth/admin.directory.user
                        </code>
                      </Box>
                    </ListItem>
                  </UnorderedList>

                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Authorize:
                  </Text>
                  <UnorderedList mb={4}>
                    <ListItem>
                      Click <strong>Authorize</strong> to grant access to the specified scopes.
                    </ListItem>
                  </UnorderedList>
                </div>
                <div className="mt-6 flex gap-4">
                  <Button
                    name="Continue"
                    customClass="w-full bg-white border !text-white !font-semibold text-[.82rem]  !bg-blueSecondary"
                    onClick={() => {
                      onClose();
                      setConfrimOpen(true);
                    }}
                  />
                  <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={() => onClose()} />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ConfrimationPopup isOpen={confrimopen} onClose={() => setConfrimOpen(false)} />
    </div>
  );
};

export default AccountDeligationPopup;
