import React from 'react';
import { BaseApi } from 'common/api/common/base-api';

const EmailTrackOpen: React.FC = () => {
    React.useEffect(() => {
        const trackEmailOpen = async () => {
            try {
                const data = await BaseApi.get('/test/open/track')
                console.log(data)
            } catch (error) {
                console.error('Error tracking email open:', error);
            }
        };
        trackEmailOpen();
    }, []);

    return (
        <div>
            <h1>React Email</h1>
        </div>
    );
};

export default EmailTrackOpen;
