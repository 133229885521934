import { AiOutlineSearch } from 'react-icons/ai';
import { IoCloudUploadOutline } from 'react-icons/io5';

import Button from 'common/sparkle-common/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { setHeaderCount, setProspects } from 'store/campaign-v2';
import { setCurrentStepCount, setTotalStepCount } from 'store/custom';
import { restrictionPlan } from 'common/utils/utility';

const InitialModal = () => {
  const dispatch = useAppDispatch();
  const { prospect_type } = useAppSelector((state) => state.campaignv2.prospects);
  const userType = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);

  //   Handle prospect select
  const handleSelect = (count: number) => {
    const prospects_flow = count === 1 ? 'manual_upload' : 'search_prospects';
    dispatch(setProspects({ prospect_type: count, prospects_flow }));
  };

  //   Handle continue button
  const handleContinue = () => {
    console.log('prospect_type', prospect_type);
    if (prospect_type === 2) {
      const restriction = restrictionPlan('enrichment', userType);
    console.log('restriction', restriction);

      if (!restriction) return;
    }
    dispatch(setCurrentStepCount(2));
  };

  // Handle back button
  const handleBack = () => {
    dispatch(setCurrentStepCount(1));
    dispatch(setTotalStepCount(1));
    dispatch(setHeaderCount(1));
  };

  return (
    <div className="flex h-[80vh] items-center justify-center">
      <div className="w-[45.8125rem] rounded-md bg-white p-5 shadow-md dark:border dark:border-darkBorder dark:bg-darkThree">
        <div className="flex flex-col">
          <h4 className="text-sm font-semibold text-heading dark:text-white">Prospect upload</h4>
          <span className="text-[11px] text-column dark:text-white">Select how you want to create your prospect list</span>
        </div>
        <div className="mt-5 flex h-36 select-none gap-4">
          <div
            id="upload_csv_section"
            className={`flex w-full cursor-pointer flex-col items-center justify-center rounded-md border ${
              prospect_type === 1 ? 'border-blueSecondary bg-bgInside dark:bg-gray-50' : 'dark:border-darkBorder dark:bg-darkTwo'
            }`}
            onClick={() => handleSelect(1)}
            onDoubleClick={handleContinue}
          >
            <IoCloudUploadOutline className="mt-2 text-[3.125rem] text-darkBlue dark:text-gray-400" />
            <h4 className={`${prospect_type === 1 ? 'text-heading' : 'text-grayish-blue dark:text-white'} mt-1 text-[.8125rem] font-semibold`}>Upload CSV</h4>
            <span className={`${prospect_type === 1 ? 'text-heading' : 'text-grayish-blue dark:text-white'} text-center text-[.6875rem] font-medium`}>
              Import your prospect list from a CSV file
            </span>
          </div>

          <div
            className={`flex w-full  cursor-pointer flex-col items-center justify-center rounded-md border ${
              prospect_type === 2 ? 'border-blueSecondary bg-bgInside dark:bg-gray-50' : 'dark:border-darkBorder dark:bg-darkThree'
            }`}
            onClick={() => handleSelect(2)}
            onDoubleClick={handleContinue}
          >
            <div className="mt-4 rounded-full bg-paused-bg p-2 dark:bg-gray-400">
              <AiOutlineSearch className="text-[1.5rem] text-heading " />
            </div>
            <h4 className={`${prospect_type === 2 ? 'text-heading' : 'text-grayish-blue dark:text-white'} mt-2 text-[.8125rem] font-bold`}>Search Prospects</h4>
            <span className={`${prospect_type === 2 ? 'text-heading' : 'text-grayish-blue dark:text-white'} w-10/12 text-center text-[.6875rem] font-medium`}>
              Find prospects in our database or via third-party tools
            </span>
          </div>
        </div>
        <div className="mt-6 flex gap-4">
          <Button name="Back" customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={handleBack} />
          <Button
            id="prospect_upload_continue_btn"
            name="Continue"
            customClass="w-full text-[.82rem] dark:bg-purple-800 dark:border-none dark:text-white"
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default InitialModal;
