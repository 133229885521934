import { Spinner } from '@chakra-ui/react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { BaseApi } from 'common/api/common/base-api';
import { LocalStorage } from 'common/api/common/storage';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { cryptoDecrypt, jwtEncrypt } from 'common/utils/utility';
import { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setLoggedUserDetails, setLoggedUserRolePermissionList } from 'store/authentication';
import { setGlobalLoading } from 'store/custom';

const SignupWithGoogle = ({ text }: any) => {
  const [loading, setLoading] = useState(false);
  const { environment } = useAppSelector((state) => state.globalsettings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const store = useAppSelector((state) => state?.auth?.loggedUser);
  useEffect(() => {
    if (store && Object.keys(store).length && store?.uuid) {
      navigate(environment?.app_default_feature?.base_url);
    }
  }, [environment?.app_default_feature?.base_url, navigate, store]);

  const continueWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${response.access_token}` },
      });
      const data = await BaseApi.post('/auth/google/signup', { credential: jwtEncrypt(userInfo?.data) });
      if (!data?.error) {
        dispatch(setGlobalLoading(true));
      }
      LocalStorage.setString(LOGIN_STORAGE_VAR, data?.data);
      const loginData = await cryptoDecrypt(data?.data, true);
      // console.log("g-login",loginData)
      dispatch(
        setLoggedUserDetails({
          id: loginData?.id,
          uuid: loginData?.uuid,
          is_admin_account: loginData?.is_admin_account,
          first_name: loginData?.first_name,
          last_name: loginData?.last_name,
          email: loginData?.email,
          is_google: loginData?.is_google,
          organization_id: loginData?.organization_id,
          workspace_id: loginData?.workspace_id,
          organization_name: loginData?.organization_name,
          workspace_name: loginData?.workspace_name,
          authToken: loginData?.authToken,
        })
      );
      dispatch(setLoggedUserRolePermissionList(loginData?.role_permission_list));
      setLoading(false);
      // dispatch(setLoggedUserDetails(jwtDecrypt(data?.data)));
    },
    // flow: 'auth-code',
  });
  // const check = async () => {
  //     const userInfo = await axios
  //         .get('https://www.googleapis.com/oauth2/v3/userinfo',
  //             {
  //                 headers: { Authorization: `Bearer ya29.a0AfB_byDFvCfU5HfBiedJ9GssWPN4GBMsvbvndVVqWxhSL99DwSw0QbZqmD2WksqwF4Nik0qiQrAvcS1rwAGen-gnCaubFnDwSHACx6JrcKuGTHIWUKFmom6k9pfp_QV-oHVCVVoIoF6kqrp0nbk9q0k7H_TlEFmjvlHjaCgYKAYcSARMSFQHGX2MiC1xnx9Lks5Hw2bHbO_MqKg0171` },
  //             })
  //     const result = userInfo;
  //     console.log('result', result)
  // }
  // check()
  //
  // const handleLogin = async (response: any) => {
  //     try {
  //         const data = await BaseApi.post('/auth/google/signup', { credential: response?.credential });
  //         SessionStorage.setString(LOGIN_STORAGE_VAR, data?.data);
  //         dispatch(setLoggedUserDetails(jwtDecrypt(data?.data)));
  //     } catch (error) {
  //         console.error(error)
  //     }
  // };
  return (
    <div
      onClick={() => continueWithGoogle()}
      className={`mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800 dark:text-white ${
        loading && 'opacity-75'
      }`}
    >
      {/* <GoogleOAuthProvider clientId="678556597651-5v1hp8u2vlm82db3ggfl220n29r4c50h.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={(res) => handleLogin(res)}
                    type="standard"
                    theme="outline"
                    size="large"
                    text={text}
                    shape="rectangular"
                    logo_alignment="center"
                    width={'1000'}
                    useOneTap={false}
                />
            </GoogleOAuthProvider> */}
      <div className="rounded-full text-xl">
        <FcGoogle />
      </div>
      <p className="text-sm font-medium text-navy-700 dark:text-white">{text}</p>
      {loading && <Spinner className="absolute" thickness="3px" speed="0.65s" color="gray.400" size="md" />}
    </div>
  );
};

export default SignupWithGoogle;
