/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useState } from 'react';

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';

// import { setEmailCampaignDraft } from "store/campaign";
import { useAppDispatch, useAppSelector } from 'store';
import { setFlagStatus, setGlobalOpt } from 'store/custom';

import { EDIT_ID } from 'common/constants/constants';
import { SessionStorage } from 'common/api/common/storage';
import { jwtDecrypt, jwtEncrypt, setAndGetQueryParams, useDebounce } from 'common/utils/utility';

import { MdAdd, MdCloudQueue, MdLink, MdLinkOff, MdViewWeek } from 'react-icons/md';
import Toggle from 'components/Toggle';
import { setCampaignSelectedCSVData } from 'store/campaign';
import CustomFilter from 'components/customFilter';
import { OptionDataType } from 'common/dto/types/components/customFilter';
// import EmailRowSettings from './features/email-list/EmailRowSettings';
import { Avatar } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
// import EmailGroupTags from './features/email-list/EmailGroupTags';
import { EmailAccountListRowObject } from 'common/dto/types/views/email-accounts';
// import EmailEditSettings from './features/email-edit-settings';
import { setEmailEditDrawer } from 'store/email-accounts';
import EmailAreaChart from './features/email-edit-settings/EmailAreaChart';
import EmailStatsData from './features/email-edit-settings/EmailStats';
import AddEmailAccountsDrawerLayout from './features/email-add';
import EmailGroupTags from './features/email-list/EmailGroupTags';
import RowSettings from 'views/campaigns-v2/features/campaign-list/RowSettings';

interface ColumnOption {
  _name: string;
  id: string;
  [key: string]: any; // Allows any other properties with string keys
}

const EmailList = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });
  const [colViewOptions, setColViewOptions] = React.useState(false);
  const [columnOptions, setColumnOptions] = React.useState<ColumnOption[]>([
    { _name: 'Name', id: 'name', name: true, disabled: true },
    { _name: 'Email accounts', id: 'email', email: true, disabled: true },
    { _name: 'Status', id: 'mailer_status', mailer_status: true, disabled: true },
    { _name: 'Daily limits', id: 'limit', limit: true },
    { _name: 'Health Score', id: '', none: true },
    { _name: 'Email groups', id: 'groups_list', groups_list: true },
    { _name: 'Custom tracking', id: '', none: true },
    { _name: 'Type', id: '', none: true },
    { _name: 'Sent', id: '', none: false },
  ]);
  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  const campaignSearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams.search, 300);

  const dispatch = useAppDispatch();
  const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);
  const columnHelper = createColumnHelper<EmailAccountListRowObject>();
  const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  // const handleSort = (column: string) => {
  // setQueryParams((prev) => ({
  //     ...prev,
  //     sort: {
  //         columnName: column,
  //         order: prev.sort.columnName === column ? (prev.sort.order === 'asc' ? 'desc' : prev.sort.order === 'desc' ? '0' : 'asc') : 'asc'
  //     }
  // }));
  // };

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        // setIsLoading(true)
        const query = setAndGetQueryParams([
          { key: 'limit', value: queryParams?.limit },
          { key: 'offset', value: queryParams?.offset },
          { key: 'search', value: queryParams?.search },
        ]);
        const response = await BaseApi.get(`/email/settings?${query}`);
        const { data, total_records }: any = jwtDecrypt(response?.data);
        if (data && data?.length) {
          console.log('data', data);
          setData((prev) => ({
            data: data,
            count: total_records,
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoading(false)
      }
    };
    fetchList();
  }, [queryParams?.limit, queryParams?.offset, queryParams?.search]);

  const fetchList = async () => {
    try {
      const response = await BaseApi.get(`/email/settings`);
      const { data, total_records }: any = jwtDecrypt(response?.data);
      if (data && data?.length) {
        setData((prev) => ({
          data: data,
          count: total_records,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="ml-2.5 flex items-center">
          <input
            type="checkbox"
            id={`row-${row.id + 1}`}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      enableSorting: false,
      header: () => <div className="select-none font-semibold">Name</div>,
      cell: (info) => (
        <div className="flex w-full items-center gap-2">
          <Avatar size={'sm'} name={info.getValue() ? info.getValue() : info.row.original?.first_name} bg="teal.500" src={info.row.original?.picture} />
          <div className="flex w-32 flex-col">
            <p className="w-full truncate text-sm font-bold text-heading dark:text-white">
              {info.getValue() ? info.getValue() : info.row.original?.first_name + ' ' + info.row.original?.last_name}
            </p>
            <p className="w-full truncate text-xs text-column">{info?.row?.original?.email}</p>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor('groups_list', {
      id: 'groups_list',
      enableSorting: false,
      header: () => <div className="column-groupHeader select-none font-semibold">Email groups</div>,
      cell: (row) => {
        // BaseApi.post('groups', [])

        return <EmailGroupTags groupTags={row.getValue()} row={row?.row?.original} fetchList={fetchList} />;
        //    return <></>
      },
    }),
    columnHelper.accessor('mailer_status', {
      id: "email_volume'",
      enableSorting: false,
      header: () => <div className={`email_volume_col relative select-none font-semibold`}>Email Volume</div>,
      cell: (info) => {
        return <EmailAreaChart data={EmailStatsData} />;
      },
    }),
    columnHelper.accessor('mailer_status', {
      id: 'unsubscribe',
      enableSorting: false,
      header: () => <div className="column-mailStatusHeader relative select-none font-semibold">Unsubscribe</div>,
      cell: (info) => {
        return <EmailAreaChart data={EmailStatsData} />;
      },
    }),
    columnHelper.accessor('mailer_status', {
      id: 'bounced',
      enableSorting: false,
      header: () => <div className="column-mailStatusHeader relative select-none font-semibold">Bounce</div>,
      cell: (info) => {
        return <EmailAreaChart data={EmailStatsData} />;
      },
    }),
    columnHelper.accessor('mailer_status', {
      id: 'mailer_status',
      enableSorting: false,
      header: () => <div className="column-mailStatusHeader relative select-none font-semibold">Status</div>,
      cell: (info) => {
        return (
          <div className="flex-col">
            {info.getValue() === 0 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1 text-[0.7rem] font-semibold text-[#d32318]">
                <MdLinkOff />
                <span>Disconnected</span>
              </div>
            )}
            {info.getValue() === 1 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]">
                <MdLink />
                <span>Connected</span>
              </div>
            )}
            {info.getValue() === 2 && (
              <div className="flex w-min items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.65rem] font-semibold text-[#029A48]">
                <MdLink />
                <span>Paused</span>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('limit', {
      id: 'limit',
      header: () => <div className="column-limitHeader select-none font-semibold">Daily limit</div>,
      cell: (info) => <p className={`text-sm font-semibold`}>{info?.getValue()}</p>,
    }),
    columnHelper.accessor('mailer_status', {
      id: 'health_score',
      header: () => <div className="select-none font-semibold">Health Score</div>,
      cell: (info) => <div className="mx-auto w-min rounded-full bg-green-600 px-2.5 py-1 text-xs text-white">92%</div>,
    }),
    columnHelper.accessor('mailer_status', {
      id: 'custom_tracking',
      header: () => <div className="select-none font-semibold">Custom tracking</div>,
      cell: (info) => {
        return <MdCloudQueue className="mx-auto" />;
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return (
          <RowSettings
            fetchList={fetchList}
            status={row.row.original.mailer_status}
            id={row.row.id}
            uuidRow={row.getValue()}
            rowData={row.row.original}
          // table={table}
          // setData={setData}
          />
        );

        // console.log(table.getSelectedRowModel().rows.map(val => val.original)) //get full client-side selected rows
        // return <EmailRowSettings fetchList = {fetchList} status={row.row.original?.mailer_status} id={row.row.id} uuidRow={row.getValue()} rowData = {row.row.original} table={table} setData = {setData}/>
        //  <div id={`${info.row.original?.campaign_name}`}
        //     className="absolute top-0 bg-white border"
        // onClick={() => {
        // SessionStorage.setString(EDIT_ID, info.row.original?.uuid);
        // const id :number = Number(info.row.id);
        //     fetchCampaignList(info.row.original?.uuid)
        // }} >
        //     <MdEdit />
        // </div>
      },
    }),
  ];

  React.useEffect(() => {
    const data = [
      // { id: '1', name: 'Assignee', conditions: [{ id: "1", name: 'Is' }, { id: "2", name: 'Is not' }], resultValue: [{ id: "1", name: 'Me' }, { id: "2", name: 'P1' }, { id: "3", name: 'P2' }] },
      {
        id: '2',
        name: 'Status',
        uniqueColumnName: 'campaign_status',
        conditions: [
          { id: '1', name: 'Is', uniqueName: 'is' },
          { id: '2', name: 'Is not', uniqueName: 'not' },
        ],
        resultValue: [
          { id: '1', name: 'Paused', uniqueName: '3' },
          { id: '2', name: 'Active', uniqueName: '1' },
          { id: '3', name: 'Draft', uniqueName: '0' },
        ],
      },
      {
        id: '3',
        name: 'Open Rate',
        uniqueColumnName: 'open_rate',
        conditions: [
          { id: '1', name: 'More Than', uniqueName: 'gte' },
          { id: '2', name: 'Less than', uniqueName: 'lte' },
        ],
        resultValue: [],
      },
      {
        id: '4',
        name: 'Created On',
        uniqueColumnName: 'cdate',
        conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
        resultValue: [
          { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
          { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
          { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
          { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
          { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
          { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
          { id: '7', name: 'Date Range' },
        ],
      },
    ];
    setOptionData(data);
    setColumns(columnData);
  }, []);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        // setIsLoading(true)
        const query = setAndGetQueryParams([
          { key: 'limit', value: queryParams?.limit },
          { key: 'offset', value: queryParams?.offset },
          { key: 'search', value: queryParams?.search },
        ]);
        const response = await BaseApi.get(`/email/settings?${query}`);
        const { data, total_records }: any = jwtDecrypt(response?.data);
        setQueryParams({ limit: 25, offset: 0, search: '', filter: '', sort: { columnName: '', order: '' } });
        if (data && data?.length) {
          setData((prev) => ({
            data: data,
            count: total_records,
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoading(false)
      }
    };
    fetchList();
  }, [queryParams?.limit, queryParams?.offset, queryParams?.filter, flagStatus, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order]);

  React.useEffect(() => {
    const tempColumns = columnData.filter((val) => {
      const tempId = columnOptions.findIndex((option) => option.id === val.id);
      if (tempId === -1) return true;
      return columnOptions[tempId]?.[val.id] === true;
    });
    setColumns(tempColumns);
    // testing ss
  }, [columnOptions]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    dispatch(setCampaignSelectedCSVData(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
  }, [table.getSelectedRowModel()]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (parentRef.current && !parentRef.current.contains(event.target) && !childRef?.current?.contains(event.target)) {
        setColViewOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [parentRef]);

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => ({
      ...prev,
      filter: encryptedData,
    }));
  };

  const handleColumnData = (colId: string, colState: boolean) => {
    setColumnOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.id === colId) {
          return { ...option, [colId]: !colState };
        }
        return option;
      });
    });
    console.log(columnOptions);
  };

  const dateFormate = (from: number | string, to: number = 1) => {
    const fromDate =
      typeof from === 'string'
        ? moment()
          .startOf(from as moment.unitOfTime.StartOf)
          .format('YYYY-MM-DD')
        : moment().subtract(from, 'days').format('YYYY-MM-DD');
    const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
    return `${fromDate},${currentDate}`;
  };

  // console.log(dateFormate('month',0))
  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        md:w-[13rem] lg:w-[26rem]"
              onClick={() => campaignSearchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={campaignSearchRef}
                type="text"
                placeholder="Search"
                id="campaign-search-input"
                className="w-full text-sm font-bold
                             text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
                onChange={handleCampaignSearch}
              />
            </div>
            {/* Custom filter */}
            <CustomFilter optionData={optionData} handleFilter={handleFilter} />
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                dispatch(setFlagStatus(true));
                SessionStorage.clearItem(EDIT_ID);
                console.log('Function Triggered');
              }}
              className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add Email</span>
            </button>
            <div className={`relative cursor-pointer rounded-md bg-white outline outline-2 ${colViewOptions ? 'outline-menuBorder' : 'outline-gray-300'}`} ref={parentRef}>
              <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setColViewOptions(!colViewOptions)}>
                <MdViewWeek className="text-gray-700" />
              </button>
              {colViewOptions && (
                <div
                  ref={childRef}
                  className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md
                                 border bg-white px-3 py-2 dark:bg-navy-700
                                 
                                 "
                >
                  {columnOptions.map((col, id) => (
                    <div key={id} className="flex items-center justify-between">
                      <h5 className="text-xs dark:text-white">{col._name}</h5>
                      <Toggle
                        checkedCondition={col?.[col.id]}
                        changeFunction={(e: Event) => {
                          handleColumnData(col.id, col?.[col.id]);
                        }}
                        disabled={col?.disabled}
                        className={`scale-50 col-list-${col?.name}`}
                        id={`col-list-${col?.name}`}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
          <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index, arr) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={`
                                                ${index === 0 && 'w-[35px]'}
                                                ${index === 1 && 'w-[200px] text-start'}
                                                ${index === 2 && 'w-[130px] text-start'}
                                                ${index >= 3 && index <= 5 && 'w-[130px]'}
                                                ${index === 6 && 'w-[100px]'}
                                                ${index === arr.length - 1 && 'w-[20px]'}
                                                mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center text-column dark:border-white/10 dark:bg-navy-900`}
                      >
                        <div className="text-[11px]">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y-[1px]">
              {data?.data?.length ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className="hover:bg-hover dark:hover:bg-navy-900" onClick={() => dispatch(setEmailEditDrawer(true))}>
                      {row.getVisibleCells().map((cell, i) => {
                        return (
                          <td key={cell.id} className={`border-white/0 py-3 text-heading ${(i === 1 || i === 2) && 'text-start'} text-center dark:text-white`}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <EmailEditSettings /> */}
      <AddEmailAccountsDrawerLayout />
    </>
  );
};

export default EmailList;
