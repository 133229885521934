// interface ModalComponentProps {
// isOpen: boolean;
// onClose: () => void;
// }

// const PaymentModal = (props :ModalComponentProps) => {
//   const { isOpen, onClose } = props;
// console.log(isOpen, onClose)
//   return (
//     <div>PaymentModal</div>
//   )
// }

// export default PaymentModal

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/modal';
import Button from 'common/sparkle-common/Button';
import Input from 'common/sparkle-common/Input';
import { BaseApi } from 'common/api/common/base-api';
import React from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
// import { MdArrowBack } from 'react-icons/md';
// import { IoMdArrowBack, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

const PaymentModal = (props: ModalComponentProps) => {
  const { isOpen, onClose } = props;
  const [creditCount, setCreditCount] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [plan, setPlan] = React.useState({ credit_point: 100 });

  const handleCheckout = async () => {
    try {
      if (creditCount < 50) {
        toast.error('Credit Should be atleast 50');
        return;
      }
      const payload = {
        payment_type: 1,
        credit: creditCount,
        location: window.location.href,
      };
      const sessionData = await BaseApi.post(
        `/subscription/payment/create`,
        payload
      );
      window.location.href = `${sessionData.data.data.sessionUrl}`;
    } catch (error) {
      console.log(error);
    }
  };

  const handlePriceChange = (e: any) => {
    setCreditCount(e.target.value);
    const credit = e.target.value;
    console.log(typeof credit, typeof plan.credit_point);
    setPrice(credit / Number(plan.credit_point));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await BaseApi.get(`subscription/plan`);
        if (data?.data?.length) {
          const plan = data.data.filter((item: any) => item.plan === 'default');
          setPlan(plan[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [isOpen]);
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'}>
        <ModalHeader>
          <div className="flex items-center gap-3">
            {/* <MdArrowBack className="text-column" /> */}
            <h5 className="text-xl font-semibold text-heading">Billing</h5>
          </div>
          <ModalCloseButton
            size={'sm'}
            color={'column'}
            id="payment-pop-close"
          />
        </ModalHeader>
        <ModalBody>
          <div className="mb-5 space-y-3 rounded-md border border-[#FEC84B] bg-gradient-to-r from-[#FFFFFF] to-[#FEF0C7] py-2">
            <div className="flex justify-between px-4">
              <div>
                <div className="flex items-center gap-2">
                  <h5 className="text-xl font-bold text-blackDark">
                    Pay as you go
                  </h5>
                  <h6 className="size-min rounded-full bg-[#F9F5FF] px-2 py-0.5 text-xs font-medium text-[#4318FF]">
                    Popular
                  </h6>
                </div>
                <p className="text-[.7rem] text-column">
                  Our most popular plan for small teams.
                </p>
              </div>
              <div className="flex gap-1">
                <h4 className="self-start text-3xl font-bold text-[#101828]">
                  $
                </h4>
                <h2 className="text-5xl font-bold text-[#101828]">{price}</h2>
                <p className="self-end text-sm text-column">Total amount</p>
              </div>
            </div>
            <hr />
            <div id="email-verifier-purchase-input" className="flex items-center px-4">
              <Input
                placeholder="2000"
                customClass="py-2 px-4 text-[1.1rem]"
                onChange={(e) => {
                  handlePriceChange(e);
                }}
              />
            </div>
            <hr />
            <div id="email-verifier-purchase-button" className="flex items-center px-4">
              <Button
                fontSize="xs"
                name="Purchase now"
                customClass="w-full"
                onClick={() => handleCheckout()}
              />
            </div>
          </div>
          <div className="mb-5 w-full space-y-1">
            <h5 className="text-[.85rem] font-bold">FEATURES</h5>
            <p className="text-xs font-thin text-column">
              Everything in our <b className="font-bold">Pay as you go plan</b>
            </p>
          </div>
          <div className="mb-3 flex w-full justify-between gap-5">
            <div className="flex w-full flex-col gap-3">
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Access to basic features
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Basic reporting + analytics
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Up to 10 individual users
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  20GB individual data
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Basic chat support
                </h3>
              </span>
            </div>
            <div className="flex w-full flex-col gap-3">
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">Attend events</h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Automatic updates
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Backup your account
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">
                  Audit log and notes
                </h3>
              </span>
              <span className="flex gap-2">
                <IoMdCheckmarkCircleOutline className="size-4 fill-brand-600" />
                <h3 className="text-[0.82rem] text-column">Feature requests</h3>
              </span>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
