import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/modal'
import { card1SVG } from 'assets/img/rewards';
import React from 'react'
import { ReactSVG } from 'react-svg';

type StepModal = {
    creditClose: () => void;
    creditOpen: boolean;
}

const CreditsModal = (props: StepModal) => {
    const { creditClose, creditOpen } = props;


    return (
        <Modal onClose={creditClose} isOpen={creditOpen} isCentered size={'lg'}>
            <ModalOverlay backdropFilter='auto' backdropBlur='5px' />
            <ModalContent>
            <ModalCloseButton size={'sm'} className='text-paused-row' />
            <ModalBody>
                    <div className='flex items-center gap-5'>
                    <ReactSVG src={card1SVG} beforeInjection={(svg: any) => { svg.setAttribute('width', '190'); return svg; }} />
                    <h5 className='text-heading text-[1.15rem] font-semibold'>Congrats! You got 50 Sparkle Credits</h5>
                    </div>
                    <div className='text-[1rem] space-y-2 pb-2'>
                        <h5 className='text-column'>Details</h5>
                        <h5 className='text-column'>Reference ID: BDW350DWAIFN452</h5>
                        <h5 className='text-column'>Rewarded for completing a task of launching 10 campaigns with Sparkle</h5>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default CreditsModal