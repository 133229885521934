import Profile from "./Profile"
import OptionalSettings from "./OptionalSettings"
import CustomTracking from "./CustomTrackingDomain"
import EmailEdit from "./EmailEdit"
import CRMEmail from "./CRMEmail"
import DomainHealth from "./DomainHealth"
import EmailWarmup from "./EmailWarmup"
import Signature from "./Signature"
import { SessionStorage } from "common/api/common/storage"
import { EDIT_ID } from "common/constants/constants"
import React from "react"
import { BaseApi } from "common/api/common/base-api"
import { useAppDispatch, useAppSelector } from "store"
import { setEditData } from "store/email-accounts"
import { getErrorMessage } from "store/custom"
import { toast } from "react-toastify"
import { jwtDecrypt, toastState } from "common/utils/utility"
import { useNavigate } from "react-router-dom"

const EmailAccountEditSettings = () => {
    const dispatch = useAppDispatch();
    const editData = useAppSelector((state) => state?.emailAccount?.getEditData)
  const navigate = useNavigate();
    const editUuid = SessionStorage.getString(EDIT_ID);
    React.useEffect(() => {
        const fetchEmailAccount = async () => {
            try {
                const response = await BaseApi.get(`/email/settings/${editUuid}`);
                const emailData = await jwtDecrypt(response?.data)
                dispatch(setEditData(emailData));
            } catch (error) {
                console.log(error)
            }
        }
        fetchEmailAccount();
    }, [dispatch, editUuid])

    const handleFormSubmit = async () => {
        try {
            const data = await BaseApi.patch(`/email/settings/${editUuid}`, editData);
            if (data?.error) {
                dispatch(getErrorMessage(data?.message))
                toast.error("error occured", toastState.error)
            } else {
                dispatch(getErrorMessage({}))
                toast.success('Email setting data updated.', toastState.success);
                navigate(-1)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="mt-3 pb-20 grid h-screen overflow-y-auto w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
            <div className="flex flex-col gap-5">
                <Profile />
                <EmailEdit />
                <OptionalSettings />
                <Signature />
            </div>
            <div className="flex flex-col gap-5">
                <CustomTracking />
                <DomainHealth />
                <EmailWarmup />
                <CRMEmail />
            </div>
            <div>
                <button className="rounded-xl border-2 border-brand-500 px-5 py-3 text-base font-medium text-brand-500 transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10">
                    Reconnect
                </button>
                <button onClick={() => handleFormSubmit()} className="rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Save
                </button>
            </div>
        </div>
    )
}

export default EmailAccountEditSettings