/* eslint-disable @typescript-eslint/no-unused-vars */

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
// import Input from 'common/sparkle-common/Input';
import React, { useRef, useState } from 'react';
// import CustomDropdown from './CustomDropdown';
import Button from 'common/sparkle-common/Button';
import { ModalComponentProp } from 'common/dto/types/views/blacklist';
// import { FiUploadCloud } from 'react-icons/fi';
// import { FaTrash } from 'react-icons/fa';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { setAndGetQueryParams, toastState } from 'common/utils/utility';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import Toggle from 'components/Toggle';
import DynamicIcons from './DynamicIcons';
import { GoPerson } from 'react-icons/go';
import { AxiosError } from 'axios';
import { resetTeamsRoleEditState } from 'store/organization';

function AddNewRoleModal(props: ModalComponentProp) {
  const [loading, setLoading] = React.useState(false);
  const { teamsRoleEdit } = useAppSelector((state) => state.organization);
  const [menuloading, setMenuLoading] = React.useState(false);
  const { errorMessage }: any = useAppSelector((state: { custom: any }) => state.custom);

  const [responseTable, setResponseTable]: any = React.useState(null);
  const [activeColumn, setActiveColumn] = React.useState('');

  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();

  const handleChangeRoleAccess = (menu_name: string, optionId: string) => {
    // console.log('menu_name', menu_name, optionId);
    const newData = responseTable.access_given.map((menu: any) => {
      if (menu.menu_name === menu_name) {
        // console.log('true');
        return {
          ...menu,
          options: menu.options.map((option: any) => {
            if (option.name === optionId) {
              return { ...option, value: !option.value };
            }
            return option;
          }),
        };
      }
      return menu;
    });
    // console.log(newData)
    setResponseTable((prev: any) => ({ ...prev, access_given: newData }));
  };

  const { isOpen, onClose } = props;
  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    //updates the Edit Role
    teamsRoleEdit?.uuid && setResponseTable(teamsRoleEdit);
    //Create New Role

    const fetchData = async () => {
      try {
        setMenuLoading(true);
        // const query = setAndGetQueryParams([
        //   {
        //     key: 'sort',
        //     value: `order_index%asc`,
        //   },
        //ytest
        // ]);
        const response = await BaseApi.get(`/menu/list`);
        const data = { ...response.data, access_given: [{ menu_name: 'Role Name' }, ...response?.data?.data] };
        setResponseTable(data);
        setMenuLoading(false);
      } catch (error) {
        setMenuLoading(false);

        console.error('Error fetching data:', error);
      }
    };
    !teamsRoleEdit?.uuid && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disable = responseTable?.role_name ? false : true;
  const handleSubmit = async () => {
    // if (responseTable?.role_name && responseTable?.access_given) {
    if (teamsRoleEdit?.uuid) {
      try {
        const hasViewAccess = responseTable.access_given.some((access: any) => access.options?.some((option: any) => option.id === 'view' && option.value === true));
        if (hasViewAccess) {
          setLoading(true);

          // responseTable.access_given.shift();
          const [role_name, ...rest] = responseTable.access_given;
          // console.log("responseTable",responseTable,">>>>>>>>>>>>>",rest)
          const submitData = await BaseApi.patch('/role/management', {
            uuid: responseTable?.uuid,
            role_name: responseTable?.role_name,
            access_given: responseTable?.access_given,
          });
          if (!submitData.error) {
            toast.success(submitData.message, toastState.success);
            dispatch(setFlagStatus(!custom?.flagStatus));
            setResponseTable(null);
            dispatch(resetTeamsRoleEditState());
            onClose();
            responseTable.access_given = rest;
            dispatch(getErrorMessage({}));
          } else {
            dispatch(getErrorMessage(submitData?.message));

            console.log('Error Submitting Data:', submitData.error);
          }
        } else {
          toast.error('View permission is required for at least one menu', toastState?.error);
        }
      } catch (error) {
        // console.log(error);
        toast.error(error instanceof AxiosError ? error.response.data.message : 'Unable to add role');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const hasViewAccess = responseTable.access_given.some((access: any) => access.options?.some((option: any) => option.id === 'view' && option.value === true));
        if (hasViewAccess) {
          const [role_menu, ...rest] = responseTable.access_given;
          const submitData = await BaseApi.post('/role/management', { role_name: responseTable?.role_name, access_given: rest });
          if (!submitData.error) {
            toast.success(submitData.message, toastState.success);
            dispatch(setFlagStatus(!custom?.flagStatus));
            setResponseTable(null);
            onClose();
            responseTable?.access_given?.shift();
            dispatch(getErrorMessage({}));
          } else {
            dispatch(getErrorMessage(submitData?.message));

            console.log('Error Submitting Data:', submitData.error);
          }
        } else {
          toast.error('View permission is required for at least one menu', toastState?.error);
        }
      } catch (error) {
        toast.error(error instanceof AxiosError ? error.response.data.message : 'Unable to add role');
      } finally {
        setLoading(false);
      }
    }
    // } else {
    //   toast.error('Role Name are Required', toastState.error);
    // }
  };

  // interface MenuRefs {
  //   [key: string]: React.RefObject<HTMLDivElement>;
  // }
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const menuRefs = useRef([]);
  // const roleRef = useRef(null);
  // const elementRef = useRef(null);

  // const scrollToSection = (uuid: number, value: string) => {
  //   setActiveColumn(value);
  //   const ref = menuRefs.current[uuid];
  //   console.log(uuid);
  //   console.log('ref', ref);
  //   ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };

  const scrollToSection = (index: number, menuName: string) => {
    setActiveColumn(menuName); // Update active column on click
    const ref = menuRefs.current[index];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // const handleRoleScroll = () => {
  //   if (roleRef?.current) {
  //     roleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };

  const [count, setCount] = useState(1);
  React.useEffect(() => {
    if (responseTable && responseTable.access_given && responseTable.access_given.length) {
      menuRefs.current = responseTable?.access_given?.map((_: any, index: any) => menuRefs.current[index] || React.createRef());
      setActiveColumn(responseTable?.access_given[0].menu_name);
    }
    const scrollFunc = () => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry: any) => {
            const index = parseInt(entry.target.dataset.index, 10);
            const menuName = responseTable.access_given[index].menu_name;

            // Check if the element is sufficiently visible to be considered active
            if (entry.isIntersecting) {
              // console.log('Hit');
              setActiveColumn(menuName); // Update active column when a significant part is visible
            }
          });
        },
        {
          root: null,
          rootMargin: '00px 100px 100px 00px',
          threshold: [0.7],
        }
      );

      menuRefs.current.forEach((ref, index) => {
        if (ref.current) {
          ref.current.dataset.index = index;
          observer.observe(ref.current);
        }
      });
      return observer;
    };
    const observer = scrollFunc();

    return () => {
      menuRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);
  if (count === 1) {
    setTimeout(() => {
      setCount((prev) => prev + 1);
    }, 1000);
  }

  // useEffect(() => {
  //   scrollFunc();
  // }, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size={'5xl'}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent overflow={'auto'} className="select-none dark:bg-darkTwo">
        <ModalHeader className="mb-1 bg-[#FAFAFC] dark:bg-darkOne">
          <div className="flex items-center  gap-3">
            <h5 className="text-lg font-semibold text-heading dark:text-white">Add New Role</h5>
          </div>
        </ModalHeader>
        <ModalBody className=" !p-3">
          <div className=" flex min-h-[20rem] ">
            <div className=" sticky top-[540px] w-1/4  bg-white dark:!bg-darkTwo">
              <div className=" !mt-6 mb-3 flex flex-col rounded-md px-2 py-2">
                <div className="mx-1 -mt-8 !h-[60vh] overflow-auto ">
                  {/* <div
                    onClick={() => handleRoleScroll()}
                    className={`role-access-menu- 'bg-brand-500 text-white' 'dark:bg-green dark:text-white' } mt-1 flex w-full items-center gap-2 rounded-md
                          p-2 text-sm text-[#667085] hover:bg-[#d9d6ff] hover:!text-blackDark dark:text-white
                      `}
                  >
                    <GoPerson
                      className={`text-gray-800dark:bg-green cursor-pointer
                         hover:bg-[#d9d6ff] hover:!text-blackDark dark:text-white`}
                    />
                    Role
                  </div> */}
                  {responseTable &&
                    responseTable.access_given.length &&
                    responseTable?.access_given?.map((item: any, index: number) => (
                      <div
                        key={item.menu_name} // Use `menu_name` as a key if it's unique, otherwise consider adding an `id` field to your items.
                        onClick={() => scrollToSection(index, item.menu_name)}
                        className={` role-access-menu-${index} mt-1 flex w-full items-center gap-2.5 rounded-md p-2 text-sm text-[#667085] ${
                          activeColumn === item.menu_name ? 'bg-brand-500 text-white' : 'dark:bg-green hover:bg-[#d9d6ff] hover:!text-blackDark dark:text-white'
                        }`}
                      >
                        {item?.menu_name === 'Role Name' ? (
                          <>
                            <GoPerson
                              className={`text-gray-800dark:bg-green cursor-pointer
                         hover:bg-[#d9d6ff] hover:!text-blackDark dark:text-white`}
                            />
                            Role
                          </>
                        ) : (
                          <DynamicIcons name={item.menu_name} />
                        )}

                        {/* <HiOutlineChartSquareBar /> {item.menu_name} */}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="box-border !h-[60vh] w-full overflow-y-auto scroll-smooth rounded-md bg-[#FAFAFC] px-8 py-4 pb-40 dark:bg-darkThree" ref={rightPanelRef}>
              {/* <div className="h-10 w-full" ref={roleRef} /> */}

              {!menuloading ? (
                responseTable &&
                responseTable.access_given.map((item: any, index: any) => (
                  <>
                    {item?.menu_name === 'Role Name' ? (
                      <div ref={menuRefs.current[index]} data-index={index} key={item.menu_name}>
                        <div key={`item-role-name-01`} className="!mt-5 mb-5 flex flex-row items-center justify-between rounded-md bg-white px-2  py-2 dark:bg-darkTwo">
                          <div className="flex flex-col">
                            <p className="ml-2 text-[18px] font-semibold text-paused-color dark:text-gray-300">
                              Enter Role Name <span className="text-red-500">*</span>
                            </p>
                            <p className="ml-2 text-[12px] font-normal text-paused-color dark:text-gray-300">Name for new Role</p>
                          </div>
                          <div>
                            <div
                              className={`ml-2 flex items-center gap-2 rounded-md border border-blue-400 px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:!border-darkBorder md:w-[13rem] lg:w-[26rem]  ${
                                errorMessage?.role_name ? `border-red-500 dark:!border-red-500` : `dark:border-none`
                              } `}
                            >
                              <GoPerson className="size-4 cursor-pointer text-gray-800 dark:text-white" />
                              <input
                                id="role-name-input"
                                key={543435}
                                value={responseTable?.role_name}
                                placeholder="Enter Role Name *"
                                className={`w-full text-sm text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:text-white dark:placeholder:text-white `}
                                onChange={(event) =>
                                  setResponseTable((prev: any) => ({
                                    ...prev,
                                    role_name: event.target.value,
                                  }))
                                }
                              />
                            </div>
                            {errorMessage?.role_name && (
                              <span className="ml-3 mt-1  flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage?.role_name}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div ref={menuRefs.current[index]} data-index={index} key={item.menu_name}>
                        {item.options.map((items: any, idx: any) => (
                          <div key={`item-${index}-${idx}`} className="!mt-5 mb-5 flex flex-row items-center justify-between rounded-md bg-white px-2 py-2 dark:bg-darkTwo">
                            <div className="flex flex-col">
                              <p className="ml-2 text-[18px] font-semibold text-paused-color dark:text-gray-300">{items.name}</p>
                              <p className="ml-2 text-[12px] font-normal text-paused-color dark:text-gray-300">{items.description}</p>
                            </div>
                            <div className="mx-2 flex h-10 flex-row items-center p-1">
                              <span className={`${items.value ? 'text-sm font-semibold text-gray-700' : 'text-sm font-semibold text-blueSecondary'}`}>No</span>
                              <Toggle
                                changeFunction={() => {
                                  handleChangeRoleAccess(item.menu_name, items.name);
                                }}
                                checkedCondition={items.value}
                                className={`${item.menu_name}-${idx} scale-50`}
                              />
                              <span className={`${items.value ? 'text-sm font-semibold text-blueSecondary' : 'text-sm font-semibold text-gray-700'}`}>Yes</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <hr />
                  </>
                ))
              ) : (
                <>
                  <div className="fixed inset-0 flex items-center justify-center">
                    <div className="mr-2 h-8 w-8 animate-spin rounded-full border-b-4 border-t-4 border-b-brandLinear border-t-horizonPurple-300"></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="!-mt-2 !mb-5 !h-2">
          <Button
            id="cancel-workspace-management"
            name="Cancel"
            customClass="!bg-[#F4F7FE]  !text-[#A3AED0]  border-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
            onClick={() => {
              // handleRemove();

              // setSelectedData([]);
              // setIsOpenState(false);
              setResponseTable(null);
              dispatch(getErrorMessage({}));
              onClose();
            }}
          />
          <Button
            id="save-workspace-management"
            disable={loading}
            loading={loading}
            name={teamsRoleEdit?.uuid ? 'Update' : 'Create'}
            customClass=" !text-[#FFFFFF] dark:border-none create_role_btn !font-medium duration-300 w-40 py-2.5 text-[0.80rem]"
            onClick={() => handleSubmit()}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddNewRoleModal;
