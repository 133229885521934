import React from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import { useAppDispatch, useAppSelector } from 'store';
import { toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { ModalComponentProp } from 'common/dto/types/views/blacklist';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import CreateBrand from 'views/campaigns-v2/features/campaign-creation/setup/brand-list/CreateBrand';

function NewBrandModal(props: ModalComponentProp) {
  const { isOpen, onClose } = props;
  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();
  const [handleState, setHandleState] = React.useState({
    loading: false,
    brandData: null,
  });

  const handleBrandDataChange = (state: any) => {
    setHandleState((prevState) => ({ ...prevState, loading: true }));
    if (state?.uuid) {
      setHandleState((prevState) => ({ ...prevState, brandData: state }));
    } else {
      const { uuid, ...rest } = state;
      setHandleState((prevState) => ({ ...prevState, brandData: rest }));
    }
  };

  const handleClose = () => {
    setHandleState((prevState) => ({ ...prevState, brandData: null }));
    dispatch(getErrorMessage({}));
    onClose();
  };

  React.useEffect(() => {
    const submitCreateBrandApi = async () => {
      if (handleState?.brandData?.uuid) {
        const brandUpdateResponse = await BaseApi.patch('/brand', handleState?.brandData);
        console.log('brandCreateResponse', brandUpdateResponse);
        if (!brandUpdateResponse?.error) {
          toast.success(brandUpdateResponse?.message, toastState.success);
          setHandleState((prevState) => ({ ...prevState, loading: false }));

          dispatch(getErrorMessage({}));

          dispatch(setFlagStatus(!custom?.flagStatus));
          handleClose();
        } else {
          dispatch(getErrorMessage(brandUpdateResponse?.message));
          setHandleState((prevState) => ({ ...prevState, loading: false }));
        }
      } else {
        const brandCreateResponse = await BaseApi.post('/brand', handleState?.brandData);
        // console.log('brandCreateResponse', brandCreateResponse);
        setHandleState((prevState) => ({ ...prevState, loading: false }));
        if (brandCreateResponse?.error) {
          dispatch(getErrorMessage(brandCreateResponse?.message));
        } else {
          dispatch(getErrorMessage({}));
          dispatch(setFlagStatus(!custom?.flagStatus));

          handleClose();
        }
      }
    };
    handleState?.brandData && submitCreateBrandApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.brandData]);

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="dark:bg-darkThree">
          <ModalBody className="w-full !p-0">
            <CreateBrand bodycustomClass={'h-[80vh] w-full '} onParentDataChange={handleBrandDataChange} onChangeloading={handleState?.loading} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default NewBrandModal;
