import React from 'react'

import { useAppDispatch, useAppSelector } from 'store'
import InitialModal from './features/initialModal'
import ProspectManualUploader from './features/upload-csv'
import MapVariable from './features/map-variables'
import ProspectsList from './features/prospect-list'
import { setCurrentStepCount } from 'store/custom'
import ProspectDbSelectingModal from './features/search-prospects/ProspectDbSelectingModal'
import SearchedProspectsList from './features/search-prospects'
// import WebSocketComponent from './SocketCheck'

const Prospects = () => {
  const campaignv2 = useAppSelector((state) => state?.campaignv2)
  const custom = useAppSelector((state) => state?.custom)
  const { prospects: { prospects_list, uploaded_prospects_list, variable_list } } = campaignv2
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (uploaded_prospects_list?.length && variable_list?.length) {
      if (prospects_list?.length) {
        dispatch(setCurrentStepCount(4))
      } else {
        dispatch(setCurrentStepCount(3))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {custom.current_step_count === 1 && <InitialModal />}
      {campaignv2.prospects.prospect_type === 1 && custom.current_step_count === 2 && <ProspectManualUploader />}
      {campaignv2.prospects.prospect_type === 1 && custom.current_step_count === 3 && <MapVariable />}
      {campaignv2.prospects.prospect_type === 2 && custom.current_step_count === 2 && <ProspectDbSelectingModal />}
      {campaignv2.prospects.prospect_type === 2 && custom.current_step_count === 3 && <SearchedProspectsList />}
      {custom.current_step_count === 4 && <ProspectsList />}
      {/* {custom.current_step_count === 4 && <WebSocketComponent />} */}
    </div>
  )
}

export default Prospects