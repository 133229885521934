import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/modal'
import React from 'react'
import CreditsModal from './CreditModal';
import { ReactSVG } from 'react-svg';
import { card6SVG } from 'assets/img/rewards';
import { useDisclosure } from '@chakra-ui/hooks';
import { MdDoneAll } from 'react-icons/md';
import { TbClockExclamation } from 'react-icons/tb';
type StepModal = {
    stepClose: () => void;
    stepOpen: boolean;
}

const StepsModal = (props: StepModal) => {
    const { stepClose, stepOpen } = props;
    const { onClose, isOpen, onOpen } = useDisclosure();
    const steps = new Array(5).fill('Step');
    return (
        <>
            <Modal onClose={stepClose} isOpen={stepOpen} size={'md'} isCentered>
                <ModalOverlay backdropFilter='auto' backdropBlur='3px' />
                <ModalContent>
                    <ModalCloseButton size={'sm'} className='text-paused-row' />
                    <ModalBody className='flex flex-col items-center justify-center border gap-2 rounded-md'>
                        <ReactSVG src={card6SVG} beforeInjection={(svg: any) => { svg.setAttribute('width', '190'); return svg; }} />
                        <h5 className='text-heading text-[0.88rem] font-semibold'>Complete the following steps to unlocked the scratch card</h5>
                        <div className='mt-2 w-full flex flex-col pb-3'>

                            {steps.map((step, i)=> <div key={i} className='w-full flex gap-3.5 p-2 rounded-md hover:bg-[#6670851F] cursor-pointer transition-all' onClick={() => { onOpen();}} >
                                {i < 1 ? 
                                <MdDoneAll className='rounded-full bg-[#B0EF8F] text-[#009045] size-9 p-2' /> :
                                <TbClockExclamation className='rounded-full bg-[#6670851F] text-[#667085] size-9 p-2' /> }
                                <div>
                                    <h5 className='text-[0.85rem] font-medium text-heading'>Launch 10 campaigns</h5>
                                    <h5 className='text-[0.8rem] text-column'>Level 1 (Completed)</h5>
                                </div>
                            </div>)}
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <CreditsModal creditOpen={isOpen} creditClose={onClose} />
        </>
    )
}

export default StepsModal