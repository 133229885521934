/* eslint-disable react-hooks/exhaustive-deps */
import OnboardBackground from 'components/crmOnboard/OnbardBackground';
import Button from 'common/sparkle-common/Button';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import { AiFillMail } from 'react-icons/ai';
import { BiCalendarCheck, BiCalendarExclamation } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { useAppSelector } from 'store';

const OnboardConnectCalendar = () => {
  const [emailData, setEmailData] = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [calenderCount, setCalenderCount] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailAccountCount, setEmailAccountCount] = React.useState(0);
  const pipelineId = useAppSelector((state) => state.crmOnboard.pipelineid);
  const navigate = useNavigate();
  const location = useLocation();
  // const checkScope = new URLSearchParams(location.search).get('scope');
  const code = new URLSearchParams(location.search).get('code');

  const updateSalesEmail = async () => {
    try {
      const data = {
        pipeline_id: pipelineId,
        code: code,
      };
      await BaseApi.patch('/crm/pipeline/sales/account/auth/code', data);
      navigate('/crm/onboard/connect/calendars');
    } catch (error) {
      console.error('Error updateSalesEmail', error);
      navigate('/crm/onboard/connect/calendars');
    }
  };

  React.useEffect(() => {
    if (code) {
      updateSalesEmail();
    }
    // navigate('/crm/onboard/connect/calendars');
  }, [code]);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await BaseApi.get(`/crm/pipeline/sales/account/${pipelineId}`);
        if (response?.data) {
          // console.log(response.data, 'response.data');
          setEmailData(response?.data?.result);
          await setCalenderCount(response?.data?.email_account_connected_count);
          await setEmailAccountCount(response?.data?.calendar_connected_count);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchList();
  }, [pipelineId, code]);
  const onClick = () => {
    navigate('/crm/onboard/connect/pipeline');
  };

  const handleCalendarAccess = async (uuid: string) => {
    try {
      const data = {
        pipeline_id: pipelineId,
        uuid: uuid,
      };
      const response = await BaseApi.post(`/crm/pipeline/calendar/access/url`, data);
      window.open(response?.data, '_blank');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <OnboardBackground count={3}>
        {}
        <div className="flex w-full justify-between">
          <div className="flex flex-col justify-start gap-8 py-6">
            <div>
              <p className="text-3xl font-bold text-heading">Connect your Calendars</p>
              <p className="mt-3 w-[33%] text-sm font-medium text-heading">
                We've added sample contacts, add some of your own to get set up faster. It's just like using a spreadsheet.
              </p>
              <div className="mt-6 flex w-full gap-6">
                <div className="flex h-[20rem] w-[45%] flex-col gap-5 rounded-md bg-white p-6 shadow-modalBody">
                  {emailData.map((item: any) => (
                    <div className="flex justify-between" key={item.uuid}>
                      <div className="flex items-center justify-center gap-3 text-xl">
                        <AiFillCheckCircle className="text-green-600" />
                        {item.email_type === 'smtp' ? <AiFillMail className="text-blueSecondary" /> : <FcGoogle />}

                        <p className="text-sm font-medium text-heading">{item.email}</p>
                      </div>
                      <Button
                        name={item.isCalendarAccessGiven ? 'Calender connected' : 'Connect calendar'}
                        onClick={() => handleCalendarAccess(item?.uuid)}
                        LeftIcon={item.isCalendarAccessGiven ? <BiCalendarCheck className="text-base" /> : <BiCalendarExclamation className="text-base" />}
                        color={item.isCalendarAccessGiven ? 'green-700' : 'blueSecondary'}
                        px="px-2"
                        py="py-1.5"
                        customClass={`${item.isCalendarAccessGiven ? '!bg-green-50' : '!bg-bgUpload'}
                          ${item.isCalendarAccessGiven ? 'border-green-700' : 'border-blueSecondary'}
                             !font-bold`}
                      />
                    </div>
                  ))}

                  <div className="flex cursor-pointer items-center gap-2" onClick={() => navigate('/crm/onboard/connect/accounts')}>
                    <AiOutlinePlus className="text-blueSecondary" />
                    <p className="text-sm font-bold text-blueSecondary ">Add an email account</p>
                  </div>
                </div>
                <div className=" w-[35%] ">
                  <p className="w- text-lg font-bold text-heading">What are sales calendars?</p>
                  <p className="mt-3 text-justify text-base font-medium text-heading">
                    Sales calendars are used to create, track appointments or meeting with your prospects. You can connect multiple sales calendar to this pipeline. But, ensure you
                    connect al least 1 sales calendar. Sparkle will sync all your sales calendar and keep you up to date of all upcoming meetings or scheduled appointments.
                  </p>
                </div>
              </div>
              <div className="mt-8 flex items-center gap-8 ">
                <Button
                  type="button"
                  name="Next"
                  fontSize="sm"
                  px="px-6"
                  // disable={
                  //   emailAccountCount !== 0 && calenderCount !== 0
                  //     ? false
                  //     : true
                  // }
                  customClass="text-column font-medium text-sm"
                  onClick={() => onClick()}
                />
              </div>
            </div>
          </div>
        </div>
      </OnboardBackground>
    </>
  );
};

export default OnboardConnectCalendar;
