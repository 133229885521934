import SelectEmailAccountLayOut from '../email-add/select-method';
import DomainBilling from './DomainBilling';
import CreateDomain from './CreateDomain';
import CustomizeEmail from './CustomizeEmail';
import DmarkSetup from './DmarkSetUp';
import Button from 'common/sparkle-common/Button';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useState } from 'react';

const BuyDomain = () => {
  const [step, setStep] = useState(0);
  const [handleNextStep, setHandleNextStep] = useState<() => Promise<boolean>>(() => async () => true);
  const handleNext = async () => {
    if (handleNextStep) {
      const isSuccessful = await handleNextStep(); // Call the step-specific logic
      if (isSuccessful) {
        setStep((prevStep) => prevStep + 1);
      }
    }
  };

  return (
    <SelectEmailAccountLayOut>
      <div className="flex h-full flex-col justify-between">
        <div>
          {step === 0 ? (
            <CreateDomain setHandleNextStep={setHandleNextStep} />
          ) : step === 1 ? (
            <DmarkSetup setHandleNextStep={setHandleNextStep} />
          ) : step === 2 ? (
            <CustomizeEmail setHandleNextStep={setHandleNextStep} />
          ) : step === 3 ? (
            <DomainBilling setHandleNextStep={setHandleNextStep} />
          ) : (
            <CreateDomain setHandleNextStep={setHandleNextStep} />
          )}
        </div>
        <div className="!mb-0 flex justify-end">
          <Button name="Next" customClass="border-blueSecondary !bg-[#f9f5ff] !rounded !text-blueSecondary text-sm" RightIcon={<AiOutlineArrowRight />} onClick={handleNext} />
        </div>
      </div>
    </SelectEmailAccountLayOut>
  );
};

export default BuyDomain;
