import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';
import header from './header';
import loader from './loading';
import auth from './authentication';
import custom from './custom';
import userSettings from './user-settings';
import campaign from './campaign';
import emailAccount from './email-accounts';
import domain from './domain';
import trueVerifier from './true-verifier';
import groupListData from './blacklist';
import crm from './crm';
import campaignv2 from './campaign-v2';
import organization from './organization';
import crmOnboard from './crm-onboard';
import senderGroupListData from './sender-accounts';
import unifiedInboxData from './unified-inbox';
import unmatched from './unmatched';
import commonrow from './common-row-settings';
import campaignInsights from './campaign-insights';
import notificationSettings from './notification-settings';
import accountSettings from './account-settings'
import globalsettings from './global-settings'

const rootReducer = combineReducers({
  custom,
  auth,
  header,
  loader,
  userSettings,
  campaign,
  campaignv2,
  emailAccount,
  domain,
  trueVerifier,
  crm,
  groupListData,
  organization,
  crmOnboard,
  senderGroupListData,
  unifiedInboxData,
  unmatched,
  commonrow,
  campaignInsights,
  notificationSettings,
  accountSettings,
  globalsettings
});

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
