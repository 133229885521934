import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdModeEdit } from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { Spinner } from '@chakra-ui/react'

import Toggle from 'components/Toggle'
import { useAppSelector } from 'store'
import { setCampaignPeriod, setCurrentSchedule, setCurrentScheduleID, setHolidays, setReplyData, setScheduleList, setSettingsData, setTrackingData } from 'store/campaign-v2'
import SetSchedule from './SetSchedule'
import SetDate from './SetDate'
import { _errorHandler, formatDateIntoDDMYYYY, toastState } from 'common/utils/utility'
import { BaseApi } from 'common/api/common/base-api'
import { toast } from 'react-toastify'

const ScheduleSettings = () => {
  const [listMover, setListMover] = useState(0)
  const [countOfSchedules, setCountOfSchedules] = useState(0)
  const [loading, setLoading] = useState(false)
  const [newScheduleModal, setNewScheduleModal] = useState(false)
  const [editScheduleModal, setEditScheduleModal] = useState(false)
  const [startDateModal, setStartDateModal] = useState(false)
  const [endDateModal, setEndDateModal] = useState(false)
  const dispatch = useDispatch()
  const state = useAppSelector(state => state?.campaignv2)
  const custom = useAppSelector(state => state?.custom)
  const { isCampaignStartDateSelected, isCampaignEndDateSelected } = state.settings

  const moveList = (operation: string) => {
    if (operation === '+') {
      if (countOfSchedules === 0) return
      setListMover(prev => prev + 21.5)
      setCountOfSchedules(prev => prev + 1)
    }
    else {
      if (countOfSchedules === -(state?.schedule_list.length - 1)) return
      setListMover(prev => prev - 21.5)
      setCountOfSchedules(prev => prev - 1)
    }
    console.log(listMover)
  }

  const getAllList = async () => {
    try {
      setLoading(true)
      const response = await BaseApi.get('/time/schedule')
      if (response?.error) {
        toast.error(_errorHandler(response?.message), toastState?.error);
      }
      dispatch(setScheduleList(response?.data))
      !state.scheduleSettings?.schedule_id && dispatch(setCurrentScheduleID(response?.data?.[0]?.uuid))
    }
    catch (err) {
      console.error(err)
    }
    finally {
      setLoading(false)
    }
  }

  const deletelist = async (uuid: string) => {
    try {
      await BaseApi.delete(`/time/schedule/${uuid}`)
      getAllList()
      if (state?.scheduleSettings?.schedule_id === uuid) {
        dispatch(setCurrentScheduleID(''))
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  useEffect(
    () => {
      getAllList()
      // setCountries(countryList)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  // const handleChangeSendingPriority = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { id, checked } = event?.target
  //   dispatch(setSendingPriority({ [id]: checked }))
  // }

  const handleToggleStartAndEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target
    dispatch(setSettingsData({ [id]: checked }))
  }

  return (
    <div className='w-[740px] rounded-md shadow-shade py-[1.2rem] relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white select-none'>
      <h5 className='px-5 mb-0.5 text-[0.9rem] font-medium text-column'>Settings</h5>
      <hr />

      <div className='flex justify-between px-3.5 py-2.5'>
        <div className='flex flex-col'>
          <h5 className='text-column font-medium text-[0.82rem]'>Set campaign active time</h5>
          <h5 className='text-column font-medium text-[0.6rem]'>Keep your campaigns running at the right time</h5>
        </div>
        <div className='relative'>
          <h5 className='text-blueSecondary text-[0.75rem] font-semibold cursor-pointer hover:underline' id='add_schedule_btn' onClick={() => setNewScheduleModal(!newScheduleModal)}>+ Add new schedule</h5>
          {newScheduleModal && <SetSchedule position='right-0' onOutsideClose={() => setNewScheduleModal(false)} onClose={() => { setNewScheduleModal(false); getAllList(); }} />}
        </div>
      </div>
      <hr />


      {
        loading ? <div className='px-3.5 py-9 relative flex justify-center items-center'><Spinner className="" thickness="3px" speed="0.65s" color="#4318FF1A" size="md" /></div>
          :
          state?.schedule_list?.length !== 0 ? (
            <div className='px-3.5 py-2 relative'>
              <div className='rounded-md px-4 py-2 bg-field w-full'>
                <button className='absolute left-1 top-1/2 -translate-y-1/2 rounded-full p-1 text-[#A3AED0] bg-white border border-[#E0E5F2] hover:text-white hover:bg-blueSecondary hover:border-blueSecondary' onClick={() => moveList('+')}><MdKeyboardArrowLeft /></button>
                <button className='absolute right-1 top-1/2 -translate-y-1/2 rounded-full p-1 text-[#A3AED0] bg-white border border-[#E0E5F2] hover:text-white hover:bg-blueSecondary hover:border-blueSecondary' onClick={() => moveList('-')}><MdKeyboardArrowRight /></button>
                <div className='flex w-full overflow-hidden'>
                  <div className={`flex gap-4 transition-all duration-500 h-16`} style={{ transform: `translate(${listMover}rem)` }}>
                    {state?.schedule_list.map((item, id) => <div className={`relative p-3 min-w-[20.5rem] rounded-md border flex justify-between items-center transition-all ${state?.scheduleSettings?.schedule_id === item?.uuid ? 'border-blueSecondary bg-white' : 'bg-white'}`} key={id} id={`Schedule_${id}`} onClick={() => dispatch(setCurrentScheduleID(item.uuid))}>
                      <div className='flex flex-col gap-1'>
                        <h5 className='text-[0.825rem] text-gray-900 font-semibold'>{item.name}</h5>
                        <h5 className='text-[0.7rem] font-semibold text-blueSecondary'>{state?.schedule_list[id]?.start_time?.hours?.padStart(2, '0')}:{state?.schedule_list[id]?.start_time?.minutes?.padStart(2, '0')}{state?.schedule_list[id]?.start_time?.period} - {state?.schedule_list[id]?.end_time?.hours?.padStart(2, '0')}:{state?.schedule_list[id]?.end_time?.minutes?.padStart(2, '0')}{state?.schedule_list[id]?.end_time?.period} {state?.schedule_list[id]?.time_zone}</h5>
                      </div>
                      <div className='flex gap-1 items-center'>
                        <button onClick={() => { setEditScheduleModal(!editScheduleModal); dispatch(setCurrentSchedule(id)) }}><MdModeEdit className='text-paused-row size-3.5 hover:border hover:border-blueSecondary hover:rounded-full hover:text-blueSecondary hover:p-0.5 hover:size-4  transition-all cursor-pointer' /></button>
                        <RiDeleteBin5Line className={`text-paused-row size-4 cursor-pointer hover:text-blueSecondary transition-all delete_${item.name}`} onClick={() => deletelist(item.uuid)} />
                      </div>
                    </div>)}
                  </div>
                  <br />
                  {editScheduleModal && <SetSchedule position='top-[3.6rem] left-[13rem]' onOutsideClose={() => setEditScheduleModal(false)} onClose={() => { setEditScheduleModal(false); getAllList(); }} />}
                </div>
              </div>
            </div>) : null}
      {custom?.errorMessage?.schedule_id && <h5 className='text-[0.7rem] text-red-500 font-medium text-center -mt-1'>{custom?.errorMessage?.schedule_id}</h5>}
      <hr />

      <div className='flex px-4 mt-3 justify-between items-center mb-3'>
        <div className='flex flex-col gap-1.5'>
          <div className='flex items-center'>
            <Toggle className='scale-[40%] -ml-4 -mr-2' id='isCampaignStartDateSelected' checkedCondition={isCampaignStartDateSelected} changeFunction={handleToggleStartAndEndDate} />
            <h5 className='text-column font-medium text-[0.82rem]'>Campaign start date</h5>
          </div>
          <div className='relative'>
            <div className={`flex relative justify-between items-center w-[21rem] border border-[#D0D5DD] rounded-md px-3.5 py-3 cursor-pointer ${isCampaignStartDateSelected ? 'text-gray-900' : 'bg-[#8F9BBA1A] text-[#8F9BBA] pointer-events-none'} transition-all`} onClick={() => setStartDateModal(!startDateModal)}>
              <h5 className='text-[0.825rem] font-semibold'>{formatDateIntoDDMYYYY(state?.scheduleSettings?.campaign_start_date)}</h5>
              <MdModeEdit className='text-paused-row size-3.5 hover:border hover:border-blueSecondary hover:rounded-full hover:text-blueSecondary hover:p-0.5 hover:size-4  transition-all cursor-pointer' />
            </div>
            {startDateModal && <SetDate
              setDateModal={setStartDateModal}
              value={state?.scheduleSettings?.campaign_start_date}
              onChange={value => dispatch(setCampaignPeriod({ key: 'campaign_start_date', value: new Date(value).toString() }))} />}
          </div>
        </div>
        <div className='flex flex-col gap-1.5'>
          <div className='flex items-center'>
            <Toggle className='scale-[40%] -ml-4 -mr-2' id='isCampaignEndDateSelected' checkedCondition={isCampaignEndDateSelected} changeFunction={handleToggleStartAndEndDate} />
            <h5 className='text-column font-medium text-[0.82rem]'>Campaign end date</h5>
          </div>
          <div className='relative'>
            <div className={`flex relative justify-between items-center w-[21rem] border border-[#D0D5DD] rounded-md px-3.5 py-3 cursor-pointer ${isCampaignEndDateSelected ? 'text-gray-900' : 'bg-[#8F9BBA1A] text-[#8F9BBA] pointer-events-none'} transition-all`} onClick={() => setEndDateModal(!endDateModal)}>
              <h5 className='text-[0.825rem] font-semibold'>{formatDateIntoDDMYYYY(state?.scheduleSettings?.campaign_end_date)}</h5>
              <MdModeEdit className='text-paused-row size-3.5 hover:border hover:border-blueSecondary hover:rounded-full hover:text-blueSecondary hover:p-0.5 hover:size-4  transition-all cursor-pointer' />
            </div>
            {endDateModal && <SetDate
              setDateModal={setEndDateModal}
              value={state?.scheduleSettings?.campaign_end_date}
              onChange={value => dispatch(setCampaignPeriod({ key: 'campaign_end_date', value: new Date(value).toString() }))} />}
          </div>
        </div>
      </div>
      {custom?.errorMessage?.settings_data?.campaign_end_date && <h5 className='text-[0.7rem] text-red-500 font-medium text-center my-1'>{custom?.errorMessage?.settings_data?.campaign_end_date}</h5>}
      <hr />

      <div className='px-4 flex gap-2 py-1 items-center'>
        <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Skip national holidays</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.settings?.skip_holidays?.status} changeFunction={() => dispatch(setHolidays({ status: !state?.settings?.skip_holidays?.status }))} />
        </div>
        {/* <ReactFlagsSelect
        selectedSize={12}
        optionsSize={12}
        className='mt-1 w-40'
          selected= {state?.settings?.skip_holidays?.country} 
          onSelect={country => {dispatch(setHolidays({ country })); console.log(country)}}
          countries={countryList}
          disabled = {!state?.settings?.skip_holidays?.status}
          searchable
        /> */}
      </div>
      <hr />

      <h5 className='text-column font-medium text-[0.82rem] px-4 mt-2'>Stop Sequence To</h5>
      <div className='px-4 flex gap-2 mb-2'>
        <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Reply for an email</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.stop_reply_to?.reply_for_email} changeFunction={() => dispatch(setReplyData('reply_for_email'))} />
        </div>
        {/* <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Reply for Linkedin message</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.stop_reply_to?.reply_for_linkedin} changeFunction={() => dispatch(setReplyData('reply_for_linkedin'))} />
        </div> */}
        {/* <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Call happened</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.stop_reply_to?.reply_for_call} changeFunction={() => dispatch(setReplyData('reply_for_call'))} />
        </div> */}
        {/* <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Clicks on a link</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.stop_reply_to?.click_on_link} changeFunction={() => dispatch(setReplyData('click_on_link'))} />
        </div> */}
      </div>
      <hr className='mb-1' />
      <h5 className='text-column font-medium text-[0.82rem] px-4 mt-2'>Tracking options</h5>
      <div className='px-4 flex gap-2 mb-2'>
        <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Track opens</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.tracking_options?.open} changeFunction={() => dispatch(setTrackingData('open'))} />
        </div>
        {/* <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Track link clicks</h5>
          <Toggle className='scale-[.4]' checkedCondition={state?.tracking_options?.link_clicks} changeFunction={() => dispatch(setTrackingData('link_clicks'))} />
        </div> */}
      </div>
      {/* <hr className='mb-1' />
      <h5 className='text-column font-medium text-[0.82rem] px-4 mt-2'>Set sending priority</h5>
      <div className='px-4 flex gap-2'>
        <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Prioritize follow-ups</h5>
          <Toggle className='scale-[.4]' checkedCondition={sending_priority?.prioritize_follow_ups} id='prioritize_follow_ups' changeFunction={handleChangeSendingPriority} />
        </div>
        <div className='flex items-center'>
          <h5 className='text-[0.73rem] font-semibold'>Prioritize this campaign</h5>
          <Toggle className='scale-[.4]' checkedCondition={sending_priority?.prioritize_this_campaign} id='prioritize_this_campaign' changeFunction={handleChangeSendingPriority} />
        </div>
      </div> */}
    </div>
  )
}

export default ScheduleSettings