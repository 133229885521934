/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { MdDns } from 'react-icons/md';
import NotificationSettingsPopup from './NotificationSettings';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
import { toastState } from 'common/utils/utility';

import { useAppDispatch } from 'store';
import { setNotificationData, setNotificationType } from 'store/notification-settings';
import { PUBLIC_VAPID_KEY } from 'common/constants/constants';

const Notifications = () => {
  const [isopen, setIsopen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const fetchNotificationData = async (key: string) => {
    try {
      const response = await BaseApi.get(`/notification/${key}`);

      if (response?.error) {
        toast.error('error while fetching data', toastState.error);
      } else {
        dispatch(setNotificationData(response?.data));
        dispatch(setNotificationType(key));

        setIsopen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const BrowserSettings = async () => {
    const publicVapidKey = PUBLIC_VAPID_KEY;
    function urlBase64ToUint8Array(base64String: string): Uint8Array {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const permissionResult = await Notification.requestPermission();
      if (permissionResult === 'granted') {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(async (registration) => {
            fetchNotificationData('browser_notify');
            const subscription = await registration.pushManager.getSubscription();
            if (!subscription) {
              const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
              };

              const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);
              await BaseApi.post(`/notification/subscribe`, { pushSubscription });
              toast.success('Browser notifications enabled successfully', toastState.success);
            }
          })
          .catch((err) => {
            console.error('Service Worker registration failed:', err);
          });
      } else if (permissionResult === 'denied') {
        toast.error('You have denied notifications. To reset, please check your browser settings.', toastState.error);
      }
    } else {
      toast.error('Service Worker or Push Manager not supported in this browser', toastState.error);
      return;
    }
  };

  const handleSettingsOption = async (key: string) => {
    if (key === 'browser_notify') {
      BrowserSettings();
    } else {
      fetchNotificationData(key);
    }
  };

  return (
    <div className="mt-3 flex min-h-[80vh] w-full items-center justify-center bg-white py-3 dark:!bg-darkOne dark:!text-white">
      <div className="w-[46rem] rounded-md p-10 shadow-lg dark:shadow-darkBorder">
        <div>
          <h3 className="mb-6 text-lg font-bold text-heading dark:!text-bglightSkyBlue">Notification Settings</h3>
          <div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption('inbox_notify');
              }}
            >
              <div>
                <MdDns className="text-column dark:text-gray-300" size={'20px'} />
              </div>
              <div className=" grow ">
                <div className="mt-0 pb-0 font-semibold text-heading dark:text-gray-200">Inbox</div>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div className="self-start">
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption('email_notify');
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="self-top grow">
                <p className="font-semibold text-heading dark:text-gray-200">Email</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
            <div
              className="mb-5 flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6 py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption('browser_notify');
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="self-top grow">
                <p className="font-semibold text-heading dark:text-gray-200">Browser</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">
                  Enable Notification
                </div>
              </div>
            </div>
            <div
              className="flex cursor-pointer gap-4 rounded-lg border border-[#E5E5E5] px-6  py-4 dark:border-darkBorder"
              onClick={() => {
                handleSettingsOption('sms_notify');
              }}
            >
              <MdDns className="text-column dark:text-gray-300" size={'20px'} />

              <div className="grow align-top">
                <p className="font-semibold text-heading dark:text-gray-200">SMS</p>
                <p className="text-sm text-column dark:text-gray-400">
                  <span>Default &nbsp;</span>Sparkle's recommendation settings
                </p>
              </div>
              <div>
                <div className="flex h-10 w-48 items-center justify-center rounded-md border border-[#D0D5DD] text-column dark:border-darkBorder dark:text-gray-400 ">Default</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isopen && (
        <NotificationSettingsPopup
          isOpen={isopen}
          onClose={() => {
            setIsopen(false);
          }}
        />
      )}
    </div>
  );
};

export default Notifications;
