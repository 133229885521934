import React from 'react';
import SelectEmailAccountLayOut from '../../email-add/select-method';
import SearchNumbers from './SearchNumbers';
import NumbersBillingPage from './NumbersBillingPage';

const PurchaseNumbersList = () => {
    const [stage,setStage] =React.useState(0)
  return (
    <SelectEmailAccountLayOut>
        {stage===0 && <SearchNumbers setStage={setStage}/>}
        {stage===1 && <NumbersBillingPage/>}
    </SelectEmailAccountLayOut>
  );
};

export default PurchaseNumbersList;
