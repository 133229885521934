import 'assets/css/Plugins.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MainRoutes from 'routes/routes';
import { ChakraProvider } from '@chakra-ui/react';
import './assets/scss/App.scss';
import Intercom from '@intercom/messenger-js-sdk';
import { INTERCOM_APP_ID, INTERCOM_CREATED_AT } from 'common/constants/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { setEnvironment } from 'store/global-settings';
const App = () => {
  const { loggedUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  if (loggedUser) {
    Intercom({
      app_id: INTERCOM_APP_ID,
      user_id: loggedUser?.uuid,
      name: `${loggedUser?.first_name || ''} ${loggedUser?.last_name || ''}`,
      email: loggedUser?.email,
      created_at: INTERCOM_CREATED_AT,
    });
  } else {
    Intercom({
      app_id: INTERCOM_APP_ID,
    });
  }

  useEffect(() => {
    const fetchAppSettings = async () => {
      try {
        const response = await BaseApi.get(`app/feature/environment`);
        if (response.data) {
          dispatch(setEnvironment(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAppSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ChakraProvider>
      <MainRoutes />
      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
