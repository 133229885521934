import { ticketDrawerDetailsType, ticketType } from "common/dto/types/views/true-verfier";


export const ticketDrawerDetails: ticketDrawerDetailsType = {
  uuid: '',
  subject: '',
  emails: [],
  message: '',
  name:''
}

export const ticket : ticketType = {
  ticket_id:'',
  status:0,
  closed_date:'',
  created_date:''
}