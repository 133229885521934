import React from 'react';
import FilterProspects, { getParams } from './features/FilterProspects';
import Button from 'common/sparkle-common/Button';
import TableList from './features/TableList';
import { useAppDispatch, useAppSelector } from 'store';
// import { setProspects } from 'store/campaign-v2';
// import { setCurrentStepCount } from 'store/custom';
import AccessDataButton from './features/AccessDataButton';
import { BiSolidContact } from 'react-icons/bi';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { LuAlertTriangle } from 'react-icons/lu';
import { setIds, setProspects, setProspectsVariable, setSearchProspectsData } from 'store/campaign-v2';
import { setCurrentStepCount } from 'store/custom';
import { _errorHandler, jwtEncrypt, toastState } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';

const SearchedProspectsList = () => {
  const selectedProspects = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.selected_prospects);
  const totalRecords = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.total_records);
  const data = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.data);
  const countData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.users_count);
  const campaign_name = useAppSelector((state) => state?.campaignv2?.campaign_name);
  const campaign_id = useAppSelector((state) => state?.campaignv2?.ids?.campaign_id);
  const prospects_id = useAppSelector((state) => state?.campaignv2?.ids?.prospects_id);
  const tool_details = useAppSelector((state) => state.campaignv2.prospects.search_prospects.tool_details);
  const searchedProspects = useAppSelector((state) => state.campaignv2.prospects.search_prospects.data);
  const apiData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.tool_details);
  const filterData = useAppSelector((state) => state.campaignv2.prospects.search_prospects.prospects_filter.filter_data);
  const selected_variable = useAppSelector((state) => state.campaignv2.prospects.selected_variable);

  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState({
    addCampaignButton: false,
    continueCampaign: false
  })
  const dispatch = useAppDispatch();

  const createCustomVariable = async (prospectsData: any[]) => {
    try {
      const customVariableApi = async (variable: string) => {
        const params = {
          campaign_id,
          variable: variable
        }
        const response = await BaseApi.post('/map/variables/linked/campaign', params)
        if (response?.error) {
          toast.error(_errorHandler(response?.message), toastState.error);
          return;
        }
        else {
          const { insert_id, ...rest } = response.data;
          return { variable, data: { ...rest } }
        }
      }
      let payLoad = {}
      // const selected_variable = Object.values(selected_variable)?.filter(item => item?.name !== "select").map(item => item.name);
      // const neededCustomeVariable = selected_variable ? Object.keys(prospects?.prospects_list[0])?.filter((key) => {
      //     return (key !== "email" && !selected_variable?.includes(key))
      // }) : Object.keys(prospects?.prospects_list[0]).filter(item => item !== "email");
      const neededCustomeVariable = Object.keys(prospectsData[0]).filter((item) => {
        if (item === 'id' || item === "github_url" || item === 'organization_id' || item === "email") {
          return false
        } else {
          if (selected_variable?.[item]) {
            return false
          } else {
            return true
          }
        }
      })

      if (neededCustomeVariable?.length) {
        const createCustomVariables = await Promise.all(neededCustomeVariable?.map((item) => customVariableApi(item)));
        const selectedVariables = createCustomVariables.filter(Boolean)
        if (selectedVariables?.length) {
          selectedVariables?.forEach(element => {
            payLoad = {
              ...payLoad,
              [element?.variable]: element?.data
            }
            // dispatch(setProspectsVariable({ [element?.variable]: element?.data }))
          });
        }
        // console.log('prospectsData', prospects?.selected_variable)
        // console.log('createCustomVariables', createCustomVariables)
        const prospects_list = prospectsData?.map((item: any) => {
          // createCustomVariables?.reduce((acc: any, variable: any) => {
          //   acc[variable?.data?.name] = item[variable?.variable];
          //   delete acc[variable?.variable]
          //   return acc
          // }, { ...item })
          return Object.keys(item)?.reduce((acc: any, key: string) => {
            if (selected_variable?.[key]) {
              acc[selected_variable[key]?.name] = item[key];
              delete acc[key]
            } else {
              const newVariable = createCustomVariables?.find((variable: any) => variable?.variable === key)
              if (newVariable) {
                acc[newVariable?.data?.name] = item[key];
                delete acc[key]
              }
            }
            return acc;
          }, { ...item })
        })
        return { prospects_list, selected_variable: payLoad }
      } else {
        const prospects_list = prospectsData?.map((item: any) => {
          // createCustomVariables?.reduce((acc: any, variable: any) => {
          //   acc[variable?.data?.name] = item[variable?.variable];
          //   delete acc[variable?.variable]
          //   return acc
          // }, { ...item })
          return Object.keys(item)?.reduce((acc: any, key: string) => {
            if (selected_variable[key]) {
              acc[selected_variable[key]?.name] = item[key];
              delete acc[key]
            }
            return acc;
          }, { ...item })
        })
        return { prospects_list, selected_variable: {} }
      }
    } catch (error) {
      console.log('createCustomVariable error',error)
      const prospects_list = prospectsData?.map((item: any) => {
        // createCustomVariables?.reduce((acc: any, variable: any) => {
        //   acc[variable?.data?.name] = item[variable?.variable];
        //   delete acc[variable?.variable]
        //   return acc
        // }, { ...item })
        return Object.keys(item)?.reduce((acc: any, key: string) => {
          if (selected_variable[key]) {
            acc[selected_variable[key]?.name] = item[key];
            delete acc[key]
          }
          return acc;
        }, { ...item })
      })
      return { prospects_list, selected_variable: {} }
    }
  }

  const handleAddProspectsList = async (prospectsData: any[]) => {
    try {

      const { prospects_list, selected_variable } = await createCustomVariable(prospectsData?.map(({ photo_url, twitter_url, github_url, facebook_url, organization_logo_url, ...rest }: any) => ({ ...rest })));
      // console.log('selected_variable', selected_variable)
      const params = {
        campaign_name: campaign_name,
        ...(campaign_id && { campaign_id }),
        prospects_data: {
          ...(prospects_id && { prospects_id }),
          prospects_total_count: prospects_list.length,
          prospects_list: prospects_list,
          ...(Object.values(selected_variable)?.length ? { selected_variable } : {}),
        },
      };
      const prospectAddingResponse = await BaseApi.post('/campaign/prospects', params);
      if (prospectAddingResponse?.error) {
        toast.error(_errorHandler(prospectAddingResponse?.message), toastState.error)
      } else {
        dispatch(setProspectsVariable(selected_variable))
        dispatch(setIds({ prospects_id: prospectAddingResponse?.data?.prospects_id }))
        // const convertedData = prospectsData?.map(({ id, github_url, organization_id, ...rest }: any) => ({ ...rest }));
        dispatch(setProspects({ prospects_list: prospects_list }));

        const responseData = prospectsData.map((item: any) => ({ id: item?.id, email: item?.email }));
        const updatedData = searchedProspects?.map((item) => {
          const enrichedItem = responseData.find((element: any) => element?.id === item?.id);
          return enrichedItem ? { ...item, email: enrichedItem.email } : item;
        }) || [];

        dispatch(setSearchProspectsData(updatedData));
        dispatch(setCurrentStepCount(4));
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddToCampaign = async () => {
    try {
      setLoading((prev) => ({ ...prev, addCampaignButton: true }))
      const checkedProspects = selectedProspects?.filter((item: { email: string }) => item?.email === 'email_not_unlocked@domain.com' || item?.email === '')
      if (checkedProspects?.length > 0 || countData?.select_number_of_people > 25) {
        setIsOpen(true);
      } else {
        // const formattedProspects = selectedProspects?.map(({ id, uuid, sparkle_leads_id, campaign_id, temprory_id, ...rest }) => ({ ...rest }));
        await handleAddProspectsList(selectedProspects)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading((prev) => ({ ...prev, addCampaignButton: false }))
    }

    // const formattedProspects = selectedProspects?.map(({ uuid, social, organization, ...rest }: any) => ({
    //   ...rest,
    //   linkedin_url: social?.linkedin_url,
    //   twitter_url: social?.twitter_url,
    //   organization_name: organization?.name,
    //   organization_phone: organization?.phone,
    //   organization_founded_year: organization?.founded_year,
    //   organization_logo_url: organization?.logo_url,
    //   organization_website_url: organization?.website_url,
    //   organization_linkedin_url: organization?.linkedin_url,
    //   organization_twitter_url: organization?.twitter_url,
    // }));
    // dispatch(setProspects({ prospects_list: formattedProspects }));
    // dispatch(setCurrentStepCount(4));
  };
  // const selectedProspectsId = selectedProspects.map(item => item.uuid)
  // const accessedEmailCount = data?.filter((item) => {
  //   if (selectedProspectsId.includes(item.uuid)) {
  //     if (item.email === 'email_not_unlocked@domain.com') {
  //       return false
  //     } else {
  //       return true
  //     }
  //   } else {
  //     return false
  //   }
  // }).map((item) => item.uuid)
  const assessedEmails = selectedProspects?.filter((item) => {
    if (item?.email !== "email_not_unlocked@domain.com") {
      return true
    } else {
      return false
    }
  })


  // Emails need to enrich
  const emailCount = countData?.select_number_of_people <= 25 ? (selectedProspects?.length - assessedEmails?.length) : (countData?.select_number_of_people - assessedEmails?.length)

  const handleContinue = async () => {
    try {
      const encodedData = jwtEncrypt(getParams(apiData?.name, "people", filterData));
      setLoading((prev) => ({ ...prev, continueCampaign: true }))
      const selectedEnrichmentData = selectedProspects?.filter((item: { email: string }) => item?.email === 'email_not_unlocked@domain.com' || item?.email === '')?.map(({ email, ...rest }) => ({ ...rest }))
      const params = {
        enrichment_tools: [{ name: tool_details?.name, apiKey: tool_details?.api_key_method === 'sparkle' ? "sparkle" : tool_details?.api_key }],
        ...(countData?.select_number_of_people <= 25 && { prospects_list: selectedEnrichmentData }),
        prospects_count: countData?.select_number_of_people <= 25 ? selectedEnrichmentData?.length : countData?.select_number_of_people,
        ...(countData?.select_number_of_people > 25 && { search_prospect_api_key: tool_details?.api_key_method === 'sparkle' ? "sparkle" : tool_details?.api_key }),
        ...(countData?.select_number_of_people > 25 && { filter_data: encodedData }),
        ...(countData?.max_people && { max_people_per_company: countData?.max_people_per_company }),
        keys: ["email"],
        // ...(countData?.select_number_of_people <= 25 && {
        //   matched_keys: {
        //     id: "id",
        //     first_name: "first_name",
        //     last_name: "last_name",
        //     name: "full_name",
        //     linkedin_url: "linkedin_url",
        //     organization_name: "organization_name"
        //   }
        // }
        // )
        ...(assessedEmails?.length ? { donot_enrich_list: assessedEmails } : {})
      }
      const enrichResponse = await BaseApi.post('/prospects/enrich', params);
      if (!enrichResponse.error) {
        await handleAddProspectsList(enrichResponse?.data);
        // await handleAddProspectsList([...assessedEmails, ...enrichResponse?.data])
      }
      else {
        toast.error(_errorHandler(enrichResponse?.error), toastState?.error);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading((prev) => ({ ...prev, continueCampaign: false }))
    }
  }
  return (
    <div className="flex h-[78vh] rounded-md bg-white p-3">
      <FilterProspects />
      {/* Table */}
      <div className="w-full overflow-x-auto rounded-md border">
        <div className="flex items-center justify-between border-b px-5 py-3">
          <span className="text-[.8125rem] font-medium text-column">{data?.length ? `Previewing ${data?.length}/${totalRecords} prospects ${selectedProspects?.length ? `and selected ${countData?.select_number_of_people > 25 ? countData?.select_number_of_people : selectedProspects?.length} prospects` : ''}` : ''}</span>
          <div className="flex gap-2">
            {selectedProspects?.length > 1 &&
              selectedProspects?.filter((item: { email: string }) => item?.email === 'email_not_unlocked@domain.com' || item?.email === '')?.length > 0 ? (
              <AccessDataButton
                prospectId={selectedProspects?.filter((item: { email: string }) => item?.email === 'email_not_unlocked@domain.com' || item?.email === '')}
                enrichmentType={'email'}
                text={'Access email'}
                leftIcon={<BiSolidContact size={15} />}
                textColor={'text-heading'}
                borderColor={'border-heading'}
              />
            ) : null}

            <Button name="Contiue" loading={loading?.addCampaignButton} disable={loading?.addCampaignButton || selectedProspects?.length <= 0} onClick={handleAddToCampaign} />
          </div>
        </div>
        <TableList />
      </div>

      <Modal isCentered isOpen={isOpen} onClose={() => { }} size="lg">
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="">
          <ModalHeader className="mb-1 bg-[#FAFAFC] ">
            <div className="flex items-center p-1">
              <div className="rounded-full border-4 border-yellow-100 bg-yellow-200  p-1 text-orange-500">
                <LuAlertTriangle size={24} />
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="!p-3">
            <>
              <div className=" mx-2 flex flex-col">
                <span className=" font-semibold">Alert</span>
                <span className="text-wrap text-base">Accessing {emailCount} email-ids will cost xyz Sparkles. Do you want to continue?</span>
              </div>
            </>
          </ModalBody>
          <ModalFooter className="!-mt-2 !mb-1">
            <>
              <Button
                id="enrichment-close"
                name="Back"
                onClick={() => {
                  setIsOpen(false);
                }}
                customClass="!bg-[#F4F7FE] w-full !text-blackDark border border-2 !border-[#A3AED0] !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
              />
              <Button
                id="enrichment-continue"
                loading={loading?.continueCampaign}
                disable={loading?.continueCampaign}
                name="Continue"
                onClick={handleContinue}
                customClass={`w-full  !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem]`}
              />
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SearchedProspectsList;
