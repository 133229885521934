import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import CustomBadge from 'components/badges/CustomBadge';
import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setAccountType } from 'store/sender-accounts';

interface ModalPropsDTO {
  isOpen?: boolean;
  channelType?: string;
}

const SelectMethodPopup = (props: ModalPropsDTO) => {
  const [account_type, setSelectedAccountType] = useState(1);
  const dispatch = useAppDispatch();
  const { isOpen, channelType } = props;
  const navigate = useNavigate();

  const handleLinkedinOAuth = async () => {
    try {
      const response = await BaseApi.get('/sender-accounts/generate/linkedin/auth/url');
      // console.log('data', response?.data);
      // window.open(response?.data, '_blank');
      window.location.href = response?.data;
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleSelect = (count: number) => {
    setSelectedAccountType(count);
  };
  const handleContinue = () => {
    dispatch(setAccountType(''));

    if (account_type === 1) {
      if (channelType === 'email') {
        navigate('add/email');
      } else if (channelType === 'linkedin') {
        handleLinkedinOAuth();
      }
    }

    if (account_type === 2 && channelType === 'email') {
      navigate('buy/domain');
    }
  };

  //   Handle continue button
  // const handleContinue = () => {
  //   dispatch(setAccountType(''));
  //   if (account_type === 1) {
  //     channelType === 'email' && navigate('/sender/add');
  //     channelType === 'linkedin' && al
  //   }
  //   if (account_type === 2) channelType === 'email' && navigate('/sender/domain');
  // };

  // Handle Cancel button
  const handleCancel = () => {
    dispatch(setAccountType(''));
  };

  const emailData = [
    {
      title: 'Connect your email IDs',
      sub_title: 'Use your existing email addresses.',
      icon: <IoCloudUploadOutline className="mt-2 text-[3.125rem] text-darkBlue dark:!text-purple-300" />,
      type: 1,
    },
    {
      title: 'Buy Email IDs',
      sub_title: ' Purchase fully set up, ready-to-use email accounts. These come with full ownership of the domains and customizable email ids.',
      icon: <AiOutlineSearch className="text-[1.5rem] text-heading dark:!text-purple-300" />,
      type: 2,
    },
  ];

  // const linkedInData = [
  //   {
  //     title: 'Connect your LinkedIn IDs',
  //     sub_title: 'Use your existing linkedin account.',
  //     icon: <IoCloudUploadOutline className="mt-2 text-[3.125rem] text-darkBlue" />,
  //     type: 1,
  //   },
  // ];

  const data = emailData;
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => dispatch(setAccountType(''))} size={'3xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="!p-0 dark:bg-darkThree ">
          <ModalHeader>
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold text-heading dark:text-gray-300">Add Accounts</h4>
              <span className="text-[11px] text-column dark:text-gray-500">Select the method of creating {channelType} accounts</span>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <ModalBody className="!p-0">
            <div className="flex items-center justify-center">
              <div className="w-[48rem] rounded-md bg-white p-5 shadow-md dark:bg-darkThree">
                <div className=" flex h-36 select-none gap-4">
                  {data.map((item, index) => (
                    <div
                      id="upload_csv_section"
                      className={`flex w-full cursor-pointer flex-col items-center justify-center rounded-md border ${
                        account_type === item.type ? 'border-blueSecondary bg-bgInside dark:bg-navy-700' : ''
                      } px-4`}
                      key={index}
                      onClick={item.title === 'Buy Email IDs' ? null : () => handleSelect(item.type)}
                    >
                      {item.icon}
                      <h4
                        className={`${
                          account_type === item.type ? 'text-blackDark dark:text-white' : 'text-grayish-blue dark:text-white'
                        } mt-1 flex items-center justify-center gap-2 text-[.8125rem] font-semibold`}
                      >
                        {item.title}
                        {/* <span>
                          <IoIosInformationCircle className="text-column dark:text-gray-400" />
                        </span> */}
                      </h4>
                      <span className={` text-center text-[.6875rem] font-medium ${account_type === item.type ? 'text-heading' : 'text-column'} dark:text-gray-300 `}>
                        {item.sub_title}
                      </span>
                      {item?.title === 'Buy Email IDs' ? (
                        <div className="mt-2">
                          <CustomBadge content={'Coming soon'} bgColor="active-bg" color={'textBlue'} extra="h-5 !font-semibold text-[.625rem] !px-2 !py-0.5" />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="mt-6 flex gap-4">
                  <Button name="Cancel" customClass="w-full cancel_btn_sender_account bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={handleCancel} />
                  <Button id="prospect_upload_continue_btn" name="Continue" customClass="continue_btn_sender_account w-full dark:border-none text-[.82rem]" onClick={handleContinue} />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SelectMethodPopup;
