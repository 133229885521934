import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
import FileUploader from 'common/sparkle-common/FileUploader';
import { checkRolePermissionStatus } from 'common/utils/utility';
import TagsField from 'components/fields/TagsField';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
// import { FiChevronLeft } from 'react-icons/fi'
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getErrorMessage, setFlagStatus } from 'store/custom';

const AddDoNotLists = () => {
  const [switchState, setSwitchState] = React.useState(1);
  const [entries, setEntries] = React.useState({ data: [], loading: false });
  const dispatch = useAppDispatch();
  const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean } }) => state.custom.flagStatus);

  const [donotemailStatus, setDonotemailStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'do_not_email_list');
      setDonotemailStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();

  const handleAddEntries = async (entryType: string, data: any) => {
    try {
      setEntries((prev) => ({ ...prev, loading: true }));
      let lists;
      if (entryType === 'upload') {
        lists = data?.map((item: any) => {
          return { data: item['Email/Domain'] };
        });
      } else {
        lists = data?.map((item: any) => {
          return { data: item.name };
        });
      }
      const brandId = sessionStorage.getItem('brandId');
      const params = {
        uuid: brandId,
        data_list: lists,
      };
      const response = await BaseApi.patch('/donot/list', params);
      if (response?.error) {
        dispatch(getErrorMessage(response?.message));
      } else {
        setEntries((prev) => ({ ...prev, data: [] }));
        dispatch(setFlagStatus(!flagStatus));
      }
    } catch (error) {
      console.log('Do not mail list adding error', error);
    } finally {
      setEntries((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <div className="mx-5 my-5 min-h-[28rem] rounded-sm ">
      {/* Head */}
      <div className="select-none bg-white px-[2.4375rem] pt-4 dark:bg-darkOne ">
        <div className="flex items-center gap-4 dark:bg-darkOne">
          <div id='section-back-btn' className="cursor-pointer rounded-[.25rem] border dark:bg-darkThree border-gray-300 p-[.0938rem] duration-300 hover:bg-lightPrimary" onClick={() => navigate('/settings/donot/list')}>
            <FiChevronLeft className="text-sm text-iconGray dark:text-gray-200" />
          </div>
          <h4 className="mt-1 text-[1.125rem] font-medium dark:text-white">Do-not-Email Lists</h4>
        </div>
        <div className="mt-[1.625rem] flex h-11 w-full">
          {(donotemailStatus?.is_owner || donotemailStatus?.edit) && (
            <div className="relative w-full cursor-pointer text-center" onClick={() => setSwitchState(1)}>
              <h4 className={`mt-1 text-[.9375rem] font-semibold ${switchState === 1 ? 'dark:text-white' : 'text-gray-700'}`}>Add Manually</h4>
              <div className={`absolute bottom-0 h-[.2188rem] w-full bg-navBlue ${switchState === 1 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
          {(donotemailStatus?.is_owner || donotemailStatus?.edit) && (
            <div id="donot_email_list_upload_csv_section" className="relative w-full cursor-pointer text-center" onClick={() => setSwitchState(2)}>
              <h4 className={`mt-1 text-[.9375rem] font-semibold ${switchState === 2 ? 'dark:text-white' : 'text-gray-700'}`}>Upload From File</h4>
              <div className={`absolute bottom-0 h-[.2188rem] w-full bg-navBlue ${switchState === 2 ? 'bolck' : 'hidden'}`} />
            </div>
          )}
        </div>
      </div>
      {/* Body */}
      <div className=" select-none px-[2.375rem] py-8 text-[.9rem] font-semibold dark:bg-darkThree ">
        {switchState === 1 ? (
          // Manual list
          <div className="flex flex-col gap-4 dark:text-white">
            <h4>Enter Email or Domain</h4>
            {(donotemailStatus?.is_owner || donotemailStatus?.edit) && (
              <TagsField
                extra="!pl-4 !pr-0 !py-0 !border-none dark:!bg-darkTwo !bg-lightGray !pb-[11.25rem] max-h-[11.25rem] "
                inputId="add_list"
                className="domain-manual-input  rounded-sm !bg-lightGray pt-4 "
                onChange={(data: any) => setEntries((prev) => ({ ...prev, data }))}
                placeholderTags={entries?.data}
                pattern={/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/}
              />
            )}
            {(donotemailStatus?.is_owner || donotemailStatus?.edit) && (
              <div className="mt-1.5 flex h-9 w-[17.5rem] gap-4">
                <Button
                  name="Clear"
                  customClass="w-full bg-white !text-gray-800 !duration-300 text-[13px] !font-normal clear_btn"
                  onClick={() => setEntries({ data: [], loading: false })}
                />
                <Button
                  id="donot_email_list_add_btn"
                  loading={entries?.loading}
                  name="Add"
                  customClass="w-full text-[13px] !font-normal add_btn"
                  LeftIcon={<MdAdd className="text-[16px]" />}
                  disable={entries.loading || entries?.data?.length <= 0}
                  onClick={() => {
                    handleAddEntries('manual', entries?.data);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          // Upload CSV
          <div className="flex items-center justify-center">
            <div className="w-[21.875rem]">
              <FileUploader
                pattern={/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/}
                onUpload={(data: any) => handleAddEntries('upload', data?.file_data)}
                keys={['Email/Domain']}
                patternKey="Email/Domain"
                confirmation={true}
                sampleCSVData={[{ 'Email/Domain': 'john@doe.com' }, { 'Email/Domain': 'jane@smith.com' }, { 'Email/Domain': 'doe.com' }, { 'Email/Domain': 'smith.com' }]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDoNotLists;
