import { usageCardsData } from 'common/dto/initial-value/views/billing'
import SparkleCheckBox from 'common/sparkle-common/checkBox'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { setSelectedCards } from 'store/account-settings'

interface PropsType {
  loading: boolean
}
const Cards = (props: PropsType) => {
  const { loading } = props
  const dispatch = useAppDispatch();
  const selectedCards = useAppSelector((state) => state?.accountSettings?.billing?.usage?.selected_cards);
  const graphData = useAppSelector((state) => state?.accountSettings?.billing?.usage?.graph_data);
  const [credits, setCredits] = React.useState<{ [key: string]: number }>({
    email_sent: 0,
    true_verify: 0,
    enrichment: 0,
    search_prospects: 0,
    blacklist_monitor: 0,
    email_verify: 0,
    inbox_reply: 0,
    crm_reply: 0,
    payg: 0,
  })

  React.useEffect(() => {
    if (graphData?.length) {
      const findCredits = graphData?.reduce((acc, data) => {
        Object.keys(data)?.forEach(element => {
          if (element !== 'date') {
            if (acc?.hasOwnProperty(element)) {
              acc[element] = acc[element] + data[element]
            } else {
              acc[element] = data[element]
            }
          }
        })
        return acc
      }, {});
      setCredits((prev) => ({ ...prev, ...findCredits }))
    } else {
      setCredits({
        email_sent: 0,
        true_verify: 0,
        enrichment: 0,
        search_prospects: 0,
        blacklist_monitor: 0,
        email_verify: 0,
        inbox_reply: 0,
        crm_reply: 0,
        payg: 0,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  const handleCardSelect = (feature: string) => {
    dispatch(setSelectedCards(feature))
  }
  return (
    <div className='flex gap-4 justify-between overflow-x-auto'>{
      loading ?
        usageCardsData?.slice(0, 5)?.map((card, i) => {
          return (
            <div key={i} className='bg-gray-100 animate-pulse flex py-6 px-3 shadow-sm gap-2 justify-start items-center border-2 border-gray-100 rounded-md w-full'>
              <span className='w-4 h-3 bg-gray-300 animate-pulse'></span>
              <span className='bg-gray-300 animate-pulse h-3 w-full'></span>
              <h5 className='font-bold text-3xl text-gray-300 animate-pulse'>{Intl.NumberFormat('en-US').format(0)}</h5>
              <span className={`p-1 rounded-full bg-gray-300 animate-pulse`}></span>
            </div>
          )
        })
        :
        usageCardsData?.slice(0, 5)?.map((card, i) => {
          return (
            <div key={i} onClick={() => handleCardSelect(card?.name)} className='bg-white select-none cursor-pointer flex py-6 px-3 shadow-sm gap-2 justify-start items-center border-2 border-gray-100 rounded-md w-full'>
              <SparkleCheckBox checked={selectedCards?.includes(card?.name)} onChange={() => {}} />
              <span className='text-xs text-column font-medium w-full'>{card?.name}</span>
              <h5 className='font-bold text-3xl'>{Intl.NumberFormat('en-US').format(credits[card?.key])}</h5>
              <span className={`p-1 rounded-full ${card?.color}`}></span>
            </div>
          )
        })
    }</div>
  )
}

export default Cards