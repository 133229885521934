import { BaseApi } from 'common/api/common/base-api';
import { EDIT_ID } from 'common/constants/constants';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import * as XLSX from 'xlsx';

interface ListDataType {
  email: string;
  verify_details: any;
}
interface RowData {
  // listData: ListDataType[];
  setShowOptions: React.Dispatch<React.SetStateAction<any>>;
  showOptions: boolean;
  totalCount: number;
}

const EmailVerificationExportOptions = (props: RowData) => {
  const { showOptions, totalCount, setShowOptions } = props;

  const handleDownload = async (report: any, fileName: string) => {
    const formatHeaders = (obj: any) => {
      const formattedObj: any = {};
      Object?.keys(obj)?.forEach((key) => {
        // Convert camelCase or snake_case to words with spaces
        const formattedKey = key?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/_/g, ' ');
        formattedObj[formattedKey] = obj[key];
      });
      return formattedObj;
    };

    // Format each row in the report
    const formattedReport = report?.map((row: any) => formatHeaders(row));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedReport);
    XLSX.utils.book_append_sheet(wb, ws, 'Email Verification');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document?.body?.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    // const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet(report);
    // XLSX.utils.book_append_sheet(wb, ws, 'Email Verification');
    // const wbout = XLSX.write(wb, { bookType: 'csv', type: 'string' });
    // const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = fileName;
    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
    // a.remove();
  };

  const handleOptions = async (selectedOpt: string) => {
    try {
      setShowOptions(false);
      const listId = sessionStorage.getItem(EDIT_ID);
      const listData = await BaseApi.get(`/email/verify/list/${listId}?limit=${totalCount}&sort=_id%asc`);
      if (listData.data.data.length) {
        const report = listData.data.data?.map((item: ListDataType) => ({
          email: item?.email,
          result: item?.verify_details?.status?.result,
          ...item?.verify_details?.multi_status,
        }));
        if (selectedOpt === 'all') {
          handleDownload(report, 'email_verification_report.csv');
        } else if (selectedOpt === 'valid') {
          const result = report.filter((item: { result: string }) => item.result === 'valid');
          handleDownload(result, 'email_verification_valid_report.csv');
        } else if (selectedOpt === 'error') {
          const result = report.filter((item: { result: string }) => item.result === 'error');
          handleDownload(result, 'email_verification_error_report.csv');
        } else if (selectedOpt === 'unknown') {
          const result = report.filter((item: { result: string }) => item.result === 'unknown');
          handleDownload(result, 'email_verification_unknown_report.csv');
        } else if (selectedOpt === 'catchall') {
          const result = report.filter((item: { result: string }) => item.result === 'catch_all');
          handleDownload(result, 'email_verification_catchall_report.csv');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = [
    { option: 'Download All Ids', key: 'all' },
    { option: 'Download valid IDs', key: 'valid' },
    { option: 'Download Error IDs', key: 'error' },
    { option: 'Download Unknown IDs', key: 'unknown' },
    { option: 'Download Catch all ID', key: 'catchall' },
  ];

  return (
    <>
      <div className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${showOptions && 'bg-[#4318FF0A]'}`} tabIndex={0}>
        <button
          className="mx-1 flex items-center justify-center gap-3 rounded-md border border-brand-500 bg-brand-50 px-2 py-1.5 text-sm font-medium text-brand-500 transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => setShowOptions(!showOptions)}
        >
          <FaCloudDownloadAlt className="float-right text-lg" /> <span className="text-nowrap text-[14px] font-semibold">Export</span>
          <IoIosArrowDown className="z-50 text-lg text-heading" />
        </button>
        {showOptions && (
          <div className="absolute -left-[30px] z-10 mt-3 flex w-[160px] flex-col overflow-hidden rounded-md border bg-white">
            {options.map((item, index: any) => (
              <div
                key={item.key}
                id={`email-export-${index}`}
                className="campaign-download-status-btn group flex cursor-pointer gap-2 px-3 py-2 font-medium text-[#2B3674] transition-all duration-150 hover:bg-brand-500  hover:text-white"
                onClick={() => handleOptions(item.key)}
              >
                <h5 className="text-xs">{item?.option}</h5>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EmailVerificationExportOptions;
