import React, { useState } from 'react'

const WorldChart = () => {
    const [range, setRange] = useState('');
    console.log(range)
  return (
    <div className='h-[22.3rem] w-[60.3rem] border border-[#EAECF0] rounded-md px-6 py-4 flex'>
        <div className='w-[9.7rem] flex flex-col gap-3'>
                <div className='h-7 w-fit border rounded-md flex font-medium text-heading text-[0.63rem]'>
                    <button onClick={()=> setRange('all')} className='px-3 hover:bg-brand-500 rounded-s-md hover:text-white transition-all'>All</button>
                    <button onClick={()=> setRange('week')} className='px-3 border-x hover:bg-brand-500 hover:text-white transition-all'>7 days</button>
                    <button onClick={()=> setRange('month')} className='px-3 rounded-e-md hover:bg-brand-500 hover:text-white transition-all'>30 days</button>
                </div>
                <div className=''>
                    <h5 className='text-[1rem] text-[#8F9BBA]'>Campaign Reach</h5>
                    <h5 className='text-[1rem] text-heading font-semibold'>12 countries</h5>
                </div>
        </div>
        <div>

        </div>
    </div>
  )
}

export default WorldChart