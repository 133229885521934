import React from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaSignature } from 'react-icons/fa6';
import { MdAccountTree, MdAttachEmail, MdCopyAll } from 'react-icons/md';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';
import { IoIosHelpCircleOutline, IoMdSettings } from 'react-icons/io';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'store';
import { BaseApi } from 'common/api/common/base-api';
import Input from 'common/sparkle-common/Input';
import Button from 'common/sparkle-common/Button';
import Toggle from 'components/Toggle';
import { toastState } from 'common/utils/utility';
import { getErrorMessage, setFlagStatus } from 'store/custom';
import CustomSunEditor from 'common/sparkle-common/TextEditor/TextEditor';

export default function AddEmailAccountsDrawerLayout(props: any) {
  const quillRef = React.useRef(null);
  const { isOpen, onClose, editData, fetchList, multiEditIds } = props;
  const custom = useAppSelector((state) => state?.custom);
  const dispatch = useAppDispatch();
  const [handleState, setHandleState] = React.useState({
    loading: false,
    toggleState: false,
    drawData: editData,
  });

  const handleClose = () => {
    setHandleState((prevState) => ({ ...prevState, drawData: {} }));
    dispatch(setFlagStatus(custom?.flagStatus));
    onClose();
  };


  React.useEffect(() => {
    multiEditIds?.length > 1 &&
      setHandleState((prevState) => ({
        ...prevState,
        drawData: {},
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSenderAccountsFormData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'daily_limit' || name === 'delay_between_emails') {
      setHandleState((prevState) => ({
        ...prevState,
        drawData: {
          ...prevState?.drawData,
          campaign_settings: {
            ...prevState?.drawData?.campaign_settings,
            [name]: value,
          },
        },
      }));
    } else {
      setHandleState((prevState) => ({
        ...prevState,
        drawData: {
          ...prevState?.drawData,
          [name]: value,
        },
      }));
    }
  };

  const handleSubmitEditedData = async () => {
    if (multiEditIds?.length > 1) {
      console.log({
        uuid: multiEditIds,
        ...handleState?.drawData,
      });
      try {
        setHandleState((prevState) => ({ ...prevState, loading: true }));
        const apiEditPatchResponse = await BaseApi.patch('/sender-accounts/email/list', {
          uuid: multiEditIds,
          ...handleState?.drawData,
        });
        if (!apiEditPatchResponse?.error) {
          toast.success('Sender Email Setting Updated', toastState.success);
          fetchList();
          handleClose();
          dispatch(getErrorMessage({}));
          setHandleState((prevState) => ({ ...prevState, loading: false }));
        } else {
          setHandleState((prevState) => ({ ...prevState, loading: false }));
          dispatch(getErrorMessage(apiEditPatchResponse?.message));
          toast.error(apiEditPatchResponse?.message, toastState.error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        setHandleState((prevState) => ({ ...prevState, loading: true }));
        const apiEditPatchResponse = await BaseApi.patch('/sender-accounts/email/list', {
          uuid: [handleState?.drawData?.uuid],
          ...handleState?.drawData,
        });
        if (!apiEditPatchResponse?.error) {
          toast.success('Sender Email Setting Updated', toastState.success);
          fetchList();
          handleClose();
          dispatch(getErrorMessage({}));
          setHandleState((prevState) => ({ ...prevState, loading: false }));
          setHandleState((prevState) => ({
            ...prevState,
            drawData: {},
          }));
        } else {
          setHandleState((prevState) => ({ ...prevState, loading: false }));
          dispatch(getErrorMessage(apiEditPatchResponse?.message));
          toast.error(apiEditPatchResponse?.message, toastState.error);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* Right Drawer for 3 widget */}
      <Drawer placement="right" onClose={handleClose} isOpen={isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px" className="!px-3 !py-4 ">
            <div className=" flex items-start gap-2  border-red-500 ">
              <MdAttachEmail className="mt-1  text-column" size={22} />
              <div className="flex flex-col  ">
                <p className="text-base font-semibold text-heading">{multiEditIds?.length > 1 ? `Bulk Email settings` : `Email settings`}</p>
                <p className="text-xs font-normal text-column">Manage your email settings and their account permissions here.</p>
              </div>
            </div>
          </DrawerHeader>

          <DrawerBody className="m-2 !px-2 !py-0">
            <div className="rounded-lg bg-[#fafafc] px-4 py-4">
              <div className="flex items-center gap-2 text-sm font-semibold  text-heading">
                <BsFillPersonFill className="!text-column" />
                Senders name
              </div>
              <div className=" flex flex-col rounded-md bg-[#FAFAFC] p-2">
                <p className="ml-0 pb-1 text-[13px]  font-semibold text-[#1B2559]">Name *</p>
                <Input
                  value={handleState?.drawData?.name}
                  name="name"
                  placeholder="Enter your name"
                  customClass=" w-full text-[19px] add-gruop-name-blacklist workspace-name-input"
                  onChange={handleChangeSenderAccountsFormData}
                />
                {custom?.errorMessage?.name && <span className="ml-1 text-[0.6rem] text-red-500">{custom?.errorMessage?.name}</span>}
              </div>
            </div>

            <div className="mt-6 rounded-lg bg-[#fafafc] px-4 py-4">
              <div className="flex items-center gap-2 text-sm font-semibold  text-heading">
                <FaSignature className="!text-column" />
                Signature
              </div>

              <CustomSunEditor
                editorRef={quillRef}
                defaultValue={handleState?.drawData?.signature || ''}
                onChange={(data: any) => {
                  setHandleState((prevState) => ({ ...prevState, drawData: { ...prevState.drawData, signature: data } }));
                }}
                placeholder="Signature"
              />
            </div>

            <div className="mt-6 rounded-lg bg-[#fafafc] px-4 py-4">
              <div className="flex items-center gap-2 text-sm font-semibold  text-heading">
                <IoMdSettings className="!text-column" />
                Campaigns settings
              </div>
              <div className="flex w-full">
                <div className=" flex w-1/2 flex-col rounded-md bg-[#FAFAFC] p-2">
                  <p className="ml-0 pb-1 text-[13px]  font-semibold text-[#1B2559]">Daily limit</p>
                  <Input
                    value={handleState?.drawData?.campaign_settings?.daily_limit}
                    name="daily_limit"
                    placeholder="Daily limit"
                    customClass=" w-full text-[19px] add-gruop-name-blacklist workspace-name-input"
                    onChange={handleChangeSenderAccountsFormData}
                  />
                  {custom?.errorMessage?.campaign_settings?.daily_limit && (
                    <span className="ml-1 text-[0.6rem] text-red-500">{custom?.errorMessage?.campaign_settings?.daily_limit}</span>
                  )}
                </div>
                <div className=" flex w-1/2 flex-col rounded-md bg-[#FAFAFC] p-2">
                  <p className="ml-0 flex items-center  gap-2 pb-1 text-[13px] font-semibold text-[#1B2559]">
                    Delay between emails <IoIosHelpCircleOutline />
                  </p>
                  <div className="relative w-full">
                    <Input
                      value={handleState?.drawData?.campaign_settings?.delay_between_emails}
                      name="delay_between_emails"
                      placeholder="0"
                      customClass="w-full text-[19px] add-gruop-name-blacklist workspace-name-input item-end"
                      onChange={handleChangeSenderAccountsFormData}
                    />

                    <span className="absolute right-3 top-1/2 -translate-y-1/2 transform text-xs text-gray-700">mins</span>
                  </div>
                  {custom?.errorMessage?.campaign_settings?.delay_between_emails && (
                    <span className="ml-1 text-[0.6rem] text-red-500">{custom?.errorMessage?.campaign_settings?.delay_between_emails}</span>
                  )}
                  {parseInt(handleState?.drawData?.delay) >= 3 && <p className="text-xs text-red-500">Value must be greater than 3</p>}
                </div>
              </div>
            </div>

            <div className="mt-6 rounded-lg bg-[#f8f6ff] px-4 py-4">
              <div className="flex items-center gap-2 text-sm font-semibold  text-heading">
                <MdAccountTree className="!text-column" />
                Custom tracking domain
                <IoIosHelpCircleOutline />
                <Toggle
                  checkedCondition={handleState?.toggleState}
                  changeFunction={() => setHandleState((prevState) => ({ ...prevState, toggleState: !prevState?.toggleState }))}
                  className={`Custom-tracking-domain scale-50 `}
                />
              </div>
              {handleState?.toggleState ? (
                <div className=" flex flex-col rounded-md p-2">
                  <div className="mb-2 flex h-28 w-full flex-col items-start justify-center rounded-lg border-2 border-dashed border-blue-500 bg-white pl-3 text-xs" id="">
                    <p>Type: CNAME</p>
                    <p>Host Name: spark</p>
                    <p className="flex items-center gap-2">
                      Value: custom.sparkle.com <MdCopyAll size={15} className=" text-blueSecondary hover:scale-125" />
                    </p>
                  </div>

                  <p className="ml-0 pb-1 text-[13px]  font-semibold text-[#1B2559]">Your tracking domain or sub domain</p>
                  <Input
                    name="custom_domain"
                    placeholder="spark.{domain_name}"
                    customClass=" w-full text-[19px] add-gruop-name-blacklist workspace-name-input"
                    onChange={handleChangeSenderAccountsFormData}
                  />

                  <Button id="verify-btn" name="Verify" customClass="w-1/6 mt-2  !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </DrawerBody>
          <DrawerFooter className="border-t-2">
            <Button
              id="cancel-workspace-management"
              name="Cancel"
              customClass="!bg-[#F4F7FE] w-full !text-[#A3AED0] border border-2 !border-[#A3AED0] !font-medium duration-300 w-40 mr-3 py-2 text-[0.80rem]"
              onClick={() => {
                onClose();
              }}
            />
            <Button
              id="apply-senderlist-btn"
              disable={handleState?.loading}
              loading={handleState?.loading}
              name="Apply"
              customClass="w-full !text-[#FFFFFF] !font-medium duration-300 w-[10.5rem] py-2.5 text-[0.80rem]"
              onClick={() => handleSubmitEditedData()}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
