import { BaseApi } from 'common/api/common/base-api';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { FiUploadCloud } from 'react-icons/fi';

interface PropsType {
  file_name?: any;
  customClass?: string;
  onChange?: (data: any) => void;
  setLoading?: any;
  logo?: string;
  deleteLogo?: () => void;
}
const InputUploader = (props: PropsType) => {
  const { onChange, file_name, customClass, setLoading, logo, deleteLogo } = props;
  const fileInputRef = React.useRef(null);
  const [fileName, setFileName] = React.useState(null);

  React.useEffect(() => {
    setFileName(file_name);
  }, [file_name]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const file = event.target.files?.[0];

      if (file) {
        setFileName('Uploading...');
        const formData = new FormData();
        formData.append('documents', file);
        const uploadImg = await BaseApi.post('/helper/store/az/bucket', formData);
        setFileName(file.name);
        onChange?.({ file_name: file.name, data: uploadImg });
        //   console.log('file', file);
        //   const reader = new FileReader();
        //   reader.onload = (e) => {
        //     console.log('first', e);
        //     onChange?.({ file_name: file.name, data: e.target?.result });
        //   };
        //   setFileName(file.name);
        // reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log('Image uploading error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    setFileName('');
    fileInputRef.current.value = '';
    deleteLogo();
  };

  return (
    <div className="flex w-full items-center gap-2 ">
      <div className="flex  h-9 w-full cursor-pointer items-center justify-between rounded-md border dark:border-darkBorder dark:bg-darkThree bg-white px-2 py-2 !text-sm" onClick={() => fileInputRef.current.click()}>
        {fileName ? <p className={` ${customClass}  text-column dark:text-gray-300 `}>{fileName}</p> : <p className={`${customClass}  text-gray-500`}>Image.png</p>}
        <FiUploadCloud className="text-paused-row" size={20} />
      </div>
      {logo && <button onClick={handleRemove} disabled={fileName ? false : true} className={` duration-300 ${fileName ? 'text-gray-600 hover:text-gray-700' : 'text-gray-500'}`}>
        <FaTrash size={15} />
      </button>}
      <input
        name="logo"
        type="file"
        accept="image/*"
        onChange={handleChange}
        className="hidden "
        ref={fileInputRef} // Connect the ref to the input element
      />
    </div>
  );
};

export default InputUploader;
