/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';
import React from 'react';
import { MdAdd, MdCheck, MdOutlineWatchLater } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { EmailVerifierRowObject } from 'common/dto/types/views/email-verifier';
import EmailRowSettings from './features/EmailRowSettings';
import { EDIT_ID } from 'common/constants/constants';
import moment from 'moment';
import ArrowedTooltip from 'components/ArrowedTooltip';
import TableLoader from 'components/Loaders/TableViewLoader';
import CheckBox from 'common/sparkle-common/MultiSelectCheckBox.tsx';
import GlobalOpt from './features/GlobalOpt';
// import * as XLSX from 'xlsx';
import { setGlobalLoading, setTableRowsList } from 'store/custom';
import { useAppDispatch, useAppSelector } from 'store';
import { checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, useDebounce } from 'common/utils/utility';
import { FiSearch } from 'react-icons/fi';
import InfiniteScrollComponent from 'components/infinityScroll';
import CustomFilter from 'components/customFilter';
import { emailVerifierFilterDataList } from 'common/utils/page-filter';

const EmailVerificationList = () => {
  const navigate = useNavigate();
  const tableRef = React.useRef(null);
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const custom = useAppSelector((state) => state?.custom);

  const [load, setLoad] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [showGlobalOpt, setGlobalOpt] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showLoading, setShowLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState('');

  const [roleStatus, setRoleStatus] = React.useState(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const {environment} = useAppSelector((state)=>state.globalsettings)

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'email_verifier');
      setRoleStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  const dispatch = useAppDispatch();

  function calculateTimeAgo(timerange: string) {
    const timestamp = moment(timerange);
    const now = moment();

    // Calculate differences for each time unit
    const years = now.diff(timestamp, 'years');
    timestamp.add(years, 'years'); // Adjust timestamp after calculating years

    const months = now.diff(timestamp, 'months');
    timestamp.add(months, 'months'); // Adjust timestamp after calculating months

    const weeks = now.diff(timestamp, 'weeks');
    timestamp.add(weeks, 'weeks'); // Adjust timestamp after calculating weeks

    const seconds = now.diff(timestamp, 'seconds');
    const days = now.diff(timestamp.startOf('day'), 'days');
    // Determine and format the output
    if (seconds < 60) return 'just now';
    if (years > 0) return years === 1 ? 'year ago' : `${years} years ago`;
    if (months > 0) return months === 1 ? 'month ago' : `${months} months ago`;
    if (weeks > 0) return weeks === 1 ? 'week ago' : `${weeks} weeks ago`;
    if (days > 0) return days === 1 ? 'yesterday' : `${days} days ago`;
    return 'today';
  }

  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });

  const onSelect = (data: string[]) => {
    dispatch(setTableRowsList(data));
  };

  const [optionData, setOptionData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptionData(emailVerifierFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
    });
  };

  const columnHelper = createColumnHelper<EmailVerifierRowObject | any>();
  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-6 flex w-10  items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-[0.9rem] cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="flex items-center " onClick={(event) => event?.stopPropagation()}>
            <CheckBox onSelect={onSelect} info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('list_name', {
      id: 'list_name',
      enableSorting: false,
      header: () => <div className="select-none truncate font-semibold text-blackDark dark:!text-white">List Name</div>,
      cell: (info) => (
        // <div id="table-click group/nameing relative">
        //   {<p className={`w-[150px] group/nameing truncate pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}>{info.getValue()}</p>}
        //   <ArrowedTooltip data={<>{info.getValue()}</>} className="ml-7 mt-4 hidden !w-48 group-hover/nameing:block" />
        // </div>
        <div id="table-click" className="group relative">
          <p className={`group-list w-[150px] truncate pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}>{info.getValue()}</p>
          {info.getValue()?.length > 15 && (
            <ArrowedTooltip className={`!mx-auto hidden !min-w-fit !px-4 group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={info.getValue()} />
          )}
        </div>
      ),
    }),
    columnHelper.accessor('cdate', {
      id: 'cdate',
      enableSorting: false,
      header: () => <div className="w-[100px] select-none font-semibold text-blackDark dark:!text-white">Created On</div>,
      cell: (info) => {
        const utcDateString = info.getValue();
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));
        return (
          <div className="group relative w-full text-sm font-semibold" id="table-click">
            <span className={` row-cdate-${info?.row?.id}`}>{calculateTimeAgo(info.getValue())}</span>
            <ArrowedTooltip className={`hidden group-hover:block`} toolTipId={`row-cdate-tooltip-${info?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
          </div>
        );
      },
    }),

    columnHelper.accessor('verify_status', {
      id: 'verify_status',
      enableSorting: false,
      header: (row) => <div className="column-verify_status relative select-none font-semibold text-blackDark dark:!text-white">Status</div>,
      cell: (info) => {
        // const row = info?.row?.original;
        return (
          <div id="table-click">
            {
              <>
                {info?.row?.original?.total_email_verify_count !== info?.row?.original?.verified_count ? (
                  <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                    <MdOutlineWatchLater className="text-xs text-draft-color" />
                    <h5 className={`text-xs font-semibold text-draft-color status-index-${info?.row?.id}`}>Inprogress</h5>
                  </div>
                ) : (
                  <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                    <MdCheck className="text-xs text-completed-color" />
                    <h5 className={`text-xs font-semibold text-completed-color status-index-${info?.row?.id}`}>Completed</h5>
                  </div>
                )}
              </>
            }
          </div>
        );
      },
    }),
    columnHelper.accessor('verified_count', {
      id: 'verified_count',
      enableSorting: false,
      header: (row) => <div className="column-campaign_progress_step_percent elative select-none font-semibold text-blackDark dark:!text-white">Progress</div>,
      cell: (info) => {
        const row = info?.row?.original;
        const percentage = Math.floor((Number(info.getValue()) / Number(row?.total_email_verify_count)) * 100);
        return (
          <>
            {
              <div className="flex flex-col" id="table-click">
                <h5 className={`text-xs font-bold dark:text-white`}>{percentage || 0}%</h5>
                <div className="mt-0.5 h-[4px] w-4/12 rounded-full bg-gray-200 dark:bg-gray-700">
                  <div
                    className={`${percentage === 0 ? 'bg-[#8F9BBA]' : 'bg-green-600'} h-[4px] rounded-full w-[${percentage}%]
                              `}
                  ></div>
                </div>
              </div>
            }
          </>
        );
      },
    }),

    columnHelper.accessor('total_email_verify_count', {
      id: 'total_email_verify_count',
      enableSorting: false,
      header: () => <div className={`relative select-none font-semibold text-blackDark dark:!text-white`}>Total</div>,
      cell: (info) => {
        return <div id="table-click">{info.getValue()}</div>;
      },
    }),

    columnHelper.accessor('verify_valid_count', {
      id: 'verify_valid_count',
      enableSorting: false,
      header: () => <div className="relative select-none font-semibold text-blackDark dark:!text-white">Valid</div>,
      cell: (info) => {
        return <div id="table-click">{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor('verify_invalid_count', {
      id: 'verify_invalid_count',
      header: () => <div className="relative select-none font-semibold text-blackDark dark:!text-white">Invalid</div>,
      cell: (info) => {
        return <div id="table-click">{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor('verify_catch_all_count', {
      id: 'verify_catch_all_count',
      header: () => <div className="select-none font-semibold text-blackDark dark:!text-white">Catch all</div>,
      cell: (info) => <div id="table-click">{info.getValue()}</div>,
    }),
    columnHelper.accessor('verify_unknown_count', {
      id: 'verify_unknown_count',
      header: () => <div className="select-none font-semibold text-blackDark dark:!text-white">Unknown</div>,
      cell: (info) => {
        return <div id="table-click">{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor('verify_error_count', {
      id: 'verify_error_count',
      header: () => <div className="select-none font-semibold text-blackDark dark:!text-white">Error</div>,
      cell: (info) => <div id="table-click">{info.getValue()}</div>,
    }),

    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="relative w-min font-thin"></div>,
      cell: (row) => {
        return (
          <div onClick={(event) => event?.stopPropagation()}>
            <EmailRowSettings
              status={1}
              id={row.row.id}
              uuidRow={row.getValue()}
              rowData={row.row.original}
              table={table}
              setData={setData}
              setLoad={setLoad}
              handleListDelete={handleListDelete}
              count={row.row.original.total_email_verify_count}
            />
          </div>
        );
      },
    }),
  ];

  const handleListDelete = async (uuid?: Array<string>) => {
    try {
      const response = await BaseApi.delete(`/email/verify/list/delete`, {
        data: {
          uuid: uuid ? uuid : selectedData?.map((val: any) => val.uuid),
        },
      });
      if (response.status === 200) {
        setLoad((prev: number) => prev + 1);
        table.reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleDownload = async () => {
  //   const result = [];

  //   for (let obj of selectedData) {
  //     const data = obj.multi_status.map((item: any) => ({
  //       email: item.email,
  //       status: item?.verify_details?.status?.result ? 'Processed' : 'Processing...',
  //       result:
  //         item?.verify_details?.status?.result === 'valid'
  //           ? 'Valid'
  //           : item?.verify_details?.status?.result === 'catch_all'
  //           ? 'Catch All'
  //           : item?.verify_details?.status?.result === 'invalid'
  //           ? 'Invalid'
  //           : item?.verify_details?.status?.result === 'unknown'
  //           ? 'Unknown'
  //           : item?.verify_details?.status?.result === 'error'
  //           ? 'Error'
  //           : '',
  //       ...item?.verify_details?.multi_status,
  //     }));
  //     result.push(...data);
  //   }
  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet(result);
  //   XLSX.utils.book_append_sheet(wb, ws, 'Email Verification');
  //   const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
  //   const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'email_verification_report.csv';
  //   document.body.appendChild(a);
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  //   a.remove();
  // };
  const debouncedSearchValue = useDebounce(searchQuery, 300);

  const fetchList = async () => {
    try {
      setShowLoading(true);
      const query = setAndGetQueryParams([
        { key: 'search', value: searchQuery },
        { key: 'offset', value: queryParams?.offset },
        { key: 'filter', value: queryParams?.filter },
      ]);
      const response = await BaseApi.get(`/email/verify/list?${query}`);
      if (!response?.error) {
        dispatch(setGlobalLoading(false));
        const { data, total_records }: any = response?.data;
        setData({
          data: data || [],
          count: total_records?.count,
        });
      }
      setColumns(columnData);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const handleOptions = (option: string) => {
    console.log(option);
    if (option === 'delete') {
      handleListDelete();
    }
    // if (option === 'download') {
    //   handleDownload();
    // }
  };
  React.useEffect(() => {
    fetchList();
  }, [load, custom?.globalDependencyLoading, queryParams?.filter, debouncedSearchValue]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setSelectedData(tableSelectedData);

    if (table.getSelectedRowModel()?.rows?.length > 1) setGlobalOpt(true);
    else setGlobalOpt(false);
  }, [table?.getSelectedRowModel()]);

  const handleTableClick = async (e: any, id: any) => {
    e.stopPropagation();
    // console.log(e.target.id, 'id');
    // if (e.target.id === 'table-click') {
    sessionStorage.setItem(EDIT_ID, id.original.uuid);
    navigate('/email-verifier/result');
    // }
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 2 },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/email/verify/list?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );
  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white`}>
      <div className="relative mb-3 py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:border-darkBorder md:w-[13rem] lg:w-[18rem]"
              // onClick={() => searchRef.current.focus()

              // }
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                // ref={searchRef}
                autoComplete="off"
                onChange={(e) => handleSearch(e)}
                type="text"
                placeholder="Search"
                id="prospect-search-input"
                className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-white dark:placeholder:text-white "
              />
            </div>
            <CustomFilter optionData={optionData} handleFilter={handleFilter} />
          </div>
          {/* Role Restriction for Adding Email Verify List */}
          {(roleStatus?.is_owner || roleStatus?.add || !environment?.app_default_feature?.is_org_enbaled) && (
            <button
              id="email-verifier-addbutton"
              className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => navigate('/email-verifier/create')}
            >
              <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add list</span>
            </button>
          )}
        </div>
      </div>
      <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
        {!showLoading ? (
          <>
            <div className="h-screen">
              <table className=" w-full">
                <thead>
                  {
                    table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, index, arr) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              onClick={header.column.getToggleSortingHandler()}
                              className={`mb-2 border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne dark:!text-white`}
                            >
                              <div className="truncate text-xs">
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: '',
                                  desc: '',
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))
                  }
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-hover dark:hover:bg-darkOne" id={`email-verifyrow${row.id} table-click`} onClick={(e) => handleTableClick(e, row)}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            return (
                              <td
                                key={cell.id}
                                className={`cursor-pointer border-white/0 py-3 text-start text-sm font-semibold text-heading dark:text-white
                                      ${i === 0 ? 'w-10' : i === 3 ? 'w-[12%]' : 'w-[10%]'}`}
                                id="table-click"
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
            <InfiniteScrollComponent
              loadMore={scrollFunction}
              loader={
                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
              }
            />
          </>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        )}
      </div>
      {showGlobalOpt && <GlobalOpt handleOptions={handleOptions} roleAccess={roleStatus} />}
    </div>
  );
};

export default EmailVerificationList;
