import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { IoMdAdd } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@chakra-ui/react';
import { FcReuse } from 'react-icons/fc';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/modal';
import { toast } from 'react-toastify';

import { BaseApi } from 'common/api/common/base-api';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import Button from 'common/sparkle-common/Button';
import { checkRolePermissionStatus, cryptoDecrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import { CustomMenu } from 'components/customMenu';
import { useAppDispatch, useAppSelector } from 'store';
import { setLoggedUserDetails, setLoggedUserRolePermissionList } from 'store/authentication';
import { setModalView, setOrganizationId, setStepCount, setWorkspace, setswitchOrganizationModalView, setWorkspaceEditState } from 'store/organization';
import OtpDeletePage from './feature/OtpDeletePage';
import { setGlobalDependencyLoading } from 'store/custom';
import { setBrandId, setPipelineId } from 'store/crm';
import { LocalStorage } from 'common/api/common/storage';
import { setSettingsData } from 'store/campaign-v2';

const SwitchOrganization = () => {
  const dispatch = useAppDispatch();
  const custom = useAppSelector((state) => state?.custom);
  const location = useLocation();
  const navigate = useNavigate();
  const { organization_view, modal_view } = useAppSelector((state) => state.organization);
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const [queryParams, setQueryParams] = React.useState({ organization_id: '', limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });
  const cardLoading = Array(9).fill(null);
  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  const [handleState, setHandleState] = React.useState({
    open: false,
    switchloading: false,
    workspaceStatus: null,
    options: [],
    dataLoading: false,
    workspaceDeleteData: {},
    deleteState: false,
    organizationSearchData: '',
    workspaceSearchData: '',
    selectedWorkSpaceId: '',
    workSpaceList: [],
    organizationList: [],
    initialFetching: true,
    selectedOrganization: { logo: '', name: '', organization_id: '' },
  });
  const debouncedOrganizationSearchValue = useDebounce(handleState?.organizationSearchData, 300);
  const debouncedSearchValue = useDebounce(handleState?.workspaceSearchData, 300);

  const handleWorkspaceSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Event', event.target);
    setHandleState((prevState) => ({
      ...prevState,
      workspaceSearchData: event.target.value,
    }));
  };

  const handleSelect = async (id: string, value: string, logo: string) => {
    setHandleState((prevState) => ({
      ...prevState,
      open: false,
    }));
    setHandleState((prevState) => ({
      ...prevState,
      selectedOrganization: {
        ...prevState?.selectedOrganization,
        organization_id: id,
        logo: logo,
        name: value,
      },
    }));

    dispatch(setOrganizationId(id));
  };

  const handleAddWorkspace = () => {
    dispatch(setWorkspaceEditState({ editState: false, editId: '', organization_id: '' }));
    dispatch(setStepCount(2));
    dispatch(setModalView(true));
  };

  const handleClose = () => {
    dispatch(setswitchOrganizationModalView(false));
  };

  const fetchEmailAliasName = async () => {
    try {
      const response = await BaseApi.get(`/organization/${handleState?.selectedOrganization?.organization_id}`);
      const aliasName = (response?.data?.sender_alias_personas?.first_name || '') + ' ' + (response?.data?.sender_alias_personas?.last_name || '');
      dispatch(setSettingsData({ email_alias_name: aliasName?.trim() }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSwitchOrganizationAndWorkspace = async () => {
    try {
      setHandleState((prevState) => ({
        ...prevState,
        switchloading: true,
      }));
      fetchEmailAliasName();
      const response = await BaseApi.post('/helper/generate/access/token', {
        organization_id: handleState?.selectedOrganization?.organization_id,
        workspace_id: handleState?.selectedWorkSpaceId,
      });
      if (!response.error) {
        LocalStorage.setString(LOGIN_STORAGE_VAR, response?.data?.data);
        const loginData = await cryptoDecrypt(response?.data?.data, true);
        dispatch(
          setLoggedUserDetails({
            id: loginData?.id,
            uuid: loginData?.uuid,
            is_admin_account: loginData?.is_admin_account,
            first_name: loginData?.first_name,
            last_name: loginData?.last_name,
            email: loginData?.email,
            organization_id: loginData?.organization_id,
            workspace_id: loginData?.workspace_id,
            organization_name: loginData?.organization_name,
            workspace_name: loginData?.workspace_name,
            authToken: loginData?.authToken,
            is_google: loginData?.is_google,
          })
        );
        dispatch(setLoggedUserRolePermissionList(loginData?.role_permission_list));
        dispatch(setswitchOrganizationModalView(false));
        dispatch(setGlobalDependencyLoading(!custom?.globalDependencyLoading));
        dispatch(setPipelineId(''));
        dispatch(setBrandId(''));

        if (location.pathname.includes('/crm')) {
          navigate('/crm');
        }
        setHandleState((prevState) => ({
          ...prevState,
          switchloading: false,
        }));
      }
    } catch (error) {
      console.log('workspace switching error', error);
    }
  };

  const handleWorkspaceEdit = async (rowuuid: string, rowDetails: any) => {
    dispatch(setModalView(true));
    dispatch(setStepCount(2));
    dispatch(setWorkspaceEditState({ editState: true, editId: rowDetails?.uuid, organization_id: rowDetails?.organization_id }));
    dispatch(setWorkspace({ file_name: rowDetails?.logo, workspace_logo: rowDetails?.logo, workspace_name: rowDetails?.name }));
  };

  const handleDeleteOneWorkSpace = async (rowuuid: string, rowDetails: any) => {
    if (rowDetails?.uuid === loggedUser?.workspace_id) {
      toast.error('Please switch to another workspace before attempting to delete the current workspace.', toastState.error);
    } else {
      try {
        setHandleState((prevState) => ({
          ...prevState,
          deleteState: true,
        }));
        setHandleState((prevState) => ({
          ...prevState,
          workspaceDeleteData: rowDetails,
        }));
      } catch (error) {
        console.error('Error form Row Edit in Sender Account:', error);
      }
    }
  };
  const handleOrganizationSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandleState((prevState) => ({
      ...prevState,
      organizationSearchData: event.target.value,
    }));
  };

  const RowSettingOptions = [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: handleWorkspaceEdit,
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      name: 'Delete',
      accessName: 'delete',

      functioning: handleDeleteOneWorkSpace,
    },
  ];

  React.useEffect(() => {
    const fetchOrganization = async () => {
      try {
        if (!loggedUser?.organization_id) {
          return;
        }
        const orgSearchQuery = setAndGetQueryParams([{ key: 'search', value: handleState?.organizationSearchData }]);
        const response = await BaseApi.get(`/organization?${orgSearchQuery}`);

        if (!response.error && response?.data?.length) {
          const defaultOrg = response?.data.find((item: any) => item.uuid === loggedUser.organization_id);
          if (handleState?.initialFetching) {
            setHandleState((prevState) => ({
              ...prevState,
              selectedOrganization: {
                ...prevState.selectedOrganization,
                organization_id: defaultOrg?.uuid,
                logo: defaultOrg?.logo,
                name: defaultOrg?.name,
              },
            }));

            setHandleState((prevState) => ({
              ...prevState,
              initialFetching: false,
            }));
          }
          setHandleState((prevState) => ({
            ...prevState,
            organizationList: response?.data,
          }));
        } else {
          setHandleState((prevState) => ({
            ...prevState,
            organizationList: response?.data,
          }));
        }
      } catch (error) {
        console.error('fetchOrganization Error:', error);
      } finally {
      }
    };
    fetchOrganization();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.organization_id, debouncedOrganizationSearchValue, modal_view]);

  React.useEffect(() => {
    setHandleState((prevState) => ({
      ...prevState,
      selectedWorkSpaceId: loggedUser?.workspace_id,
    }));
    const getOrgWorkspace = async () => {
      if (!handleState?.selectedOrganization?.organization_id) {
        return;
      }
      const query = setAndGetQueryParams([
        { key: 'organization_id', value: handleState?.selectedOrganization?.organization_id },
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: handleState?.workspaceSearchData },
        {
          key: 'sort',
          value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
        },
        { key: 'filter', value: queryParams?.filter },
      ]);
      setHandleState((prevState) => ({
        ...prevState,
        dataLoading: true,
      }));
      const workSpaceResponse = await BaseApi.get(`/workspace/switch/org/page?${query}`);
      if (!workSpaceResponse.error) {
        console.log(workSpaceResponse);
        setHandleState((prevState) => ({
          ...prevState,
          selectedWorkSpaceId: workSpaceResponse?.data?.data?.[0]?.uuid,
          workSpaceList: workSpaceResponse?.data?.data || [],
        }));

        setHandleState((prevState) => ({
          ...prevState,
          dataLoading: false,
        }));
      }
    };
    getOrgWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, modal_view, handleState?.deleteState, handleState?.selectedOrganization?.organization_id]);

  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'workspace_management');
      setHandleState((prevState) => ({
        ...prevState,
        workspaceStatus: status,
      }));
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const finalRowSetting = handleState?.workspaceStatus?.is_owner
      ? RowSettingOptions
      : RowSettingOptions.filter((item) => handleState?.workspaceStatus?.hasOwnProperty(item?.accessName) && handleState?.workspaceStatus?.hasOwnProperty(item.accessName));
    setHandleState((prevState) => ({
      ...prevState,
      options: finalRowSetting,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.workspaceStatus]);

  return (
    <>
      {/* {!deleteState ? ( */}
      <div>
        <Modal isOpen={organization_view} size={'auto'} onClose={() => dispatch(setswitchOrganizationModalView(false))} isCentered={true}>
          <ModalOverlay />
          <ModalContent className="h-[650px] !w-[58rem] dark:bg-darkThree">
            <ModalCloseButton className="z-10 text-gray-700 dark:bg-darkOne dark:text-gray-200 dark:hover:text-red-500" />
            <ModalBody className="!relative h-full !overflow-hidden !rounded-md  !p-0 ">
              {/* Header */}
              <div className="mt-6 flex select-none flex-col items-center justify-center ">
                <h4 className="mt-2 text-lg font-bold text-heading dark:text-white">All Workspaces</h4>
                <span className="mt-2 text-xs text-column dark:text-gray-300">If you want to switch the organization select the dropdown below</span>
                {/* Select organization */}
                <CustomMenu
                  open={handleState?.open}
                  onOpen={() => {
                    setHandleState((prevState) => ({
                      ...prevState,
                      open: true,
                    }));
                  }}
                  onClose={() => {
                    setHandleState((prevState) => ({
                      ...prevState,
                      organizationSearchData: '',
                    }));
                    setHandleState((prevState) => ({
                      ...prevState,
                      open: false,
                    }));
                  }}
                  btnContent={
                    <div className="flex items-center font-semibold ">
                      {handleState?.selectedOrganization.logo ? (
                        <img src={handleState?.selectedOrganization.logo} alt="Nothing" className="-ml-[5px] size-full h-6 w-6 rounded-full border p-1  " />
                      ) : (
                        handleState?.selectedOrganization.name && (
                          <Avatar
                            size={'5'}
                            cursor="pointer"
                            height="32px"
                            width="32px"
                            name={`${handleState?.selectedOrganization?.name[0]}${handleState?.selectedOrganization?.name.slice(1)}`}
                            bg="imagebg"
                            id="org_logo"
                            className="!mr-2 flex !h-6 !w-6 rounded-full bg-imagebg p-2 !text-xs !text-blackDark dark:!text-white"
                          />
                        )
                      )}
                      {/* {selectedOrganization.name || 'Loading...'} */}
                      {handleState?.selectedOrganization.name ? (
                        handleState?.selectedOrganization.name
                      ) : (
                        <>
                          <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-t-2 border-blue-500"></div>
                          <span>Loading...</span>
                        </>
                      )}
                    </div>
                  }
                  btnId="organization_dropdown_btn"
                  btnClassName="w-64 dark:!bg-darkTwo dark:border-darkBorder mt-4 justify-between organization_dropdown_btn !h-9 !text-column !text-sm !rounded-md !font-normal truncate !bg-white "
                  menuListClassName="w-full !h-64 !mt-0.5 !p-0 !overflow-auto"
                  rightIcon={<BiChevronDown size={18} />}
                >
                  <button
                    onClick={() => {
                      dispatch(setStepCount(1));
                      dispatch(setswitchOrganizationModalView(false));
                      dispatch(setModalView(true));
                    }}
                    className="text-coloumn flex w-full items-center gap-1.5 border-b-2 border-t-0 px-1.5 py-2 pl-1 text-xs transition-transform duration-150 ease-out hover:bg-lightPrimary active:scale-95 dark:hover:bg-darkOne"
                    id="add_organization"
                  >
                    <IoMdAdd size={13} className="text-blueSecondary dark:text-white" />
                    <span className="text-heading dark:text-white">Add Organization</span>
                  </button>

                  <div className="mx-1 mt-1 flex w-auto items-center gap-2 rounded-md border px-2 py-1 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400">
                    <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-gray-200" />
                    <input
                      value={handleState?.organizationSearchData}
                      type="text"
                      placeholder="Search"
                      id="donot-search-input"
                      onChange={handleOrganizationSearch}
                      className="w-full text-sm  text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkTwo dark:text-gray-200  dark:placeholder:text-white "
                    />
                  </div>

                  {handleState?.organizationList?.length ? (
                    handleState?.organizationList?.map((item, index) => (
                      <button
                        onClick={() => {
                          setHandleState((prevState) => ({
                            ...prevState,
                            workspaceSearchData: '',
                          }));
                          setQueryParams((prev) => ({ ...prev, search: '' }));
                          handleSelect(item.uuid, item.name, item.logo);
                        }}
                        id={`organization_${index}`}
                        className="text-coloumn flex w-full items-center  border-b-2 px-1.5 py-2 pl-2 text-start text-xs hover:bg-lightPrimary dark:border-darkBorder dark:hover:bg-darkOne"
                        key={index}
                      >
                        {item?.logo ? (
                          <div className="h-6 w-6  rounded-full">
                            <img src={item?.logo} alt="Nothing" className="size-full rounded-full border p-1 " />
                          </div>
                        ) : (
                          <Avatar
                            size={'5'}
                            cursor={'pointer'}
                            height={'32px'}
                            width={'32px'}
                            name={`${item?.name[0]} ${item?.name[1]}`}
                            bg="imagebg"
                            id="org_logo"
                            className="!mr-0.5 flex !h-6 !w-6  rounded-full bg-imagebg p-2 !text-blackDark dark:bg-darkThree dark:!text-white"
                          />
                        )}
                        <span>{item.name}</span>
                      </button>
                    ))
                  ) : (
                    <button
                      onClick={() => {
                        setHandleState((prevState) => ({
                          ...prevState,
                          workspaceSearchData: '',
                        }));
                        setQueryParams((prev) => ({ ...prev, search: '' }));
                      }}
                      className="text-coloumn flex w-full items-center  border-b-2 px-1.5 py-2 pl-2 text-start text-xs hover:bg-lightPrimary dark:border-darkBorder dark:hover:bg-darkOne"
                    >
                      <div className="flex items-center gap-1">
                        <div className="h-6 w-6  rounded-full">
                          <FcReuse size={22} />
                        </div>
                        <span>Result not found</span>
                      </div>
                    </button>
                  )}
                </CustomMenu>
              </div>
              {/* Body */}
              <div className="mt-5 px-4">
                {/* Search */}
                <div className="mb-2 flex items-center justify-between px-4">
                  <div
                    className={`m-2 flex w-2/4 items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-1 focus-within:outline-brand-400 dark:border-darkBorder `}
                  >
                    <FiSearch className="size-4 cursor-pointer dark:text-gray-200" />
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Search..."
                      value={handleState?.workspaceSearchData}
                      onChange={handleWorkspaceSearch}
                      id={'Workspace-search-input'}
                      className={`w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-gray-200  dark:placeholder:text-white`}
                    />
                  </div>
                  {(handleState?.workspaceStatus?.is_owner || handleState?.workspaceStatus?.add) && (
                    <Button customClass="dark:border-none" name="Add Workspace" id="workspace_creation_btn" LeftIcon={<IoMdAdd size={15} />} onClick={handleAddWorkspace} />
                  )}
                </div>

                {/* Cards */}
                {!handleState?.dataLoading ? (
                  <div className="flex h-[23.125rem] flex-wrap content-start gap-3 overflow-y-auto  ">
                    {handleState?.workSpaceList && handleState?.workSpaceList.length ? (
                      handleState?.workSpaceList?.map((item, index) => {
                        return (
                          <div
                            className={`relative mb-2 flex h-28 w-72 cursor-pointer select-none rounded-md border p-2 dark:border-darkBorder dark:bg-darkTwo ${handleState?.selectedWorkSpaceId === item.uuid ? '!border-blueSecondary bg-bgInside dark:bg-navy-900' : ''
                              }`}
                            key={index}
                            onClick={() =>
                              setHandleState((prevState) => ({
                                ...prevState,
                                selectedWorkSpaceId: item.uuid,
                              }))
                            }
                            // onDoubleClick={handleSwitchOrganizationAndWorkspace}
                            id={`workspace_${index}`}
                          >
                            <button className="absolute right-1 text-column ">
                              {/* <TbDotsVertical size={20} /> */}
                              <CommonRowSettings rowData={item} options={handleState?.options} bodyClassName={'border border-blue-500'} buttonClassName={' text-sec'} />
                            </button>
                            <div className="flex h-full w-1/5 items-center justify-center pr-1.5">
                              {/* logo */}

                              {item?.logo ? (
                                <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-imagebg p-2">
                                  <img src={item?.logo} alt={item?.name} className="h-fit w-fit object-cover" />
                                </div>
                              ) : (
                                <Avatar
                                  cursor={'pointer'}
                                  height={'32px'}
                                  width={'32px'}
                                  name={`${item?.name[0]} ${item?.name[1]}`}
                                  bg="imagebg"
                                  id="card_logo"
                                  className="flex !h-12 !w-12 !items-center !justify-center !overflow-hidden rounded-full bg-imagebg p-2 !text-blackDark dark:!bg-darkBorder dark:!text-white"
                                />
                              )}
                            </div>
                            <div className="flex h-full w-3/4 flex-col items-center justify-center ">
                              <h4 className="w-full truncate text-sm font-bold text-textBlack dark:text-white">{item?.name}</h4>
                              <span className="mt-0.5 text-xs text-paused-color dark:text-gray-200">{item?.no_of_users} Active members are currently in this workspace</span>
                            </div>
                          </div>
                        );
                      })
                    ) :
                      // handleState?.workSpaceList && handleState?.workSpaceList.length === 0 ?
                      (
                        <h1 className="mx-auto text-center">No data Found</h1>
                      )
                      // : 
                      // (
                      //   <>
                      //     <div className="fixed inset-0 flex items-center justify-center">
                      //       <div className="mr-2 h-8 w-8 animate-spin rounded-full border-b-4 border-t-4 border-b-brandLinear border-t-horizonPurple-300"></div>
                      //     </div>
                      //   </>
                      // )
                    }
                  </div>
                ) : (
                  <div className="flex h-[23.125rem] flex-wrap content-start gap-3 overflow-y-auto">
                    {/* Adding spacing between each loading element */}
                    {cardLoading.map((_, index) => (
                      <div
                        key={index}
                        className=" flex h-28 w-72 animate-pulse cursor-pointer select-none rounded-md border bg-bgInside p-2 dark:border-darkBorder dark:bg-darkOne"
                      >
                        <div className="flex h-full w-1/5 items-center justify-center pr-1.5">
                          <div className="h-12 w-12 rounded-full bg-gray-300 dark:bg-gray-800"></div>
                        </div>
                        <div className="flex h-full w-full flex-col items-start justify-center">
                          <div className="h-4 w-full rounded bg-gray-400 dark:bg-gray-900"></div>
                          <div className="mt-1 h-3 w-3/4 rounded bg-gray-400 dark:bg-gray-900"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Footer */}
                <div className="mt-4 flex  gap-4">
                  <Button
                    name="Cancel"
                    onClick={handleClose}
                    customClass="w-full bg-white border !text-gray-800 !font-semibold !text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out"
                  />
                  <Button
                    disable={handleState?.switchloading}
                    loading={handleState?.switchloading}
                    id="continue_btn"
                    name="Continue"
                    customClass="w-full dark:border-none !text-[.8125rem] active:scale-95 transition-transform duration-150 ease-out active:scale-95 transition-transform duration-150 ease-out"
                    onClick={handleSwitchOrganizationAndWorkspace}
                  />
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
      {/* ) : ( */}
      <div>
        {handleState?.deleteState && (
          <OtpDeletePage
            isOpen={handleState?.deleteState}
            onClose={() => setHandleState((prevState) => ({ ...prevState, deleteState: false }))}
            workspacedata={handleState?.workspaceDeleteData}
          />
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default SwitchOrganization;
