import { generateDateInterval } from './utility';

export const campaignFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'campaign_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'In Progress', uniqueName: 5 },
      { id: '2', name: 'Draft', uniqueName: 0 },
      { id: '3', name: 'Active', uniqueName: 1 },
      { id: '4', name: 'Paused', uniqueName: 3 },
      { id: '3', name: 'Completed', uniqueName: 4 },
    ],
  },
  {
    id: '2',
    name: 'Open Rate',
    uniqueColumnName: 'campaign_statistics.open_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'Sent Rate',
    uniqueColumnName: 'campaign_statistics.sent_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '4',
    name: 'Reply Rate',
    uniqueColumnName: 'campaign_statistics.replied_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',
  },
  {
    id: '5',
    name: 'Bounce Rate',
    uniqueColumnName: 'campaign_statistics.bounced_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [],
    result_value_field_type: 'input',

  },
  {
    id: '6',
    name: 'Unsub Rate',
    uniqueColumnName: 'campaign_statistics.unsubscribed_percentage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any[],
    result_value_field_type: 'input',
  },
  {
    id: '7',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const senderAccountsFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'account_details.mailer_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Connected', uniqueName: 1 },
      { id: '2', name: 'Disconected', uniqueName: 0 },
    ],
  },
  {
    id: '2',
    name: 'Daily limit',
    uniqueColumnName: 'campaign_settings.daily_limit',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'Health Score',
    uniqueColumnName: 'account_details.email_health_score',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '6',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const brandsFilterDataList = [
  {
    id: '1',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
  {
    id: '2',
    name: 'Campaigns Count',
    uniqueColumnName: 'campaign_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  // {
  //   id: '3',
  //   name: 'Interests',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'input',
  // },
  // {
  //   id: '4',
  //   name: 'Meeting booked',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'input',
  // },
  // {
  //   id: '5',
  //   name: 'Opportunites Won',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'input',
  // },
  // {
  //   id: '6',
  //   name: 'Total deal value',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'input',
  // },
];

export const trueVerifierFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'ticket_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'In Progress', uniqueName: 0 },
      { id: '2', name: 'Completed', uniqueName: 1 },
      { id: '3', name: 'Reopen', uniqueName: 2 },
      { id: '4', name: 'Cancel', uniqueName: 3 },
    ],
  },
  {
    id: '2',
    name: 'Variant',
    uniqueColumnName: 'variant',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'True Verifier', uniqueName: 'true_verifier' },
      { id: '2', name: 'Blacklist', uniqueName: 'blacklist' },
      { id: '3', name: 'Campaign', uniqueName: 'campaign' },
      { id: '4', name: 'Email Verifier', uniqueName: 'email_verifier' },
    ],
  },
  {
    id: '3',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const blacklistFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'blacklist_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Pending', uniqueName: 0 },
      { id: '2', name: 'Active', uniqueName: 1 },
      { id: '3', name: 'Paused', uniqueName: 2 },
      // { id: '4', name: 'Resume', uniqueName: '3' },
    ],
  },
  {
    id: '2',
    name: 'Added On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
  {
    id: '3',
    name: 'Last Checked',
    uniqueColumnName: 'last_checked',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
  {
    id: '4',
    name: 'Type',
    uniqueColumnName: 'blacklist_type',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Domain', uniqueName: 'Domain' },
      { id: '2', name: 'Ip', uniqueName: 'IPv4' },
    ],
  },
];

export const emailVerifierFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'verify_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'In Progress', uniqueName: 0 },
      { id: '4', name: 'Completed', uniqueName: 1 },
      // { id: '2', name: 'Rejected', uniqueName: 2 },
      // { id: '3', name: 'Paused', uniqueName: 3 },
    ],
  },
  {
    id: '2',
    name: 'Total',
    uniqueColumnName: 'total_email_verify_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'Valid',
    uniqueColumnName: 'verify_valid_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '4',
    name: 'Invalid',
    uniqueColumnName: 'verify_invalid_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '5',
    name: 'Catch all',
    uniqueColumnName: 'verify_catch_all_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '6',
    name: 'Unknown',
    uniqueColumnName: 'verify_unknown_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '7',
    name: 'Error',
    uniqueColumnName: 'verify_error_count',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },

  {
    id: '8',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const workspaceManagementFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Active', uniqueName: 1 },
      { id: '2', name: 'Inactive', uniqueName: 0 },
    ],
  },
  {
    id: '2',
    name: 'No of Users',
    uniqueColumnName: 'no_of_users',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '3',
    name: 'No of Campaigns',
    uniqueColumnName: 'no_of_campaigns',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  {
    id: '5',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const userManagementFilterDataList = [
  {
    id: '1',
    name: 'Status',
    uniqueColumnName: 'all_workspaces.invite_details.invite_status',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Invited', uniqueName: 0 },
      { id: '2', name: 'Active', uniqueName: 1 },
    ],
  },
  {
    id: '2',
    name: 'Credits Usage',
    uniqueColumnName: 'credits_usage',
    conditions: [
      { id: '1', name: 'More Than', uniqueName: 'greater_than' },
      { id: '2', name: 'Less than', uniqueName: 'less_than' },
    ],
    resultValue: [] as any,
    result_value_field_type: 'input',
  },
  // {
  //   id: '3',
  //   name: 'Role',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'text',
  // },

  {
    id: '4',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const roleManagementFilterDataList = [
  // {
  //   id: '1',
  //   name: 'Status',
  //   uniqueColumnName: 'campaign_status',
  //   conditions: [
  //     { id: '1', name: 'Is', uniqueName: 'is' },
  //     { id: '2', name: 'Is not', uniqueName: 'not' },
  //   ],
  //   resultValue: [
  //     { id: '1', name: 'Invited', uniqueName: '5' },
  //     { id: '2', name: 'Active', uniqueName: '0' },

  //   ],
  // },
  // {
  //   id: '2',
  //   name: 'Credits Usage',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'input',
  // },
  // {
  //   id: '1',
  //   name: 'Role',
  //   uniqueColumnName: 'campaign_statistics.email_open_percentage',
  //   conditions: [
  //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //   ],
  //   resultValue: [] as any,
  //   result_value_field_type: 'text',
  // },

  {
    id: '2',
    name: 'Created On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const donotEmailFilterOption = [
  // {
  //   id: '1',
  //   name: 'Added By',
  //   uniqueColumnName: 'added_by',
  //   conditions: [
  //     { id: '1', name: 'Is', uniqueName: 'is' },
  //     { id: '2', name: 'Is not', uniqueName: 'not' },
  //   ],
  //   resultValue: [{ id: '1', name: 'Me', uniqueName: 'me' }],
  // },
  {
    id: '2',
    name: 'Added On',
    uniqueColumnName: 'cdate',
    conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
    resultValue: [
      { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
      { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
      { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
      { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
      { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
      { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
    ],
  },
];

export const InboxFilterDataList = [
  {
    id: '1',
    name: 'Read Status',
    uniqueColumnName: 'read_history.is_viewed',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      // { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Unread', uniqueName: false },
      { id: '2', name: 'Read', uniqueName: true },
    ],
  },
  {
    id: '2',
    name: 'Starred Status',
    uniqueColumnName: 'is_starred_message',
    conditions: [
      { id: '1', name: 'Is', uniqueName: 'is' },
      // { id: '2', name: 'Is not', uniqueName: 'not' },
    ],
    resultValue: [
      { id: '1', name: 'Important', uniqueName: true },
      { id: '2', name: 'Not Important', uniqueName: false },
    ],
  },
  // {
  //   id: '3',
  //   name: 'Date',
  //   uniqueColumnName: 'platform_message_details.mail_sent_date',
  //   conditions: [
  //     { id: '1', name: 'Is', uniqueName: 'is' },
  //     // { id: '2', name: 'Is not', uniqueName: 'not' },
  //   ],
  //   resultValue: [
  //     { id: '1', name: 'Today', uniqueName: generateDateInterval(0, 0) },
  //     { id: '2', name: 'Yesterday', uniqueName: generateDateInterval(1) },
  //     { id: '3', name: 'Last 7 days', uniqueName: generateDateInterval(7) },
  //     { id: '4', name: 'Last 30 days', uniqueName: generateDateInterval(29) },
  //     { id: '5', name: 'This week', uniqueName: generateDateInterval('week', 0) },
  //     { id: '6', name: 'This month', uniqueName: generateDateInterval('month', 0) },
  //   ],
  // },
];
// 0 - pending, 1 - cleared, 2 - snoozed, 3 - important, 4 - starred
