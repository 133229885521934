import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import moment from 'moment';
import { MdAdd, MdCheck, MdEdit, MdOutlineHourglassEmpty, MdOutlineWatchLater, MdPauseCircle } from 'react-icons/md';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { useAppDispatch, useAppSelector } from 'store';
import { resetTeamsWorkspaceEditState, setTeamsWorkspaceEditState } from 'store/organization';
import { calculateTimeAgo, checkRolePermissionStatus, jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import { BaseApi } from 'common/api/common/base-api';
import Button from 'common/sparkle-common/Button';
// import CustomFilter from 'components/customFilter';
import AddWorkspaceModal from './feature/AddWorkspaceModal';
import ArrowedTooltip from 'components/ArrowedTooltip';

// import { OptionDataType } from 'common/dto/types/components/customFilter';
import CommonRowSettings from 'components/commonRowSetting/CommonRowSettings';
import OtpDeletePage from 'views/organization/feature/OtpDeletePage';
import CustomFilter from 'components/customFilter';
import { workspaceManagementFilterDataList } from 'common/utils/page-filter';

const WorkspaceManagementTable = () => {
  const columnWidth = ['w-[5%]', 'w-[12%]', 'w-[8%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[10%]', 'w-[3%]', 'w-[3%]'];
  const [handleState, setHandleState] = React.useState({
    deleteState: false,
    workspaceDeleteData: {},
    workspaceStatus: null,
    options: [],
    showCreateModal: false,
    workspaceEditData: {
      editState: false,
    },
  });
  // const [workspaceStatus, setWorkspaceStatus] = React.useState(null);
  // const [deleteState, setDeleteState] = React.useState(false);
  // const [workspaceDeleteData, setWorkspaceDeleteData] = React.useState({});
  // const [options, setOptions] = React.useState([]);
  // const [showCreateModal, setShowCreateModal] = React.useState(false);

  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [workspaceEditData, setWorkspaceEditData] = React.useState({
  //   editState: false,
  //   name: '',
  //   logo: '',
  //   credits: '',
  // });

  const dispatch = useAppDispatch();

  //
  const [data, setData] = React.useState({
    data: [],
    count: 0,
    loading: false,
  });
  const custom = useAppSelector((state) => state?.custom);

  const handleWorkspaceDeleteWithOtp = async (rowuuid: string, rowDetails: any) => {
    console.log('row', rowuuid, rowDetails);
    if (rowDetails?.uuid === loggedUser?.workspace_id) {
      toast.error('Attempting to delete the current workspace is not possible.', toastState.error);
    } else {
      try {
        setHandleState((prevState) => ({
          ...prevState,
          deleteState: true,
        }));

        // setDeleteState(true);
        setHandleState((prevState) => ({
          ...prevState,
          workspaceDeleteData: rowDetails,
        }));

        // setWorkspaceDeleteData(rowDetails);
        // console.log('rowDetails', rowDetails);
        // setWorkspaceDeleteData((prev:any)=>{...prev,workspace_name:rowDetails?.})
      } catch (error) {
        console.error('Error form Row Edit in Sender Account:', error);
      }
    }
  };

  const handeEditWorkspaceInTeams = async (rowuuid: string, rowDetails: any) => {
    const apiEditResponse = await BaseApi.get(`/workspace/${rowuuid}`);
    // console.log('API RESZ', apiEditResponse?.data);
    if (!apiEditResponse?.error) {
      dispatch(
        setTeamsWorkspaceEditState({
          editState: true,
          uuid: apiEditResponse?.data?.uuid,
          logo: apiEditResponse?.data?.logo,
          name: apiEditResponse?.data?.name,
        })
      );
      setHandleState((prevState) => ({
        ...prevState,
        showCreateModal: true,
      }));

      // setShowCreateModal(true);
    }
  };

  const userRolePermissionListStoreData = useAppSelector((state) => state.auth.loggedUserRolePermissionList);
  React.useEffect(() => {
    const getRolePermissionStatus = async () => {
      const status = await checkRolePermissionStatus(userRolePermissionListStoreData, 'workspace_management');
      setHandleState((prevState) => ({
        ...prevState,
        workspaceStatus: status,
      }));

      // setWorkspaceStatus(status);
    };
    getRolePermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const rowSettingOptions = [
    {
      icon: <MdEdit className="text-brand-500 group-hover:text-white" />,
      accessName: 'edit',
      name: 'Edit',
      functioning: handeEditWorkspaceInTeams,
    },
    {
      icon: <TbTrash className="text-brand-500 group-hover:text-white" />,
      accessName: 'delete',

      name: 'Delete',
      functioning: handleWorkspaceDeleteWithOtp,
    },
  ];

  React.useEffect(() => {
    const finalRowSetting = handleState?.workspaceStatus?.is_owner
      ? rowSettingOptions
      : rowSettingOptions.filter((item) => handleState?.workspaceStatus?.hasOwnProperty(item?.accessName) && handleState?.workspaceStatus?.hasOwnProperty(item.accessName));
    // setOptions(finalRowSetting);
    setHandleState((prevState) => ({
      ...prevState,
      options: finalRowSetting,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleState?.workspaceStatus]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns, setColumns] = React.useState([]);
  // const handleFilter = async (filteredData: any) => {
  //   const encryptedData = jwtEncrypt(filteredData);
  //   setQueryParams((prev) => {
  //     if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
  //     return { ...prev, filter: encryptedData };
  //   });
  // };

  // React.useEffect(() => {
  //   const data = [
  //     // { id: '1', name: 'Assignee', conditions: [{ id: "1", name: 'Is' }, { id: "2", name: 'Is not' }], resultValue: [{ id: "1", name: 'Me' }, { id: "2", name: 'P1' }, { id: "3", name: 'P2' }] },
  //     {
  //       id: '2',
  //       name: 'Status',
  //       uniqueColumnName: 'status',
  //       conditions: [
  //         { id: '1', name: 'Is', uniqueName: 'is' },
  //         { id: '2', name: 'Is not', uniqueName: 'not' },
  //       ],
  //       resultValue: [
  //         { id: '1', name: 'Inactive', uniqueName: '0' },
  //         { id: '2', name: 'Active', uniqueName: '1' },
  //       ],
  //     },

  //     // {
  //     //   id: '4',
  //     //   name: 'No of Users',
  //     //   uniqueColumnName:
  //     //     'no',
  //     //   conditions: [
  //     //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //     //   ],
  //     //   resultValue: [],
  //     // },
  //     // {
  //     //   id: '5',
  //     //   name: 'Bounce Rate',
  //     //   uniqueColumnName:
  //     //     'campaign_delivery_message_data.email_bounce_percentage',
  //     //   conditions: [
  //     //     { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //     //     { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //     //   ],
  //     //   resultValue: [],
  //     // },
  //     {
  //       id: '6',
  //       name: 'Added By',
  //       uniqueColumnName: 'campaign_delivery_message_data.email_unsubscribe_percentage',
  //       conditions: [
  //         { id: '1', name: 'More Than', uniqueName: 'greater_than' },
  //         { id: '2', name: 'Less than', uniqueName: 'less_than' },
  //       ],
  //       resultValue: [],
  //     },
  //   ];
  //   setOptionData(data);
  //   setColumns(columnData);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });

  const debouncedSearchValue = useDebounce(queryParams?.search, 300);
  const columnHelper = createColumnHelper<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableSelection, setTableSelection] = React.useState({
    selectedIds: [],
    globalOpt: false,
  });

  const [optionsData, setOptionsData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setOptionsData(workspaceManagementFilterDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => {
      if (filteredData?.data?.length) {
        return { ...prev, filter: encryptedData };
      } else {
        return { ...prev, filter: '' };
      }
      // if (!prev?.filter && !filteredData?.data?.length) return { ...prev, filter:'' };
      // return { ...prev, filter: encryptedData };
    });
    // setQueryParams((prev) => {
    //   if (!prev?.filter && !filteredData?.data?.length) return { ...prev };
    //   return { ...prev, filter: encryptedData };
    // });
  };

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-3 flex items-center">
          S.no
          {/* <input
            type="checkbox"
            id="select_all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          /> */}
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-4 flex items-center text-sm" onClick={(event) => event?.stopPropagation()}>
            {info?.row?.index + 1}
            {/* <input
              type="checkbox"
              id={`row-${info?.row.id + 1}`}
              checked={info?.row.getIsSelected()}
              // disabled={!info?.row.getCanSelect()}
              onChange={info?.row.getToggleSelectedHandler()}
              className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
            /> */}
          </div>
        );
      },
    }),

    columnHelper.accessor('workspace', {
      id: 'workspace',
      enableSorting: false,
      header: () => <div>Workspace</div>,
      cell: (info) => <h5 className="text-xs font-bold text-gray-900 dark:text-gray-300">{info.row?.original?.name}</h5>,
    }),

    columnHelper.accessor('no_of_users', {
      id: 'no_of_users',
      enableSorting: false,
      header: () => <div>No of Users</div>,
      cell: (info) => <h5 className=" ml-5 text-[10px] font-medium text-gray-700 dark:text-gray-300">{info.row?.original?.no_of_users}</h5>,
    }),

    columnHelper.accessor('no_of_campaigns', {
      id: 'no_of_campaigns',
      enableSorting: false,
      header: () => <div>No of Campaigns</div>,
      cell: (info) => <h5 className=" ml-5 text-[10px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: (row) => {
        return (
          <div className="column-cdate group  w-min select-none text-nowrap font-semibold">
            Created On
            {/* {!row.column.getIsSorted() && <IoArrowUp className="hidden group-hover:block absolute -right-4 top-0.5" />}
                  {(row.column.getIsSorted() === "asc") && <IoArrowDown className="absolute -right-4 top-0.5" />}  onClick={() => handleSort('cdate')}
                  {((row.column.getIsSorted() === "desc") || (!table.getState().sorting.length)) && <IoArrowUp className="absolute -right-4 top-0.5" />} */}
          </div>
        );
      },
      cell: (row) => {
        const utcDateString = row.row?.original.cdate;
        const userTimeZone = 'Asia/Kolkata';
        const utcDate = new Date(utcDateString);
        const localDate = utcDate.toLocaleString('en-US', {
          timeZone: userTimeZone,
        });
        const date = moment(new Date(localDate));

        return (
          <div className="group/tooltip  ml-2 w-full text-xs font-semibold dark:text-gray-300">
            <div className="">
              <span className={`w-28 row-cdate-${row?.row?.id}`}>{calculateTimeAgo(row.row?.original.cdate)}</span>
              <div className="relative">
                <ArrowedTooltip className={`-mt-5 hidden group-hover/tooltip:block `} toolTipId={`row-cdate-tooltip-${row?.row?.id}`} data={date.format('DD MMM YYYY, hh:mm A')} />
              </div>
            </div>
          </div>

          //           <div className="group ml-2 w-full text-xs font-semibold dark:text-gray-300">
          //   <div className="inline-block ">
          //     <span className={`w-28 row-cdate-${row?.row?.id}`}>
          //       {calculateTimeAgo(row.row?.original.cdate)}
          //     </span>
          //     <ArrowedTooltip
          //       className={`absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 z-10
          //                   left-0 top-full mt-1`}
          //       toolTipId={`row-cdate-tooltip-${row?.row?.id}`}
          //       data={date.format('DD MMM YYYY, hh:mm A')}
          //     />
          //   </div>
          // </div>
        );
      },
    }),

    // columnHelper.accessor('added_date', {
    //   id: 'added_date',
    //   header: () => <div className="text-start">Added Date</div>,
    //   cell: (info) => (
    //     <h5 className="text-[10px] font-medium text-gray-700">
    //       {info.row?.original?.cdate}
    //     </h5>
    //   ),
    // }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => <div className="ml-3 text-start">Status</div>,
      cell: (row) => (
        <>
          {
            <>
              {row.row.original.status === 0 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                  <MdOutlineWatchLater className="text-xs text-draft-color" />
                  <h5 className={`text-xs font-semibold text-draft-color status-index-${row?.row?.id}`}>Draft</h5>
                </div>
              )}
              {row.getValue() === 1 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1">
                  <MdOutlineHourglassEmpty className="text-xs text-active-color" />
                  <h5 className={`text-xs font-semibold text-active-color status-index-${row?.row?.id}`}>Active</h5>
                </div>
              )}
              {row.getValue() === 2 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                  <MdOutlineWatchLater className="text-xs text-rejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color status-index-${row?.row?.id}`}>Rejected</h5>
                </div>
              )}
              {row.getValue() === 3 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-paused-bg px-2 py-1">
                  <MdPauseCircle className="text-xs text-paused-color" />
                  <h5 className={`text-xs font-semibold text-paused-color status-index-${row?.row?.id}`}>Paused</h5>
                </div>
              )}
              {row.getValue() === 4 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                  <MdCheck className="text-xs text-completed-color" />
                  <h5 className={`text-xs font-semibold text-completed-color status-index-${row?.row?.id}`}>Complete</h5>
                </div>
              )}
              {row.getValue() === 5 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                  <MdPauseCircle className="text-xs text-rejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color status-index-${row?.row?.id}`}>Disconnected</h5>
                </div>
              )}
              {row.getValue() === 6 && (
                <div className="flex w-min items-center gap-0.5 rounded-full bg-rejected-bg px-2 py-1">
                  <MdPauseCircle className="text-xs text-rejected-color" />
                  <h5 className={`text-xs font-semibold text-rejected-color status-index-${row?.row?.id}`}>Error</h5>
                </div>
              )}
            </>
          }
        </>
      ),
    }),

    columnHelper.accessor('added_by', {
      id: 'added_by',
      header: () => <div className="ml-3 text-start">Added By</div>,
      cell: (info) => <h5 className="text-[10px] font-medium text-gray-700 dark:text-gray-300">{info.getValue()}</h5>,
    }),

    columnHelper.accessor('uuid', {
      id: 'edit',
      header: () => <div className="-ml-2 w-min">Action</div>,
      cell: (row) => {
        return <>{<CommonRowSettings uuidRow={row?.row?.original?.uuid} rowData={row?.row?.original} options={handleState?.options} />}</>;
      },
    }),
  ];

  const table = useReactTable({
    data: data?.data,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const handleWorkspaceSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const fetchallworkspace = async () => {
    try {
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
        {
          key: 'sort',
          value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
        },
        { key: 'filter', value: queryParams?.filter },
      ]);
      console.log(queryParams);
      setData({ ...data, loading: true });
      const response = await BaseApi.get(`/workspace?${query}`);
      // console.log(query, 'DEBOUNCE RESPONCE', response?.data);

      response?.data?.data?.length ? setData({ ...data, data: response?.data?.data, loading: false }) : setData({ ...data, data: [], loading: false });

      // console.log('WorkspaceResponse', response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchallworkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.flagStatus, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, custom?.globalDependencyLoading]);

  React.useEffect(() => {
    const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original);
    setTableSelection((prev) => ({ ...prev, selectedIds: tableSelectedData }));

    if (table.getSelectedRowModel().rows.length > 1) setTableSelection((prev) => ({ ...prev, globalOpt: true }));
    else setTableSelection((prev) => ({ ...prev, globalOpt: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  // Scroll function
  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset + 1 },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`/workspace?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: prev?.data?.length,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, debouncedSearchValue, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div className=" sticky top-[0px]  z-10 -mt-2 flex w-full items-center rounded-bl-lg rounded-br-lg bg-white px-2 py-4 shadow dark:!bg-darkOne sm:gap-32 lg:gap-32 xl:justify-between">
        <div className="flex items-center gap-2.5">
          <div
            className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 dark:!border-darkBorder md:w-[13rem] lg:w-[26rem]"
            // onClick={() => searchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer text-gray-800 dark:text-white" />
            <input
              type="text"
              placeholder="Search"
              id="donot-search-input"
              className="workspace_search_box w-full text-sm text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkOne dark:text-gray-300 dark:placeholder:text-white "
              onChange={handleWorkspaceSearch}
            />
          </div>
          <CustomFilter optionData={optionsData} handleFilter={handleFilter}  />

          {/* {<CustomFilter optionData={optionData} handleFilter={handleFilter} id="campaign-filter-btn" />} //commented need for development */}
        </div>
        {(handleState?.workspaceStatus?.is_owner || handleState?.workspaceStatus?.add) && (
          <Button
            id="add-workspace-btn"
            name="Add Workspace"
            customClass="text-xs !font-normal bg-blueSecondary dark:border-none "
            LeftIcon={<MdAdd className="text-[16px]" />}
            // disable={openPopUP}
            onClick={() => {
              dispatch(resetTeamsWorkspaceEditState());
              setHandleState((prevState) => ({
                ...prevState,
                showCreateModal: true,
              }));

              // setShowCreateModal(true);
            }}

            // onClick={() => setOpenPopUp(true)}
          />
        )}
      </div>
      <div className=" my-3 h-[73vh] overflow-y-auto scroll-smooth rounded-lg bg-white py-3 shadow dark:bg-darkTwo">
        {!data.loading ? (
          <>
            <div className="mb-2 ">
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        const RowDataWidth = columnWidth;
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-2.5 text-start text-column dark:border-white/10 dark:bg-darkOne`}
                          >
                            <div className="text-[.625rem] font-semibold">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px] dark:divide-darkBorder">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className={`cursor-default select-none hover:bg-hover dark:hover:bg-darkOne`}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = columnWidth;
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9} className="pt-2 text-center text-[10px] font-medium text-gray-700">
                        No Matching Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent
                loadMore={scrollFunction}
                loader={
                  <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 4% 4%" />
                }
              />
            </div>
          </>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={9}
            row={3}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
          />
        )}
      </div>
      <div>
        {(handleState?.showCreateModal || handleState?.workspaceEditData?.editState) && (
          <AddWorkspaceModal
            workSpaceEditData={handleState?.workspaceEditData}
            isOpen={handleState?.showCreateModal}
            onClose={
              () =>
                setHandleState((prevState) => ({
                  ...prevState,
                  showCreateModal: false,
                }))

              // setShowCreateModal(false)
            }
          />
        )}
      </div>
      <div>
        {handleState?.deleteState && (
          <OtpDeletePage
            workspacedata={handleState?.workspaceDeleteData}
            isOpen={handleState?.deleteState}
            onClose={() =>
              setHandleState((prevState) => ({
                ...prevState,
                deleteState: false,
              }))
            }
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceManagementTable;
