import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import SolidSubtleMultiAlert from 'components/actions/Notification';
import SetUp from 'components/actions/SetUp';
import Card from 'components/card';
import { MdCheckCircle, MdInfoOutline } from 'react-icons/md';
import CustomBadge from 'components/badges/CustomBadge';



const DomainHealth = () => {
  return (
   <Card extra={"w-full px-8 py-8"}>
        <h4 className="mb-5 text-xl font-bold text-navy-700 dark:text-white">
       Domain Health
                </h4>
       <SolidSubtleMultiAlert
            title="Your domain is healthy!"
            description="This gives you a higher chance to reach leads' Inbox."
            metadata = "Learn more about domain records"
            icon={<BsFillCheckCircleFill />}
            iconColor="text-green-500"
            bg="bg-[#C9FBD5] dark:!bg-navy-700"
            mb="mb-6"
            closeBg="hover:bg-white/20 text-navy-900 dark:text-white"
          />
          <SetUp 
                name="SPF"
                value=""
                component = {<>
                <MdCheckCircle className='text-2xl text-green-500'/>
                  <CustomBadge color='green' bgColor='green' content='Valid'/>
                </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}
            />
            <SetUp 
                name="DKIM"
                value=""
                component = {<>
                  <MdCheckCircle className='text-2xl text-green-500'/>
                    <CustomBadge color='green' bgColor='green' content='Valid'/>
                  </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}

            />
            <SetUp 
                name="DMARC"
                value=""
                component = {<>
                  <MdCheckCircle className='text-2xl text-green-500'/>
                    <CustomBadge color='green' bgColor='green' content='Valid'/>
                  </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}
            />
            <SetUp 
                name="MX"
                value=""
                component = {<>
                  <MdCheckCircle className='text-2xl text-green-500'/>
                    <CustomBadge color='green' bgColor='green' content='Valid'/>
                  </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}
            />
            <SetUp 
                name="A-Record"
                value=""
                component = {<>
                  <MdCheckCircle className='text-2xl text-green-500'/>
                    <CustomBadge color='green' bgColor='green' content='Valid'/>
                  </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}
            />
            <SetUp 
                name="rDNS"
                value=""
                component = {<>
                  <MdCheckCircle className='text-2xl text-green-500'/>
                    <CustomBadge color='green' bgColor='green' content='Valid'/>
                  </>}
                py="py-6"
                border="border-b"
                Icon={<MdInfoOutline color='gray' className='text-2xl'/>}
            />
            <div className='mt-5 flex flex-start gap-5 items-center'>
             <button className="w-1/4 rounded-2xl bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Check Health
            </button>
            <p className="text-base text-gray-600">
            Last checked: 06 Nov 2023, 12.02 PM</p>
            </div>
    </Card>
  )
}

export default DomainHealth