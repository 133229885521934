import { CustomMenu } from 'components/customMenu';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';

const WorkspaceDropdown = ({ options, placeholderName, onSelectionChange ,error }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSearchChange = (e: any) => setSearch(e.target.value);

  const handleSelect = (option: any) => {
    setSelectedOptions((prev) => {
      const index = prev.findIndex((item) => item.uuid === option.uuid);
      let newSelection = [];
      if (index >= 0) {
        newSelection = [...prev.slice(0, index), ...prev.slice(index + 1)];
      } else {
        newSelection = [...prev, option];
      }
      onSelectionChange(newSelection); // Notify parent component of the change
      return newSelection;
    });
  };
  //   const handleSelect = (option: any) => {
  //     setSelectedOptions([option]); // Directly set the selected option
  //     onSelectionChange([option]); // Notify parent component of the change
  //   };

  const filteredOptions = options.filter((option: any) => option?.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="relative bg-white dark:bg-darkThree">
      <CustomMenu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        btnId='workspace-select'
        btnContent={placeholderName}
        rightIcon={<IoIosArrowDown className="text-gray-600" />}
        menuListClassName={'w-full h-32 overflow-auto dark:border-darkBorder dark:bg-darkOne dark:!text-white dark:shadow-darkBorder '}
        btnClassName={`flex dark:bg-darkOne dark:border-darkBorder dark:!text-gray-400 workspace-list-dropdown-btn w-full items-center justify-between rounded-md border border-gray-200  text-start text-xs !text-[#667085] focus:text-blackDark ${error ? `!border-red-500`:``}`}
      >
        <div className="mx-2 mt-2 rounded-md border border-gray-500">
          <div className=" flex w-full items-center">
            <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              className="input dark:bg-darkOne dark:!text-white input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none"
            />
          </div>
        </div>
        <div className="mt-3">
          <ul className="">
            {filteredOptions.map((option: any, idx: number) => (
              <li key={option.uuid} className="flex items-center p-2">
                <input
                  id={`workspace-data-check-${idx}`}
                  type="checkbox"
                  checked={selectedOptions.some((sel) => sel.uuid === option.uuid)}
                  onChange={() => handleSelect(option)}
                  className="checkbox checkbox-primary mr-2"
                />
                <label className="select-none text-sm" id={`workspace-opt-${idx}`} onClick={() => handleSelect(option)}>
                  {option.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </CustomMenu>
      {/* <button
        id="workspace-list-dropdown-btn"
        onClick={toggleDropdown}
        className="flex w-full items-center justify-between rounded-md border border-gray-200 px-2 py-2 text-start text-xs !text-[#667085] focus:text-blackDark"
      >
        {placeholderName}
        <IoIosArrowDown className="text-gray-600" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full rounded-md bg-white shadow-md">
          <div className="m-3 rounded-md border border-gray-500">
            <div className="relative flex w-full items-center">
              <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                className="input input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none"
              />
            </div>
          </div>
          <div className="m-3">
            <ul className="max-h-40 overflow-y-auto">
              {filteredOptions.map((option: any, idx: number) => (
                <li key={option.uuid} className="flex items-center p-2">
                  <input
                    id={`workspace-data-check-${idx}`}
                    type="checkbox"
                    checked={selectedOptions.some((sel) => sel.uuid === option.uuid)}
                    onChange={() => handleSelect(option)}
                    className="checkbox checkbox-primary mr-2"
                  />
                  <label className="select-none text-sm" onClick={() => handleSelect(option)}>
                    {option.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default WorkspaceDropdown;
