import Button from 'common/sparkle-common/Button';
import SparkleCheckBox from 'common/sparkle-common/checkBox';
import { CustomMenu } from 'components/customMenu';
import InputField from 'components/fields/InputField';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoPeopleSharp } from 'react-icons/io5';
import { useAppDispatch, useAppSelector } from 'store';
import { setSelectedProspectsCountData } from 'store/campaign-v2';

const SelectCheckbox = (props: any) => {
  const { table } = props;
  const total_records = useAppSelector((state) => state.campaignv2.prospects.search_prospects.total_records);
  const countData = useAppSelector((state) => state?.campaignv2?.prospects?.search_prospects?.users_count)
  // const [handleState, setHandleState] = React.useState({
  //   select_number_of_people: 0,
  //   max_people_per_company: 0,
  // });
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClose = () => {
    setIsOpen(false);

    //   setHandleState({
    //     select_number_of_people: 0,
    //     max_people_per_company: 0,
    //   })
  };

  // const selectTableBox = (value:number) => {
  //   const rows = table?.getCoreRowModel()?.rows;

  //   if (rows) {
  //     for (let i = 0; i < value; i++) {

  //         rows[i]?.toggleSelected();
  //       }
  //   }
  // };


  // const selectTableBox = (value: number) => {
  //   const rows = table?.getCoreRowModel()?.rows;

  //   if (rows) {
  //     for (let i = 0; i < value; i++) {
  //       if (!rows[i]?.getIsSelected()) {
  //         // Only toggle if the row is not already selected
  //         rows[i]?.toggleSelected();
  //       }
  //     }
  //   }
  // };

  const selectTableBox = (value: number) => {
    const rows = table?.getCoreRowModel()?.rows;

    if (rows) {
      // First, select the rows up to the specified value
      for (let i = 0; i < value && i < rows.length; i++) {
        if (!rows[i]?.getIsSelected()) {
          rows[i]?.toggleSelected();
        }
      }

      // Then, deselect any rows beyond the specified value
      for (let i = value; i < rows.length; i++) {
        if (rows[i]?.getIsSelected()) {
          rows[i]?.toggleSelected();
        }
      }
    }
  };


  const handleUploadUsersCount = () => {
    // dispatch(setSelectedProspectsCountData({ ...handleState }));
    selectTableBox(countData?.select_number_of_people)
    handleClose()
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    const numericValue = parseInt(value);

    if ((id === 'max_people_per_company' && (numericValue < 0 || numericValue > 2000)) || (id === 'select_number_of_people' && (numericValue < 0 || numericValue > total_records || numericValue > 2000))) {
      return;
    } else {
      // setHandleState((prevState) => ({
      //   ...prevState,
      //   [id]: numericValue,
      // }));
      dispatch(setSelectedProspectsCountData({[id]:numericValue}))
    }
  };
  return (
    <CustomMenu
      open={isOpen}
      onClose={handleClose}
      onOpen={() => setIsOpen(true)}
      btnContent={<IoIosArrowDown className="text-gray-600" />}
      btnContentClassName="-mx-0"
      btnClassName="flex !w-fit !h-2 dark:bg-darkOne dark:!text-gray-400 role-list-dropdown-btn items-center justify-between rounded-md border border-gray-200 !px-1 py-2 text-start text-xs !text-[#667085] focus:text-blackDark"
      menuListClassName="!w-72 dark:bg-darkOne dark:!text-white rounded-md bg-white shadow-md dark:border-darkBorder dark:shadow-darkBorder"
    // rightIcon={<IoIosArrowDown className="text-gray-600 -ml-2" />}
    >
      <div className="m-2 py-2">
        <div className="flex items-center gap-4 text-xs font-semibold text-heading">
          <IoPeopleSharp size={18} />
          <p className=" w-52"> Select number of people</p>
          <InputField
            onChange={handleChange}
            value={countData?.select_number_of_people}
            id="select_number_of_people"
            type="number"
            inputClass="!rounded-sm border border-gray-600 !w-12 !h-7 !px-1"
          />

          {/* <input type="number" name="prospects_input_number" id="count" className="focus:border-transparent w-9 rounded-sm border border-gray-600 p-1 focus:outline-none" /> */}
        </div>

        <div className=" flex items-center gap-4 text-xs font-semibold text-heading">
          {/* <IoCheckboxOutline size={18} className="text-purple-600" /> */}
          <SparkleCheckBox id='max_people' checked={countData?.max_people} onChange={(event: any) => { dispatch(setSelectedProspectsCountData({ max_people: event?.target?.checked })) }} />
          <p className=" w-52">Max people per company</p>
          <InputField
            onChange={handleChange}
            value={countData?.max_people_per_company}
            id="max_people_per_company"
            type="number"
            inputClass="!rounded-sm border border-gray-600 !w-12 !h-7 !px-1"
          />
          {/* <input type="number" name="prospects_input_number" id="count" className="focus:border-transparent w-9 rounded-sm border border-gray-600 p-1 focus:outline-none" /> */}
        </div>

        <hr className="mt-4" />
        <Button
          name={`Apply Selection`}
          onClick={handleUploadUsersCount}
          // loading={loading}
          // disable={loading}
          customClass={`w-full border mt-4 -mb-2 text-xs active:scale-95 transition-transform duration-150 ease-out`}
        />
      </div>
    </CustomMenu>
  );
};

export default SelectCheckbox;


