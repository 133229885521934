import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import React, { useState } from 'react';
import { MdLink } from 'react-icons/md';
import { IoMdSearch } from 'react-icons/io';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { TbProgressCheck } from 'react-icons/tb';

import { useAppDispatch, useAppSelector } from 'store';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import { setCampaignSelectedCSVData } from 'store/campaign';
import { setFlagStatus, setGlobalOpt } from 'store/custom';
import EnrichemntModal from '../enrichment';
import { setEnrichment, setProspects, updateVariableData } from 'store/campaign-v2';
import UpdateVariableDrawer from '../update-variable/UpdateVariableDrawer';
import UploadLeads from './feature/UploadLeads';
import { restrictionPlan } from 'common/utils/utility';

const ProspectsList = () => {
  const { prospects, header_count } = useAppSelector((state) => state.campaignv2);
  const [columnOptions, setColumnOptions] = React.useState<{ [key: string]: boolean }>({});
  const [data, setData] = useState([]);
  const [loadedData, setLoadedData] = useState([]);
  const tableRef = React.useRef(null);
  const campaignSearchRef = React.useRef(null);
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const columnHelper = createColumnHelper<{ [key: string]: string }>();
  const [queryParams, setQueryParams] = useState({ offset: 0, searchOffset: 0 });
  // const initialMount = useRef(false);
  const columnData =
    prospects?.prospects_list?.length > 0 &&
    Object.keys(prospects?.prospects_list?.[0])
      ?.filter((item) => {
        return (
          item !== 'id' &&
          item !== 'organization_id' &&
          item !== '__twitter_url__' &&
          item !== '__facebook_url__' &&
          item !== '__facebook_url__' &&
          item !== 'github_url' &&
          (header_count === 3 ? true : !item?.includes('verification_status'))
        );
      })
      ?.map((key, index) => {
        return columnOptions[key]
          ? columnHelper.accessor(key, {
              id: key,
              enableSorting: false,
              header: (row) => {
                return <div className={`w-full select-none truncate font-semibold capitalize ${index === 0 ? 'pl-6' : 'px-3'}`}>{key.replaceAll('_', ' ')}</div>;
              },
              cell: (info) => {
                return (
                  <>
                    {info.cell.column.id === 'verification_status' ? (
                      <h5 className="w-full text-nowrap pl-6" id="verified_col">
                        <>
                          {info.getValue() === 'valid' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                              <MdLink className="text-xs text-completed-color" />
                              <h5 className={`text-xs font-semibold text-completed-color`}>Valid</h5>
                            </div>
                          )}
                          {info.getValue() === 'invalid' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-draft-bg px-2 py-1">
                              <MdLink className="text-xs text-draft-color" />
                              <h5 className={`text-xs font-semibold text-draft-color`}>Invalid</h5>
                            </div>
                          )}
                          {info.getValue() === 'unknown' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1">
                              <MdLink className="text-xs text-active-color" />
                              <h5 className={`text-xs font-semibold text-active-color`}>Unknown</h5>
                            </div>
                          )}
                          {info.getValue() === 'error' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-[#FEF3F2] px-2 py-1">
                              <MdLink className="text-xs text-[#B42318]" />
                              <h5 className={`text-xs font-semibold text-[#B42318]`}>Error</h5>
                            </div>
                          )}
                          {info.getValue() === 'catch_all' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-[#EEF4FF] px-2 py-1">
                              <MdLink className="text-xs text-[#3538CD]" />
                              <h5 className={`text-xs font-semibold text-[#3538CD]`}>Catch All</h5>
                            </div>
                          )}
                          {info.getValue() === 'timeout' && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-active-bg px-2 py-1">
                              <MdLink className="text-xs text-active-color" />
                              <h5 className={`text-xs font-semibold text-active-color`}>Timeout</h5>
                            </div>
                          )}
                          {!info.getValue() && (
                            <div className="flex w-min items-center gap-0.5 rounded-full bg-completed-bg px-2 py-1">
                              <TbProgressCheck className="text-xs text-completed-color" />
                              <h5 className={`text-xs font-semibold text-completed-color`}>Verifying</h5>
                            </div>
                          )}
                        </>
                      </h5>
                    ) : // : info?.cell?.column?.id?.includes('linkedin_url') ? (
                    //   (info.getValue() as string) ? (
                    //     <div className="group relative pl-3 font-semibold">
                    //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                    //         <FaLinkedin size={18} />
                    //       </a>
                    //     </div>
                    //   ) : (
                    //     <p
                    //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                    //       id={`campaign - ${info.getValue()}`}
                    //     >
                    //       <button className="text-warnorange ">
                    //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                    //       </button>
                    //     </p>
                    //   )
                    // )
                    info?.cell?.column?.id?.includes('photo_url') ? (
                      (info.getValue() as string) ? (
                        <div className="group relative  flex w-40 items-center gap-2 pl-3 font-semibold">
                          <div className="rounded-full border p-0.5">
                            <img src={info.getValue()} alt="" className="size-6 rounded-full" />
                          </div>
                        </div>
                      ) : (
                        <p
                          className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                          id={`campaign - ${info.getValue()}`}
                        >
                          <button className="text-warnorange">
                            {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                          </button>
                        </p>
                      )
                    ) : (
                      // : info?.cell?.column?.id?.includes('twitter_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <FaSquareXTwitter size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('facebook_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <FaFacebook size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_website_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <TbWorldWww size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_linkedin_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <FaLinkedin size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_twitter_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <FaSquareXTwitter size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_facebook_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <FaFacebook size={18} />
                      //       </a>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_logo_url') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative  flex w-40 items-center gap-2 pl-3 font-semibold">
                      //       <div className="rounded-full border p-0.5">
                      //         <img src={info.getValue()} alt="" className="size-6 rounded-full" />
                      //       </div>
                      //     </div>
                      //   ) : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      // : info?.cell?.column?.id?.includes('organization_primary_domain') ? (
                      //   (info.getValue() as string) ? (
                      //     <div className="group relative pl-3 font-semibold">
                      //       <a href={info?.getValue()} target="_blank" rel="noreferrer" className="cursor-pointer">
                      //         <TbWorldWww size={18} />
                      //       </a>
                      //     </div>
                      //   )
                      //   : (
                      //     <p
                      //       className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} truncate text-[.8125rem] row-campaign-${info?.row?.id}`}
                      //       id={`campaign - ${info.getValue()}`}
                      //     >
                      //       <button className="text-warnorange">
                      //         {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                      //       </button>
                      //     </p>
                      //   )
                      // )
                      <div
                        className={`w-full ${index === 0 ? 'pl-6 font-bold text-heading' : 'px-3'} text-[.8125rem] row-campaign-${info?.row?.id}`}
                        id={`campaign - ${info.getValue()}`}
                      >
                        {(info.getValue() as string) ? (
                          <div className="max-w-72 truncate ">{info.getValue()}</div>
                        ) : (
                          <button className="text-warnorange">
                            {<span className="rounded-full border border-warnorange px-1 text-[.625rem]">?</span>} {`{{ Missing }}`}{' '}
                          </button>
                        )}
                      </div>
                    )}
                  </>
                );
              },
            })
          : null;
      })
      .filter(Boolean);

  const table = useReactTable({
    data: loadedData,
    columns: columnData,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const setPageData = () => {
    const list = prospects.preview.isPreview ? prospects.preview.update_prospect_list : prospects?.prospects_list;
    const pageList = list?.map(({ email, verification_status, ...item }: any) => (header_count === 3 ? { email, verification_status, ...item } : { email, ...item }));
    setData(pageList);
  };

  React.useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects.preview.isPreview, prospects?.prospects_list]);

  React.useEffect(() => {
    setLoadedData(prospects?.prospects_list?.slice(0, 25));
    const columnOptionsData =
      prospects?.prospects_list?.length > 0 &&
      Object.keys(prospects?.prospects_list[0]).reduce((acc: { [key: string]: boolean }, value) => {
        // acc[value] = index < 10 ? true : false
        acc[value] = true;
        return acc;
      }, {});
    setColumnOptions(columnOptionsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects?.prospects_list]);

  React.useEffect(() => {
    setQueryParams((prev) => ({ ...prev, offset: 0 }));
    if (!search?.trim()) {
      setLoadedData([]);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const tableSelectedData = table?.getSelectedRowModel()?.rows?.map((val) => val.original);
    dispatch(setCampaignSelectedCSVData(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const prospectsFetch = async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
    // if (!initialMount?.current) {
    //   initialMount.current = true;
    //   return;
    // }
    const fetchEmailData = async () => {
      try {
        setIsFetching(true);
        setLoadedData((prev) => [...prev, ...data?.filter((item) => item?.email?.includes(search))?.slice(queryParams?.offset, queryParams?.offset + 25)]);

        setQueryParams((prev) => ({
          ...prev,
          offset: prev?.offset + 25,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    if (search) return;
    loadedData?.length < data?.length && fetchEmailData();
  };
  const userType = useAppSelector((state) => state?.globalsettings?.globalSetting?.billings);

  const handleEnrichment = () => {
    const restriction = restrictionPlan('enrichment', userType);
    if (!restriction) return;
    dispatch(setFlagStatus(true));
    // SessionStorage.clearItem(EDIT_ID);
    dispatch(setEnrichment({ modalView: true }));
  };

  const handleVariableChange = (email: string) => {
    if (header_count === 3) return;
    dispatch(updateVariableData({ modal_view: true, row_email: email }));
  };
  return (
    <>
      <div className={`w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[26rem]"
              onClick={() => campaignSearchRef.current.focus()}
            >
              <IoMdSearch className="size-4 cursor-pointer" />
              <input
                ref={campaignSearchRef}
                type="text"
                placeholder="Search email"
                id="prospects-search-input"
                className="w-full text-sm font-medium text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            {header_count !== 3 && prospects?.prospect_type === 1 ? (
              <button
                onClick={handleEnrichment}
                className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                <AiOutlineThunderbolt className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add Enrichments</span>
              </button>
            ) : (
              <></>
            )}
            {/* {
              Object.keys(columnOptions)?.length > 10 ?
                <div className={`bg-white outline outline-2 rounded-md relative cursor-pointer ${colViewOptions ? 'outline-menuBorder' : 'outline-gray-300'}`} ref={parentRef}>
                  <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setColViewOptions(!colViewOptions)}>
                    <MdViewWeek className="text-gray-700" />
                  </button>
                  {colViewOptions && <div ref={childRef}
                    className="z-10 py-2 px-3 space-y-1 w-64 rounded-md border bg-white dark:bg-navy-700 absolute top-10 right-0 max-h-80 overflow-y-auto">
                    {Object.entries(columnOptions).map((column) => <div key={column[0]}
                      className="flex justify-between items-center">
                      <h5 className="text-xs dark:text-white">{column[0]}</h5>
                      <Toggle
                        checkedCondition={column[1]}
                        changeFunction={() => { handleColumnData(column[0]) }}
                        // disabled={col?.disabled}
                        className={`scale-50 col-list-${column[0]}`}
                        id={`col-list-${column[0]}`}
                      />
                    </div>)}
                  </div>}
                </div>
                :
                <></>
            } */}
          </div>
        </div>
        <div className="h-[65vh] overflow-x-auto overflow-y-auto scroll-smooth xl:overflow-x-auto" ref={tableRef}>
          {prospects?.prospects_list?.length > 0 && (
            <table className=" overflow-x-auto sm:w-[150%] lg:w-[120%] xl:w-full ">
              <thead>
                {table?.getHeaderGroups()?.map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup?.headers?.map((header, index, arr) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-2 text-start dark:border-white/10 dark:bg-navy-900`}
                        >
                          <div className="text-[0.7rem] font-medium text-[#667085]">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px] selection:bg-yellow-50 selection:underline selection:decoration-yellow-400 selection:decoration-[1.5px]">
                {prospects?.prospects_list.length ? (
                  table
                    ?.getRowModel()
                    ?.rows?.filter((row) => row?.original?.email.includes(search))
                    ?.map((row) => {
                      return (
                        <tr
                          key={row.id}
                          className={`${row?.original?.email === prospects.update_variables.row_email ? 'bg-hover' : ''}`}
                          onClick={() => handleVariableChange(row?.original?.email)}
                        >
                          {row?.getVisibleCells?.()?.map((cell) => {
                            return (
                              <td
                                key={cell.id}
                                className={`cursor-pointer border-white/0 py-4 text-start text-column duration-300 hover:bg-hover dark:text-white dark:hover:bg-navy-900`}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          )}
          <InfiniteScrollComponent
            loadMore={prospectsFetch}
            loader={
              <TableLoader
                width="w-full"
                border="border border-gray-100"
                column={10}
                row={1}
                skeletonHeight="h-2"
                padding="px-5 py-4"
                columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
              />
            }
          />
        </div>
      </div>
      <EnrichemntModal />
      <UpdateVariableDrawer />
      {prospects?.uploaded_leads_count?.view && (
        <UploadLeads
          isOpen={prospects?.uploaded_leads_count?.view}
          onClose={() =>
            dispatch(
              setProspects({
                uploaded_leads_count: {
                  ...prospects?.uploaded_leads_count,
                  view: false,
                },
              })
            )
          }
        />
      )}
    </>
  );
};

export default ProspectsList;
