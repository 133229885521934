import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Button from 'common/sparkle-common/Button';
import React, { useContext, useRef, useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import { IoMdSearch } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { setSelectedRowItems } from 'store/campaign-insights';
import BubbleChart from './features/BubbleChart';
import { InsightsContext } from '..';
import TableLoader from 'components/Loaders/TableViewLoader';
import InfiniteScrollComponent from 'components/infinityScroll';
import { _errorHandler, CSVFileDownloader, setAndGetQueryParams, toastState, useDebounce } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { toast } from 'react-toastify';
// import BubbleRanger from './features/BubbleRanger';

const ProspectsInsights = () => {
    const { uuid, data, setData, prospectLoader } = useContext(InsightsContext)
    const state = useAppSelector(state => state?.campaignInsights)
    // const [colViewOptions, setColViewOptions] = useState(false)
    // const [columnOptions, setColumnOptions] = useState([])
    const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 2, sort: { columnName: '', order: '' }, search: '', filter: '' });
    const [search, setSearch] = useState(null);
    const debouncedSearchValue = useDebounce(search, 300);
    const columnHelper = createColumnHelper<any>();
    const tableRef = useRef(null)
    const dispatch = useDispatch()

    // const handleSelect = (rowId: string) => {
    //     dispatch(setLastSelectedItem(rowId))
    // }

    // const handleColumnData = (colId: string, colState: boolean) => {
    //     setColumnOptions((prevOptions: any) => {
    //         return prevOptions.map((option: any) => {
    //             if (option.id === colId) {
    //                 return { ...option, [colId]: !colState };
    //             }
    //             return option;
    //         });
    //     });
    // }
    // const convertArrayOfObjectsToCSV = (array: any): string => {
    //     const list = array.map(({ status, ...item }: any) => {
    //         return { ...item }
    //     })
    //     if (list.length === 0) {
    //         return '';
    //     }
    //     const headers = Object.keys(list[0]).join(',');
    //     const rows = list.map((obj: any) => {
    //         return Object.values(obj).map(item => item.toString().replace(',', '/,')).join(',')
    //     });
    //     return [headers, ...rows].join('\n');
    // };

    const downloadCsv = async () => {
        try {
            const response = await BaseApi.get(`/campaign/prospects/export/list/${uuid}`);
            if (!response?.error) {
                CSVFileDownloader(response?.data, 'Prospect_csv');
            }
            else {
                toast.error(_errorHandler(response?.message), toastState.error);
            }
        }
        catch (err) {
            console.error(err);
        }
    };

    const columnData = state?.prospectsList?.length > 0 && Object.keys(state?.prospectsList?.[0])
        ?.map((key, index) => {
            return columnHelper.accessor((key), {
                id: key,
                enableSorting: false,
                header: (row) => {
                    return (<>
                        {/* {key === 'id' && <input type="checkbox" id="select-all" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()}
                            className="size-3 cursor-pointer accent-brand-500 dark:accent-purple-600 pl-6 pr-3 w-full block" />} */}

                        {key !== 'id' && key !== 'status' && <div className={`select-none font-semibold truncate capitalize w-full px-3`}>
                            {key.replaceAll('_', ' ')} </div>}

                        {key === 'status' && <div className={`select-none font-semibold w-full px-3`}>Status</div>}
                    </>)
                },
                cell: (info) => {
                    return (<>
                        {/* {key === 'id' && <CheckBox info={info} table={table} lastSelectedItem={state?.lastSelectedItem} onSelect={handleSelect} className='pl-6 pr-3' />} */}
                        {key !== 'id' && key !== 'status' && <p className={`w-full px-3 text-[#667085] truncate text-[.75rem]`}>{info.getValue()} </p>}
                        {key === 'status' && <BubbleChart data={info.getValue()} />}
                        {/* {key === 'status' && <BubbleRanger data={info.getValue()} />} */}
                    </>

                    )
                },
            })
        }).filter(Boolean)

    const table = useReactTable({
        data: data?.data,
        columns: columnData || [],
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
    });



    // React.useEffect(
    //     () => {
    // fetchList()
    // setData(state?.prospectsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []
    // )

    // React.useEffect(
    //     () => {
    //         const colViewList = Object.keys(state?.prospectsList?.[0] || [])?.filter(key => key !== 'status').map((item: string) => {
    //             return { name: item, id: item, [item]: true }
    //         })
    //         // console.log(colViewList)
    //         setColumnOptions(colViewList)
    //     }, [state?.prospectsList]
    // )

    React.useEffect(() => {
        const tableSelectedData = table.getSelectedRowModel().rows.map((val: any) => val.original.id);
        dispatch(setSelectedRowItems(tableSelectedData));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.getSelectedRowModel()]);

    React.useEffect(
        () => {
            const handleSearch = async () => {
                const response = await BaseApi.get(`/campaign/v2/insights/prospects/${uuid}?search=${search}`);
                const data = response?.data;
                setData({
                    data: data?.data,
                    count: data?.total_records?.count
                })
            }
            search !== null && handleSearch();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedSearchValue]
    )

    const prospectsFetch = React.useCallback(
        async (setIsFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
            const fetchProspectsData = async () => {
                try {
                    setIsFetching(true);
                    const query = setAndGetQueryParams([
                        { key: 'limit', value: queryParams?.limit },
                        { key: 'offset', value: queryParams?.offset },
                        { key: 'sort', value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '' },
                        { key: 'filter', value: queryParams?.filter },
                    ]);
                    const response = await BaseApi.get(`/campaign/v2/insights/prospects/${uuid}?${query}`);
                    const responseData = response.data;
                    if (!responseData?.data) return setData({ data: [], count: 0 });
                    if (responseData?.data?.length < 25) {
                        setData((prev: any) => ({ data: [...prev?.data, ...responseData?.data], count: prev?.data?.length }));
                    } else {
                        setData((prev: any) => ({ ...prev, data: [...prev?.data, ...responseData?.data] }));
                    }
                    setQueryParams((prev) => ({
                        ...prev,
                        offset: prev?.offset + 1,
                    }));
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsFetching(false);
                }
            };

            data?.data?.length && data?.data?.length < data?.count && fetchProspectsData();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
    );

    return (
        <div>
            <hr className='mx-2' />
            <div className='border-[1px] border-[#EAECF0] rounded-sm shadow-bottom mt-4 mx-2'>
                <div className='h-[3.8rem] border-b p-3 px-3.5 flex justify-between items-center'>

                    <div className='flex items-center gap-2'>
                        <div className="flex items-center lg:w-[26rem] md:w-[13rem] rounded-md py-[0.3rem] px-3 gap-2 border outline outline-0 focus-within:outline-2 focus-within:outline-brand-400" >
                            <IoMdSearch className="size-4 cursor-pointer" />
                            <input type="text" placeholder="Search" value={search} onChange={(e: any) => setSearch(e.target.value)} className="w-full outline-none placeholder:text-xs placeholder:text-gray-700 dark:placeholder:text-white caret-brand-500 text-sm font-medium text-gray-800 dark:bg-navy-700 "
                            />
                        </div>
                        {/* <CustomFilter optionData={[]} handleFilter={() => { }} id="campaign-filter-btn" /> */}
                    </div>

                    <div className='flex items-center flex-end gap-3'>
                        <Button onClick={downloadCsv} LeftIcon={<AiOutlineArrowRight className='text-[#4318FF] size-4' />} py='py-2' px='px-3.5' name='Export' color='[#4318FF]' bg='[#F9F5FF]' customClass='gap-1 border rounded-md border-[#4318FF] font-semibold' />
                        {/* <div className={`relative cursor-pointer rounded-md bg-white border ${colViewOptions ? 'outline outline-[2px] outline-menuBorder' : 'outline-gray-300'}`} >
                            <button className="h-full w-full px-3 py-2" id="campaign-column-list-id" onClick={() => setColViewOptions(!colViewOptions)}>
                                <MdViewWeek className="text-gray-700" />
                            </button>
                            {colViewOptions && (
                                <div className="absolute right-0 top-10 z-10 w-64 space-y-1 rounded-md border bg-white px-3 py-2 dark:bg-navy-700">
                                    {columnOptions?.map((col: any, id: any) => (
                                        <div key={id} className="flex items-center justify-between">
                                            <h5 className="text-xs dark:text-white">{col.name}</h5>
                                            <Toggle
                                                checkedCondition={col?.[col.id]}
                                                changeFunction={(e: Event) => {
                                                    handleColumnData(col.id, col?.[col.id]);
                                                }}
                                                disabled={col?.disabled}
                                                className={`scale-50 col-list-${col?.name}`}
                                                id={`col-list-${col?.name}`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                    </div>

                </div>
                <div className="h-[73vh] overflow-y-auto overflow-x-scroll scroll-smooth" ref={tableRef}>
                    <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
                        <thead>
                            {table?.getHeaderGroups()?.map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup?.headers?.map((header, index, arr) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                onClick={header.column.getToggleSortingHandler()}
                                                className={`mb-2 cursor-pointer bg-gray-50 dark:bg-navy-900 py-2 border-b border-gray-200 text-start dark:border-white/10`}>
                                                <div className="text-[0.7rem] text-[#667085]">
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: "",
                                                        desc: "",
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="divide-y-[1px] selection:decoration-[1.5px] selection:decoration-yellow-400 selection:underline selection:bg-yellow-50">
                            {state?.prospectsList?.length ? table
                                ?.getRowModel()
                                ?.rows
                                ?.map((row) => {
                                    return (
                                        <tr key={row.id} className=' text-column dark:text-white hover:bg-hover dark:hover:bg-navy-900'>
                                            {row?.getVisibleCells?.()?.map((cell) => {
                                                return (
                                                    <td key={cell.id} className={`border-white/0 text-start cursor-pointer duration-300`}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                }) : <tr></tr>
                            }
                        </tbody>
                    </table>
                    <InfiniteScrollComponent
                        loadMore={prospectsFetch}
                        loader={
                            <TableLoader
                                width="w-full"
                                border="border border-gray-100"
                                column={10}
                                row={1}
                                skeletonHeight="h-2"
                                padding="px-5 py-4"
                                columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                            />
                        }
                    />
                    {prospectLoader && <TableLoader
                        width="w-full"
                        border="border border-gray-100"
                        column={10}
                        row={1}
                        skeletonHeight="h-2"
                        padding="px-5 py-4"
                        columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                    />}
                </div>
            </div>
        </div>
    )
}

export default ProspectsInsights