import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { setVariableArea, setMessageData } from 'store/campaign-v2'
import { ContentDrawerContext } from './ContentDrawer'
import { useAppSelector } from 'store'

const LinkedinDrawer = () => {
    const { id } = useContext(ContentDrawerContext);
    const state = useAppSelector(state => state?.campaignv2);
    const { delivery_data, channelAction } = state?.sequence[id];
    const version = state?.current_version === 'A' ? 'version_a' : 'version_b';
    const dispatch = useDispatch();

    return (
        <div className='rounded-md bg-[#A3AED00F] flex flex-col gap-3 p-3.5 relative select-none'>
            <div className='flex justify-between'>
                <h5 className='text-[#667085] text-[0.85rem] font-medium capitalize'>{channelAction?.replace('_', ' ')}</h5>
                <button className='rounded-sm bg-[#F4F7FE] font-semibold py-1.5 px-2 text-[0.7rem] border border-[#E9EDF7]'>Preview</button>
            </div>
            {id !== 0 && !delivery_data[version]?.subject && <h5 className='rounded-md text-[0.7rem] px-2 py-0.5 bg-purple-100 font-medium'> <b>Note</b> : Leave the Subject Empty to send in the same thread</h5>}
            <div className=''>
                <textarea 
                name="" 
                id="content_drawer_subject_input"   
                spellCheck={false}
                value={delivery_data[version]?.message || ''}
                onFocus={() => dispatch(setVariableArea('message'))}
                onChange={(e) => dispatch(setMessageData({ input: 'message', value: e.target.value }))}
                placeholder='Subject' className='placeholder:text-[#667085] rounded-md border resize-none border-gray-200 p-2 text-xs text-[#667085] focus:text-blackDark selection:decoration-[1.5px] focus:outline focus:outline-2 focus:outline-menuBorder selection:decoration-yellow-400 selection:underline selection:bg-yellow-50'
                />
            </div>
        </div>
    )
}

export default LinkedinDrawer