import { useNavigate } from 'react-router-dom';
import React from 'react';

import OnboardBackground from 'components/crmOnboard/OnbardBackground';
import FileUploader from 'common/sparkle-common/FileUploader';
import Button from 'common/sparkle-common/Button';
import { setProspectList } from 'store/crm-onboard';
import { useAppDispatch, useAppSelector } from 'store';
import MapVariablePopup from './MapVariablePopup';
import OnboardProspectPopup from './OnboardProspectPopup';

const OnboardUploadProspects = () => {
  const resultList = useAppSelector((state) => state.crmOnboard.prospect_list_result);
  const [isOpenVerify, setIsOpenVerify] = React.useState(false);
  const { is_prospect_uploaded, prospect_list_result } = useAppSelector((state) => state.crmOnboard);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleNav = () => {
    navigate('/crm/onboard/connect/pipeline');
  };
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCsvUpload = (data: any) => {
    dispatch(setProspectList(data));
    setIsOpen(true);
  };
  return (
    <>
      <OnboardBackground count={2}>
        <div className="flex justify-between">
          <div className="flex flex-col justify-start gap-8 py-6">
            <div>
              <p className="text-3xl font-bold text-heading">Add prospects to see your CRM in action</p>
              <p className="mt-3 w-[55%] text-sm font-medium text-heading">
                We have already added all contacts who have responded to your campaigns under Brand X to this pipeline. You can add more prospects manually if required or skip this
                step and do it later.
              </p>
              <div className="mt-10 h-[22rem] w-1/2 rounded-xl bg-white dark:bg-darkTwo dark:shadow-darkBorder px-6 pb-6 pt-2 shadow-shade">
                {!is_prospect_uploaded ? (
                  <FileUploader customClass={`!h-[19rem] ${'upload_csv_Prospects'}`} onUpload={handleCsvUpload} />
                ) : (
                  <div className="mt-4 flex h-[19rem] flex-col items-center justify-center gap-1 rounded-sm border-[0.1rem] border-dashed border-green-300 bg-green-50">
                    <div className="text-sm font-bold text-green-700">{prospect_list_result?.length} Leads have been successfully added</div>
                  </div>
                )}
              </div>
              <div className="ml-8 mt-8 flex items-center gap-8">
                <p className="cursor-pointer text-xs font-medium text-brand-500 underline" onClick={() => handleNav()}>
                  Skip
                </p>
                <Button
                  type="button"
                  name="Next"
                  id={'prospect-upload-next-btn'}
                  disable={resultList.length > 0 ? false : true}
                  onClick={() => handleNav()}
                  fontSize="sm"
                  px="px-6"
                  customClass={`text-column font-medium text-medium ${'upload-prospect-next-btn'}`}
                />
              </div>
            </div>
          </div>
        </div>
      </OnboardBackground>
      <OnboardProspectPopup isOpen={isOpenVerify} onClose={() => setIsOpenVerify(false)} />
      <MapVariablePopup isOpen={isOpen} onClose={() => setIsOpen(false)} setIsOpenVerify={setIsOpenVerify} />
    </>
  );
};

export default OnboardUploadProspects;
