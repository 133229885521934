import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Text } from '@chakra-ui/react';
import Button from 'common/sparkle-common/Button';
import { GiProcessor } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

const WarningPopup = (props: any) => {
  const { isOpen } = props;
  const navigate = useNavigate();
  const onClose = () => {
    navigate('/sender-accounts');
  };
  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="!p-0">
          <ModalHeader>
            <div className="flex flex-col">
              <div className="flex items-center text-lg font-semibold text-green-700">
                <GiProcessor className="mr-1 inline-block" />
                <span>Coming Soon!</span>
              </div>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <ModalBody className="bg-modalbg !p-0">
            <div className="flex items-center justify-center">
              <div className="w-[44rem] px-6  py-6 shadow-md">
                <div className="h-48  gap-5 overflow-auto rounded-md bg-white px-3 py-2">
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    We’re excited to announce that this feature will be available soon! 🚀
                  </Text>

                  <Text fontSize="md" mb={2}>
                    Our team is working hard to bring you this feature, which will allow you to buy email domain. Stay tuned for updates, and get ready to enhance your experience.
                  </Text>
                </div>
                <div className="mt-6 flex gap-4">
                  <Button name="Cancel" customClass="w-full !bg-blueSecondary border !text-white !font-semibold text-[.92rem]" onClick={() => onClose()} />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default WarningPopup;
