import { MdOutlineAttachEmail } from "react-icons/md";
import { SiMicrosoftoffice } from "react-icons/si";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Stack } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import Widget from "components/widget";
import { useAppDispatch, useAppSelector } from "store";
import { setFlagStatus } from "store/custom";
import { BaseApi } from "common/api/common/base-api";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from "react";
import AddNewSMTP from "./add-smtp";
import AddSMTPSingleAccount from "./add-smtp/features/SingleAccount";

export default function AddEmailAccountsDrawerLayout() {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const authCode = searchParams.get('code');

    const [smtpFlag, setSmtpFlag] = useState(false)

    const generateCodeToTokenAndUserInfo = useCallback(async (authCode: string) => {
        try {
            await BaseApi.patch('/sender-accounts/email/auth/code', { code: authCode });
            dispatch(setFlagStatus(true))
        } catch (error) {
            console.error(error)
        }
    }, [dispatch])
    useEffect(() => {
        if (authCode) {
            generateCodeToTokenAndUserInfo(authCode);
            navigate('/email/settings')
        }
    }, [authCode, generateCodeToTokenAndUserInfo, navigate])

    const handleGoogleOAuthSetup = async () => {
        try {
            const response = await BaseApi.get('/sender-accounts/generate/email/auth/url');
            window.open(response?.data, '_blank');
        } catch (error) {
            console.error('error', error)
        }
    }

    return (
        <>
            {/* Right Drawer for 3 widget */}
            <Drawer placement="right" onClose={() => dispatch(setFlagStatus(false))} isOpen={state?.custom?.flagStatus} size={'sm'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Add a new email account
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack spacing='24px'>
                            {!smtpFlag &&
                                <Box>
                                    <div className="m-3">
                                        <Widget onClick={() => {
                                            handleGoogleOAuthSetup()
                                            console.log('Drawer rendered in the DOM')
                                        }} icon={<FcGoogle size={35} />} title={"Google"} subtitle={"Gmail / G-Suite"} />
                                    </div>
                                    <div className="m-3">
                                        <Widget icon={<SiMicrosoftoffice size={35} color="red" />} title={"Microsoft"} subtitle={"Office 365 / Outlook"} />
                                    </div>
                                    <div className="m-3">
                                        <Widget onClick={() => setSmtpFlag(true)} icon={<MdOutlineAttachEmail size={35} color="silver" />} title={"Any Provider"} subtitle={"IMAP / SMTP"} />
                                    </div>
                                </Box>
                            }
                            {smtpFlag && <AddNewSMTP setSmtpFlag={setSmtpFlag} />}
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            {/* Right Drawer for Single SMTP Account */}
            {state?.emailAccount?.smtpDrawerFlag && <AddSMTPSingleAccount />}
        </>
    );
}
