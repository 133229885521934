import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/modal';
import { ModalComponentProps } from 'common/dto/types/views/blacklist';
import { AiFillCheckCircle } from 'react-icons/ai';

const ResultModal = (props: ModalComponentProps) => {
  const getColor = (blacklistDomain: string) => {
    switch (blacklistDomain) {
      case 'Connection Fail':
        return '#175CD3';
      case 'Bad Name':
        return '#667085';
      case 'Listed':
        return '#F04438';
      default:
        return '#12B76A';
    }
  };
  const { onClose, isOpen, modalData } = props;
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'}>
          <ModalHeader>
            <div className="space-y-1">
              <h5 className="text-sm text-heading">Real time analysis</h5>
              <p className="flex w-3/4 gap-1 text-xs font-thin text-column">
                {modalData?.blacklist_name}
                <span>
                  <AiFillCheckCircle className="size-4 text-green-600" />
                </span>
              </p>
              <p className="w-3/4 text-xs font-thin text-column">
                Type:{' '}
                <b className="font-semibold  text-[#2B3674]">
                  {modalData?.blacklist_type}
                </b>{' '}
              </p>
              <p className="w-3/4 text-xs font-thin text-column ">
                Classification:{' '}
                <b className="font-semibold text-[#2B3674]">Blacklist Result</b>{' '}
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton id="email-verify-modal-close-button" />
          <ModalBody>
            <div className="mb-3 h-96 overflow-auto ">
              <div className="flex items-center border-b bg-[#F9FAFB] px-3 py-2 text-sm text-column">
                <h5 className="w-full">Listed Domain</h5>
                <h5 className="w-full">Status</h5>
              </div>

              {modalData?.blacklist_result &&
                modalData?.blacklist_result?.filter((items)=>items?.status !== 'Connection Fail')?.map((key: any) => (
                  <div
                    className="flex items-center border-b bg-white p-3 text-sm text-column"
                    key={key.blacklist_domain}
                  >
                    <h5 className="flex w-full items-center gap-1 font-semibold text-heading">
                      <span>{key.blacklist_domain}</span>
                    </h5>
                    <h5 className="flex w-full items-center gap-1 font-semibold text-heading">
                      <span
                        style={{
                          color: getColor(key.status),
                        }}
                      >
                        {key.status}
                      </span>
                    </h5>
                  </div>
                ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResultModal;
