import { CustomMenu } from 'components/customMenu';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';

const RoleDropdown = ({ options, placeholderName, onSelectionChange, error }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSearchChange = (e: any) => setSearch(e.target.value);

  const handleSelect = (option: any) => {
    setSelectedOptions([option]); // Directly set the selected option
    onSelectionChange([option]); // Notify parent component of the change
    handleClose();
    // toggleDropdown();
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const filteredOptions = options.filter((option: any) => option?.role_name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className=" bg-white dark:bg-darkThree">
      <CustomMenu
        open={isOpen}
        onClose={handleClose}
        onOpen={() => setIsOpen(true)}
        btnId='role-select'
        btnClassName={`flex w-full dark:bg-darkOne dark:!text-gray-400 role-list-dropdown-btn items-center justify-between rounded-md border border-gray-200 px-2 py-2 text-start text-xs !text-[#667085] focus:text-blackDark ${error ? `!border-red-500` : ``}`}
        btnContent={placeholderName}
        menuListClassName="w-full dark:bg-darkOne dark:!text-white rounded-md bg-white shadow-md dark:border-darkBorder dark:shadow-darkBorder"
        rightIcon={<IoIosArrowDown className="text-gray-600" />}
      >
        <div className="mx-2 mt-2 rounded-md border border-gray-500 ">
          <div className="relative flex w-full items-center">
            <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              className="input dark:bg-darkOne dark:!text-white input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none"
            />
          </div>
        </div>
        <div className="mt-3 w-full">
          {/* <ul className=""> */}
          {filteredOptions.map((option: any, idx: number) => (
            <button
              key={option.uuid}
              className={`flex w-full cursor-pointer items-center rounded-md p-1 dark:bg-darkTwo dark:!text-white px-2 text-start !text-xs  dark:hover:bg-blue-900 ${selectedOptions.some((sel) => sel.uuid === option.uuid) ? 'bg-blueSecondary text-white dark:bg-blueSecondary' : 'bg-white text-column  hover:bg-gray-100 hover:text-blackDark'
                }`}
              id={`role-index-${idx}`}
              onClick={() => {
                handleSelect(option);
                setIsOpen(false);
              }}
            >
              <label className={`flex-1 select-none text-sm role-select-input-${idx}`} htmlFor={`role-select-input-${idx}`}>
                {option.role_name}
              </label>
            </button>
          ))}
          {/* </ul> */}
        </div>
      </CustomMenu>

      {/* <button
        id="role-list-dropdown-btn"
        onClick={toggleDropdown}
        className="flex w-full items-center justify-between rounded-md border border-gray-200 px-2 py-2 text-start text-xs !text-[#667085] focus:text-blackDark"
      >
        {placeholderName}
        <IoIosArrowDown className="text-gray-600" />
      </button> */}
      {/* {isOpen && ( */}
      {/* <div className="absolute z-10 w-full rounded-md bg-white shadow-md">
          <div className="m-3 rounded-md border border-gray-500">
            <div className="relative flex w-full items-center">
              <FiSearch className="pointer-events-none absolute ml-2 text-gray-600" size={14} />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                className="input input-bordered w-full rounded-md p-1 pl-6 text-sm focus:outline-none"
              />
            </div>
          </div>
          <div className="m-3">
           
            <ul className="max-h-28 overflow-y-auto">
              {filteredOptions.map((option: any, idx: number) => (
                <li
                  key={option.uuid}
                  className={`flex cursor-pointer  items-center rounded-md p-1 px-2 !text-xs ${
                    selectedOptions.some((sel) => sel.uuid === option.uuid) ? 'bg-blueSecondary text-white' : 'bg-white text-column  hover:bg-gray-100 hover:text-blackDark'
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  <label className="flex-1 select-none text-sm" htmlFor={`role-select-input-${idx}`}>
                    {option.role_name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
      {/* <ul className="max-h-40 overflow-y-auto">
              {filteredOptions.map((option: any, idx: number) => (
                <li key={option.uuid} className="flex items-center p-2">
                  <input
                    id={`role-select-input-${idx}`}
                    type="checkbox"
                    checked={selectedOptions.some(
                      (sel) => sel.uuid === option.uuid
                    )}
                    onChange={() => handleSelect(option)}
                    className="checkbox checkbox-primary mr-2"
                  />
                  <label
                    className="select-none text-sm"
                    onClick={() => handleSelect(option)}
                  >
                    {option.role_name}
                  </label>
                </li>
              ))}
            </ul> */}
      {/* )} */}
    </div>
  );
};

export default RoleDropdown;
